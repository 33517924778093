// import React from 'react'
// import { loginRedirectCall } from './RedirectPathMange';
// import { Helmet } from 'react-helmet';
// import { Link } from 'react-router-dom';

// export default function RedirectBlankPage() {
//   return (
//     <>
//       <Helmet>
//         <style>
//           {`
//    /* NotFound.css */
//    .not-found {
//      text-align: center;
//      padding: 50px;
//      color: #333;
//    }
   
//    .not-found h1 {
//      font-size: 5em;
//      margin: 0;
//    }
   
//    .not-found h2 {
//      margin: 20px 0;
//      font-size: 2em;
//    }
   
//    .not-found p {
//      font-size: 1.2em;
//      margin: 20px 0;
//    }
   
//    .not-found a {
//      color: #007bff;
//      text-decoration: none;
//      font-size: 1.2em;
//    }
   
//    .not-found a:hover {
//      text-decoration: underline;
//    }
   
//     `}
//         </style>
//       </Helmet>
//       <div>
//         <div className="not-found">
//           <h1>404</h1>
//           <h2>Page Not Found</h2>
//           <p>Sorry, the page you are looking for does not exist.</p>
//           <Link to="/">Go back to Home</Link>
//         </div>
//       </div>
//     </>
//   )
// }

import React from 'react'
import {loginRedirectCall} from './RedirectPathMange';

export default function RedirectBlankPage() {
  return (
    <div>
        {loginRedirectCall()}      
    </div>
  )
}