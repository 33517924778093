import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import JqueryLoad from '../../../JqueryLoad/JqueryLoad'
import OFooter_Dashboard from '../OInclude/OFooter_Dashboard'
import OHeader_Dashboard from '../OInclude/OHeader_Dashboard'
import Modals from 'react-modal';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { registerLocale } from 'react-datepicker'
import { copy_payment_class_list, copy_structure, edit_payment_settings, payment_data_get, payment_data_insert, payment_setting_classes_list, payment_setting_teacher } from '../../../api/apiHandler'
import { TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/common.service'

var COMMON_STYLE_FOR_POPUP = {
  content: {
    // backgroundColor: 'rgb(0 0 0 / 56%)',
    background: 'none',
    border: '0px',
  },
}
export default function OPaymentDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  var teacher_id = location?.state;
  console.log('teacher_id :', teacher_id);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpen1, setIsOpen1] = useState(false);
  const [editModalIsOpen, setEditIsOpen] = useState(false);
  const [TeacherData, setTeacherData] = useState(false);
  console.log('TeacherData :', TeacherData);
  const [TeacherSub, setTeacherSub] = useState([]);
  console.log('TeacherSub :', TeacherSub);
  const [Activity, setActivity] = useState([]);
  console.log('Activity :', Activity);
  const [tData, setTableData] = useState([]);
  const [teacherTableName, setTeacherTableName] = useState([]);
  const [oldTeacherData, setOldTeacherData] = useState([]);
  const [lastElement, setLastElement] = useState([]);
  const [paymentId, setPaymentId] = useState([]);
  const [paymentValue, setPaymentValue] = useState([]);
  const [typeData, setTypeData] = useState("online");
  const [learner, setLearner] = useState();
  console.log('learner :', learner);

  
  // console.log('lastElement :', lastElement);

  // console.log('oldTeacherData :', oldTeacherData);


  // console.log('teacherTableName :', teacherTableName);
  // console.log('tData :', tData);
  // console.log('Activity :', Activity);

  const schema = yup.object().shape({
    paymentUsd: yup.string().required("This is required "),
  });

  const schema1 = yup.object().shape({
    select_option: yup.string().required("This is required "),
  });

  const schema2 = yup.object().shape({
  });

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    setValue: setValue1,
    clearErrors: clearErrors1,
    reset: reset1,
    watch: watch1,
    control: control1,
    getValues: getValues1,
    formState: { errors: errors1 },
  } = useForm({
    resolver: yupResolver(schema1),
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    clearErrors: clearErrors2,
    reset: reset2,
    watch: watch2,
    control: control2,
    getValues: getValues2,
    formState: { errors: errors2 },
  } = useForm({
    resolver: yupResolver(schema2),
  });


  let TeacherDetails = async () => {
    try {
      let send_data = {
        teacher_id: teacher_id
      }
      const response = await payment_setting_teacher(send_data);
      // console.log('payment_setting_teacher :', response);
      if (response?.code == 1) {
        setTeacherData(response?.data[0])
        TeacherSubject();
      } else {
        setTeacherData([])
      }
    } catch (error) {
      console.log('error :', error);
    }
  }

  useEffect(() => {
    TeacherDetails();
  }, [])

  let TeacherSubject = async () => {
    try {
      let send_data = {
        assign_teacher_id: teacher_id
      }
      const response = await payment_setting_classes_list(send_data);
      // console.log('payment_setting_classes_list :', response);
      if (response?.code == 1) {
        setTeacherSub(response?.data)
        setActivity(response?.data[0])
        setLearner(response?.data[0]?.max_learner)
        TableData(response?.data[0]?.classes_id, response?.data[0]?.classes_type, typeData);
      } else {
        setTeacherSub([])
      }
    } catch (error) {
      console.log('error :', error);
    }
  }

  const ClassType = (data) => {
    // console.log('ClassType :', data);
    setTypeData(data)
  }

  useEffect(() => {
    TableData(Activity?.classes_id, Activity?.classes_type);
  }, [typeData]);

  let TableData = async (id, type) => {
    try {
      let send_data = {
        teacher_id: teacher_id,
        activity_id: id,
        activity_type: type,
        type: typeData
      }
      // console.log('send_dataTableData :', send_data);
      const response = await payment_data_get(send_data);
      // console.log('payment_data_get :', response);
      if (response?.code == 1) {
        setTableData(response?.data)
        setLastElement(response?.data?.slice(-1)[0])
      } else {
        setTableData([])
      }
    } catch (error) {
      console.log('error :', error);
    }
  }



  const handleSelectedLearner = (data) => {
    // console.log('data1234 :', data);
    var main = JSON.parse(data);
    setActivity(main)
    setLearner(main?.max_learner)
    console.log('max_learner :', main?.max_learner);
    
    TableData(main.classes_id, main.classes_type);
    // console.log('main :', main);
  }

  const handleSelectedTeacher = (data) => {
    // console.log('data12345 :', data);
    var main1 = JSON.parse(data);
    setOldTeacherData(main1)
  }



  // useEffect(() => {
  //   TeacherSubject();
  // }, [])

  let onSubmit = async (data) => {
    // console.log('data123 :', data.payment_usd);
    try {
      let send_data = {
        activity_id: Activity?.classes_id,
        activity_type: Activity?.classes_type,
        teacher_id: teacher_id,
        no_students: tData.length + 1,
        payment: data.paymentUsd,
        payment_type: Activity?.payment_type,
        type: typeData

      }
      if (TeacherData.type == "percentage") {
        send_data.percentage_value = data.perentageData
      }
      console.log('onSubmit :', send_data);

      // console.log('send_data123 :', send_data);
      const response = await payment_data_insert(send_data);
      // console.log('payment_data_insert :', response);
      if (response?.code == 1) {
        TOAST_SUCCESS(response.message)
        TableData(Activity?.classes_id, Activity?.classes_type);
        reset();
        setIsOpen(false)
      } else {
        TOAST_ERROR(response.message)
      }
    } catch (error) {
      console.log('error :', error);
    }
  }

  let TeacherTable = async () => {
    try {
      let send_data = {
        teacher_id: teacher_id,
        activity_id: Activity?.classes_id,
        activity_type: Activity?.classes_type,

      }
      // console.log('send_data1234 :', send_data);
      const response = await copy_payment_class_list(send_data);
      // console.log('copy_payment_class_list :', response);
      if (response?.code == 1) {
        setTeacherTableName(response?.data)
        setIsOpen1(true)
      } else {
        setIsOpen1(true)
        setTeacherTableName([])
      }
    } catch (error) {
      console.log('error :', error)
    }
  }

  let onSubmit1 = async (data) => {
    try {
      let send_data = {
        activity_id: oldTeacherData?.class_id,
        activity_type: oldTeacherData?.class_type,
        teacher_id: teacher_id,
        updated_activity_id: Activity?.classes_id,
        updated_activity_type: Activity?.classes_type,
        type: typeData

      }
      // console.log('send_data :', send_data);
      const response = await copy_structure(send_data);
      // console.log('copy_structure :', response);
      if (response?.code == 1) {
        TOAST_SUCCESS(response.message)
        TableData(Activity?.classes_id, Activity?.classes_type);
        reset();
        setIsOpen1(false)
      } else {
        TOAST_ERROR(response.message)
      }

    } catch (error) {
      console.log('error :', error);

    }
  }

  const editPayment = async (payment_id, payment, pTage) => {
    setPaymentId(payment_id)
    setPaymentValue(payment)
    setValue2("paymentUsd", payment)
    if (pTage != null && pTage != undefined) {
      setValue2("perentageData", pTage)
    }

    setEditIsOpen(true)
  }

  let onSubmit2 = async (data) => {
    try {
      let send_data = {
        payment_id: paymentId,
        payment: data.paymentUsd,
      }
      if (data.perentageData) {
        send_data.perentageData = data.perentageData
      }
      // console.log('send_data :', send_data);

      const response = await edit_payment_settings(send_data);
      // console.log('copy_structure :', response);
      if (response?.code == 1) {
        TOAST_SUCCESS(response.message)
        TableData(Activity?.classes_id, Activity?.classes_type);
        reset();
        setEditIsOpen(false)
      } else {
        TOAST_ERROR(response.message)
      }

    } catch (error) {
      console.log('error :', error);
    }
  }



  return (
    <>
      <JqueryLoad />
      <OHeader_Dashboard />

      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className>
              {/*start*/}
              <a onClick={() => navigate("/organization_payment")} className="mb-4 arrow_left mb-3 d-block">
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span onClick={() => navigate("/organization_payment")} className="ms-2">Back</span>
                </span>
              </a>
              {/*end*/}
              {/*start*/}
              <h2 className="mb-2 poppins fw-600 text-capitalize">Payment made to teachers</h2>
              <p className="mb-5 poppins fw-600 text-capitalize">Please setup the payment tables to pay your teachers.</p>
              <div className="row pay_pice_set ">
                <div className="col-xl-8">
                  <div className="card  mb-3">
                    <div className="pay_pice_box d-md-flex align-items-center justify-content-md-between">
                      <div className="d-flex align-items-center mb-md-0 mb-4">
                        <div className="position-relative pay_set_left">
                          <img src={TeacherData?.profile_image} alt="user4" style={{ borderRadius: "50%" }} />
                          <div className="price_flag position-absolute">
                            {/* <img src={TeacherData?.country_image} alt="country_flag" /> */}
                            <span className="ms-2">{TeacherData?.country_image}</span>

                          </div>
                        </div>
                        <h3 className="ms-3 poppins fw-600">{TeacherData?.full_name}</h3>
                      </div>

                      <div className="pay_set_right d-flex justify-content-end">
                        {TeacherSub.length > 0 ?
                          <select className="border-purple fs-6 fw-500 text-dif-black rounded-cricle rounded-pill ms-3 px-2 py-2" onChange={(e) => handleSelectedLearner(e.target.value)}>
                            {TeacherSub?.map((subject, index) => {
                              const classes_id = subject?.classes_id;
                              const classes_type = subject?.classes_type;
                              const payment_type = subject?.payment_type;
                              const max_learner = subject?.max_learner;
                              const valueObj = JSON.stringify({ classes_id, classes_type, payment_type,max_learner });
                              return (
                                <option key={index} value={valueObj}>{subject?.activity_title}</option>
                              )
                            })}
                          </select>
                          : <></>}
                        <select className="border-purple fs-6 fw-500 text-dif-black rounded-cricle rounded-pill ms-3 px-2 py-2" onChange={(e) => ClassType(e.target.value)}>
                          <option value={"online"}>Online Classes</option>
                          <option value={"inperson"}>In-Person</option>
                        </select>
                      </div>

                    </div>
                  </div>
                  <p className="mb-5 poppins fw-600 text-capitalize">Please add ‘{learner}’ learners to class as the max number of learners is set to {learner}</p>

                  <div className="d-flex justify-content-end">
                    <button onClick={(e) => TeacherTable()} className="btn-theme btn-prple-border d-inline-block text-center text-dif-black fw-500 mx-4">Copy table</button>

                    <Link onClick={(e) => setIsOpen(true)} state={teacher_id} className="text-purple fs-5 text-decoration-underline" style={{ marginTop: "10px" }}>+ Add Learner's</Link>
                    {/* <Link className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block mx-2" data-bs-toggle="modal" >Comment</Link> */}
                  </div>
                  <div className="table-responsive mt-4">
                    <table className="table table-bordered">
                      <thead className="text-center">
                        <tr>
                          <th scope="col" className="fw-600 w-25" >No of Learners</th>
                          <th scope="col" className="fw-600 w-25" >Payment in USD</th>
                          <th className="w-25" >Edit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tData?.sort((a, b) => a.no_students - b.no_students)?.map((tableDetails, index) => {
                          // console.log('tableDetails :', tableDetails);
                          return (
                            <>
                              <tr className="text-center">
                                <td>{tableDetails.no_students}</td>
                                <td>USD {tableDetails.payment}</td>
                                <td align="center" className="text-center">
                                  <a onClick={(e) => editPayment(tableDetails.id, tableDetails.payment, tableDetails?.percentage_value)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="27" height="27" viewBox="0 0 300 150">
                                      <defs>
                                        <linearGradient x1="46.807" y1="9.849" x2="46.807" y2="24.215" gradientUnits="userSpaceOnUse" id="color-1_56304_gr1">
                                          <stop offset="0" stop-color="#570861"></stop>
                                          <stop offset="1" stop-color="#570861"></stop>
                                        </linearGradient>
                                        <linearGradient x1="32" y1="8.084" x2="32" y2="55.83" gradientUnits="userSpaceOnUse" id="color-2_56304_gr2">
                                          <stop offset="0" stop-color="#570861"></stop>
                                          <stop offset="1" stop-color="#570861"></stop>
                                        </linearGradient>
                                      </defs>
                                      <g fill="none" fill-rule="nonzero">
                                        <g transform="scale(4,4)">
                                          <path d="M49.482,24.392l-9.874,-9.874l4.232,-4.232c0.39,-0.39 1.021,-0.39 1.411,0l8.464,8.464c0.39,0.39 0.39,1.021 0,1.411z" fill="url(#color-1_56304_gr1)"></path>
                                          <path d="M50.697,25.999l4.428,-4.428c1.167,-1.167 1.167,-3.065 0,-4.232l-8.464,-8.464c-1.167,-1.167 -3.065,-1.167 -4.232,0l-4.428,4.428c-0.664,-0.175 -1.4,-0.011 -1.92,0.509l-1.411,1.411c-0.52,0.52 -0.684,1.256 -0.509,1.92l-22.963,22.963l-0.508,0.508l-0.2,0.2l-2.373,9.967c-0.343,1.442 0.078,2.928 1.126,3.976c1.048,1.048 2.534,1.469 3.976,1.125l9.967,-2.373l0.2,-0.2l0.508,-0.508l22.964,-22.964c0.664,0.175 1.4,0.011 1.92,-0.509l1.411,-1.411c0.519,-0.518 0.683,-1.254 0.508,-1.918zM47.367,27.92l-11.286,-11.286l1.411,-1.411l11.285,11.285zM23.46,50.414c-0.28,-1.063 -0.682,-2.077 -1.198,-3.034l20.872,-20.872l2.116,2.116zM14.916,53.428c-0.12,-1.074 -0.58,-2.115 -1.405,-2.939c-0.825,-0.825 -1.865,-1.285 -2.939,-1.405l0.698,-2.931c1.649,0.266 3.173,1.036 4.357,2.22c1.184,1.184 1.954,2.709 2.22,4.357zM17.038,46.962c-1.447,-1.447 -3.301,-2.396 -5.306,-2.75l0.463,-1.943c2.382,0.441 4.533,1.562 6.254,3.282c1.721,1.72 2.841,3.872 3.282,6.254l-1.943,0.463c-0.355,-2.005 -1.303,-3.859 -2.75,-5.306zM19.859,44.141c-0.477,-0.477 -0.987,-0.907 -1.517,-1.304l20.561,-20.561l2.821,2.821l-20.561,20.561c-0.397,-0.53 -0.827,-1.04 -1.304,-1.517zM16.62,41.738c-0.957,-0.516 -1.971,-0.918 -3.034,-1.198l21.79,-21.79l2.116,2.116zM43.84,10.286c0.389,-0.389 1.022,-0.389 1.411,0l8.464,8.464c0.389,0.389 0.389,1.022 0,1.411l-4.232,4.232l-9.874,-9.874z" fill="url(#color-2_56304_gr2)"></path>
                                        </g>
                                      </g>
                                    </svg>
                                  </a>
                                </td>
                              </tr>
                            </>
                          )

                        })}

                        {/* <tr>

                          <td>2</td>
                          <td>USD 32</td>
                          <td align="center" className="text-center">
                            <a href="#">
                              <svg width={17} height={16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_4147_11885)">
                                  <path fillRule="evenodd" clipRule="evenodd" d="M16.5 2.40156H0.5V1.60156H16.5V2.40156Z" fill="#DE5462" />
                                  <path fillRule="evenodd" clipRule="evenodd" d="M2.10156 1.73644L2.89895 1.67188L3.99418 15.1975H12.824L14.1021 1.75958L14.8985 1.83533L13.5515 15.9975H3.25634L2.10156 1.73644Z" fill="#DE5462" />
                                  <path fillRule="evenodd" clipRule="evenodd" d="M8.09766 13.6V4H8.89766V13.6H8.09766Z" fill="#DE5462" />
                                  <path fillRule="evenodd" clipRule="evenodd" d="M5.69922 13.6V4H6.49922V13.6H5.69922Z" fill="#DE5462" />
                                  <path fillRule="evenodd" clipRule="evenodd" d="M10.5 13.6V4H11.3V13.6H10.5Z" fill="#DE5462" />
                                  <path d="M7.30078 0H9.70078V0.8H7.30078V0Z" fill="#DE5462" />
                                </g>
                                <defs>
                                  <clipPath id="clip0_4147_11885">
                                    <rect width={16} height={16} fill="white" transform="translate(0.5)" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </a>
                          </td>
                        </tr>
                        <tr>

                          <td>3</td>
                          <td>USD 34</td>
                          <td align="center" className="text-center">
                            <a href="#">
                              <svg width={17} height={16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_4147_11885)">
                                  <path fillRule="evenodd" clipRule="evenodd" d="M16.5 2.40156H0.5V1.60156H16.5V2.40156Z" fill="#DE5462" />
                                  <path fillRule="evenodd" clipRule="evenodd" d="M2.10156 1.73644L2.89895 1.67188L3.99418 15.1975H12.824L14.1021 1.75958L14.8985 1.83533L13.5515 15.9975H3.25634L2.10156 1.73644Z" fill="#DE5462" />
                                  <path fillRule="evenodd" clipRule="evenodd" d="M8.09766 13.6V4H8.89766V13.6H8.09766Z" fill="#DE5462" />
                                  <path fillRule="evenodd" clipRule="evenodd" d="M5.69922 13.6V4H6.49922V13.6H5.69922Z" fill="#DE5462" />
                                  <path fillRule="evenodd" clipRule="evenodd" d="M10.5 13.6V4H11.3V13.6H10.5Z" fill="#DE5462" />
                                  <path d="M7.30078 0H9.70078V0.8H7.30078V0Z" fill="#DE5462" />
                                </g>
                                <defs>
                                  <clipPath id="clip0_4147_11885">
                                    <rect width={16} height={16} fill="white" transform="translate(0.5)" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </a>
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>
      <Modals isOpen={modalIsOpen} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal" >

        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <h5 className="modal-title poppins fw-600" id="exampleModalLabel">Add Learner's</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setIsOpen(false)} aria-label="Close" />
            </div>
            <div className="modal-body">
              <div className>
                <form onSubmit={handleSubmit(onSubmit)} className="custom_form mt-4" action id>
                  <div className="form-group mb-2">
                    <label className="mb-2">Teacher will be paid ${lastElement?.payment} for Learner {lastElement?.no_students}</label>

                  </div>
                  {TeacherData.type == "percentage" ?
                    <>
                      <div className="form-group mb-2">
                        <label className="mb-2">Add Percentage</label>
                        <input type="number" className="form-control" name='perentageData' {...register("perentageData")} />
                        {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.perentageData?.message}</span></p> */}
                      </div></>
                    : <></>}

                  <div className="form-group mb-2">
                    <label className="mb-2">Add Payment in USD</label>
                    <input type="number" className="form-control" name='paymentUsd' {...register("paymentUsd")} />
                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.paymentUsd?.message}</span></p>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn-theme bg-yellow d-inline-block fw-500" data-bs-dismiss="modal">Send</button>
                    <button type="button" onClick={() => setIsOpen(false)} className="btn-theme btn-prple-border d-inline-block text-center text-dif-black fw-500">Close</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modals>
      <Modals isOpen={editModalIsOpen} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal" >

        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <h5 className="modal-title poppins fw-600" id="exampleModalLabel">Edit Learner's</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setEditIsOpen(false)} aria-label="Close" />
            </div>
            <div className="modal-body">
              <div className>
                <form onSubmit={handleSubmit2(onSubmit2)} className="custom_form mt-4" action id>

                  {/* <>
                    <div className="form-group mb-2">
                      <label className="mb-2">Add Percentage</label>
                      <input type="number" className="form-control" name='perentageData' />
                    </div></> */}
                  {TeacherData.type == "percentage" ?
                    <>
                      <div className="form-group mb-2">
                        <label className="mb-2">Add Percentage</label>
                        <input type="number" className="form-control" name='perentageData' {...register2("perentageData")} />
                        {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.perentageData?.message}</span></p> */}
                      </div></>
                    : <></>}
                  <div className="form-group mb-2">
                    <label className="mb-2">Add Payment in USD</label>
                    <input type="number" className="form-control" name='paymentUsd' {...register2("paymentUsd")} />
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn-theme bg-yellow d-inline-block fw-500" >Send</button>
                    <button type="button" onClick={() => setEditIsOpen(false)} className="btn-theme btn-prple-border d-inline-block text-center text-dif-black fw-500">Close</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modals>
      <Modals isOpen={modalIsOpen1} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal" >

        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <h5 className="modal-title poppins fw-600" id="exampleModalLabel">Copy Payment Structure</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setIsOpen1(false)} aria-label="Close" />
            </div>
            <div className="modal-body">

              <div className>
                <form onSubmit={handleSubmit1(onSubmit1)} className="custom_form mt-4" action id>
                  {TeacherData.type == "percentage" ?
                    <>
                      <div className="form-group mb-2">
                        <label className="mb-2">Add Percentage</label>
                        <input type="number" className="form-control" name='perentageData' {...register1("perentageData")} />
                        {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.perentageData?.message}</span></p> */}
                      </div></>
                    : <></>}
                  <div className="form-group mb-4">
                    <label className="mb-2">Selsct Class Name</label>
                    <select className="form-select" aria-label="Default select example" name="select_option" {...register1("select_option")} onChange={(e) => handleSelectedTeacher(e.target.value)}>
                      <option value="" >Select class</option>
                      {teacherTableName?.map((subject, index) => {
                        const class_id = subject?.activity_id;
                        const class_type = subject?.activity_type;
                        const valueObj1 = JSON.stringify({ class_id, class_type });
                        // console.log('valueObj1 :', valueObj1);
                        return (
                          <option key={index} value={valueObj1}>{subject?.activity_title}</option>
                        )
                      })}


                    </select>
                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.select_option?.message}</span></p>
                    {/* 
                      <span className="fw-500 text-dif-black mt-2 d-block">Please confirm this change with the learner before you transfer.</span> */}
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn-theme bg-yellow d-inline-block fw-500" data-bs-dismiss="modal">Send</button>
                    <button type="button" onClick={() => setIsOpen1(false)} className="btn-theme btn-prple-border d-inline-block text-center text-dif-black fw-500">Close</button>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </Modals>
      <OFooter_Dashboard />
    </>
  )
}
