import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import io from "socket.io-client";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { SOCKET_URL } from "../../../Config";
import { uploadImageOnAWS } from "../../aws/Service";
import { TOAST_ERROR, TOAST_SUCCESS, convertToBase64 } from "../../../utils/common.service";
import { block_chat, chat_history, pin_chat, update_user_activity_status } from "../../../api/apiHandler";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const CommonChatBox = ({ discussionId, userData, typingusers, setTypingUsers, getChatList, handleChatList }) => {
console.log('discussionId :', discussionId);
  console.log('userData33333333 :', userData);
  console.log('typingusersmain :', typingusers);
  const [chatHistory, setChatHistory] = useState(null);
  console.log('chatHistory11111111111 :', chatHistory);
  console.log("🚀 ~ CommonChatBox ~ chatHistory:", chatHistory);
  const [page, setPage] = useState(1);
  const [message, setMessage] = useState("");
  console.log('message :', message);
  const [socket, setSocket] = useState(null);
  const [image, setImage] = useState({ blob: "", base64: "" });
  const imageVal = useRef(null);

  console.log('image :', image);
  const [status, setStatus] = useState("Inactive");
  const USER_ID = JSON.parse(localStorage.getItem("Tid"));
  const USER_TYPE = localStorage.getItem("userType");
  const scrollToBottomRef = useRef(null);
  const scrollToTopRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    userData?.chat_id && userData?.id && getChatHistory();
  }, [userData?.id,userData?.chat_id , page, discussionId]);

  useEffect(() => {
    getChatHistory();
  }, [discussionId]);

  useEffect(() => {
    if (userData?.id) {
      setMessage("");
      setImage({ blob: "", base64: "" });
      setPage(1);
      setStatus(userData?.status === "online" ? "Active" : "Inactive");
    }
  }, [userData?.id]);
  // useEffect(() => {
  //   userData && setStatus(userData?.status === "online" ? "Active" : "Inactive");
  // }, [userData]);

  const commonBody = {
    chat_id: chatHistory?.detail?.chat_id,
    activity_id: chatHistory?.detail?.activity_id,
    activity_type: chatHistory?.detail?.activity_type,
  };

  console.log('commonBody :', commonBody);

  const getChatHistory = async () => {
    try {
      let body = {
        page,
        user_id1: userData?.id,
        user_type1: "user",
        user_type2: userData?.user_type,
        chat_id : userData?.chat_id,
        activity_id : userData?.activity_id,
        activity_type : userData?.activity_type,
      };
      console.log("chathistory2222", body)
      if (discussionId) body.discussion_id = discussionId;
      const { data, code, message } = await chat_history(body);
      console.log('datamessage :', data);
      if (code == 1) {
        console.log("navodata", {
          chat_id: data?.detail?.chat_id,
          user_id: userData?.id
        })
        socket &&
          socket.emit("read_message", {
            chat_id: data?.detail?.chat_id,
            user_id: userData?.id
          });
        if (page === 1) setChatHistory(data);
        else
          setChatHistory({
            ...chatHistory,
            list: [...chatHistory?.list, ...data.list],
          });
      }
    } catch (err) {
      // TOAST_ERROR(err.message);
    }
  };

  const handleActivityStatus = async (status) => {
    try {
      await update_user_activity_status({
        user_id: USER_ID,
        status,
        user_type: USER_TYPE === "learner" ? "learner" : "user",
      });
    } catch (error) {
      // TOAST_ERROR(error.message);
    }
  };

  useEffect(() => {
    const socketInstance = io(SOCKET_URL, { query: { user_id: USER_ID } });
    // console.log('22222 :', USER_ID);
    setSocket(socketInstance);
    socketInstance.on("connect", (res) => {
      handleActivityStatus("online");
      console.log("Connected to server");
    });

    socketInstance.on("error", (error) => {
      console.error("Socket error:", error);
    });
    socketInstance.on("send_message", (newMessage) => {
      console.log("newMessage", newMessage)
      console.log('userDataqqqqqq :', userData);
      if ((userData?.id == newMessage?.data?.user_id || USER_ID == newMessage?.data?.user_id) && (userData?.activity_id == newMessage?.data?.activity_id)) {
        if((userData?.is_block == 1 && userData?.id == newMessage?.data?.send_push_id)){
          const updatedChatHistory = {
            ...chatHistory,
            list: [...chatHistory?.list, newMessage?.data],
          };
          setChatHistory(updatedChatHistory);
          getChatList();
        }else{
          if(userData?.is_block == 1){

          }else{
            const updatedChatHistory = {
              ...chatHistory,
              list: [...chatHistory?.list, newMessage?.data],
            };
            setChatHistory(updatedChatHistory);
            getChatList();
          }
         
        }
      
      }
    });
    socketInstance.on("user_status", (data) => {
      console.log("🚀 ~ socketInstance.on ~ data:", data);
      setStatus("Active");
    });
    socketInstance.on("start_typing", (data) => {
      console.log("typingusers111", typingusers)
      console.log("data111", data)

      if (!typingusers?.includes(data.chat_id)) {
        let typing_array = [...typingusers, data.chat_id];
        console.log('typing_array :', typing_array);
        setTypingUsers(typing_array);
        console.log("typingusers222", typing_array);
      }
      if (data.user_id == userData?.id) setStatus("typing...");
    });
    socketInstance.on("stop_typing", (data) => {
      console.log("data222", data)
      if (typingusers?.includes(data.chat_id)) {
        let typing_array = typingusers.filter(chatId => chatId !== data.chat_id);
        setTypingUsers(typing_array);
        console.log("typingusers222333", typing_array);
      }
      if (data.user_id == userData?.id) setStatus("Active");
    });

    return () => {
      if (socketInstance) {
        socketInstance.disconnect();
        handleActivityStatus("offline");
      }
    };
  }, [chatHistory?.list]);

  const deleteImage = () => {
    setImage({ blob: "", base64: "" });
    if (imageVal && imageVal.current) {
      imageVal.current.value = "";
      imageVal.current.type = "text";
      imageVal.current.type = "file";
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    console.log("message456", message)
    if (message || image.base64) {
      let attachment_file = null;
      if (image.base64) attachment_file = await uploadImageOnAWS(image.blob, "chat");
      console.log("🚀 ~ sendMessage ~ attachment_file:", attachment_file);
      console.log("imagebase64 :", image.base64)
      console.log("image.blob.type", image.blob.type)

      const newMessageBody = {
        ...commonBody,
        user_type: "user",
        type: image.base64 ? (
          image.blob.type.includes("image") ? "photo" : (
            image.blob.type.includes("video") ? "video" : (
              image.blob.type.includes("pdf") ? "pdf" : (
                image.blob.type.includes("xlsx") ? "excel" : image.blob.type.includes("spreadsheetml") ? "excel" : image.blob.type.includes("application/vnd.ms-excel") ? "excel" : image.blob.type.includes("doc")
                  ? "doc" : ("text")
              )
            )
          )
        ) : "text",
        message: message != '' ? message : attachment_file,
        // message: message != '' ? 'abcd' : 'efgh',

      };
      console.log('newMessageBody :', newMessageBody);

      socket.emit("send_message", newMessageBody);
      setMessage("");
      setImage({ blob: "", base64: "" });
      if (imageVal && imageVal.current) {
        imageVal.current.value = "";
        imageVal.current.type = "text";
        imageVal.current.type = "file";
      }
      handleChatList();
    }
  };

  // const handleMessage = async (e) => {
  //   const { files, value, type } = e.target;
  //   if (type === "text") setMessage(value);
  //   else if (type === "file") setImage({ blob: files[0], base64: await convertToBase64(files[0]) });
  // };

  // const handleMessage = async (e) => {
  //   const { files, value, type } = e.target;
  //   console.log('type :', type);
  //   if (type === "text") {
  //     setMessage(value);
  //   } else if (type === "file") {
  //     const file = files[0];
  //     console.log('file :', file.type);
  //     const extension = file.name.split('.').pop().toLowerCase();
  //     console.log('extension :', extension);
  //     if (extension === 'xlsx' || extension === 'pdf' || extension === 'doc' || extension === 'docx') {
  //       setImage({ blob: file, base64: await convertToBase64(file) });
  //     } else {
  //       // Handle unsupported file type
  //       console.error("Unsupported file type");
  //     }
  //   }
  // };

  const handleMessage = async (e) => {
    const { files, value, type } = e.target;
    console.log('type :', type);

    if (type === "text") {
      setMessage(value);
    } else if (type === "file") {
      const file = files[0];
      // console.log('file :', file.type);
      const extension = file?.name.split('.').pop().toLowerCase();
      console.log('extension :', extension);

      if (extension === 'xlsx' || extension === 'pdf' || extension === 'doc' || extension === 'docx' || extension === 'xls') {
        // Handle documents
        setImage({ blob: file, base64: await convertToBase64(file) });
      } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif') {
        // Handle images
        setImage({ blob: file, base64: await convertToBase64(file) });
      } else if (extension === 'mp4' || extension === 'avi' || extension === 'mov' || extension === 'wmv') {
        // Handle videos
        setImage({ blob: file, base64: await convertToBase64(file) });
      } else {
        // Handle unsupported file type
        console.error("Unsupported file type");
      }
    }
  };



  const handleScroll = () => {
    if (scrollToTopRef?.current?.scrollTop === 0 && chatHistory?.list?.length < chatHistory?.totalMessageCount) {
      setPage(page + 1);
    }
  };

  const scrollToBottom = () => scrollToBottomRef.current?.scrollIntoView({ behavior: "smooth" });

  useEffect(() => {
    if (message || page === 1) scrollToBottom();
  }, [chatHistory?.list, message, image.blob]);

  useEffect(() => {
    scrollToBottom();
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (socket && userData?.id) {
      if (message) socket.emit("start_typing", { ...commonBody, user_id: userData?.id });
      else socket.emit("stop_typing", { ...commonBody, user_id: userData?.id });
    }
  }, [message, userData?.id, socket]);

  const handlePinUnpin = async () => {
    try {
      const body = {
        ...commonBody,
        is_pin: chatHistory?.detail?.is_pin === 0 ? 1 : 0,
      };
      const { code, message } = await pin_chat(body);
      if (code == 1) {
        getChatHistory();
        typeof handleChatList === "function" && handleChatList();
      }
    } catch (err) {
      // TOAST_ERROR(err.message);
    }
  };

  const handleBlockUnblock = async () => {
    try {
      const body = {
        ...commonBody,
        is_block: chatHistory?.detail?.is_block === 0 ? 1 : 0,
      };
      const { code, message } = await block_chat(body);
      if (code == 1) {
        getChatHistory();
      }
    } catch (err) {
      // TOAST_ERROR(err.message);
    }
  };

  const downloadDocx = (url) => {
    let fileName = url.split("/");
    fileName = fileName[fileName.length - 1];

    const anchor = document.createElement("a");
    anchor.style.display = "none";
    anchor.href = url;
    anchor.target = "_blank";
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };


  const showMessages = useMemo(() => {
    dayjs.extend(utc);
    let showDividerIndex;
    return chatHistory?.list
      ?.sort((a, b) => a.chat_reply_id - b.chat_reply_id)
      ?.map((chat, i) => {
        const timestampToDate1 = dayjs.unix(chat.insert_datetime);
        const timestampToDate2 = dayjs.unix(chatHistory?.list?.[i - 1]?.insert_datetime);
        const time = timestampToDate1.format("h:mm A");
        const isToday = timestampToDate1.startOf("day").isSame(dayjs(), "day");
        const date = timestampToDate1.format("DD-MM-YYYY");
        if (isToday && !showDividerIndex) showDividerIndex = chat.chat_reply_id;
        else if (!timestampToDate1.startOf("day").isSame(timestampToDate2.startOf("day"))) showDividerIndex = chat.chat_reply_id;
        return (
          <Fragment key={chat.chat_reply_id}>
            {isToday && showDividerIndex === chat.chat_reply_id ? (
              <li>
                <div className="divider">
                  <h6>Today</h6>
                </div>
              </li>
            ) : showDividerIndex === chat.chat_reply_id ? (
              <li>
                <div className="divider">
                  <h6>{date}</h6>
                </div>
              </li>
            ) : (
              <></>
            )}
            {USER_ID == chat.user_id ? (
              <li className="repaly">
                <div className="d-flex justify-content-end">
                  <div>
                    {chat.type == "text" ? (
                      <p className="text-dif-black">{chat.message}</p>
                    ) : chat.type == "photo" ? (
                      <img width={100} src={chat.message} />
                    ) : chat.type == "video" ? (
                      <video controls width={150} src={chat.message} />
                    ) : chat.type == "pdf" ? (<>
                      {/* <p className="text-dif-black">pdf</p> */}

                      {/* <a href={chat.message} type="application/pdf" width="100%" height="600px" /> */}
                      <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="currentColor" style={{ color: "red", marginLeft: "20px" }} class="bi bi-filetype-pdf" viewBox="0 0 16 16"
                        onClick={() => downloadDocx("https://hlink-bhavinp-s3.s3.amazonaws.com/student_scholar/chat/" + chat.message)}>
                        <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                      </svg>
                    </>

                    ) : chat.type == "doc" ? (
                      // <a href={chat.message} download>{chat.message}</a>
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={30} height={30} style={{ marginLeft: "20px" }} viewBox="0 0 48 48" onClick={() => downloadDocx("https://hlink-bhavinp-s3.s3.amazonaws.com/student_scholar/chat/" + chat.message)}>
                        <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path><path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path><path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
                      </svg>
                    ) : chat.type == "excel" ? (
                      // <a href={chat.message} download>{chat.message}</a>
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={30} height={30} style={{ marginLeft: "20px" }} viewBox="0 0 48 48" onClick={() => downloadDocx("https://hlink-bhavinp-s3.s3.amazonaws.com/student_scholar/chat/" + chat.message)}>
                        <path fill="#169154" d="M29,6H15.744C14.781,6,14,6.781,14,7.744v7.259h15V6z"></path><path fill="#18482a" d="M14,33.054v7.202C14,41.219,14.781,42,15.743,42H29v-8.946H14z"></path><path fill="#0c8045" d="M14 15.003H29V24.005000000000003H14z"></path><path fill="#17472a" d="M14 24.005H29V33.055H14z"></path><g><path fill="#29c27f" d="M42.256,6H29v9.003h15V7.744C44,6.781,43.219,6,42.256,6z"></path><path fill="#27663f" d="M29,33.054V42h13.257C43.219,42,44,41.219,44,40.257v-7.202H29z"></path><path fill="#19ac65" d="M29 15.003H44V24.005000000000003H29z"></path><path fill="#129652" d="M29 24.005H44V33.055H29z"></path></g><path fill="#0c7238" d="M22.319,34H5.681C4.753,34,4,33.247,4,32.319V15.681C4,14.753,4.753,14,5.681,14h16.638 C23.247,14,24,14.753,24,15.681v16.638C24,33.247,23.247,34,22.319,34z"></path><path fill="#fff" d="M9.807 19L12.193 19 14.129 22.754 16.175 19 18.404 19 15.333 24 18.474 29 16.123 29 14.013 25.07 11.912 29 9.526 29 12.719 23.982z"></path>
                      </svg>
                    ) : null}

                    <span className="time text-start">{time}</span>
                  </div>
                  <img className="img-fluid chat_user ms-2 rounded-circle" src={chat.profile_image} alt="user img" />
                </div>
              </li>
            ) : (
              <li className="sender">
                <div className="d-flex align-items-start">
                  <img className="img-fluid chat_user me-2 rounded-circle" src={chat.learner_profile_image != null ? chat.learner_profile_image : chat.profile_image} alt="user img" />
                  <div>
                    {chat.type == "text" ? (
                      <p className="text-dif-black">{chat.message}</p>
                    ) : chat.type == "photo" ? (
                      <img width={100} src={chat.message} />
                    ) : chat.type == "video" ? (
                      <video controls width={150} src={chat.message} />
                    ) : chat.type == "pdf" ? (<>
                      {/* <p className="text-dif-black">pdf</p> */}

                      {/* <a href={chat.message} type="application/pdf" width="100%" height="600px" /> */}
                      <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="currentColor" style={{ color: "red", marginLeft: "20px" }} class="bi bi-filetype-pdf" viewBox="0 0 16 16"
                        onClick={() => downloadDocx(chat.message)}>
                        <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                      </svg>
                    </>

                    ) : chat.type == "doc" ? (
                      // <a href={chat.message} download>{chat.message}</a>
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={30} height={30} style={{ marginLeft: "20px" }} viewBox="0 0 48 48" onClick={() => downloadDocx(chat.message)}>
                        <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path><path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path><path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
                      </svg>
                    ) : chat.type == "excel" ? (
                      // <a href={chat.message} download>{chat.message}</a>
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={30} height={30} style={{ marginLeft: "20px" }} viewBox="0 0 48 48" onClick={() => downloadDocx(chat.message)}>
                        <path fill="#169154" d="M29,6H15.744C14.781,6,14,6.781,14,7.744v7.259h15V6z"></path><path fill="#18482a" d="M14,33.054v7.202C14,41.219,14.781,42,15.743,42H29v-8.946H14z"></path><path fill="#0c8045" d="M14 15.003H29V24.005000000000003H14z"></path><path fill="#17472a" d="M14 24.005H29V33.055H14z"></path><g><path fill="#29c27f" d="M42.256,6H29v9.003h15V7.744C44,6.781,43.219,6,42.256,6z"></path><path fill="#27663f" d="M29,33.054V42h13.257C43.219,42,44,41.219,44,40.257v-7.202H29z"></path><path fill="#19ac65" d="M29 15.003H44V24.005000000000003H29z"></path><path fill="#129652" d="M29 24.005H44V33.055H29z"></path></g><path fill="#0c7238" d="M22.319,34H5.681C4.753,34,4,33.247,4,32.319V15.681C4,14.753,4.753,14,5.681,14h16.638 C23.247,14,24,14.753,24,15.681v16.638C24,33.247,23.247,34,22.319,34z"></path><path fill="#fff" d="M9.807 19L12.193 19 14.129 22.754 16.175 19 18.404 19 15.333 24 18.474 29 16.123 29 14.013 25.07 11.912 29 9.526 29 12.719 23.982z"></path>
                      </svg>
                    ) : null}

                    <span className="time text-end">{time}</span>
                  </div>
                </div>
              </li>
            )}
          </Fragment>
        );
      });
  }, [chatHistory?.list]);

  return (
    <>
          <Helmet>
        <script>{`
        jQuery(document).ready(function() {

          // $(".chat-list a").click(function() {
          //     $(".chatbox").addClass('showbox');
          //     return false;
          // });
      
          $(".chat-icon").click(function() {
              $(".chatbox").removeClass('showbox');
          });
      
      
      });
        `}</script>
      </Helmet> 
    <div className="modal-dialog-scrollable">
      <div className="modal-content">
        <div className="msg-head">
          {discussionId ? (
            <span>Live Discussion Board</span>
          ) : (
            <div className="row">
              <div className="col-8">
                <div className="d-flex align-items-center">
                  <span className="chat-icon">
                    <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                    </svg>
                  </span>
                  <div className="flex-shrink-0 position-relative">
                    <img className="img-fluid chat_user rounded-circle" src={userData?.profile_image} alt="user img " />
                    {userData?.status == "online" && <span className="active" />}
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h3>
                      {userData.full_name}
                      <span style={{ marginLeft: '10px', fontSize: '0.8em', color: 'grey' }}>
                        ({userData?.timeZone})
                      </span>
                    </h3>                    
                    <p>{status}</p>
                  </div>
                  {userData?.category == "above_18" || userData?.user_type == "learner" || userData?.category == "below_18" ?
                    <div className="flex-grow-1">
                      <Link to="/teach-detail" state={{ id: userData?.activity_id, class: userData?.activity_type, type: "message", user_id: userData?.id }}>{userData?.activity_title}</Link>
                    </div>
                    : <></>}
                </div>
              </div>
              <div className="col-4">
                <ul className="moreoption">
                  <li className="navbar nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="fa fa-ellipsis-v" aria-hidden="true" />
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item pointer" onClick={handlePinUnpin}>
                          {chatHistory?.detail?.is_pin ? "Unpin" : "Pin"}
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item pointer" onClick={handleBlockUnblock}>
                          {chatHistory?.detail?.is_block ? "Unblock" : "Block"}
                        </a>
                      </li>
                      {/* <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li> */}
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
        <div className="modal-body" ref={scrollToTopRef} onScroll={handleScroll}>
          <div className="msg-body">
            <ul>
              {showMessages}
              {/* <li className="sender">
                <div className="d-flex align-items-start">
                  <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                  <div>
                    <p className="text-dif-black">
                      {" "}
                      Hi, I hope you are doing well, yesterday you have gave a pen This very nice, i am very happy for this.yesterday you have gave a
                      pen This very nice{" "}
                    </p>
                    <span className="time text-end">10:06 am</span>
                  </div>
                </div>
              </li>
              <li className="sender">
                <div className="d-flex align-items-start">
                  <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                  <div>
                    <p className="text-dif-black"> Hey, Are you there? </p>
                    <span className="time text-end">10:16 am</span>
                  </div>
                </div>
              </li>
              <li className="repaly">
                <div className="d-flex justify-content-end">
                  <div>
                    <p className="text-dif-black">yea I’m well, Thank you, i am very happy for this.yesterday you have gave a pen This very nice</p>
                    <span className="time text-start">10:20 am</span>
                  </div>
                  <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                </div>
              </li>
              <li className="sender">
                <div className="d-flex align-items-start">
                  <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                  <div>
                    <p className="text-dif-black"> Hey, Are you there? </p>
                    <span className="time text-end">10:26 am</span>
                  </div>
                </div>
              </li>
              <li className="sender">
                <div className="d-flex align-items-start">
                  <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                  <div>
                    <p className="text-dif-black"> Hey, Are you there? </p>
                    <span className="time text-end">10:32 am</span>
                  </div>
                </div>
              </li>
              <li className="repaly">
                <div className="d-flex justify-content-end">
                  <div>
                    <p className="text-dif-black">How are you?</p>
                    <span className="time text-start">10:35 am</span>
                  </div>
                  <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                </div>
              </li>
              <li>
                <div className="divider">
                  <h6>Today</h6>
                </div>
              </li>
              <li className="repaly">
                <div className="d-flex justify-content-end">
                  <div>
                    <p className="text-dif-black"> yes, tell me</p>
                    <span className="time text-start">10:36 am</span>
                  </div>
                  <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                </div>
              </li>
              <li className="repaly">
                <div className="d-flex justify-content-end">
                  <div>
                    <p className="text-dif-black">yes... on it</p>
                    <span className="time text-start">junt now</span>
                  </div>
                  <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                </div>
              </li> */}
            </ul>
            <div ref={scrollToBottomRef}></div>
          </div>
        </div>
        <div className="send-box">
          {/* {image.blob?.type?.includes("image") ? (
            <img src={image?.base64} width={50} />
          ) : image.blob?.type?.includes("video") ? (
            <video src={image?.base64} width={100} />
          ) : (
            <></>
          )} */}
          {/* {image.blob?.type?.includes("image") ? (
            // <img src={image?.base64} width={50} />
            <div className="image-container">
              <img src={image?.base64} width={50} />
              <button className="delete-button" onClick={() => deleteImage()}>X</button>
            </div>

          ) : image.blob?.type?.includes("video") ? (
            <video src={image?.base64} width={100} />
          ) : image.blob?.type?.includes("application/pdf") ? (
            // <embed src={image?.base64} width={200} height={200} type="application/pdf" />
            <div>Pdf File</div>
          ) : image.blob?.type?.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") || image.blob?.type?.includes("application/vnd.ms-excel") ? (
            <div>Excel File</div>
          ) : image.blob?.type?.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document") || image.blob?.type?.includes("application/msword") ? (
            <div>Word Document</div>
          ) : (
            <></>
          )} */}
          {image.blob?.type?.includes("image") ? (
            <div className="image-container">
              <img src={image?.base64} width={50} />
              <button
                className="delete-button"
                onClick={() => deleteImage()}
                style={{
                  background: 'none',
                  border: 'none',
                  marginLeft: '7px',
                  color: '#570861'
                }}
              >
                x
              </button>
            </div>
          ) : image.blob?.type?.includes("video") ? (
            <div>
              <video src={image?.base64} width={100} />
              <button
                className="delete-button"
                onClick={() => deleteImage()}
                style={{
                  background: 'none',
                  border: 'none',
                  marginLeft: '7px',
                  color: '#570861'
                }}
              >
                x
              </button>
            </div>
          ) : image.blob?.type?.includes("application/pdf") ? (
            <div>
              Pdf File
              <button
                className="delete-button"
                onClick={() => deleteImage()}
                style={{
                  background: 'none',
                  border: 'none',
                  marginLeft: '7px',
                  color: '#570861'
                }}
              >
                x
              </button>
            </div>
          ) : image.blob?.type?.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") || image.blob?.type?.includes("application/vnd.ms-excel") ? (
            <div>
              Excel File
              <button
                className="delete-button"
                onClick={() => deleteImage()}
                style={{
                  background: 'none',
                  border: 'none',
                  marginLeft: '7px',
                  color: '#570861'
                }}
              >
                x
              </button>
            </div>
          ) : image.blob?.type?.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document") || image.blob?.type?.includes("application/msword") ? (
            <div>
              Word Document
              <button
                className="delete-button"
                onClick={() => deleteImage()}
                style={{
                  background: 'none',
                  border: 'none',
                  marginLeft: '7px',
                  color: '#570861'
                }}
              >
                x
              </button>
            </div>
          ) : null}
          <form action className="position-relative">
            <input
              type="text"
              onChange={handleMessage}
              ref={inputRef}
              value={message}
              className="form-control rounded-pill"
              aria-label="message…"
              placeholder="Enter text"
            />
            <button onClick={sendMessage} className="send_icons btn">
              <i className="fa fa-paper-plane" aria-hidden="true" />
            </button>
            <div className="send-btns pointer">
              <div className="attach">
                <div className="button-wrapper position-relative ">
                  <span className="label">
                    <svg width={16} height={18} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.9297 2.94612C12.0719 2.08831 10.6797 2.08831 9.82187 2.94612L3.35313 9.41487C1.87305 10.8949 1.87305 13.2926 3.35313 14.7727C4.8332 16.2528 7.23086 16.2528 8.71094 14.7727L14.0547 9.42893C14.4379 9.04573 15.0637 9.04573 15.4469 9.42893C15.8301 9.81213 15.8301 10.4379 15.4469 10.8211L10.1031 16.1649C7.85312 18.4149 4.21094 18.4149 1.96094 16.1649C-0.289062 13.9149 -0.289062 10.2727 1.96094 8.02268L8.42969 1.55393C10.0574 -0.0738037 12.6941 -0.0738037 14.3219 1.55393C15.9496 3.18166 15.9496 5.81838 14.3219 7.44612L8.13437 13.6336C7.12891 14.6391 5.49766 14.6391 4.49219 13.6336C3.48672 12.6281 3.48672 10.9969 4.49219 9.99143L9.55469 4.92893C9.93789 4.54573 10.5637 4.54573 10.9469 4.92893C11.3301 5.31213 11.3301 5.93791 10.9469 6.32112L5.88438 11.3836C5.64883 11.6192 5.64883 12.0059 5.88438 12.2414C6.11992 12.477 6.50664 12.477 6.74219 12.2414L12.9297 6.05393C13.7875 5.19612 13.7875 3.80393 12.9297 2.94612Z"
                        fill="#131313"
                      />
                    </svg>
                  </span>
                  <input
                    type="file"
                    onChange={handleMessage}
                    ref={imageVal}
                    name="upload"
                    id="upload"
                    className="upload-box"
                    placeholder="Upload File"
                    aria-label="Upload File"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    </>
  );
};

export default CommonChatBox;
