import React, { useState } from "react";
import { Link } from "react-router-dom";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import PFooter_Dashboard from "../Pinc/PFooter_Dashboard";
import PHeader_Dashboard from "../Pinc/PHeader_Dashboard";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { TOAST_SUCCESS, TOAST_ERROR } from "../../../utils/common.service";

import { yupResolver } from "@hookform/resolvers/yup";
import { Change_Passsword } from "../../../api/apiHandler";
import BackToPrevious from "../../../components/backToPrevious";

export default function PChangePassword({isLoader}) {
  const navigate = useNavigate();

  const schema = yup.object().shape({
    old_password: yup.string().required("Please enter current password"),
    // .min(8, "Password must be 8 or more characters"),
    // .matches(/(?=.*[a-z])(?=.*[A-Z])\w+/, "Password should contain at least one uppercase and lowercase character")
    // .matches(/\d/, "Password should contain at least one number")
    // .matches(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, "Password should contain at least one special character"),

    new_password: yup
      .string()
      .required("Please enter password")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .min(8, "Password must contain 8 or more characters, at least one uppercase, one lowercase, one digit, and one special character")
      .matches(/^\S+$/, "Password cannot contain leading or trailing spaces")
      .matches(/(?=.*[a-z])(?=.*[A-Z])/, "Password must contain at least one uppercase and one lowercase letter")
      .matches(/\d/, "Password must contain at least one digit")
      .matches(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, "Password must contain at least one special character"),
    // .min(8, "Password must be 8 or more characters")
    // .matches(/(?=.*[a-z])(?=.*[A-Z])\w+/, "Password ahould contain at least one uppercase and lowercase character")
    // .matches(/\d/, "Password should contain at least one number")
    // .matches(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, "Password should contain at least one special character"),

    confirm_password: yup
      .string()
      .required("Please enter confirm password")
      .oneOf([yup.ref("new_password"), null], "Confirm password doesn't match with the New password "),
  });

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    watch,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  let onSubmit = (data) => {
    isLoader(true)
    // alert('gfy');
    let submitData = {
      old_password: data.old_password,
      new_password: data.new_password,
    };

    // localStorage.setItem("MAid", data.id);

    console.log("submitData", submitData);

    Change_Passsword(submitData).then((res) => {
      console.log("res :", res);
      // alert('hello')
      // return
      if (res.code == 1) {
        TOAST_SUCCESS(res.message);
        isLoader(false)
      } else {
        TOAST_ERROR(res.message);
        isLoader(false)
      }
    });
    reset();

    //  localStorage.setItem("step_1",JSON.stringify(data))
    // navigate("/dashboard");
  };

  const handleTogglePassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleTogglePassword1 = (e) => {
    e.preventDefault();
    setShowPassword1(!showPassword1);
  };

  const handleTogglePassword2 = (e) => {
    e.preventDefault();
    setShowPassword2(!showPassword2);
  };

  return (
    <>
      <JqueryLoad />
      <PHeader_Dashboard />

      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div>
              {/*start*/}
              <BackToPrevious />
              {/*end*/}
              {/*start*/}
              <div>
                <div className=" ">
                  {/*start*/}
                  <div className="mb-4">
                    <h2 className="mb-2 poppins text-capitalize fw-600">Please Change your Password here.</h2>
                  </div>
                  {/*end*/}
                  {/*start*/}
                  <div className="row mb-3 align-items-center">
                    <div className="col-lg-6">
                      <form onSubmit={handleSubmit(onSubmit)} className="custom_form mt-4" action id>
                        <div className="form-group mb-4">
                          <div className="d-flex justify-content-between align-items-center">
                            <label className="mb-2">Current Password</label>
                            <a href="#" onClick={handleTogglePassword}>
                              <span>
                                {showPassword ? (
                                  <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#666666">
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                                  </svg>
                                ) : (
                                  // <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">

                                  // </svg>

                                  <svg width={19} height={17} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M17.0194 0.881336L16.2835 0.145393C16.0755 -0.0625975 15.6915 -0.0305884 15.4515 0.257357L12.8913 2.80132C11.7392 2.30538 10.4754 2.06538 9.14731 2.06538C5.19519 2.08132 1.77141 4.38529 0.12329 7.69749C0.0272626 7.90549 0.0272626 8.16141 0.12329 8.3374C0.891223 9.90543 2.04329 11.2015 3.4833 12.1774L1.3873 14.3054C1.1473 14.5454 1.11529 14.9293 1.27534 15.1374L2.01128 15.8733C2.21928 16.0813 2.60326 16.0493 2.84326 15.7613L16.8912 1.71345C17.1952 1.47358 17.2272 1.08962 17.0192 0.881611L17.0194 0.881336ZM9.9953 5.71322C9.72329 5.6492 9.43534 5.56925 9.16332 5.56925C7.80327 5.56925 6.71538 6.65727 6.71538 8.01718C6.71538 8.28919 6.7794 8.57714 6.85936 8.84916L5.78724 9.90519C5.46728 9.34524 5.2913 8.72114 5.2913 8.01722C5.2913 5.88924 7.00332 4.17722 9.1313 4.17722C9.83536 4.17722 10.4593 4.3532 11.0193 4.67316L9.9953 5.71322Z"
                                      fill="#666666"
                                      fillOpacity="0.8"
                                    />
                                    <path
                                      d="M18.1714 7.69743C17.6115 6.57738 16.8754 5.56945 15.9635 4.75342L12.9875 7.69743V8.01738C12.9875 10.1454 11.2754 11.8574 9.14745 11.8574H8.8275L6.93951 13.7454C7.64357 13.8893 8.37952 13.9854 9.09952 13.9854C13.0516 13.9854 16.4754 11.6814 18.1235 8.35325C18.2675 8.12918 18.2675 7.90527 18.1715 7.69726L18.1714 7.69743Z"
                                      fill="#666666"
                                      fillOpacity="0.8"
                                    />
                                  </svg>
                                )}
                              </span>
                              {/* {showPassword ? 'Hide' : 'Show'} */}
                            </a>
                          </div>
                          <input
                            name="old_password"
                            {...register("old_password")}
                            type={showPassword ? "text" : "password"}
                            placeholder=""
                            className="form-control"
                          />
                          <p className="mt-2 font-bold col-red">
                            <span style={{ color: "red" }}>{errors.old_password?.message}</span>
                          </p>
                        </div>
                        <div className="form-group mb-4">
                          <div className="d-flex justify-content-between align-items-center">
                            <label className="mb-2">New Password</label>
                            <a href="#" onClick={handleTogglePassword1}>
                              <span>
                                {showPassword1 ? (
                                  <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#666666">
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                                  </svg>
                                ) : (
                                  // <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">

                                  // </svg>

                                  <svg width={19} height={17} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M17.0194 0.881336L16.2835 0.145393C16.0755 -0.0625975 15.6915 -0.0305884 15.4515 0.257357L12.8913 2.80132C11.7392 2.30538 10.4754 2.06538 9.14731 2.06538C5.19519 2.08132 1.77141 4.38529 0.12329 7.69749C0.0272626 7.90549 0.0272626 8.16141 0.12329 8.3374C0.891223 9.90543 2.04329 11.2015 3.4833 12.1774L1.3873 14.3054C1.1473 14.5454 1.11529 14.9293 1.27534 15.1374L2.01128 15.8733C2.21928 16.0813 2.60326 16.0493 2.84326 15.7613L16.8912 1.71345C17.1952 1.47358 17.2272 1.08962 17.0192 0.881611L17.0194 0.881336ZM9.9953 5.71322C9.72329 5.6492 9.43534 5.56925 9.16332 5.56925C7.80327 5.56925 6.71538 6.65727 6.71538 8.01718C6.71538 8.28919 6.7794 8.57714 6.85936 8.84916L5.78724 9.90519C5.46728 9.34524 5.2913 8.72114 5.2913 8.01722C5.2913 5.88924 7.00332 4.17722 9.1313 4.17722C9.83536 4.17722 10.4593 4.3532 11.0193 4.67316L9.9953 5.71322Z"
                                      fill="#666666"
                                      fillOpacity="0.8"
                                    />
                                    <path
                                      d="M18.1714 7.69743C17.6115 6.57738 16.8754 5.56945 15.9635 4.75342L12.9875 7.69743V8.01738C12.9875 10.1454 11.2754 11.8574 9.14745 11.8574H8.8275L6.93951 13.7454C7.64357 13.8893 8.37952 13.9854 9.09952 13.9854C13.0516 13.9854 16.4754 11.6814 18.1235 8.35325C18.2675 8.12918 18.2675 7.90527 18.1715 7.69726L18.1714 7.69743Z"
                                      fill="#666666"
                                      fillOpacity="0.8"
                                    />
                                  </svg>
                                )}
                              </span>
                              {/* {showPassword ? 'Hide' : 'Show'} */}
                            </a>
                          </div>
                          <input
                            name="new_password"
                            {...register("new_password")}
                            type={showPassword1 ? "text" : "password"}
                            placeholder=""
                            className="form-control"
                          />
                          <p className="mt-2 font-bold col-red">
                            <span style={{ color: "red" }}>{errors.new_password?.message}</span>
                          </p>
                        </div>
                        <div className="form-group mb-4">
                          <div className="d-flex justify-content-between align-items-center">
                            <label className="mb-2">Confirm New Password</label>
                            <a href="#" onClick={handleTogglePassword2}>
                              <span>
                                {showPassword2 ? (
                                  <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#666666">
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                                  </svg>
                                ) : (
                                  // <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">

                                  // </svg>

                                  <svg width={19} height={17} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M17.0194 0.881336L16.2835 0.145393C16.0755 -0.0625975 15.6915 -0.0305884 15.4515 0.257357L12.8913 2.80132C11.7392 2.30538 10.4754 2.06538 9.14731 2.06538C5.19519 2.08132 1.77141 4.38529 0.12329 7.69749C0.0272626 7.90549 0.0272626 8.16141 0.12329 8.3374C0.891223 9.90543 2.04329 11.2015 3.4833 12.1774L1.3873 14.3054C1.1473 14.5454 1.11529 14.9293 1.27534 15.1374L2.01128 15.8733C2.21928 16.0813 2.60326 16.0493 2.84326 15.7613L16.8912 1.71345C17.1952 1.47358 17.2272 1.08962 17.0192 0.881611L17.0194 0.881336ZM9.9953 5.71322C9.72329 5.6492 9.43534 5.56925 9.16332 5.56925C7.80327 5.56925 6.71538 6.65727 6.71538 8.01718C6.71538 8.28919 6.7794 8.57714 6.85936 8.84916L5.78724 9.90519C5.46728 9.34524 5.2913 8.72114 5.2913 8.01722C5.2913 5.88924 7.00332 4.17722 9.1313 4.17722C9.83536 4.17722 10.4593 4.3532 11.0193 4.67316L9.9953 5.71322Z"
                                      fill="#666666"
                                      fillOpacity="0.8"
                                    />
                                    <path
                                      d="M18.1714 7.69743C17.6115 6.57738 16.8754 5.56945 15.9635 4.75342L12.9875 7.69743V8.01738C12.9875 10.1454 11.2754 11.8574 9.14745 11.8574H8.8275L6.93951 13.7454C7.64357 13.8893 8.37952 13.9854 9.09952 13.9854C13.0516 13.9854 16.4754 11.6814 18.1235 8.35325C18.2675 8.12918 18.2675 7.90527 18.1715 7.69726L18.1714 7.69743Z"
                                      fill="#666666"
                                      fillOpacity="0.8"
                                    />
                                  </svg>
                                )}
                              </span>
                              {/* {showPassword ? 'Hide' : 'Show'} */}
                            </a>
                          </div>
                          <input
                            name="confirm_password"
                            {...register("confirm_password")}
                            type={showPassword2 ? "text" : "password"}
                            placeholder=""
                            className="form-control"
                          />
                          <p className="mt-2 font-bold col-red">
                            <span style={{ color: "red" }}>{errors.confirm_password?.message}</span>
                          </p>
                        </div>
                        <div className="row mt-5 mb-xl-0 mb-3">
                          <div className="col-xl-4 col-lg-4">
                            <div className="button">
                              {/* <Link to="/dashboard" className="btn-theme bg-yellow px-3 text-center d-block">Submit</Link> */}
                              <button type="submit" className="btn-theme bg-yellow text-center fw-600 d-block">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col-lg-6 mb-lg-0 mb-5 order-lg-last order-first text-lg-end text-center">
                      {/* <div class=" ms-lg-auto m-auto position-relative">
									<img src="./assets/images/parent_password.png" alt="kids-profile-demo">  
								</div> */}
                    </div>
                  </div>
                  {/*end*/}
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>
      <PFooter_Dashboard />
    </>
  );
}
