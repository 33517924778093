import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router-dom';
import { chat_list, total_lerner } from '../../../api/apiHandler';
import { TOAST_ERROR } from '../../../utils/common.service';
import useDebounce from '../../../utils/useDebounce';
import CommonChatBox from '../Message/commonChatBox';
import MessegesCommon from './MessegesCommon';
import CryptoJS from 'crypto-js';
var key = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNfTmDXHWVkQWdanYOm');
var iv = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNf');
export default function Messages() {

  const location = useLocation();
  // var id = location?.state;
  // console.log('Messagesid :', id);

  var after = useParams();
  var id = location?.state;
  console.log('idMessages :', id);

  if (location?.search) {
    const queryParams = new URLSearchParams(location?.search);
    const encryptedData = queryParams.get('data');
  
    // Define your key and IV (must be the same as used for encryption)
    // const key = CryptoJS.enc.Utf8.parse('your-key-here');
    // const iv = CryptoJS.enc.Utf8.parse('your-iv-here');
  
    // Decode and decrypt the data
    const decryptedData1 = CryptoJS.AES.decrypt(decodeURIComponent(encryptedData), key, { iv: iv }).toString(CryptoJS.enc.Utf8);
    console.log('decryptedData1 :', decryptedData1);
    const params = new URLSearchParams(decryptedData1);
    console.log('params :', params);

     id = {
      id: parseInt(params?.get('activityId'), 10),  // Assuming id is a number
      class: params?.get('typeClass'),
      type_class: params?.get('type'),
    };;
    console.log('idxyz1 :', id);
    var edit_class_id = id?.id;
    // setDataType(id?.type)
  }else if (location?.state) {
    console.log('idxyz :', id);
    var edit_class_id = id?.id;
  } else {
    // const { id } = useParams();
    // var type = location?.search?.split("=")[1];
    // console.log('type :', type);
    // var id = after
    // id.class = type
    // console.log('idtype :', id);
    // const queryParams = queryString.parse(location.search);

    // // Extract specific query parameters
    // const type = queryParams.type;

    // const typeClass = queryParams?.type_class;

    // var id = after
    // id.class = type
    // id.type_class = typeClass

    var decryptedData = CryptoJS.AES.decrypt(decodeURIComponent(location?.pathname?.split('/')[2]), key, { iv: iv }).toString(CryptoJS.enc.Utf8); 
    var descryptedJson = decryptedData.split('?')
    const queryParams = queryString.parse(descryptedJson[1]);
    console.log('queryParams :', queryParams);    
    const type = queryParams?.type;
    const typeClass = queryParams?.type_class;
    var id = {}
    var  edit_class_id = descryptedJson[0]
    id.id = descryptedJson[0]
    id.class = type
    id.type_class = typeClass
  }


  const [lList, setlList] = useState([]);
  console.log('lList :', lList);
  const [chatList, setChatList] = useState(null);
  const [userData, setUserData] = useState(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const debounce = useDebounce(search, 1000);

  console.log('lList :', lList);

  let learnerList = async () => {
    try {
      let send_data = {
        activity_id: id.id,
        activity_type: id.class
      }
      console.log('send_datalearnerList :', send_data);
      const response = await total_lerner(send_data);
      console.log('total_lerner :', response);
      if (response?.code == 1) {
        setlList(response?.data)
      } else {
        setlList([])
      }
    } catch (error) {
      console.log('error :', error);
    }
  }

  useEffect(() => {
    learnerList();
  }, [])

  const getTeacherData = (userData) => {
    console.log('userData4444 :', userData);
    if (userData?.type == "learner") {
      setUserData({ full_name: userData?.name, profile_image: userData?.profile_image, id: userData?.user_id, status: userData?.status, parent_id: userData?.parent_id, parent_profile_image: userData?.parent_profile_image, parent_name: userData?.parent_name, type: 'learner' })
    } else if (userData?.type == "user") {
      setUserData({ full_name: userData?.name, profile_image: userData?.profile_image, id: userData?.user_id, status: userData?.status, type: 'user' })
    }
  };

  const ParentChat = (userData) => {
    console.log('userData44443333 :', userData);
    setUserData({ full_name: userData?.full_name, profile_image: userData?.profile_image, id: userData?.id, status: userData?.status, type: 'user', learner_id : userData?.learner_id })
  }
  const handleChatList = () => {
    getChatList();
    // getPinnedChatList();
  };

  useEffect(() => {
    getChatList();
  }, [page, debounce]);

  const getChatList = async () => {
    try {
      const body = {
        page,
        user_type1: "user",
        is_pin: "0",
        search: debounce,
      };
      const { data, code, message } = await chat_list(body);
      console.log("getChatListdata", data)
      if (!userData) getTeacherData(data?.result?.[0]);
      if (code == 1) setChatList(data);
      else setChatList(null);
    } catch (err) {
      // TOAST_ERROR(err.message);
    }
  };

  const listItemRefs = useRef([]);

  var matchedUserId = id?.user_id;

  // console.log('matchedUserId :', matchedUserId);

  useEffect(() => {
    console.log("matchedUserId:", matchedUserId);
    console.log("lList:", lList);
    console.log("listItemRefs.current:", listItemRefs.current);

    // Ensure listItemRefs array is initialized with correct length
    listItemRefs.current = listItemRefs.current.slice(0, lList.length);
    console.log("listItemRefs.current after slice:", listItemRefs.current);

    const matchedIndex = lList.findIndex(learner => learner?.user_id == matchedUserId);
    console.log("matchedIndex:", matchedIndex);
    console.log("listItemRefs.current[matchedIndex]:", listItemRefs.current[matchedIndex]);

    if (matchedIndex !== -1 && listItemRefs.current[matchedIndex]) {
      console.log("Clicking on listItemRefs.current[matchedIndex]");
      listItemRefs.current[matchedIndex].click();
    }
  }, [matchedUserId, lList]);



  return (
    <>
      {/* <div className="tab-pane fade" id="pills-Messages" role="tabpanel" aria-labelledby="pills-Messages-tab">  */}
      {/*start-chatbox*/}

      <div className="row">
        <div className="col-xl-4">
          <div className="modal-dialog-scrollable">
            <div className="modal-content">
              <div className="msg-head">
                <span>Total Learners</span>
              </div>
              <div className="modal-body p-0">
                <div className="msg-body">
                  {/*first*/}
                  <div className="accordion border-radius-10 " id="accordionExample">
                    <div className="accordion-item border-0 border-bottom">
                      <h2 className="accordion-header poppins" id="headingOne">
                        <button className="accordion-button shadow-none fs-6 text-dif-black bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"><div className="usr_leart_imgs">
                          <span>E</span>
                        </div>
                          Enrolled (1)
                        </button>
                      </h2>
                      <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <div className="enroll_list">
                            <ul>
                              {/* {lList?.map((LearnerList, index) => {
                                return (
                                  <li ref={el => (listItemRefs.current[index] = el)} onClick={() => getTeacherData(LearnerList)} className="d-flex mt-0 justify-content-between align-items-center">
                                    <div className="enroll_left d-flex align-items-center">
                                      <div className>
                                        <img src={LearnerList.profile_image} alt="kids-profile" />
                                      </div>
                                      <div className="ms-3">
                                        <span>{LearnerList.name}</span>
                                        <span className="ms-2 enroll_flag">
                                          <img src="./assets/images/country_flag.png" alt="country_flag" />
                                        </span>
                                      </div>
                                    </div>
                                    {LearnerList.learner_age <= 18 ?
                                      <div className="enroll_age">
                                        <span>Age : {LearnerList.learner_age}</span>
                                      </div>
                                      : <></>}
                                  </li>
                                )
                              })} */}
                              {lList?.map((LearnerList, index) => (
                                <li
                                  ref={el => (listItemRefs.current[index] = el)}
                                  onClick={() => getTeacherData(LearnerList)}
                                  key={index}
                                  className="d-flex mt-0 justify-content-between align-items-center"
                                >
                                  <div className="enroll_left d-flex align-items-center">
                                    <div className="">
                                      <img src={LearnerList.profile_image} alt="kids-profile" />
                                    </div>
                                    <div className="ms-3">
                                      <span>{LearnerList.name}</span>
                                      <span className="ms-2 enroll_flag">
                                        <img src="./assets/images/country_flag.png" alt="country_flag" />
                                      </span>
                                    </div>
                                  </div>
                                  {LearnerList.learner_age <= 18 && (
                                    <div className="enroll_age">
                                      <span>Age : {LearnerList.learner_age}</span>
                                    </div>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*second*/}
                  <div className="accordion border-radius-10 " id="accordionExample">
                    <div className="accordion-item border-0 border-bottom">
                      <h2 className="accordion-header poppins" id="headingOne">
                        <button className="accordion-button shadow-none fs-6 text-dif-black bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo"><div className="usr_leart_imgs">
                          <span>P</span>
                        </div>
                          Past Enrolled (1)
                        </button>
                      </h2>
                      <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <div className="enroll_list">
                            <ul>
                              <li className="d-flex mt-0 justify-content-between align-items-center">
                                <div className="enroll_left d-flex align-items-center">
                                  <div className>
                                    <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                                  </div>
                                  <div className="ms-3">
                                    <span>Mark joseph</span>
                                    <span className="ms-2 enroll_flag">
                                      <img src="./assets/images/country_flag.png" alt="country_flag" />
                                    </span>
                                  </div>
                                </div>
                                <div className="enroll_age">
                                  <span>Age : 8</span>
                                </div>
                              </li>
                              <li className="d-flex mt-0 justify-content-between align-items-center">
                                <div className="enroll_left d-flex align-items-center">
                                  <div className>
                                    <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                                  </div>
                                  <div className="ms-3">
                                    <span>Mark joseph</span>
                                    <span className="ms-2 enroll_flag">
                                      <img src="./assets/images/country_flag.png" alt="country_flag" />
                                    </span>
                                  </div>
                                </div>
                                <div className="enroll_age">
                                  <span>Age : 8</span>
                                </div>
                              </li>
                              <li className="d-flex mt-0 justify-content-between align-items-center">
                                <div className="enroll_left d-flex align-items-center">
                                  <div className>
                                    <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                                  </div>
                                  <div className="ms-3">
                                    <span>Mark joseph</span>
                                    <span className="ms-2 enroll_flag">
                                      <img src="./assets/images/country_flag.png" alt="country_flag" />
                                    </span>
                                  </div>
                                </div>
                                <div className="enroll_age">
                                  <span>Age : 8</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*three*/}
                  {/* <div className="accordion border-radius-10 " id="accordionExample">
                    <div className="accordion-item border-0 border-bottom">
                      <h2 className="accordion-header poppins" id="headingOne">
                        <button className="accordion-button shadow-none fs-6 text-dif-black bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree"><div className="usr_leart_imgs">
                          <span>W</span>
                        </div>
                          Withdrawn Enrollment(1)
                        </button>
                      </h2>
                      <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <div className="enroll_list">
                            <ul>
                              <li className="d-flex mt-0 justify-content-between align-items-center">
                                <div className="enroll_left d-flex align-items-center">
                                  <div className>
                                    <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                                  </div>
                                  <div className="ms-3">
                                    <span>Mark joseph</span>
                                    <span className="ms-2 enroll_flag">
                                      <img src="./assets/images/country_flag.png" alt="country_flag" />
                                    </span>
                                  </div>
                                </div>
                                <div className="enroll_age">
                                  <span>Age : 8</span>
                                </div>
                              </li>
                              <li className="d-flex mt-0 justify-content-between align-items-center">
                                <div className="enroll_left d-flex align-items-center">
                                  <div className>
                                    <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                                  </div>
                                  <div className="ms-3">
                                    <span>Mark joseph</span>
                                    <span className="ms-2 enroll_flag">
                                      <img src="./assets/images/country_flag.png" alt="country_flag" />
                                    </span>
                                  </div>
                                </div>
                                <div className="enroll_age">
                                  <span>Age : 8</span>
                                </div>
                              </li>
                              <li className="d-flex mt-0 justify-content-between align-items-center">
                                <div className="enroll_left d-flex align-items-center">
                                  <div className>
                                    <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                                  </div>
                                  <div className="ms-3">
                                    <span>Mark joseph</span>
                                    <span className="ms-2 enroll_flag">
                                      <img src="./assets/images/country_flag.png" alt="country_flag" />
                                    </span>
                                  </div>
                                </div>
                                <div className="enroll_age">
                                  <span>Age : 8</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-8">
          <div className="mb-4">
            {userData && (
              <div className="chatbox">
                <MessegesCommon userData={userData} callFunction={ParentChat} handleChatList={handleChatList} id={id} />
                {/* <div className="modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="msg-head">
                      <div className="row">
                        <div className="col-12">
                          <div className="d-sm-flex flex-wrap align-items-center justify-content-between">
                            <div className="d-flex mb-sm-0 mb-3 align-items-center">
                              <div className="flex-shrink-0 position-relative">
                                <img className="img-fluid chat_user" src="./assets/images/user/user.png" alt="user img " />
                                <span className="active" />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <h3>Killan James</h3>
                                <p>Active Now</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0 position-relative">
                                <img className="img-fluid chat_user" src="./assets/images/user/user2.png" alt="user img " />
                                <span className="active" />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <h3>Parents Name</h3>
                                <p>Send A Message Now</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-body">
                      <div className="msg-body">
                        <ul>
                          <li className="sender">
                            <div className="d-flex align-items-start">
                              <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                              <div>
                                <p className="text-dif-black"> Hi, I hope you are doing well, yesterday you have gave a pen This very nice, i am very happy for this.yesterday you have gave a pen This very nice </p>
                                <span className="time text-end">10:06 am</span>
                              </div>
                            </div>
                          </li>
                          <li className="sender">
                            <div className="d-flex align-items-start">
                              <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                              <div>
                                <p className="text-dif-black"> Hey, Are you there? </p>
                                <span className="time text-end">10:16 am</span>
                              </div>
                            </div>
                          </li>
                          <li className="repaly">
                            <div className="d-flex justify-content-end">
                              <div className>
                                <p className="text-dif-black">yea I’m well, Thank you, i am very happy for this.yesterday you have gave a pen This very nice</p>
                                <span className="time text-start">10:20 am</span>
                              </div>
                              <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                            </div>
                          </li>
                          <li className="sender">
                            <div className="d-flex align-items-start">
                              <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                              <div>
                                <p className="text-dif-black"> Hey, Are you there? </p>
                                <span className="time text-end">10:26 am</span>
                              </div>
                            </div>
                          </li>
                          <li className="sender">
                            <div className="d-flex align-items-start">
                              <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                              <div>
                                <p className="text-dif-black"> Hey, Are you there? </p>
                                <span className="time text-end">10:32 am</span>
                              </div>
                            </div>
                          </li>
                          <li className="repaly">
                            <div className="d-flex justify-content-end">
                              <div className>
                                <p className="text-dif-black">How are you?</p>
                                <span className="time text-start">10:35 am</span>
                              </div>
                              <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                            </div>
                          </li>
                          <li>
                            <div className="divider">
                              <h6>Today</h6>
                            </div>
                          </li>
                          <li className="repaly">
                            <div className="d-flex justify-content-end">
                              <div className>
                                <p className="text-dif-black"> yes, tell me</p>
                                <span className="time text-start">10:36 am</span>
                              </div>
                              <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                            </div>
                          </li>
                          <li className="repaly">
                            <div className="d-flex justify-content-end">
                              <div className>
                                <p className="text-dif-black">yes... on it</p>
                                <span className="time text-start">junt now</span>
                              </div>
                              <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="send-box">
                      <form action className="position-relative">
                        <input type="text" className="form-control rounded-pill" aria-label="message…" placeholder="Write message…" />
                        <a href="#" className="send_icons"><i className="fa fa-paper-plane" aria-hidden="true" /></a>
                        <div className="send-btns">
                          <div className="attach">
                            <div className="button-wrapper position-relative">
                              <span className="label">
                                <svg width={16} height={18} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M12.9297 2.94612C12.0719 2.08831 10.6797 2.08831 9.82187 2.94612L3.35313 9.41487C1.87305 10.8949 1.87305 13.2926 3.35313 14.7727C4.8332 16.2528 7.23086 16.2528 8.71094 14.7727L14.0547 9.42893C14.4379 9.04573 15.0637 9.04573 15.4469 9.42893C15.8301 9.81213 15.8301 10.4379 15.4469 10.8211L10.1031 16.1649C7.85312 18.4149 4.21094 18.4149 1.96094 16.1649C-0.289062 13.9149 -0.289062 10.2727 1.96094 8.02268L8.42969 1.55393C10.0574 -0.0738037 12.6941 -0.0738037 14.3219 1.55393C15.9496 3.18166 15.9496 5.81838 14.3219 7.44612L8.13437 13.6336C7.12891 14.6391 5.49766 14.6391 4.49219 13.6336C3.48672 12.6281 3.48672 10.9969 4.49219 9.99143L9.55469 4.92893C9.93789 4.54573 10.5637 4.54573 10.9469 4.92893C11.3301 5.31213 11.3301 5.93791 10.9469 6.32112L5.88438 11.3836C5.64883 11.6192 5.64883 12.0059 5.88438 12.2414C6.11992 12.477 6.50664 12.477 6.74219 12.2414L12.9297 6.05393C13.7875 5.19612 13.7875 3.80393 12.9297 2.94612Z" fill="#131313" />
                                </svg>
                              </span>
                              <input type="file" name="upload" id="upload" className="upload-box" placeholder="Upload File" aria-label="Upload File" />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div> */}
              </div>
            )}
          </div>
          {/* <div className="row justify-content-end">
            <div className="col-xl-4">
              <div className="accordion border-radius-10 " id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header poppins" id="headingOne">
                    <button className="accordion-button fs-5 text-dif-black bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">New Notifications</button>
                  </h2>
                  <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <div className="list_notification">
                        <ul  >
                          <li className="d-flex align-items-center mb-4">
                            <div className="notif_left d-flex align-items-center">
                              <div className="noti_img bg-pink position-relative">
                                <span className="text-uppercase text-danger">A</span>
                                <span className="notifi_logs">
                                  <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                  </svg>
                                </span>
                              </div>
                              <p className="ms-3 noti_text">Assignments Due</p>
                            </div>
                            <span className="noti_timer">10.12 PM</span>
                          </li>
                          <li className="d-flex align-items-center mb-4">
                            <div className="notif_left d-flex align-items-center">
                              <div className="noti_img bg-diff-yellow-op-1 position-relative">
                                <span className="text-uppercase text-yellow">q</span>
                                <span className="notifi_logs">
                                  <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                  </svg>
                                </span>
                              </div>
                              <p className="ms-3 noti_text">New assignments.</p>
                            </div>
                            <span className="noti_timer">10.12 PM</span>
                          </li>
                          <li className="d-flex align-items-center mb-4">
                            <div className="notif_left d-flex align-items-center">
                              <div className="noti_img bg-sky-blue-op-1 position-relative">
                                <span className="text-uppercase text-blue">e</span>
                                <span className="notifi_logs">
                                  <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                  </svg>
                                </span>
                              </div>
                              <p className="ms-3 noti_text">Any upcoming events</p>
                            </div>
                            <span className="noti_timer">10.12 PM</span>
                          </li>
                          <li className="d-flex align-items-center mb-4">
                            <div className="notif_left d-flex align-items-center">
                              <div className="noti_img bg-sky-blue-op-1 position-relative">
                                <span className="text-uppercase text-blue">e</span>
                                <span className="notifi_logs">
                                  <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                  </svg>
                                </span>
                              </div>
                              <p className="ms-3 noti_text">Any upcoming events</p>
                            </div>
                            <span className="noti_timer">10.12 PM</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/*end-chatbox*/}
      {/* </div> */}
    </>
  )
}
