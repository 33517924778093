import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import JqueryLoad from '../../../../JqueryLoad/JqueryLoad'
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { classroom_edit_lesson, classroom_lesson_list } from '../../../../api/apiHandler';
import { convertToBase64, TOAST_ERROR, TOAST_SUCCESS } from '../../../../utils/common.service';
import { uploadImageOnAWS } from '../../../aws/Service';
import OHeader_Dashboard from '../../OInclude/OHeader_Dashboard';
import OFooter_Dashboard from '../../OInclude/OFooter_Dashboard';


export default function OEditLesson() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const location = useLocation();
    const navigate = useNavigate();

    var edit_id = location?.state;

    const schema = yup.object().shape({
        lesson_topic: yup.string().required("Lesson topic is required"),

        // lesson_description: yup.string().required("Lesson description is required"),

    });

    const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const [lessonData, setLessonData] = useState([])
    const [attachmentData, setAttachmentData] = useState([])
    var [classTitleLength, setclassTitleLength] = useState(0);

    const classroom_lessonlist = () => {

        classroom_lesson_list({ classroom_id: edit_id }).then((response) => {

            if (response?.code == 1) {
                setclassTitleLength(response?.data?.[0]?.lession_topic.length)
                reset({
                    // lesson_topic: response?.data?.[0]?.lession_topic,
                    // lesson_description: response?.data?.[0]?.description,
                    hours: response?.data?.[0]?.duration?.split(' ')?.[0],
                    minutes: response?.data?.[0]?.duration?.split(' ')?.[2],
                    attachments_data: response?.data?.[0]?.attachments

                });
                setLessonData(response?.data?.[0])
                setAttachmentData(response?.data?.[0]?.lessiondata)
            } else {
                 
            }
        });
    }

    let classTitleData = (e) => {

        setclassTitleLength(0)
    }

    useEffect(() => {
        classroom_lessonlist();
    }, [])

    const [lessonDetails, setLessonDetails] = useState([{ description: '', attachment: null }]);

    const handleAddDescription = () => {
        setAttachmentData([...attachmentData, { description: '', attachment: null }]);
    };


    const [profileImageState, setProfileImageState] = useState('')

    const [AttachmentData1, setAttachmentData1] = useState({})

    const handleImageChange = async (image, Attachment_Index, fileIndex, type) => {
        if (image != null) {
            TOAST_SUCCESS("Your attachment has been uploaded!");
        }
        var str = "attachment_" + Attachment_Index
        var temp_arr = AttachmentData1
        if (temp_arr[str]) {
            var image_name = await uploadImageOnAWS(image, "lesson_attachments");

            temp_arr[str][fileIndex] = { attachments: image_name, attachment_type: type };
        } else {
            var image_name = await uploadImageOnAWS(image, "lesson_attachments");

            temp_arr[str] = ['', '', '', '', '', '']
            temp_arr[str][fileIndex] = { attachments: image_name, attachment_type: type }

        }
        setAttachmentData1(temp_arr)
        setValue("attachments_data", setAttachmentData1);

        const bufferImage = await convertToBase64(image);
        setProfileImageState(bufferImage);
        clearErrors("attachments_data");
    }

    let onSubmit = async (data) => {

        // let image = data?.attachments_data
        // if (image instanceof Blob) image = await uploadImageOnAWS(image, "class_image");

        // var image = await uploadImageOnAWS(data?.attachments_data, "class_image");

        let edit_lesson = {
            classroom_id: edit_id,
            lession_topic: data.lesson_topic,
            // description: data.lesson_description,
            // attachments: image,
            //   duration: data.hours + " " + data.minutes,
            duration: data.hours + " Hour" + (data.hours != 1 ? "s " : " ") + data.minutes + " Minutes",

        }
        var description_arr = [];
        attachmentData.map((lesson, index) => {
            var temp_obj = {}

            var temp_str = "lesson_description_" + index;
            var attach_str = "attachment_" + index
            temp_obj.description = data[temp_str]

            if (AttachmentData1[attach_str]) {
                var originalArray = AttachmentData1[attach_str];

                // Remove empty strings from the array
                var newArray = originalArray.filter(item => item !== '');
                temp_obj.attachments = newArray
            } else {
                temp_obj.attachments = []
            }
            description_arr.push(temp_obj);

        });
        // coupon_data.description = {}
        edit_lesson.lessiondata = description_arr

        classroom_edit_lesson(edit_lesson).then((response) => {

            if (response?.code == 1) {
                TOAST_SUCCESS(response?.message);
                navigate('/organization-tech-detail', {
                    state: { id: lessonData.activity_id, class: lessonData.activity_type }
                })
            } else {
                TOAST_ERROR(response?.message);
            }
        });
        reset();

    }
    return (
        <>
            <JqueryLoad />
            <OHeader_Dashboard />
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="#" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <div className="class-content">
                        <div className>
                            {/*start*/}
                            <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                                <span>
                                    <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                                    </svg>
                                    <span  className="ms-2">Back</span>
                                </span></a>
                            {/*end*/}
                            {/*start*/}
                            <div className>
                                <div className=" ">
                                    {/*start*/}
                                    <div className="row">
                                        <div className="col-xl-8">
                                            {/*start*/}
                                            <div className="mb-4">
                                                <h2 className="mb-2 poppins fw-bold">Edit Lesson Information</h2>
                                            </div>
                                            {/*end*/}
                                            {/*start*/}
                                            <form onSubmit={handleSubmit(onSubmit)} className="custom_form">
                                                <div className="form-group mb-4">
                                                    <label className="form-label">Add Lesson Topic</label>
                                                    <div className="position-relative class_title">
                                                        <input type="text" {...register("lesson_topic")} maxLength={50} name="lesson_topic" className="form-control" placeholder id defaultValue={lessonData.lession_topic} onKeyUp={(e) => classTitleData(e.target.value)} />
                                                        <span className="position-absolute word_count">
                                                            {Math.max((0, 50 - (classTitleLength + ((watch("lesson_topic")?.split('')?.length ?? 0)))))} Words
                                                        </span>

                                                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.lesson_topic?.message}</span></p>
                                                    </div>
                                                </div>
                                                {attachmentData?.map((des_data, index) => {
                                                    return (<>
                                                        <div className="form-group mb-4">
                                                            <label className="form-label">Add Lesson Description</label>
                                                            <textarea className="form-control"      {...register(`lesson_description_${index}`)}
                                                                name={`lesson_description_${index}`} rows={7} id>{des_data.description}</textarea>
                                                            {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.lesson_description?.message}</span></p> */}

                                                        </div>
                                                        <div className="form-group mb-4 attach_box">
                                                            <label className="form-label">Add Attachments</label>
                                                            <div className="position-relative">
                                                                <textarea type="text" className="form-control" name rows={7} />
                                                                <div className="att_icon_list position-absolute">
                                                                    <ul className="d-flex align-items-center justify-content-end">
                                                                        <li>
                                                                            <label htmlFor={`attach1_${index}`}>
                                                                                <a>
                                                                                    <img src="./assets/images/attach1.png" alt="attach1" />
                                                                                </a>
                                                                            </label>
                                                                            <input type="file" id={`attach1_${index}`} onInput={(e) => {
                                                                                const file = e.target.files[0];
                                                                                const fileType = file.type.startsWith('image') ? 'image' : 'video';
                                                                                handleImageChange(file, index, 0, fileType);
                                                                            }} {...register("attachments")} accept="image/*,video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                                        </li>
                                                                        <li>
                                                                            <label htmlFor={`attach2_${index}`}>
                                                                                <a >
                                                                                    <img src="./assets/images/attach2.png" alt="attach2" />
                                                                                </a>
                                                                            </label>
                                                                            <input type="file" id={`attach2_${index}`} {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], index, 1, "video")} accept="video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                                        </li>
                                                                        <li>
                                                                            <label htmlFor={`attach3_${index}`}>
                                                                                <a>
                                                                                    <img src="./assets/images/attach3.png" alt="attach3" />
                                                                                </a>
                                                                                <input type="file" id={`attach3_${index}`}  {...register("attachments")} accept="image/*" onInput={(e) => handleImageChange(e.target.files[0], index, 2, "image")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                            </label>
                                                                        </li>
                                                                        <li>
                                                                            <label htmlFor={`attach4_${index}`}>
                                                                                <a>
                                                                                    <img src="./assets/images/attach4.png" alt="attach4" />
                                                                                </a>
                                                                                <input type="file" id={`attach4_${index}`} {...register("attachments")} accept=".docx" onInput={(e) => handleImageChange(e.target.files[0], index, 3, "docx")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                            </label>
                                                                        </li>
                                                                        <li>

                                                                            <label htmlFor={`attach5_${index}`}>

                                                                                <a >
                                                                                    <img src="./assets/images/attach5.png" alt="attach5" />
                                                                                </a>
                                                                                <input type="file" id={`attach5_${index}`} {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], index, 4, "xlsx")} accept=".xlsx" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                                            </label>
                                                                        </li>
                                                                        <li>
                                                                            <label htmlFor={`attach6_${index}`}>

                                                                                <a >
                                                                                    <img src="./assets/images/attach6.png" alt="attach6" />
                                                                                </a>
                                                                                <input type="file" id={`attach6_${index}`} {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], index, 5, "pdf")} accept=".pdf" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                            </label>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-content-end mt-3">
                                                                {/* <a href="#" className="text-decoration-underline text-purple">+ Add another Description</a> */}
                                                            </div>
                                                        </div> </>)
                                                })}
                                                <div className="d-flex justify-content-end mt-3">
                                                    <a className="text-decoration-underline text-purple" onClick={handleAddDescription}>
                                                        + Add another Description
                                                    </a>
                                                </div>

                                                <h3 className="mb-3">Lesson duration</h3>
                                                <div className="d-md-flex mb-4 align-items-center">
                                                    <p className="fs-6  mb-md-0 mb-3">Expected Time Spent On Lesson Hours </p>
                                                    <div className="d-md-flex ms-3">
                                                        <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                                                            <select className="form-select mb-md-0 mb-3 w-auto pe-5 rounded-pill" {...register("hours")} name="hours" aria-label="Default select example">
                                                                <option value={0}>0 </option>
                                                                <option value={1}>1 </option>
                                                                <option value={2}>2 </option>
                                                                <option value={3}>3 </option>
                                                                <option value={4}>4 </option>
                                                            </select>
                                                            <span className="ms-3">Minutes</span>
                                                        </div>
                                                        <div className="ms-md-3">
                                                            <select className="form-select w-auto pe-5 rounded-pill" {...register("minutes")} name="minutes" aria-label="Default select example">
                                                                <option value={0}>0 </option>
                                                                <option value={5}>5 </option>
                                                                <option value={10}>10 </option>
                                                                <option value={15}>15 </option>
                                                                <option value={20}>20 </option>
                                                                <option value={25}>25 </option>
                                                                <option value={30}>30 </option>
                                                                <option value={35}>35 </option>
                                                                <option value={40}>40 </option>
                                                                <option value={45}>45 </option>
                                                                <option value={45}>50 </option>
                                                                <option value={45}>55 </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="enviorment_div mb-5 align-items-center d-md-flex">
                                                    <div className="env_img">
                                                        <img src="./assets/images/enviorment.png" alt="enviorment" />
                                                    </div>
                                                    <div className="env_text">
                                                        <p className="text-500">Help save the envionrment. Cut down on printing. Instead please use Google docs, sheets and slides which is freely available to you.</p>
                                                    </div>
                                                </div>
                                                <div className="button">
                                                    {/* <Link to="/tech_detail" className="btn-theme bg-yellow d-inline-block fw-600">Create Lesson</Link> */}
                                                    <button className="btn-theme bg-yellow text-center fw-600 d-block" >
                                                        Edit Lesson
                                                    </button>
                                                </div>
                                            </form>
                                            {/*end*/}
                                        </div>
                                    </div>
                                    {/*end*/}
                                </div>
                            </div>
                            {/*end*/}
                        </div>
                    </div>
                </div>
            </main>
            <OFooter_Dashboard />
        </>
    )
}
