import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs';
import TFooter_Dashboard from '../../Include/TFooter_Dashboard'
import THeader_Dashboard from '../../Include/THeader_Dashboard'
import AddClass from './AddClass'
import AddClub from './AddClub'
import AddCompetitation from './AddCompetitation'
import AddQuiz from './AddQuiz'
import Filter from './Filter'
import Header_class from './Header_class'

export default function Teacher_Class({isLoader}) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
    const handleStepChange = (newStep) => setStep(newStep);
    const navigate = useNavigate();

    // const location = useLocation();
    const [classType,setclassType] = useState('')
    const [classPrice,setclassPrice] = useState('')
    const [classAge,setclassAge] = useState('')
    const [classLength,setclassLength] = useState('')
    const [classList,setClassList] = useState('')

    const [classLanguage,setclassLanguage] = useState('')
    // const [selectedRadio, setRadioButtonCheck] = useState('Today');
    const [DateApply,setDateApply] = useState('')
    const [DateApplydata,setDateApplydata] = useState('')
    const [SortData,setSortData] = useState('Relevance')
    const [selectedDaysSend, setselectedDaysSend] = useState([]);

    const [StartTimeSend, setStartTimeSend] = useState('');
    const [EndTimeSend, setEndTimeSend] = useState('');
    const [SearchTab, setSearchTab] = useState('');
    const [CategoriesData,setCategoriesData] = useState("Academic")
    const [subCategoriesData,setSubCategoriesData] = useState([])  
    const [CountryName, setCountryName] = useState('');

    var [stateList, setstateList] = useState('');

    const currentDate = dayjs();

    // Set the default value to the current year, month, and day
    const defaultValue = {
        year: currentDate.year(),
        month: currentDate.month() + 1,  // Month in Day.js starts from 0
        day: currentDate.date(),
    };
    const [selectedDay, setSelectedDay] = useState(defaultValue);

    const [step, setStep] = useState(parseInt(localStorage.getItem("Class_Step", 1)) ? parseInt(localStorage.getItem("Class_Step", 1)) : 1);
  
    useEffect(() => {
      localStorage.setItem("Class_Step", step)
  }, [step,parseInt(localStorage.getItem("Class_Step",1))]);
  
  // console.log('parseInt(localStorage.getItem("Class_Step",1)) :', localStorage.getItem("Class_Step",1));
    const renderStep = () => {
      switch (localStorage.getItem("Class_Step",1) ?parseInt(localStorage.getItem("Class_Step",1)): 1) {
        case 1:
          return (
            <AddClass SearchTab={SearchTab} classList={classList} classType={classType} classPrice={classPrice} classAge={classAge} classLength={classLength} CategoriesData={CategoriesData} subCategoriesData={subCategoriesData} selectedDay={selectedDay} DateApply={DateApply} DateApplydata={DateApplydata} SortData={SortData} selectedDaysSend={selectedDaysSend} StartTimeSend={StartTimeSend} EndTimeSend={EndTimeSend} isLoader={isLoader}/>
          );
          case 2:
          return (
            <AddClub SearchTab={SearchTab} classList={classList} classType={classType} classPrice={classPrice} classAge={classAge} classLength={classLength} CategoriesData={CategoriesData} subCategoriesData={subCategoriesData} selectedDay={selectedDay} DateApply={DateApply} DateApplydata={DateApplydata} SortData={SortData} selectedDaysSend={selectedDaysSend} StartTimeSend={StartTimeSend} EndTimeSend={EndTimeSend}  isLoader={isLoader}/>
          );
          case 3:
            return (
              <AddQuiz SearchTab={SearchTab} classList={classList} classType={classType} classPrice={classPrice} classAge={classAge} classLength={classLength} CategoriesData={CategoriesData} subCategoriesData={subCategoriesData} selectedDay={selectedDay} DateApply={DateApply} DateApplydata={DateApplydata} SortData={SortData} selectedDaysSend={selectedDaysSend} StartTimeSend={StartTimeSend} EndTimeSend={EndTimeSend}   isLoader={isLoader} />
            );
  
          case 4:
            return (
              <AddCompetitation SearchTab={SearchTab} classList={classList} classType={classType} classPrice={classPrice} classAge={classAge} classLength={classLength} CategoriesData={CategoriesData} subCategoriesData={subCategoriesData} selectedDay={selectedDay} DateApply={DateApply} DateApplydata={DateApplydata} SortData={SortData} selectedDaysSend={selectedDaysSend} StartTimeSend={StartTimeSend} EndTimeSend={EndTimeSend}   isLoader={isLoader} />
            );
  
        default:
          return null;
      }
    };

    return (
        <>
            <THeader_Dashboard />
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="#" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <div className="class-content">
                        <div className>
                            {/*start*/}
                            <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" onclick="window.history.back()">
                                <span>
                                    <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                                    </svg>
                                    <span onClick={()=>navigate(-1)} className="ms-2">Back</span>
                                </span></a>
                                <Header_class onStepChange={handleStepChange}/>
                                <Filter step={step} SearchTab={SearchTab} setSearchTab={setSearchTab} classType={classType} setclassType={setclassType} classPrice={classPrice} setclassPrice={setclassPrice} classAge={classAge} setclassAge={setclassAge} classLength={classLength} setclassLength={setclassLength} selectedDay={selectedDay} setSelectedDay={setSelectedDay} DateApply={DateApply} setDateApply={setDateApply} DateApplydata={DateApplydata} setDateApplydata={setDateApplydata} SortData={SortData} setSortData={setSortData} selectedDaysSend={selectedDaysSend} setselectedDaysSend={setselectedDaysSend} StartTimeSend={StartTimeSend} EndTimeSend={EndTimeSend} setStartTimeSend={setStartTimeSend} setEndTimeSend={setEndTimeSend} CategoriesData={CategoriesData} setCategoriesData={setCategoriesData} subCategoriesData={subCategoriesData} setSubCategoriesData={setSubCategoriesData} classList={classList} setClassList={setClassList} isLoader={isLoader}/>
                                {renderStep()}
                        </div>
                    </div>
                </div>
            </main>
            <TFooter_Dashboard />
        </>
    )
}
