import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { payment_listing_type, teacher_name_list } from '../../../api/apiHandler'
import JqueryLoad from '../../../JqueryLoad/JqueryLoad'
import { TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/common.service'
import OFooter_Dashboard from '../OInclude/OFooter_Dashboard'
import OHeader_Dashboard from '../OInclude/OHeader_Dashboard'

export default function OPaymentSetting({ isLoader }) {
  const navigate = useNavigate();

  const [selTeacherData, setSelTeacherData] = useState([]);
  const [pType, setPType] = useState([]);
  // console.log('pType :', pType);
  // console.log('selTeacherData :', selTeacherData);


  const [SearchTab, setSearchTab] = useState('');
  // console.log('SearchTab :', SearchTab);

  const [originalData, setOriginalData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  // console.log('searchTerm :', searchTerm);
  const [listening, setListening] = useState(false);
  const [data, setData] = useState([]); // Placeholder for filtered data

  const recognition = new window.webkitSpeechRecognition();

  const handleSearch = () => {
    // console.log("FDG")
    if (searchTerm.trim() === '') {
      setData(originalData);
    } else {
      const filteredData = originalData.filter((classItem) =>
        Object.values(classItem).some((value) =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setData(filteredData);
    }
  };

  const handleInputChange = (e) => {
    setSearchTab(e.target.value);
    setSearchTerm(e.target.value);
    handleSearch();
  };

  const startListening = () => {
    recognition.start();
    setListening(true);
  };

  const stopListening = () => {
    recognition.stop();
    setListening(false);
  };

  recognition.onresult = (event) => {
    const transcript = event.results[0][0].transcript;
    setSearchTab(transcript);
    setSearchTerm(transcript);
    handleSearch();
  };

  recognition.onerror = (event) => {
    console.error('Speech recognition error:', event.error);
  };


  const Teacherlisting = () => {
    let Submit_data = {
      search: SearchTab
    }
    isLoader(true)
    teacher_name_list(Submit_data).then((response) => {
      // console.log("teacher_name_list", response)
      if (response?.code == 1) {
        isLoader(false)
        setSelTeacherData(response.data)
      } else {
        isLoader(false)
        setSelTeacherData([])

      }
    });
  }
  useEffect(() => {
    Teacherlisting();
  }, [SearchTab]);

  const PaymentType = async (type, userId) => {
    try {
      let send_data = {
        type: type,
        teacher_id: userId
      }
      // console.log('send_data :', send_data);
      isLoader(true)
      const response = await payment_listing_type(send_data);
      // console.log('response :', response);
      if (response?.code == 1) {
        isLoader(false)
        TOAST_SUCCESS(response.message)
        Teacherlisting();
      } else {
        isLoader(false)
        // TOAST_ERROR(response.message)
      }
    } catch (error) {
      console.log('error :', error);
    }
  }

  return (
    <>
      <JqueryLoad />
      <OHeader_Dashboard />
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className>
              {/*start*/}
              <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span onClick={() => navigate(-1)} className="ms-2">Back</span>
                </span></a>
              {/*end*/}
              {/*start*/}


              <div className="row pay_pice_set ">
                <div className="col-xl-8">
                  {/*start*/}
                  <div className="search_box mb-4 position-relative" >
                    <input type="text" name="search" value={searchTerm}
                      onChange={handleInputChange} placeholder="Type any topic or teacher" id="inputPlaceholderBlack"
                    />
                    <span className="ms-3 position-absolute">
                      <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z" fill="#666666" fillOpacity="0.4" />
                      </svg>
                    </span>
                    <img src="./assets/images/rec_icon.png" onClick={listening ? stopListening : startListening} alt="rec_icon" className="position-absolute rec_search_icon" />
                  </div>
                  {/*end*/}
                  {selTeacherData?.map((TD, index) => {
                    return (
                      <div className="card">
                        <Link to="/organization_payment_details" state={TD.id} className="pay_pice_box d-md-flex align-items-center justify-content-md-between">
                          <div className="d-flex align-items-center mb-md-0 mb-4">
                            <div className="position-relative pay_set_left">
                              <img src={TD.profile_image} alt="user4" style={{ borderRadius: "50%" }} />
                              <div className="price_flag position-absolute">
                                {/* <img src={TD.country_image} alt="country_flag" /> */}
                                <span className="ms-2">{TD.country_image}</span>

                              </div>
                            </div>
                            <h3 className="ms-3 poppins fw-600">{TD.full_name}</h3>
                          </div>
                        </Link>
                        <div className="pay_set_right d-flex align-items-center justify-content-end">
                          <label className="radio-button-container fw-600" htmlFor={`doller_${index}`} style={{ marginTop: "-96px" }}>Dollar
                            <input type="radio" id={`doller_${index}`} name={`doller_${index}`} onClick={(e) => PaymentType("usd", TD.id)} defaultChecked={TD.type == "usd" ? true : false} />
                            <span className="checkmarks" />
                          </label>
                          <label className="radio-button-container fw-600 ms-3" htmlFor={`percentage_${index}`} style={{ marginTop: "-96px" }}>Percentage
                            <input type="radio" id={`percentage_${index}`} name={`doller_${index}`} onClick={(e) => PaymentType("percentage", TD.id)} defaultChecked={TD.type == "percentage" ? true : false} />
                            <span className="checkmarks" />
                          </label>
                        </div>
                      </div>
                    )
                  })}
                  {/* <div className="card">
                    <Link to="/organization_payment_details" className="pay_pice_box d-md-flex align-items-center justify-content-md-between">
                      <div className="d-flex align-items-center mb-md-0 mb-4">
                        <div className="position-relative pay_set_left">
                          <img src="./assets/images/user/user4.png" alt="user4" />
                          <div className="price_flag position-absolute">
                            <img src="./assets/images/country_flag.png" alt="country_flag" />
                          </div>
                        </div>
                        <h3 className="ms-3 poppins fw-600">Michael Wong</h3>
                      </div>
                      <div className="pay_set_right d-flex justify-content-end">
                        <label className="radio-button-container fw-600">Dollar
                          <input type="radio" name="radio" />
                          <span className="checkmarks" />
                        </label>
                        <label className="radio-button-container fw-600 ms-3">Percentage
                          <input type="radio" name="radio" />
                          <span className="checkmarks" />
                        </label>
                      </div>
                    </Link>
                  </div>
                  <div className="card">
                    <Link to="/organization_payment_details" className="pay_pice_box d-md-flex align-items-center justify-content-md-between">
                      <div className="d-flex align-items-center mb-md-0 mb-4">
                        <div className="position-relative pay_set_left">
                          <img src="./assets/images/user/user5.png" alt="user5" />
                          <div className="price_flag position-absolute">
                            <img src="./assets/images/country_flag.png" alt="country_flag" />
                          </div>
                        </div>
                        <h3 className="ms-3 poppins fw-600">Michael Wong</h3>
                      </div>
                      <div className="pay_set_right d-flex justify-content-end">
                        <label className="radio-button-container fw-600">Dollar
                          <input type="radio" name="radio" defaultChecked />
                          <span className="checkmarks" />
                        </label>
                        <label className="radio-button-container fw-600 ms-3">Percentage
                          <input type="radio" name="radio" defaultChecked />
                          <span className="checkmarks" />
                        </label>
                      </div>
                    </Link>
                  </div>
                  <div className="card">
                    <Link to="/organization_payment_details" className="pay_pice_box d-md-flex align-items-center justify-content-md-between">
                      <div className="d-flex align-items-center mb-md-0 mb-4">
                        <div className="position-relative pay_set_left">
                          <img src="./assets/images/user/user5.png" alt="user5" />
                          <div className="price_flag position-absolute">
                            <img src="./assets/images/country_flag.png" alt="country_flag" />
                          </div>
                        </div>
                        <h3 className="ms-3 poppins fw-600">Michael Wong</h3>
                      </div>
                      <div className="pay_set_right d-flex justify-content-end">
                        <label className="radio-button-container fw-600">Dollar
                          <input type="radio" name="radio" defaultChecked />
                          <span className="checkmarks" />
                        </label>
                        <label className="radio-button-container fw-600 ms-3">Percentage
                          <input type="radio" name="radio" defaultChecked />
                          <span className="checkmarks" />
                        </label>
                      </div>
                    </Link>
                  </div>
                  <div className="card">
                    <Link to="/organization_payment_details" className="pay_pice_box d-md-flex align-items-center justify-content-md-between">
                      <div className="d-flex align-items-center mb-md-0 mb-4">
                        <div className="position-relative pay_set_left">
                          <img src="./assets/images/user/user2.png" alt="user2" />
                          <div className="price_flag position-absolute">
                            <img src="./assets/images/country_flag.png" alt="country_flag" />
                          </div>
                        </div>
                        <h3 className="ms-3 poppins fw-600">Michael Wong</h3>
                      </div>
                      <div className="pay_set_right d-flex justify-content-end">
                        <label className="radio-button-container fw-600">Dollar
                          <input type="radio" name="radio" defaultChecked />
                          <span className="checkmarks" />
                        </label>
                        <label className="radio-button-container fw-600 ms-3">Percentage
                          <input type="radio" name="radio" defaultChecked />
                          <span className="checkmarks" />
                        </label>
                      </div>
                    </Link>
                  </div> */}
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>
      <OFooter_Dashboard />
    </>
  )
}
