import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer';
import { useNavigate } from 'react-router-dom';
import * as yup from "yup";
import { TOAST_ERROR, TOAST_SUCCESS } from '../../utils/common.service';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { contact_us } from '../../api/apiHandler';


export default function LandingPageContactUs() {


    const navigate = useNavigate()

    let schema = yup.object().shape({
        name: yup
            .string()
            .required("Please enter full name")
            .matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/, "Only alphabet characters are allowed in the Full Name field")
            .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
            .min(2, "Field must contain atleast 2 or 3 characters long"),

        email: yup
            .string()
            .email()
            .matches(/^(?!.*@[^,]*,)/)
            .required("Please enter email"),
        subject: yup.string().required("Please select subject"),
        message: yup.string().required("Please add description").min(2, "Field must contain atleast 2 or 3 characters long"),

    });
    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            current_status: "New to StudentScholars"
        }
    });


    let onSubmit = (data) => {
        console.log('data :', data);
        // return
        contact_us(data).then((resposnse) => {
            if (resposnse?.code == 1) {
                TOAST_SUCCESS(resposnse?.message);
                reset();
            } else {
                TOAST_ERROR(resposnse?.message)
            }
        });


    }

    const handleBack = () => {
        navigate(-1);
    };


    return (
        <>

            <Header />
            <main>

                <section>
                    <span className="mb-4 arrow_left mb-3 d-block mx-4 mt-2" onClick={handleBack} >

                        <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                        </svg>
                        <span className="ms-2">Back</span>
                    </span>
                    {/*end*/}
                    <div className="container mb-5">
                        <section className="requirement_form">
                            <h2 className="mb-4 fw-600 poppins text-capitalize text-dif-black text-center">Contact Us</h2>
                            <div className="title mb-4 text-center">
                                <h2 className="fw-600 text-dif-black mb-3 poppins">
                                    Hello There, <span className="text-purple">Welcome to StudentScholars Support</span>
                                </h2>
                                <p className="fw-500 text-gray">Please fill in the form below and our Support Team will email you</p>
                            </div>
                            <div className="row align-items-center justify-content-center">
                                <div className="col-lg-8">
                                    <div className="card border-purple p-4">
                                        <form className="custom_form mt-4" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="form-group mb-4">
                                                <label className="mb-2">Full Name</label>

                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="name"
                                                    {...register("name")}
                                                />
                                                {errors.name && <p className="mt-2 font-bold col-red">
                                                    <span style={{ color: "red" }}>{errors.name.message}</span>
                                                </p>}
                                            </div>

                                            <div className="form-group mb-4">
                                                <label className="mb-2">Email</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    name="email"
                                                    {...register("email")}

                                                />
                                                {errors.email && <p className="mt-2 font-bold col-red">
                                                    <span style={{ color: "red" }}>{errors.email.message}</span>
                                                </p>}
                                            </div>

                                            <div className="form-group mb-4">
                                                <label className="mb-2">Current Status</label>
                                                <select
                                                    className="form-select"
                                                    aria-label="Default select example"
                                                    name="current_status"
                                                    {...register("current_status")}
                                                    disabled
                                                    style={{ backgroundColor: '#e9ecef' }}

                                                >
                                                    <option value="New to StudentScholars" selected>
                                                        New to StudentScholars
                                                    </option>

                                                </select>
                                                {errors.current_status && <p className="mt-2 font-bold col-red">
                                                    <span style={{ color: "red" }}>{errors.current_status.message}</span>
                                                </p>}
                                            </div>
                                            <div className="form-group mb-4">
                                                <label className="mb-2 d-block">Subject</label>
                                                <input
                                                    type="text"
                                                    id="mobile_code"
                                                    className="form-control w-100"
                                                    name="subject"
                                                    {...register("subject")}

                                                />
                                                {errors.subject && <p className="mt-2 font-bold col-red">
                                                    <span style={{ color: "red" }}>{errors.subject.message}</span>
                                                </p>}
                                            </div>
                                            <div className="form-group mb-4">
                                                <label className="mb-2">Description</label>
                                                <textarea
                                                    className="form-control"
                                                    rows={5}
                                                    name="message"
                                                    {...register("message")}
                                                />
                                                {errors.message && <p className="mt-2 font-bold col-red">
                                                    <span style={{ color: "red" }}>{errors.message.message}</span>
                                                </p>}
                                            </div>


                                            <div className="button mt-5 d-flex justify-content-center">
                                                <button type="submit" className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block">
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {/* <div class="col-lg-6">
        			<div class="">
        				<img src="./assets/images/help_contact.png" alt="help_contact">
        			</div>
        		</div> */}
                            </div>
                        </section>

                    </div>
                </section>



            </main>
            <Footer />
        </>
    )
}
