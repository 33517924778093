import React, { useState, useEffect, useRef } from 'react';
import { findAll } from 'highlight-words-core';

const TextToSpeech = ({ text }) => {
  const [highlightedText, setHighlightedText] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const utteranceRef = useRef(null);
  const timerRef = useRef(null);

  useEffect(() => {
    setHighlightedText([]);
    setCurrentWordIndex(0);
  }, [text]);

  const handleSpeech = () => {
    if (!text) return;

    const words = text.split(' ');
    const utterance = new SpeechSynthesisUtterance(text);
    utteranceRef.current = utterance;

    const rate = utterance.rate || 1  ; // Speech rate (default 1)
    const wordDuration = 171 / rate; // Approx 600ms per word at normal rate

    let wordIndex = 0;

    utterance.onstart = () => {
      // Start manual word highlighting
      timerRef.current = setInterval(() => {
        if (wordIndex < words.length) {
          setCurrentWordIndex(wordIndex);
          highlightWords(wordIndex);
          wordIndex++;
        } else {
          clearInterval(timerRef.current);
        }
      }, wordDuration);
    };

    utterance.onend = () => {
      clearInterval(timerRef.current);
      setHighlightedText([]);
      setCurrentWordIndex(0);
    };

    speechSynthesis.speak(utterance);
  };

  const highlightWords = (index) => {
    const ranges = findAll({
      textToHighlight: text,
      searchWords: text.split(' '),
      sanitize: (word) => word,
    });

    const highlighted = ranges.map((range, idx) => ({
      ...range,
      highlight: idx === index,
    }));

    setHighlightedText(highlighted);
  };

  const stopSpeech = () => {
    if (utteranceRef.current) {
      speechSynthesis.cancel();
      clearInterval(timerRef.current);
      setHighlightedText([]);
      setCurrentWordIndex(0);
    }
  };

  return (
    <div>
      <button onClick={handleSpeech}>Speak</button>
      <button onClick={stopSpeech}>Stop</button>
      <div>
        {highlightedText.length > 0
          ? highlightedText.map((word, index) => (
              <span
                key={index}
                style={{
                  backgroundColor: word.highlight ? 'yellow' : 'transparent',
                }}
              >
                {text.substring(word.start, word.end)}{' '}
              </span>
            ))
          : text}
      </div>
    </div>
  );
};

export default TextToSpeech;
