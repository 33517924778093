import { Link } from "react-router-dom";

const ViewTeacherLink = ({ data }) => {
  return (
    <div className="d-flex bottom-info align-items-center justify-content-between">
      <Link to="/teachers_details" state={data?.teacher_id} className="d-flex align-items-center">
        <img src={data?.profile_image} alt="user1" />
        <span className="tech_nm ms-2">{data?.full_name}</span>
      </Link>
      <div className="star_rat">
        <span>
          <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z"
              fill="#FDC840"
            />
          </svg>
          &nbsp;
        </span>
        <span className="fw-600">
          {data.teacher_ratings}
          <span className="text-gray fw-500">({data.teacher_review})</span>
        </span>
      </div>
    </div>
  );
};

export default ViewTeacherLink;
