const CommonAttendanceCalender = ({ children }) => {
  return (
    <div className="col-xl-3">
      <div className="mb-4">
        <span className="fs-5 fw-600 mb-4 d-block text-dif-black">Attendance</span>
        <div className="wrappers">
          <div className="container-calendar">
            <div className="cal_header d-flex justify-content-between">
              <h3 className="poppins fw_500" id="monthAndYear" />
              <div className="button-container-calendar">
                <button id="previous" onClick="previous()">
                  ‹
                </button>
                <button className=" ms-3" id="next" onClick="next()">
                  ›
                </button>
              </div>
            </div>
            <table className="table-calendar" id="calendar" data-lang="en">
              <thead id="thead-month" />
              <tbody id="calendar-body" />
            </table>
            <div className="footer-container-calendar d-none">
              <label htmlFor="month">Jump To: </label>
              <select id="month" onchange="jump()">
                <option value={0}>Jan</option>
                <option value={1}>Feb</option>
                <option value={2}>Mar</option>
                <option value={3}>Apr</option>
                <option value={4}>May</option>
                <option value={5}>Jun</option>
                <option value={6}>Jul</option>
                <option value={7}>Aug</option>
                <option value={8}>Sep</option>
                <option value={9}>Oct</option>
                <option value={10}>Nov</option>
                <option value={11}>Dec</option>
              </select>
              <select id="year" onchange="jump()" />
            </div>
          </div>
        </div>
        {/*start*/}
        <div className="attendence_count g-3 row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
            <div className="attendence_box flex-wrap d-flex align-items-center">
              <canvas id="myChart" width={33} height={33} />
              <div className="ms-3">
                <span>150</span>
                <p>Total Hours attended for Month</p>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
            <div className="attendence_box flex-wrap d-flex align-items-center">
              <canvas id="myChart1" width={33} height={33} />
              <div className="ms-3">
                <span>150</span>
                <p>Total Hours attended To Date</p>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
            <div className="attendence_box flex-wrap absent_bg d-flex align-items-center">
              <canvas id="myChart2" width={33} height={33} />
              <div className="ms-3">
                <span>150</span>
                <p>Total Days Absent for Month</p>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
            <div className="attendence_box flex-wrap absent_bg d-flex align-items-center">
              <canvas id="myChart3" width={33} height={33} />
              <div className="ms-3">
                <span>150</span>
                <p>Total Days Absent To Date</p>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
            <div className="attendence_box flex-wrap joined_bg d-flex align-items-center">
              <canvas id="myChart4" width={33} height={33} />
              <div className="ms-3">
                <span>150</span>
                <p>Total Days joined for Month</p>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
            <div className="attendence_box flex-wrap joined_bg d-flex align-items-center">
              <canvas id="myChart5" width={33} height={33} />
              <div className="ms-3">
                <span>150</span>
                <p>Total Days joined To Date</p>
              </div>
            </div>
          </div>
        </div>
        {/*end*/}
      </div>
      {children}
    </div>
  );
};

export default CommonAttendanceCalender;
