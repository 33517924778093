import { useEffect, useState } from "react";
import { get_time_zone_details } from "../api/apiHandler";

const useUserTimezone = () => {
  const [timezone, setTimezone] = useState('');
  console.log('Timezone state:', timezone); 

  const userTimeZone = async () => {
    try {
      const response = await get_time_zone_details();
      console.log('API response:', response); 
      if (response?.code == 1) {
        setTimezone(response?.data?.matchedTimezone);
      } else {
        setTimezone('');
      }
    } catch (error) {
      console.error("Failed to fetch timezone details", error);
      setTimezone('');
    }
  };

  useEffect(() => {
    userTimeZone();
  }, []);

  return timezone;
};

export { useUserTimezone };
