import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import PFooter_Dashboard from "../Pinc/PFooter_Dashboard";
import PHeader_Dashboard from "../Pinc/PHeader_Dashboard";
import { learner_list_parents } from "../../../api/apiHandler";
import BackToPrevious from "../../../components/backToPrevious";

export default function ParentCertificate() {
  const [learnerList, setLearnerList] = useState([]);
  console.log("🚀 ~ ParentCertificate ~ learnerList:", learnerList);

  useEffect(() => {
    learner_list_parents_details();
  }, []);

  const learner_list_parents_details = () => {
    learner_list_parents().then((resposnse) => {
      if (resposnse?.code == 1) {
        console.log(resposnse,'kids');
        setLearnerList(resposnse?.data);
      } else {
        setLearnerList([]);
      }
    });
  };

  const showLearnerList = learnerList.map((item, index) => {
    return (
      <Link key={item?.id} to="/kids_certificates_list" state={item?.id} className="certifi_col">
        <div className="certifi_display">
          <div className="cert_image">
            <img src={item?.learner_profile_img || "./assets/images/kids-profile.png"} alt="kids-profile" />
          </div>
          <div className="cert_name">
            <span>{item?.learner_name}</span>
          </div>
        </div>
      </Link>
    );
  });

  return (
    <>
      <JqueryLoad />
      <PHeader_Dashboard />
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div>
              {/*start*/}
              <BackToPrevious />
              {/*end*/}
              {/*start*/}
              <div className="certi-section">
                <div className="title">
                  <h2>Certificates</h2>
                  <p>Select Children</p>
                </div>
                <div className="certi-main">
                  {showLearnerList}
                  {/* <Link to="/kids_certificates_list" className="certifi_col">
                    <div className="certifi_display">
                      <div className="cert_image">
                        <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                      </div>
                      <div className="cert_name">
                        <span>Mark joseph</span>
                      </div>
                    </div>
                  </Link>
                  <Link to="/kids_certificates_list" className="certifi_col">
                    <div className="certifi_display">
                      <div className="cert_image">
                        <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                      </div>
                      <div className="cert_name">
                        <span>Mark joseph</span>
                      </div>
                    </div>
                  </Link>
                  <Link to="/kids_certificates_list" className="certifi_col">
                    <div className="certifi_display">
                      <div className="cert_image">
                        <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                      </div>
                      <div className="cert_name">
                        <span>Mark joseph</span>
                      </div>
                    </div>
                  </Link>
                  <Link to="/kids_certificates_list" className="certifi_col">
                    <div className="certifi_display">
                      <div className="cert_image">
                        <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                      </div>
                      <div className="cert_name">
                        <span>Mark joseph</span>
                      </div>
                    </div>
                  </Link>
                  <Link to="/kids_certificates_list" className="certifi_col">
                    <div className="certifi_display">
                      <div className="cert_image">
                        <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                      </div>
                      <div className="cert_name">
                        <span>Mark joseph</span>
                      </div>
                    </div>
                  </Link>
                  <Link to="/kids_certificates_list" className="certifi_col">
                    <div className="certifi_display">
                      <div className="cert_image">
                        <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                      </div>
                      <div className="cert_name">
                        <span>Mark joseph</span>
                      </div>
                    </div>
                  </Link> */}
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>
      <PFooter_Dashboard />
    </>
  );
}
