import React from 'react'
import TFooter_Dashboard from '../Include/TFooter_Dashboard'
import THeader_Dashboard from '../Include/THeader_Dashboard'

export default function Nopermission() {
  return (
      <>
    <THeader_Dashboard/>
    <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div>
              {/*start*/}
              <a href="#" className="mb-4 arrow_left mb-3 d-block" onclick="window.history.back()">
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span className="ms-2">Back</span>
                </span>
              </a>
              {/*end*/}
              {/*start*/}
              <div className="class_section">
              <h4 className="d-flex justify-content-center" style={{ "color": "#570861", textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}>Oops!😔 It seems you don't have permission to access this feature.🙂</h4>
             </div>
            </div>
          </div>
        </div>
      </main>
    <TFooter_Dashboard/>
    </>
  )
}
