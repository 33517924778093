import React from 'react'
import { Helmet } from 'react-helmet'

export default function Loader({ isActive }) {
    if (!isActive) return <></>;
    return (
        <>
            <Helmet>
                <style>{`
                    .load-bgblack {
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(255, 255, 255, 0.7);
                        z-index: 1111;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                    }
                    .loader-image {
                        width: 70px;
                        height: 70px;
                        animation: rotate 1s infinite linear;
                    }
                    @keyframes rotate {
                        100% {
                            transform: rotate(360deg);
                        }
                    }
                    .please-wait {
                        margin-top: 15px;
                        font-size: 16px;
                        color: #570861;
                        font-weight: bold;
                    }
                `}</style>
            </Helmet>
            <div className='load-bgblack'>
                <img src="/assets/images/hat_purple_1.png" alt="Loading" className="loader-image" />
                <div className="please-wait">Hang on,Loading Knowledge...</div>
            </div>
        </>
    )
}
