import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import BackToPrevious from "../../../components/backToPrevious";
import { TOAST_ERROR } from "../../../utils/common.service";
import useCountdown from "../../../utils/useCountdown";

import { getQuizQuestion, getQuizResult, insert_quiz_answer } from "../../../api/apiHandler";
import OHeader_Dashboard from "../OInclude/OHeader_Dashboard";
import OFooter_Dashboard from "../OInclude/OFooter_Dashboard";
import Modals from 'react-modal';

var COMMON_STYLE_FOR_POPUP = {
  content: {
    background: 'none',
    border: '0px',
  },
}
export default function QuizStartO() {
  window.scrollTo(0, 0)
  const [questionData, setQuestionData] = useState(null);
  console.log("🚀  PStartQuiz  questionData:", questionData);
  const [result, setResult] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [queData, setQueData] = useState(null);
  const [page, setPage] = useState(1);
  const location = useLocation();
  const { quiz_id, no_of_question: totalQuestions, duration, subject_category, main_category, is_submit } = location?.state;
  console.log("hello", location?.state)
  console.log('quiz_id :', quiz_id);
  // console.log("🚀  PStartQuiz  duration:", duration);
  const splittedDuration = duration?.split(" ");
  const hours = splittedDuration?.[0];
  const minutes = splittedDuration?.[3];
  const { formattedTimeString, pauseCountdown } = useCountdown(hours, minutes);
  const [showModal, setShowModal] = useState(false);

  const submitRef = useRef();
  const resultRef = useRef();

  const fetchQuizQuestion = async () => {
    try {
      const { code, data, message } = await getQuizQuestion({ quiz_id, page, per_page: 1 });
      if (code == 1) {
        setQuestionData(data[0]);
      } else {
        TOAST_ERROR(message);
      }
    } catch (err) {
      TOAST_ERROR(err.message);
    }
  };


  // const handleSubmitAnswer = async () => {
  //   try {
  //     const body = answers.find((el) => el.question_id === questionData?.question_id);
  //     if (body) {
  //       const { code, message } = await insert_quiz_answer(body);
  //       if (code != 1) TOAST_ERROR(message);
  //     }
  //     totalQuestions > page && setPage(page + 1);
  //   } catch (err) {
  //     TOAST_ERROR(err.message);
  //   }
  // };
  const handleSubmitAnswer = async () => {
    try {

      console.log('answers111 :', answers);
      const body = answers.find((el) => el.question_id === questionData?.question_id);
      console.log('body :', body);
      if (body) {
        const { code, message } = await insert_quiz_answer(body);
        if (code != 1) TOAST_ERROR(message);
      }
      // console.log("www",totalQuestions )
      // console.log("rrrr",answers.length )
      // console.log("qqqq",totalQuestions == answers.length)
      if (totalQuestions == answers.length) {
        console.log("hello")
        setShowModal(true);
      } else {
        // totalQuestions > page && setPage(page + 1);
        if (totalQuestions > page) {
          setPage(page + 1);
        } else {
          TOAST_ERROR("Please answer all the questions before submitting!");
        }
      }

    } catch (err) {
      TOAST_ERROR(err.message);
    }
  };

  const handleQuizResult = async () => {
    const { code, message } = await insert_quiz_answer(queData);
    setShowModal(false);

    try {
      const { code, message, data } = await getQuizResult({ quiz_id });
      if (code == 1) {
        const percentage = (data[0]?.total_score / data[0]?.total_points) * 100;
        setResult(percentage);
        pauseCountdown();
        console.log("🚀  handleQuizResult  data:", data[0]);
      } else TOAST_ERROR(message);
    } catch (err) {
      TOAST_ERROR(err.message);
    }
  };

  useEffect(() => {
    // alert(quiz_id)
    quiz_id && fetchQuizQuestion();
  }, [quiz_id, page]);

  useEffect(() => {
    if (duration && formattedTimeString === "00:00:00") {
      submitRef.current.click();
    }
  }, [formattedTimeString]);

  const handlePage = (type) => {
    if (type === "next") {
      totalQuestions > page && setPage(page + 1);
    } else if (type === "prev") page > 1 && setPage(page - 1);
  };

  const handleChange = (e) => {
    let tempAnswers = [...answers];
    const { value, checked } = e.target;
    const index = tempAnswers.findIndex((el) => el.question_id === questionData?.question_id);
    const data = {
      quiz_id,
      question_id: questionData?.question_id,
      option_id: value,
      score: questionData?.points,
    };
    if (index >= 0) {
      if (questionData?.que_type === "Multiple") {
        let checkedAnswers = tempAnswers[index].option_id;
        if (checked) checkedAnswers = `${checkedAnswers},${value}`;
        else {
          checkedAnswers = checkedAnswers.split(",");
          const index = checkedAnswers.findIndex((optionId) => optionId === value);
          checkedAnswers.splice(index, 1);
          checkedAnswers = checkedAnswers.join(",");
        }
        tempAnswers[index] = { ...tempAnswers[index], option_id: checkedAnswers };
      } else tempAnswers[index] = data;
    } else tempAnswers.push(data);
    console.log("🚀  handleChange  tempAnswers:", tempAnswers);
    setAnswers(tempAnswers);
  };

  const showOptions = questionData?.options?.map((el) => {
    return (
      <div key={el.option_id} className="form-check mb-3">
        {questionData?.que_type === "Multiple" ? (
          <input
            onChange={handleChange}
            className="form-check-input pointer"
            type="checkbox"
            name="flexRadioDefault"
            value={el.option_id}
            checked={answers.some((item) => item.option_id.includes(el.option_id))}
          />
        ) : (
          <input
            onChange={handleChange}
            className="form-check-input pointer"
            type="radio"
            name="flexRadioDefault"
            value={el.option_id}
            checked={answers.some((item) => item.option_id == el.option_id)}
          />
        )}
        <label className="form-check-label" htmlFor="flexRadioDefault1">
          {el.options}
        </label>
      </div>
    );
  });

  const totalPages = [];
  for (let i = 0; i < totalQuestions; i++) {
    totalPages.push(
      <li onClick={() => setPage(i + 1)} key={i} className={`circle ${page === i + 1 ? "active" : ""} pointer`}>
        <a className="text-purple font-w">{i + 1}</a>
      </li>
    );
  }

  return (
    <>
      <JqueryLoad />
      <OHeader_Dashboard />
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div>
              <BackToPrevious />
              <div>
                <div className="class_lsit_section">
                  <div className="mb-4">
                    <h2 className="mb-2 poppins fw-600">Start Quiz</h2>
                    <p className="fs-6 text-gray text-capitalize">{main_category} </p>
                  </div>
                  <div className="quiz_card">
                    <div className="card_title d-lg-flex justify-content-between align-items-center mb-4">
                      <div className="mb-lg-0 mb-4">
                        <h3 className="mb-2 poppins fw-bold">{subject_category} Quiz</h3>
                        <p className="fs-6 text-dif-black">Read the following instructions</p>
                      </div>
                      {is_submit == 0 && duration && (
                        <div className="timer_calss">
                          <span>Timer: </span>&nbsp;
                          <span className="text-danger">
                            {formattedTimeString}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="row mb-5 align-items-center">
                      <div className="col-xl-6 mb-xl-0 mb-4 left_side">
                        <div className="quiz_image">
                          {questionData?.attachment_type === "image" ? (
                            <img src={questionData?.question_attachment} alt="class4" />
                          ) : (
                            <video id="video" loop width="100%" controls="hide" poster="">
                              <source src={questionData?.question_attachment} type="video/mp4" />
                            </video>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-6 right_side">
                        <div className="quiz_info ps-xl-5">
                          <div className="quiz_ques">
                            <div className="quiz_count text-purple fw-600 mb-3">
                              <span>Question</span>
                              <span className="ms-2">
                                {page}/{totalQuestions}
                              </span>
                            </div>
                            <p className="text-dif-black">{questionData?.question}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="quiz_Inst">
                      <h4 className="mb-3 poppins fw-600">Choose answer</h4>
                      <form>{showOptions}</form>
                    </div>
                    <div className="row button_bottom align-items-center justify-content-center">
                      <div className="pagenation_box col-xl-9">
                        <div className="custom_pagenation">
                          <ul className="d-flex align-items-center justify-content-center flex-wrap">
                            <li onClick={() => handlePage("prev")} className="pointer">
                              <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
                                <path d="M561-240 320-481l241-241 43 43-198 198 198 198-43 43Z" />
                              </svg>
                            </li>
                            {totalPages}
                            {/* <li className="circle active">
                              <a href="#" className="text-purple font-w">
                                {page}
                              </a>
                            </li> */}
                            <li onClick={() => handlePage("next")} className="pointer">
                              <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
                                <path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z" />
                              </svg>
                            </li>
                            {/* <li className="circle">
                              <a href="#" className="text-purple font-w">
                                2
                              </a>
                            </li>
                            <li className="circle">
                              <a href="#" className="text-purple font-w">
                                3
                              </a>
                            </li>
                            <li className="circle">
                              <a href="#" className="text-purple font-w">
                                4
                              </a>
                            </li>
                            <li className="mx-2">
                              <span>...</span>
                            </li>
                            <li className="circle">
                              <a href="#" className="text-purple font-w">
                                13
                              </a>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                      {(!duration || formattedTimeString !== "00:00:00") && is_submit == 0 ? (
                        <div className="text-end pt-2 col-xl-3 order-xl-last order-first mb-xl-0 mb-4">
                          {totalQuestions > page ? (
                            <button onClick={handleSubmitAnswer} className="btn-theme bg-yellow d-inline-block px-xl-5 fw-500">
                              Next
                            </button>
                          ) : (
                            <button
                              onClick={handleSubmitAnswer}

                              className="btn-theme bg-yellow d-inline-block px-xl-5 fw-500"
                            >
                              Submit
                            </button>
                          )}
                        </div>
                      ) : (
                        <div className="text-end pt-2 col-xl-3 order-xl-last order-first mb-xl-0 mb-4">
                          <Link
                            to={"/organization-quiz-review"}
                            onClick={() => resultRef.current.click()}
                            state={{ quiz_id, totalQuestions, duration }}
                            className="btn-theme bg-yellow d-inline-block px-xl-5 fw-500"
                          >
                            Review Quiz
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* <div className="modal fade" id="quiz_submit" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel" />
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              <div className="text-center">
                <span className="mb-3 d-block">
                  <svg width={100} height={100} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width={100} height="99.2307" rx="49.6154" fill="#FFC924" />
                    <path
                      d="M62.1688 44.3298C62.1688 41.6143 60.9292 39.0099 58.7226 37.0898C56.5161 35.1696 53.5233 34.0908 50.4028 34.0908C47.2822 34.0908 44.2895 35.1696 42.0829 37.0898C39.8764 39.0099 38.6367 41.6143 38.6367 44.3298C38.6367 44.8729 38.8846 45.3938 39.326 45.7778C39.7673 46.1619 40.3658 46.3776 40.9899 46.3776C41.614 46.3776 42.2126 46.1619 42.6539 45.7778C43.0952 45.3938 43.3431 44.8729 43.3431 44.3298C43.3431 43.1148 43.7572 41.927 44.5329 40.9167C45.3086 39.9064 46.4112 39.119 47.7012 38.6541C48.9911 38.1891 50.4106 38.0674 51.78 38.3045C53.1495 38.5415 54.4074 39.1266 55.3947 39.9858C56.382 40.8449 57.0544 41.9396 57.3268 43.1313C57.5992 44.323 57.4593 45.5582 56.925 46.6808C56.3907 47.8033 55.4858 48.7628 54.3249 49.4379C53.1639 50.1129 51.799 50.4732 50.4028 50.4732C49.7787 50.4732 49.1801 50.689 48.7388 51.073C48.2975 51.457 48.0496 51.9779 48.0496 52.521V56.6166C48.0496 57.1597 48.2975 57.6806 48.7388 58.0646C49.1801 58.4487 49.7787 58.6644 50.4028 58.6644C51.0269 58.6644 51.6254 58.4487 52.0667 58.0646C52.5081 57.6806 52.756 57.1597 52.756 56.6166V54.364C55.4136 53.8919 57.8022 52.6353 59.5175 50.8068C61.2328 48.9783 62.1694 46.6902 62.1688 44.3298Z"
                      fill="#570861"
                    />
                    <path
                      d="M50.402 66.8554C51.7017 66.8554 52.7552 65.9385 52.7552 64.8076C52.7552 63.6766 51.7017 62.7598 50.402 62.7598C49.1024 62.7598 48.0488 63.6766 48.0488 64.8076C48.0488 65.9385 49.1024 66.8554 50.402 66.8554Z"
                      fill="#570861"
                    />
                  </svg>
                </span>
                <p className="fw-600">Are You Sure Want To Submit Your Quiz?</p>
              </div>
            </div>
            <div className="modal-footer border-top-0 pt-0 justify-content-center">
              <button
                ref={submitRef}
                onClick={handleQuizResult}
                type="button"
                className="btn-theme bg-yellow"
                data-bs-dismiss="modal"
                data-bs-toggle="modal"
                data-bs-target="#quiz_congrat"
              >
                Yes
              </button>
              <button type="button" className="btn-theme d-block text-center" data-bs-dismiss="modal">
                No
              </button>
            </div>
          </div>
        </div>
      </div> */}
      <Modals isOpen={showModal} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabelqqq" />
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowModal(false)}
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="text-center">
                <span className="mb-3 d-block">
                  <svg width={100} height={100} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width={100} height="99.2307" rx="49.6154" fill="#FFC924" />
                    <path
                      d="M62.1688 44.3298C62.1688 41.6143 60.9292 39.0099 58.7226 37.0898C56.5161 35.1696 53.5233 34.0908 50.4028 34.0908C47.2822 34.0908 44.2895 35.1696 42.0829 37.0898C39.8764 39.0099 38.6367 41.6143 38.6367 44.3298C38.6367 44.8729 38.8846 45.3938 39.326 45.7778C39.7673 46.1619 40.3658 46.3776 40.9899 46.3776C41.614 46.3776 42.2126 46.1619 42.6539 45.7778C43.0952 45.3938 43.3431 44.8729 43.3431 44.3298C43.3431 43.1148 43.7572 41.927 44.5329 40.9167C45.3086 39.9064 46.4112 39.119 47.7012 38.6541C48.9911 38.1891 50.4106 38.0674 51.78 38.3045C53.1495 38.5415 54.4074 39.1266 55.3947 39.9858C56.382 40.8449 57.0544 41.9396 57.3268 43.1313C57.5992 44.323 57.4593 45.5582 56.925 46.6808C56.3907 47.8033 55.4858 48.7628 54.3249 49.4379C53.1639 50.1129 51.799 50.4732 50.4028 50.4732C49.7787 50.4732 49.1801 50.689 48.7388 51.073C48.2975 51.457 48.0496 51.9779 48.0496 52.521V56.6166C48.0496 57.1597 48.2975 57.6806 48.7388 58.0646C49.1801 58.4487 49.7787 58.6644 50.4028 58.6644C51.0269 58.6644 51.6254 58.4487 52.0667 58.0646C52.5081 57.6806 52.756 57.1597 52.756 56.6166V54.364C55.4136 53.8919 57.8022 52.6353 59.5175 50.8068C61.2328 48.9783 62.1694 46.6902 62.1688 44.3298Z"
                      fill="#570861"
                    />
                    <path
                      d="M50.402 66.8554C51.7017 66.8554 52.7552 65.9385 52.7552 64.8076C52.7552 63.6766 51.7017 62.7598 50.402 62.7598C49.1024 62.7598 48.0488 63.6766 48.0488 64.8076C48.0488 65.9385 49.1024 66.8554 50.402 66.8554Z"
                      fill="#570861"
                    />
                  </svg>
                </span>
                <p className="fw-600">Are You Sure Want To Submit Your Quiz?</p>
              </div>
            </div>
            <div className="modal-footer border-top-0 pt-0 justify-content-center">
              <button
                ref={submitRef}
                onClick={handleQuizResult}
                type="button"
                className="btn-theme bg-yellow"
                data-bs-dismiss="modal"
                data-bs-toggle="modal"
                data-bs-target="#quiz_congrat"
              >
                Yes
              </button>
              <button type="button" className="btn-theme d-block text-center" onClick={() => setShowModal(false)}>
                No
              </button>
            </div>
          </div>
        </div>
      </Modals>
      <div className="modal fade" id="quiz_congrat" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel" />
              <button ref={resultRef} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              <div className="text-center">
                <span className="mb-3 d-block">
                  <img src="./assets/images/congrat.png" alt="congrat" />
                </span>
                <p className="fw-600">{result >= 33 ? "Congratulations you have passed" : "Sorry, you did not pass this time"}</p>
                <span className="my-3 d-block">You scored {result}%</span>
                <Link
                  to={"/organization-quiz-review"}
                  onClick={() => resultRef.current.click()}
                  state={{ quiz_id, totalQuestions, duration, main_category, subject_category }}
                  className="text-purple d-block fs-6 fw-bold"
                >
                  Review Quiz
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OFooter_Dashboard />
    </>
  );
}
