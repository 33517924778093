import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { previous_classes, update_read_status_enrollment, update_read_status_request_class } from "../../../api/apiHandler";
import { TOAST_ERROR, formatDate } from "../../../utils/common.service";

export default function PResponded_class({ CategoriesData, subCategoriesData, startDate, endDate, classSize }) {
  var [data, setData] = useState([]);

  const teacherNewLeads = () => {
    var search_item = {};
    if (CategoriesData && subCategoriesData?.[0]) {
      search_item.category_list = CategoriesData;
      search_item.sub_category = subCategoriesData;
      // console.log('search_item :', search_item);
    }
    if (startDate) {
      search_item.start_date = formatDate(startDate, "DD-MM-YYYY");
      // search_item.endDate = endDate
    }
    if (endDate) {
      search_item.end_date = formatDate(endDate, "DD-MM-YYYY");
    }
    if (classSize) {
      search_item.class_size = classSize;
    }
    console.log("search_item :", search_item);
    previous_classes(search_item).then((resposnse) => {
      console.log("previous_classes", resposnse);

      if (resposnse?.code == 1) {
        setData(resposnse?.data);
      } else {
        console.log("Data Not Found");
        setData([]);
      }
    });
  };

  const handleUpdateReadStatus = async (request_class_id, is_read) => {
    try {
      if (!is_read)
        await update_read_status_request_class({
          request_class_id,
        });
    } catch (error) {
      TOAST_ERROR(error.message);
    }
  };

  useEffect(() => {
    teacherNewLeads();
  }, [CategoriesData, subCategoriesData, startDate, endDate, classSize]);

  return (
    <>
      {/* <div className="tab-pane fade" id="pills-responded" role="tabpanel" aria-labelledby="pills-responded-tab"> */}
      <div className="row">
        <div className="col-xl-9">
          <div className="lead_request">
            {data.length > 0 ? (
              data.map((data, index) => (
                <div className="card" key={index}>
                  <a href="#" className="lead_req_img d-flex position-relative align-items-center">
                    <img src={data.profile_image} alt="user" className="rounded-circle" />
                    <div className="ms-3">
                      <span className="fw-500">{data.full_name}</span>
                      {data?.is_read === 0 && <span className="ms-3 green_dots position-absolute" />}
                    </div>
                  </a>
                  <a href="#" className="d-flex mb-3 align-items-center justify-content-between">
                    {/* <ul>
                                <li className="mb-2">
                                  <span className="font-12">
                                    <span>
                                      <svg width={12} height={16} viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M0.75 5.75C0.75 2.8475 3.0975 0.5 6 0.5C8.9025 0.5 11.25 2.8475 11.25 5.75C11.25 9.6875 6 15.5 6 15.5C6 15.5 0.75 9.6875 0.75 5.75ZM4.125 5.75C4.125 6.785 4.965 7.625 6 7.625C7.035 7.625 7.875 6.785 7.875 5.75C7.875 4.715 7.035 3.875 6 3.875C4.965 3.875 4.125 4.715 4.125 5.75Z" fill="#5C5C5C" />
                                      </svg>
                                    </span>
                                    {data.country_name}
                                  </span>
                                </li>
                                <li className="mb-2">
                                  <span className="fw-500 text-dif-black font-14">{data.name}</span>
                                </li>
                                <li className="mb-2">
                                  <span className="fw-500 text-dif-black font-14">Requested Class : <span>{data.subcategory_list}/{data.want_lessons}.</span></span>
                                </li>
                              </ul>  */}
                    <ul>
                      {/* <li className="mb-2">
                                  <span className="font-12">
                                    <span>
                                      <svg width={12} height={16} viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M0.75 5.75C0.75 2.8475 3.0975 0.5 6 0.5C8.9025 0.5 11.25 2.8475 11.25 5.75C11.25 9.6875 6 15.5 6 15.5C6 15.5 0.75 9.6875 0.75 5.75ZM4.125 5.75C4.125 6.785 4.965 7.625 6 7.625C7.035 7.625 7.875 6.785 7.875 5.75C7.875 4.715 7.035 3.875 6 3.875C4.965 3.875 4.125 4.715 4.125 5.75Z" fill="#5C5C5C" />
                                      </svg>
                                    </span>
                                  {data.country_name}
                                  </span>
                                </li> */}
                      {/* <li className="mb-2">
                                  <span className="fw-500 text-dif-black font-14">{data.name}</span>
                                </li> */}
                      <li className="mb-2">
                        <span className="fw-500 text-dif-black font-14">
                          <span style={{ color: "purple" }}>Learner’s Country:</span> {data.country_name ? `${data.country_name}` : "N/A"}
                        </span>
                      </li>
                      <li className="mb-2">
                        <span className="fw-500 text-dif-black font-14">
                          <span style={{ color: "purple" }}> Learner’s Local Time: </span>
                          {data.local_time ? `${data.local_time}` : "N/A"}
                        </span>
                      </li>
                      <li className="mb-2">
                        <span className="fw-500 text-dif-black font-14">
                          <span style={{ color: "purple" }}>Requested Categories:</span> {data.request_category ? `${data.request_category}` : "N/A"}
                        </span>
                      </li>
                      <li className="mb-2">
                        <span className="fw-500 text-dif-black font-14">
                          <span style={{ color: "purple" }}>Requested Subjects:</span> {data.request_subject ? `${data.request_subject}` : "N/A"}
                        </span>
                      </li>
                      <li className="mb-2">
                        <span className="fw-500 text-dif-black font-14">
                          <span style={{ color: "purple" }}>Class Size: </span> {data.class_size ? `${data.class_size}` : "N/A"}
                        </span>
                      </li>
                      <li className="mb-2">
                        <span className="fw-500 text-dif-black font-14">
                          <span style={{ color: "purple" }}>Frequency of Lessons:</span> {data.subcategory_list}
                          {data.want_lessons}
                        </span>
                      </li>

                      {/* <li className="mb-2">
                                  <span className="fw-500 text-dif-black font-14">Requested Class : <span>{data.subcategory_list}/{data.want_lessons}.</span></span>
                                </li> */}
                    </ul>
                    <div className="num_of_teach text-end">
                      <span className="fs-5 mb-3 d-block">Number Of Teachers have Responded.</span>
                      <ul className="d-flex align-items-center justify-content-end">
                        {[...Array(5)].map((_, index) => (
                          <li key={index} className={index < data.Respond_teacher ? "active" : ""} />
                        ))}
                        <span className="ms-3 fw-600">{data.Respond_teacher}/5</span>
                      </ul>
                    </div>
                  </a>
                  <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                    <div className="button d-sm-flex align-items-center">
                      <Link
                        to="/previos_request_class_details"
                        state={{ id: data?.id }}
                        onClick={() => handleUpdateReadStatus(data?.id, data?.is_read)}
                        className="discussion_btn btn-theme px-3 d-inline-block text-center font-14"
                      >
                        View Details
                      </Link>
                      <Link
                        to="/sponsored_teachers"
                        state={{ id: data?.id }}
                        className="discussion_btn btn-theme px-3 d-inline-block text-center font-14 mx-2"
                      >
                        Contact Matched Teachers
                      </Link>
                    </div>
                    <div className="list_btw_dots mb-xl-0 mb-4">
                      <ul className="d-sm-flex align-items-center">
                        <li>
                          <span className="text-purple fw-500 font-14">{data.Format_time ? `${data.Format_time}` : "N/A"}</span>
                        </li>
                        <li>
                          <span className="text-purple fw-500 font-14">{data.formate_date ? `${data.formate_date}` : "N/A"}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <h4 className="d-flex justify-content-center mb-5 mt-5" style={{ "color": "#570861" }}>Data Not Found For Previous Class Request</h4>

            )}
          </div>
        </div>
      </div>
      {/* </div>/ */}
    </>
  );
}
