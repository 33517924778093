import React, { useEffect, useState } from "react";
import queryString from 'query-string';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getQuizInfo, historyQuiz } from "../../../api/apiHandler";
import BackToPrevious from "../../../components/backToPrevious";
import { TOAST_ERROR } from "../../../utils/common.service";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import OHeader_Dashboard from "../OInclude/OHeader_Dashboard";
import OFooter_Dashboard from "../OInclude/OFooter_Dashboard";
import CryptoJS from 'crypto-js';
var key = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNfTmDXHWVkQWdanYOm');
var iv = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNf');

export default function QuizVIewDetailO() {
  window.scrollTo(0, 0)
  const [quizInfo, setQuizInfo] = useState(null);
  console.log('quizInfo :', quizInfo);
  const location = useLocation();
  //   const { id: quiz_id } = location?.state;
  // var quiz_id = location?.state;
  // console.log('quiz_id :', quiz_id);
  // var id = quiz_id?.id;
  // console.log('idQuiz :', id);
  // console.log("🚀  PQuizInfo  quizInfo:", quizInfo);

  var after = useParams();
  if(location?.state){
    var quiz_id = location?.state;
    var id = quiz_id?.id;
    console.log('quiz_id :', id);
  }else{
    // var type = location?.pathname.split("/")[2];
    // console.log('location :', location);
    // console.log('type :', type);
    // var id = type

    var decryptedData = CryptoJS.AES.decrypt(decodeURIComponent(location?.pathname?.split('/')[2]), key, { iv: iv }).toString(CryptoJS.enc.Utf8);
    console.log('location :', location);

    console.log('decryptedData :', decryptedData);

    var descryptedJson = decryptedData.split('?')
    console.log('descryptedJson :', descryptedJson);
    //  var id = {}
    //  id.class = decryptedData.split("=")[1];
    const queryParams = queryString.parse(descryptedJson[1]);
    console.log('queryParams :', queryParams);

    // Extract specific query parameters
    const type = queryParams?.type;

    const typeClass = queryParams?.type_class;

    var id = descryptedJson[0]
    console.log('id :', id);
 
    // id.class = type
    // console.log('idtype :', id);
  }

  const fetchQuizInfo = async () => {
    try {
      const { code, data, message } = await historyQuiz({ quiz_id: id });
      console.log("abc:", data);
      if (code == 1) setQuizInfo(data[0]);
      else TOAST_ERROR(message);
    } catch (err) {
      TOAST_ERROR(err.message);
    }
  };

  useEffect(() => {
    id && fetchQuizInfo();
  }, [id]);

  return (
    <>
      <JqueryLoad />
      <OHeader_Dashboard />
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div>
              <BackToPrevious />
              <div>
                <div className="class_lsit_section">
                  <div className="mb-4">
                    <h2 className="mb-2 poppins fw-600">Start Quiz</h2>
                    <p className="fs-6 text-gray">Start Your Quiz </p>
                  </div>
                  <div className="quiz_card">
                    <div className="card_title mb-4">
                      <h3 className="mb-2 poppins fw-bold">{quizInfo?.subject_category} Quiz</h3>
                      <p className="fs-6 text-dif-black">Read the following instructions</p>
                    </div>
                    <div className="row mb-5 align-items-center">
                      <div className="col-xl-6 mb-xl-0 mb-4 left_side">

                        {/* <video id="video" className loop width="100%" controls="hide">
                          <source src={quizInfo?.quiz_video} type="video/mp4" />
                        </video> */}
                        <video id="video" loop width="100%" src={quizInfo?.quiz_video} controls />

                      </div>
                      <div className="col-xl-6 right_side">
                        <div className="quiz_info ps-xl-5">
                          <table className="table table-borderless">
                            <tbody>
                              <tr>
                                <td className="text-purple fw-600">Date: </td>
                                <td className="text-dif-black">{quizInfo?.created_date}</td>
                              </tr>
                              {quizInfo?.duration && (
                                <tr>
                                  <td className="text-purple fw-600">Time Limit:</td>
                                  <td className="text-dif-black">{quizInfo?.duration}</td>
                                </tr>
                              )}
                              {/* <tr>
                                <td className="text-purple fw-600">Attempts:</td>
                                <td className="text-dif-black">Once</td>
                              </tr> */}
                              <tr>
                                <td className="text-purple fw-600">Points:</td>
                                <td className="text-dif-black">{quizInfo?.total_points} Points</td>
                              </tr>
                              <tr>
                                <td className="text-purple fw-600">No Of Ques:</td>
                                <td className="text-dif-black">{quizInfo?.no_of_question} Questions</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="quiz_Inst">
                      <h4 className="mb-3 text-dif-black poppins fw-600">Instructions</h4>
                      <p className="fs-6 mb-lg-4 mb-md-3 mb-2">{quizInfo?.instruction}</p>

                    </div>
                    <div className="text-end pt-2">
                      <Link to={"/edit-quiz"} state={{ id: quiz_id?.id, class_id: quiz_id?.class_id }} className="btn-theme bg-yellow d-inline-block fw-500 text-dif-black mx-2">
                        Edit Quiz
                      </Link>
                      <Link to={"/organization-start-quiz"} state={quizInfo} className="btn-theme bg-yellow d-inline-block fw-500 text-dif-black">
                        Start Quiz
                      </Link>
                  
                    </div>
                  </div>
                  {/*end*/}
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>
      <OFooter_Dashboard />
    </>
  );
}
