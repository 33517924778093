// config.js
export const baseUrls = {
  kids: "/kids",
  parents: "/parents",
  organization: "/organization",
  teacher: "/teacher",
};
export const SOCKET_URL = "https://api.studentscholars.com:4225/";
// export const SOCKET_URL = "http://13.234.122.246:4225/";

export const SHARE_DETAIL_URL = "http://13.234.122.246/studentscholar"
export const Video_URL = "https://hlink-bhavinp-s3.s3.amazonaws.com/student_scholar/video"
export const Video_URL1 = "https://studentscholars.s3.amazonaws.com/video"


export const ENC_KEY = process.env.REACT_APP_KEY
export const ENC_IV = process.env.REACT_APP_IV

export const REACT_APP_BASE_URL = "http://99.79.109.5/studentscholar";
export const BASE_NAME = "/studentscholar";

// cd Downloads/opt/FileZilla3/bin/
