import React from 'react';
import { htmlToText } from 'html-to-text';

const DesShow = ({ text, length }) => {
  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };

  // Convert HTML to plain text
  const plainText = htmlToText(text || '', {
    wordwrap: false,
    ignoreImage: true,
    ignoreHref: true,
  });

  const truncatedText = truncateString(plainText, length);

  return (
    <p
      dangerouslySetInnerHTML={{ __html: truncatedText }}
      className="fs-6"
      style={{ fontWeight: 'normal', fontSize: '1rem', textDecoration: 'none' }} // Adjust the font size and weight
    />
  );
};

export default DesShow;
