import React from 'react'
import { Link } from 'react-router-dom'
import JqueryLoad from '../../../JqueryLoad/JqueryLoad'
import OFooter_Dashboard from '../OInclude/OFooter_Dashboard'
import OHeader_Dashboard from '../OInclude/OHeader_Dashboard'

export default function OUpcomingMeeting() {
  return (
    <>
<JqueryLoad/>
<OHeader_Dashboard/>
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div> 
            </div> 
          </header>  
          <div className="class-content">
            <div className>
              {/*start*/}
              <a href="#" className="mb-4 arrow_left mb-3 d-block" onclick="window.history.back()">
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span className="ms-2">Back</span>
                </span></a>
              {/*end*/} 
              {/*start*/}
              <div className> 
                <div className="row">
                  <div className="col-xl-7">
                    <form className="mb-4"> 
                      <div className="meeting_div">
                        <div className="d-flex mb-4 align-items-center justify-content-between">
                          <h3 className="poppins fw-600">Upcoming Meetings</h3>
                          <Link to="/scheduling_class" className="btn-theme bg-yellow">Add Schedule</Link>
                        </div>
                        <ul className="next_meets mb-4">
                          <li className="card mb-3">
                            <div className="d-md-flex justify-content-between align-items-center">
                              <div className> 
                                <h4 className="fs-6 poppins fw-600 mb-2">Next meets on Sat, May 20, 3 PM</h4>
                                <span className="mb-md-0 mb-3 d-inline-block font-14">StudentScholars , in progress , 1 space available</span>
                              </div>
                              <div className="d-flex align-items-center">
                                <ul className="me-4 edit_delete d-flex align-items-center justify-content-end">
                                  <li>
                                    <a href="#!" className>
                                      <svg width={20} height={20} viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.80679 25.6355C1.80679 27.406 3.24075 28.84 5.01126 28.84H17.8291C19.5995 28.84 21.0335 27.406 21.0335 25.6355V6.40886H1.80679V25.6355ZM17.0279 1.6022L15.4257 0H7.41459L5.81232 1.6022H0.20459V4.80667H22.6357V1.6022H17.0279Z" fill="#FF2929" />
                                      </svg>
                                    </a>
                                  </li>
                                  <li>
                                    <Link to="/organization-add-class" className>
                                      <svg width={20} height={23} viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.10738 18.8439C3.32277 18.8439 3.36584 18.8224 3.55969 18.7793L7.43661 18.0039C7.84584 17.8962 8.25507 17.7024 8.57815 17.3793L17.9689 7.98851C19.412 6.54543 19.412 4.06851 17.9689 2.62543L17.172 1.78543C15.7289 0.342356 13.2305 0.342356 11.7874 1.78543L2.39661 11.1977C2.09508 11.4993 1.87969 11.93 1.772 12.3393L0.953537 16.2593C0.845845 16.9916 1.06123 17.7024 1.57815 18.2193C1.98738 18.6285 2.59046 18.8439 3.10738 18.8439V18.8439ZM3.83969 12.7485L13.2305 3.3362C13.8551 2.71159 14.9966 2.71159 15.5997 3.3362L16.4181 4.15466C17.1505 4.88697 17.1505 5.92082 16.4181 6.63159L7.04892 16.0439L3.06431 16.7116L3.83969 12.7485Z" fill="#570861" />
                                        <path d="M17.8827 20.1797H1.98734C1.36272 20.1797 0.953491 20.5889 0.953491 21.2135C0.953491 21.8382 1.47041 22.2474 1.98734 22.2474H17.7966C18.4212 22.2474 18.9381 21.8382 18.9381 21.2135C18.9166 20.5889 18.3997 20.1797 17.8827 20.1797Z" fill="#570861" />
                                      </svg>
                                    </Link>
                                  </li>
                                </ul>
                                <a href="#" className="btn-theme px-2 py-1">Public</a>
                              </div>
                            </div>
                          </li>
                          <li className="card mb-3">
                            <div className="d-md-flex justify-content-between align-items-center">
                              <div className> 
                                <h4 className="fs-6 poppins fw-600 mb-2">Next meets on Sat, May 20, 3 PM</h4>
                                <span className="mb-md-0 mb-3 d-inline-block font-14">StudentScholars , in progress , 1 space available</span>
                              </div>
                              <div className="d-flex align-items-center">
                                <ul className="me-4 edit_delete d-flex align-items-center justify-content-end">
                                  <li>
                                    <a href="#!" className>
                                      <svg width={20} height={20} viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.80679 25.6355C1.80679 27.406 3.24075 28.84 5.01126 28.84H17.8291C19.5995 28.84 21.0335 27.406 21.0335 25.6355V6.40886H1.80679V25.6355ZM17.0279 1.6022L15.4257 0H7.41459L5.81232 1.6022H0.20459V4.80667H22.6357V1.6022H17.0279Z" fill="#FF2929" />
                                      </svg>
                                    </a>
                                  </li>
                                  <li>
                                    <Link to="/organization-add-class" className>
                                      <svg width={20} height={23} viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.10738 18.8439C3.32277 18.8439 3.36584 18.8224 3.55969 18.7793L7.43661 18.0039C7.84584 17.8962 8.25507 17.7024 8.57815 17.3793L17.9689 7.98851C19.412 6.54543 19.412 4.06851 17.9689 2.62543L17.172 1.78543C15.7289 0.342356 13.2305 0.342356 11.7874 1.78543L2.39661 11.1977C2.09508 11.4993 1.87969 11.93 1.772 12.3393L0.953537 16.2593C0.845845 16.9916 1.06123 17.7024 1.57815 18.2193C1.98738 18.6285 2.59046 18.8439 3.10738 18.8439V18.8439ZM3.83969 12.7485L13.2305 3.3362C13.8551 2.71159 14.9966 2.71159 15.5997 3.3362L16.4181 4.15466C17.1505 4.88697 17.1505 5.92082 16.4181 6.63159L7.04892 16.0439L3.06431 16.7116L3.83969 12.7485Z" fill="#570861" />
                                        <path d="M17.8827 20.1797H1.98734C1.36272 20.1797 0.953491 20.5889 0.953491 21.2135C0.953491 21.8382 1.47041 22.2474 1.98734 22.2474H17.7966C18.4212 22.2474 18.9381 21.8382 18.9381 21.2135C18.9166 20.5889 18.3997 20.1797 17.8827 20.1797Z" fill="#570861" />
                                      </svg>
                                    </Link>
                                  </li>
                                </ul>
                                <a href="#" className="btn-theme px-2 py-1">Public</a>
                              </div>
                            </div>
                          </li>
                          <li className="card mb-3">
                            <div className="d-md-flex justify-content-between align-items-center">
                              <div className> 
                                <h4 className="fs-6 poppins fw-600 mb-2">Next meets on Sat, May 20, 3 PM</h4>
                                <span className="mb-md-0 mb-3 d-inline-block font-14">StudentScholars , in progress , 1 space available</span>
                              </div>
                              <div className="d-flex align-items-center">
                                <ul className="me-4 edit_delete d-flex align-items-center justify-content-end">
                                  <li>
                                    <a href="#!" className>
                                      <svg width={20} height={20} viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.80679 25.6355C1.80679 27.406 3.24075 28.84 5.01126 28.84H17.8291C19.5995 28.84 21.0335 27.406 21.0335 25.6355V6.40886H1.80679V25.6355ZM17.0279 1.6022L15.4257 0H7.41459L5.81232 1.6022H0.20459V4.80667H22.6357V1.6022H17.0279Z" fill="#FF2929" />
                                      </svg>
                                    </a>
                                  </li>
                                  <li>
                                    <Link to="/organization-add-class" className>
                                      <svg width={20} height={23} viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.10738 18.8439C3.32277 18.8439 3.36584 18.8224 3.55969 18.7793L7.43661 18.0039C7.84584 17.8962 8.25507 17.7024 8.57815 17.3793L17.9689 7.98851C19.412 6.54543 19.412 4.06851 17.9689 2.62543L17.172 1.78543C15.7289 0.342356 13.2305 0.342356 11.7874 1.78543L2.39661 11.1977C2.09508 11.4993 1.87969 11.93 1.772 12.3393L0.953537 16.2593C0.845845 16.9916 1.06123 17.7024 1.57815 18.2193C1.98738 18.6285 2.59046 18.8439 3.10738 18.8439V18.8439ZM3.83969 12.7485L13.2305 3.3362C13.8551 2.71159 14.9966 2.71159 15.5997 3.3362L16.4181 4.15466C17.1505 4.88697 17.1505 5.92082 16.4181 6.63159L7.04892 16.0439L3.06431 16.7116L3.83969 12.7485Z" fill="#570861" />
                                        <path d="M17.8827 20.1797H1.98734C1.36272 20.1797 0.953491 20.5889 0.953491 21.2135C0.953491 21.8382 1.47041 22.2474 1.98734 22.2474H17.7966C18.4212 22.2474 18.9381 21.8382 18.9381 21.2135C18.9166 20.5889 18.3997 20.1797 17.8827 20.1797Z" fill="#570861" />
                                      </svg>
                                    </Link>
                                  </li>
                                </ul>
                                <a href="#" className="btn-theme px-2 py-1">Public</a>
                              </div>
                            </div>
                          </li>
                          <li className="card mb-3">
                            <div className="d-md-flex justify-content-between align-items-center">
                              <div className> 
                                <h4 className="fs-6 poppins fw-600 mb-2">Next meets on Sat, May 20, 3 PM</h4>
                                <span className="mb-md-0 mb-3 d-inline-block font-14">StudentScholars , in progress , 1 space available</span>
                              </div>
                              <div className="d-flex align-items-center">
                                <ul className="me-4 edit_delete d-flex align-items-center justify-content-end">
                                  <li>
                                    <a href="#!" className>
                                      <svg width={20} height={20} viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.80679 25.6355C1.80679 27.406 3.24075 28.84 5.01126 28.84H17.8291C19.5995 28.84 21.0335 27.406 21.0335 25.6355V6.40886H1.80679V25.6355ZM17.0279 1.6022L15.4257 0H7.41459L5.81232 1.6022H0.20459V4.80667H22.6357V1.6022H17.0279Z" fill="#FF2929" />
                                      </svg>
                                    </a>
                                  </li>
                                  <li>
                                    <Link to="/organization-add-class" className>
                                      <svg width={20} height={23} viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.10738 18.8439C3.32277 18.8439 3.36584 18.8224 3.55969 18.7793L7.43661 18.0039C7.84584 17.8962 8.25507 17.7024 8.57815 17.3793L17.9689 7.98851C19.412 6.54543 19.412 4.06851 17.9689 2.62543L17.172 1.78543C15.7289 0.342356 13.2305 0.342356 11.7874 1.78543L2.39661 11.1977C2.09508 11.4993 1.87969 11.93 1.772 12.3393L0.953537 16.2593C0.845845 16.9916 1.06123 17.7024 1.57815 18.2193C1.98738 18.6285 2.59046 18.8439 3.10738 18.8439V18.8439ZM3.83969 12.7485L13.2305 3.3362C13.8551 2.71159 14.9966 2.71159 15.5997 3.3362L16.4181 4.15466C17.1505 4.88697 17.1505 5.92082 16.4181 6.63159L7.04892 16.0439L3.06431 16.7116L3.83969 12.7485Z" fill="#570861" />
                                        <path d="M17.8827 20.1797H1.98734C1.36272 20.1797 0.953491 20.5889 0.953491 21.2135C0.953491 21.8382 1.47041 22.2474 1.98734 22.2474H17.7966C18.4212 22.2474 18.9381 21.8382 18.9381 21.2135C18.9166 20.5889 18.3997 20.1797 17.8827 20.1797Z" fill="#570861" />
                                      </svg>
                                    </Link>
                                  </li>
                                </ul>
                                <a href="#" className="btn-theme px-2 py-1">Private</a>
                              </div>
                            </div>
                          </li> 
                        </ul> 
                      </div>
                    </form>  
                  </div>
                </div>  
              </div>
              {/*end*/} 
            </div>
          </div>
        </div> 
      </main>
 <OFooter_Dashboard/>
    </>
  )
}
