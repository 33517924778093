import React, { useState, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { json, Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/bootstrap.css";
import "intl-tel-input/build/css/intlTelInput.css";
import intlTelInput from "intl-tel-input";
import Modals from "react-modal";
import SendOtpModel from "../Models/SendOtpModel";
import ReactIntlTelInput from "react-intl-tel-input-v2";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import countryList from "react-select-country-list";
import CountryFlag from 'react-country-flag';
import moment from "moment-timezone";
import { add_class_country_list, privacy_policy, signUpp, state_list, terms_conditions } from "../../api/apiHandler";
import { TOAST_ERROR, TOAST_SUCCESS, convertToBase64 } from "../../utils/common.service";
import { uploadImageOnAWS } from "../aws/Service";
import BackToPrevious from "../../components/backToPrevious";
import ReactCountryFlag from "react-country-flag";
import { auth, google, facebook } from '../../utils/Config';
import { signInWithPopup } from 'firebase/auth'
import { async } from '@firebase/util';
import CommonTruncatedText from "../../utils/commonTruncatedText";

var COMMON_STYLE_FOR_POPUP = {
  content: {
    // backgroundColor: 'rgb(0 0 0 / 56%)',
    background: "none",
    border: "0px",
  },
};

export default function Signup2({ isLoader }) {
  const navigate = useNavigate();
  const [lernerCategory, setLernerCategory] = useState("below_18");
  const [modalIsOpen, setIsOpen] = useState(false);
  console.log('modalIsOpen :', modalIsOpen);
  const [isChecked, setChecked] = useState(false);
  const [phoneDetails, setPhoneDetails] = useState({});
  const [countryCode, setCountryCode] = useState();

  const [modalIsOpenTearms, setIsOpenTearms] = useState(false);

  const [Terms, setTearms] = useState();
  const TermsService = async () => {
    isLoader(true)
    console.log('test');
    try {
      const response = await terms_conditions()
      console.log('response :', response);
      if (response?.code == 1) {
        setTearms(response?.data)
        isLoader(false)
        setIsOpenTearms(true)

      } else {
        isLoader(false)
      }
    } catch (error) {

    }
  }

  const [modalIsOpenPrivacy, setIsOpenPrivacy] = useState(false);
  const [poli, setPolicy] = useState();
  const [countryName, setCountryName] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [stateData, setStateData] = useState();
  const [phoneCountry, setPhoneCountry] = useState();
  const [phoneCountryCode, setPhoneCountryCode] = useState();


  const PrivacyPolicy = async () => {
    isLoader(true)
    try {
      const response = await privacy_policy()
      console.log('response :', response);
      if (response?.code == 1) {
        setPolicy(response?.data)
        isLoader(false)
        setIsOpenPrivacy(true)
      } else {
        isLoader(false)
      }
    } catch (error) {
    }
  }


  useEffect(() => {
    countryListData()
    selectedCountry && stateList()
  }, [selectedCountry])

  const stateList = async () => {
    try {
      const response = await state_list({ parent_country_id: selectedCountry?.id })
      console.log('response1 :', response);
      if (response?.code == 1) {
        setStateData(response?.data)
      } else {
        setStateData()
      }
    } catch (error) {

    }

  }

  const showClassList = (() => {
    return (
      <div className="form-group mb-4">
        <label className="mb-2">Provinces and Territories</label>
        <Select
          options={stateData.map(state => ({ value: state.state_id, label: state.state_province }))}
          aria-label="Default select example"
          id="select2-example"
          name="province"
          {...register("province")}
        />
        <p className="mt-2 font-bold col-red">
          <span style={{ color: "red" }}>{errors.province?.message}</span>
        </p>
      </div>
    );
  });

  const countryListData = async () => {
    try {
      const response = await add_class_country_list();
      console.log('response :', response);
      if (response?.code == 1) {
        const transformedOptions = response?.data.map(country => ({
          value: country.country_name,
          id: country.country_id,
          label: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {/* <span
                style={{
                  fontSize: '20px',
                  marginRight: '8px'
                }}
              >
                {country.country_image}
              </span> */}
              <img
                src={country.country_image}
                alt={`${country.country_image} flag`}
                style={{
                  width: '30px',
                  height: '20px',
                  objectFit: 'cover',
                  marginRight: '8px'
                }}
              />
              {country.country_name}
            </div>
          ),
        }));
        setCountryName(transformedOptions);
      } else {
        // Handle the else case
      }
    } catch (error) {
      // Handle the error case
    }
  };

  const handleCountryChange = (selectedOption) => {
    console.log('selectedOption :', selectedOption);
    setPhoneCountry(selectedOption?.value)

    setValue("country", selectedOption ? selectedOption.id : null);
    clearErrors("country");
    if (selectedOption?.value === 'Canada') {
      setSelectedCountry(selectedOption)
    }
    else {
      setSelectedCountry('')
    }
    console.log('Selected Country ID:', selectedOption ? selectedOption : 'None');
  };

  const handleStateChange = (selectedOption) => {

    console.log('selectedOption :', selectedOption);
    setValue("province", selectedOption ? selectedOption.value : null);
    clearErrors("province");

  }

  const handleCheckboxChange = () => {
    console.log("isChecked :", isChecked);
    setChecked(!isChecked);
    if (!isChecked) {
      setValue("referral_code", ""); // Using setValue to reset the field
    }
  };

  const handlePhoneChange = (phone, country) => {
    console.log('phone :', phone);
    console.log('country :', country);
    // setPhoneDetails({ phone, country });
    if (country && country.dialCode) {
      const formattedValue = phone.substring(country.dialCode.length);
      setPhoneCountryCode(country?.name)
      setCountryCode(country.dialCode);
      setValue("phone", formattedValue);
    }
    clearErrors('phone')

  };

  let abc = (phone) => {
    // Do nothing
    setLernerCategory(phone);
    setProfileImageState("");
    clearErrors();
    // reset();
    // console.log(phone)
  };

  // const [value, setCountry] = useState("");
  // // console.log("hello",value)


  // const options = useMemo(() => {
  //   return countryList().getData().map((country) => ({
  //     value: country.value,
  //     label: (
  //       <div>
  //         <ReactCountryFlag
  //           countryCode={country.value}
  //           style={{ fontSize: "1.5em", marginRight: "5px" }}
  //         />
  //         {country.label}
  //       </div>
  //     ),
  //   }));
  // }, []);

  // const options = useMemo(() => {
  //   return countryList().getData().map((country, index) => {
  //     // Append sequential id to each country object
  //     const countryWithId = {
  //       ...country,
  //       id: index + 1
  //     };
  //     console.log('countryWithId :', countryWithId);
  //     return {
  //       ...countryWithId,
  //       value: country.value,
  //       label: (
  //         // console.log('country :', country),

  //         <div>
  //           <ReactCountryFlag
  //             countryCode={country.value}
  //             style={{ fontSize: "1.5em", marginRight: "5px" }}
  //           />
  //           {country.label}
  //         </div>
  //       ),
  //     };
  //   });
  // }, []);

  //////Social sign up function//////////


  const [loginType, setLoginType] = useState("S");
  const [socialId, setSocialId] = useState("");


  const handleSocialSignin = async (provider, login_type) => {
    const result = await signInWithPopup(auth, provider)
    console.log('result :', result?.user?.providerData[0]?.uid);

    if (result.user) {
      setValue("email", result?.user?.providerData[0]?.email)
      setValue("full_name", result?.user?.providerData[0]?.displayName)
      setValue("phone", result?.user?.providerData[0]?.phoneNumber)
      setValue("password", "Ssss@8495")
      setSocialId(result?.user?.providerData[0]?.uid)
      setLoginType(login_type)
    }
  }

  let schema;

  if (lernerCategory == "below_18") {
    schema = yup.object().shape({

      email: yup
        .string()
        .email()
        .matches(/^(?!.*@[^,]*,)/)
        .required("Please enter email"),
      password: yup.string()
        .required("Please enter password")
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        .min(8, "Password must contain 8 or more characters, at least one uppercase, one lowercase, one digit, and one special character")
        .matches(/^\S+$/, "Password cannot contain leading or trailing spaces")
        .matches(/(?=.*[a-z])(?=.*[A-Z])/, "Password must contain at least one uppercase and one lowercase letter")
        .matches(/\d/, "Password must contain at least one digit")
        .matches(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, "Password must contain at least one special character"),

      phone: yup.string().required("Please enter phone number")
        .matches(/^[A-Za-z0-9]+$/, "Only numbers are allowed")
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        .min(2, "Field must contain atleast 2 or 3 characters long"),

      country: yup.string().required("Please enter country"),
      checkbox: yup
        .boolean()
        .oneOf([true], "You must agree to the Terms of Service and Privacy Policy")
        .required("You must agree to the Terms of Service and Privacy Policy"),
    });
  } else if (lernerCategory == "above_18") {
    schema = yup.object().shape({

      email: yup
        .string()
        .email()
        .matches(/^(?!.*@[^,]*,)/)
        .required("Please enter email"),
      password: yup.string()
        .required("Please enter password")
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        .min(8, "Password must contain 8 or more characters, at least one uppercase, one lowercase, one digit, and one special character")
        .matches(/^\S+$/, "Password cannot contain leading or trailing spaces")
        .matches(/(?=.*[a-z])(?=.*[A-Z])/, "Password must contain at least one uppercase and one lowercase letter")
        .matches(/\d/, "Password must contain at least one digit")
        .matches(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, "Password must contain at least one special character"),

      phone: yup.string().required("Please enter phone number")
        .matches(/^[A-Za-z0-9]+$/, "Only numbers are allowed")
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        .min(2, "Field must contain atleast 2 or 3 characters long"),

      country: yup.string().required("Please enter country"),
      checkbox: yup
        .boolean()
        .oneOf([true], "You must agree to the Terms of Service and Privacy Policy")
        .required("You must agree to the Terms of Service and Privacy Policy"),
    });
  } else {
    schema = yup.object().shape({

      email: yup
        .string()
        .email()
        .matches(/^(?!.*@[^,]*,)/)
        .required("Please enter email"),

      password: yup.string()
        .required("Please enter password")
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        .min(8, "Password must contain 8 or more characters, at least one uppercase, one lowercase, one digit, and one special character")
        .matches(/^\S+$/, "Password cannot contain leading or trailing spaces")
        .matches(/(?=.*[a-z])(?=.*[A-Z])/, "Password must contain at least one uppercase and one lowercase letter")
        .matches(/\d/, "Password must contain at least one digit")
        .matches(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, "Password must contain at least one special character"),
      phone: yup.string().required("Please enter phone number"),
      country: yup.string().required("Please enter country"),
      checkbox: yup
        .boolean()
        .oneOf([true], "You must agree to the Terms of Service and Privacy Policy")
        .required("You must agree to the Terms of Service and Privacy Policy"),
    });
  }

  if (loginType == "S") {
    if (lernerCategory == "below_18") {
      schema = schema.shape({
        full_name: yup
          .string()
          .required("Please enter full name")
          .matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/, "Only alphabet characters are allowed in the Full Name field")
          .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        ,

      });
    }
    else if (lernerCategory == "above_18") {
      schema = schema.shape({
        full_name: yup
          .string()
          .required("Please enter student name")
          .matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/, "Only alphabet characters are allowed in the Full Name field")
          .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value)),


      });
    }
    else {
      schema = schema.shape({
        full_name: yup.string().required("Please enter organization name").matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/, "Only alphabet characters are allowed in the Organization Full Name field")
          .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value)),



      });
    }
  } else {
    schema = schema.shape({
      full_name: yup
        .string()
        .required("Please enter full name")
        .matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/, "Only alphabet characters are allowed in the Full Name field")
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value)),

    });
  }

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    watch,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // console.log(errors)

  const [profileImageState, setProfileImageState] = useState("");

  const handleImageChange = async (image) => {
    setValue("imageUpload", image);
    const bufferImage = await convertToBase64(image);
    setProfileImageState(bufferImage);
    clearErrors("imageUpload");
  };

  let onSubmit = async (data) => {
    localStorage.clear();
    console.log('data :', data);
    // return
    isLoader(true)
    if (data.imageUpload != null) {
      var image = await uploadImageOnAWS(data?.imageUpload, "profile_image");
    }
    var val = document.getElementsByClassName("iti__selected-flag");
    data.time_zone = selectedTimezone;
    data.country_code = "+" + countryCode
    data.category = lernerCategory;
    data.role = "Learn";
    data.device_token = localStorage.getItem('fcmToken')

    localStorage.setItem("userType", lernerCategory);

    if (lernerCategory == "below_18") {
      // alert('gfy');
      let submitData = {
        full_name: data?.full_name,
        email: data?.email,
        // phone: data?.phone,
        // password: data?.password,
        phone: data?.phone,
        login_type: loginType,
        role: "Learn",
        category: data.category,
        country_code: data.country_code,
        country_name: data.country,
        time_zone: data.time_zone,
        use_refferal: data.referral_code,
        countryCode: phoneCountry,
        phoneCode: phoneCountryCode
      };
      if (data.imageUpload != null) {
        submitData.profile_image = image;
      }
      if (data.province != null) {
        submitData.state_id = data.province
      }

      if (loginType == "S") {
        submitData.password = data.password
      } else {
        submitData.social_id = socialId
      }

      console.log("submitData :", submitData);
      // return
      localStorage.setItem("MAid", data.id);
      console.log(submitData)
      signUpp(submitData).then((res) => {
        console.log("res :", res);

        if (res.code == 1) {
          console.log("PAid", res.data.id);
          console.log("Poto", res.data.otp);
          console.log("MAname", res.data?.full_name);

          localStorage.setItem("PAid", res.data?.id);
          localStorage.setItem("Otp", res.data?.otp);
          localStorage.setItem("MAname", res.data?.full_name);
          isLoader(false)

          setIsOpen(true);
          // localStorage.setItem("PASignupStep", res.data?.signup_step);
          // isLoader(false);
        } else {
          isLoader(false)

          TOAST_ERROR(res.message);
        }
      });
      // reset();

      //  localStorage.setItem("step_1",JSON.stringify(data))
      // navigate("/sign_in_step3");
    } else if (lernerCategory == "above_18") {
      let submitData = {
        full_name: data?.full_name,
        email: data?.email,
        password: data?.password,
        phone: data?.phone,
        login_type: loginType,
        role: "Learn",
        category: data.category,
        country_code: data.country_code,
        country_name: data.country,
        time_zone: data.time_zone,
        use_refferal: data.referral_code,
        countryCode: phoneCountry,
        phoneCode: phoneCountryCode

      };
      if (data.imageUpload != null) {
        submitData.profile_image = image;
      }
      if (data.province != null) {
        submitData.state_id = data.province
      }
      if (loginType == "S") {
        submitData.password = data.password
      } else {
        submitData.social_id = socialId
      }

      console.log("submitData :", submitData);


      signUpp(submitData).then((res) => {
        console.log("res :", res);

        if (res.code == 1) {
          setIsOpen(true);
          console.log("PAid", res.data.id);
          console.log("MAname", res.data?.full_name);
          localStorage.setItem("PAid", res.data?.id);
          localStorage.setItem("MAname", res.data?.full_name);
          isLoader(false)

          // localStorage.setItem("PASignupStep", res.data?.signup_step);
          // isLoader(false);
        } else {
          isLoader(false)

          TOAST_ERROR(res.message);
        }
      });
      // reset();

      // navigate("/kidsDashboard");
    } else {
      let submitData = {
        // "profile_image": image,
        full_name: data?.full_name,
        email: data?.email,
        // password: data?.password,
        phone: data?.phone,
        login_type: loginType,
        role: "Learn",
        category: data.category,
        country_code: data.country_code,
        country_name: data.country,
        time_zone: data.time_zone,
        use_refferal: data.referral_code,
        countryCode: phoneCountry,
        phoneCode: phoneCountryCode

      };
      if (data.imageUpload != null) {
        submitData.profile_image = image;
      }
      if (data.province != null) {
        submitData.state_id = data.province

      }
      if (loginType == "S") {
        submitData.password = data.password
      } else {
        submitData.social_id = socialId
      }
      console.log("submitData :", submitData);

      localStorage.setItem("MAid", data.id);

      signUpp(submitData).then((res) => {
        console.log("res :", res);

        if (res.code == 1) {
          console.log("PAid", res.data.id);
          console.log("Poto", res.data.otp);
          console.log("MAname", res.data?.full_name);
          localStorage.setItem("PAid", res.data?.id);
          localStorage.setItem("MAname", res.data?.full_name);
          isLoader(false)

          setIsOpen(true);

          // localStorage.setItem("PASignupStep", res.data?.signup_step);
          // isLoader(false);
        } else {
          isLoader(false)

          TOAST_ERROR(res.message);
        }
      });
      // reset();
      // navigate("/sign_in_after_school_step3");
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const [selectedTimezone, setSelectedTimezone] = useState("");
  console.log("selectedTimezone :", selectedTimezone);
  const timezones = moment.tz.names().map((tz) => ({
    value: tz,
    label: `${tz} (UTC ${moment.tz(tz).format("Z")})`,
  }));

  useEffect(() => {
    // Detect and set the user's timezone as the default value.
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setSelectedTimezone(userTimezone);
  }, []);

  let handleChange = (selectedOption) => {
    setSelectedTimezone(selectedOption.value);
    // console.log(setSelectedTimezone)
  };

  const [modalIsOpenForgot, setIsOpenForgot] = useState(false);
  // console.log('model',modalIsOpenForgot)

  const [selectSubject, setSelectSubject] = useState([]);
  const [selectDone, setSelectDone] = useState(false);
  const handleSelectSubject = (subjectName) => {
    // selectSubject.push(subjectName)
    // setSelectSubject([...selectSubject,subjectName])
    // console.log('selectSubject',selectSubject)
    console.log(selectSubject);
    if (selectSubject.includes(subjectName)) {
      // console.log(selectSubject.indexOf(subjectName))
      // selectSubject.includes(subjectName)
      // var index = selectSubject.indexOf(subjectName)
      // delete selectSubject[index]
      let filteredArray = selectSubject.filter((item) => item !== subjectName);
      setSelectSubject(filteredArray);
    } else {
      setSelectSubject((prevSelectSubject) => [...new Set([...prevSelectSubject, subjectName])]);
    }

    // console.log('subjectName :', subjectName)
  };

  return (
    <>
      <Helmet>
        {/* <script>{`
    	// -----Country Code Selection
        $("#mobile_code").intlTelInput({
            initialCountry: "in",
            separateDialCode: true,
            // utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.4/js/utils.js"
        });
    `}

                </script> */}

        {/* <script>
                    {`
                   
                    const input_phone = document.querySelector("#phone");
                    window.intlTelInput(input_phone, {
                      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js",
                    });

                    intlTelInput(input_phone, {
                        customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
                          return "e.g. " + selectedCountryPlaceholder;
                        },
                      });
                      
                      intlTelInput(input_phone, {
                        initialCountry: "auto",
                        geoIpLookup: function(callback) {
                          fetch("https://ipapi.co/json")
                            .then(function(res) { return res.json(); })
                            .then(function(data) { callback(data.country_code); })
                            .catch(function() { callback("us"); });
                        }
                      })
                    `}
                </script> */}
        <script>
          {`		function readURL(input) {
		    if (input.files && input.files[0]) {
		        var reader = new FileReader();
		        reader.onload = function(e) {
		            $('#imagePreview').css('background-image', 'url(' + e.target.result + ')');
		            $('#imagePreview').hide();
		            $('#imagePreview').fadeIn(650);
		        }
		        reader.readAsDataURL(input.files[0]);
		    }
		}
		$("#imageUpload").change(function() {
		    readURL(this);
		});
        `}
        </script>
        <script>
          {`	
    // Get references to the select element and content divs
    const dropdown = document.getElementById('dropdown');
    const content1 = document.getElementById('content1');
    const content2 = document.getElementById('content2');
    const content3 = document.getElementById('content3');

    // Function to show or hide content based on the selected option
    function toggleContent() {
        // Hide all content divs first
        content1.style.display = 'none';
        content2.style.display = 'none';
        content3.style.display = 'none';

        // Get the selected option's value
        const selectedOption = dropdown.value;

        // Show the corresponding content div based on the selected option
        if (selectedOption === 'option1') {
            content1.style.display = 'block';
        } else if (selectedOption === 'option2') {
            content2.style.display = 'block';
        } else if (selectedOption === 'option3') {
            content3.style.display = 'block';
        }
    }

    // Attach event listener to the dropdown select
    dropdown.addEventListener('change', toggleContent);

    // Initially, call the toggleContent function to show/hide content based on the default selected option
    toggleContent();
    `}
        </script>
        <style>
          {`
    .react-tel-input .country-list {
        max-height: 300px; /* Set a maximum height for the dropdown */
        overflow-y: auto; /* Enable vertical scrolling if the content exceeds the max height */
      }
      
      .react-tel-input .country {
        display: flex;
        align-items: center;
        padding: 8px 16px; /* Adjust the padding as needed */
      }
      
      .react-tel-input .country .react-tel-input__country-flag {
        margin-right: 10px; /* Add some spacing between the flag and the country name */
        width: 20px; /* Set the width of the country flag */
        height: 20px; /* Set the height of the country flag */
      }
      
      .react-tel-input .country .react-tel-input__country-name {
        font-size: 14px; /* Adjust the font size of the country name */
      }

      .react-tel-input .form-control{
            margin-left: 45px;
      }
    `}
        </style>
      </Helmet>
      <main>
        <div className="login_sec">
          <div className="container-fluid">
            <div className="row">
              <div
                className="align-items-center col-lg-6 d-flex justify-content-center left"
                style={{
                  backgroundImage:
                    lernerCategory === "above_18"
                      ? "url(./assets/images/auth/adult.jpg)"
                      : lernerCategory === "after_school"
                        ? "url(https://studentscholars.s3.amazonaws.com/static_images/81.jpg)"
                        : "url(./assets/images/auth/sign_in1.png)",
                }}
              >
                {lernerCategory === "above_18" ? (
                  <h1 className="text-white poppins fw_500 position-relative">
                    Create an Account to Grow Your Skills, Knowledge, and Talents!

                    
                  </h1>
                ) : lernerCategory === "after_school" ? (
                  <h1 className="text-white poppins fw_500 position-relative">
                     Create An Admin Account to Add Learners.
                  </h1>
                ) : (
                  <h1 className="text-white poppins fw_500 position-relative">
                    Please Create A Parent Account To Add Learners Under 18 Years Old.
                  </h1>
                )}
              </div>

              <div className="col-lg-6 right">
                <div className="top">
                  <div className="d-md-flex justify-content-between">
                    <BackToPrevious />
                    <div>
                      <p className="fs-6 text-gray">
                        Already Have Account?
                        <Link to="/login" className="text-purple ps-2">
                          Sign In
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="btm_div form-section pt-lg-5">
                  <div className="d-xl-flex justify-content-between align-items-center mb-5">
                    {lernerCategory == "above_18" ? (
                      <h2 className="text-purple fw-600 poppins text-capitalize">
                        Create an Account to Grow Your Skills, Knowledge, and Talents!
                      </h2>
                    ) : (
                      <>
                        {lernerCategory == "below_18" && (
                          <h2 className="text-purple fw-600 poppins text-capitalize">
                            Create A Parent Account
                          </h2>
                        )}

                        {lernerCategory == "after_school" && (
                          <h2 className="text-purple fw-600 poppins text-capitalize">
                            Create A School Admin Account
                          </h2>
                        )}

                        <span className="text-end d-block text-purple">Step 1/2</span>
                      </>
                    )}
                  </div>

                  <form onSubmit={handleSubmit(onSubmit)} className="custom_form mt-4" action id>
                    <div className="form-group mb-4">
                      <label className="mb-2" htmlFor="dropdown">
                        Select Learner Category
                      </label>
                      <select
                        id="dropdown"
                        name="category"
                        className="form-select"
                        onChange={(e) => abc(e.target.value)}
                        defaultValue={lernerCategory}
                      >
                        <option value="below_18">Learners under 18 years</option>
                        <option value="above_18">Learners over 18 years</option>
                        <option value="after_school">Schools & Afterschool Centers</option>
                      </select>
                    </div>
                    {/* <div id="content1" style={{ display: 'none' }}> */}
                    {lernerCategory == "below_18" && (
                      <>
                        <Controller
                          name="imageUpload"
                          control={control}
                          defaultValue={null}
                          rules={{ required: "Please upload profile image" }}
                          render={({ field }) => (
                            <>
                              <div className="avatar-upload">
                                <div className="avatar-edit">
                                  {/* <input name="file" type="file" id="imageUpload" accept=".png, .jpg, .jpeg" /> */}
                                  <input
                                    type="file"
                                    className="file-input"
                                    id="imageUpload"
                                    accept="image/*"
                                    onInput={(e) => handleImageChange(e.target.files[0])}
                                  />
                                  {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.file?.message}</span></p> */}

                                  <label htmlFor="imageUpload" />
                                </div>
                                <div className="avatar-preview">
                                  <div
                                    id="imagePreview"
                                    style={{
                                      backgroundImage: `url(${profileImageState ? profileImageState : "./assets/images/kids-profile-demo.png"})`,
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </>
                          )}
                        />

                        <div className="form-group mb-4">
                          <label className="mb-2">Adult full name</label>
                          <input name="full_name" {...register("full_name")} type="text" className="form-control" placeholder />
                          <p className="mt-2 font-bold col-red">
                            <span style={{ color: "red" }}>{errors.full_name?.message}</span>
                          </p>
                        </div>
                        <div className="form-group mb-4">
                          <label className="mb-2">Adult Email ID</label>
                          <input name="email" {...register("email")} type="text" className="form-control" placeholder />
                          <p className="mt-2 font-bold col-red">
                            <span style={{ color: "red" }}>{errors.email?.message}</span>
                          </p>
                        </div>
                        {loginType == "S" ?
                          <div className="form-group mb-4">
                            <div className="d-flex justify-content-between position-relative">
                              <label className="mb-2">Your Password</label>
                              <Link onClick={handleTogglePassword}
                                style={{ position: "absolute", right: "20px", zIndex: "9999", bottom: "-36px" }}
                              >
                                <span>
                                  {showPassword ? (
                                    <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#666666">
                                      <path d="M0 0h24v24H0z" fill="none" />
                                      <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                                    </svg>
                                  ) : (
                                    <svg width={19} height={17} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="M17.0194 0.881336L16.2835 0.145393C16.0755 -0.0625975 15.6915 -0.0305884 15.4515 0.257357L12.8913 2.80132C11.7392 2.30538 10.4754 2.06538 9.14731 2.06538C5.19519 2.08132 1.77141 4.38529 0.12329 7.69749C0.0272626 7.90549 0.0272626 8.16141 0.12329 8.3374C0.891223 9.90543 2.04329 11.2015 3.4833 12.1774L1.3873 14.3054C1.1473 14.5454 1.11529 14.9293 1.27534 15.1374L2.01128 15.8733C2.21928 16.0813 2.60326 16.0493 2.84326 15.7613L16.8912 1.71345C17.1952 1.47358 17.2272 1.08962 17.0192 0.881611L17.0194 0.881336ZM9.9953 5.71322C9.72329 5.6492 9.43534 5.56925 9.16332 5.56925C7.80327 5.56925 6.71538 6.65727 6.71538 8.01718C6.71538 8.28919 6.7794 8.57714 6.85936 8.84916L5.78724 9.90519C5.46728 9.34524 5.2913 8.72114 5.2913 8.01722C5.2913 5.88924 7.00332 4.17722 9.1313 4.17722C9.83536 4.17722 10.4593 4.3532 11.0193 4.67316L9.9953 5.71322Z"
                                        fill="#666666"
                                        fillOpacity="0.8"
                                      />
                                      <path
                                        d="M18.1714 7.69743C17.6115 6.57738 16.8754 5.56945 15.9635 4.75342L12.9875 7.69743V8.01738C12.9875 10.1454 11.2754 11.8574 9.14745 11.8574H8.8275L6.93951 13.7454C7.64357 13.8893 8.37952 13.9854 9.09952 13.9854C13.0516 13.9854 16.4754 11.6814 18.1235 8.35325C18.2675 8.12918 18.2675 7.90527 18.1715 7.69726L18.1714 7.69743Z"
                                        fill="#666666"
                                        fillOpacity="0.8"
                                      />
                                    </svg>
                                  )}
                                </span>
                              </Link>
                            </div>
                            <input name="password"
                              {...register("password")}
                              type={showPassword ? 'text' : 'password'}
                              placeholder=""
                              className="form-control" />
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.password?.message}</span></p>
                          </div>
                          : <></>}
                        {/* <div className="form-group mb-4">
                                                    <label className="mb-2">Age</label>
                                                    <select className="form-select" aria-label="Default select example">
                                                        <option selected>3 Years</option>
                                                        <option>4 Years</option>
                                                        <option>5 Years</option>
                                                        <option>6 Years</option>
                                                        <option>7 Years</option>
                                                        <option>8 Years</option>
                                                        <option>9 Years</option>
                                                        <option>10 Years</option>
                                                        <option>11 Years</option>
                                                        <option>12 Years</option>
                                                        <option>13 Years</option>
                                                        <option>14 Years</option>
                                                        <option>15 Years</option>
                                                        <option>16 Years</option>
                                                        <option>17 Years</option>
                                                        <option>18 Years</option>
                                                    </select>
                                                </div> */}
                        {/* <div>
                                                    <h1>Phone Input Example</h1>
                                                    <PhoneInput
                                                        country={'us'} // Default country
                                                        // value={phoneNumber}
                                                        onChange={handlePhoneChange}
                                                        countryCodeEditable={false}
                                                        enableSearch={true}
                                                        autoFormat={false}
                                                        disableCountryCode={true}  // Set this prop to hide the country code
                                                        inputProps={{
                                                            name: 'phone',
                                                            required: true,
                                                            autoFocus: false,
                                                            disableSearchIcon: true
                                                        }}
                                                    />
                                                </div> */}
                        <div className="form-group mb-4">
                          <label className="mb-2 d-block">Phone Number</label>
                          <PhoneInput
                            placeholder="  Enter phone number"
                            name="phone"
                            {...register("phone")}
                            id="phone"
                            onChange={(value, country) => handlePhoneChange(value, country)}
                          />
                          <p className="mt-2 font-bold col-red">
                            <span style={{ color: "red" }}>{errors.phone?.message}</span>
                          </p>
                        </div>

                        <div className="form-group mb-4">
                          <label className="mb-2">Country</label>
                          {/* <Select options={options} value={value} onChange={changeHandler}/> */}
                          {/* <Select    {...register("country")}
                                                        name='country'
                                                        options={options}
                                                        value={value}
                                                        // onChange={changeHandler}

                                                        onChange={(changeHandler) => {
                                                            setValue("country", changeHandler.value);
                                                            setCountry(changeHandler)
                                                            clearErrors("country");
                                                        }}

                                                        placeholder=""
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                border: '1px solid rgba(102, 102, 102, 0.35)',
                                                                borderRadius: '12px',
                                                                padding: '5px 10px', // Adjust padding to make the component smaller
                                                                height: '46px', // Set the height of the component
                                                            }),

                                                        }}
                                                    /> */}
                          <Select
                            {...register("country")}
                            options={countryName}
                            onChange={handleCountryChange}

                            placeholder={"Select Country"}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                border: "1px solid rgba(102, 102, 102, 0.35)",
                                borderRadius: "12px",
                                padding: "5px 10px",
                                height: "46px",
                              }),
                            }}
                            name="country"
                          />
                          <p className="mt-2 font-bold col-red">
                            <span style={{ color: "red" }}>{errors.country?.message}</span>
                          </p>
                        </div>
                        {selectedCountry && (
                          <div className="form-group mb-4">
                            <label className="mb-2">Provinces and Territories</label>
                            <Controller
                              name="province"
                              control={control}
                              rules={{ required: "please select province" }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  options={stateData && stateData.map(state => ({
                                    value: state.state_id,
                                    label: state.state_province
                                  }))}
                                  aria-label="Default select example"
                                  id="select2-example"
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption);
                                    handleStateChange(selectedOption);
                                  }}
                                  value={field.province}
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      border: "1px solid rgba(102, 102, 102, 0.35)",
                                      borderRadius: "12px",
                                      padding: "5px 10px",
                                      height: "46px",
                                    }),
                                  }}
                                />
                              )}
                            />
                            <p className="mt-2 font-bold col-red">
                              <span style={{ color: "red" }}>{errors.province?.message}</span>
                            </p>
                          </div>
                        )}

                        <div className="form-group mb-4">
                          <label className="mb-2">Time Zone</label>
                          <Select
                            options={timezones}
                            value={timezones.find((tz) => tz.value === selectedTimezone)}
                            onChange={handleChange}
                            isSearchable={true}
                            placeholder="Search for a timezone..."
                            isDisabled={true}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                border: "1px solid rgba(102, 102, 102, 0.35)",
                                borderRadius: "12px",
                                padding: "5px 10px", // Adjust padding to make the component smaller
                                height: "46px", // Set the height of the component
                              }),
                            }}
                          />
                        </div>
                        <div className="form-group mb-4">
                          <div className="form-check">
                            <input
                              className="form-check-input rounded-1"
                              type="checkbox"
                              checked={isChecked}
                              onChange={handleCheckboxChange}
                              id="invalidCheck1"
                            />
                            <label className="form-check-label ps-3" htmlFor="invalidCheck1">
                              Do You Have Any Referral Code?
                              <br />
                            </label>
                          </div>
                        </div>
                        <div className="form-group mb-4">
                          {isChecked && (
                            <>
                              <label className="mb-2">Referral Code</label>
                              <input name="referral_code" {...register("referral_code")} type="text" className="form-control" />
                            </>
                          )}
                        </div>
                        <div className="form-group mb-4">
                          <div className="form-group mb-4">
                            <div className="form-check">
                              <input className="form-check-input rounded-1" {...register('checkbox')} name='checkbox' type="checkbox" defaultValue id="invalidCheck" />
                              <label className="form-check-label ps-3 mt-1" htmlFor="invalidCheck">
                                By continuing, you agree to the<br />
                              </label><br />
                              <span className="fw-600 text-purple ps-3 mt-1" style={{ "marginRight": "3px" }} onClick={(e) => TermsService()}>Terms of Service</span>&nbsp;and <span className="fw-600 text-purple" onClick={(e) => PrivacyPolicy()}>Privacy Policy.</span>
                              <p className='mt-2 font-bold col-red'><span style={{ marginLeft: '-21px', color: 'red' }}>{errors.checkbox?.message}</span></p>
                            </div>
                          </div>
                        </div>
                        <div className="mt-2">
                          {/* <Link to="/sign_in_step3" className="btn-theme bg-yellow text-center fw-600 d-block">Next</Link> */}
                          <button type="submit" className="col-md-12 btn-theme bg-yellow text-center fw-600 d-block">
                            {/* <Link to="/sign_in_step3">Next</Link> */}Next
                          </button>
                        </div>
                      </>
                    )}
                    {/* </div> */}
                    {/* <div id="content2" style={{ display: 'none' }}> */}
                    {lernerCategory == "above_18" && (
                      <>
                        <Controller
                          name="imageUpload"
                          control={control}
                          defaultValue={null}
                          rules={{ required: "Please upload profile image" }}
                          render={({ field }) => (
                            <>
                              <div className="avatar-upload">
                                <div className="avatar-edit">
                                  {/* <input name="file" type="file" id="imageUpload" accept=".png, .jpg, .jpeg" /> */}
                                  <input
                                    type="file"
                                    className="file-input"
                                    id="imageUpload"
                                    accept="image/*"
                                    onInput={(e) => handleImageChange(e.target.files[0])}
                                  />
                                  {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.file?.message}</span></p> */}

                                  <label htmlFor="imageUpload" />
                                </div>
                                <div className="avatar-preview">
                                  <div
                                    id="imagePreview"
                                    style={{
                                      backgroundImage: `url(${profileImageState ? profileImageState : "./assets/images/kids-profile-demo.png"})`,
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </>
                          )}
                        />
                        <div className="form-group mb-4">
                          <label className="mb-2">Student Name</label>
                          <input type="text" className="form-control" {...register("full_name")} name="full_name" placeholder />
                          <p className="mt-2 font-bold col-red">
                            <span style={{ color: "red" }}>{errors.full_name?.message}</span>
                          </p>
                        </div>
                        <div className="form-group mb-4">
                          <label className="mb-2">Email ID</label>
                          <input type="text" {...register("email")} name="email" className="form-control" placeholder />
                          <p className="mt-2 font-bold col-red">
                            <span style={{ color: "red" }}>{errors.email?.message}</span>
                          </p>
                        </div>
                        {loginType == "S" ?
                          <div className="form-group mb-4">
                            <div className="d-flex justify-content-between position-relative">
                              <label className="mb-2">Your Password</label>
                              <Link onClick={handleTogglePassword}
                                style={{ position: "absolute", right: "20px", zIndex: "9999", bottom: "-36px" }}
                              >
                                <span>
                                  {showPassword ? (
                                    <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#666666">
                                      <path d="M0 0h24v24H0z" fill="none" />
                                      <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                                    </svg>
                                  ) : (
                                    <svg width={19} height={17} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="M17.0194 0.881336L16.2835 0.145393C16.0755 -0.0625975 15.6915 -0.0305884 15.4515 0.257357L12.8913 2.80132C11.7392 2.30538 10.4754 2.06538 9.14731 2.06538C5.19519 2.08132 1.77141 4.38529 0.12329 7.69749C0.0272626 7.90549 0.0272626 8.16141 0.12329 8.3374C0.891223 9.90543 2.04329 11.2015 3.4833 12.1774L1.3873 14.3054C1.1473 14.5454 1.11529 14.9293 1.27534 15.1374L2.01128 15.8733C2.21928 16.0813 2.60326 16.0493 2.84326 15.7613L16.8912 1.71345C17.1952 1.47358 17.2272 1.08962 17.0192 0.881611L17.0194 0.881336ZM9.9953 5.71322C9.72329 5.6492 9.43534 5.56925 9.16332 5.56925C7.80327 5.56925 6.71538 6.65727 6.71538 8.01718C6.71538 8.28919 6.7794 8.57714 6.85936 8.84916L5.78724 9.90519C5.46728 9.34524 5.2913 8.72114 5.2913 8.01722C5.2913 5.88924 7.00332 4.17722 9.1313 4.17722C9.83536 4.17722 10.4593 4.3532 11.0193 4.67316L9.9953 5.71322Z"
                                        fill="#666666"
                                        fillOpacity="0.8"
                                      />
                                      <path
                                        d="M18.1714 7.69743C17.6115 6.57738 16.8754 5.56945 15.9635 4.75342L12.9875 7.69743V8.01738C12.9875 10.1454 11.2754 11.8574 9.14745 11.8574H8.8275L6.93951 13.7454C7.64357 13.8893 8.37952 13.9854 9.09952 13.9854C13.0516 13.9854 16.4754 11.6814 18.1235 8.35325C18.2675 8.12918 18.2675 7.90527 18.1715 7.69726L18.1714 7.69743Z"
                                        fill="#666666"
                                        fillOpacity="0.8"
                                      />
                                    </svg>
                                  )}
                                </span>
                              </Link>
                            </div>
                            <input name="password"
                              {...register("password")}
                              type={showPassword ? 'text' : 'password'}
                              placeholder=""
                              className="form-control" />
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.password?.message}</span></p>
                          </div>
                          : <></>}

                        <div className="form-group mb-4">
                          <label className="mb-2 d-block">Phone Number</label>
                          {/* <input type="text" name="phone" {...register("phone")} id="phone_after_school" className="form-control w-100" placeholder />
                          <p className="mt-2 font-bold col-red">
                            <span style={{ color: "red" }}>{errors.phone?.message}</span>
                          </p> */}
                          <PhoneInput
                            placeholder="  Enter phone number"
                            name="phone"
                            {...register("phone")}
                            id="phone_after_school"
                            onChange={(value, country) => handlePhoneChange(value, country)}
                          />
                        </div>
                        <div className="form-group mb-4">
                          <label className="mb-2">Country</label>
                          <Select
                            {...register("country")}
                            options={countryName}
                            onChange={handleCountryChange}

                            placeholder={"Select Country"}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                border: "1px solid rgba(102, 102, 102, 0.35)",
                                borderRadius: "12px",
                                padding: "5px 10px",
                                height: "46px",
                              }),
                            }}
                            name="country"
                          />
                          <p className="mt-2 font-bold col-red">
                            <span style={{ color: "red" }}>{errors.country?.message}</span>
                          </p>
                        </div>
                        {selectedCountry && (
                          <div className="form-group mb-4">
                            <label className="mb-2">Provinces and Territories</label>
                            <Controller
                              name="province"
                              control={control}
                              rules={{ required: "Please select province" }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  options={stateData && stateData.map(state => ({
                                    value: state.state_id,
                                    label: state.state_province
                                  }))}
                                  aria-label="Default select example"
                                  id="select2-example"
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption);
                                    handleStateChange(selectedOption);
                                  }}
                                  value={field.province}
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      border: "1px solid rgba(102, 102, 102, 0.35)",
                                      borderRadius: "12px",
                                      padding: "5px 10px",
                                      height: "46px",
                                    }),
                                  }}
                                />
                              )}
                            />
                            <p className="mt-2 font-bold col-red">
                              <span style={{ color: "red" }}>{errors.province?.message}</span>
                            </p>
                          </div>
                        )}
                        <div className="form-group mb-4">
                          <label className="mb-2">Time Zone</label>
                          <Select
                            options={timezones}
                            value={timezones.find((tz) => tz.value === selectedTimezone)}
                            onChange={handleChange}
                            isSearchable={true}
                            placeholder="Search for a timezone..."
                            isDisabled
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                border: "1px solid rgba(102, 102, 102, 0.35)",
                                borderRadius: "12px",
                                padding: "5px 10px", // Adjust padding to make the component smaller
                                height: "46px", // Set the height of the component
                              }),
                              // indicatorsContainer: (provided) => ({
                              //     ...provided,
                              //     display: 'none', // Hide the dropdown indicator
                              // }),
                            }}
                          />
                        </div>
                        <div className="form-group mb-4">
                          <div className="form-check">
                            <input
                              className="form-check-input rounded-1"
                              type="checkbox"
                              checked={isChecked}
                              onChange={handleCheckboxChange}
                              id="invalidCheck1"
                            />
                            <label className="form-check-label ps-3" htmlFor="invalidCheck1">
                              Do You Have Any Referral Code?
                              <br />
                            </label>
                          </div>
                        </div>
                        <div className="form-group mb-4">
                          {isChecked && (
                            <>
                              <label className="mb-2">Referral Code</label>
                              <input name="referral_code" {...register("referral_code")} type="text" className="form-control" />
                            </>
                          )}
                        </div>

                        <div className="form-group mb-4">
                          <div className="form-check">
                            <input className="form-check-input rounded-1" {...register('checkbox')} name='checkbox' type="checkbox" defaultValue id="invalidCheck" />
                            <label className="form-check-label ps-3 mt-1" htmlFor="invalidCheck">
                              By continuing, you agree to the<br />
                            </label><br />
                            <span className="fw-600 text-purple ps-3 mt-1" style={{ "marginRight": "3px" }} onClick={(e) => TermsService()}>Terms of Service</span>&nbsp;and <span className="fw-600 text-purple" onClick={(e) => PrivacyPolicy()}>Privacy Policy.</span>
                            <p className='mt-2 font-bold col-red'><span style={{ marginLeft: '-21px', color: 'red' }}>{errors.checkbox?.message}</span></p>
                          </div>
                        </div>
                        {/* <div className="mt-4">
                                                <Link to="/kidsDashboard" className="btn-theme bg-yellow text-center fw-600 d-block">Next</Link>
                                            </div> */}
                        <button type="submit" className="col-md-12 btn-theme bg-yellow text-center fw-600 d-block">
                          {/* <Link to="/sign_in_step3">Next</Link> */}Next
                        </button>
                      </>
                    )}
                    {/* </div> */}
                    {/* <div id="content3" style={{ display: 'none' }}> */}
                    {lernerCategory == "after_school" && (
                      <>
                        <Controller
                          name="imageUpload"
                          control={control}
                          defaultValue={null}
                          rules={{ required: "Please upload profile image" }}
                          render={({ field }) => (
                            <>
                              <div className="avatar-upload">
                                <div className="avatar-edit">
                                  {/* <input name="file" type="file" id="imageUpload" accept=".png, .jpg, .jpeg" /> */}
                                  <input
                                    type="file"
                                    className="file-input"
                                    id="imageUpload"
                                    accept="image/*"
                                    onInput={(e) => handleImageChange(e.target.files[0])}
                                  />
                                  {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.file?.message}</span></p> */}

                                  <label htmlFor="imageUpload" />
                                </div>
                                <div className="avatar-preview">
                                  <div
                                    id="imagePreview"
                                    style={{
                                      backgroundImage: `url(${profileImageState ? profileImageState : "./assets/images/kids-profile-demo.png"})`,
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </>
                          )}
                        />
                        <div className="form-group mb-4">
                          <label className="mb-2">Organization full name</label>
                          <input type="text" className="form-control" {...register("full_name")} name="full_name" placeholder />
                          <p className="mt-2 font-bold col-red">
                            <span style={{ color: "red" }}>{errors.full_name?.message}</span>
                          </p>
                        </div>
                        <div className="form-group mb-4">
                          <label className="mb-2">Organization Email ID</label>
                          <input type="text" {...register("email")} name="email" className="form-control" placeholder />
                          <p className="mt-2 font-bold col-red">
                            <span style={{ color: "red" }}>{errors.email?.message}</span>
                          </p>
                        </div>
                        {loginType == "S" ?
                          <div className="form-group mb-4">
                            <div className="d-flex justify-content-between position-relative">
                              <label className="mb-2">Your Password</label>
                              <Link onClick={handleTogglePassword}
                                style={{ position: "absolute", right: "20px", zIndex: "9999", bottom: "-36px" }}
                              >
                                <span>
                                  {showPassword ? (
                                    <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#666666">
                                      <path d="M0 0h24v24H0z" fill="none" />
                                      <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                                    </svg>
                                  ) : (
                                    <svg width={19} height={17} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="M17.0194 0.881336L16.2835 0.145393C16.0755 -0.0625975 15.6915 -0.0305884 15.4515 0.257357L12.8913 2.80132C11.7392 2.30538 10.4754 2.06538 9.14731 2.06538C5.19519 2.08132 1.77141 4.38529 0.12329 7.69749C0.0272626 7.90549 0.0272626 8.16141 0.12329 8.3374C0.891223 9.90543 2.04329 11.2015 3.4833 12.1774L1.3873 14.3054C1.1473 14.5454 1.11529 14.9293 1.27534 15.1374L2.01128 15.8733C2.21928 16.0813 2.60326 16.0493 2.84326 15.7613L16.8912 1.71345C17.1952 1.47358 17.2272 1.08962 17.0192 0.881611L17.0194 0.881336ZM9.9953 5.71322C9.72329 5.6492 9.43534 5.56925 9.16332 5.56925C7.80327 5.56925 6.71538 6.65727 6.71538 8.01718C6.71538 8.28919 6.7794 8.57714 6.85936 8.84916L5.78724 9.90519C5.46728 9.34524 5.2913 8.72114 5.2913 8.01722C5.2913 5.88924 7.00332 4.17722 9.1313 4.17722C9.83536 4.17722 10.4593 4.3532 11.0193 4.67316L9.9953 5.71322Z"
                                        fill="#666666"
                                        fillOpacity="0.8"
                                      />
                                      <path
                                        d="M18.1714 7.69743C17.6115 6.57738 16.8754 5.56945 15.9635 4.75342L12.9875 7.69743V8.01738C12.9875 10.1454 11.2754 11.8574 9.14745 11.8574H8.8275L6.93951 13.7454C7.64357 13.8893 8.37952 13.9854 9.09952 13.9854C13.0516 13.9854 16.4754 11.6814 18.1235 8.35325C18.2675 8.12918 18.2675 7.90527 18.1715 7.69726L18.1714 7.69743Z"
                                        fill="#666666"
                                        fillOpacity="0.8"
                                      />
                                    </svg>
                                  )}
                                </span>
                              </Link>
                            </div>
                            <input name="password"
                              {...register("password")}
                              type={showPassword ? 'text' : 'password'}
                              placeholder=""
                              className="form-control" />
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.password?.message}</span></p>
                          </div>
                          : <></>}
                        <div className="form-group mb-4">
                          <label className="mb-2 d-block">Phone Number</label>
                          {/* <input type="text" name="phone" {...register("phone")} id="phone" className="form-control w-100" placeholder /> */}
                          <PhoneInput
                            placeholder="  Enter phone number"
                            name="phone"
                            {...register("phone")}
                            id="phone"
                            onChange={(value, country) => handlePhoneChange(value, country)}
                          />
                          <p className="mt-2 font-bold col-red">
                            <span style={{ color: "red" }}>{errors.phone?.message}</span>
                          </p>{" "}
                        </div>
                        <div className="form-group mb-4">
                          <label className="mb-2">Country</label>
                          <Select
                            {...register("country")}
                            options={countryName}
                            onChange={handleCountryChange}

                            placeholder={"Select Country"}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                border: "1px solid rgba(102, 102, 102, 0.35)",
                                borderRadius: "12px",
                                padding: "5px 10px",
                                height: "46px",
                              }),
                            }}
                            name="country"
                          />
                          <p className="mt-2 font-bold col-red">
                            <span style={{ color: "red" }}>{errors.country?.message}</span>
                          </p>
                        </div>
                        {selectedCountry && (
                          <div className="form-group mb-4">
                            <label className="mb-2">Provinces and Territories</label>
                            <Controller
                              name="province"
                              control={control}
                              rules={{ required: "Please select province" }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  options={stateData && stateData.map(state => ({
                                    value: state.state_id,
                                    label: state.state_province
                                  }))}
                                  aria-label="Default select example"
                                  id="select2-example"
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption);
                                    handleStateChange(selectedOption);
                                  }}
                                  value={field.province}
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      border: "1px solid rgba(102, 102, 102, 0.35)",
                                      borderRadius: "12px",
                                      padding: "5px 10px",
                                      height: "46px",
                                    }),
                                  }}
                                />
                              )}
                            />
                            <p className="mt-2 font-bold col-red">
                              <span style={{ color: "red" }}>{errors.province?.message}</span>
                            </p>
                          </div>
                        )}
                        <div className="form-group mb-4">
                          <label className="mb-2">Time Zone</label>
                          <Select
                            options={timezones}
                            value={timezones.find((tz) => tz.value === selectedTimezone)}
                            onChange={handleChange}
                            isSearchable={true}
                            placeholder="Search for a timezone..."
                            isDisabled
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                border: "1px solid rgba(102, 102, 102, 0.35)",
                                borderRadius: "12px",
                                padding: "5px 10px", // Adjust padding to make the component smaller
                                height: "46px", // Set the height of the component
                              }),
                            }}
                          />
                        </div>
                        <div className="form-group mb-4">
                          <div className="form-check">
                            <input
                              className="form-check-input rounded-1"
                              type="checkbox"
                              checked={isChecked}
                              onChange={handleCheckboxChange}
                              id="invalidCheck1"
                            />
                            <label className="form-check-label ps-3" htmlFor="invalidCheck1">
                              Do You Have Any Referral Code?
                              <br />
                            </label>
                          </div>
                        </div>
                        <div className="form-group mb-4">
                          {isChecked && (
                            <>
                              <label className="mb-2">Referral Code</label>
                              <input name="referral_code" {...register("referral_code")} type="text" className="form-control" />
                            </>
                          )}
                        </div>
                        <div className="form-group mb-4">
                          <div className="form-check">
                            <input className="form-check-input rounded-1" {...register('checkbox')} name='checkbox' type="checkbox" defaultValue id="invalidCheck" />
                            <label className="form-check-label ps-3 mt-1" htmlFor="invalidCheck">
                              By continuing, you agree to the<br />
                            </label><br />
                            <span className="fw-600 text-purple ps-3 mt-1" style={{ "marginRight": "3px" }} onClick={(e) => TermsService()}>Terms of Service</span>&nbsp;and <span className="fw-600 text-purple" onClick={(e) => PrivacyPolicy()}>Privacy Policy.</span>
                            <p className='mt-2 font-bold col-red'><span style={{ marginLeft: '-21px', color: 'red' }}>{errors.checkbox?.message}</span></p>
                          </div>
                        </div>
                        <div className="mt-2">
                          {/* <Link to="/sign_in_after_school_step3" className="btn-theme bg-yellow text-center fw-600 d-block">Next</Link> */}
                          <button
                            type="submit"
                            className="col-md-12 btn-theme bg-yellow text-center fw-600 d-block"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                          >
                            {/* <Link to="/sign_in_step3">Next</Link> */}Next
                          </button>
                        </div>
                      </>
                    )}
                    {/* </div> */}
                  </form>
                  <div className="position-relative or_sec">
                    <div className="position-absolute">OR</div>
                  </div>
                  <div>
                    <a onClick={(e) => handleSocialSignin(facebook, "F")} className="btn-theme btn-prple-border d-block text-center my-4">
                      <span>
                        <svg width={33} height={33} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="16.5" cy="16.5" r={14} fill="#0C82EE" />
                          <path
                            d="M21.7137 20.7816L22.3356 16.8301H18.4452V14.267C18.4452 13.1857 18.9877 12.1311 20.7302 12.1311H22.5V8.76699C22.5 8.76699 20.8945 8.5 19.3603 8.5C16.1548 8.5 14.0617 10.3929 14.0617 13.8184V16.8301H10.5V20.7816H14.0617V30.3345C14.7767 30.444 15.5082 30.5 16.2534 30.5C16.9986 30.5 17.7302 30.444 18.4452 30.3345V20.7816H21.7137Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      Continue with Facebook
                    </a>
                    <a onClick={(e) => handleSocialSignin(google, "G")} className="btn-theme btn-prple-border d-block text-center ">
                      <span>
                        <svg width={24} height={25} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M22.501 12.7331C22.501 11.8698 22.4296 11.2398 22.2748 10.5864H12.2153V14.483H18.12C18.001 15.4514 17.3582 16.9097 15.9296 17.8897L15.9096 18.0202L19.0902 20.4349L19.3106 20.4564C21.3343 18.6247 22.501 15.9297 22.501 12.7331Z"
                            fill="#4285F4"
                          />
                          <path
                            d="M12.214 23.0001C15.1068 23.0001 17.5353 22.0667 19.3092 20.4567L15.9282 17.89C15.0235 18.5083 13.8092 18.94 12.214 18.94C9.38069 18.94 6.97596 17.1083 6.11874 14.5767L5.99309 14.5871L2.68583 17.0955L2.64258 17.2133C4.40446 20.6433 8.0235 23.0001 12.214 23.0001Z"
                            fill="#34A853"
                          />
                          <path
                            d="M6.12046 14.5767C5.89428 13.9234 5.76337 13.2233 5.76337 12.5C5.76337 11.7767 5.89428 11.0767 6.10856 10.4234L6.10257 10.2842L2.75386 7.7356L2.64429 7.78667C1.91814 9.21002 1.50146 10.8084 1.50146 12.5C1.50146 14.1917 1.91814 15.79 2.64429 17.2133L6.12046 14.5767Z"
                            fill="#FBBC05"
                          />
                          <path
                            d="M12.2141 6.05997C14.2259 6.05997 15.583 6.91163 16.3569 7.62335L19.3807 4.73C17.5236 3.03834 15.1069 2 12.2141 2C8.02353 2 4.40447 4.35665 2.64258 7.78662L6.10686 10.4233C6.97598 7.89166 9.38073 6.05997 12.2141 6.05997Z"
                            fill="#EB4335"
                          />
                        </svg>
                      </span>
                      Continue with Google
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modals isOpen={modalIsOpenTearms} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal" >

          {/* <div className="modal fade yello_left_border" id="safety_tips1" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
          <div className="modal-dialog modal-lg modal-dialog-scrollable yello_left_border">
            <div className="modal-content">
              <div className="modal-header border-bottom-0">
                <h5 className="modal-title poppins fw_500" id="exampleModalLabel">
                  <span className="me-2">
                    <svg width={23} height={30} viewBox="0 0 43 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21.2886 15.4744C16.0354 15.4744 11.7617 19.748 11.7617 25.0007C11.7617 30.2541 16.0354 34.5276 21.2886 34.5276C26.5415 34.5276 30.8149 30.2541 30.8149 25.0008C30.8149 19.7479 26.5415 15.4744 21.2886 15.4744ZM26.9446 23.2068L20.1197 30.0316C19.9836 30.1681 19.8219 30.2763 19.6438 30.3501C19.4658 30.4239 19.2749 30.4618 19.0821 30.4616C18.8894 30.4618 18.6986 30.424 18.5206 30.3502C18.3426 30.2764 18.181 30.1681 18.045 30.0316L15.4368 27.4235C14.8639 26.8509 14.8639 25.922 15.4368 25.3491C16.0094 24.7761 16.9383 24.7761 17.5112 25.3491L19.0821 26.92L24.8702 21.1324C25.4428 20.5593 26.3717 20.5593 26.9446 21.1324C27.5176 21.7049 27.5176 22.6338 26.9446 23.2068Z" fill="#570861" />
                      <path d="M42.528 13.5663L42.5266 13.5274C42.5038 13.0097 42.4888 12.4918 42.4815 11.9737C42.4407 9.21675 40.2491 6.93629 37.4919 6.78229C31.7432 6.46139 27.296 4.58688 23.4962 0.883177L23.4638 0.852318C22.2244 -0.284106 20.3548 -0.284106 19.115 0.852318L19.0826 0.883177C15.2828 4.58688 10.8356 6.46139 5.08688 6.78258C2.33005 6.93629 0.138066 9.21685 0.0972458 11.974C0.090081 12.492 0.0751053 13.0098 0.052324 13.5274L0.0499802 13.6177C-0.061836 19.4798 -0.200703 26.7754 2.23992 33.3973C3.582 37.0389 5.61442 40.2042 8.28053 42.8062C11.317 45.7695 15.2942 48.122 20.1015 49.7982C20.2597 49.8532 20.4216 49.8973 20.5859 49.9302C20.8194 49.9767 21.0544 50 21.2894 50C21.5244 50 21.7597 49.9767 21.9928 49.9302C22.1578 49.8971 22.3203 49.8527 22.4791 49.7973C27.2807 48.1181 31.2537 45.7645 34.2875 42.8017C36.9525 40.1989 38.985 37.0327 40.3281 33.3904C42.7779 26.7487 42.6394 19.4393 42.528 13.5663ZM21.2886 37.4614C14.4175 37.4614 8.82789 31.8718 8.82789 25.0008C8.82789 18.1297 14.4176 12.5401 21.2886 12.5401C28.1593 12.5401 33.7493 18.1297 33.7493 25.0008C33.7493 31.8719 28.1593 37.4614 21.2886 37.4614Z" fill="#570861" />
                    </svg>
                  </span>
                  Terms of Service :
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => setIsOpenTearms
                  (false)} />
              </div>
              <div className="modal-body">
                <div dangerouslySetInnerHTML={{ __html: Terms?.content }} className="mb-3 mt-2" />
              </div>
            </div>
          </div>
          {/* </div> */}
        </Modals>
        <Modals isOpen={modalIsOpenPrivacy} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal" >

          {/* <div className="modal fade yello_left_border" id="safety_tips1" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
          <div className="modal-dialog modal-lg modal-dialog-scrollable yello_left_border">
            <div className="modal-content">
              <div className="modal-header border-bottom-0">
                <h5 className="modal-title poppins fw_500" id="exampleModalLabel">
                  <span className="me-2">
                    <svg width={23} height={30} viewBox="0 0 43 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21.2886 15.4744C16.0354 15.4744 11.7617 19.748 11.7617 25.0007C11.7617 30.2541 16.0354 34.5276 21.2886 34.5276C26.5415 34.5276 30.8149 30.2541 30.8149 25.0008C30.8149 19.7479 26.5415 15.4744 21.2886 15.4744ZM26.9446 23.2068L20.1197 30.0316C19.9836 30.1681 19.8219 30.2763 19.6438 30.3501C19.4658 30.4239 19.2749 30.4618 19.0821 30.4616C18.8894 30.4618 18.6986 30.424 18.5206 30.3502C18.3426 30.2764 18.181 30.1681 18.045 30.0316L15.4368 27.4235C14.8639 26.8509 14.8639 25.922 15.4368 25.3491C16.0094 24.7761 16.9383 24.7761 17.5112 25.3491L19.0821 26.92L24.8702 21.1324C25.4428 20.5593 26.3717 20.5593 26.9446 21.1324C27.5176 21.7049 27.5176 22.6338 26.9446 23.2068Z" fill="#570861" />
                      <path d="M42.528 13.5663L42.5266 13.5274C42.5038 13.0097 42.4888 12.4918 42.4815 11.9737C42.4407 9.21675 40.2491 6.93629 37.4919 6.78229C31.7432 6.46139 27.296 4.58688 23.4962 0.883177L23.4638 0.852318C22.2244 -0.284106 20.3548 -0.284106 19.115 0.852318L19.0826 0.883177C15.2828 4.58688 10.8356 6.46139 5.08688 6.78258C2.33005 6.93629 0.138066 9.21685 0.0972458 11.974C0.090081 12.492 0.0751053 13.0098 0.052324 13.5274L0.0499802 13.6177C-0.061836 19.4798 -0.200703 26.7754 2.23992 33.3973C3.582 37.0389 5.61442 40.2042 8.28053 42.8062C11.317 45.7695 15.2942 48.122 20.1015 49.7982C20.2597 49.8532 20.4216 49.8973 20.5859 49.9302C20.8194 49.9767 21.0544 50 21.2894 50C21.5244 50 21.7597 49.9767 21.9928 49.9302C22.1578 49.8971 22.3203 49.8527 22.4791 49.7973C27.2807 48.1181 31.2537 45.7645 34.2875 42.8017C36.9525 40.1989 38.985 37.0327 40.3281 33.3904C42.7779 26.7487 42.6394 19.4393 42.528 13.5663ZM21.2886 37.4614C14.4175 37.4614 8.82789 31.8718 8.82789 25.0008C8.82789 18.1297 14.4176 12.5401 21.2886 12.5401C28.1593 12.5401 33.7493 18.1297 33.7493 25.0008C33.7493 31.8719 28.1593 37.4614 21.2886 37.4614Z" fill="#570861" />
                    </svg>
                  </span>
                  Privacy Policy :
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => setIsOpenPrivacy
                  (false)} />
              </div>
              <div className="modal-body">
                <div dangerouslySetInnerHTML={{ __html: poli?.content }} className="mb-3 mt-2" />
              </div>
            </div>
          </div>
          {/* </div> */}
        </Modals>
      </main >
      <Modals isOpen={modalIsOpen} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal">
        <SendOtpModel message={`We have sent you an verification code to ${watch("phone")}`} type={`Signup2`} setIsOpen={setIsOpen} isLoader={isLoader} />
      </Modals>
    </>
  );
}
