import { useNavigate } from "react-router-dom";

const BackToPrevious = () => {
  const navigate = useNavigate();
  return (
    <a className="mb-4 arrow_left mb-3 d-block pointer custom-back" onClick={() => navigate(-1)}>
      {/* btn btn-custom text-capitalize custom-back */}
      <span>
        <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.86245 2.225L8.37912 0.75L0.137451 9L8.38745 17.25L9.86245 15.775L3.08745 9L9.86245 2.225Z" fill="#131313" />
        </svg>
      </span>
      &nbsp; Back
    </a>
  );
};

export default BackToPrevious;
