// import React, { useEffect, useState } from 'react'
// import Modals from 'react-modal';
// import { formatDate } from '../../../../utils/common.service';
// import { progress_report_learners_list, progress_report_student_parents, progress_report_teacher_side } from '../../../../api/apiHandler';

// var COMMON_STYLE_FOR_POPUP = {
//     content: {
//         // backgroundColor: 'rgb(0 0 0 / 56%)',
//         background: 'none',
//         border: '0px',
//     },
// }

// export default function CommonProgressReport({ classes_id }) {


//     const [modalIsOpen, setIsOpen] = useState(false);
//     const [progressData, setProgressData] = useState([]);
//     const [multiProgressData, setMultiProgressData] = useState([]);
//     console.log('multiProgressData :', multiProgressData);
//     console.log('progressData :', progressData);
//     console.log('classes_id', classes_id);

//     // const openProgress = (lId,uId) => {
//     // console.log('lId :', lId);
//     // console.log('uId :', uId);
//     //     // isLoader(true)
//     //     var teacher_detail = {
//     //         activity_id: id.id,
//     //         activity_type: id.class,
//     //     }
//     //     if (uId != null) {
//     //         // If userId is provided, use it in the teacher_detail object
//     //         teacher_detail.userId = uId;
//     //     } else if (lId != null) {
//     //         // If learnerId is provided, use it in the teacher_detail object
//     //         teacher_detail.learnerId = lId;
//     //     } 
//     //     console.log('teacher_detail :', teacher_detail);

//     //     progress_report_teacher_side(teacher_detail).then((resposnse) => {
//     //         console.log('openProgress :', resposnse);
//     //         // isLoader(false)
//     //         if (resposnse.code == 1) {
//     //             setProgressData(resposnse?.data)
//     //             setMultiProgressData(resposnse?.data?.dates)
//     //             setIsOpen(true)
//     //         } else {
//     //              
//     //         }
//     //     });
//     // }


//     // const [progressLearnerList, setProgressLearnerList] = useState([]);
//     // console.log('progressLearnerList :', progressLearnerList);

//     // const ProgressLearnerList = async () => {
//     //     try {
//     //         let reqData = {
//     //             activity_id: id.id,
//     //             activity_type: id.class
//     //         }
//     //         const response = await progress_report_learner_list(reqData);
//     //         console.log('ProgressLearnerList :', response);
//     //         if (response.code == 1) {
//     //             setProgressLearnerList(response?.data);
//     //         } else {
//     //             setProgressLearnerList([]);
//     //         }
//     //     } catch (error) {

//     //     }
//     // }

//     // useEffect(() => {
//     //     ProgressLearnerList();
//     // }, []);

//     var userType = localStorage.getItem('userType')



//     const openProgress = () => {

//         // setIsOpen(true)

//         // console.log('uId :', uId);
//         var obj = {
//             activity_id: classes_id.class_id,
//             activity_type: classes_id.type,
//             // learnerId: classes_id?.learner_data?.id
//         }
//         if (classes_id?.learner_data?.id != null && classes_id?.learner_data?.id != undefined) {
//             obj.learnerId = classes_id?.learner_data?.id
//         }
//         console.log(obj);
//         progress_report_teacher_side(obj).then((resposnse) => {
//             console.log('openProgress :', resposnse);
//             // isLoader(false)
//             if (resposnse.code == 1) {
//                 setProgressData(resposnse?.data)
//                 setMultiProgressData(resposnse?.data?.dates)
//                 setIsOpen(true)
//             } else {
//                  
//             }
//         });
//     }



//     return (
//         <>
//             {/* <div className="tab-pane fade" id="pills-Progress" role="tabpanel" aria-labelledby="pills-Progress-tab"> */}
//             {userType == "below_18" ? <>
//                 <div className="enroll_list progrss_report col-xl-9 mb-xl-0 mb-4">

//                     <div className="card">
//                         <div className="d-sm-flex align-items-center justify-content-between">
//                             <div className="enroll_left d-md-flex align-items-center mb-sm-0 mb-3">
//                                 <div className="mb-md-0 mb-3">
//                                     <img src={classes_id?.learner_data?.learner_profile_img} alt="kids-profile" />
//                                 </div>
//                                 <div className="ms-md-3 d-flex align-items-center flex-wrap">
//                                     <span className="mb-md-0 mb-2">{classes_id?.learner_data?.learner_name}</span>

//                                     <div className="enroll_age d-inline-block ms-4 mb-md-0">
//                                         <span>Age: {classes_id?.learner_data?.learner_age}</span>
//                                     </div>
//                                 </div>
//                             </div>
//                             <a onClick={() => openProgress()} className="btn-theme px-3 py-2 d-inline-block">Progress Report</a>
//                         </div>
//                     </div>

//                 </div>
//             </> :
//                 <div className="col-xl-9 mb-xl-0 mb-4">
//                     <div className="button my-5 text-center">
//                         <a onClick={() => openProgress()} className="btn-theme bg-yellow d-inline-block">
//                             Go To Your Progress Report
//                         </a>
//                     </div>
//                 </div>
//             }

//             {/* </div> */}
//             <Modals isOpen={modalIsOpen} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal" >

//                 {/* {/ <div className="modal fade" id="progrss-report" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true"> /} */}
//                 <div className="modal-dialog modal-xl">
//                     <div className="modal-content">
//                         <div className="modal-header">
//                             <h1 className="modal-title fs-5" id="exampleModalLabel">Progress Report</h1>
//                             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setIsOpen(false)} />
//                         </div>
//                         <div className="modal-body">
//                             <div className="mb-2 fw-600">
//                                 <span>Student’s Name:</span><span className>{progressData.learner_name || progressData.full_name}</span>
//                             </div>
//                             <div className="fw-600 mb-4">
//                                 <span>Program Name:</span><span> {progressData.activity_title}</span>
//                             </div>
//                             <div className="table-responsive-xl">
//                                 <table className="table">
//                                     <thead>
//                                         <tr>
//                                             <th scope="col" style={{color : '#570861' }}>Created Date</th>
//                                             <th scope="col" style={{color : '#570861' }}>Lesson Topic</th>
//                                             <th scope="col" style={{color : '#570861' }}>Homework</th>
//                                             <th scope="col" style={{color : '#570861' }}>Assessment</th>
//                                             <th scope="col" style={{color : '#570861' }}>Quiz</th>
//                                             <th scope="col" style={{color : '#570861' }}>Discussion</th>
//                                             <th scope="col" style={{color : '#570861' }}>Teacher’s Name</th>
//                                             <th scope="col" style={{color : '#570861' }}>Teacher Feedback</th>

//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         {multiProgressData?.map((tableData, index) => {
//                                             return (
//                                                 <tr>
//                                                     <th style={{ fontWeight: "normal" }}>{formatDate(tableData?.date, "DD/MM/YYYY")}</th>
//                                                     <td >{tableData?.lesson_data}</td>
//                                                     <td >{tableData?.homework_data}</td>
//                                                     <td >{tableData?.assessment_data}</td>
//                                                     <td >{tableData?.quiz_data}</td>
//                                                     <td ></td>
//                                                     <td />

//                                                     <td>


//                                                     </td>

//                                                 </tr>
//                                             )
//                                         })}

                                    
//                                     </tbody>
//                                 </table>
//                             </div>
//                         </div>
//                         <div className="modal-footer">
//                             <button type="button" className="btn-theme" data-bs-dismiss="modal" onClick={() => setIsOpen(false)}>Close</button>
                      
//                         </div>
//                     </div>
//                 </div>
//                 {/* {/ </div> /} */}
//             </Modals>
//         </>
//     )
// }

import React, { useEffect, useState } from 'react'
import Modals from 'react-modal';
import { TOAST_ERROR, TOAST_WARNING, formatDate } from '../../../../utils/common.service';
import { progress_report_learners_list, progress_report_student_parents, progress_report_teacher_side } from '../../../../api/apiHandler';

var COMMON_STYLE_FOR_POPUP = {
    content: {
        background: 'none',
        border: '0px',
    },
}

export default function CommonProgressReport({ classes_id }) {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [progressData, setProgressData] = useState([]);
    const [multiProgressData, setMultiProgressData] = useState([]);
    console.log('multiProgressData :', multiProgressData);
    console.log('progressData :', progressData);
    console.log('classes_id', classes_id);

    var userType = localStorage.getItem('userType')

    const openProgress = () => {
        var obj = {
            activity_id: classes_id.class_id,
            activity_type: classes_id.type,
        }
        if (classes_id?.learner_data?.id != null && classes_id?.learner_data?.id != undefined) {
            obj.learnerId = classes_id?.learner_data?.id
        }
        console.log(obj);
        progress_report_teacher_side(obj).then((resposnse) => {
            console.log('openProgress :', resposnse);
            if (resposnse?.code == 1) {
                setProgressData(resposnse?.data)
                setMultiProgressData(resposnse?.data?.dates)
                setIsOpen(true)
            } else {
                TOAST_WARNING(resposnse?.message)
                 
            }
        });
    }


    return (
        <>
            {userType == "below_18" ? <>
                <div className="enroll_list progrss_report col-xl-9 mb-xl-0 mb-4">
                    <div className="card">
                        <div className="d-sm-flex align-items-center justify-content-between">
                            <div className="enroll_left d-md-flex align-items-center mb-sm-0 mb-3">
                                <div className="mb-md-0 mb-3">
                                    <img src={classes_id?.learner_data?.learner_profile_img} alt="kids-profile" />
                                </div>
                                <div className="ms-md-3 d-flex align-items-center flex-wrap">
                                    <span className="mb-md-0 mb-2">{classes_id?.learner_data?.learner_name}</span>
                                    <div className="enroll_age d-inline-block ms-4 mb-md-0">
                                        <span>Age: {classes_id?.learner_data?.learner_age}</span>
                                    </div>
                                </div>
                            </div>
                            <a onClick={() => openProgress()} className="btn-theme px-3 py-2 d-inline-block">Progress Report</a>
                        </div>
                    </div>
                </div>
            </> :
                <div className="col-xl-9 mb-xl-0 mb-4">
                    <div className="button my-5 text-center">
                        <a onClick={() => openProgress()} className="btn-theme bg-yellow d-inline-block">
                            Go To Your Progress Report
                        </a>
                    </div>
                </div>
            }

            <Modals isOpen={modalIsOpen} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal" >
                <div className="modal-dialog modal-xl progress_report">
                    <div className="modal-content ">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Progress Report</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setIsOpen(false)} />
                        </div>
                        <div className="modal-body">
                            <div className="mb-2 fw-600">
                                <span>Student's Name:</span><span className>{progressData.learner_name || progressData.full_name}</span>
                            </div>
                            <div className="fw-600 mb-4">
                                <span>Program Name:</span><span> {progressData.activity_title}</span>
                            </div>
                            <div className="table-responsive-xl">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ color: '#570861' }}>Created Date</th>
                                            <th scope="col" style={{ color: '#570861' }}>Lesson Topic</th>
                                            <th scope="col" style={{ color: '#570861' }}>Homework</th>
                                            <th scope="col" style={{ color: '#570861' }}>Assessment</th>
                                            <th scope="col" style={{ color: '#570861' }}>Quiz</th>
                                            <th scope="col" style={{ color: '#570861' }}>Discussion</th>
                                            <th scope="col" style={{ color: '#570861' }}>Teacher's Name</th>
                                            <th scope="col" style={{ color: '#570861' }}>Teacher Feedback</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                       {multiProgressData?.map((tableData, index) => {
                                            return (
                                                <tr>
                                                    <th style={{ fontWeight: "normal" }}>{formatDate(tableData?.date, "DD/MM/YYYY")}</th>
                                                    <td >{tableData?.lesson_data}</td>
                                                    <td >{tableData?.homework_data}</td>
                                                    <td >{tableData?.assessment_data}</td>
                                                    <td >{tableData?.quiz_data}</td>
                                                    <td >{tableData?.discussion_data}</td>
                                                    <td >{tableData?.teacher_name}</td>
                                                    <td >{tableData?.feedback}</td>
                                                    <td ></td>
                                                    <td />

                                                    <td>


                                                    </td>

                                                </tr>
                                            )
                                        })}

                                    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn-theme" data-bs-dismiss="modal" onClick={() => setIsOpen(false)}>Close</button>
                        </div>
                    </div>
                </div>
            </Modals>
        </>
    )
}