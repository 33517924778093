import { useState, useEffect, useRef, useMemo, React, Fragment, useCallback } from 'react';
import { useSelector } from 'react-redux';
import io from "socket.io-client";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { SOCKET_URL } from '../../Config';
import { agora_room_already_exists, agora_room_chat_history } from '../../api/apiHandler';
import { TOAST_ERROR, convertToBase64 } from '../../utils/common.service';
import { uploadImageOnAWS } from '../aws/Service';

export const useChatParent = (classRoomData, joinedUsers, userType) => {
    console.log('joinedUsers :', joinedUsers);
    const [roomId, setRoomId] = useState(null);
    console.log('roomId :', roomId);
    const [chatHistory, setChatHistory] = useState(null);
    const [page, setPage] = useState(1);
    const [message, setMessage] = useState("");
    const [image, setImage] = useState({ blob: "", base64: "" });
    const [socket, setSocket] = useState(null);
    const imageVal = useRef(null);
    const scrollToBottomRef = useRef(null);
    const scrollToTopRef = useRef(null);
    const inputRef = useRef(null);

    const USER_ID = JSON.parse(localStorage.getItem("PAid"));

    const userIdsArray = useMemo(() => {
        const ids = joinedUsers
            .filter(user => user.user_id !== "NULL" && user.user_id !== undefined)
            .map(user => user.user_id);

        return ids;
    }, [joinedUsers]);
    console.log('userIdsArray :', userIdsArray);

    const getChatHistory = async () => {
        try {
            let body = { room_id: roomId };
            const { data, code } = await agora_room_chat_history(body);
            console.log('chatHistory :', data);
            if (code == 1) {
                if (page == 1) setChatHistory(data);
                else
                    setChatHistory(prev => ({
                        ...prev,
                        list: [...prev?.list, ...data.list],
                    }));
            }
        } catch (err) {
            console.error('Error fetching chat history:', err);
        }
    };

    useEffect(() => {
        if (roomId) {
            getChatHistory();
        }
    }, [roomId, page]);


    const checkRoomExist = async () => {
        try {
            const members = joinedUsers
                .filter(user => user.user_id !== "NULL" && user.user_id !== "undefined")
                .map(user => ({
                    user_type: user.type == "learner" ? "learner" : "user",
                    user_id: user.user_id
                }));




            let send_data = {
                meeting_id: classRoomData.id,
                members: members,
                activity_id: classRoomData?.classes_id,
                activity_type: classRoomData?.classes_type
            };

            console.log('send_data :', send_data);

            // return

            const response = await agora_room_already_exists(send_data);
            if (response?.code == 1) {
                setRoomId(response?.data[0]?.id);
                getChatHistory()
            } else {
                // TOAST_ERROR(response.message);
            }
        } catch (error) {
            console.error('Error checking room existence:', error);
        }
    };


    useEffect(() => {
        checkRoomExist();
    }, [joinedUsers]);

    useEffect(() => {
        console.log('call parent');
        //add condition here type teacher then teacher id else user id
        const socketInstance = io(SOCKET_URL, { query: { user_id: userIdsArray } });
        // const socketInstance = io(SOCKET_URL, { query: { user_id:  userIdsArray } });
        setSocket(socketInstance);

        socketInstance.on("error", (error) => {
            console.error("Socket error:", error);
        });

        socketInstance.on("agora_room_send_message", (newMessage) => {
            console.log('newMessage :', newMessage);
            if (newMessage?.data) {
                setChatHistory((prevChatHistory) => ({
                    ...prevChatHistory,
                    data: {
                        ...prevChatHistory.data,
                        list: [...prevChatHistory.data.list, {
                            chat_reply_id: newMessage.data.chat_reply_id,
                            room_id: newMessage.data.room_id,
                            user_id: newMessage.data.user_id,
                            status: null,
                            full_name: newMessage.data.user_name,
                            profile_image: newMessage.data.profile_image,
                            learner_id: null,
                            learner_name: null,
                            learner_profile_image: null,
                            message: newMessage.data.message,
                            type: newMessage.data.type,
                            user_type: userType == "learner" ? "learner" : "user",
                            created_at: newMessage.data.created_at
                        }]
                    }
                }));
            }
        });
    }, [chatHistory?.data?.list]);

    const deleteImage = () => {
        setImage({ blob: "", base64: "" });
        if (imageVal.current) {
            imageVal.current.value = "";
            imageVal.current.type = "text";
            imageVal.current.type = "file";
        }
    };

    const sendMessage = async (e) => {
        e.preventDefault();
        let attachment_file = null;
        if (image.base64) attachment_file = await uploadImageOnAWS(image.blob, "chat");
        if (message || image.base64) {
            const newMessageBody = {
                room_id: roomId,
                user_type: userType == 'learner' ? 'learner' : 'user',
                sender_id: USER_ID,
                type: image.base64 ? (
                    image.blob.type.includes("image") ? "photo" : (
                        image.blob.type.includes("video") ? "video" : (
                            image.blob.type.includes("pdf") ? "pdf" : (
                                image.blob.type.includes("xlsx") ? "excel" : image.blob.type.includes("spreadsheetml") ? "excel" : image.blob.type.includes("application/vnd.ms-excel") ? "excel" : image.blob.type.includes("doc")
                                    ? "doc" : ("text")
                            )
                        )
                    )
                ) : "text",
                message: message != '' ? message : attachment_file,
            };

            console.log('newMessageBody :', newMessageBody);
            socket.emit("agora_room_send_message", newMessageBody);
            console.log('newMessageBody1 :', newMessageBody);

            setMessage("");
            getChatHistory()
            setImage({ blob: "", base64: "" });
            if (imageVal.current) {
                imageVal.current.value = "";
                imageVal.current.type = "text";
                imageVal.current.type = "file";
            }
        }
    };
    const handleMessage = async (e) => {
        const { files, value, type } = e.target;
        console.log('type :', type);

        if (type === "text") {
            const trimmedValue = value.trim();
            if (trimmedValue !== "") {
                setMessage(trimmedValue);
            } else {
                setMessage("");
            }
        } else if (type === "file") {
            const file = files[0];
            console.log('file :', file.type);
            const extension = file.name.split('.').pop().toLowerCase();
            console.log('extension :', extension);

            if (extension === 'xlsx' || extension === 'pdf' || extension === 'doc' || extension === 'docx' || extension === 'xls') {
                // Handle documents
                setImage({ blob: file, base64: await convertToBase64(file) });
            } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif') {
                // Handle images
                setImage({ blob: file, base64: await convertToBase64(file) });
            } else if (extension === 'mp4' || extension === 'avi' || extension === 'mov' || extension === 'wmv') {
                // Handle videos
                setImage({ blob: file, base64: await convertToBase64(file) });
            } else {
                // Handle unsupported file type
                console.error("Unsupported file type");
            }
        }
    };


    const downloadDocx = (url) => {
        let fileName = url.split("/");
        fileName = fileName[fileName.length - 1];

        const anchor = document.createElement("a");
        anchor.style.display = "none";
        anchor.href = url;
        anchor.target = "_blank";
        anchor.download = fileName;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    };
    const renderMessageContent = useCallback((chat, time) => {
        const isCurrentUser = USER_ID === (chat.user_id ? chat.user_id : chat.learner_id);
        const userName = chat.full_name || chat.learner_name;
        const profileImage = isCurrentUser
            ? (userType === "learner" ? chat.learner_profile_image : chat.profile_image)
            : chat.profile_image;

        const renderMessageByType = (chat) => {
            switch (chat.type) {
                case "text":
                    return <p className="message">{chat.message}</p>;
                case "photo":
                    return <img width={200} src={chat.message} alt="chat attachment" />;
                case "video":
                    return <video controls width={150} src={chat.message} />;
                case "pdf":
                    return (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={50}
                            height={50}
                            fill="currentColor"
                            style={{ color: "red", marginLeft: "20px" }}
                            className="bi bi-filetype-pdf"
                            viewBox="0 0 16 16"
                            onClick={() => downloadDocx(`https://hlink-bhavinp-s3.s3.amazonaws.com/student_scholar/chat/${chat.message}`)}
                        >
                            <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                        </svg>
                    );
                case "doc":
                    return (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width={50}
                            height={50}
                            style={{ marginLeft: "20px" }}
                            viewBox="0 0 48 48"
                            onClick={() => downloadDocx(`https://hlink-bhavinp-s3.s3.amazonaws.com/student_scholar/chat/${chat.message}`)}
                        >
                            <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path>
                            <path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path>
                            <path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
                        </svg>
                    );
                case "excel":

                    return (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width={50}
                            height={50}
                            style={{ marginLeft: "20px" }}
                            viewBox="0 0 48 48"
                            onClick={() => downloadDocx(`https://hlink-bhavinp-s3.s3.amazonaws.com/student_scholar/chat/${chat.message}`)}
                        >
                            <path fill="#169154" d="M29,6H15.744C14.781,6,14,6.781,14,7.744v7.259h15V6z"></path>
                            <path fill="#18482a" d="M14,33.054v7.202C14,41.219,14.781,42,15.743,42H29v-8.946H14z"></path>
                            <path fill="#0c8045" d="M14 15.003H29V24.005000000000003H14z"></path>
                            <path fill="#17472a" d="M14 24.005H29V33.055H14z"></path>
                            <g>
                                <path fill="#29c27f" d="M42.256,6H29v9.003h15V7.744C44,6.781,43.219,6,42.256,6z"></path>
                                <path fill="#27663f" d="M29,33.054V42h13.257C43.219,42,44,41.219,44,40.257v-7.202H29z"></path>
                                <path fill="#19ac65" d="M29 15.003H44V24.005000000000003H29z"></path>
                                <path fill="#129652" d="M29 24.005H44V33.055H29z"></path>
                            </g>
                            <path fill="#0c7238" d="M22.319,34H5.681C4.753,34,4,33.247,4,32.319V15.681C4,14.753,4.753,14,5.681,14h16.638 C23.247,14,24,14.753,24,15.681v16.638C24,33.247,23.247,34,22.319,34z"></path>
                            <path fill="#fff" d="M9.807 19L12.193 19 14.129 22.754 16.175 19 18.404 19 15.333 24 18.474 29 16.123 29 14.013 25.07 11.912 29 9.526 29 12.719 23.982z"></path>
                        </svg>
                    );
                default:
                    return null;
            }
        };

        if (isCurrentUser) {
            return (
                <div className="right">
                    <div className="msg-recieve">
                        <div>
                            {renderMessageByType(chat)}
                            <span className="time-chat">{time}</span>
                        </div>

                    </div>
                </div>
            );
        } else {
            return (
                <div className="left">

                    <img src={profileImage} alt="" className="chat-photo-header" />


                    <div className="msg-sent-container">
                        <p className="message-sent">
                            <span className="active">{userName}</span><br /><br />
                            {renderMessageByType(chat)}
                        </p>
                        <p className="time-chat">{time}</p>
                    </div>


                </div>
            );
        }
    }, [USER_ID]);




    const showMessages = useMemo(() => {
        console.log('Rendering messages');
        dayjs.extend(utc);


        return chatHistory?.data?.list
            ?.sort((a, b) => a.chat_reply_id - b.chat_reply_id)
            ?.map((chat, i) => {
                const timestampToDate = dayjs.unix(chat.created_at);
                const time = timestampToDate.format("h:mm A");

                return (
                    <Fragment key={chat.chat_reply_id}>

                        {renderMessageContent(chat, time)}
                    </Fragment>
                );
            });
    }, [chatHistory?.data?.list, renderMessageContent, USER_ID]);

    const handleImageClick = () => {
        if (imageVal.current) {
            imageVal.current.click();
        }
    };

    
    return {
        roomId,
        chatHistory,
        message,
        setMessage,
        image,
        imageVal,
        scrollToBottomRef,
        scrollToTopRef,
        inputRef,
        sendMessage,
        handleMessage,
        deleteImage,
        showMessages,
        checkRoomExist,
        handleImageClick
    };
};