import { initializeApp } from "firebase/app";
import { FacebookAuthProvider, getAuth, GoogleAuthProvider, OAuthProvider } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyAICUG5pDsYwKhsGNxqY9b-8TddpU51OpA",
  authDomain: "studentscholar-ac63a.firebaseapp.com",
  projectId: "studentscholar-ac63a",
  storageBucket: "studentscholar-ac63a.appspot.com",
  messagingSenderId: "756847248050",
  appId: "1:756847248050:web:6ed4efd62a75a959df3fab",
  measurementId: "G-D9YYNQJSJH"
};

initializeApp(firebaseConfig);
export const auth = getAuth()

// Google Part
export const google = new GoogleAuthProvider()
google.addScope('email')

// Facebook Part
export const facebook = new FacebookAuthProvider()
facebook.addScope('email')