import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import JqueryLoad from '../../../../JqueryLoad/JqueryLoad'
import TFooter_Dashboard from '../../Include/TFooter_Dashboard'
import THeader_Dashboard from '../../Include/THeader_Dashboard'
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { convertToBase64, TOAST_ERROR } from '../../../../utils/common.service';
import { uploadImageOnAWS } from '../../../aws/Service';
import { main_category_list, subject_category_list, sub_category_request_class } from '../../../../api/apiHandler';


export default function SetupQuiz({ isLoader }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate();
  const location = useLocation();
  console.log('location1111 :', location);

  var class_id = location?.state;

  const schema = yup.object().shape({
    quiz_title: yup.string()
      .required("Please enter quiz title")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .test(
        'no-only-whitespace',
        'Quiz title cannot consist of only spaces',
        (value) => value && value.trim().length > 0
      )
      .min(20, 'The quiz title must be at least 20 characters long'),

    quiz_summary: yup.string()
      .required("Please enter quiz summary")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .test(
        'no-only-whitespace',
        'Quiz summary cannot consist of only spaces',
        (value) => value && value.trim().length > 0
      )
      .min(50, 'The quiz summary must be at least 50 characters long'),

    instruction: yup.string().required("Please enter quiz instructions")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .test(
        'no-only-whitespace',
        'Quiz instructions cannot consist of only spaces',
        (value) => value && value.trim().length > 0
      ),

    // quiz_summury: yup.string().required("Learner summary is required"),
    profileVideo: yup
      .mixed()
      .required("Please upload quiz video"),

  });

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const [profileImageState, setProfileImageState] = useState(null)

  const handleImageChange = async (image) => {
    setValue("profileImage", image);
    const bufferImage = await convertToBase64(image);
    setProfileImageState(bufferImage);
    clearErrors("profileImage");
  }

  const [profileVideoState, setProfileVideoState] = useState(null)
  const handleVideoChange = async (Video) => {
    setValue("profileVideo", Video);
    const bufferVideo = await convertToBase64(Video);
    setProfileVideoState(bufferVideo);
    clearErrors("profileVideo");
  }

  const [price, setPrice] = useState('');
  const [Estimatedprice, setEstimatedprice] = useState(0);
  const [endEstimatedprice, setendEstimatedprice] = useState(0);
  const [pricingLevel, setPricingLevel] = useState('Very low');

  const handlePriceChange = (e) => {
    const inputPrice = e.target.value;
    setPrice(inputPrice);
    var start_est_ear = parseInt(inputPrice * 0.6);
    // var end_est_ear = parseInt(inputPrice * 0.6);
    setEstimatedprice(start_est_ear)
    // setendEstimatedprice(end_est_ear)

    // Validate if the input is not empty and is a valid number
    if (inputPrice !== "" && !isNaN(inputPrice)) {
      const newPrice = parseFloat(inputPrice);
      // Determine pricing level based on the input value
      if (newPrice > 50) {
        setPricingLevel('High');
      } else if (newPrice >= 20) {
        setPricingLevel('Medium');
      } else {
        setPricingLevel('Very low');
      }
    } else {
      // Handle invalid or empty input (e.g., reset pricing level or show an error)
      setPricingLevel('Very low');
    }
  };

  const progressBarWidth = `${Math.max(0, Math.min((price - 20) / 50 * 100, 100))}%`;

  var [startageRanges, setstartAgeRanges] = useState(3)
  var [ageRanges, setAgeRanges] = useState(6)

  const endageRange = (data) => {
    setAgeRanges(data);
  }

  const ageRange = (data) => {
    if (data < 16) {
      setAgeRanges(parseInt(data) + 3);
      setstartAgeRanges(data);
    } else if (data == 'adults') {
      setAgeRanges('adults');
      setstartAgeRanges(data);
    } else {
      setAgeRanges(18);
      setstartAgeRanges(data);
    }
  };
  var [selectQuestion, setSelectQuestion] = useState('10-25')
  var [printEstimation, setPrintEstimation] = useState("")

  const Suggest_que = (data) => {
    setSelectQuestion(data)
  }

  const SetPriceData = () => {
    if (ageRanges <= 6) {
      if (selectQuestion == "10-25") {
        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of quiz is between $5 to $15')

      }
      if (selectQuestion == "26-50") {
        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of quiz is between $10 to $25')

      }
      if (selectQuestion == "51-75") {
        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of quiz is between $20 to $35')

      }
      if (selectQuestion == "76-100") {
        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of quiz is between $30 to $45')
      }
    } else if (startageRanges > 6 && ageRanges <= 12) {
      if (selectQuestion == "10-25") {
        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of quiz is between $5 to $15')

      }
      if (selectQuestion == "26-50") {
        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of quiz is between $10 to $25')

      }
      if (selectQuestion == "51-75") {
        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of quiz is between $20 to $35')

      }
      if (selectQuestion == "76-100") {
        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of quiz is between $30 to $45')
      }
    } else if (startageRanges > 12 && ageRanges <= 18) {
      if (selectQuestion == "10-25") {
        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of quiz is between $5 to $25')

      }
      if (selectQuestion == "26-50") {
        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of quiz is between $10 to $35')

      }
      if (selectQuestion == "51-75") {
        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of quiz is between $20 to $45')

      }
      if (selectQuestion == "76-100") {
        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of quiz is between $30 to $55')
      }
    } else {
      if (selectQuestion == "10-25") {
        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of quiz is between $5 to $35')

      }
      if (selectQuestion == "26-50") {
        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of quiz is between $10 to $45')

      }
      if (selectQuestion == "51-75") {
        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of quiz is between $20 to $55')

      }
      if (selectQuestion == "76-100") {
        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of quiz is between $30 to $65')
      }
    }
  }

  useEffect(() => {
    if (startageRanges !== null && ageRanges !== null) {
      SetPriceData();
    }
  }, [startageRanges, ageRanges, selectQuestion]);

  var [classHoursData, setClassHoursData] = useState('0')
  var [classMinutesData, setClassMinutesData] = useState('30')

  const classHours = (data) => {
    setClassHoursData(data)
  }

  const classsMinutesData = (data) => {
    setClassMinutesData(data)
  }

  let onSubmit = async (data) => {

    if (classHoursData === '0' && classMinutesData === '0') {
      TOAST_ERROR("Quiz duration cannot be 0 hours and 0 minutes, Please select a valid duration");
      return;
    }

    isLoader(true)
    if (data.profileImage != null) {
      var image = await uploadImageOnAWS(data?.profileImage, "class_image");
    }
    var video = await uploadImageOnAWS(data?.profileVideo, "class_image");

    let submitData = {
      class_id: class_id ? class_id : '',
      title: data.quiz_title,
      summary: data.quiz_summary,
      video: video,
      // image: image,
      main_category: subjectCategory,
      subject_category: SelectedSubCategory,
      min_age: data.start_age,
      max_age: ageRanges,
      language: data.language,
      select_question: selectQuestion,
      // duration: data.hours + ' ' + data.minutes,
      price: price,
      instruction: data.instruction

    }

    if (data.hours !== "" && data.minutes !== "") {
      submitData.duration = data.class_hours + " Hour" + (data.class_hours != 1 ? "s " : " ") + data.class_min + " Minutes"
    } else {
      submitData.duration = "";
    }
    if (data.profileImage != null) {
      submitData.image = image
    }
    localStorage.setItem("quiz_form", JSON.stringify(submitData))
    isLoader(false)
    navigate("/add_question");
  };

  const [subjectCategory, setSubjectCategory] = useState('Academic');
  const [subjectCategoryId, setSubjectCategoryId] = useState('1');
  var [MainCatdata, setMainCatdata] = useState([]);
  var [SelectedSubCategory, SetSelectedSubCategory] = useState('');
  var [SelectedSubCat, setSelectedSubCat] = useState([]);
  var [SelectedSubCatErr, setSelectedSubCatErr] = useState(null);

  const handleSubjectCategoryChange = (e) => {
    setSubjectCategory((e.target.value)?.split(",")[1]);
    setSubjectCategoryId((e.target.value)?.split(",")[0])
  };

  const handleSubjectSubCategoryChange = (e) => {
    SetSelectedSubCategory(e.target.value)
  };

  const MainCategory = () => {
    isLoader(true)
    main_category_list().then((resposnse) => {
      if (resposnse?.code == 1) {
        setMainCatdata(resposnse?.data)
        isLoader(false)
        // setSelectedCatID([resposnse.data[0].category_id])
        // SetSelectedCategory([resposnse.data[0].category_name])
      } else {
        setMainCatdata([])
        isLoader(false)
      }
    });
  }

  useEffect(() => {
    MainCategory();
  }, []);

  const SubCategoryList = () => {
    isLoader(true)
    subject_category_list({ category_id: subjectCategoryId }).then((resposnse) => {
      if (resposnse.code == 1) {
        setSelectedSubCat(resposnse?.data)
        isLoader(false)
        if (resposnse?.data?.length > 0) {
          if (resposnse.data[0]?.category_name) {
            SetSelectedSubCategory(resposnse.data[0]?.category[0]?.subject_name)
          } else {
            SetSelectedSubCategory(resposnse?.data[0]?.subject_name)
          }
          setSelectedSubCatErr('abcd')
        }
      } else {
        isLoader(false)
      }
    });
  }

  useEffect(() => {
    SubCategoryList()
  }, [subjectCategoryId]);

  const handleBack = () => {
    navigate(-1);
  }
  return (
    <>
      <JqueryLoad />
      <THeader_Dashboard />
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className>
              {/*start*/}
              <a onClick={handleBack} className="mb-4 arrow_left mb-3 d-block" >
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span className="ms-2">Back</span>
                </span></a>
              {/*end*/}
              {/*start*/}
              <div className>
                <div className="row mb-4">
                  <div className="col-xl-7 d-sm-flex align-items-center justify-content-between">
                    <h3 className="text-purple mb-sm-0 mb-3">Setup Your Online Quiz</h3>
                    <span className="text-purple text-end d-block fw-600">Step 1/2</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-7">
                    <form onSubmit={handleSubmit(onSubmit)} className="custom_form">
                      <div className="form-group mb-4">
                        <h3 className="mb-1 text-capitalize">Quiz Title</h3>
                        <p className="fs-6 mb-4 1 text-capitalize">Choose A Catchy And Descriptive Title. Eg. "Math Quiz for Ages 6-9”. No More Than 1 Exclamation Mark, And No Emojis Or Coupon Codes.</p>
                        <label className="form-label">Quiz Title</label>
                        <div className="position-relative class_title">
                          <textarea type="text" className="form-control" maxLength={50} name="quiz_title" {...register("quiz_title")} rows={2} defaultValue={""} />
                          <span className="position-absolute word_count">{50 - (watch("quiz_title")?.split('')?.length ?? 0)} Words</span>
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.quiz_title?.message}</span></p>

                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <h3 className="mb-1 1 text-capitalize">Quiz summary</h3>
                        <p className="fs-6 mb-4 1 text-capitalize">Add A Single Sentence Or Phrase To Describe Your Class. The Summary Should Be Clear And Descriptive Rather Than A Teaser, And Include Keywords So Learners Can Find Your Class Easily. Use No More Than One Exclamation Mark, And No Emojis Or Coupon Codes</p>
                        <label className="form-label">Program summary</label>
                        <div className="position-relative class_title">

                          <textarea type="text" className="form-control" maxLength={150} id {...register('quiz_summary')} name='quiz_summary' />
                          <span className="position-absolute word_count">{150 - (watch("quiz_summary")?.split('')?.length ?? 0)} Words</span>

                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.quiz_summary?.message}</span></p>
                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <h3 className="mb-1 1 text-capitalize">Instructions</h3>
                        <p className="fs-6 mb-4 1 text-capitalize">Add any instructions regarding the quiz to the user.</p>
                        <label className="form-label">Instructions of quiz </label>
                        <div className="position-relative class_title">

                          <textarea type="text" className="form-control" placeholder id {...register('instruction')} name='instruction' />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.instruction?.message}</span></p>
                        </div>
                      </div>
                      {/* <div className="form-group mb-4 class_image">
                        <h3 className="mb-1">Class Image (Optional)</h3>
                        <p className="fs-6 mb-4 text-capitalize">Choose an image that is engaging, free of words or text, age-appropriate and at least 600 pixels wide. Make sure you have permission to use your image (no copyrighted images, please).</p>
                        <label className="form-label">Program Image</label>
                        <Controller name="profileImage" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                          <>
                            <div className="imageWrapper position-relative">
                              <div className="d-flex align-items-center justify-content-center">
                                <img className="image" src={profileImageState ? profileImageState : "./assets/images/image_upload.png"} alt="image_upload" style={{ "width": profileImageState ? "100%" : "" }} />
                              </div>

                              <label htmlFor='profileImage'>
                                <button className="file-upload">
                                  <input type="file" className="file-input" id="profileImage" accept="image/*" onChange={(e) => handleImageChange(e.target.files[0])} />Choose File
                                </button>
                              </label>
                            </div>
                          </>
                        )} />
                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.profileImage?.message}</span></p>
                      </div> */}
                      <div className="form-group mb-4 class_image">
                        <h3 className="mb-1">Quiz Video</h3>
                        <p className="fs-6 mb-4 text-capitalize">Describe To Learners What To Expect From Class. Recommended Length: Max 90 Seconds Limit</p>
                        <label className="form-label">Program Video</label>

                        <Controller name="profileVideo" control={control} defaultValue={null} rules={{ required: "Please upload video" }} render={({ field }) => (
                          <>
                            <div className="imageWrapper position-relative">
                              <div className="d-flex align-items-center justify-content-center">
                                {profileVideoState ?
                                  <video src={profileVideoState} width="100%" height="100%" className="video" loop muted autoPlay playsInline controls></video>
                                  :
                                  <img className="video" src="./assets/images/video_upload.png" style={{ "width": profileVideoState ? "100%" : "" }} alt="video_upload" />}
                              </div>

                              <label htmlFor='profileVideo'>
                                <button className="file-upload">
                                  <input type="file" className="file-input" id="profileVideo" accept="video/*" onChange={(e) => handleVideoChange(e.target.files[0])} />Choose File
                                </button>
                              </label>
                            </div>
                          </>
                        )} />
                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.profileVideo?.message}</span></p>

                      </div>
                      {/* <div className="form-group mb-4 class_image">
                        <h3 className="mb-1 1 text-capitalize">Quiz Image</h3>
                        <p className="fs-6 mb-4 1 text-capitalize">Choose an image that is engaging, free of words or text, age-appropriate and at least 600 pixels wide. Make sure you have permission to use your image (no copyrighted images, please).</p>
                        <label className="form-label">Program Image</label>
                        <div className="imageWrapper position-relative">
                          <div className="d-flex align-items-center justify-content-center">
                            <img className="image" src="./assets/images/image_upload.png" alt="image_upload" />
                          </div>
                          <button className="file-upload">
                            <input type="file" className="file-input" />Choose File
                          </button>
                        </div>
                      </div> */}
                      <div className="form-group mb-4">
                        <h3 className="mb-2">Sub-Category</h3>
                        <label className="mb-2" style={{ textTransform: 'none' }}>Select the Main Category</label>
                        <select className="form-select" aria-label="Default select example" name='subject_category' {...register("subject_category")} onChange={handleSubjectCategoryChange}>
                          {MainCatdata?.filter(mainCat => mainCat.category_name != "In-person Classes").map((mainCategory, index) => {
                            return (
                              <option
                                key={index}
                                selected={subjectCategory === mainCategory.category_id ? 'selected' : ''}
                                value={`${mainCategory.category_id},${mainCategory.category_name}`}
                              >
                                {mainCategory.category_name}
                              </option>

                            );
                          })}
                        </select>

                      </div>

                      {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.subject_category?.message}</span></p> */}
                      <div className="form-group mb-4">
                        <label className="mb-2" style={{ textTransform: 'none' }}>Select the Sub-Category</label>
                        <select className="form-select fw-500" aria-label="Default select example" name='subject_sub_category' {...register("subject_sub_category")} onChange={handleSubjectSubCategoryChange}>
                          {SelectedSubCat[0]?.category_name ? (<>
                            {SelectedSubCat?.map((SubCat, index) => {
                              return (
                                <>
                                  <optgroup label={SubCat.category_name}>
                                    {SubCat.category?.map((SubCat1, index1) => {
                                      return (

                                        <option value={SubCat1.subject_name}>{SubCat1.subject_name}</option>
                                      )

                                    })}
                                  </optgroup>
                                </>)
                            })}
                          </>) : (<>
                            {SelectedSubCat?.map((SubCat, index) => {
                              return (
                                <>
                                  <option value={SubCat.subject_name}>{SubCat.subject_name}</option>
                                </>)
                            })}
                          </>)}


                        </select>
                      </div>
                      <div className="form-group mb-4 class_image">
                        <h3 className="mb-1 1 text-capitalize">Learner age range</h3>
                        <p className="fs-6 mb-4 1 text-capitalize">Age Range Cannot Exceed 3 Years for Group Classes For Learners Under 18 Years Old. It is Because Learners Tend To Thrive In Classes With Others Who Match Their Developmental Age.
                        </p>
                        <div className="d-md-flex">
                          <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                            <select className="form-select fw-500 mb-md-0 mb-3 w-auto pe-5 rounded-pill" aria-label="Default select example" {...register("start_age")} onChange={(e) => ageRange(e.target.value)}>
                              <option selected={startageRanges == '3' ? 'selected' : ''} value="3" >3 Years</option>
                              <option selected={startageRanges == '4' ? 'selected' : ''} value="4">4 Years</option>
                              <option selected={startageRanges == '5' ? 'selected' : ''} value="5">5 Years</option>
                              <option selected={startageRanges == '6' ? 'selected' : ''} value="6" >6 Years</option>
                              <option selected={startageRanges == '7' ? 'selected' : ''} value="7">7 Years</option>
                              <option selected={startageRanges == '8' ? 'selected' : ''} value="8">8 Years</option>
                              <option selected={startageRanges == '9' ? 'selected' : ''} value="9">9 Years</option>
                              <option selected={startageRanges == '10' ? 'selected' : ''} value="10">10 Years</option>
                              <option selected={startageRanges == '11' ? 'selected' : ''} value="11">11 Years</option>
                              <option selected={startageRanges == '12' ? 'selected' : ''} value="12">12 Years</option>
                              <option selected={startageRanges == '13' ? 'selected' : ''} value="13">13 Years</option>
                              <option selected={startageRanges == '14' ? 'selected' : ''} value="14">14 Years</option>
                              <option selected={startageRanges == '15' ? 'selected' : ''} value="15">15 Years</option>
                              <option selected={startageRanges == '16' ? 'selected' : ''} value="16">16 Years</option>
                              <option selected={startageRanges == '17' ? 'selected' : ''} value="17">17 Years</option>
                              <option selected={startageRanges == '18' ? 'selected' : ''} value="18">18 Years</option>
                              <option selected={startageRanges == 'adults' ? 'selected' : ''} value="adults">Adults</option>
                            </select>
                            <span className="ms-3">To</span>
                          </div>
                          <div className="d-flex ms-md-3 align-items-center">
                            <input type="hidden" {...register("end_age")} value={ageRanges} />

                            <select className="form-select fw-500 w-auto pe-5 rounded-pill" value={ageRanges} aria-label="Default select example" onChange={(e) => endageRange(e.target.value)} disabled>
                              <option value="3">3 Years</option>
                              <option value="4">4 Years</option>
                              <option value="5">5 Years</option>
                              <option value="6">6 Years</option>
                              <option value="7">7 Years</option>
                              <option value="8">8 Years</option>
                              <option value="9">9 Years</option>
                              <option value="10">10 Years</option>
                              <option value="11">11 Years</option>
                              <option value="12">12 Years</option>
                              <option value="13">13 Years</option>
                              <option value="14">14 Years</option>
                              <option value="15">15 Years</option>
                              <option value="16">16 Years</option>
                              <option value="17">17 Years</option>
                              <option value="18">18 Years</option>
                              <option value="adults">Adults</option>
                            </select>
                            <span className="ms-3 text-capitalize">years old</span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-4 class_image">
                        <h3 className="mb-1 1 text-capitalize">Quiz Duration</h3>
                        <p className="fs-6 mb-4 1 text-capitalize">Set A Timer For The Quiz. </p>
                        <div className="d-md-flex align-items-center">
                          <p className="fs-6  mb-md-0 mb-3">Length of the Quiz in Hours </p>
                          <div className="d-md-flex ms-md-3">
                            <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                              <select className="form-select mb-md-0 mb-3 w-auto pe-5 fw-500 rounded-pill"
                                {...register("class_hours")} onChange={(e) => classHours(e.target.value)} name='class_hours' aria-label="Default select example">
                                {/* <option selected>0 Hours</option> */}
                                <option selected>0</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>6</option>
                                <option>7</option>
                                <option>8</option>
                                <option>9</option>

                              </select>
                              <span className="ms-3 text-capitalize">and minutes</span>
                            </div>
                            <div className="ms-md-3">
                              <select className="form-select w-auto pe-5 rounded-pill fw-500"
                                {...register("class_min")} onChange={(e) => classsMinutesData(e.target.value)} name='class_min' aria-label="Default select example">
                                <option>0 </option>
                                <option>5 </option>
                                <option>10 </option>
                                <option>15 </option>
                                <option>20 </option>
                                <option>25 </option>
                                <option selected>30 </option>
                                <option>35 </option>
                                <option>40 </option>
                                <option>45 </option>
                                <option>50 </option>
                                <option >55 </option>
                              </select>
                            </div>

                          </div>

                        </div>
                        {classHoursData == '0' && classMinutesData == '0' && (
                          <p className="text-danger mt-1">Quiz duration cannot be 0 hours and 0 minutes. Please select a valid duration.</p>
                        )}
                      </div>

                      <div className="form-group mb-4">
                        <h3 className="mb-2 1 text-capitalize">Language of instruction</h3>
                        <p className="fs-6 mb-4 1 text-capitalize">What Is The Main Language of the Quiz?</p>
                        <label className="mb-2">Select the language</label>
                        <select className="form-select fw-500 mb-4" {...register("language")} aria-label="Default select example">
                          <option selected>English</option>
                          <option>French</option>
                          <option>Mandarin</option>
                          <option>Cantonese</option>
                          <option>Spanish</option>
                          <option>Hindi</option>
                          <option>Japanese</option>
                          <option>Arabic</option>
                        </select>
                      </div>
                      <div className="form-group mb-4">
                        <h3 className="mb-2 1 text-capitalize">Select Question</h3>
                        <select className="form-select fw-500 mb-4" {...register("select_que")} onChange={(e) => { Suggest_que(e.target.value) }} aria-label="Default select example">
                          <option value="10-25">10 - 25 Questions</option>
                          <option value="26-50">26 - 50 Questions</option>
                          <option value="51-75">51 - 75 Questions</option>
                          <option value="76-100">76 - 100 Questions</option>
                        </select>
                      </div>
                      <div className="form-group mb-4">
                        <h3 className="mb-2 1 text-capitalize">Pricing</h3>
                        <p className="fs-6 mb-4  text-capitalize">How Much Will You Charge For Your Quiz?</p>
                        <p className="fs-6 fw-600 mb-4 text-capitalize">{printEstimation}</p>
                        <div className="row">
                          <div className="col-lg-6 mb-lg-0 mb-4">
                            <div className="usd_learner flex-wrap mb-4 d-flex align-items-center">
                              <span>$</span>
                              <input
                                type="number"
                                className="form-control mx-3 w-auto"
                                name="price"
                                value={price}
                                onChange={handlePriceChange}
                                min={20}
                                max={70}
                                autocomplete="off"
                              />
                              <span className="text-capitalize">USD per learner</span>
                            </div>
                            <div className="pricing_guide">
                              <span className="mb-2 d-block text-capitalize">{pricingLevel}</span>

                              <div className="progress rounded-pill">

                                <div className="progress-bar bg-warning rounded-pill" role="progressbar" style={{ width: progressBarWidth }} aria-valuenow={price} aria-valuemin={20} aria-valuemax={70} />

                              </div>
                              <div className="mt-1 mb-2 d-flex justify-content-between align-items-center ">
                                <span>(20 $)</span>
                                <span>(50 $)</span>
                                <span>(70 $)</span>
                              </div>
                              <p className="fs-6 text-capitalize">Pricing Guide: Please Refer to Similar Quiz Prices Set by Other Teachers, Sub-Category and Ages of the Learners.</p>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="pricing_guide">
                              <span className="fs-6 fw-600 mb-2 text-capitalize">Estimated Earnings</span>
                              <h3 className="font-18 mb-2 text-capitalize">${Estimatedprice} USD per learner</h3>
                              <p className="fs-6 text-capitalize">How This is Calculated: The Price Per Class, And Studentscholar’s 40% Service Fee is Taken into Account.</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-5">
                        <div className="col-lg-3">
                          {/* <Link to="/add_question" className="btn-theme bg-yellow d-block text-center fw-600">Next</Link> */}
                          <button className="btn-theme bg-yellow text-center fw-600 d-block" >
                            Next
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>
      <TFooter_Dashboard />
    </>
  )
}
