import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import JqueryLoad from '../../../JqueryLoad/JqueryLoad';
import OFooter_Dashboard from '../OInclude/OFooter_Dashboard';
import OHeader_Dashboard from '../OInclude/OHeader_Dashboard';
import { Change_Passsword } from '../../../api/apiHandler';
import { TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/common.service';

export default function OChangepassword() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate();

  const schema = yup.object().shape({
    password: yup.string()
      .required("Please enter current password"),

    new_password: yup.string()
      .required("Please enter new password")
      .min(8, "Password must contains 8 or more characters,at least one uppercase, one lowercase, one digit, one special character")
      .matches(/(?=.*[a-z])(?=.*[A-Z])\w+/, "Password must contains 8 or more characters,at least one uppercase, one lowercase, one digit, one special character")
      .matches(/\d/, "Password must contains 8 or more characters,at least one uppercase, one lowercase, one digit, one special character")
      .matches(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, "Password must contains 8 or more characters,at least one uppercase, one lowercase, one digit, one special character"),

    confirm_password: yup.string()
      .required('Please enter new password ')
      .oneOf([yup.ref('new_password'), null], "Confirm password doesn't match with the New password "),
  });

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  let onSubmit = data => {
    let submitData = {
      "old_password": data.password,
      "new_password": data.new_password,
    }

    console.log("submitData", submitData)
    Change_Passsword(submitData).then((response) => {
      console.log('Change_Passsword :', response);
      if (response?.code == 1) {
        TOAST_SUCCESS(response.message);
        navigate("/login");
      } else {
        TOAST_ERROR(response.message);
      }
    });
  }

  const handleTogglePassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleTogglePassword1 = (e) => {
    e.preventDefault();
    setShowPassword1(!showPassword1);
  };

  const handleTogglePassword2 = (e) => {
    e.preventDefault();
    setShowPassword2(!showPassword2);
  };

  return (
    <>
      <JqueryLoad />
      <OHeader_Dashboard />
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div>
              <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span className="ms-2">Back</span>
                </span></a>
              <div>
                <div className="mb-4">
                  <h2 className="mb-2 poppins fw-600">Please Change your Password here.</h2>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="col-lg-6">
                    <form onSubmit={handleSubmit(onSubmit)} className="custom_form mt-4" action id>
                      <div className="form-group mb-4">
                        <div className="d-flex justify-content-between position-relative">
                          <label className="mb-2">Current Password</label>
                          <Link onClick={handleTogglePassword}
                            style={{ position: "absolute", right: "20px", zIndex: "9999", bottom: "-36px" }}
                          >
                            <span>
                              {showPassword ? (
                                <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#666666">
                                  <path d="M0 0h24v24H0z" fill="none" />
                                  <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                                </svg>
                              ) : (
                                <svg width={19} height={17} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M17.0194 0.881336L16.2835 0.145393C16.0755 -0.0625975 15.6915 -0.0305884 15.4515 0.257357L12.8913 2.80132C11.7392 2.30538 10.4754 2.06538 9.14731 2.06538C5.19519 2.08132 1.77141 4.38529 0.12329 7.69749C0.0272626 7.90549 0.0272626 8.16141 0.12329 8.3374C0.891223 9.90543 2.04329 11.2015 3.4833 12.1774L1.3873 14.3054C1.1473 14.5454 1.11529 14.9293 1.27534 15.1374L2.01128 15.8733C2.21928 16.0813 2.60326 16.0493 2.84326 15.7613L16.8912 1.71345C17.1952 1.47358 17.2272 1.08962 17.0192 0.881611L17.0194 0.881336ZM9.9953 5.71322C9.72329 5.6492 9.43534 5.56925 9.16332 5.56925C7.80327 5.56925 6.71538 6.65727 6.71538 8.01718C6.71538 8.28919 6.7794 8.57714 6.85936 8.84916L5.78724 9.90519C5.46728 9.34524 5.2913 8.72114 5.2913 8.01722C5.2913 5.88924 7.00332 4.17722 9.1313 4.17722C9.83536 4.17722 10.4593 4.3532 11.0193 4.67316L9.9953 5.71322Z"
                                    fill="#666666"
                                    fillOpacity="0.8"
                                  />
                                  <path
                                    d="M18.1714 7.69743C17.6115 6.57738 16.8754 5.56945 15.9635 4.75342L12.9875 7.69743V8.01738C12.9875 10.1454 11.2754 11.8574 9.14745 11.8574H8.8275L6.93951 13.7454C7.64357 13.8893 8.37952 13.9854 9.09952 13.9854C13.0516 13.9854 16.4754 11.6814 18.1235 8.35325C18.2675 8.12918 18.2675 7.90527 18.1715 7.69726L18.1714 7.69743Z"
                                    fill="#666666"
                                    fillOpacity="0.8"
                                  />
                                </svg>
                              )}
                            </span>
                          </Link>
                        </div>
                        <input name="password"
                          {...register("password")}
                          type={showPassword ? 'text' : 'password'}
                          placeholder=""
                          className="form-control" />
                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.password?.message}</span></p>
                      </div>
                      <div className="form-group mb-4">
                        <div className="d-flex justify-content-between position-relative">
                          <label className="mb-2">New Password</label>

                          <Link onClick={handleTogglePassword1}
                            style={{ position: "absolute", right: "20px", zIndex: "9999", bottom: "-36px" }}
                          >
                            <span>
                              {showPassword1 ? (
                                <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#666666">
                                  <path d="M0 0h24v24H0z" fill="none" />
                                  <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                                </svg>
                              ) : (
                                <svg width={19} height={17} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M17.0194 0.881336L16.2835 0.145393C16.0755 -0.0625975 15.6915 -0.0305884 15.4515 0.257357L12.8913 2.80132C11.7392 2.30538 10.4754 2.06538 9.14731 2.06538C5.19519 2.08132 1.77141 4.38529 0.12329 7.69749C0.0272626 7.90549 0.0272626 8.16141 0.12329 8.3374C0.891223 9.90543 2.04329 11.2015 3.4833 12.1774L1.3873 14.3054C1.1473 14.5454 1.11529 14.9293 1.27534 15.1374L2.01128 15.8733C2.21928 16.0813 2.60326 16.0493 2.84326 15.7613L16.8912 1.71345C17.1952 1.47358 17.2272 1.08962 17.0192 0.881611L17.0194 0.881336ZM9.9953 5.71322C9.72329 5.6492 9.43534 5.56925 9.16332 5.56925C7.80327 5.56925 6.71538 6.65727 6.71538 8.01718C6.71538 8.28919 6.7794 8.57714 6.85936 8.84916L5.78724 9.90519C5.46728 9.34524 5.2913 8.72114 5.2913 8.01722C5.2913 5.88924 7.00332 4.17722 9.1313 4.17722C9.83536 4.17722 10.4593 4.3532 11.0193 4.67316L9.9953 5.71322Z"
                                    fill="#666666"
                                    fillOpacity="0.8"
                                  />
                                  <path
                                    d="M18.1714 7.69743C17.6115 6.57738 16.8754 5.56945 15.9635 4.75342L12.9875 7.69743V8.01738C12.9875 10.1454 11.2754 11.8574 9.14745 11.8574H8.8275L6.93951 13.7454C7.64357 13.8893 8.37952 13.9854 9.09952 13.9854C13.0516 13.9854 16.4754 11.6814 18.1235 8.35325C18.2675 8.12918 18.2675 7.90527 18.1715 7.69726L18.1714 7.69743Z"
                                    fill="#666666"
                                    fillOpacity="0.8"
                                  />
                                </svg>
                              )}
                            </span>
                          </Link>
                        </div>
                        <input name="new_password"
                          {...register("new_password")}
                          type={showPassword1 ? 'text' : 'password'}
                          placeholder=""
                          className="form-control" />
                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.new_password?.message}</span></p>
                      </div>
                      <div className="form-group mb-4">
                        <div className="d-flex justify-content-between position-relative">
                          <label className="mb-2">Confirm New Password</label>

                          <Link onClick={handleTogglePassword2}
                          style={{ position: "absolute", right: "20px", zIndex: "9999", bottom: "-36px" }}
                        >
                          <span>
                            {showPassword2 ? (
                              <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#666666">
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                              </svg>
                            ) : (
                              <svg width={19} height={17} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M17.0194 0.881336L16.2835 0.145393C16.0755 -0.0625975 15.6915 -0.0305884 15.4515 0.257357L12.8913 2.80132C11.7392 2.30538 10.4754 2.06538 9.14731 2.06538C5.19519 2.08132 1.77141 4.38529 0.12329 7.69749C0.0272626 7.90549 0.0272626 8.16141 0.12329 8.3374C0.891223 9.90543 2.04329 11.2015 3.4833 12.1774L1.3873 14.3054C1.1473 14.5454 1.11529 14.9293 1.27534 15.1374L2.01128 15.8733C2.21928 16.0813 2.60326 16.0493 2.84326 15.7613L16.8912 1.71345C17.1952 1.47358 17.2272 1.08962 17.0192 0.881611L17.0194 0.881336ZM9.9953 5.71322C9.72329 5.6492 9.43534 5.56925 9.16332 5.56925C7.80327 5.56925 6.71538 6.65727 6.71538 8.01718C6.71538 8.28919 6.7794 8.57714 6.85936 8.84916L5.78724 9.90519C5.46728 9.34524 5.2913 8.72114 5.2913 8.01722C5.2913 5.88924 7.00332 4.17722 9.1313 4.17722C9.83536 4.17722 10.4593 4.3532 11.0193 4.67316L9.9953 5.71322Z"
                                  fill="#666666"
                                  fillOpacity="0.8"
                                />
                                <path
                                  d="M18.1714 7.69743C17.6115 6.57738 16.8754 5.56945 15.9635 4.75342L12.9875 7.69743V8.01738C12.9875 10.1454 11.2754 11.8574 9.14745 11.8574H8.8275L6.93951 13.7454C7.64357 13.8893 8.37952 13.9854 9.09952 13.9854C13.0516 13.9854 16.4754 11.6814 18.1235 8.35325C18.2675 8.12918 18.2675 7.90527 18.1715 7.69726L18.1714 7.69743Z"
                                  fill="#666666"
                                  fillOpacity="0.8"
                                />
                              </svg>
                            )}
                          </span>
                        </Link>
                        </div>
                        <input name="confirm_password"
                          {...register("confirm_password")}
                          type={showPassword2 ? 'text' : 'password'}
                          placeholder=""
                          className="form-control" />
                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.confirm_password?.message}</span></p>
                      </div>
                      <div className="row mt-5 mb-3">
                        <div className="button">
                          <button type='submit' className="col-md-12 btn-theme bg-yellow text-center fw-600 d-block" >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-lg-6 mb-lg-0 mb-5 order-lg-last order-first text-lg-end text-center">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <OFooter_Dashboard />
    </>
  )
}
