import React, { useEffect, useState } from "react";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import { useLocation, useNavigate } from "react-router-dom";
import { getQuizAnswer } from "../../../api/apiHandler";
import { TOAST_ERROR } from "../../../utils/common.service";
import BackToPrevious from "../../../components/backToPrevious";
import OHeader_Dashboard from "../OInclude/OHeader_Dashboard";
import OFooter_Dashboard from "../OInclude/OFooter_Dashboard";

export default function QuizReviewO() {
    window.scrollTo(0, 0)
    const navigate = useNavigate();

    const [questionData, setQuestionData] = useState(null);
    console.log("🚀  PQuizReview  questionData:", questionData);
    const [page, setPage] = useState(1);
    const location = useLocation();
    const { quiz_id, duration, main_category, subject_category, totalQuestions } = location?.state;

    const fetchQuizQuestion = async () => {
        try {
            const { code, data, message } = await getQuizAnswer({ quiz_id, page, per_page: 1 });
            if (code == 1) setQuestionData(data[0]);
            else TOAST_ERROR(message);
        } catch (err) {
            TOAST_ERROR(err.message);
        }
    };

    useEffect(() => {
        quiz_id && fetchQuizQuestion();
    }, [quiz_id, page]);

    var getData = localStorage.getItem("storeData");
    var get_data = JSON.parse(getData);

    const getBack = () => {
        localStorage.removeItem("storeData")
        navigate('/organization-tech-detail', {
            state: { id: get_data.id, class: get_data.class }
        })
    }

    const downloadDocx = (url) => {
        let fileName = url.split("/");
        fileName = fileName[fileName.length - 1];

        const anchor = document.createElement("a");
        anchor.style.display = "none";
        anchor.href = url;
        anchor.target = "_blank";
        anchor.download = fileName;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    };


    const showOptions = questionData?.options?.map((el) => {
        return (
            <div key={el.option_id} className="form-check mb-3">
                {questionData.que_type === "Multiple" ? (
                    <input
                        className="form-check-input pointer"
                        type="checkbox"
                        name="flexRadioDefault"
                        value={el.option_id}
                        checked={questionData?.user_answerd_option_id.includes(el.option_id)}
                        readOnly
                    />
                ) : (
                    <input
                        className="form-check-input pointer"
                        type="radio"
                        name="flexRadioDefault"
                        value={el.option_id}
                        checked={questionData?.user_answerd_option_id == el.option_id}
                        readOnly
                    />
                )}
                <label className="form-check-label d-xl-flex " htmlFor="flexRadioDefault1">
                    {el.options}
                    {questionData?.user_answerd_option_id == el.option_id ? (
                        <span className="text-danger d-sm-inline-block d-block fw-bold ms-sm-5">Your Answer</span>
                    ) : (
                        <></>
                    )}
                    {el.is_ans ? (
                        <span className="text-success d-sm-inline-block d-block fw-bold ms-sm-5">Correct Answer </span>
                    ) : (
                        // <span className="d-lg-flex ms-xl-5">
                        //   <span className="text-success w-100 d-lg-inline-block d-block fw-bold">Correct Answer </span>
                        //   <p className="text-dark mx-5">Explaination:&nbsp;{questionData?.answer_explanation}</p>
                        //   {/ <input type="text" placeholder="Explaination Of answere will Come Here...." className="form-control bg-gray" /> /}
                        // </span>
                        <></>
                    )}
                </label>
            </div>
        );
    });

    const renderAttachment = () => {
        
        console.log('description_typerenderAttachment :', questionData?.description_type);
        if (questionData?.description_type == "image") {
            return <img src={questionData?.description_attachment} alt="Attachment" />;
        } else if (questionData?.description_type == "video") {
            return (
                <video controls>
                    <source src={questionData?.description_attachment} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            );
        } else if (questionData?.description_type == "docx" || questionData?.description_type == "pdf" || questionData?.description_type == "xlsx") {
            return (
                <button className="btn border-purple rounded-cricle rounded-pill mt-4" onClick={() => downloadDocx(questionData?.description_attachment)}>
                    Download {questionData?.description_type.toUpperCase()}
                </button>
            );
        } else {
            return null;
        }
    };

    return (
        <>
            <JqueryLoad />
            <OHeader_Dashboard />
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="#" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <div className="class-content">
                        <div>
                            <BackToPrevious />
                            <div>
                                <div className="class_lsit_section">
                                    <div className="mb-4">
                                        <h2 className="mb-2 poppins fw-600">Quiz Review</h2>
                                        <p className="fs-6 text-gray text-capitalize">{main_category}</p>
                                    </div>
                                    <div className="quiz_card">
                                        <div className="card_title d-flex justify-content-between align-items-center mb-4">
                                            <div>
                                                <h3 className="mb-2 poppins fw-bold">{subject_category} Quiz</h3>
                                                {/* {/ <p className="fs-6 text-dif-black">Read the following instructions</p> /} */}
                                            </div>
                                            {/* <div className="timer_calss">
                        <span>Timer: </span>
                        <span>
                          <span className="text-danger">29:09</span> Mins
                        </span>
                      </div> */}
                                        </div>
                                        <div className="row mb-5 align-items-center">
                                            <div className="col-lg-6 left_side">
                                                <div className="quiz_image">
                                                    {questionData?.attachment_type === "image" ? (
                                                        <img src={questionData?.question_attachment} alt="class4" />
                                                    ) : (
                                                        <video id="video" loop width="100%" controls="hide" poster="">
                                                            <source src={questionData?.question_attachment} type="video/mp4" />
                                                        </video>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 right_side">
                                                <div className="quiz_info ps-lg-5">
                                                    <div className="quiz_ques">
                                                        <div className="quiz_count text-purple fw-600 mb-3">
                                                            <span>Question</span>
                                                            <span className="ms-2">
                                                                {page}/{totalQuestions}
                                                            </span>
                                                        </div>
                                                        <p className="text-dif-black">{questionData?.question}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="quiz_Inst">
                                            <h4 className="mb-3 poppins fw-600">Choose answer</h4>
                                            <form>{showOptions}</form>
                                            <b className="text-dark text-bold">Explaination:</b>
                                            <p dangerouslySetInnerHTML={{ __html: questionData?.answer_explanation }} />
                                            {renderAttachment()}

                                        </div>
                                        <div className="d-flex justify-content-end pt-2">
                                            {page !== 1 && (
                                                <button onClick={() => setPage(page - 1)} className="btn-theme bg-yellow d-inline-block fw-500 ">
                                                    Prev
                                                </button>
                                            )}
                                            {page !== totalQuestions && (
                                                <button onClick={() => setPage(page + 1)} className="btn-theme bg-yellow d-inline-block fw-500 mx-2">
                                                    Next
                                                </button>
                                            )}
                                            <button onClick={() => getBack()} className="btn-theme bg-yellow d-inline-block fw-500 mx-2">
                                                Back To Quiz
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <OFooter_Dashboard />
        </>
    );
}
