import React from "react";
import { Link } from "react-router-dom";
import Footer_dashboard from "../../components/Footer_dashboard";
import Hdashboard from "../../components/Hdashboard";
import { Helmet } from "react-helmet";
import JqueryLoad from "../../JqueryLoad/JqueryLoad";

export default function RequestClassForm() {
  return (
    <>
      <JqueryLoad />
      <Hdashboard />
      <Helmet>
        <script>
          {`
            $(document).ready(function () {
              $('.js-example-basic-single').select2();
            });
          `}
        </script>

        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/css/select2.min.css" />

        <style>
          {`
          .select2-container--default .select2-selection--multiple .select2-selection__choice {
            background-color: #782f89;
            color: #fff;
          }
          `}
        </style>
      </Helmet>
      <div>
        <main className="dashboard-app">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            {/*start*/}
            <a href="#" className="mb-4 arrow_left mb-3 d-block" onClick="window.history.back()">
              <span>
                <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                </svg>
                <span className="ms-2">Back</span>
              </span>
            </a>
            {/*end*/}
            <section className="requirement_form">
              <div className="text-center mb-4">
                <h2 className=" text-center mb-2 fw-600 poppins text-capitalize text-purple">Request A Class</h2>
                <p className="fs-5">A Teacher will contact you</p>
              </div>
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-8">
                  <div className="card border-purple p-4">
                    <form className="custom_form mt-4" action id>
                      <div className="form-group mb-4">
                        <label className="mb-2">Parent’s/Guardian/Student’s name:</label>
                        <input type="text" className="form-control" name placeholder defaultValue />
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">Time zone</label>
                        <select className="form-select" aria-label="Default select example">
                          <option>UTC Universal Coordinated Time GMT</option>
                          <option>ECT European Central Time GMT+1:00</option>
                          <option>EET Eastern European Time GMT+2:00</option>
                          <option>ART (Arabic) Egypt Standard Time GMT+2:00</option>
                          <option>EAT Eastern African Time GMT+3:00</option>
                          <option>MET Middle East Time GMT+3:30</option>
                          <option>NET Near East Time GMT+4:00</option>
                          <option>PLT Pakistan Lahore Time GMT+5:00</option>
                          <option>IST India Standard Time GMT+5:30</option>
                          <option>BST Bangladesh Standard Time GMT+6:00</option>
                          <option>VST Vietnam Standard Time GMT+7:00</option>
                          <option>CTT China Taiwan Time GMT+8:00</option>
                          <option>JST Japan Standard Time GMT+9:00</option>
                          <option>ACT Australia Central Time GMT+9:30</option>
                          <option>AET Australia Eastern Time GMT+10:00</option>
                          <option>SST Solomon Standard Time GMT+11:00</option>
                          <option>NST New Zealand Standard Time GMT+12:00</option>
                          <option>MIT Midway Islands Time GMT-11:00</option>
                          <option>HST Hawaii Standard Time GMT-10:00</option>
                          <option>AST Alaska Standard Time GMT-9:00</option>
                          <option>PST Pacific Standard Time GMT-8:00</option>
                          <option>PNT Phoenix Standard Time GMT-7:00</option>
                          <option>MST Mountain Standard Time GMT-7:00</option>
                          <option>CST Central Standard Time GMT-6:00</option>
                          <option>EST Eastern Standard Time GMT-5:00</option>
                          <option>IET Indiana Eastern Standard Time GMT-5:00</option>
                          <option>PRT Puerto Rico and US Virgin Islands Time GMT-4:00</option>
                          <option>CNT Canada Newfoundland Time GMT-3:30</option>
                          <option>AGT Argentina Standard Time GMT-3:00</option>
                          <option>BET Brazil Eastern Time GMT-3:00</option>
                          <option>CAT Central African TimeGMT-1:00</option>
                        </select>
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">Categories list(predefined list)</label>
                        <select
                          className="form-select"
                          data-bs-toggle="modal"
                          data-bs-target="#select_learner_like"
                          aria-label="Default select example"
                          disabled
                        >
                          <option>Academic</option>
                          <option>Languages</option>
                          <option>Coding &amp; Tech</option>
                          <option>Art</option>
                          <option>Music</option>
                          <option>Health &amp; Wellness</option>
                          <option>Life Skills</option>
                          <option>Professional Training</option>
                          <option>Online</option>
                        </select>
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">Subcategories list</label>
                        <select
                          className="form-select"
                          data-bs-toggle="modal"
                          data-bs-target="#select_subcategories"
                          aria-label="Default select example"
                          disabled
                        >
                          <optgroup label="Subjects">
                            <option>Math</option>
                            <option>English</option>
                            <option>Science</option>
                            <option>Nature &amp; Space</option>
                            <option>Social Sciences</option>
                            <option>Business / Economics</option>
                            <option>Accounting/ Finance</option>
                            <option>Engineering</option>
                            <option>Medicine/Nursing</option>
                            <option>Law</option>
                            <option>Exam Preparation</option>
                            <option>Homework Help</option>
                          </optgroup>
                          {/*Note for developer:- clubs and Competitions no need to show on here */}
                        </select>
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">Age of the student</label>
                        <select className="form-select" aria-label="Default select example">
                          <option selected>3 Years</option>
                          <option>4 Years</option>
                          <option>5 Years</option>
                          <option>6 Years</option>
                          <option>7 Years</option>
                          <option>8 Years</option>
                          <option>9 Years</option>
                          <option>10 Years</option>
                          <option>11 Years</option>
                          <option>12 Years</option>
                          <option>13 Years</option>
                          <option>14 Years</option>
                          <option>15 Years</option>
                          <option>16 Years</option>
                          <option>17 Years</option>
                          <option>18 Years</option>
                          <option>Adults</option>
                        </select>
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">Type of Class</label>
                        <select className="form-select" aria-label="Default select example">
                          <option selected>Online</option>
                          <option>In-person</option>
                        </select>
                      </div>
                      {/*If it’s in-person, please enter country, city and zip code */}
                      <div className="form-group mb-4">
                        <label className="mb-2">Country</label>
                        <select className="form-select" aria-label="Default select example">
                          <option selected>India</option>
                          <option>United States</option>
                          <option>Australia</option>
                          <option>Canada</option>
                          <option>Bangladesh</option>
                          <option>Germany</option>
                          <option>China</option>
                          <option>Argentina</option>
                          <option>Afghanistan</option>
                          <option>Belgium</option>
                          <option>France</option>
                          <option>Brazil</option>
                          <option>Austria</option>
                          <option>Cambodia</option>
                          <option>Angola</option>
                          <option>Algeria</option>
                        </select>
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">City</label>
                        <select className="form-select" aria-label="Default select example">
                          <option selected>India</option>
                          <option>Sydney</option>
                          <option>Australia</option>
                          <option>Melbourne</option>
                          <option>Bangladesh</option>
                          <option>Brisbane</option>
                          <option>Canberra</option>
                          <option>Perth</option>
                          <option>Adelaide</option>
                          <option>Hobart</option>
                          <option>Darwin</option>
                          <option>Gold Coast</option>
                          <option>Cairns</option>
                          <option>Newcastle</option>
                          <option>Wollongong</option>
                          <option>Geelong</option>
                        </select>
                      </div>
                      <div className="form-group mb-4">
                        {/*only 3 digit show and dots or something */}
                        <label className="mb-2">zip code</label>
                        <input type="number" className="form-control" name placeholder defaultValue />
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">What is your budget per ?</label>
                        <select className="form-select" aria-label="Default select example">
                          <option selected>Under $25</option>
                          <option>Under $25-$50</option>
                          <option>Under $50-75</option>
                          <option>Under $75-$100</option>
                          <option>Over $100+</option>
                          <option>Does not matter</option>
                        </select>
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">Class duration:</label>
                        <select className="form-select" aria-label="Default select example">
                          <option selected>30 minutes</option>
                          <option>45 minutes</option>
                          <option>1 hour</option>
                          <option>2 hours</option>
                          <option>Does not matter</option>
                          <option>Specify</option>
                        </select>
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">Class Size</label>
                        <select className="form-select" aria-label="Default select example">
                          <option selected>Individual/private</option>
                          <option>Group</option>
                        </select>
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">What is Your Day Preference?</label>
                        <select multiple className="form-select" aria-label="Default select example" id="select2-example">
                          <option selected>Monday</option>
                          <option>Tuesday</option>
                          <option>Wednesday</option>
                          <option>Thursday</option>
                          <option>Friday</option>
                          <option>Saturday</option>
                          <option>Sunday</option>
                        </select>
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">What Is Your Time Preference?</label>
                        <select multiple className="form-select" aria-label="Default select example" id="select3-example">
                          <option value="O1" select3-example="./assets/images/dropdown_icon/time.jpg" selected>
                            No Preference: Flexible
                          </option>
                          <option value="O2" select3-example="./assets/images/dropdown_icon/time.jpg">
                            Morning: 9:00 AM- 12:00 PM
                          </option>
                          <option value="O3" select3-example="./assets/images/dropdown_icon/time.jpg">
                            Afternoon: 12:00 - 4:00 PM
                          </option>
                          <option value="O4" select3-example="./assets/images/dropdown_icon/time.jpg">
                            Evening: 4:00 - 7:00 PM
                          </option>
                          <option value="O5" select3-example="./assets/images/dropdown_icon/time.jpg">
                            Night: 7:00 - 10:00 PM
                          </option>
                        </select>
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">What you are expecting from the class:</label>
                        <textarea className="form-control" rows={6} defaultValue={""} />
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">How often do you want lessons?</label>
                        <select multiple className="form-select" aria-label="Default select example" id="mySelect">
                          <option selected>Daily</option>
                          <option>Once a week Week</option>
                          <option>Few times a week</option>
                          <option>Other: Please specify</option>
                        </select>
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">Are you ready to hire someone?</label>
                        <select className="form-select" aria-label="Default select example">
                          <option selected>Yes, if the professional is right</option>
                          <option>Yes, I need this service ASAP</option>
                          <option>I'm researching and planning</option>
                          <option>Just looking</option>
                        </select>
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">Comments</label>
                        <textarea className="form-control" rows={6} defaultValue={""} />
                      </div>
                      <div className="button mt-5">
                        <Link to="/kidsDashboard" className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block">
                          Ask a teacher to contact me
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
                {/* <div class="col-lg-6 order-lg-last order-first">
        			<div class="">
        				<img src="./assets/images/request_a_class.png" alt="request_a_class">
        			</div>
        		</div> */}
              </div>
            </section>
          </div>
        </main>
        {/* Modal */}
        <div className="modal fade for_cate_modal" id="select_learner_like" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Select Your Category
                </h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <div className="popular_item">
                  <ul>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon active">
                          <img src="./assets/images/topic/topic9.png" alt="topic9" />
                        </div>
                        <span className="ms-3">Academic</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon">
                          <img src="./assets/images/topic/topic3.png" alt="topic3" />
                        </div>
                        <span className="ms-3">Languages</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon">
                          <img src="./assets/images/topic/topic5.png" alt="topic5" />
                        </div>
                        <span className="ms-3">Coding &amp; Tech</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon">
                          <img src="./assets/images/topic/topic1.png" alt="topic1" />
                        </div>
                        <span className="ms-3">Arts</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon">
                          <img src="./assets/images/topic/topic6.png" alt="topic6" />
                        </div>
                        <span className="ms-3">Music</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon">
                          <img src="./assets/images/topic/topic2.png" alt="topic2" />
                        </div>
                        <span className="ms-3">Health and Wellness</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon">
                          <img src="./assets/images/topic/topic4.png" alt="topic4" />
                        </div>
                        <span className="ms-3">Life Skills</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon">
                          <img src="./assets/images/topic/topic7.png" alt="topic7" />
                        </div>
                        <span className="ms-3">Professional Training</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <div className="col-lg-4 col-12">
                  <button type="button" className="btn-theme w-100 bg-yellow fw-600" data-bs-dismiss="modal">
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end*/}
        {/*start*/}
        {/* Modal */}
        <div className="modal fade for_cate_modal" id="select_subcategories" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Select Your Subcategories
                </h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <div className="popular_item">
                  <h4 className="fs-6 mb-4">Subject</h4>
                  <ul>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon active">
                          <img src="./assets/images/dropdown_icon/maths.png" alt="maths" />
                        </div>
                        <span className="ms-3">Math</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon">
                          <img src="./assets/images/dropdown_icon/english.png" alt="english" />
                        </div>
                        <span className="ms-3">English</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon">
                          <img src="./assets/images/dropdown_icon/space.png" alt="space" />
                        </div>
                        <span className="ms-3">Nature &amp; Space</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon">
                          <img src="./assets/images/dropdown_icon/social.png" alt="social" />
                        </div>
                        <span className="ms-3">Social Sciences</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon">
                          <img src="./assets/images/dropdown_icon/business.png" alt="business" />
                        </div>
                        <span className="ms-3">Business / Economics</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon">
                          <img src="./assets/images/dropdown_icon/accounting.png" alt="accounting" />
                        </div>
                        <span className="ms-3">Accounting/ Finance</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon">
                          <img src="./assets/images/dropdown_icon/engineering.png" alt="engineering" />
                        </div>
                        <span className="ms-3">Engineering</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon">
                          <img src="./assets/images/dropdown_icon/medicine.png" alt="medicine" />
                        </div>
                        <span className="ms-3">Medicine/Nursing</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon">
                          <img src="./assets/images/dropdown_icon/law.png" alt="law" />
                        </div>
                        <span className="ms-3">Law</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon">
                          <img src="./assets/images/dropdown_icon/exam.png" alt="exam" />
                        </div>
                        <span className="ms-3">Exam Preparation</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon">
                          <img src="./assets/images/dropdown_icon/homework_help.png" alt="homework_help" />
                        </div>
                        <span className="ms-3">Homework Help</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <div className="col-lg-4 col-12">
                  <button type="button" className="btn-theme w-100 bg-yellow fw-600" data-bs-dismiss="modal">
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer_dashboard />
    </>
  );
}
