import React from 'react'
import OFooter_Dashboard from '../../OInclude/OFooter_Dashboard'
import OHeader_Dashboard from '../../OInclude/OHeader_Dashboard'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import JqueryLoad from '../../../../JqueryLoad/JqueryLoad'
import { Video_URL } from '../../../../Config'

export default function OTeachDetailOffline() {
  return (
    <>
<JqueryLoad/>
<OHeader_Dashboard/>
<Helmet>
<script src="./assets/js/custom_calendar.js"></script>

<script>
  {`
  var ctx = document.getElementById("myChart").getContext('2d');

	var myChart = new Chart(ctx, {
	    type: 'doughnut',
	    data: {
	        labels:false,
	        datasets: [{
	            backgroundColor: [
	                "rgba(255, 255, 255, 0.5)",
	                "rgba(255, 255, 255, 1)"
	            ],
	            data: [50, 50]
	        }]
	    }
	});




var ctx = document.getElementById("myChart1").getContext('2d');
	var myChart = new Chart(ctx, {
	    type: 'doughnut',
	    data: {
	        labels:false,
	        datasets: [{
	            backgroundColor: [
	                "rgba(255, 255, 255, 0.5)",
	                "rgba(255, 255, 255, 1)"
	            ],
	            data: [50, 50]
	        }]
	    }
	});



var ctx = document.getElementById("myChart2").getContext('2d');
	var myChart = new Chart(ctx, {
	    type: 'doughnut',
	    data: {
	        labels:false,
	        datasets: [{
	            backgroundColor: [
	                "rgba(255, 255, 255, 0.5)",
	                "rgba(255, 255, 255, 1)"
	            ],
	            data: [50, 50]
	        }]
	    }
	});



	var ctx = document.getElementById("myChart3").getContext('2d');
	var myChart = new Chart(ctx, {
	    type: 'doughnut',
	    data: {
	        labels:false,
	        datasets: [{
	            backgroundColor: [
	                "rgba(255, 255, 255, 0.5)",
	                "rgba(255, 255, 255, 1)"
	            ],
	            data: [50, 50]
	        }]
	    }
	});


	var ctx = document.getElementById("myChart4").getContext('2d');
	var myChart = new Chart(ctx, {
	    type: 'doughnut',
	    data: {
	        labels:false,
	        datasets: [{
	            backgroundColor: [
	                "rgba(255, 255, 255, 0.5)",
	                "rgba(255, 255, 255, 1)"
	            ],
	            data: [50, 50]
	        }]
	    }
	});


	var ctx = document.getElementById("myChart5").getContext('2d');
	var myChart = new Chart(ctx, {
	    type: 'doughnut',
	    data: {
	        labels:false,
	        datasets: [{
	            backgroundColor: [
	                "rgba(255, 255, 255, 0.5)",
	                "rgba(255, 255, 255, 1)"
	            ],
	            data: [50, 50]
	        }]
	    }
	});
  `}
  </script>
</Helmet>
      <main className="dashboard-app d-lg-flex">
        <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
          <div className="align-items-center justify-content-between d-flex">
            <div className="d-flex align-items-center">
              <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                <i className="fa fa-bars" />
              </a>
              <a href="#" className="d-lg-none d-block brand-logo">
                <img src="./assets/images/logo.png" alt="logo" />
              </a>
            </div>
          </div>
        </header>
        <div className="class-content  bg-dark-gray">
          {/*start*/}
          <a href="#" className="mb-4 arrow_left mb-3 d-block" onclick="window.history.back()">
            <span>
              <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
              </svg>
              <span className="ms-2">Back</span>
            </span></a>
          {/*end*/}
          {/*start*/}
          <section className>  
            <div className="class_dtls_sec pb-0">
              <div className="row align-items-center"> 
                <div className="class_image_box h-auto col-xl-7 mb-xl-0 mb-4">
                  <div className="class_img position-relative">
                    <div className="pb-0 position-static video-container">
                      {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                      <video id="video" className="position-static rounded-3" loop width="100%" controls="hide" poster="./assets/images/video_image.png">
                                              <source src={`${Video_URL}/Classroom -teachers (2).mp4`} type="video/mp4" />

                      </video>
                    </div> 
                  </div>
                  <div>
                    <div className="align-items-center bottom-info d-flex justify-content-between position-static rounded-3">
                      <a href="teachers_detail.php" className="d-flex align-items-center">
                        <img src="./assets/images/user1.png" alt="user1" />
                        <span className="tech_nm ms-2">Celina Mark</span>
                      </a>
                      <div className="star_rat">
                        <span>
                          <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z" fill="#FDC840" />
                          </svg>
                        </span>
                        <span className="fw-600">4.87<span className="text-gray fw-500">(8426)</span></span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between follow-btn-sec position-static py-2 w-100">
                      <div>
                        <a href="#" className="follow_btn">Follow Me</a>
                      </div>
                      <span className="bg-yellow px-3 py-1 rounded-3 border-radius-10">Music</span>
                    </div>
                  </div> 
                </div>
                <div className="col-xl-5 ps-xl-5"> 
                  <div className="justify-content-between">
                    <div className>
                      <h2 className="mb-4 poppins fw-600">Welcome To</h2>
                      <p className="fw-500 text-capitalize">Get private writing tutoring on a weekly basis to improve your skills and receive personalized feedback.</p> 
                      <div className="button safety_btn mt-4">
                        <a href="#!" data-bs-toggle="modal" data-bs-target="#safety_offline" className="btn-theme mb-3 bg-yellow d-inline-block">
                          <span className="me-3">
                            <svg width={26} height={31} viewBox="0 0 26 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.1731 9.78467C10.0212 9.78467 7.45703 12.3488 7.45703 15.5005C7.45703 18.6525 10.0212 21.2166 13.1731 21.2166C16.3249 21.2166 18.889 18.6525 18.889 15.5005C18.889 12.3488 16.3249 9.78467 13.1731 9.78467ZM16.5668 14.4241L12.4718 18.519C12.3902 18.6009 12.2931 18.6658 12.1863 18.7101C12.0795 18.7544 11.9649 18.7771 11.8493 18.777C11.7337 18.7771 11.6192 18.7544 11.5124 18.7102C11.4056 18.6659 11.3086 18.6009 11.227 18.519L9.66209 16.9541C9.31832 16.6106 9.31832 16.0533 9.66209 15.7095C10.0056 15.3657 10.563 15.3657 10.9067 15.7095L11.8493 16.652L15.3221 13.1795C15.6657 12.8356 16.223 12.8356 16.5668 13.1795C16.9105 13.523 16.9105 14.0804 16.5668 14.4241Z" fill="#570861" />
                              <path d="M25.9172 8.63975L25.9163 8.61643C25.9027 8.30584 25.8937 7.99507 25.8893 7.68421C25.8648 6.03005 24.5499 4.66177 22.8955 4.56937C19.4463 4.37683 16.778 3.25213 14.4981 1.02991L14.4787 1.01139C13.735 0.329536 12.6133 0.329536 11.8694 1.01139L11.8499 1.02991C9.57005 3.25213 6.90175 4.37683 3.45252 4.56955C1.79842 4.66177 0.48323 6.03011 0.458738 7.68439C0.454439 7.99519 0.445454 8.3059 0.431785 8.61643L0.430379 8.67063C0.363289 12.1879 0.279969 16.5652 1.74434 20.5384C2.54959 22.7233 3.76904 24.6225 5.36871 26.1837C7.19062 27.9617 9.5769 29.3732 12.4613 30.3789C12.5562 30.4119 12.6533 30.4384 12.7519 30.4581C12.892 30.486 13.033 30.5 13.174 30.5C13.315 30.5 13.4562 30.486 13.5961 30.4581C13.6951 30.4383 13.7926 30.4116 13.8879 30.3784C16.7688 29.3709 19.1526 27.9587 20.9729 26.181C22.5719 24.6194 23.7914 22.7196 24.5973 20.5343C26.0671 16.5492 25.9841 12.1636 25.9172 8.63975ZM13.1736 22.9769C9.05091 22.9769 5.69713 19.6231 5.69713 15.5005C5.69713 11.3778 9.05097 8.02405 13.1736 8.02405C17.296 8.02405 20.65 11.3778 20.65 15.5005C20.65 19.6231 17.296 22.9769 13.1736 22.9769Z" fill="#570861" />
                            </svg>
                          </span>
                          Safety Tips
                        </a>
                        <a href="#!" data-bs-toggle="modal" data-bs-target="#class_option" className="btn-theme mb-3 bg-yellow d-inline-block">
                          <span className="me-3">
                            <svg width={30} height={27} viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M27.2385 0.5H14.0584C12.5331 0.5 11.2969 1.73645 11.2969 3.26147V9.75365C11.9936 10.0999 12.7465 10.4744 13.4491 10.8237L14.5242 8.67272C15.1751 7.37193 16.7619 6.84342 18.0622 7.49322C19.3652 8.14537 19.8933 9.72781 19.241 11.0311L17.3245 14.8649C17.3759 15.3737 17.3292 15.8876 17.1872 16.3789H18.4525L15.1154 25.5553C14.9173 26.0995 15.3001 26.7346 15.9416 26.7346C16.3003 26.7346 16.6377 26.5133 16.7672 26.1558L20.3227 16.3789H20.9386L24.5302 26.1588C24.6608 26.5145 24.9973 26.7346 25.3557 26.7346C25.9552 26.7346 26.3946 26.1359 26.1802 25.5529L22.8111 16.3789H27.2386C28.7636 16.3789 30 15.1425 30 13.6174V3.26152C30 1.73645 28.7636 0.5 27.2385 0.5ZM25.3553 12.6136H21.4119C20.9266 12.6136 20.533 12.2199 20.533 11.7346C20.533 11.249 20.9266 10.8557 21.4119 10.8557H25.3553C25.841 10.8557 26.2342 11.249 26.2342 11.7346C26.2342 12.2199 25.8411 12.6136 25.3553 12.6136ZM25.3553 9.31836H21.4119C20.9266 9.31836 20.533 8.9252 20.533 8.43945C20.533 7.95424 20.9266 7.56055 21.4119 7.56055H25.3553C25.841 7.56055 26.2342 7.95424 26.2342 8.43945C26.2342 8.9252 25.8411 9.31836 25.3553 9.31836ZM25.3553 6.02363H15.9416C15.4559 6.02363 15.0627 5.62988 15.0627 5.14473C15.0627 4.65957 15.4559 4.26582 15.9416 4.26582H25.3553C25.841 4.26582 26.2342 4.65945 26.2342 5.14473C26.2342 5.63 25.841 6.02363 25.3553 6.02363Z" fill="#570861" />
                              <path d="M15.4642 16.0019C15.623 15.5366 15.6219 15.0505 15.4877 14.6069L17.669 10.2448C17.886 9.81066 17.7102 9.28268 17.276 9.06564C16.8421 8.84844 16.314 9.02445 16.0968 9.45869L14.2365 13.1789C13.1245 12.6252 11.4352 11.7853 10.0743 11.1085C8.80805 10.4788 7.90441 9.91414 6.3627 9.91414H5.58609C6.78656 9.91414 7.85795 9.3616 8.55926 8.49687C9.0944 7.83887 9.41438 7.00021 9.41438 6.08633C9.41438 3.97168 7.70051 2.25781 5.58563 2.25781C3.47168 2.25781 1.75781 3.97168 1.75781 6.08633C1.75781 7.10187 2.15332 8.02473 2.79855 8.70957C3.49617 9.45137 4.48699 9.91414 5.58539 9.91437C4.11275 9.91437 2.67311 10.511 1.6374 11.5495C0.581543 12.6026 0 14.0056 0 15.5002V18.3245C0 19.5403 0.789434 20.5747 1.88279 20.9428V25.8555C1.88279 26.341 2.27625 26.7344 2.7617 26.7344H8.41002C8.89547 26.7344 9.28893 26.341 9.28893 25.8555V15.9805C10.1223 16.3971 11.2171 16.9443 12.1028 17.3874C12.7238 17.6978 13.4493 17.7257 14.0964 17.4625C14.7416 17.197 15.2389 16.6685 15.4607 16.0123L15.4642 16.0019Z" fill="#570861" />
                            </svg>
                          </span>
                          Class Options
                        </a>
                      </div>
                      <div className="d-md-flex">
                        <Link to="/organization-add-class" className="fw-500 text-capitalize text-purple text-decoration-underline mb-md-0 mb-3 d-inline-md-block d-block">Edit Class Details</Link>
                        <Link to="/organization-add-class" className="fw-500 text-capitalize text-purple text-decoration-underline ms-md-4">Edit My Class Schedule</Link>
                      </div>
                    </div>	 
                  </div> 
                </div> 
              </div>
            </div>
          </section>
          {/*end*/}
        </div>  
        <div className="class-content pt-lg-5">
          {/*start-video-section*/}
          <div className="video_boxs_sec position-relative">
            <div className>
              <div className="video_boxs">
                <video id="video" className="w-100" loop height={557} controls="controls" poster="./assets/images/video_bg_dash.png">
                                        <source src={`${Video_URL}/Classroom -teachers (2).mp4`} type="video/mp4" />

                </video>
              </div> 
            </div>
            <div className="cancel_btn position-absolute">
              <a href="#" className="colse_div_video">
                <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" height={25} viewBox="0 -960 960 960" width={25}>
                  <path d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
                </svg>
              </a>
            </div>
          </div>
          {/*end-video-section*/}
          {/*start-tabing*/}
          <ul className="nav custom_nav_bar custom_yellow_line nav-pills mb-4" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link active" id="pills-classes-tab" data-bs-toggle="pill" data-bs-target="#pills-classes" type="button" role="tab" aria-controls="pills-classes" aria-selected="true"> 
                Classroom
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="pills-Homework-tab" data-bs-toggle="pill" data-bs-target="#pills-Homework" type="button" role="tab" aria-controls="pills-Homework" aria-selected="false"> 
                Homework
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="pills-Assignments-tab" data-bs-toggle="pill" data-bs-target="#pills-Assignments" type="button" role="tab" aria-controls="pills-Assignments" aria-selected="false"> 
                Assesments
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="pills-quizzes-tab" data-bs-toggle="pill" data-bs-target="#pills-quizzes" type="button" role="tab" aria-controls="pills-quizzes" aria-selected="false"> 
                Quizzes
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="pills-Discussion-tab" data-bs-toggle="pill" data-bs-target="#pills-Discussion" type="button" role="tab" aria-controls="pills-Discussion" aria-selected="false"> 
                Discussion Board</button>
            </li> 
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="pills-Attendence-tab" data-bs-toggle="pill" data-bs-target="#pills-Attendence" type="button" role="tab" aria-controls="pills-Attendence" aria-selected="false"> 
                Attendance</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="pills-Messages-tab" data-bs-toggle="pill" data-bs-target="#pills-Messages" type="button" role="tab" aria-controls="pills-Messages" aria-selected="false"> 
                Messages</button>
            </li> 
            {/* <li class="nav-item" role="presentation">
				<button class="nav-link" id="pills-review-tab" data-bs-toggle="pill" data-bs-target="#pills-review" type="button" role="tab" aria-controls="pills-review" aria-selected="false"> 
				Review</button>
			</li> */}
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="pills-Progress-tab" data-bs-toggle="pill" data-bs-target="#pills-Progress" type="button" role="tab" aria-controls="pills-Progress" aria-selected="false"> 
                Progress Report</button>
            </li>
            {/* <li class="nav-item" role="presentation">
				<button class="nav-link" id="pills-feedback-tab" data-bs-toggle="pill" data-bs-target="#pills-feedback" type="button" role="tab" aria-controls="pills-feedback" aria-selected="false"> 
				Feedback</button>
			</li> */}
          </ul>
          {/*end-tabing*/}
          {/*start*/}
          <div className="row">
            <div className="col-xl-12 mb-xl-0 mb-4">  
              {/*start-content*/}
              <div className="tab-content" id="pills-tabContent">
                {/*----------------------------------Tab1-------------------------------------------------*/}
                <div className="tab-pane fade active show" id="pills-classes" role="tabpanel" aria-labelledby="pills-classes-tab"> 
                  <div className="row">  
                    <div className="col-xl-9"> 
                      <div className="card mb-4 mt-4 bg-gray review_box text-center">
                        <h3 className="mb-4 poppins fw-600">Next Meeting Starts Apr 24th At 6 PM EST</h3>
                        <div className="button">
                          <a href="#!" className="btn-theme fw-600 text-dark bg-yellow mb-xl-0 mb-3 d-inline-block" data-bs-toggle="modal" data-bs-target="#ask_the_teacher">Start Live Meeting</a>
                        </div>
                      </div> 
                    </div>
                    <div className="col-xl-12"> 
                      <ul className="d-flex flex-wrap">
                        <li><Link to="/add_Lessons" className="btn-theme border-dark d-inline-block mb-xl-4 mb-lg-3 mb-2 fw-500 text-dif-black ">+ Add Lesson</Link></li>
                        <li><Link to="/add_Homework" className="btn-theme border-dark d-inline-block mb-xl-4 mb-lg-3 mb-2 fw-500 text-dif-black  ms-3">+ Add Homework</Link></li>
                        <li><Link to="/add_assignment" className="btn-theme border-dark d-inline-block mb-xl-4 mb-lg-3 mb-2 fw-500 text-dif-black  ms-3">+ Add Assesments</Link></li>
                        <li><Link to="/add_quiz" className="btn-theme border-dark d-inline-block mb-xl-4 mb-lg-3 mb-2 fw-500 text-dif-black  ms-3">+ Add Quiz</Link></li>
                        <li><Link to="/add_discussions" className="btn-theme border-dark d-inline-block mb-xl-4 mb-lg-3 mb-2 fw-500 text-dif-black  ms-3">+ Add Discussion</Link></li>
                      </ul> 
                    </div>
                  </div>	
                  <div className="row">  
                    <div className="col-xl-9 mb-xl-0 mb-4"> 
                      <div className="enviorment_div mb-5 align-items-center d-md-flex">
                        <div className="env_img">
                          <img src="./assets/images/enviorment.png" alt="enviorment" />
                        </div>
                        <div className="env_text">
                          <p className="text-500">Help save the envionrment. Cut down on printing. Instead please use Google docs, sheets and slides which is freely available to you.</p>
                        </div>
                      </div>
                      <div className="review_dack">
                        <div className="card review_box mb-4">
                          <div className="review_top">
                            <div className="list_btw_dots mb-4">
                              <ul className="d-sm-flex align-items-center">
                                <span className="pe-4 fw-600 position-relative">Lesson <span className="green_dots position-absolute" /></span>
                                <li>
                                  <span className="text-purple fw-500">Duration Of Lesson : 3 Hours</span>
                                </li>
                              </ul>
                            </div>
                            <div className="d-lg-flex justify-content-between mb-3 align-items-satrt">
                              <div className="d-flex align-items-center"> 
                                <div className="fw-600">
                                  <span className="d-block">Collecting Moodboard from Art.</span> 
                                </div>
                              </div>
                              <div className="d-lg-flex d-block font-14 mb-lg-0 mb-2 fw-600 align-items-center">
                                <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                              </div>
                            </div>
                            <div className="review_text row mb-4">
                              <div className="col-lg-8">
                                <p className="fs-6">Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of problems.</p>
                              </div>
                            </div>
                            <div className="d-flex flex-sm-row  flex-column-reverse justify-content-between align-items-sm-center">
                              <div className="button d-flex align-items-center justify-content-between">
                                <a href="#" className="btn-theme px-3 d-inline-block text-center">View Details</a>
                                <ul className="edit_delete d-flex ms-4 align-items-center justify-content-end">
                                  <li>
                                    <a href="#!" className>
                                      <svg width={20} height={20} viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.80679 25.6355C1.80679 27.406 3.24075 28.84 5.01126 28.84H17.8291C19.5995 28.84 21.0335 27.406 21.0335 25.6355V6.40886H1.80679V25.6355ZM17.0279 1.6022L15.4257 0H7.41459L5.81232 1.6022H0.20459V4.80667H22.6357V1.6022H17.0279Z" fill="#FF2929" />
                                      </svg>
                                    </a>
                                  </li>
                                  <li>
                                    <Link to="/organization-add-class" className>
                                      <svg width={20} height={23} viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.10738 18.8439C3.32277 18.8439 3.36584 18.8224 3.55969 18.7793L7.43661 18.0039C7.84584 17.8962 8.25507 17.7024 8.57815 17.3793L17.9689 7.98851C19.412 6.54543 19.412 4.06851 17.9689 2.62543L17.172 1.78543C15.7289 0.342356 13.2305 0.342356 11.7874 1.78543L2.39661 11.1977C2.09508 11.4993 1.87969 11.93 1.772 12.3393L0.953537 16.2593C0.845845 16.9916 1.06123 17.7024 1.57815 18.2193C1.98738 18.6285 2.59046 18.8439 3.10738 18.8439ZM3.83969 12.7485L13.2305 3.3362C13.8551 2.71159 14.9966 2.71159 15.5997 3.3362L16.4181 4.15466C17.1505 4.88697 17.1505 5.92082 16.4181 6.63159L7.04892 16.0439L3.06431 16.7116L3.83969 12.7485Z" fill="#570861" />
                                        <path d="M17.8827 20.1797H1.98734C1.36272 20.1797 0.953491 20.5889 0.953491 21.2135C0.953491 21.8382 1.47041 22.2474 1.98734 22.2474H17.7966C18.4212 22.2474 18.9381 21.8382 18.9381 21.2135C18.9166 20.5889 18.3997 20.1797 17.8827 20.1797Z" fill="#570861" />
                                      </svg>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="list_btw_dots mb-sm-0 mb-4">
                                <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                  {/* <li>
															<span class="text-danger fw-500">1 Day Left</span>
														</li> */}
                                  <li>
                                    <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span> 25 March 2022   5:00 PM</span>
                                  </li>
                                  {/* <li>
															<span class="text-purple fw-500"><span class="text-dif-black">Deadline:</span> 25 March 2022   5:00 PM</span>
														</li> */}
                                </ul>
                              </div>
                            </div>
                          </div> 
                        </div>  
                        <div className="card review_box mb-4">
                          <div className="review_top">
                            <div className="list_btw_dots mb-4">
                              <ul className="d-sm-flex align-items-center">
                                <span className="fw-600 position-relative">Lesson</span>
                                <li>
                                  <span className="text-purple fw-500">Duration Of Lesson : 3 Hours</span>
                                </li>
                              </ul>
                            </div>
                            <div className="d-lg-flex justify-content-between mb-3 align-items-satrt">
                              <div className="d-flex align-items-center"> 
                                <div className="fw-600">
                                  <span className="d-block">Collecting Moodboard from Art.</span> 
                                </div>
                              </div>
                              <div className="d-lg-flex d-block font-14 mb-lg-0 mb-2 fw-600 align-items-center">
                                <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                              </div>
                            </div>
                            <div className="review_text row mb-4">
                              <div className="col-lg-8">
                                <p className="fs-6">Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of problems.</p>
                              </div>
                            </div>
                            <div className="d-flex flex-sm-row  flex-column-reverse justify-content-between align-items-sm-center">
                              <div className="button">
                                <a href="#" className="btn-theme px-3 d-inline-block text-center">View Details</a>
                              </div>
                              <div className="list_btw_dots mb-sm-0 mb-4">
                                <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                  {/* <li>
															<span class="text-danger fw-500">1 Day Left</span>
														</li> */}
                                  <li>
                                    <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span> 25 March 2022   5:00 PM</span>
                                  </li>
                                  {/* <li>
															<span class="text-purple fw-500"><span class="text-dif-black">Deadline:</span> 25 March 2022   5:00 PM</span>
														</li> */}
                                </ul>
                              </div>
                            </div>
                          </div> 
                        </div>  
                      </div>
                      {/*start-pagination*/}
                      <nav aria-label="Page navigation example" className="mt-5 custom-pagination">
                        <ul className="pagination justify-content-evenly align-items-center border-radius-10">
                          <li className="page-item">
                            <a className="page-link text-dark" href="#" aria-label="Previous">
                              <span className>
                                <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="M453-241 213-481l240-240 42 42-198 198 198 198-42 42Zm253 0L466-481l240-240 42 42-198 198 198 198-42 42Z" /></svg>
                              </span>
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link text-dark border-start-0" href="#" aria-label="Previous">
                              <span className>
                                <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="M561-240 320-481l241-241 43 43-198 198 198 198-43 43Z" /></svg>
                              </span>
                            </a>
                          </li>
                          <li className="page-item"><a className="page-link text-dark border-start-0 border-end-0" href="#">May 2023</a></li>
                          <li className="page-item active"><a className="page-link text-dark border-start-0 border-end-0" href="#">Apr 2023</a></li>
                          <li className="page-item"><a className="page-link text-dark border-start-0 border-end-0" href="#">Mar 2023</a></li>
                          <li className="page-item"><a className="page-link text-dark border-start-0 border-end-0" href="#">Feb 2023</a></li> 
                          <li className="page-item">
                            <a className="page-link border-start-0 " href="#" aria-label="Next">
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z" /></svg>
                              </span>
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link text-dark border-start-0 " href="#" aria-label="Next">
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="m255-241-42-42 198-198-198-198 42-42 240 240-240 240Zm253 0-42-42 198-198-198-198 42-42 240 240-240 240Z" /></svg>
                              </span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                      {/*end*/}
                    </div>  
                    <div className="col-xl-3">
                      <div className> 
                        <div className="accordion border-radius-10 " id="accordionExample">
                          <div className="accordion-item">
                            <h2 className="accordion-header poppins" id="headingOne">
                              <button className="accordion-button fs-5 text-dif-black bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">New Notifications</button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <div className="list_notification">
                                  <ul className="position-static">
                                    <li className="d-flex align-items-center mb-4">
                                      <div className="notif_left d-flex align-items-center">
                                        <div className="noti_img bg-pink position-relative">
                                          <span className="text-uppercase text-danger">A</span>
                                          <span className="notifi_logs">
                                            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                            </svg>
                                          </span>
                                        </div>
                                        <p className="ms-3 noti_text">Assignments Due</p>
                                      </div>
                                      <span className="noti_timer">10.12 PM</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-4">
                                      <div className="notif_left d-flex align-items-center">
                                        <div className="noti_img bg-diff-yellow-op-1 position-relative">
                                          <span className="text-uppercase text-yellow">q</span>
                                          <span className="notifi_logs">
                                            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                            </svg>
                                          </span>
                                        </div>
                                        <p className="ms-3 noti_text">New assignments.</p>
                                      </div>
                                      <span className="noti_timer">10.12 PM</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-4">
                                      <div className="notif_left d-flex align-items-center">
                                        <div className="noti_img bg-sky-blue-op-1 position-relative">
                                          <span className="text-uppercase text-blue">e</span>
                                          <span className="notifi_logs">
                                            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                            </svg>
                                          </span>
                                        </div>
                                        <p className="ms-3 noti_text">Any upcoming events</p>
                                      </div>
                                      <span className="noti_timer">10.12 PM</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-4">
                                      <div className="notif_left d-flex align-items-center">
                                        <div className="noti_img bg-sky-blue-op-1 position-relative">
                                          <span className="text-uppercase text-blue">e</span>
                                          <span className="notifi_logs">
                                            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                            </svg>
                                          </span>
                                        </div>
                                        <p className="ms-3 noti_text">Any upcoming events</p>
                                      </div>
                                      <span className="noti_timer">10.12 PM</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*----------------------------------Tab2-------------------------------------------------*/}
                <div className="tab-pane fade" id="pills-Homework" role="tabpanel" aria-labelledby="pills-Homework-tab"> 
                  {/*start*/}
                  <div className="row">
                    <div className="col-xl-9 mb-xl-0 mb-4">  
                      <div className="d-flex mb-4 justify-content-between align-items-center">
                        <a href="add_homework_info.php" className="btn-theme fw-500 text-dif-black border-dark">+ Add Homework</a>
                        <select className="bg-purple text-white border-purple fs-6 rounded-cricle rounded-pill ms-sm-3 px-2 py-2">
                          <option selected>Learner’s Name</option>
                          <option>Relevance</option>
                          <option>New Classes</option>
                          <option>Starting soon</option>
                        </select>
                      </div> 
                      <div className="review_dack">
                        <div className="card review_box mb-4">
                          <div className="review_top"> 
                            <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                              <div className="list_btw_dots mb-3">
                                <ul className="d-xl-flex align-items-center">
                                  <span className="pe-sm-4 fw-600 position-relative">Collecting Moodboard from Art. <span className="green_dots position-absolute" /></span> 
                                  <li>
                                    <span className="text-purple fw-500">Total Duration: 1 Hours</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                                <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                              </div>
                            </div>
                            <div className="review_text row mb-4">
                              <div className="col-lg-8">
                                <p className="fs-6">Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of problems.</p>
                              </div>
                            </div>
                            <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                              <div className="button d-sm-flex align-items-center">
                                <a href="#" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">View Details</a>
                                <a href="#" className="btn-theme text-center bg-yellow d-inline-block ms-sm-3">Upload Homework</a>
                              </div>
                              <div className="list_btw_dots mb-xl-0 mb-4">
                                <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                  <li>
                                    <span className="text-danger fw-500">1 Day Left</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span> 25 March 2022   5:00 PM</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500"><span className="text-dif-black">Deadline:</span> 25 March 2022   5:00 PM</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div> 
                        </div>
                        <div className="card review_box mb-4">
                          <div className="review_top"> 
                            <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                              <div className="list_btw_dots mb-3">
                                <ul className="d-xl-flex align-items-center">
                                  <span className="fw-600 position-relative">Collecting Moodboard from Art.</span> 
                                  <li>
                                    <span className="text-purple fw-500">Total Duration: 1 Hours</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                                <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                              </div>
                            </div>
                            <div className="review_text row mb-4">
                              <div className="col-lg-8">
                                <p className="fs-6">Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of problems.</p>
                              </div>
                            </div>
                            <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                              <div className="button d-sm-flex align-items-center">
                                <a href="#" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">View Details</a>
                                <a href="#" className="btn-theme text-center bg-yellow d-inline-block ms-sm-3">Upload Homework</a>
                              </div>
                              <div className="list_btw_dots mb-xl-0 mb-4">
                                <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                  <li>
                                    <span className="text-danger fw-500">1 Day Left</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span> 25 March 2022   5:00 PM</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500"><span className="text-dif-black">Deadline:</span> 25 March 2022   5:00 PM</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div> 
                        </div> 
                      </div>
                      {/*start-pagination*/}
                      <nav aria-label="Page navigation example" className="mt-5 custom-pagination">
                        <ul className="pagination justify-content-evenly align-items-center border-radius-10">
                          <li className="page-item">
                            <a className="page-link text-dark" href="#" aria-label="Previous">
                              <span className>
                                <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="M453-241 213-481l240-240 42 42-198 198 198 198-42 42Zm253 0L466-481l240-240 42 42-198 198 198 198-42 42Z" /></svg>
                              </span>
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link text-dark border-start-0" href="#" aria-label="Previous">
                              <span className>
                                <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="M561-240 320-481l241-241 43 43-198 198 198 198-43 43Z" /></svg>
                              </span>
                            </a>
                          </li>
                          <li className="page-item"><a className="page-link text-dark border-start-0 border-end-0" href="#">May 2023</a></li>
                          <li className="page-item active"><a className="page-link text-dark border-start-0 border-end-0" href="#">Apr 2023</a></li>
                          <li className="page-item"><a className="page-link text-dark border-start-0 border-end-0" href="#">Mar 2023</a></li>
                          <li className="page-item"><a className="page-link text-dark border-start-0 border-end-0" href="#">Feb 2023</a></li> 
                          <li className="page-item">
                            <a className="page-link border-start-0 " href="#" aria-label="Next">
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z" /></svg>
                              </span>
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link text-dark border-start-0 " href="#" aria-label="Next">
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="m255-241-42-42 198-198-198-198 42-42 240 240-240 240Zm253 0-42-42 198-198-198-198 42-42 240 240-240 240Z" /></svg>
                              </span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div className="col-xl-3">
                      <div className>
                        <div className="accordion border-radius-10 " id="accordionExample">
                          <div className="accordion-item">
                            <h2 className="accordion-header poppins" id="headingOne">
                              <button className="accordion-button fs-5 text-dif-black bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">New Notifications</button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <div className="list_notification">
                                  <ul className>
                                    <li className="d-flex align-items-center mb-4">
                                      <div className="notif_left d-flex align-items-center">
                                        <div className="noti_img bg-pink position-relative">
                                          <span className="text-uppercase text-danger">A</span>
                                          <span className="notifi_logs">
                                            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                            </svg>
                                          </span>
                                        </div>
                                        <p className="ms-3 noti_text">Assignments Due</p>
                                      </div>
                                      <span className="noti_timer">10.12 PM</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-4">
                                      <div className="notif_left d-flex align-items-center">
                                        <div className="noti_img bg-diff-yellow-op-1 position-relative">
                                          <span className="text-uppercase text-yellow">q</span>
                                          <span className="notifi_logs">
                                            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                            </svg>
                                          </span>
                                        </div>
                                        <p className="ms-3 noti_text">New assignments.</p>
                                      </div>
                                      <span className="noti_timer">10.12 PM</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-4">
                                      <div className="notif_left d-flex align-items-center">
                                        <div className="noti_img bg-sky-blue-op-1 position-relative">
                                          <span className="text-uppercase text-blue">e</span>
                                          <span className="notifi_logs">
                                            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                            </svg>
                                          </span>
                                        </div>
                                        <p className="ms-3 noti_text">Any upcoming events</p>
                                      </div>
                                      <span className="noti_timer">10.12 PM</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-4">
                                      <div className="notif_left d-flex align-items-center">
                                        <div className="noti_img bg-sky-blue-op-1 position-relative">
                                          <span className="text-uppercase text-blue">e</span>
                                          <span className="notifi_logs">
                                            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                            </svg>
                                          </span>
                                        </div>
                                        <p className="ms-3 noti_text">Any upcoming events</p>
                                      </div>
                                      <span className="noti_timer">10.12 PM</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*end*/} 
                </div>
                {/*----------------------------------Tab2-------------------------------------------------*/}
                <div className="tab-pane fade" id="pills-Assignments" role="tabpanel" aria-labelledby="pills-Assignments-tab"> 
                  <div className="row">  
                    <div className="col-xl-9 mb-xl-0 mb-4">   
                      <div className="d-flex mb-4 justify-content-between align-items-center">
                        <a href="add_assessment_info.php" className="btn-theme fw-500 text-dif-black border-dark">+ Add Assesments</a>
                        <select className="bg-purple text-white border-purple fs-6 rounded-cricle rounded-pill ms-sm-3 px-2 py-2">
                          <option selected>Learner’s Name</option>
                          <option>Relevance</option>
                          <option>New Classes</option>
                          <option>Starting soon</option>
                        </select>
                      </div>
                      <div className="review_dack">
                        <div className="card review_box mb-4">
                          <div className="review_top"> 
                            <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                              <div className="list_btw_dots mb-3">
                                <ul className="d-xl-flex align-items-center">
                                  <span className="pe-sm-3 position-relative fw-600">Collecting Moodboard from Art. <span className="green_dots position-absolute" /></span>
                                  <li>
                                    <span className="text-purple fw-600">Score : 25/30</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500">Total Duration: 1 Hours</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                                <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                              </div>
                            </div>
                            <div className="review_text row mb-4">
                              <div className="col-lg-8">
                                <p className="fs-6">Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of problems.</p>
                              </div>
                            </div>
                            <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                              <div className="button d-sm-flex align-items-center">
                                <a href="#" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">View Details</a> 
                              </div>
                              <div className="list_btw_dots mb-xl-0 mb-4">
                                <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                  <li>
                                    <span className="text-danger fw-500">1 Day Left</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span> 25 March 2022   5:00 PM</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500"><span className="text-dif-black">Deadline:</span> 25 March 2022   5:00 PM</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div> 
                        </div>
                        <div className="card review_box mb-4">
                          <div className="review_top"> 
                            <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                              <div className="list_btw_dots mb-3">
                                <ul className="d-xl-flex align-items-center"> 
                                  <span className="position-relative fw-600">Collecting Moodboard from Art.</span> 
                                  <li>
                                    <span className="text-purple fw-600">Score : 25/30</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500">Total Duration: 1 Hours</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                                <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                              </div>
                            </div>
                            <div className="review_text row mb-4">
                              <div className="col-lg-8">
                                <p className="fs-6">Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of problems.</p>
                              </div>
                            </div>
                            <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                              <div className="button d-sm-flex align-items-center">
                                <a href="#" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">View Details</a> 
                                <ul className="ms-3 edit_delete d-flex align-items-center justify-content-end">
                                  <li>
                                    <a href="#!" className>
                                      <svg width={20} height={20} viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.80679 25.6355C1.80679 27.406 3.24075 28.84 5.01126 28.84H17.8291C19.5995 28.84 21.0335 27.406 21.0335 25.6355V6.40886H1.80679V25.6355ZM17.0279 1.6022L15.4257 0H7.41459L5.81232 1.6022H0.20459V4.80667H22.6357V1.6022H17.0279Z" fill="#FF2929" />
                                      </svg>
                                    </a>
                                  </li>
                                  <li>
                                    <Link to="/organization-add-class" className>
                                      <svg width={20} height={23} viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.10738 18.8439C3.32277 18.8439 3.36584 18.8224 3.55969 18.7793L7.43661 18.0039C7.84584 17.8962 8.25507 17.7024 8.57815 17.3793L17.9689 7.98851C19.412 6.54543 19.412 4.06851 17.9689 2.62543L17.172 1.78543C15.7289 0.342356 13.2305 0.342356 11.7874 1.78543L2.39661 11.1977C2.09508 11.4993 1.87969 11.93 1.772 12.3393L0.953537 16.2593C0.845845 16.9916 1.06123 17.7024 1.57815 18.2193C1.98738 18.6285 2.59046 18.8439 3.10738 18.8439V18.8439ZM3.83969 12.7485L13.2305 3.3362C13.8551 2.71159 14.9966 2.71159 15.5997 3.3362L16.4181 4.15466C17.1505 4.88697 17.1505 5.92082 16.4181 6.63159L7.04892 16.0439L3.06431 16.7116L3.83969 12.7485Z" fill="#570861" />
                                        <path d="M17.8827 20.1797H1.98734C1.36272 20.1797 0.953491 20.5889 0.953491 21.2135C0.953491 21.8382 1.47041 22.2474 1.98734 22.2474H17.7966C18.4212 22.2474 18.9381 21.8382 18.9381 21.2135C18.9166 20.5889 18.3997 20.1797 17.8827 20.1797Z" fill="#570861" />
                                      </svg>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="list_btw_dots mb-xl-0 mb-4">
                                <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                  <li>
                                    <span className="text-danger fw-500">1 Day Left</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span> 25 March 2022   5:00 PM</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500"><span className="text-dif-black">Deadline:</span> 25 March 2022   5:00 PM</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div> 
                        </div>
                        <div className="card review_box mb-4">
                          <div className="review_top"> 
                            <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                              <div className="list_btw_dots mb-3">
                                <ul className="d-xl-flex align-items-center"> 
                                  <span className="pe-sm-3 position-relative fw-600">Collecting Moodboard from Art. <span className="green_dots position-absolute" /></span> 
                                  <li>
                                    <span className="text-purple fw-600">Score : 25/30</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500">Total Duration: 1 Hours</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                                <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                              </div>
                            </div>
                            <div className="review_text row mb-4">
                              <div className="col-lg-8">
                                <p className="fs-6">Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of problems.</p>
                              </div>
                            </div>
                            <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                              <div className="button d-sm-flex align-items-center">
                                <a href="#" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">View Details</a> 
                              </div>
                              <div className="list_btw_dots mb-xl-0 mb-4">
                                <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                  <li>
                                    <span className="text-danger fw-500">1 Day Left</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span> 25 March 2022   5:00 PM</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500"><span className="text-dif-black">Deadline:</span> 25 March 2022   5:00 PM</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div> 
                        </div>   
                      </div>
                      {/*start-pagination*/}
                      <nav aria-label="Page navigation example" className="mt-5 custom-pagination">
                        <ul className="pagination justify-content-evenly align-items-center border-radius-10">
                          <li className="page-item">
                            <a className="page-link text-dark" href="#" aria-label="Previous">
                              <span className>
                                <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="M453-241 213-481l240-240 42 42-198 198 198 198-42 42Zm253 0L466-481l240-240 42 42-198 198 198 198-42 42Z" /></svg>
                              </span>
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link text-dark border-start-0" href="#" aria-label="Previous">
                              <span className>
                                <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="M561-240 320-481l241-241 43 43-198 198 198 198-43 43Z" /></svg>
                              </span>
                            </a>
                          </li>
                          <li className="page-item"><a className="page-link text-dark border-start-0 border-end-0" href="#">May 2023</a></li>
                          <li className="page-item active"><a className="page-link text-dark border-start-0 border-end-0" href="#">Apr 2023</a></li>
                          <li className="page-item"><a className="page-link text-dark border-start-0 border-end-0" href="#">Mar 2023</a></li>
                          <li className="page-item"><a className="page-link text-dark border-start-0 border-end-0" href="#">Feb 2023</a></li> 
                          <li className="page-item">
                            <a className="page-link border-start-0 " href="#" aria-label="Next">
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z" /></svg>
                              </span>
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link text-dark border-start-0 " href="#" aria-label="Next">
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="m255-241-42-42 198-198-198-198 42-42 240 240-240 240Zm253 0-42-42 198-198-198-198 42-42 240 240-240 240Z" /></svg>
                              </span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                      {/*end*/}
                    </div>
                    <div className="col-xl-3">
                      <div className> 
                        <div className="accordion border-radius-10 " id="accordionExample">
                          <div className="accordion-item">
                            <h2 className="accordion-header poppins" id="headingOne">
                              <button className="accordion-button fs-5 text-dif-black bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">New Notifications</button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <div className="list_notification">
                                  <ul className="position-static">
                                    <li className="d-flex align-items-center mb-4">
                                      <div className="notif_left d-flex align-items-center">
                                        <div className="noti_img bg-pink position-relative">
                                          <span className="text-uppercase text-danger">A</span>
                                          <span className="notifi_logs">
                                            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                            </svg>
                                          </span>
                                        </div>
                                        <p className="ms-3 noti_text">Assignments Due</p>
                                      </div>
                                      <span className="noti_timer">10.12 PM</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-4">
                                      <div className="notif_left d-flex align-items-center">
                                        <div className="noti_img bg-diff-yellow-op-1 position-relative">
                                          <span className="text-uppercase text-yellow">q</span>
                                          <span className="notifi_logs">
                                            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                            </svg>
                                          </span>
                                        </div>
                                        <p className="ms-3 noti_text">New assignments.</p>
                                      </div>
                                      <span className="noti_timer">10.12 PM</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-4">
                                      <div className="notif_left d-flex align-items-center">
                                        <div className="noti_img bg-sky-blue-op-1 position-relative">
                                          <span className="text-uppercase text-blue">e</span>
                                          <span className="notifi_logs">
                                            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                            </svg>
                                          </span>
                                        </div>
                                        <p className="ms-3 noti_text">Any upcoming events</p>
                                      </div>
                                      <span className="noti_timer">10.12 PM</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-4">
                                      <div className="notif_left d-flex align-items-center">
                                        <div className="noti_img bg-sky-blue-op-1 position-relative">
                                          <span className="text-uppercase text-blue">e</span>
                                          <span className="notifi_logs">
                                            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                            </svg>
                                          </span>
                                        </div>
                                        <p className="ms-3 noti_text">Any upcoming events</p>
                                      </div>
                                      <span className="noti_timer">10.12 PM</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div> 
                        </div>
                      </div>
                    </div>
                  </div> 
                </div>
                {/*----------------------------------Tab3-------------------------------------------------*/}
                <div className="tab-pane fade" id="pills-quizzes" role="tabpanel" aria-labelledby="pills-quizzes-tab"> 
                  <div className="row">   
                    <div className="col-xl-9 mb-xl-0 mb-4">
                      <div className="d-flex mb-4 justify-content-between align-items-center">
                        <Link to="/add_quiz" className="btn-theme fw-500 text-dif-black border-dark">+ Add Quiz</Link>
                        <select className="bg-purple text-white border-purple fs-6 rounded-cricle rounded-pill ms-sm-3 px-2 py-2">
                          <option selected>Learner’s Name</option>
                          <option>Relevance</option>
                          <option>New Classes</option>
                          <option>Starting soon</option>
                        </select>
                      </div>
                      <div className="review_dack mt-4">
                        <div className="card review_box mb-4">
                          <div className="review_top"> 
                            <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                              <div className="list_btw_dots mb-3">
                                <ul className="d-xl-flex align-items-center">
                                  <span className="pe-sm-4 fw-600 position-relative">1. Collecting Moodboard from Art. <span className="green_dots position-absolute" /></span>
                                  <li>
                                    <span className="text-purple fw-500">Score : 25/30 </span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500">Total Duration: 1 Hours</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                                <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                              </div>
                            </div>
                            <div className="review_text row mb-4">
                              <div className="col-lg-8">
                                <p className="fs-6">Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of problems.</p>
                              </div>
                            </div>
                            <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                              <div className="button d-sm-flex align-items-center">
                                <a href="#" className="btn-theme px-3 mb-sm-0 mb-3  d-inline-block">View Details</a>
                                <a href="#" className="btn-theme px-3 ms-sm-3 bg-yellow d-inline-block text-center">Show Certificate</a>
                              </div>
                              <div className="list_btw_dots mb-xl-0 mb-4">
                                <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                  <li>
                                    <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span> 25 March 2022   5:00 PM</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500"><span className="text-dif-black">Deadline:</span> 25 March 2022   5:00 PM</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div> 
                        </div>
                        <div className="card review_box mb-4">
                          <div className="review_top"> 
                            <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                              <div className="list_btw_dots mb-3">
                                <ul className="d-xl-flex align-items-center">
                                  <span className="fw-600 position-relative">2. Collecting Moodboard from Art.</span>
                                  <li>
                                    <span className="text-purple fw-500">Score : 25/30 </span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500">Total Duration: 1 Hours</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                                <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                              </div>
                            </div>
                            <div className="review_text row mb-4">
                              <div className="col-lg-8">
                                <p className="fs-6">Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of problems.</p>
                              </div>
                            </div>
                            <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                              <div className="button d-sm-flex align-items-center">
                                <a href="#!" className="btn-theme px-3 d-inline-block text-center">View Details</a> 
                                <ul className="ms-3 edit_delete d-flex align-items-center justify-content-end">
                                  <li>
                                    <a href="#!" className>
                                      <svg width={20} height={20} viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.80679 25.6355C1.80679 27.406 3.24075 28.84 5.01126 28.84H17.8291C19.5995 28.84 21.0335 27.406 21.0335 25.6355V6.40886H1.80679V25.6355ZM17.0279 1.6022L15.4257 0H7.41459L5.81232 1.6022H0.20459V4.80667H22.6357V1.6022H17.0279Z" fill="#FF2929" />
                                      </svg>
                                    </a>
                                  </li>
                                  <li>
                                    <Link to="/organization-add-class" className>
                                      <svg width={20} height={23} viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.10738 18.8439C3.32277 18.8439 3.36584 18.8224 3.55969 18.7793L7.43661 18.0039C7.84584 17.8962 8.25507 17.7024 8.57815 17.3793L17.9689 7.98851C19.412 6.54543 19.412 4.06851 17.9689 2.62543L17.172 1.78543C15.7289 0.342356 13.2305 0.342356 11.7874 1.78543L2.39661 11.1977C2.09508 11.4993 1.87969 11.93 1.772 12.3393L0.953537 16.2593C0.845845 16.9916 1.06123 17.7024 1.57815 18.2193C1.98738 18.6285 2.59046 18.8439 3.10738 18.8439V18.8439ZM3.83969 12.7485L13.2305 3.3362C13.8551 2.71159 14.9966 2.71159 15.5997 3.3362L16.4181 4.15466C17.1505 4.88697 17.1505 5.92082 16.4181 6.63159L7.04892 16.0439L3.06431 16.7116L3.83969 12.7485Z" fill="#570861" />
                                        <path d="M17.8827 20.1797H1.98734C1.36272 20.1797 0.953491 20.5889 0.953491 21.2135C0.953491 21.8382 1.47041 22.2474 1.98734 22.2474H17.7966C18.4212 22.2474 18.9381 21.8382 18.9381 21.2135C18.9166 20.5889 18.3997 20.1797 17.8827 20.1797Z" fill="#570861" />
                                      </svg>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="list_btw_dots mb-xl-0 mb-4">
                                <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                  <li>
                                    <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span> 25 March 2022   5:00 PM</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500"><span className="text-dif-black">Deadline:</span> 25 March 2022   5:00 PM</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div> 
                        </div> 
                      </div>
                      {/*start-pagination*/}
                      <nav aria-label="Page navigation example" className="mt-5 custom-pagination">
                        <ul className="pagination justify-content-evenly align-items-center border-radius-10">
                          <li className="page-item">
                            <a className="page-link text-dark" href="#" aria-label="Previous">
                              <span className>
                                <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="M453-241 213-481l240-240 42 42-198 198 198 198-42 42Zm253 0L466-481l240-240 42 42-198 198 198 198-42 42Z" /></svg>
                              </span>
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link text-dark border-start-0" href="#" aria-label="Previous">
                              <span className>
                                <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="M561-240 320-481l241-241 43 43-198 198 198 198-43 43Z" /></svg>
                              </span>
                            </a>
                          </li>
                          <li className="page-item"><a className="page-link text-dark border-start-0 border-end-0" href="#">May 2023</a></li>
                          <li className="page-item active"><a className="page-link text-dark border-start-0 border-end-0" href="#">Apr 2023</a></li>
                          <li className="page-item"><a className="page-link text-dark border-start-0 border-end-0" href="#">Mar 2023</a></li>
                          <li className="page-item"><a className="page-link text-dark border-start-0 border-end-0" href="#">Feb 2023</a></li> 
                          <li className="page-item">
                            <a className="page-link border-start-0 " href="#" aria-label="Next">
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z" /></svg>
                              </span>
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link text-dark border-start-0 " href="#" aria-label="Next">
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="m255-241-42-42 198-198-198-198 42-42 240 240-240 240Zm253 0-42-42 198-198-198-198 42-42 240 240-240 240Z" /></svg>
                              </span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                      {/*end*/}
                    </div>
                    <div className="col-xl-3">
                      <div className> 
                        <div className="accordion border-radius-10 " id="accordionExample">
                          <div className="accordion-item">
                            <h2 className="accordion-header poppins" id="headingOne">
                              <button className="accordion-button fs-5 text-dif-black bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">New Notifications</button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <div className="list_notification">
                                  <ul className="position-static">
                                    <li className="d-flex align-items-center mb-4">
                                      <div className="notif_left d-flex align-items-center">
                                        <div className="noti_img bg-pink position-relative">
                                          <span className="text-uppercase text-danger">A</span>
                                          <span className="notifi_logs">
                                            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                            </svg>
                                          </span>
                                        </div>
                                        <p className="ms-3 noti_text">Assignments Due</p>
                                      </div>
                                      <span className="noti_timer">10.12 PM</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-4">
                                      <div className="notif_left d-flex align-items-center">
                                        <div className="noti_img bg-diff-yellow-op-1 position-relative">
                                          <span className="text-uppercase text-yellow">q</span>
                                          <span className="notifi_logs">
                                            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                            </svg>
                                          </span>
                                        </div>
                                        <p className="ms-3 noti_text">New assignments.</p>
                                      </div>
                                      <span className="noti_timer">10.12 PM</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-4">
                                      <div className="notif_left d-flex align-items-center">
                                        <div className="noti_img bg-sky-blue-op-1 position-relative">
                                          <span className="text-uppercase text-blue">e</span>
                                          <span className="notifi_logs">
                                            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                            </svg>
                                          </span>
                                        </div>
                                        <p className="ms-3 noti_text">Any upcoming events</p>
                                      </div>
                                      <span className="noti_timer">10.12 PM</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-4">
                                      <div className="notif_left d-flex align-items-center">
                                        <div className="noti_img bg-sky-blue-op-1 position-relative">
                                          <span className="text-uppercase text-blue">e</span>
                                          <span className="notifi_logs">
                                            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                            </svg>
                                          </span>
                                        </div>
                                        <p className="ms-3 noti_text">Any upcoming events</p>
                                      </div>
                                      <span className="noti_timer">10.12 PM</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div> 
                        </div>
                      </div>
                    </div>
                  </div> 
                </div>
                {/*----------------------------------Tab4-------------------------------------------------*/}
                <div className="tab-pane fade" id="pills-Discussion" role="tabpanel" aria-labelledby="pills-Discussion-tab"> 
                  <div className="row">
                    <div className="col-xl-9 mb-xl-0 mb-4">	
                      <div className="d-flex mb-4 justify-content-between align-items-center">
                        <a href="add_discussion_info.php" className="btn-theme fw-500 text-dif-black border-dark">+ Add Discussion</a>
                      </div>
                      <div className="review_dack discussion_divs">
                        <div className="card review_box mb-4">
                          <div className="review_top"> 
                            <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                              <div className="list_btw_dots mb-3">
                                <ul className="d-xl-flex align-items-center">
                                  <span className="pe-sm-4 fw-600 position-relative">1. Collecting Moodboard from Art. <span className="green_dots position-absolute" /></span>
                                  <li>
                                    <span className="text-purple fw-500"> Score : 25/30</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500">Total Duration: 1 Hours</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                                <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                              </div>
                            </div>
                            <div className="review_text row mb-4">
                              <div className="col-lg-8">
                                <p className="fs-6">Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of problems.</p>
                              </div>
                            </div>
                            <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                              <div className="button d-sm-flex align-items-center"> 
                                <a href="#!" className="discussion_btn btn-theme px-3 d-inline-block text-center">Start Discussion</a> 
                              </div>
                              <div className="list_btw_dots mb-xl-0 mb-4">
                                <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                  <li>
                                    <span className="text-danger fw-500">Closed</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span> 25 March 2022   5:00 PM</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500"><span className="text-dif-black">Deadline:</span> 25 March 2022   5:00 PM</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div> 
                        </div>
                        <div className="card review_box mb-4">
                          <div className="review_top"> 
                            <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                              <div className="list_btw_dots mb-3">
                                <ul className="d-xl-flex align-items-center">
                                  <span className="fw-600 position-relative">2. Collecting Moodboard from Art.</span>
                                  <li>
                                    <span className="text-purple fw-500"> Score : 25/30</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500">Total Duration: 1 Hours</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                                <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                              </div>
                            </div>
                            <div className="review_text row mb-4">
                              <div className="col-lg-8">
                                <p className="fs-6">Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of problems.</p>
                              </div>
                            </div>
                            <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                              <div className="button d-sm-flex align-items-center">
                                <a href="#!" className="discussion_btn btn-theme px-3 d-inline-block text-center">Start Discussion</a> 
                                <ul className="ms-3 edit_delete d-flex align-items-center justify-content-end">
                                  <li>
                                    <a href="#!" className>
                                      <svg width={20} height={20} viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.80679 25.6355C1.80679 27.406 3.24075 28.84 5.01126 28.84H17.8291C19.5995 28.84 21.0335 27.406 21.0335 25.6355V6.40886H1.80679V25.6355ZM17.0279 1.6022L15.4257 0H7.41459L5.81232 1.6022H0.20459V4.80667H22.6357V1.6022H17.0279Z" fill="#FF2929" />
                                      </svg>
                                    </a>
                                  </li>
                                  <li>
                                    <Link to="/organization-add-class" className>
                                      <svg width={20} height={23} viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.10738 18.8439C3.32277 18.8439 3.36584 18.8224 3.55969 18.7793L7.43661 18.0039C7.84584 17.8962 8.25507 17.7024 8.57815 17.3793L17.9689 7.98851C19.412 6.54543 19.412 4.06851 17.9689 2.62543L17.172 1.78543C15.7289 0.342356 13.2305 0.342356 11.7874 1.78543L2.39661 11.1977C2.09508 11.4993 1.87969 11.93 1.772 12.3393L0.953537 16.2593C0.845845 16.9916 1.06123 17.7024 1.57815 18.2193C1.98738 18.6285 2.59046 18.8439 3.10738 18.8439V18.8439ZM3.83969 12.7485L13.2305 3.3362C13.8551 2.71159 14.9966 2.71159 15.5997 3.3362L16.4181 4.15466C17.1505 4.88697 17.1505 5.92082 16.4181 6.63159L7.04892 16.0439L3.06431 16.7116L3.83969 12.7485Z" fill="#570861" />
                                        <path d="M17.8827 20.1797H1.98734C1.36272 20.1797 0.953491 20.5889 0.953491 21.2135C0.953491 21.8382 1.47041 22.2474 1.98734 22.2474H17.7966C18.4212 22.2474 18.9381 21.8382 18.9381 21.2135C18.9166 20.5889 18.3997 20.1797 17.8827 20.1797Z" fill="#570861" />
                                      </svg>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="list_btw_dots mb-xl-0 mb-4">
                                <ul className="d-sm-flex align-items-center flex-wrap justify-content-end"> 
                                  <li>
                                    <span className="text-success fw-500">Opened</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span> 25 March 2022   5:00 PM</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500"><span className="text-dif-black">Deadline:</span> 25 March 2022   5:00 PM</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div> 
                        </div> 
                        <div className="card review_box mb-4">
                          <div className="review_top"> 
                            <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                              <div className="list_btw_dots mb-3">
                                <ul className="d-xl-flex align-items-center">
                                  <span className="fw-600 position-relative">3. Collecting Moodboard from Art.</span>
                                  <li>
                                    <span className="text-purple fw-500"> Score : 25/30</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500">Total Duration: 1 Hours</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                                <span>Created By</span>:&nbsp;<span>Celina Mark</span>
                              </div>
                            </div>
                            <div className="review_text row mb-4">
                              <div className="col-lg-8">
                                <p className="fs-6">Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of problems.</p>
                              </div>
                            </div>
                            <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                              <div className="button d-sm-flex align-items-center"> 
                                <a href="#!" className="discussion_btn btn-theme px-3 d-inline-block text-center">Start Discussion</a> 
                              </div>
                              <div className="list_btw_dots mb-xl-0 mb-4">
                                <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                  <li>
                                    <span className="text-danger fw-500">Closed</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span> 25 March 2022   5:00 PM</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500"><span className="text-dif-black">Deadline:</span> 25 March 2022   5:00 PM</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div> 
                        </div>
                      </div>
                      {/*start-discussion-panel*/}
                      <div className="discussion_panel_board" style={{display: 'none'}}>
                        <div className="modal-dialog-scrollable">
                          <div className="modal-content">
                            <div className="msg-head"> 
                              <div className>
                                <span>Live Discussion Board</span>
                              </div>
                            </div> 
                            <div className="modal-body">
                              <div className="msg-body"> 
                                {/*start-loop-to-here*/}
                                <div className="d-sm-flex align-items-center mb-4 justify-content-between">
                                  <div className="discussion_sec mb-sm-0 mb-3">
                                    <div className=" d-flex align-items-center">
                                      <div className="discss_img">
                                        <img src="./assets/images/user/user4.png" alt="user3" />
                                      </div>
                                      <div className="discss_ctn ms-3">
                                        <span className="text-gray">Celina <span className="text-dif-black">Helllo how are!</span></span>
                                      </div>
                                    </div> 
                                  </div>
                                  <div className>
                                    <span className="text-gray">25 March</span>
                                    <span className="d-block text-dif-black">10:10 PM </span>
                                  </div>
                                </div>
                                {/*end-loop-to-here*/}
                              </div>
                            </div> 
                            <div className="send-box">
                              <form action className="position-relative">
                                <input type="text" className="form-control rounded-pill" aria-label="message…" placeholder="Write message…" />
                                <a href="#" className="send_icons"><i className="fa fa-paper-plane" aria-hidden="true" /></a>
                                <div className="send-btns">
                                  <div className="attach">
                                    <div className="button-wrapper position-relative">
                                      <span className="label">
                                        <svg width={16} height={18} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M12.9297 2.94612C12.0719 2.08831 10.6797 2.08831 9.82187 2.94612L3.35313 9.41487C1.87305 10.8949 1.87305 13.2926 3.35313 14.7727C4.8332 16.2528 7.23086 16.2528 8.71094 14.7727L14.0547 9.42893C14.4379 9.04573 15.0637 9.04573 15.4469 9.42893C15.8301 9.81213 15.8301 10.4379 15.4469 10.8211L10.1031 16.1649C7.85312 18.4149 4.21094 18.4149 1.96094 16.1649C-0.289062 13.9149 -0.289062 10.2727 1.96094 8.02268L8.42969 1.55393C10.0574 -0.0738037 12.6941 -0.0738037 14.3219 1.55393C15.9496 3.18166 15.9496 5.81838 14.3219 7.44612L8.13437 13.6336C7.12891 14.6391 5.49766 14.6391 4.49219 13.6336C3.48672 12.6281 3.48672 10.9969 4.49219 9.99143L9.55469 4.92893C9.93789 4.54573 10.5637 4.54573 10.9469 4.92893C11.3301 5.31213 11.3301 5.93791 10.9469 6.32112L5.88438 11.3836C5.64883 11.6192 5.64883 12.0059 5.88438 12.2414C6.11992 12.477 6.50664 12.477 6.74219 12.2414L12.9297 6.05393C13.7875 5.19612 13.7875 3.80393 12.9297 2.94612Z" fill="#131313" />
                                        </svg>
                                      </span>
                                      <input type="file" name="upload" id="upload" className="upload-box" placeholder="Upload File" aria-label="Upload File" />
                                    </div> 
                                  </div>
                                </div>
                              </form> 
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*end-discuss-panel*/}
                      {/*start-pagination*/}
                      <nav aria-label="Page navigation example" className="mt-5 custom-pagination">
                        <ul className="pagination justify-content-evenly align-items-center border-radius-10">
                          <li className="page-item">
                            <a className="page-link text-dark" href="#" aria-label="Previous">
                              <span className>
                                <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="M453-241 213-481l240-240 42 42-198 198 198 198-42 42Zm253 0L466-481l240-240 42 42-198 198 198 198-42 42Z" /></svg>
                              </span>
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link text-dark border-start-0" href="#" aria-label="Previous">
                              <span className>
                                <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="M561-240 320-481l241-241 43 43-198 198 198 198-43 43Z" /></svg>
                              </span>
                            </a>
                          </li>
                          <li className="page-item"><a className="page-link text-dark border-start-0 border-end-0" href="#">May 2023</a></li>
                          <li className="page-item active"><a className="page-link text-dark border-start-0 border-end-0" href="#">Apr 2023</a></li>
                          <li className="page-item"><a className="page-link text-dark border-start-0 border-end-0" href="#">Mar 2023</a></li>
                          <li className="page-item"><a className="page-link text-dark border-start-0 border-end-0" href="#">Feb 2023</a></li> 
                          <li className="page-item">
                            <a className="page-link border-start-0 " href="#" aria-label="Next">
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z" /></svg>
                              </span>
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link text-dark border-start-0 " href="#" aria-label="Next">
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="m255-241-42-42 198-198-198-198 42-42 240 240-240 240Zm253 0-42-42 198-198-198-198 42-42 240 240-240 240Z" /></svg>
                              </span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                      {/*end*/}
                    </div>
                    <div className="col-xl-3">
                      <div className> 
                        <div className="accordion border-radius-10 " id="accordionExample">
                          <div className="accordion-item">
                            <h2 className="accordion-header poppins" id="headingOne">
                              <button className="accordion-button fs-5 text-dif-black bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">New Notifications</button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <div className="list_notification">
                                  <ul className="position-static">
                                    <li className="d-flex align-items-center mb-4">
                                      <div className="notif_left d-flex align-items-center">
                                        <div className="noti_img bg-pink position-relative">
                                          <span className="text-uppercase text-danger">A</span>
                                          <span className="notifi_logs">
                                            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                            </svg>
                                          </span>
                                        </div>
                                        <p className="ms-3 noti_text">Assignments Due</p>
                                      </div>
                                      <span className="noti_timer">10.12 PM</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-4">
                                      <div className="notif_left d-flex align-items-center">
                                        <div className="noti_img bg-diff-yellow-op-1 position-relative">
                                          <span className="text-uppercase text-yellow">q</span>
                                          <span className="notifi_logs">
                                            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                            </svg>
                                          </span>
                                        </div>
                                        <p className="ms-3 noti_text">New assignments.</p>
                                      </div>
                                      <span className="noti_timer">10.12 PM</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-4">
                                      <div className="notif_left d-flex align-items-center">
                                        <div className="noti_img bg-sky-blue-op-1 position-relative">
                                          <span className="text-uppercase text-blue">e</span>
                                          <span className="notifi_logs">
                                            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                            </svg>
                                          </span>
                                        </div>
                                        <p className="ms-3 noti_text">Any upcoming events</p>
                                      </div>
                                      <span className="noti_timer">10.12 PM</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-4">
                                      <div className="notif_left d-flex align-items-center">
                                        <div className="noti_img bg-sky-blue-op-1 position-relative">
                                          <span className="text-uppercase text-blue">e</span>
                                          <span className="notifi_logs">
                                            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                            </svg>
                                          </span>
                                        </div>
                                        <p className="ms-3 noti_text">Any upcoming events</p>
                                      </div>
                                      <span className="noti_timer">10.12 PM</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div> 
                        </div>
                      </div>
                    </div>
                  </div> 
                </div>
                {/*----------------------------------Tab5-------------------------------------------------*/}
                <div className="tab-pane fade" id="pills-Attendence" role="tabpanel" aria-labelledby="pills-Attendence-tab"> 
                  {/*start*/}
                  <div className="row">
                    <div className="col-xl-9 mb-xl-0 mb-4">
                      <div className="review_dack">
                        <div className="card review_box mb-4">
                          <div className="review_top">
                            <div className="list_btw_dots mb-4">
                              <ul className="d-sm-flex align-items-center">
                                <span className="pe-4 position-relative">Lesson 1<span className="green_dots position-absolute" /></span>
                                <li>
                                  <span className="text-purple fw-500"> Duration Of Lesson : 3 Hours</span>
                                </li>
                              </ul>
                            </div>
                            <div className="d-lg-flex justify-content-between mb-3 align-items-satrt">
                              <div className="d-flex align-items-center"> 
                                <div className="fw-600">
                                  <span className="d-block">Collecting Moodboard from Art.</span> 
                                </div>
                              </div> 
                            </div>
                            <div className="review_text row mb-4">
                              <div className="col-lg-8">
                                <p className="fs-6">Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of problems.</p>
                              </div>
                            </div>
                            <div className="d-flex flex-sm-row  flex-column-reverse justify-content-between align-items-sm-center">
                              <div className="list_btw_dots mb-sm-0 mb-4">
                                <ul className="d-sm-flex align-items-center">
                                  <li>
                                    <span className="text-danger fw-500">30 Mins  Completed</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span> 25 March 2022   5:00 PM</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div> 
                        </div>  
                        <div className="card review_box mb-4">
                          <div className="review_top">
                            <div className="list_btw_dots mb-4">
                              <ul className="d-sm-flex align-items-center">
                                <span className="pe-4 position-relative">Lesson 2</span>
                                <li>
                                  <span className="text-purple fw-500"> Duration Of Lesson : 3 Hours</span>
                                </li>
                              </ul>
                            </div>
                            <div className="d-lg-flex justify-content-between mb-3 align-items-satrt">
                              <div className="d-flex align-items-center"> 
                                <div className="fw-600">
                                  <span className="d-block">Collecting Moodboard from Art.</span> 
                                </div>
                              </div>
                            </div>
                            <div className="review_text row mb-4">
                              <div className="col-lg-8">
                                <p className="fs-6">Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of problems.</p>
                              </div>
                            </div>
                            <div className="d-flex flex-sm-row  flex-column-reverse justify-content-between align-items-sm-center">
                              <div className="list_btw_dots mb-sm-0 mb-4">
                                <ul className="d-sm-flex align-items-center">
                                  <li>
                                    <span className="text-danger fw-500">30 Mins  Completed</span>
                                  </li>
                                  <li>
                                    <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span> 25 March 2022   5:00 PM</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div> 
                        </div>  
                      </div>
                      {/*start-pagination*/}
                      <nav aria-label="Page navigation example" className="mt-5 custom-pagination">
                        <ul className="pagination justify-content-evenly align-items-center border-radius-10">
                          <li className="page-item">
                            <a className="page-link text-dark" href="#" aria-label="Previous">
                              <span className>
                                <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="M453-241 213-481l240-240 42 42-198 198 198 198-42 42Zm253 0L466-481l240-240 42 42-198 198 198 198-42 42Z" /></svg>
                              </span>
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link text-dark border-start-0" href="#" aria-label="Previous">
                              <span className>
                                <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="M561-240 320-481l241-241 43 43-198 198 198 198-43 43Z" /></svg>
                              </span>
                            </a>
                          </li>
                          <li className="page-item"><a className="page-link text-dark border-start-0 border-end-0" href="#">May 2023</a></li>
                          <li className="page-item active"><a className="page-link text-dark border-start-0 border-end-0" href="#">Apr 2023</a></li>
                          <li className="page-item"><a className="page-link text-dark border-start-0 border-end-0" href="#">Mar 2023</a></li>
                          <li className="page-item"><a className="page-link text-dark border-start-0 border-end-0" href="#">Feb 2023</a></li> 
                          <li className="page-item">
                            <a className="page-link border-start-0 " href="#" aria-label="Next">
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z" /></svg>
                              </span>
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link text-dark border-start-0 " href="#" aria-label="Next">
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="m255-241-42-42 198-198-198-198 42-42 240 240-240 240Zm253 0-42-42 198-198-198-198 42-42 240 240-240 240Z" /></svg>
                              </span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div className="col-xl-3">
                      <div className="mb-4"> 
                        <span className="fs-5 fw-600 mb-4 d-block text-dif-black">Attendance</span>
                        <div className="wrappers">
                          <div className="container-calendar"> 
                            <div className="cal_header d-flex justify-content-between">
                              <h3 className="poppins fw_500" id="monthAndYear" />	
                              <div className="button-container-calendar">
                                <button id="previous" onclick="previous()">‹</button>
                                <button className=" ms-3" id="next" onclick="next()">›</button>
                              </div>
                            </div> 
                            <table className="table-calendar" id="calendar" data-lang="en">
                              <thead id="thead-month" />
                              <tbody id="calendar-body" />
                            </table> 
                            <div className="footer-container-calendar d-none">
                              <label htmlFor="month">Jump To: </label>
                              <select id="month" onchange="jump()">
                                <option value={0}>Jan</option>
                                <option value={1}>Feb</option>
                                <option value={2}>Mar</option>
                                <option value={3}>Apr</option>
                                <option value={4}>May</option>
                                <option value={5}>Jun</option>
                                <option value={6}>Jul</option>
                                <option value={7}>Aug</option>
                                <option value={8}>Sep</option>
                                <option value={9}>Oct</option>
                                <option value={10}>Nov</option>
                                <option value={11}>Dec</option>
                              </select>
                              <select id="year" onchange="jump()" />
                            </div>
                          </div>
                        </div>
                        {/*start*/}
                        <div className="attendence_count g-3 row">
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">  
                            <div className="attendence_box flex-wrap d-flex align-items-center">
                              <canvas id="myChart" width={33} height={33} />
                              <div className="ms-3">
                                <span>150</span>
                                <p>Total Hours attended for Month</p>
                              </div>
                            </div> 
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">  
                            <div className="attendence_box flex-wrap d-flex align-items-center">
                              <canvas id="myChart1" width={33} height={33} />
                              <div className="ms-3">
                                <span>150</span>
                                <p>Total Hours attended To Date</p>
                              </div>
                            </div> 
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">  
                            <div className="attendence_box flex-wrap absent_bg d-flex align-items-center">
                              <canvas id="myChart2" width={33} height={33} />
                              <div className="ms-3">
                                <span>150</span>
                                <p>Total  Days Absent for Month</p>
                              </div>
                            </div> 
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">  
                            <div className="attendence_box flex-wrap absent_bg d-flex align-items-center">
                              <canvas id="myChart3" width={33} height={33} />
                              <div className="ms-3">
                                <span>150</span>
                                <p>Total  Days Absent To Date</p>
                              </div>
                            </div> 
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">  
                            <div className="attendence_box flex-wrap joined_bg d-flex align-items-center">
                              <canvas id="myChart4" width={33} height={33} />
                              <div className="ms-3">
                                <span>150</span>
                                <p>Total  Days joined for Month</p>
                              </div>
                            </div> 
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">  
                            <div className="attendence_box flex-wrap joined_bg d-flex align-items-center">
                              <canvas id="myChart5" width={33} height={33} />
                              <div className="ms-3">
                                <span>150</span>
                                <p>Total Days joined To Date</p>
                              </div>
                            </div> 
                          </div>
                        </div>
                        {/*end*/}
                      </div>
                      <div className> 
                        <div className="accordion border-radius-10 " id="accordionExample">
                          <div className="accordion-item">
                            <h2 className="accordion-header poppins" id="headingOne">
                              <button className="accordion-button fs-5 text-dif-black bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">New Notifications</button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <div className="list_notification">
                                  <ul className="position-static">
                                    <li className="d-flex align-items-center mb-4">
                                      <div className="notif_left d-flex align-items-center">
                                        <div className="noti_img bg-pink position-relative">
                                          <span className="text-uppercase text-danger">A</span>
                                          <span className="notifi_logs">
                                            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                            </svg>
                                          </span>
                                        </div>
                                        <p className="ms-3 noti_text">Assignments Due</p>
                                      </div>
                                      <span className="noti_timer">10.12 PM</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-4">
                                      <div className="notif_left d-flex align-items-center">
                                        <div className="noti_img bg-diff-yellow-op-1 position-relative">
                                          <span className="text-uppercase text-yellow">q</span>
                                          <span className="notifi_logs">
                                            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                            </svg>
                                          </span>
                                        </div>
                                        <p className="ms-3 noti_text">New assignments.</p>
                                      </div>
                                      <span className="noti_timer">10.12 PM</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-4">
                                      <div className="notif_left d-flex align-items-center">
                                        <div className="noti_img bg-sky-blue-op-1 position-relative">
                                          <span className="text-uppercase text-blue">e</span>
                                          <span className="notifi_logs">
                                            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                            </svg>
                                          </span>
                                        </div>
                                        <p className="ms-3 noti_text">Any upcoming events</p>
                                      </div>
                                      <span className="noti_timer">10.12 PM</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-4">
                                      <div className="notif_left d-flex align-items-center">
                                        <div className="noti_img bg-sky-blue-op-1 position-relative">
                                          <span className="text-uppercase text-blue">e</span>
                                          <span className="notifi_logs">
                                            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                            </svg>
                                          </span>
                                        </div>
                                        <p className="ms-3 noti_text">Any upcoming events</p>
                                      </div>
                                      <span className="noti_timer">10.12 PM</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div> 
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*end*/}
                </div>
                {/*----------------------------------Tab6-------------------------------------------------*/}
                <div className="tab-pane fade" id="pills-Messages" role="tabpanel" aria-labelledby="pills-Messages-tab"> 
                  {/*start-chatbox*/}
                  <div className="row">
                    <div className="col-xl-4">
                      <div className="modal-dialog-scrollable">
                        <div className="modal-content">
                          <div className="msg-head">
                            <span>Total Leaners</span>
                          </div>
                          <div className="modal-body p-0">
                            <div className="msg-body">
                              {/*first*/}
                              <div className="accordion border-radius-10 " id="accordionExample">
                                <div className="accordion-item border-0 border-bottom">
                                  <h2 className="accordion-header poppins" id="headingOne">
                                    <button className="accordion-button shadow-none fs-6 text-dif-black bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"><div className="usr_leart_imgs">
                                        <span>E</span>	
                                      </div>
                                      Enrolled (1)
                                    </button>
                                  </h2>
                                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                      <div className="enroll_list">
                                        <ul>
                                          <li className="d-flex mt-0 justify-content-between align-items-center">
                                            <div className="enroll_left d-flex align-items-center">
                                              <div className>
                                                <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                                              </div>
                                              <div className="ms-3">
                                                <span>Mark joseph</span>
                                                <span className="ms-2 enroll_flag">
                                                  <img src="./assets/images/country_flag.png" alt="country_flag" />
                                                </span>
                                              </div>
                                            </div>
                                            <div className="enroll_age">
                                              <span>Age : 8</span>
                                            </div>
                                          </li>
                                          <li className="d-flex mt-0 justify-content-between align-items-center">
                                            <div className="enroll_left d-flex align-items-center">
                                              <div className>
                                                <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                                              </div>
                                              <div className="ms-3">
                                                <span>Mark joseph</span>
                                                <span className="ms-2 enroll_flag">
                                                  <img src="./assets/images/country_flag.png" alt="country_flag" />
                                                </span>
                                              </div>
                                            </div>
                                            <div className="enroll_age">
                                              <span>Age : 8</span>
                                            </div>
                                          </li>
                                          <li className="d-flex mt-0 justify-content-between align-items-center">
                                            <div className="enroll_left d-flex align-items-center">
                                              <div className>
                                                <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                                              </div>
                                              <div className="ms-3">
                                                <span>Mark joseph</span>
                                                <span className="ms-2 enroll_flag">
                                                  <img src="./assets/images/country_flag.png" alt="country_flag" />
                                                </span>
                                              </div>
                                            </div>
                                            <div className="enroll_age">
                                              <span>Age : 8</span>
                                            </div>
                                          </li> 
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div> 
                              </div>  
                              {/*second*/}
                              <div className="accordion border-radius-10 " id="accordionExample">
                                <div className="accordion-item border-0 border-bottom">
                                  <h2 className="accordion-header poppins" id="headingOne">
                                    <button className="accordion-button shadow-none fs-6 text-dif-black bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo"><div className="usr_leart_imgs">
                                        <span>P</span>	
                                      </div>
                                      Past Enrolled (1)
                                    </button>
                                  </h2>
                                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                      <div className="enroll_list">
                                        <ul>
                                          <li className="d-flex mt-0 justify-content-between align-items-center">
                                            <div className="enroll_left d-flex align-items-center">
                                              <div className>
                                                <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                                              </div>
                                              <div className="ms-3">
                                                <span>Mark joseph</span>
                                                <span className="ms-2 enroll_flag">
                                                  <img src="./assets/images/country_flag.png" alt="country_flag" />
                                                </span>
                                              </div>
                                            </div>
                                            <div className="enroll_age">
                                              <span>Age : 8</span>
                                            </div>
                                          </li>
                                          <li className="d-flex mt-0 justify-content-between align-items-center">
                                            <div className="enroll_left d-flex align-items-center">
                                              <div className>
                                                <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                                              </div>
                                              <div className="ms-3">
                                                <span>Mark joseph</span>
                                                <span className="ms-2 enroll_flag">
                                                  <img src="./assets/images/country_flag.png" alt="country_flag" />
                                                </span>
                                              </div>
                                            </div>
                                            <div className="enroll_age">
                                              <span>Age : 8</span>
                                            </div>
                                          </li>
                                          <li className="d-flex mt-0 justify-content-between align-items-center">
                                            <div className="enroll_left d-flex align-items-center">
                                              <div className>
                                                <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                                              </div>
                                              <div className="ms-3">
                                                <span>Mark joseph</span>
                                                <span className="ms-2 enroll_flag">
                                                  <img src="./assets/images/country_flag.png" alt="country_flag" />
                                                </span>
                                              </div>
                                            </div>
                                            <div className="enroll_age">
                                              <span>Age : 8</span>
                                            </div>
                                          </li> 
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div> 
                              </div>   
                              {/*three*/}
                              <div className="accordion border-radius-10 " id="accordionExample">
                                <div className="accordion-item border-0 border-bottom">
                                  <h2 className="accordion-header poppins" id="headingOne">
                                    <button className="accordion-button shadow-none fs-6 text-dif-black bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree"><div className="usr_leart_imgs">
                                        <span>W</span>	
                                      </div>
                                      Withdrawn Enrollment(1)
                                    </button>
                                  </h2>
                                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                      <div className="enroll_list">
                                        <ul>
                                          <li className="d-flex mt-0 justify-content-between align-items-center">
                                            <div className="enroll_left d-flex align-items-center">
                                              <div className>
                                                <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                                              </div>
                                              <div className="ms-3">
                                                <span>Mark joseph</span>
                                                <span className="ms-2 enroll_flag">
                                                  <img src="./assets/images/country_flag.png" alt="country_flag" />
                                                </span>
                                              </div>
                                            </div>
                                            <div className="enroll_age">
                                              <span>Age : 8</span>
                                            </div>
                                          </li>
                                          <li className="d-flex mt-0 justify-content-between align-items-center">
                                            <div className="enroll_left d-flex align-items-center">
                                              <div className>
                                                <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                                              </div>
                                              <div className="ms-3">
                                                <span>Mark joseph</span>
                                                <span className="ms-2 enroll_flag">
                                                  <img src="./assets/images/country_flag.png" alt="country_flag" />
                                                </span>
                                              </div>
                                            </div>
                                            <div className="enroll_age">
                                              <span>Age : 8</span>
                                            </div>
                                          </li>
                                          <li className="d-flex mt-0 justify-content-between align-items-center">
                                            <div className="enroll_left d-flex align-items-center">
                                              <div className>
                                                <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                                              </div>
                                              <div className="ms-3">
                                                <span>Mark joseph</span>
                                                <span className="ms-2 enroll_flag">
                                                  <img src="./assets/images/country_flag.png" alt="country_flag" />
                                                </span>
                                              </div>
                                            </div>
                                            <div className="enroll_age">
                                              <span>Age : 8</span>
                                            </div>
                                          </li> 
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div> 
                              </div>  
                            </div>
                          </div> 
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-8">
                      <div className="mb-4">
                        <div className="modal-dialog-scrollable">
                          <div className="modal-content">
                            <div className="msg-head">
                              <div className="row">
                                <div className="col-12">
                                  <div className="d-sm-flex flex-wrap align-items-center justify-content-between">
                                    <div className="d-flex mb-sm-0 mb-3 align-items-center"> 
                                      <div className="flex-shrink-0 position-relative">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user.png" alt="user img " />
                                        <span className="active" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Killan James</h3>
                                        <p>Active Now</p>
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-center"> 
                                      <div className="flex-shrink-0 position-relative">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user2.png" alt="user img " />
                                        <span className="active" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Parents Name</h3>
                                        <p>Send A Message Now</p>
                                      </div>
                                    </div>
                                  </div>
                                </div> 
                              </div>
                            </div>
                            <div className="modal-body">
                              <div className="msg-body">
                                <ul>
                                  <li className="sender">
                                    <div className="d-flex align-items-start">
                                      <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                                      <div>
                                        <p className="text-dif-black"> Hi, I hope you are doing well, yesterday you have gave a pen This very nice, i am very happy for this.yesterday you have gave a pen This very nice </p>
                                        <span className="time text-end">10:06 am</span>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="sender">
                                    <div className="d-flex align-items-start">
                                      <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                                      <div>
                                        <p className="text-dif-black"> Hey, Are you there? </p>
                                        <span className="time text-end">10:16 am</span>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="repaly">
                                    <div className="d-flex justify-content-end">
                                      <div className>
                                        <p className="text-dif-black">yea I’m well, Thank you, i am very happy for this.yesterday you have gave a pen This very nice</p>
                                        <span className="time text-start">10:20 am</span>
                                      </div>
                                      <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                                    </div>
                                  </li>
                                  <li className="sender">
                                    <div className="d-flex align-items-start">
                                      <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                                      <div>
                                        <p className="text-dif-black"> Hey, Are you there? </p>
                                        <span className="time text-end">10:26 am</span>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="sender">
                                    <div className="d-flex align-items-start">
                                      <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                                      <div>
                                        <p className="text-dif-black"> Hey, Are you there? </p>
                                        <span className="time text-end">10:32 am</span>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="repaly">
                                    <div className="d-flex justify-content-end">
                                      <div className>
                                        <p className="text-dif-black">How are you?</p>
                                        <span className="time text-start">10:35 am</span>
                                      </div>
                                      <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                                    </div>
                                  </li>
                                  <li>
                                    <div className="divider">
                                      <h6>Today</h6>
                                    </div>
                                  </li>
                                  <li className="repaly">
                                    <div className="d-flex justify-content-end">
                                      <div className>
                                        <p className="text-dif-black"> yes, tell me</p>
                                        <span className="time text-start">10:36 am</span>
                                      </div>
                                      <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                                    </div>
                                  </li>
                                  <li className="repaly">
                                    <div className="d-flex justify-content-end">
                                      <div className>
                                        <p className="text-dif-black">yes... on it</p>
                                        <span className="time text-start">junt now</span>
                                      </div>
                                      <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="send-box">
                              <form action className="position-relative">
                                <input type="text" className="form-control rounded-pill" aria-label="message…" placeholder="Write message…" />
                                <a href="#" className="send_icons"><i className="fa fa-paper-plane" aria-hidden="true" /></a>
                                <div className="send-btns">
                                  <div className="attach">
                                    <div className="button-wrapper position-relative">
                                      <span className="label">
                                        <svg width={16} height={18} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M12.9297 2.94612C12.0719 2.08831 10.6797 2.08831 9.82187 2.94612L3.35313 9.41487C1.87305 10.8949 1.87305 13.2926 3.35313 14.7727C4.8332 16.2528 7.23086 16.2528 8.71094 14.7727L14.0547 9.42893C14.4379 9.04573 15.0637 9.04573 15.4469 9.42893C15.8301 9.81213 15.8301 10.4379 15.4469 10.8211L10.1031 16.1649C7.85312 18.4149 4.21094 18.4149 1.96094 16.1649C-0.289062 13.9149 -0.289062 10.2727 1.96094 8.02268L8.42969 1.55393C10.0574 -0.0738037 12.6941 -0.0738037 14.3219 1.55393C15.9496 3.18166 15.9496 5.81838 14.3219 7.44612L8.13437 13.6336C7.12891 14.6391 5.49766 14.6391 4.49219 13.6336C3.48672 12.6281 3.48672 10.9969 4.49219 9.99143L9.55469 4.92893C9.93789 4.54573 10.5637 4.54573 10.9469 4.92893C11.3301 5.31213 11.3301 5.93791 10.9469 6.32112L5.88438 11.3836C5.64883 11.6192 5.64883 12.0059 5.88438 12.2414C6.11992 12.477 6.50664 12.477 6.74219 12.2414L12.9297 6.05393C13.7875 5.19612 13.7875 3.80393 12.9297 2.94612Z" fill="#131313" />
                                        </svg>
                                      </span>
                                      <input type="file" name="upload" id="upload" className="upload-box" placeholder="Upload File" aria-label="Upload File" />
                                    </div> 
                                  </div>
                                </div>
                              </form> 
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-end">
                        <div className="col-xl-4">
                          <div className="accordion border-radius-10 " id="accordionExample">
                            <div className="accordion-item">
                              <h2 className="accordion-header poppins" id="headingOne">
                                <button className="accordion-button fs-5 text-dif-black bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">New Notifications</button>
                              </h2>
                              <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                  <div className="list_notification">
                                    <ul className="position-static">
                                      <li className="d-flex align-items-center mb-4">
                                        <div className="notif_left d-flex align-items-center">
                                          <div className="noti_img bg-pink position-relative">
                                            <span className="text-uppercase text-danger">A</span>
                                            <span className="notifi_logs">
                                              <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                              </svg>
                                            </span>
                                          </div>
                                          <p className="ms-3 noti_text">Assignments Due</p>
                                        </div>
                                        <span className="noti_timer">10.12 PM</span>
                                      </li>
                                      <li className="d-flex align-items-center mb-4">
                                        <div className="notif_left d-flex align-items-center">
                                          <div className="noti_img bg-diff-yellow-op-1 position-relative">
                                            <span className="text-uppercase text-yellow">q</span>
                                            <span className="notifi_logs">
                                              <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                              </svg>
                                            </span>
                                          </div>
                                          <p className="ms-3 noti_text">New assignments.</p>
                                        </div>
                                        <span className="noti_timer">10.12 PM</span>
                                      </li>
                                      <li className="d-flex align-items-center mb-4">
                                        <div className="notif_left d-flex align-items-center">
                                          <div className="noti_img bg-sky-blue-op-1 position-relative">
                                            <span className="text-uppercase text-blue">e</span>
                                            <span className="notifi_logs">
                                              <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                              </svg>
                                            </span>
                                          </div>
                                          <p className="ms-3 noti_text">Any upcoming events</p>
                                        </div>
                                        <span className="noti_timer">10.12 PM</span>
                                      </li>
                                      <li className="d-flex align-items-center mb-4">
                                        <div className="notif_left d-flex align-items-center">
                                          <div className="noti_img bg-sky-blue-op-1 position-relative">
                                            <span className="text-uppercase text-blue">e</span>
                                            <span className="notifi_logs">
                                              <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                              </svg>
                                            </span>
                                          </div>
                                          <p className="ms-3 noti_text">Any upcoming events</p>
                                        </div>
                                        <span className="noti_timer">10.12 PM</span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div> 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*end-chatbox*/}
                </div>
                {/*----------------------------------Tab7-------------------------------------------------*/}
                {/* <div class="tab-pane fade" id="pills-feedback" role="tabpanel" aria-labelledby="pills-feedback-tab"> 
						<div class="row">
							<div class="col-lg-6 mb-4">
								<form class="custom_form mt-4" action="" id="">  
								 	<div class="form-group mb-4">
								 		<label class="mb-2">Teacher’s Name</label>
								 		<input type="text" class="form-control" name="" placeholder="" value="">
								 	</div>

								 	<div class="form-group mb-4">
								 		<label class="mb-2">Name of Class</label>
								 		<input type="text" class="form-control" name="" placeholder="" value="">
								 	</div>

								 	<div class="form-group mb-4">
										<label class="mb-2">Student’s Name</label>
										<select class="form-select" aria-label="Default select example">
											<option selected="">Mark joseph</option>
											<option value="1">Killan James</option>
											<option value="2">Celina Marlk</option>
											<option value="3">Julie Mark</option>
										</select>
									</div>

								 	<div class="form-group mb-4">
								 		<label class="mb-2">URL link to the class</label>
								 		<input type="text" class="form-control" name="" placeholder="" value="">
								 	</div>

									<div class="form-group mb-4">
										<label class="mb-2">Day and Time of Class</label>
										<select class="form-select" aria-label="Default select example">
											<option selected="">Lorem ipsum dolor sit amet</option>
											<option value="1">Lorem ipsum dolor sit amet.</option>
											<option value="2">sed do eiusmod ut labore et dolore magna aliqua</option>
											<option value="3">sed do eiusmod ut labore et dolore.</option>
										</select>
									</div>  

									<div class="form-group mb-4">
										<label class="mb-2">Date of the Incident</label>
										<select class="form-select" aria-label="Default select example">
											<option selected="">Lorem ipsum dolor sit amet</option>
											<option value="1">Lorem ipsum dolor sit amet.</option>
											<option value="2">sed do eiusmod ut labore et dolore magna aliqua</option>
											<option value="3">sed do eiusmod ut labore et dolore.</option>
										</select>
									</div>

									<div class="form-group mb-4">
										<label class="mb-2">Type of Incident (predefined list)</label>
										<select class="form-select pe-5" aria-label="Default select example">
											<optgroup label="Attendance">
												<option>Student cancellation within 24 hours of class</option>
												<option>Student Cancelling remaining session(s) after class begins</option>
												<option>Multiple student cancellations 24 hours before a class begins</option>
												<option>Missing or failing to attend Session(s)</option>
												<option>Repeatedly arriving late or leaving early</option>
											</optgroup>
											<optgroup label="Classroom Behaviour">
												<option>Using inappropriate language or gestures</option>
												<option>Bullying other students or teacher</option>
												<option>Not providing regular feedback or answering questions</option>
											</optgroup>
											<optgroup label="Communication">
												<option>Parent/student reviews or comments </option>
												<option>Not responding to messages from learners who are enrolled in a class</option>
												<option>Failing to refund</option>
											</optgroup>
											<optgroup label="Strictly Prohibited">
												<option>Disregarding learner or parent safety or privacy</option>
												<option>Using an account or unapproved third party platform to teach live sessions, rather
												than using the services</option> 
												<option>Making payment (other than those collected through StudentScholars)</option>
												<option>Requesting student’s/teacher’s phone number and email to contact them privately.?</option>
												<option>Sharing your phone number and email with the student/teacher for them to contact you
												Privately.</option> 
												<option>Other</option>
											</optgroup>
										</select>

									</div>

									<div class="form-group mb-4">
										<label class="mb-2">Sub category of Incident (predefined list)</label>
										<select class="form-select" aria-label="Default select example">
											<option selected="">Lorem ipsum dolor sit amet</option>
											<option value="1">Lorem ipsum dolor sit amet.</option>
											<option value="2">sed do eiusmod ut labore et dolore magna aliqua</option>
											<option value="3">sed do eiusmod ut labore et dolore.</option>
										</select>
									</div>

									<div class="form-group mb-4">
										<label class="mb-2">Please describe the incident in detail</label>
										<textarea class="form-control" rows="6"></textarea>
									</div>  

									<div class="">
										<label class="mb-2">Attachments</label>
										 <div class="row">
										 	<div class="col-lg-4">
												<div class="form-group mb-lg-0 mb-4  file_upload"> 
													<div class="file_update position-relative">
														<input type="file" class="form-control" name="">
														<span class="file_icons position-absolute">
															<svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path fill-rule="evenodd" clip-rule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fill-opacity="0.4"></path>
															</svg>
														</span>
													</div>
												</div>
										 	</div>
										 	<div class="col-lg-4">
										 		<div class="form-group mb-lg-0 mb-4  file_upload"> 
													<div class="file_update position-relative">
														<input type="file" class="form-control" name="">
														<span class="file_icons position-absolute">
															<svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path fill-rule="evenodd" clip-rule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fill-opacity="0.4"></path>
															</svg>
														</span>
													</div>
												</div>
										 	</div>
										 	<div class="col-lg-4">
										 		<div class="form-group mb-lg-0 mb-4  file_upload"> 
													<div class="file_update position-relative">
														<input type="file" class="form-control" name="">
														<span class="file_icons position-absolute">
															<svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path fill-rule="evenodd" clip-rule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fill-opacity="0.4"></path>
															</svg>
														</span>
													</div>
												</div>
										 	</div>
										 </div>
									</div>   
								</form>	
							</div>
							<div class="col-lg-12">
								<div class="">
									<p class="text-gray fw-600">StudentScholars Support team will get back to you.</a></p>
								</div>
								<div class="button mt-4">
									<a href="#" class="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block">Submit</a>
								</div>
							</div>
						</div> 
					</div> */}
                {/*----------------------------------Tab8-------------------------------------------------*/}
                {/* <div class="tab-pane fade" id="pills-review" role="tabpanel" aria-labelledby="pills-review-tab"> 
						<div class="">
							<div class="card review_box mb-4">
								<div class="review_top">
									<div class="d-lg-flex justify-content-between mb-3 align-items-satrt">
										<div class="review_user d-flex align-items-center">
											<img src="./assets/images/user/user1.png" alt="user1">
											<div class="ms-3">
												<span class="d-block fs-5">Celina Marlk</span>
												<ul class="d-flex d-lg-none align-items-center">
							    					<li class="me-2">
														<svg width="15" height="16" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M20.8942 25.5637C20.5767 25.5637 20.2608 25.4662 19.9888 25.2721L13.5953 20.687L7.20182 25.2721C6.93692 25.4627 6.61859 25.5647 6.29227 25.5636C5.96595 25.5624 5.64832 25.4582 5.38476 25.2658C5.12094 25.0745 4.92417 24.8049 4.82243 24.4953C4.7207 24.1858 4.71918 23.852 4.8181 23.5415L7.2026 15.8457L0.866469 11.3809C0.603876 11.1871 0.40886 10.9156 0.309004 10.6049C0.209148 10.2942 0.209508 9.95997 0.310033 9.64947C0.411351 9.33973 0.607448 9.06967 0.87061 8.87744C1.13377 8.68522 1.45069 8.58056 1.77657 8.57826L9.62404 8.56647L12.1225 1.06166C12.2256 0.752496 12.4234 0.483606 12.6878 0.293068C12.9522 0.102529 13.2698 0 13.5957 0C13.9216 0 14.2393 0.102529 14.5037 0.293068C14.7681 0.483606 14.9658 0.752496 15.0689 1.06166L17.525 8.56647L25.4125 8.57826C25.7388 8.5801 26.0562 8.6847 26.3196 8.87722C26.583 9.06973 26.7791 9.34035 26.8799 9.65065C26.9808 9.96095 26.9813 10.2951 26.8814 10.6057C26.7815 10.9163 26.5862 11.1876 26.3234 11.3809L19.9873 15.8457L22.3718 23.5415C22.4709 23.8519 22.4696 24.1857 22.368 24.4953C22.2663 24.8049 22.0697 25.0745 21.8059 25.2658C21.5415 25.4597 21.2221 25.5641 20.8942 25.5637Z" fill="#CEC9C1"></path>
														</svg>
							    					</li>
							    					<li class="me-2">
														<svg width="15" height="16" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33"></path>
														</svg>
							    					</li>
							    					<li class="me-2">
														 <svg width="15" height="16" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33"></path>
														</svg>
							    					</li>
							    					<li class="me-2">
														 <svg width="15" height="16" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33"></path>
														</svg>
							    					</li>
							    					<li class="">
														 <svg width="15" height="16" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33"></path>
														</svg>
							    					</li>
												</ul>
											</div>
										</div>
										<ul class="d-lg-flex d-none mb-lg-0 mb-2 align-items-center">
											<li class="me-2">
												<svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M20.8942 25.5637C20.5767 25.5637 20.2608 25.4662 19.9888 25.2721L13.5953 20.687L7.20182 25.2721C6.93692 25.4627 6.61859 25.5647 6.29227 25.5636C5.96595 25.5624 5.64832 25.4582 5.38476 25.2658C5.12094 25.0745 4.92417 24.8049 4.82243 24.4953C4.7207 24.1858 4.71918 23.852 4.8181 23.5415L7.2026 15.8457L0.866469 11.3809C0.603876 11.1871 0.40886 10.9156 0.309004 10.6049C0.209148 10.2942 0.209508 9.95997 0.310033 9.64947C0.411351 9.33973 0.607448 9.06967 0.87061 8.87744C1.13377 8.68522 1.45069 8.58056 1.77657 8.57826L9.62404 8.56647L12.1225 1.06166C12.2256 0.752496 12.4234 0.483606 12.6878 0.293068C12.9522 0.102529 13.2698 0 13.5957 0C13.9216 0 14.2393 0.102529 14.5037 0.293068C14.7681 0.483606 14.9658 0.752496 15.0689 1.06166L17.525 8.56647L25.4125 8.57826C25.7388 8.5801 26.0562 8.6847 26.3196 8.87722C26.583 9.06973 26.7791 9.34035 26.8799 9.65065C26.9808 9.96095 26.9813 10.2951 26.8814 10.6057C26.7815 10.9163 26.5862 11.1876 26.3234 11.3809L19.9873 15.8457L22.3718 23.5415C22.4709 23.8519 22.4696 24.1857 22.368 24.4953C22.2663 24.8049 22.0697 25.0745 21.8059 25.2658C21.5415 25.4597 21.2221 25.5641 20.8942 25.5637Z" fill="#CEC9C1"></path>
												</svg>
											</li>
											<li class="me-2">
												<svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33"></path>
												</svg>
											</li>
											<li class="me-2">
												 <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33"></path>
												</svg>
											</li>
											<li class="me-2">
												 <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33"></path>
												</svg>
											</li>
											<li class="">
												 <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33"></path>
												</svg>
											</li>
										</ul>
									</div>
									<div class="review_text row mb-4">
										<div class="col-lg-6">
											<p class="fw-500">My son says this is one of his absolute favourite classes on Outschool (and he does a lot of classes).</p>
										</div>
									</div>
									<div class="d-flex align-items-center justify-content-between">
										<span class="text-gray fs-6">March 16,2020</span>
										<a href="#">Reply</a>	
									</div>
									
								</div>
							</div>
							<div class="card review_box mb-4">
								<div class="review_top">
									<div class="d-lg-flex justify-content-between mb-3 align-items-satrt">
										<div class="review_user d-flex align-items-center">
											<img src="./assets/images/user/user1.png" alt="user1">
											<div class="ms-3">
												<span class="d-block fs-5">Celina Marlk</span>
												<ul class="d-flex d-lg-none align-items-center">
							    					<li class="me-2">
														<svg width="15" height="16" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M20.8942 25.5637C20.5767 25.5637 20.2608 25.4662 19.9888 25.2721L13.5953 20.687L7.20182 25.2721C6.93692 25.4627 6.61859 25.5647 6.29227 25.5636C5.96595 25.5624 5.64832 25.4582 5.38476 25.2658C5.12094 25.0745 4.92417 24.8049 4.82243 24.4953C4.7207 24.1858 4.71918 23.852 4.8181 23.5415L7.2026 15.8457L0.866469 11.3809C0.603876 11.1871 0.40886 10.9156 0.309004 10.6049C0.209148 10.2942 0.209508 9.95997 0.310033 9.64947C0.411351 9.33973 0.607448 9.06967 0.87061 8.87744C1.13377 8.68522 1.45069 8.58056 1.77657 8.57826L9.62404 8.56647L12.1225 1.06166C12.2256 0.752496 12.4234 0.483606 12.6878 0.293068C12.9522 0.102529 13.2698 0 13.5957 0C13.9216 0 14.2393 0.102529 14.5037 0.293068C14.7681 0.483606 14.9658 0.752496 15.0689 1.06166L17.525 8.56647L25.4125 8.57826C25.7388 8.5801 26.0562 8.6847 26.3196 8.87722C26.583 9.06973 26.7791 9.34035 26.8799 9.65065C26.9808 9.96095 26.9813 10.2951 26.8814 10.6057C26.7815 10.9163 26.5862 11.1876 26.3234 11.3809L19.9873 15.8457L22.3718 23.5415C22.4709 23.8519 22.4696 24.1857 22.368 24.4953C22.2663 24.8049 22.0697 25.0745 21.8059 25.2658C21.5415 25.4597 21.2221 25.5641 20.8942 25.5637Z" fill="#CEC9C1"></path>
														</svg>
							    					</li>
							    					<li class="me-2">
														<svg width="15" height="16" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33"></path>
														</svg>
							    					</li>
							    					<li class="me-2">
														 <svg width="15" height="16" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33"></path>
														</svg>
							    					</li>
							    					<li class="me-2">
														 <svg width="15" height="16" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33"></path>
														</svg>
							    					</li>
							    					<li class="">
														 <svg width="15" height="16" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33"></path>
														</svg>
							    					</li>
												</ul>
											</div>
										</div>
										<ul class="d-lg-flex d-none mb-lg-0 mb-2 align-items-center">
											<li class="me-2">
												<svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M20.8942 25.5637C20.5767 25.5637 20.2608 25.4662 19.9888 25.2721L13.5953 20.687L7.20182 25.2721C6.93692 25.4627 6.61859 25.5647 6.29227 25.5636C5.96595 25.5624 5.64832 25.4582 5.38476 25.2658C5.12094 25.0745 4.92417 24.8049 4.82243 24.4953C4.7207 24.1858 4.71918 23.852 4.8181 23.5415L7.2026 15.8457L0.866469 11.3809C0.603876 11.1871 0.40886 10.9156 0.309004 10.6049C0.209148 10.2942 0.209508 9.95997 0.310033 9.64947C0.411351 9.33973 0.607448 9.06967 0.87061 8.87744C1.13377 8.68522 1.45069 8.58056 1.77657 8.57826L9.62404 8.56647L12.1225 1.06166C12.2256 0.752496 12.4234 0.483606 12.6878 0.293068C12.9522 0.102529 13.2698 0 13.5957 0C13.9216 0 14.2393 0.102529 14.5037 0.293068C14.7681 0.483606 14.9658 0.752496 15.0689 1.06166L17.525 8.56647L25.4125 8.57826C25.7388 8.5801 26.0562 8.6847 26.3196 8.87722C26.583 9.06973 26.7791 9.34035 26.8799 9.65065C26.9808 9.96095 26.9813 10.2951 26.8814 10.6057C26.7815 10.9163 26.5862 11.1876 26.3234 11.3809L19.9873 15.8457L22.3718 23.5415C22.4709 23.8519 22.4696 24.1857 22.368 24.4953C22.2663 24.8049 22.0697 25.0745 21.8059 25.2658C21.5415 25.4597 21.2221 25.5641 20.8942 25.5637Z" fill="#CEC9C1"></path>
												</svg>
											</li>
											<li class="me-2">
												<svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33"></path>
												</svg>
											</li>
											<li class="me-2">
												 <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33"></path>
												</svg>
											</li>
											<li class="me-2">
												 <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33"></path>
												</svg>
											</li>
											<li class="">
												 <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33"></path>
												</svg>
											</li>
										</ul>
									</div>
									<div class="review_text row mb-4">
										<div class="col-lg-6">
											<p class="fw-500">My son says this is one of his absolute favourite classes on Outschool (and he does a lot of classes).</p>
										</div>
									</div>
									<div class="d-flex align-items-center justify-content-between">
										<span class="text-gray fs-6">March 16,2020</span>
										<a href="#">Reply</a>	
									</div>
									
								</div>
							</div>
							<div class="card review_box mb-4">
								<div class="review_top">
									<div class="d-lg-flex justify-content-between mb-3 align-items-satrt">
										<div class="review_user d-flex align-items-center">
											<img src="./assets/images/user/user1.png" alt="user1">
											<div class="ms-3">
												<span class="d-block fs-5">Celina Marlk</span>
												<ul class="d-flex d-lg-none align-items-center">
							    					<li class="me-2">
														<svg width="15" height="16" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M20.8942 25.5637C20.5767 25.5637 20.2608 25.4662 19.9888 25.2721L13.5953 20.687L7.20182 25.2721C6.93692 25.4627 6.61859 25.5647 6.29227 25.5636C5.96595 25.5624 5.64832 25.4582 5.38476 25.2658C5.12094 25.0745 4.92417 24.8049 4.82243 24.4953C4.7207 24.1858 4.71918 23.852 4.8181 23.5415L7.2026 15.8457L0.866469 11.3809C0.603876 11.1871 0.40886 10.9156 0.309004 10.6049C0.209148 10.2942 0.209508 9.95997 0.310033 9.64947C0.411351 9.33973 0.607448 9.06967 0.87061 8.87744C1.13377 8.68522 1.45069 8.58056 1.77657 8.57826L9.62404 8.56647L12.1225 1.06166C12.2256 0.752496 12.4234 0.483606 12.6878 0.293068C12.9522 0.102529 13.2698 0 13.5957 0C13.9216 0 14.2393 0.102529 14.5037 0.293068C14.7681 0.483606 14.9658 0.752496 15.0689 1.06166L17.525 8.56647L25.4125 8.57826C25.7388 8.5801 26.0562 8.6847 26.3196 8.87722C26.583 9.06973 26.7791 9.34035 26.8799 9.65065C26.9808 9.96095 26.9813 10.2951 26.8814 10.6057C26.7815 10.9163 26.5862 11.1876 26.3234 11.3809L19.9873 15.8457L22.3718 23.5415C22.4709 23.8519 22.4696 24.1857 22.368 24.4953C22.2663 24.8049 22.0697 25.0745 21.8059 25.2658C21.5415 25.4597 21.2221 25.5641 20.8942 25.5637Z" fill="#CEC9C1"></path>
														</svg>
							    					</li>
							    					<li class="me-2">
														<svg width="15" height="16" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33"></path>
														</svg>
							    					</li>
							    					<li class="me-2">
														 <svg width="15" height="16" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33"></path>
														</svg>
							    					</li>
							    					<li class="me-2">
														 <svg width="15" height="16" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33"></path>
														</svg>
							    					</li>
							    					<li class="">
														 <svg width="15" height="16" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33"></path>
														</svg>
							    					</li>
												</ul>
											</div>
										</div>
										<ul class="d-lg-flex d-none mb-lg-0 mb-2 align-items-center">
											<li class="me-2">
												<svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M20.8942 25.5637C20.5767 25.5637 20.2608 25.4662 19.9888 25.2721L13.5953 20.687L7.20182 25.2721C6.93692 25.4627 6.61859 25.5647 6.29227 25.5636C5.96595 25.5624 5.64832 25.4582 5.38476 25.2658C5.12094 25.0745 4.92417 24.8049 4.82243 24.4953C4.7207 24.1858 4.71918 23.852 4.8181 23.5415L7.2026 15.8457L0.866469 11.3809C0.603876 11.1871 0.40886 10.9156 0.309004 10.6049C0.209148 10.2942 0.209508 9.95997 0.310033 9.64947C0.411351 9.33973 0.607448 9.06967 0.87061 8.87744C1.13377 8.68522 1.45069 8.58056 1.77657 8.57826L9.62404 8.56647L12.1225 1.06166C12.2256 0.752496 12.4234 0.483606 12.6878 0.293068C12.9522 0.102529 13.2698 0 13.5957 0C13.9216 0 14.2393 0.102529 14.5037 0.293068C14.7681 0.483606 14.9658 0.752496 15.0689 1.06166L17.525 8.56647L25.4125 8.57826C25.7388 8.5801 26.0562 8.6847 26.3196 8.87722C26.583 9.06973 26.7791 9.34035 26.8799 9.65065C26.9808 9.96095 26.9813 10.2951 26.8814 10.6057C26.7815 10.9163 26.5862 11.1876 26.3234 11.3809L19.9873 15.8457L22.3718 23.5415C22.4709 23.8519 22.4696 24.1857 22.368 24.4953C22.2663 24.8049 22.0697 25.0745 21.8059 25.2658C21.5415 25.4597 21.2221 25.5641 20.8942 25.5637Z" fill="#CEC9C1"></path>
												</svg>
											</li>
											<li class="me-2">
												<svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33"></path>
												</svg>
											</li>
											<li class="me-2">
												 <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33"></path>
												</svg>
											</li>
											<li class="me-2">
												 <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33"></path>
												</svg>
											</li>
											<li class="">
												 <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33"></path>
												</svg>
											</li>
										</ul>
									</div>
									<div class="review_text row mb-4">
										<div class="col-lg-6">
											<p class="fw-500">My son says this is one of his absolute favourite classes on Outschool (and he does a lot of classes).</p>
										</div>
									</div>
									<div class="d-flex align-items-center justify-content-between">
										<span class="text-gray fs-6">March 16,2020</span>
										<a href="#">Reply</a>	
									</div>
									
								</div>
							</div>
						</div>
						<div class="row justify-content-end">
							<div class="col-xl-4">
								<div class="accordion border-radius-10 " id="accordionExample">
								  <div class="accordion-item">
								    <h2 class="accordion-header poppins" id="headingOne">
								      <button class="accordion-button fs-5 text-dif-black bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">New Notifications</button>
								    </h2>
								    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
								      <div class="accordion-body">
										<div class="list_notification">
											<ul class="position-static">
												<li class="d-flex align-items-center mb-4">
													<div class="notif_left d-flex align-items-center">
														<div class="noti_img bg-pink position-relative">
															<span class="text-uppercase text-danger">A</span>
															<span class="notifi_logs">
																<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44"></path>
																</svg>
															</span>
														</div>
														<p class="ms-3 noti_text">Assignments Due</p>
													</div>
													<span class="noti_timer">10.12 PM</span>
												</li>
												<li class="d-flex align-items-center mb-4">
													<div class="notif_left d-flex align-items-center">
														<div class="noti_img bg-diff-yellow-op-1 position-relative">
															<span class="text-uppercase text-yellow">q</span>
															<span class="notifi_logs">
																<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44"></path>
																</svg>
															</span>
														</div>
														<p class="ms-3 noti_text">New assignments.</p>
													</div>
													<span class="noti_timer">10.12 PM</span>
												</li>
												<li class="d-flex align-items-center mb-4">
													<div class="notif_left d-flex align-items-center">
														<div class="noti_img bg-sky-blue-op-1 position-relative">
															<span class="text-uppercase text-blue">e</span>
															<span class="notifi_logs">
																<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44"></path>
																</svg>
															</span>
														</div>
														<p class="ms-3 noti_text">Any upcoming events</p>
													</div>
													<span class="noti_timer">10.12 PM</span>
												</li>
												<li class="d-flex align-items-center mb-4">
													<div class="notif_left d-flex align-items-center">
														<div class="noti_img bg-sky-blue-op-1 position-relative">
															<span class="text-uppercase text-blue">e</span>
															<span class="notifi_logs">
																<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44"></path>
																</svg>
															</span>
														</div>
														<p class="ms-3 noti_text">Any upcoming events</p>
													</div>
													<span class="noti_timer">10.12 PM</span>
												</li>
											</ul>
										</div>
								      </div>
								    </div>
								  </div> 
								</div>
							</div>
						</div> 
					</div> */}  
                {/*----------------------------------Tab9-------------------------------------------------*/}
                <div className="tab-pane fade" id="pills-Progress" role="tabpanel" aria-labelledby="pills-Progress-tab"> 
                  <div className="enroll_list progrss_report">
                    <div className="card">
                      <div className="d-sm-flex align-items-center justify-content-between"> 
                        <div className="enroll_left d-md-flex align-items-center mb-sm-0 mb-3">
                          <div className="mb-md-0 mb-3">
                            <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                          </div>
                          <div className="ms-md-3  d-flex align-items-center flex-wrap">
                            <span className="mb-md-0 mb-2">Mark joseph</span>
                            <span className="ms-2 mb-md-0 mb-2 d-inline-block enroll_flag">
                              <img src="./assets/images/country_flag.png" alt="country_flag" />
                            </span>
                            <div className="enroll_age d-inline-block ms-4 mb-md-0">
                              <span>Age : 8</span>
                            </div>
                          </div>
                        </div>
                        <a href="#" className="btn-theme px-3 py-2 d-inline-block">Progress Report</a>
                      </div>
                    </div>
                    <div className="card">
                      <div className="d-sm-flex align-items-center justify-content-between"> 
                        <div className="enroll_left d-md-flex align-items-center mb-sm-0 mb-3">
                          <div className="mb-md-0 mb-3">
                            <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                          </div>
                          <div className="ms-md-3  d-flex align-items-center flex-wrap">
                            <span className="mb-md-0 mb-2">Mark joseph</span>
                            <span className="ms-2 mb-md-0 mb-2 d-inline-block enroll_flag">
                              <img src="./assets/images/country_flag.png" alt="country_flag" />
                            </span>
                            <div className="enroll_age d-inline-block ms-4 mb-md-0">
                              <span>Age : 8</span>
                            </div>
                          </div>
                        </div>
                        <a href="#" className="btn-theme px-3 py-2 d-inline-block">Progress Report</a>
                      </div>
                    </div>
                    <div className="card">
                      <div className="d-sm-flex align-items-center justify-content-between"> 
                        <div className="enroll_left d-md-flex align-items-center mb-sm-0 mb-3">
                          <div className="mb-md-0 mb-3">
                            <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                          </div>
                          <div className="ms-md-3  d-flex align-items-center flex-wrap">
                            <span className="mb-md-0 mb-2">Mark joseph</span>
                            <span className="ms-2 mb-md-0 mb-2 d-inline-block enroll_flag">
                              <img src="./assets/images/country_flag.png" alt="country_flag" />
                            </span>
                            <div className="enroll_age d-inline-block ms-4 mb-md-0">
                              <span>Age : 8</span>
                            </div>
                          </div>
                        </div>
                        <a href="#" className="btn-theme px-3 py-2 d-inline-block">Progress Report</a>
                      </div>
                    </div>
                    <div className="card">
                      <div className="d-sm-flex align-items-center justify-content-between"> 
                        <div className="enroll_left d-md-flex align-items-center mb-sm-0 mb-3">
                          <div className="mb-md-0 mb-3">
                            <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                          </div>
                          <div className="ms-md-3  d-flex align-items-center flex-wrap">
                            <span className="mb-md-0 mb-2">Mark joseph</span>
                            <span className="ms-2 mb-md-0 mb-2 d-inline-block enroll_flag">
                              <img src="./assets/images/country_flag.png" alt="country_flag" />
                            </span>
                            <div className="enroll_age d-inline-block ms-4 mb-md-0">
                              <span>Age : 8</span>
                            </div>
                          </div>
                        </div>
                        <a href="#" className="btn-theme px-3 py-2 d-inline-block">Progress Report</a>
                      </div>
                    </div>
                    <div className="card">
                      <div className="d-sm-flex align-items-center justify-content-between"> 
                        <div className="enroll_left d-md-flex align-items-center mb-sm-0 mb-3">
                          <div className="mb-md-0 mb-3">
                            <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                          </div>
                          <div className="ms-md-3  d-flex align-items-center flex-wrap">
                            <span className="mb-md-0 mb-2">Mark joseph</span>
                            <span className="ms-2 mb-md-0 mb-2 d-inline-block enroll_flag">
                              <img src="./assets/images/country_flag.png" alt="country_flag" />
                            </span>
                            <div className="enroll_age d-inline-block ms-4 mb-md-0">
                              <span>Age : 8</span>
                            </div>
                          </div>
                        </div>
                        <a href="#" className="btn-theme px-3 py-2 d-inline-block">Progress Report</a>
                      </div>
                    </div>
                  </div>
                </div> 
              </div> 
              {/*end-content*/}
            </div> 
          </div>  
          {/*end*/}
        </div>
      </main>
      {/* <Helmet>
      <script src="./assets/js/custom_calendar.js"></script>
        
      </Helmet> */}
 <OFooter_Dashboard/>
    </>
  )
}
