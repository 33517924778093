import React from 'react';
import useRelativeTime from './getRelativeTime';

const RelativeTime = ({ dateString }) => {
console.log('dateString :', dateString);
  const relativeTime = useRelativeTime(dateString);
  console.log('relativeTime :', relativeTime);

  return <span className="text-purple fw-500 font-14">{relativeTime}</span>;
};

export default RelativeTime;