import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import Modals from 'react-modal';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { progress_report_learner_list, progress_report_teacher_side, teacher_progress_feedback } from '../../../api/apiHandler';
import { formatDate,TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/common.service';
import queryString from 'query-string';
import CryptoJS from 'crypto-js';
var key = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNfTmDXHWVkQWdanYOm');
var iv = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNf');

var COMMON_STYLE_FOR_POPUP = {
    content: {
        // backgroundColor: 'rgb(0 0 0 / 56%)',
        background: 'none',
        border: '0px',
    },
}

export default function ProgressReport() {
    const location = useLocation();
    const navigate = useNavigate();


    var after = useParams();
    if (location?.search) {
        const queryParams = new URLSearchParams(location?.search);
        const encryptedData = queryParams.get('data');
    
        // Define your key and IV (must be the same as used for encryption)
        // const key = CryptoJS.enc.Utf8.parse('your-key-here');
        // const iv = CryptoJS.enc.Utf8.parse('your-iv-here');
    
        // Decode and decrypt the data
        const decryptedData1 = CryptoJS.AES.decrypt(decodeURIComponent(encryptedData), key, { iv: iv }).toString(CryptoJS.enc.Utf8);
        console.log('decryptedData1 :', decryptedData1);
        const params = new URLSearchParams(decryptedData1);
        console.log('params :', params);
    
        id = {
          id: parseInt(params?.get('activityId'), 10),  // Assuming id is a number
          class: params?.get('typeClass'),
          type_class: params?.get('type'),
        };
        console.log('idxyz1 :', id);
        var edit_class_id = id?.id;
        // setDataType(id?.type)
      } else if (location?.state) {
        var id = location?.state;
        var edit_class_id = id?.id;
    } else {
        // const { id } = useParams();
        // var type = location?.search?.split("=")[1];
        // var id = after
        // id.class = type
        const queryParams = queryString.parse(location.search);

        // Extract specific query parameters
        const type = queryParams.type;
    
        const typeClass = queryParams?.type_class;
    
        var id = after
        id.class = type
        id.type_class = typeClass
    }

    const [modalIsOpen, setIsOpen] = useState(false);
    const [progressData, setProgressData] = useState([]);
    const [multiProgressData, setMultiProgressData] = useState([]);
    const [getId, setId] = useState({});



    const openProgress = (lId, uId) => {
        if(lId != null) {
            setId({
                type : "learner",
                learnerId : lId
            })
         }else if(uId != null){
            setId({
                type : "user",
                userId : uId
            })
         }

        var teacher_detail = {
            activity_id: id.id,
            activity_type: id.class,
        }
        if (uId != null) {
            teacher_detail.userId = uId;
        } else if (lId != null) {
            teacher_detail.learnerId = lId;
        }
        // console.log('teacher_detail :', teacher_detail);

        progress_report_teacher_side(teacher_detail).then((response) => {
            // console.log('openProgress :', response);
            // isLoader(false)
            if (response?.code == 1) {
                setProgressData(response?.data)
                setMultiProgressData(response?.data?.dates)
                setIsOpen(true)
            } else {
                setIsOpen(true)

                 
            }
        });
    }


    const [progressLearnerList, setProgressLearnerList] = useState([]);
    // console.log('progressLearnerList :', progressLearnerList);

    const ProgressLearnerList = async () => {
        try {
            let reqData = {
                activity_id: id.id,
                activity_type: id.class
            }
            const response = await progress_report_learner_list(reqData);
            // console.log('ProgressLearnerList :', response);
            if (response?.code == 1) {
                setProgressLearnerList(response?.data);
            } else {
                setProgressLearnerList([]);
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        ProgressLearnerList();
    }, []);

    const schema = yup.object().shape({

    });

    const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    let onSubmit = async (data) => {
        // console.log('dataonSubmit :', data);
        var data_len = data.total_data
        var homework_data = {
            activity_id: id.id,
            activity_type: id.class
        }
        if(getId?.type == "learner"){
            homework_data.learnerId = getId?.learnerId
        }else if(getId?.type == "user"){
            homework_data.userId = getId?.userId
        }
        var mainArr = []
        for (var i = 0; i < data_len; i++) {
            if (data[`fdata_` + i] != '') {
                var projectX = {
                    type_id: data[`type_id_` + i],
                    type: data[`type_` + i],
                    feedback: data[`fdata_` + i],
                }
                mainArr.push(projectX)
            }
        }
        homework_data.data = mainArr
        // console.log('homework_data :', homework_data);
        teacher_progress_feedback(homework_data).then((res) => {
            // console.log("teacher_progress_feedback", res)
            if (res.code == 1) {
                TOAST_SUCCESS(res.message);
                setIsOpen(false)
                reset()
            } else {
                TOAST_ERROR(res.message);
            }
        });
    }

    return (
        <>
              <div className="enroll_list progrss_report">
                {progressLearnerList?.map((pData, index) => (
                    <div className="card" key={index}>
                        <div className="d-sm-flex align-items-center justify-content-between">
                            <div className="enroll_left d-md-flex align-items-center mb-sm-0 mb-3">
                                <div className="mb-md-0 mb-3">
                                    <img src={pData.learner_profile || pData.user_profile} alt="kids-profile" />
                                </div>
                                <div className="ms-md-3 d-flex align-items-center flex-wrap">
                                    <span className="mb-md-0 mb-2">{pData.learner_name || pData.user_name}</span>
                                    <span className="ms-2 mb-md-0 mb-2 d-inline-block enroll_flag">
                                        {/* <img src="./assets/images/country_flag.png" alt="country_flag" /> */}
                                    </span>
                                    {pData.learner_age ?
                                        <div className="enroll_age d-inline-block ms-4 mb-md-0">
                                            <span>Age: {pData.learner_age}</span>
                                        </div>
                                        : <></>}
                                </div>
                            </div>
                            <a onClick={() => openProgress(pData.learner_id, pData.user_id)} className="btn-theme px-3 py-2 d-inline-block">Progress Report</a>
                        </div>
                    </div>
                ))}
            </div>

            {/* </div> */}
            <Modals isOpen={modalIsOpen} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal" >

                {/* <div className="modal fade" id="progrss-report" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Progress Report</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setIsOpen(false)} />
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="modal-body">

                                <div className="mb-2 fw-600">
                                    <span>Student’s Name:</span><span className>{progressData.full_name}</span>
                                </div>
                                <div className="fw-600 mb-4">
                                    <span>Program Name:</span><span> {progressData.activity_title}</span>
                                </div>
                                <div className="table-responsive-xl">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Date Creation</th>
                                                <th scope="col">Lesson Topic</th>
                                                <th scope="col">Homework</th>
                                                <th scope="col">Assessment</th>
                                                <th scope="col">Quiz</th>
                                                <th scope="col">Discussion</th>
                                                <th scope="col">Teacher’s Name</th>
                                                <th scope="col">Teacher Feedback</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <input type="hidden" name={`total_data`} {...register(`total_data`)} value={multiProgressData.length} />
                                            {multiProgressData?.map((tableData, index) => {
                                                console.log(tableData)
                                                return (
                                                    <tr>
                                                        <th style={{ fontWeight: "normal" }}>{formatDate(tableData?.date, "DD/MM/YYYY")}</th>
                                                        <td >{tableData?.lesson_data}</td>
                                                        <td >{tableData?.homework_data}</td>
                                                        <td >{tableData?.assessment_data}</td>
                                                        <td >{tableData?.quiz_data}</td>
                                                        <td ></td>
                                                        <td ></td>
                                                        <td>
                                                            {tableData?.lesson_data && (
                                                                <>
                                                                    <input type="hidden" name={`type_id_${index}`} {...register(`type_id_${index}`)} value={tableData?.lession_id} />
                                                                    <input type="hidden" name={`type_${index}`} {...register(`type_${index}`)} value="lesson" />
                                                                    <textarea rows="3" cols="12" maxLength={1000} {...register(`fdata_${index}`)} name={`fdata_${index}`} defaultValue={tableData?.feedback || ""} />
                                                                </>
                                                            )}
                                                            {tableData?.homework_data && (
                                                                <>
                                                                    <input type="hidden" name={`type_id_${index}`} {...register(`type_id_${index}`)} value={tableData?.homework_id} />
                                                                    <input type="hidden" name={`type_${index}`} {...register(`type_${index}`)} value="homework" />
                                                                    <textarea rows="3" cols="12" maxLength={1000} {...register(`fdata_${index}`)} name={`fdata_${index}`} defaultValue={tableData?.feedback || ""}/>
                                                                </>
                                                            )}
                                                            {tableData?.assessment_data && (
                                                                <>
                                                                    <input type="hidden" name={`type_id_${index}`} {...register(`type_id_${index}`)} value={tableData?.assessment_id} />
                                                                    <input type="hidden" name={`type_${index}`} {...register(`type_${index}`)} value="assessment" />
                                                                    <textarea rows="3" cols="12" maxLength={1000} {...register(`fdata_${index}`)} name={`fdata_${index}`} defaultValue={tableData?.feedback || ""}/>
                                                                </>
                                                            )}
                                                            {tableData?.quiz_data && (
                                                                <>
                                                                    <input type="hidden" name={`type_id_${index}`} {...register(`type_id_${index}`)} value={tableData?.quiz_id} />
                                                                    <input type="hidden" name={`type_${index}`} {...register(`type_${index}`)} value="quiz" />
                                                                    <textarea rows="3" cols="12" maxLength={1000} {...register(`fdata_${index}`)} name={`fdata_${index}`} defaultValue={tableData?.feedback || ""}/>
                                                                </>
                                                            )}
                                                            {/* <textarea className="form-control" {...register("feedback_data")} name="feedback_data" rows={7} placeholder /> */}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            {/* <tr>
                                            <th scope="row">2/1/2023</th>
                                            <td />
                                            <td />
                                            <td />s
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                           </tr>
                                        <tr>
                                            <th scope="row">3/1/2023</th>
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                        </tr> */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn-theme" data-bs-dismiss="modal" onClick={() => setIsOpen(false)}>Close</button>
                                <button type="submit" className="btn-theme bg-yellow">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
                {/* </div> */}
            </Modals>
        </>
    )
}
