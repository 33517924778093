import React, { useEffect } from 'react'
import $ from 'jquery';
export default function JqueryLoad() {
    useEffect(() => {
        // console.log("Effect is running!");
        const mobileScreen = window.matchMedia("(max-width: 990px)");
    
        $(".dashboard-nav-dropdown-toggle").click(function () {
          $(this).closest(".dashboard-nav-dropdown")
            .toggleClass("show")
            .find(".dashboard-nav-dropdown")
            .removeClass("show");
          $(this).parent()
            .siblings()
            .removeClass("show");
        });
    
        $(".menu-toggle").click(function () {
          if (mobileScreen.matches) {
            $(".dashboard-nav").toggleClass("mobile-show");
          } else {
            $(".dashboard").toggleClass("dashboard-compact");
          }
        });
    
        // Clean up event listeners when component unmounts
        return () => {
          $(".dashboard-nav-dropdown-toggle").off('click');
          $(".menu-toggle").off('click');
        };
      }, []);


  return (
    <>
      
    </>
  )
}
