// import React, { useEffect, useState } from 'react';
// import { Helmet } from 'react-helmet';
// import { LocalUser, RemoteUser, useIsConnected, useJoin, useLocalMicrophoneTrack, useLocalCameraTrack, usePublish, useRemoteUsers } from 'agora-rtc-react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { joinedMeetingUserList } from '../../../api/apiHandler'
// import { useSelector, useDispatch } from 'react-redux';
// import { getUserDetailsApiCall } from '../../../Store/slices/MasterSlice';
// import io from "socket.io-client";
// import { SOCKET_URL } from '../../../Config';


// const appId = "97edeb3090ee499481463e85a90f42d8";

// const StartMeeting = () => {
//     const location = useLocation();
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const { userDetails: { data: userDetails } } = useSelector((state) => state.master);
//     console.log('userDetails :', userDetails);

//     const token = location?.state?.token;
//     const channel = location?.state?.channel;
//     const uid = parseInt(location?.state?.uid);
//     const classRoomData = location?.state?.classRoomData;
//     console.log('classRoomData :', classRoomData);
//     const callData = location?.state?.callData;
//     const groupData = location?.state?.groupData;
//     const receiverAgoraUid = location?.state?.receiverAgoraUid;

//     const [time, setTime] = useState(30);
//     const [calling, setCalling] = useState(true);
//     const isConnected = useIsConnected();

//     useJoin({ appid: appId, channel, token, uid }, calling);

//     const [micOn, setMic] = useState(true);
//     const [cameraOn, setCamera] = useState(true);
//     const [receiverDetailed, setReceiverDetailed] = useState(null);
//     const [getReceiverUserList, setGetReceiverUserList] = useState([]);
//     const [requestList, setRequestList] = useState([]);
//     const [pendingRequests, setPendingRequests] = useState([]);
//     const [socket, setSocket] = useState(null);
//     const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
//     const { localCameraTrack } = useLocalCameraTrack(cameraOn);
//     usePublish([localMicrophoneTrack, localCameraTrack]);

//     const remoteUsers = useRemoteUsers();

//     useEffect(() => {
//         const socketInstance = io(SOCKET_URL);
//         setSocket(socketInstance);
//         socketInstance.on('join_request', handleJoinRequest);
//         socketInstance.on('request_response', handleRequestResponse);

//         return () => {
//             socketInstance.off('join_request', handleJoinRequest);
//             socketInstance.off('request_response', handleRequestResponse);
//         };
//     }, []);

//     const handleJoinRequest = (request) => {
//         setPendingRequests((prev) => [...prev, request]);
//     };

//     const handleRequestResponse = (response) => {
//         setRequestList((prev) => prev.filter((req) => req.agora_uid !== response.agora_uid));
//     };

//     const handleAcceptRequest = (request) => {
//         const messageObj = {
//             type: 'accept',
//             channel: request.channel,
//             agora_uid: request.agora_uid,
//         };
//         socket.emit('request_response', messageObj);
//         setPendingRequests((prev) => prev.filter((req) => req.agora_uid !== request.agora_uid));
//     };

//     const handleRejectRequest = (request) => {
//         const messageObj = {
//             type: 'reject',
//             channel: request.channel,
//             agora_uid: request.agora_uid,
//         };
//         socket.emit('request_response', messageObj);
//         setPendingRequests((prev) => prev.filter((req) => req.agora_uid !== request.agora_uid));
//     };

//     useEffect(() => {
//         if (remoteUsers?.length > 0) {
//             handleJoinedUserList();
//         }
//     }, [remoteUsers]);

//     const handleGetUserDetailsApiCall = () => {
//         dispatch(getUserDetailsApiCall({ userId: localStorage.getItem("Tid") }));
//     }

//     useEffect(() => {
//         if (!userDetails && localStorage.getItem("Tid")) {
//             handleGetUserDetailsApiCall();
//         }
//     }, [userDetails]);

//     const handleJoinedUserList = async () => {
//         let joinedMeetingUserListSendData = {
//             agora_uids: remoteUsers?.map(item => item?.uid),
//         }
//         const joinedMeetingUserListResponse = await joinedMeetingUserList(joinedMeetingUserListSendData);
//         if (joinedMeetingUserListResponse?.code === "1") {
//             setGetReceiverUserList(joinedMeetingUserListResponse?.data);
//         }
//     }

//     const callSize = () => {
//         switch (remoteUsers?.length) {
//             case 1:
//                 return "col-xl-6 col-lg-6 col-sm-6 p-1"
//             case 2:
//                 return "col-xl-4 col-lg-4 col-sm-6 p-1"
//             case 3:
//                 return "col-xl-6 col-lg-6 col-sm-6 p-1"
//             case 4:
//                 return "col-xl-4 col-lg-4 col-sm-6 p-1"
//             case 5:
//                 return "col-xl-4 col-lg-4 col-sm-6 p-1"
//             case 6:
//                 return "col-xl-3 col-lg-3 col-sm-6 p-1"
//             case 7:
//                 return "col-xl-3 col-lg-3 col-sm-6 p-1"
//             case 8:
//                 return "col-xl-3 col-lg-2 col-sm-6 p-1"
//             case 9:
//                 return "col-xl-3 col-lg-2 col-sm-6 p-1"
//             case 10:
//                 return "col-xl-2 col-lg-2 col-sm-6 p-1"
//             case 11:
//                 return "col-xl-2 col-lg-2 col-sm-6 p-1"
//             case 12:
//                 return "col-xl-2 col-lg-2 col-sm-6 p-1"
//             default:
//                 return "col-md-12 p-1"
//         }
//     };

//     const callVideoHeight = () => {
//         const length = remoteUsers?.length;
//         if (length === 1 || length === 2) {
//             return "height84";
//         } else if (length >= 3 && length <= 12) {
//             return "height42";
//         } else {
//             return "height42";
//         }
//     };

//     const handleCallMissed = async () => {
//         // let { code: callStatusCode, data: callStatusData } = await API.callStatusUpdateApi({ call_history_id: callData?.id, type: 'call_missed' });
//         // if (callStatusCode === '1') {
//         //     let callType = ''
//         //     if (callStatusData?.call_type === 'S') {
//         //         if (callStatusData?.type === 'voice') {
//         //             callType = 'single_voice_call'
//         //         } else {
//         //             callType = 'single_video_call'
//         //         }
//         //     } else {
//         //         if (callStatusData?.type === 'voice') {
//         //             callType = 'group_voice_call'
//         //         } else {
//         //             callType = 'group_video_call'
//         //         }
//         //     }
//         //     const messageObj = {
//         //         call_history_id: callStatusData?.id,
//         //         call_type: `${callType}_end`,
//         //         channel: channel,
//         //         receiver_id: receiverDetailed?.user_id,
//         //         receiver_type: receiverDetailed?.user_type,
//         //         sender_agora_id: userDetails?.agora_uid,
//         //         sender_id: userDetails?.id,
//         //         sender_type: userRoleType,
//         //         chat_room_id: callStatusData?.chat_room_id,
//         //     };
//         //     setCalling((a) => !a);
//         //     pauseSound();
//         //     socket.emit('call_notification', JSON.stringify(messageObj));
//         navigate(-1);
//         // }
//     }

//     const handleCallCut = async () => {
//         // let { code: callStatusCode, data: callStatusData } = await API.callStatusUpdateApi({ call_history_id: callData?.id, type: 'call_end' });
//         // console.log('callStatusData :', callStatusData);
//         // if (callStatusCode === '1') {
//         //     let callType = ''
//         //     if (callStatusData?.call_type === 'S') {
//         //         if (callStatusData?.type === 'voice') {
//         //             callType = 'single_voice_call'
//         //         } else {
//         //             callType = 'single_video_call'
//         //         }
//         //     } else {
//         //         if (callStatusData?.type === 'voice') {
//         //             callType = 'group_voice_call'
//         //         } else {
//         //             callType = 'group_video_call'
//         //         }
//         //     }
//         //     setCalling((a) => !a);
//         //     if (remoteUsers?.length === 1) {
//         // const messageObj = {
//         //     call_history_id: callStatusData?.id,
//         //     call_type: `${callType}_end`,
//         //     channel: channel,
//         //     receiver_id: receiverDetailed?.user_id,
//         //     receiver_type: receiverDetailed?.user_type,
//         //     sender_agora_id: userDetails?.agora_uid,
//         //     sender_id: userDetails?.id,
//         //     sender_type: userRoleType,
//         //     chat_room_id: callStatusData?.chat_room_id,
//         //     agora_ids: (callData?.agora_ids || callData?.agora_uids).concat(',' + callData?.sender_agora_uid),
//         // };
//         // console.log('messageObj :', messageObj);
//         // socket.emit('call_notification', JSON.stringify(messageObj));
//         //     }
//         navigate(-1);
//         // }
//     }

//     return (
//         <>
//             <Helmet>
//                 <style>
//                     {`
//                         /* styles.css */
//                         .agora-btn {
//                             background-color: #570861 !important;
//                             border: none;
//                         }

//                         .agora-btn svg {
//                             fill: #ffffff;
//                         }
//                     `}
//                 </style>
//             </Helmet>
//             {isConnected ?
//                 (<>
//                     <main className="main-content-block">
//                         <section className="videocall-sec-one">
//                             <div className="container-fluid">
//                                 <div className="row">
//                                     {/* <div className="col-xl-2 col-lg-4 col-sm-6"> */}
//                                     <div className={callSize()}>
//                                         <div className={`image-contaie-video active ${callVideoHeight()}`}>
//                                             <LocalUser audioTrack={localMicrophoneTrack} cameraOn={cameraOn} micOn={micOn} videoTrack={localCameraTrack} className="z-3 rounded-4" cover={userDetails?.profile_image}>
//                                                 <div className="badge-chat">
//                                                     {!micOn && (
//                                                         <img src={process.env.PUBLIC_URL + "/assets/images/svg/mice-off-red.svg"} alt="" />
//                                                     )}
//                                                     <p>{userDetails?.full_name}</p>
//                                                 </div>
//                                             </LocalUser>
//                                         </div>
//                                     </div>
//                                     {remoteUsers?.map((user) => {
//                                         let userData = getReceiverUserList?.filter(item => item?.agora_uid == user?.uid)?.[0];
//                                         return (
//                                             <div className={callSize()} key={user.uid}>
//                                                 <div className={`image-contaie-video active ${callVideoHeight()}`}>
//                                                     <RemoteUser cover={userData?.profile_image} user={user}>
//                                                         <div className="badge-chat">
//                                                             {user?._audio_muted_ && (
//                                                                 <img src={process.env.PUBLIC_URL + "/assets/images/svg/mice-off-red.svg"} alt="" />
//                                                             )}
//                                                             <p>{userData?.full_name}</p>
//                                                         </div>
//                                                     </RemoteUser>
//                                                 </div>
//                                             </div>
//                                         )
//                                     })}
//                                 </div>
//                                 <div className='row'>
//                                     <div className="col-12 p-0">
//                                         <div className="videos-btns-container">
//                                             {/* <button className="btn-videocall btn-refresh"></button> */}
//                                             <button className={`btn-videocall ${!cameraOn ? 'btn-vidoo-videocall-mute' : 'btn-vidoo-videocall'}`} onClick={() => setCamera((a) => !a)}></button>
//                                             {/* <button className="btn-profile-2user-videocall btn-videocall" onClick={() => dispatch(setOffcanvasStatus({ canvasType: CALL_MEMBER_CANVAS, isOpen: true, data: { callData, joinedAgoraUid: [...remoteUsers?.map(item => item?.uid), userDetails?.agora_uid] } }))}><span className="total-vallno">{remoteUsers?.length + 1}</span></button> */}
//                                             <button className={`btn-videocall ${!micOn ? 'btn-microphone-mute' : 'btn-microphone-2'}`} onClick={() => setMic((a) => !a)}></button>
//                                             <button className="cancellcall" onClick={handleCallCut}></button>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </section>
//                     </main>
//                 </>)
//                 : // call dialing
//                 (<>
//                     <section className="videocall-signle-sec-one">
//                         <div className="container-fluid">
//                             <div className="row">
//                                 <div className="col-12 px-0">
//                                     <div className="image-contaiener-video-2 gradient2 d-flex justify-content-center align-items-center">
//                                         <div className="calling-box">
//                                             <div className="calling-img-container">
//                                                 <img src={callData?.call_type === 'S' ? receiverDetailed?.profile_image_link : groupData?.group_image_link} alt="" />
//                                             </div>
//                                             <div className="diller-info_box">
//                                                 <p className="diller-name">{callData?.call_type === 'S' ? receiverDetailed?.user_name : groupData?.group_name}</p>
//                                                 {callData?.call_type === 'S' && (
//                                                     <p className="diller-number">{receiverDetailed?.country_code + ' ' + receiverDetailed?.mobile_number}</p>
//                                                 )}
//                                                 <p className="diller-number">Dialling</p>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 <div className="col-12 p-0">
//                                     <div className="videos-btns-container">
//                                         <button className={`btn-videocall ${!cameraOn ? 'off' : ''}`} onClick={() => setCamera((a) => !a)}></button>
//                                         <button className={`btn-speeker btn-videocall`}></button>
//                                         <button className={`btn-videocall ${!micOn ? 'btn-microphone-mute' : 'btn-microphone-2'}`} onClick={() => setMic((a) => !a)}></button>
//                                         <button className="cancellcall" onClick={handleCallMissed}></button>
//                                     </div>
//                                 </div>

//                                 <div className="gradient01"></div>
//                             </div>
//                         </div>
//                     </section>
//                 </>)}
//         </>
//     );
// };

// export default StartMeeting;
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { LocalUser, RemoteUser, useIsConnected, useJoin, useLocalMicrophoneTrack, useLocalCameraTrack, usePublish, useRemoteUsers, useLocalScreenTrack, useRTCClient } from 'agora-rtc-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { agora_room_already_exists, agora_room_chat_history, agora_room_chat_list, end_meeting, generateAgoraToken, join_meeting, joinedMeetingUserList, kick_off_from_meeting, start_recording, stop_recording } from '../../../api/apiHandler';
import { useSelector, useDispatch } from 'react-redux';
import { getUserDetailsApiCall } from '../../../Store/slices/MasterSlice';
import io from "socket.io-client";
import { SOCKET_URL } from '../../../Config';
import AgoraRTC from "agora-rtc-sdk-ng";
import { TOAST_ERROR, convertToBase64 } from '../../../utils/common.service';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useChat, useChatTeacher } from '../../Common/useChatTeacher';
import moment from 'moment';
import Modals from 'react-modal';


const appId = "97edeb3090ee499481463e85a90f42d8";


const TStartMeeting = () => {
    const location = useLocation()

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userDetails: { data: userDetails } } = useSelector((state) => state.master);
    console.log('userDetails :', userDetails);
    const token = location?.state?.token;

    console.log('token :', token);
    const channel = location?.state?.channel;
    const classRoomData = location?.state?.classRoomData;
    const title = location?.state?.title;
    const uid = parseInt(location?.state?.uid);

    console.log('classRoomData :', classRoomData);
    const callData = location?.state?.callData;
    const groupData = location?.state?.groupData;
    const USER_TYPE = localStorage.getItem("type") || localStorage.getItem("userType");

    const [calling, setCalling] = useState(true);
    const isConnected = useIsConnected();

    useJoin({ appid: appId, channel, token, uid }, calling);
    const [getReceiverUserList, setGetReceiverUserList] = useState([]);
    console.log('getReceiverUserList :', getReceiverUserList);
    const [pendingRequests, setPendingRequests] = useState(() => {
        const savedPendingRequests = localStorage.getItem('pendingRequests');
        return savedPendingRequests ? JSON.parse(savedPendingRequests) : [];
    });
    console.log('pendingRequests :', pendingRequests);
    const [acceptedUsers, setAcceptedUsers] = useState(() => {
        const savedAcceptedUsers = localStorage.getItem('acceptedUsers');
        return savedAcceptedUsers ? JSON.parse(savedAcceptedUsers) : [];
    });
    const [joinedUsers, setJoinedUsers] = useState(() => {
        const savedJoinedUsers = localStorage.getItem('joinedUsers');
        return savedJoinedUsers ? JSON.parse(savedJoinedUsers) : [];
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const openModal = (content) => {
        setModalContent(content);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
    };
    const [socket, setSocket] = useState(null);
    const [recordingUid, setRecordingUid] = useState(null);
    const [recordingDetails, setRecordingDetails] = useState(null);
    const [recordingCalling, setRecordingCalling] = useState(false);
    const [recordingToken, setRecordingToken] = useState();

    const [isHost, setIsHost] = useState(USER_TYPE == 'teacher' || USER_TYPE == "org_teacher");

    const client = useRTCClient();
    const { localMicrophoneTrack } = useLocalMicrophoneTrack();
    const { localCameraTrack } = useLocalCameraTrack();
    const [micOn, setMic] = useState(true);
    const [cameraOn, setCamera] = useState(true);
    const [screenOn, setScreenOn] = useState(false);
    const [screenTrack, setScreenTrack] = useState(null);
    const [isPublishing, setIsPublishing] = useState(false);
    const [uniqueId, setUniqueId] = useState();
    const [screenClient, setScreenClient] = useState(null);

    usePublish([localMicrophoneTrack, localCameraTrack, screenTrack].filter(Boolean));

    const handleScreenShareToggle = async () => {
        if (isPublishing) {
            console.log("A publish operation is already in progress. Please wait.");
            return;
        }

        setIsPublishing(true);

        try {
            if (!screenOn) {
                console.log("Starting screen share...");

                const tempScreenTrack = await AgoraRTC.createScreenVideoTrack(
                    {
                        encoderConfig: "1080p"
                    },
                    "auto"
                );

                console.log("Screen track created. Unpublishing camera track...");
                if (localCameraTrack) {
                    await client.unpublish(localCameraTrack);
                }

                console.log("Publishing screen track...");
                try {
               
                    const screenClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

          
                    await screenClient.join(appId, channel, token, uid);

         
                    await screenClient.publish(tempScreenTrack);

                
                    setScreenTrack(tempScreenTrack);

                    setScreenOn(true);

         
                    tempScreenTrack.on('track-ended', async () => {
                        console.log("Screen sharing ended by user.");
                        await handleStopScreenShare(screenClient);
                    });

                    console.log("Screen share started successfully.");
                } catch (publishError) {
                    console.error("Error publishing screen track:", publishError);
                    throw publishError;
                }
            } else {
                await handleStopScreenShare();
            }
        } catch (error) {
            console.error("Error in screen sharing:", error);
            setScreenOn(false);
            setScreenTrack(null);
        } finally {
            setIsPublishing(false);
        }
    };
    const handleStopScreenShare = async () => {
        try {
            console.log('screenTrack :', screenTrack);
            console.log('screenClient :', screenClient);
            if (screenTrack) {
            alert('1')
                console.log('Stopping screen share...');
                
            
                if (screenClient.connectionState === 'CONNECTED') {
                    await screenClient.unpublish(screenTrack);
                    await screenClient.leave();
                }
    
            
                screenTrack.close();
                
               
                if (localCameraTrack) {
                    await client.publish(localCameraTrack);
                }
    
                setScreenTrack(null);
                setScreenOn(false);
                setScreenClient(null);
                
                console.log('Screen share stopped successfully');
            }
        } catch (error) {
            console.error("Error stopping screen share:", error);
     
            setScreenTrack(null);
            setScreenOn(false);
            setScreenClient(null);
        }
    };
    
    
    useEffect(() => {
        const handleUserPublished = async (user, mediaType) => {
            await client.subscribe(user, mediaType);
        };
    
        const handleUserUnpublished = (user) => {
            // Handle unpublished users
        };
    
        client.on("user-published", handleUserPublished);
        client.on("user-unpublished", handleUserUnpublished);
    
        return () => {
            client.off("user-published", handleUserPublished);
            client.off("user-unpublished", handleUserUnpublished);
        };
    }, [client]);
    
    useEffect(() => {
        return () => {
            if (screenTrack) {
                screenTrack.close();
            }
            if (screenClient) {
                screenClient.leave();
            }
        };
    }, [screenTrack, screenClient]);
   
    const remoteUsers = useRemoteUsers();
    console.log('remoteUsers :', remoteUsers);

  

    const [hostInfo, setHostInfo] = useState(null);

    useEffect(() => {
        const socketInstance = io(SOCKET_URL);
        setSocket(socketInstance);

        const storedJoinedUsers = JSON.parse(localStorage.getItem('joinedUsers') || '[]');

        const storedHost = storedJoinedUsers.find(user => user.isHost);

        if (isHost) {
            const newHostInfo = {
                agora_uid: uid,
                name: userDetails?.full_name,
                isHost: true,
                user_type: localStorage.getItem('type'),
                profile_image: userDetails?.profile_image

            };
            setHostInfo(newHostInfo);

            const updatedJoinedUsers = storedHost
                ? storedJoinedUsers.map(user => user.isHost ? newHostInfo : user)
                : [newHostInfo, ...storedJoinedUsers];

            setJoinedUsers(updatedJoinedUsers);
            localStorage.setItem('joinedUsers', JSON.stringify(updatedJoinedUsers));

            socketInstance.emit('host_joined', newHostInfo);
        } else if (storedHost) {
            // If not host but a host exists in storage, use that
            setHostInfo(storedHost);
        }

        // Listen for join requests from learners
        socketInstance.on('join_request_teacher', (request) => {
            console.log('Join Request Received:', request);

            // Defensive checks to avoid undefined errors
            if (request && request.request && request.request.agora_uid) {
                setPendingRequests((prev) => {
                    if (!prev.some(r => r.agora_uid == request.request.agora_uid)) {
                        const updatedRequests = [...prev, request.request];
                        localStorage.setItem('pendingRequests', JSON.stringify(updatedRequests));
                        return updatedRequests;
                    }
                    return prev;
                });
            } else {
                console.error('Invalid join request received:', request);
            }
        });

        return () => {
            socketInstance.off('join_request_teacher');
            socketInstance.disconnect();
        };
    }, [isHost, uid, userDetails, pendingRequests]);

    useEffect(() => {
        const socketInstance = io(SOCKET_URL);
        setSocket(socketInstance);

        // Listen for call cut event
        socketInstance.on('call_cut_status', (message) => {
            const { agora_uid } = JSON.parse(message?.response);
            console.log('Call cut message received:', agora_uid);

            setJoinedUsers(prev => {
                const updatedJoinedUsers = prev.filter(user => user.agora_uid !== agora_uid);
                localStorage.setItem('joinedUsers', JSON.stringify(updatedJoinedUsers));
                return updatedJoinedUsers;
            });

            setPendingRequests(prev => {
                const updatedPendingRequests = prev.filter(req => req.agora_uid !== agora_uid);
                localStorage.setItem('pendingRequests', JSON.stringify(updatedPendingRequests));
                return updatedPendingRequests;
            });

            setAcceptedUsers(prev => {
                const updatedAcceptedUsers = prev.filter(id => id !== agora_uid);
                localStorage.setItem('acceptedUsers', JSON.stringify(updatedAcceptedUsers));
                return updatedAcceptedUsers;
            });

            // Optionally notify users or update UI
        });

        return () => {
            socketInstance.off('call_cut_status');
            socketInstance.disconnect();
        };
    }, []);

    const handleAcceptRequest = (request, status) => {
        console.log('request :', request);

        var rules = {
            learner_id: request?.user_id,
            user_type: request?.user_type,
            class_meeting_id: classRoomData?.id,
            agora_uid: request?.agora_uid,
        }
        join_meeting(rules).then((response) => {
            console.log('response :', response);
            if (response?.code == 1) {
                if (!acceptedUsers.includes(request.agora_uid)) {
                    socket.emit('accept_join_request', {
                        agora_uid: request?.agora_uid,
                        status,
                        hostInfo: hostInfo
                    });
                    setAcceptedUsers(prev => {
                        const updatedAcceptedUsers = [...prev, request?.agora_uid];
                        localStorage.setItem('acceptedUsers', JSON.stringify(updatedAcceptedUsers));
                        handleJoinedUserList(updatedAcceptedUsers);
                        return updatedAcceptedUsers;
                    });

                    setPendingRequests(prev => {
                        const updatedRequests = prev.filter(req => req?.agora_uid !== request?.agora_uid);
                        localStorage.setItem('pendingRequests', JSON.stringify(updatedRequests));
                        return updatedRequests;
                    });

                    setJoinedUsers(prev => {
                        const newUser = { ...request, isHost: false };
                        const updatedJoinedUsers = [...prev, newUser];
                        localStorage.setItem('joinedUsers', JSON.stringify(updatedJoinedUsers));
                        return updatedJoinedUsers;
                    });
                }
            } else {
                TOAST_ERROR(response?.message)
            }
        })

    };

    const handleRejectRequest = (request, status) => {
        socket.emit('accept_join_request', { agora_uid: request.agora_uid, status });

        setPendingRequests(prev => {
            // Filter out the request based on agora_uid
            const updatedRequests = prev.filter(req => req?.agora_uid !== req?.agora_uid);
            localStorage.setItem('pendingRequests', JSON.stringify(updatedRequests));
            console.log('Pending Requests after rejection:', updatedRequests); // Debugging
            return updatedRequests;
        });
    };

    const handleRemoveUser = (user) => {

        var rules = {
            class_meeting_id: classRoomData?.id,
            is_user_kicked: 1,
            agora_uid: user.agora_uid
        }
        kick_off_from_meeting(rules).then((response) => {
            if (response?.code == 1) {
                socket.emit('remove_user', { agora_uid: user.agora_uid, message: "Teacher removed from the call" });

                setJoinedUsers(prev => {
                    const updatedJoinedUsers = prev.filter(u => u.agora_uid !== user.agora_uid);
                    localStorage.setItem('joinedUsers', JSON.stringify(updatedJoinedUsers));
                    return updatedJoinedUsers;
                });

                setPendingRequests(prev => {
                    const updatedPendingRequests = prev.filter(req => req.agora_uid !== user.agora_uid);
                    localStorage.setItem('pendingRequests', JSON.stringify(updatedPendingRequests));
                    return updatedPendingRequests;
                });

                setAcceptedUsers(prev => {
                    const updatedAcceptedUsers = prev.filter(id => id !== user.agora_uid);
                    localStorage.setItem('acceptedUsers', JSON.stringify(updatedAcceptedUsers));
                    return updatedAcceptedUsers;
                });
            } else {
                TOAST_ERROR(response?.message)
            }
        })
    };



    useEffect(() => {
        const savedAcceptedUsers = localStorage.getItem('acceptedUsers');
        if (savedAcceptedUsers) {
            handleJoinedUserList(JSON.parse(savedAcceptedUsers));
        }
    }, [remoteUsers, pendingRequests]);

    const handleGetUserDetailsApiCall = () => {
        dispatch(getUserDetailsApiCall({ userId: localStorage.getItem("Tid") }));
    }

    useEffect(() => {
        if (!userDetails && localStorage.getItem("Tid")) {
            handleGetUserDetailsApiCall();
        }
    }, [userDetails]);

    const handleJoinedUserList = async (updatedAcceptedUsers = acceptedUsers) => {
        let joinedMeetingUserListSendData = {
            agora_uids: remoteUsers
                ?.filter(user => updatedAcceptedUsers.includes(user?.uid.toString()))
                .map(item => item.uid.toString()),
        };

        const joinedMeetingUserListResponse = await joinedMeetingUserList(joinedMeetingUserListSendData);
        if (joinedMeetingUserListResponse?.code === "1") {
            setGetReceiverUserList(joinedMeetingUserListResponse?.data);
        }
    };

    const callSize = () => {
        switch (remoteUsers?.length) {
            case 1:
                return "col-xl-6 col-lg-6 col-sm-6 p-1"
            case 2:
                return "col-xl-4 col-lg-4 col-sm-6 p-1"
            case 3:
                return "col-xl-6 col-lg-6 col-sm-6 p-1"
            case 4:
                return "col-xl-4 col-lg-4 col-sm-6 p-1"
            case 5:
                return "col-xl-4 col-lg-4 col-sm-6 p-1"
            case 6:
                return "col-xl-3 col-lg-3 col-sm-6 p-1"
            case 7:
                return "col-xl-3 col-lg-3 col-sm-6 p-1"
            case 8:
                return "col-xl-3 col-lg-2 col-sm-6 p-1"
            case 9:
                return "col-xl-3 col-lg-2 col-sm-6 p-1"
            case 10:
                return "col-xl-2 col-lg-2 col-sm-6 p-1"
            case 11:
                return "col-xl-2 col-lg-2 col-sm-6 p-1"
            case 12:
                return "col-xl-2 col-lg-2 col-sm-6 p-1"
            default:
                return "col-md-12 p-1"
        }
    };

    const sortUsers = (users) => {
        return users.sort((a, b) => {
            if (a.isHost) return -1;
            if (b.isHost) return 1;
            return 0;
        });
    };

    const callVideoHeight = () => {
        const length = remoteUsers?.length;
        if (length === 1 || length === 2) {
            return "height84";
        } else if (length >= 3 && length <= 12) {
            return "height42";
        } else {
            return "height42";
        }
    };


    useJoin({
        appId: appId,
        channel: channel,
        token: recordingToken,
        uid: recordingUid,
    }, recordingCalling);

    const startRecording = async () => {
        try {
            const recording_uid = Math.floor(Math.random() * 100000);
            setRecordingUid(recording_uid);
            const send_data = {
                agora_uid: recording_uid,
                class_name: title,
                channel_name: channel
            };

            const tokenResponse = await generateAgoraToken(send_data);
            console.log('Token Response:', tokenResponse);

            if (tokenResponse?.code === "1" && tokenResponse?.data?.token) {
                const rToken = tokenResponse?.data?.token;
                setRecordingToken(rToken);
                setRecordingCalling(true);


                const response = await start_recording({
                    token: rToken,
                    channel_name: channel,
                    agora_uid: uid,
                    recording_uid: recording_uid
                });
                console.log('responseStart :', response);

                if (response?.code == 1) {
                    setRecordingDetails({
                        ...response?.data,
                        recording_uid
                    });

                } else {
                    throw new Error("Failed to start recording");
                }
            } else {
                throw new Error("Invalid token response");
            }
        } catch (error) {
            console.error("Error in startRecording:", error);

            setRecordingCalling(false);
            setRecordingUid(null);
            setRecordingToken(null);
            setRecordingDetails(null);
        }
    };

    const stopRecording = async () => {
        if (!recordingUid || !recordingDetails) {
            console.error("No active recording found");
            return;
        }

        try {
            const response = await stop_recording({
                channel_name: recordingDetails?.cname,
                sid: recordingDetails?.sid,
                resource_id: recordingDetails?.resourceId,
                class_meeting_id: classRoomData?.id,
                recording_uid: recordingUid
            });

            if (response?.code == 1) {

                setRecordingCalling(false);
                setRecordingDetails(null);
                setRecordingUid(null);
                setRecordingToken(null);
                console.log("Recording stopped successfully");
            } else {
                throw new Error("Failed to stop recording");
            }
        } catch (error) {
            console.error("Error stopping recording:", error);
        }
    };


    useEffect(() => {
        return () => {
            if (recordingUid) {
                stopRecording();
            }
        };
    }, []);



    const handleCallCut = async () => {
        var rules = {
            class_meeting_id: classRoomData?.id,
            type: 'all'
        }

        end_meeting(rules).then((response) => {
            if (response?.code == 1) {
                socket.emit('call_ended', { message: "Meeting has been ended by the host." });
                localStorage.removeItem('acceptedUsers');
                localStorage.removeItem('pendingRequests');
                localStorage.removeItem('joinedUsers');
                navigate(-1);
            } else {
                console.log('error in end meeting');
            }
        })

    };

    const filteredPendingRequests = pendingRequests.filter(
        request => !joinedUsers.some(joinedUser => joinedUser.agora_uid == request.agora_uid)
    );
    const {
        roomId,
        chatHistory,
        message,
        setMessage,
        image,
        imageVal,
        scrollToBottomRef,
        scrollToTopRef,
        inputRef,
        sendMessage,
        handleMessage,
        deleteImage,
        showMessages,
        downloadFile,
        checkRoomExist,
        handleImageClick
    } = useChatTeacher(classRoomData, joinedUsers, USER_TYPE);

    return (
        <>
            <Helmet>
                <style>
                    {`
                        / styles.css /
                        .agora-btn {
                            background-color: #570861 !important;
                            border: none;
                        }
                        
                        .agora-btn svg {
                            fill: #ffffff;
                        }
                        .host-badge-list {
                            background-color: #570861;
                            color: white;
                            padding: 2px 8px;
                            border-radius: 12px;
                            font-size: 12px;
                            margin-left: 10px;
                        }

                        .user_item.host {
                          
                         align-items: left
                        }
                    `}
                </style>
            </Helmet>
            {/* {isConnected ?
                (<> */}
            <main className="main-content-block">
                <section className="videocall-sec-one">
                    <div className="container-fluid">
                        <div className="row">
                            <div className={callSize()}>
                                <div className={`image-contaie-video active ${callVideoHeight()}`}>
                                    <LocalUser 
                                    audioTrack={null}
                                        cameraOn={cameraOn}
                                        micOn={micOn}
                                        videoTrack={screenOn && screenTrack ? screenTrack : (cameraOn ? localCameraTrack : null)}
                                        className="z-3 rounded-4"
                                        cover={userDetails?.profile_image}>
                                        <div className="badge-chat">
                                            {!micOn && (
                                                <img src={process.env.PUBLIC_URL + "/assets/images/svg/mice-off-red.svg"} alt="" />
                                            )}
                                            <p>{userDetails?.full_name ? userDetails?.full_name : userDetails?.learner_name}</p>
                                        </div>
                                    </LocalUser>
                                </div>
                            </div>
                            {remoteUsers?.map((user) => {
                                let userData = getReceiverUserList?.filter(item => item?.agora_uid == user?.uid)?.[0];
                                return (
                                    <div className={callSize()} key={user.uid}>
                                        <div className={`image-contaie-video active ${callVideoHeight()}`}>
                                            <RemoteUser cover={userData?.profile_image} user={user}>
                                                <div className="badge-chat">
                                                    {user?._audio_muted_ && (
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/svg/mice-off-red.svg"} alt="" />
                                                    )}
                                                    <p>{userData?.full_name ? userData?.full_name : userData?.learner_name}</p>
                                                </div>
                                            </RemoteUser>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='row'>
                            <div className="col-12 p-0">
                                <div className="videos-btns-container">
                                    <button className="btn-participant btn-videocall" data-bs-toggle="offcanvas" data-bs-target="#learnerlist" aria-controls="learnerlist"></button>
                                    <button className="btn-message btn-videocall" data-bs-toggle="offcanvas" data-bs-target="#chatoffcanvas" aria-controls="chatoffcanvas" onClick={checkRoomExist}></button>

                                    <button className={`btn-videocall ${!cameraOn ? 'btn-vidoo-videocall-mute' : 'btn-vidoo-videocall'}`} onClick={() => setCamera((a) => !a)}></button>
                                    {/* <button className={`btn-videocall `} onClick={startRecording}>
                                                Recording
                                            </button> */}
                                    <button className={`btn-videocall ${!micOn ? 'btn-microphone-mute' : 'btn-microphone-2'}`} onClick={() => setMic((a) => !a)}></button>
                                    <button className="cancellcall" onClick={() => handleCallCut()}></button>
                                    <button className="btn-screen-share" onClick={() => handleScreenShareToggle()}>
                                        {screenOn ? 'Stop Sharing' : 'Share Screen'}
                                    </button>
                                    <button className="" onClick={() => startRecording()}>recording</button>
                                    <button className="" onClick={() => stopRecording()}>stop recording</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </main>
            <div className="offcanvas offcanvas-end" tabIndex="-1" id="learnerlist" aria-labelledby="learnerlistLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="learnerlistLabel">Participants({joinedUsers.length})</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    {filteredPendingRequests.length > 0 && (<>
                        <div className="box-waiting_room">
                            <button className="btn-borderless" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePendingRequests" aria-expanded="false" aria-controls="collapsePendingRequests">
                                Waiting Room ({filteredPendingRequests.length}) <img src="./assets/images/downarrow1.svg" alt="" />
                            </button>
                        </div>

                        <div className="collapse mt-3" id="collapsePendingRequests">
                            <div className="card card-body">
                                <ul className="user-container">
                                    {filteredPendingRequests.map((request) => (
                                        <li key={request.agora_uid} className="user_item">
                                            <div className="left">
                                                <div className="img_user1">
                                                    <img src={request?.profile_image} alt="" />
                                                </div>
                                                <p className="name-user1">{request.name}</p>
                                            </div>
                                            <div className="right">
                                                <button className="btn btn-primary fonts-size" onClick={() => handleAcceptRequest(request, 'accept')}>Admit</button>
                                                <button className="btn btn-danger fonts-size" onClick={() => handleRejectRequest(request, 'reject')}>Reject</button>
                                            </div>
                                        </li>
                                    ))}
                                </ul>

                            </div>
                        </div>
                    </>
                    )}

                    <div className="box-waiting_room mt-3">
                        <button className="btn-borderless" type="button" data-bs-toggle="collapse" data-bs-target="#collapseJoinedUsers" aria-expanded="false" aria-controls="collapseJoinedUsers">
                            Joined ({joinedUsers.length}) <img src="./downarrow1.svg" alt="" />
                        </button>
                    </div>

                    <div className="collapse mt-3" id="collapseJoinedUsers">
                        <div className="card card-body">
                            {joinedUsers.length === 0 ? (
                                <p>No users have joined yet.</p>
                            ) : (
                                <ul className="user-container">
                                    {joinedUsers.map((user) => (
                                        <li key={user.agora_uid} className={`user_item ${user.isHost ? 'host' : ''}`}>
                                            <div className="left">
                                                <div className="img_user1">
                                                    <img src={user.profile_image} alt="" />
                                                </div>
                                                <p className="name-user1">
                                                    {user.name}
                                                    {user.isHost && <span className="host-badge-list">Host</span>}
                                                </p>
                                            </div>
                                            {!user.isHost && isHost && (
                                                <button className="btn btn-danger fonts-size" onClick={() => handleRemoveUser(user)}>Remove</button>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* message body */}
            <div className="offcanvas offcanvas-end" tabIndex="-1" id="chatoffcanvas" aria-labelledby="chatoffcanvasLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="chatoffcanvasLabel">Chat</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div className="conent-chat_group mt-3 mt-lg-0">

                        <div className="content-chatlog position-relative">
                            {showMessages}
                            <div ref={scrollToBottomRef}></div>
                        </div>
                        <div className="chat-bottom">
                            <div>
                                <button className="chat-upload-icon" onClick={() => handleImageClick()}>

                                </button>
                            </div>
                            <input
                                type="text"
                                className="chat-input"
                                placeholder="Type a message"
                                value={message}
                                onChange={handleMessage}
                                ref={inputRef}
                            />
                            <button className="send" onClick={sendMessage}>Send</button>

                        </div>
                        <div className="">
                            {image.blob?.type?.includes("image") ? (
                                <div className="image-container">
                                    <img src={image?.base64} width={150} />
                                    <button
                                        className="delete-button"
                                        onClick={() => deleteImage()}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            marginLeft: '7px',
                                            color: '#570861'
                                        }}
                                    >
                                        x
                                    </button>
                                </div>
                            ) : image.blob?.type?.includes("video") ? (
                                <div>
                                    <video src={image?.base64} width={150} />
                                    <button
                                        className="delete-button"
                                        onClick={() => deleteImage()}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            marginLeft: '7px',
                                            color: '#570861'
                                        }}
                                    >
                                        x
                                    </button>
                                </div>
                            ) : image.blob?.type?.includes("application/pdf") ? (
                                <div>
                                    Pdf File
                                    <button
                                        className="delete-button"
                                        onClick={() => deleteImage()}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            marginLeft: '7px',
                                            color: '#570861'
                                        }}
                                    >
                                        x
                                    </button>
                                </div>
                            ) : image.blob?.type?.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") || image.blob?.type?.includes("application/vnd.ms-excel") ? (
                                <div>
                                    Excel File
                                    <button
                                        className="delete-button"
                                        onClick={() => deleteImage()}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            marginLeft: '7px',
                                            color: '#570861'
                                        }}
                                    >
                                        x
                                    </button>
                                </div>
                            ) : image.blob?.type?.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document") || image.blob?.type?.includes("application/msword") ? (
                                <div>
                                    Word Document
                                    <button
                                        className="delete-button"
                                        onClick={() => deleteImage()}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            marginLeft: '7px',
                                            color: '#570861'
                                        }}
                                    >
                                        x
                                    </button>
                                </div>
                            ) : null}


                            <Modals isOpen={isModalOpen} onRequestClose={closeModal} contentLabel="Media Modal"
                                style={{
                                    overlay: {
                                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                                        zIndex: 1000,
                                    },
                                    content: {
                                        //  top: '50%',
                                        //  left: '50%',
                                        //  right: 'auto',
                                        //  bottom: 'auto',
                                        //  marginRight: '-50%',
                                        //  transform: 'translate(-50%, -50%)',
                                        padding: '40px',
                                        //  borderRadius: '8px',
                                        //  maxHeight: '50vh',
                                        overflowY: 'auto',
                                        width: '80%',
                                        marginLeft: '150px'
                                    },
                                }}
                            >
                                <button onClick={closeModal} style={{ position: 'absolute', top: '2px', right: '2px', background: 'none', border: 'none', fontSize: '16px', cursor: 'pointer' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 512 512" id="close"><path d="M256 33C132.3 33 32 133.3 32 257s100.3 224 224 224 224-100.3 224-224S379.7 33 256 33zm108.3 299.5c1.5 1.5 2.3 3.5 2.3 5.6 0 2.1-.8 4.2-2.3 5.6l-21.6 21.7c-1.6 1.6-3.6 2.3-5.6 2.3-2 0-4.1-.8-5.6-2.3L256 289.8l-75.4 75.7c-1.5 1.6-3.6 2.3-5.6 2.3-2 0-4.1-.8-5.6-2.3l-21.6-21.7c-1.5-1.5-2.3-3.5-2.3-5.6 0-2.1.8-4.2 2.3-5.6l75.7-76-75.9-75c-3.1-3.1-3.1-8.2 0-11.3l21.6-21.7c1.5-1.5 3.5-2.3 5.6-2.3 2.1 0 4.1.8 5.6 2.3l75.7 74.7 75.7-74.7c1.5-1.5 3.5-2.3 5.6-2.3 2.1 0 4.1.8 5.6 2.3l21.6 21.7c3.1 3.1 3.1 8.2 0 11.3l-75.9 75 75.6 75.9z" /></svg>
                                </button>
                                {modalContent}
                            </Modals>
                        </div>

                    </div>
                </div>
                <input
                    type="file"
                    onChange={handleMessage}
                    ref={imageVal}
                    style={{ display: 'none' }}
                />
            </div>


            {/* </>)
                : (
                    <main className="main-content-block">
                        <section className="videocall-sec-one">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-sm-6">
                                        <div className="image-contaie-video active height84">
                                            <img src={process.env.PUBLIC_URL + "/assets/images/svg/call-waiting.svg"} alt="videocall" />
                                            <div className="badge-chat">
                                                <p>Calling...</p>
                                            </div>
                                            <div className="cancel-btn">
                                                <button className="cancellcall" onClick={() => handleCallMissed()}></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                )} */}
        </>
    );

};


export default TStartMeeting;
