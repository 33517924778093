import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "react-phone-input-2/lib/bootstrap.css";
import "react-phone-input-2/lib/style.css";
import { convertToBase64, TOAST_ERROR, TOAST_SUCCESS } from '../../../../utils/common.service'
import { uploadImageOnAWS } from '../../../aws/Service';
import { country_list, state_province_list, teacher_identity } from '../../../../api/apiHandler';
import { Helmet } from 'react-helmet';

export default function TeacherVerification({ isLoader }) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const navigate = useNavigate();
    const today = new Date().toISOString().split('T')[0];

    const schema = yup.object().shape({
        legal_name: yup.string().required("Please enter full legal name")
            .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
            .matches(/^[a-zA-Z\s!]+$/, "Special characters and numbers are not allowed")
            .test(
                'no-only-whitespace',
                'Full name cannot consist of only spaces',
                (value) => value && value.trim().length > 0
            ),
        street_number: yup
            .string()
            .required("Please enter street number")
            .test(
                'no-only-whitespace',
                'Street number cannot consist of only spaces',
                (value) => value && value.trim().length > 0
            )
            .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
            .max(50, "Street number can't exceed 50 characters"),
        street_name: yup
            .string()
            .required("Please enter street name")
            .test(
                'no-only-whitespace',
                'Street name cannot consist of only spaces',
                (value) => value && value.trim().length > 0
            )
            .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
            .min(2, "Street name must have at least 2 or 3 characters")
            .max(200, "Street name can't exceed 200 characters"),
        country: yup.string().required("Please select country"),
        state_province: yup.string().required("Please select state/province"),
        city: yup
            .string()
            .required("Please enter city")
            .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
            .matches(/^[a-zA-Z\s'!]+$/, "Special characters and numbers are not allowed")
            .test(
                'no-only-whitespace',
                'City cannot consist of only spaces',
                (value) => value && value.trim().length > 0
            )
            .min(2, "City must have at least 2 characters")
            .max(200, "City can't exceed 200 characters"),

        zip_code: yup
            .string()
            .required("Please enter zip code")
            .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
            .matches(/^[a-zA-Z0-9\s\-]+$/, "Special characters are not allowed")
            .test(
                'no-only-whitespace',
                'Zip code cannot consist of only spaces',
                (value) => value && value.trim().length > 0
            )
            .matches(
                /^(\d{5}(-\d{4})?|[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d|\d{4}|\d{6})$/,
                "Please enter valid zip code"
            ),
        teacher_photo: yup
            .mixed()
            .required("Please upload your selfie"),
        checkbox: yup.boolean()
            .oneOf([true], 'You must agree to your information is accurate or not')
            .required('You must agree to your information is accurate or not'),
        // date1: yup.string().required("Date is required"),
        country_of_residence: yup.string().required("Please enter your country of residence")
            .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
            .matches(/^[a-zA-Z\s!]+$/, "Special characters and numbers are not allowed")
            .test(
                'no-only-whitespace',
                'Country cannot consist of only spaces',
                (value) => value && value.trim().length > 0
            ),
        first_name: yup
            .string()
            .required("Please enter full name")
            .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
            .matches(/^[a-zA-Z\s!]+$/, "Special characters and numbers are not allowed")
            .test(
                'no-only-whitespace',
                'Full name cannot consist of only spaces',
                (value) => value && value.trim().length > 0
            ),

        attach1: yup.mixed()
            .required('Please upload Front side of ID proof')
            .test('fileSize', 'File is too large', (value) => {
                return value && value.size <= 5 * 1024 * 1024; // 5MB limit
            })
            .test('fileType', 'Invalid file type', (value) => {
                const allowedTypes = [
                    'image/jpeg',
                    'image/png',
                    'application/pdf',
                    'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                ];
                return value && allowedTypes.includes(value.type);
            }),

        attach2: yup.mixed()
            .required('Please upload Back side of ID proof')
            .test('fileSize', 'File is too large', (value) => {
                return value && value.size <= 5 * 1024 * 1024; // 5MB limit
            })
            .test('fileType', 'Invalid file type', (value) => {
                const allowedTypes = [
                    'image/jpeg',
                    'image/png',
                    'application/pdf',
                    'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                ];
                return value && allowedTypes.includes(value.type);
            }),

    });

    const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const [StartDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
    const [profileImageState, setProfileImageState] = useState('')
    const [profileImageState1, setProfileImageState1] = useState(['', ''])
    const [docExtension, setDocExtension] = useState('')
    const [docExtension1, setDocExtension1] = useState(['', ''])
    var [CountryListdata, setCountryListdata] = useState([]);
    var [CountryID, setCountryID] = useState([]);
    var [stateListdata, setstateListdata] = useState([]);
    var [StateID, setStateID] = useState([]);

    const countryListing = () => {
        isLoader(true)
        country_list().then((resposnse) => {
        console.log('country_list :', resposnse);
            if (resposnse?.code == 1) {
                setCountryListdata(resposnse?.data)
                isLoader(false)
            } else {
                isLoader(false)
            }
        });
    }

    const StateListing = () => {
        isLoader(true)
        state_province_list({ country_id: CountryID }).then((resposnse) => {
        console.log('state_province_list :', resposnse);
            if (resposnse?.code == 1) {
                setstateListdata(resposnse?.data)
                isLoader(false)
            } else {
                setstateListdata()
                isLoader(false)
            }
        });
    }

    useEffect(() => {
        countryListing();
    }, []);

    useEffect(() => {
        StateListing()
    }, [CountryID]);

    const handleCountryChange = (e) => {
        setstateListdata([])
        setCountryID(e)
        clearErrors('country')
    }

    const handleStateChange = (e) => {
        setStateID(e)
        clearErrors('state_province')
    }

    const handleImageChange = async (image) => {
        // Check if the uploaded file is an image type
        if (!image || !image.type.startsWith('image/')) {
            TOAST_ERROR('Please attach a valid image file');
            setProfileImageState(null); // Reset the state if invalid file is uploaded
            setValue("teacher_photo", null); // Clear the form value for invalid file
            return; // Stop further execution
        }

        setDocExtension(image.type);

        // Show success toast if the file is valid
        TOAST_SUCCESS("Your attachment has been uploaded");

        // Set the uploaded file in the form value
        setValue("teacher_photo", image);

        // Convert the image to Base64 and set it in the state
        const bufferImage = await convertToBase64(image);
        setProfileImageState(bufferImage);

        // Clear any validation errors for this field
        clearErrors("teacher_photo");
    };

    const handleImage2Change = async (image, numImage) => {
        // Allow only image types (jpeg, png, etc.)
        const allowedFileTypes = [
            'image/jpeg',
            'image/png',
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ];

        // Check if the uploaded file is of an allowed type
        if (!allowedFileTypes.includes(image?.type)) {
            TOAST_ERROR('Please attach a valid image (JPEG/PNG), PDF, or DOC file');

            // Clear the specific file input
            setValue(numImage === 0 ? "attach1" : "attach2", null);

            // Update state to reflect removal
            const newProfileImageState = [...profileImageState1];
            newProfileImageState[numImage] = '';
            setProfileImageState1(newProfileImageState);

            const newDocExtension = [...docExtension1];
            newDocExtension[numImage] = '';
            setDocExtension1(newDocExtension);

            return;
        }

        // If the image is valid
        if (image != null) {
            TOAST_SUCCESS("Your attachment has been uploaded");
        }

        // Set form values
        setValue(numImage === 0 ? "attach1" : "attach2", image);

        // Convert to base64
        const bufferImage = await convertToBase64(image);

        // Update state arrays
        const newProfileImageState = [...profileImageState1];
        newProfileImageState[numImage] = bufferImage;
        setProfileImageState1(newProfileImageState);

        const newDocExtension = [...docExtension1];
        newDocExtension[numImage] = image.type;
        setDocExtension1(newDocExtension);

        // Clear any previous errors
        clearErrors(numImage === 0 ? "attach1" : "attach2");
    };

    const handleDocumentRemove = (index) => {
        // Clear the specific file input
        setValue(index === 0 ? "attach1" : "attach2", null);

        // Update state to reflect removal
        const newProfileImageState = [...profileImageState1];
        newProfileImageState[index] = '';
        setProfileImageState1(newProfileImageState);

        const newDocExtension = [...docExtension1];
        newDocExtension[index] = '';
        setDocExtension1(newDocExtension);

        // Set error for this specific document
        setValue(index === 0 ? "attach1" : "attach2", null);
    };


    let onSubmit = async (data) => {

        if (!data.attach1 || !data.attach2) {
            TOAST_ERROR("Please upload both front and back sides of your ID proof");
            return;
        }

        isLoader(true);
        let submit_data = {
            teacher_id: localStorage.getItem("Tid"),
            legal_name: data.legal_name,
            street_number: data.street_number,
            street_name: data.street_name,
            country: data.country,
            state_province: data.state_province,
            zip_code: data.zip_code,
            city: data.city,
            doc_name: data.idType,
            full_name : data.first_name,
            country_residence: data.country_of_residence,
            type: "Identity",
        }

        let teacherPhoto = data?.teacher_photo

        if (data?.teacher_photo) {
            teacherPhoto = await uploadImageOnAWS(teacherPhoto, "teacher_verification");
            submit_data.selfie_pic = teacherPhoto;
        }

        const id_proof = [];
        const documentFields = ['attach1', 'attach2'];

        for (let field of documentFields) {
            if (data[field]) {
                const id_proof_attach = await uploadImageOnAWS(data[field], "teacher_application");
                id_proof.push({ "attachment": id_proof_attach });
            }
        }

        if (id_proof.length === 2) {
            submit_data.doc_attach = id_proof;
        } else {
            throw new Error("Both document sides must be uploaded");
        }

        console.log('submit_data :', submit_data);

        teacher_identity(submit_data).then((response) => {
            if (response?.code == 1) {
                isLoader(false)
                TOAST_SUCCESS(response?.message)
                navigate("/signup-teacher")
                reset();
            } else {
                isLoader(false)
                TOAST_ERROR(response?.message)
            }
        })
    }

    return (
        <>
            <Helmet>
                <style>
                    {`
                     .custom_form .form-group label {
                       text-transform: capitalize;
                       color: #000000;
                     }
                     `}
                </style>
            </Helmet>
            <main>
                <div className="login_sec">
                    <div className="container-fluid">
                        <div className="row my-4 justify-content-center">
                            <div className="col-lg-8 right card">
                                <div className="top">
                                    <div className="arrow_back mb-3">
                                        <a onClick={() => navigate(-1)} className="d-flex align-items-center">
                                            <span>
                                                <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.86245 2.225L8.37912 0.75L0.137451 9L8.38745 17.25L9.86245 15.775L3.08745 9L9.86245 2.225Z" fill="#131313" />
                                                </svg>
                                            </span>
                                            <span className="ps-3">Back</span>
                                        </a>
                                    </div>
                                    <h2 className="mb-4"> Teacher Identity Verification Check </h2>
                                    <form onSubmit={handleSubmit(onSubmit)} className="custom_form mt-4" >
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group mb-4">
                                                    <label className="mb-2">
                                                        What is your full legal name
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <input type="text" className="form-control" {...register("legal_name")} name="legal_name" />
                                                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.legal_name?.message}</span></p>

                                                </div>
                                            </div>
                                            {/* <div className="col-lg-12">
                                                <div className="form-group mb-4">
                                                    <label className="mb-2">
                                                        What is full address
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <textarea className="form-control" {...register("address")} name="address" id cols={30} rows={5} />
                                                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.address?.message}</span></p>

                                                </div>
                                            </div> */}
                                            <div className="col-lg-12">
                                                <div className="form-group mb-4">
                                                    <label className="mb-2">
                                                    What Is Your Address? Your Address Should Be The Same As Shown In Your Government Identification That You Will Be Submitting Below.
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="form-group mb-4">
                                                                <label className="mb-2">Street Number</label>
                                                                <input type="text" className="form-control" {...register("street_number")} name="street_number" />
                                                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.street_number?.message}</span></p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group mb-4">
                                                                <label className="mb-2">Street Name</label>
                                                                <input type="text" className="form-control"  {...register("street_name")} name="street_name" />
                                                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.street_name?.message}</span></p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group mb-4">
                                                                <label className="mb-2">Country</label>

                                                                <select className="form-select" {...register("country")} onChange={(e) => handleCountryChange(e.target.value)} name="country" aria-label="Default select example">
                                                                    <option value='' selected disabled>select country</option>
                                                                    {CountryListdata?.map((countrylistdetail, index) => {
                                                                        return (
                                                                            <option key={index} value={countrylistdetail?.country_id}>{countrylistdetail?.country_name}</option>
                                                                        )
                                                                    })}
                                                                </select>

                                                                <p className='mt-2 font-bold col-red'>
                                                                    <span style={{ color: 'red' }}>{errors.country?.message}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group mb-4">
                                                                <label className="mb-2">Province/State</label>
                                                                <select className="form-select" {...register("state_province")} onChange={(e) => handleStateChange(e.target.value)} name="state_province" aria-label="Default select example">
                                                                    <option value='' selected disabled>select state/province</option>
                                                                    {stateListdata?.map((countrylistdetail, index) => {
                                                                        return (
                                                                            <option key={index} value={countrylistdetail?.state_id}>{countrylistdetail?.state_province}</option>
                                                                        )
                                                                    })}
                                                                </select>

                                                                <p className='mt-2 font-bold col-red'>
                                                                    <span style={{ color: 'red' }}>{errors.state_province?.message}</span>
                                                                </p>

                                                            </div>
                                                        </div>
                                                        {/* <div className="col-lg-6">
                              <div className="form-group mb-4">
                                <label className="mb-2">City</label>
                                <select className="form-select" aria-label="Default select example">
                                  <option selected>India</option>
                                  <option value={1}>Australia</option>
                                  <option value={2}>Canada</option>
                                  <option value={3}>United States</option>
                                </select>
                              </div>
                            </div> */}
                                                        <div className="col-lg-6">
                                                            <label className="mb-2">City</label>
                                                            <input type="text" className="form-control" {...register("city")} name="city" />
                                                            <p className='mt-2 font-bold col-red'>
                                                                <span style={{ color: 'red' }}>{errors.city?.message}</span>
                                                            </p>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <label className="mb-2">Zip Code</label>
                                                            <input type="text" {...register("zip_code")} className="form-control" name="zip_code" />
                                                            <p className='mt-2 font-bold col-red'>
                                                                <span style={{ color: 'red' }}>{errors.zip_code?.message}</span>
                                                            </p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group mb-4">
                                                    <label className="mb-2">
                                                        Please upload a selfie of yourself. You should clearly show your face.
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <div className="form-group mb-lg-0 mb-4  file_upload">
                                                                <Controller name="teacher_photo" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                                                    <>
                                                                        <div className="file_update position-relative">
                                                                            <div className="d-flex align-items-center justify-content-center">

                                                                                {/* <img className="image" onClick={() => {
                                      document.getElementById("teacher_photo")?.click()
                                    }} src={profileImageState ? profileImageState : ""} alt="" style={{
                                      width: profileImageState ? "100%" : "",
                                      height: profileImageState ? "166px" : "",
                                    }} /> */}
                                                                                {(docExtension && docExtension.startsWith('image/')) && profileImageState && (
                                                                                    <img
                                                                                        onClick={() => {
                                                                                            document.getElementById("teacher_photo")?.click();
                                                                                        }}
                                                                                        className="image"
                                                                                        src={profileImageState ? profileImageState : ""}
                                                                                        alt=""
                                                                                        style={{
                                                                                            width: profileImageState ? "100%" : "",
                                                                                            height: profileImageState ? "166px" : "",
                                                                                        }}
                                                                                    />
                                                                                )}


                                                                            </div>
                                                                            <input type="file" id='teacher_photo' name="teacher_photo" accept="image/*" onInput={(e) => handleImageChange(e.target.files[0])} className="form-control" style={{ display: profileImageState ? "none" : "" }} />
                                                                            {profileImageState ?
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn-close position-absolute"
                                                                                    data-bs-dismiss="modal"
                                                                                    onClick={() => {
                                                                                        setProfileImageState(null);
                                                                                        document.getElementById("teacher_photo").value = null;
                                                                                    }}
                                                                                    aria-label="Close"
                                                                                    style={{
                                                                                        top: "10px",
                                                                                        right: "10px",
                                                                                        zIndex: "10",
                                                                                    }}
                                                                                ></button> :
                                                                                <span className="file_icons position-absolute">
                                                                                    <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                                                                    </svg>
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    </>
                                                                )} />
                                                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.teacher_photo?.message}</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group mb-4">
                                                <label className="mb-2">
                                                    What type of government ID will you upload to verify your identity? The
                                                    ID should have your picture.
                                                    <span className="text-danger">*</span></label>
                                                <select className="form-select" {...register("idType")} aria-label="Default select example">
                                                    <option selected value={"Passport - Detail page that has your photograph"}>
                                                        Passport - Detail page that has your photograph
                                                    </option>
                                                    <option value={"National ID Card - Front and back"}>
                                                        National ID Card - Front and back
                                                    </option>
                                                    <option value={"Drivers License - Front and back"}>
                                                        Drivers License - Front and back
                                                    </option>
                                                    <option value={"Health Card - Front and back"}>
                                                        Health Card - Front and back
                                                    </option>
                                                </select>

                                                <div className="row mt-3">
                                                    <div className="col-lg-4 mb-4">
                                                        <label className="mb-2">
                                                            Please upload front side of your ID proof.
                                                            <span className="text-danger">*</span></label>
                                                        <div className="form-group mb-lg-0 mb-4  file_upload">
                                                            <Controller name="attach1" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                                                <>
                                                                    <div className="file_update position-relative">
                                                                        <div className="d-flex align-items-center justify-content-center">

                                                                            {(docExtension1[0] === 'image/jpeg' || docExtension1[0] === 'image/png') && profileImageState1[0] && (
                                                                                <img onClick={() => {
                                                                                    document.getElementById("attach1")?.click()
                                                                                }}
                                                                                    className="image"
                                                                                    src={profileImageState1[0] !== '' ? profileImageState1[0] : ""}
                                                                                    alt=""
                                                                                    style={{
                                                                                        width: profileImageState1[0] !== '' ? "100%" : "",
                                                                                        height: profileImageState1[0] !== '' ? "166px" : "",
                                                                                    }}
                                                                                />
                                                                            )}

                                                                            {docExtension1[0] === 'application/pdf' && profileImageState1[0] && (
                                                                                <>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="currentColor" style={{ color: "red", margin: "30px" }} class="bi bi-filetype-pdf" viewBox="0 0 16 16" onClick={() => {
                                                                                        document.getElementById("attach1")?.click()
                                                                                    }}>
                                                                                        <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                                                                                    </svg>
                                                                                </>
                                                                            )}

                                                                            {(docExtension1[0] === 'application/msword' || docExtension1[0] === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') && profileImageState1[0] && (
                                                                                <>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={100} height={100} style={{ margin: "30px" }} viewBox="0 0 48 48" onClick={() => {
                                                                                        document.getElementById("attach1")?.click()
                                                                                    }}>
                                                                                        <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path><path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path><path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
                                                                                    </svg>
                                                                                </>
                                                                            )}

                                                                        </div>
                                                                        <input type="file" id='attach1' name="attach1" accept=".jpg, .jpeg, .png, .pdf, .doc, .docx" onInput={(e) => handleImage2Change(e.target.files[0], 0)} className="form-control" style={{ display: profileImageState1[0] !== "" ? "none" : "" }} />

                                                                        {profileImageState1[0] !== "" ?
                                                                            <button
                                                                                type="button"
                                                                                className="btn-close position-absolute"
                                                                                data-bs-dismiss="modal"
                                                                                onClick={() => handleDocumentRemove(0)}
                                                                                aria-label="Close"
                                                                                style={{
                                                                                    top: "10px",
                                                                                    right: "10px",
                                                                                    zIndex: "10",
                                                                                }}
                                                                            ></button> :
                                                                            <span className="file_icons position-absolute">
                                                                                <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                                                                </svg>
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                    {errors.attach1 && (
                                                                        <p className="text-danger mt-2">
                                                                            {errors.attach1.message}
                                                                        </p>
                                                                    )}
                                                                </>
                                                            )} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-4">
                                                        <label className="mb-2">
                                                            Please upload back side of your ID proof.
                                                            <span className="text-danger">*</span></label>
                                                        <div className="form-group mb-lg-0 mb-4  file_upload">
                                                            <Controller name="attach2" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                                                <>
                                                                    <div className="file_update position-relative">
                                                                        <div className="d-flex align-items-center justify-content-center">


                                                                            {(docExtension1[1] === 'image/jpeg' || docExtension1[1] === 'image/png') && profileImageState1[1] && (
                                                                                <img onClick={() => {
                                                                                    document.getElementById("attach2")?.click()
                                                                                }}
                                                                                    className="image"
                                                                                    src={profileImageState1[1] !== '' ? profileImageState1[1] : ""}
                                                                                    alt=""
                                                                                    style={{
                                                                                        width: profileImageState1[1] !== '' ? "100%" : "",
                                                                                        height: profileImageState1[1] !== '' ? "166px" : "",
                                                                                    }}
                                                                                />
                                                                            )}

                                                                            {docExtension1[1] === 'application/pdf' && profileImageState1[1] && (
                                                                                <>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="currentColor" style={{ color: "red", margin: "30px" }} class="bi bi-filetype-pdf" viewBox="0 0 16 16" onClick={() => {
                                                                                        document.getElementById("attach2")?.click()
                                                                                    }}>
                                                                                        <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                                                                                    </svg>
                                                                                </>
                                                                            )}

                                                                            {(docExtension1[1] === 'application/msword' || docExtension1[1] === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') && profileImageState1[1] && (
                                                                                <>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={100} height={100} style={{ margin: "30px" }} viewBox="0 0 48 48" onClick={() => {
                                                                                        document.getElementById("attach2")?.click()
                                                                                    }}>
                                                                                        <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path><path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path><path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
                                                                                    </svg>
                                                                                </>
                                                                            )}

                                                                        </div>
                                                                        <input type="file" id='attach2' name="attach2" accept=".jpg, .jpeg, .png, .pdf, .doc, .docx" onInput={(e) => handleImage2Change(e.target.files[0], 1)} className="form-control" style={{ display: profileImageState1[1] !== "" ? "none" : "" }} />
                                                                        {profileImageState1[1] !== "" ?
                                                                            <button
                                                                                type="button"
                                                                                className="btn-close position-absolute"
                                                                                data-bs-dismiss="modal"
                                                                                onClick={() => handleDocumentRemove(1)}
                                                                                aria-label="Close"
                                                                                style={{
                                                                                    top: "10px",
                                                                                    right: "10px",
                                                                                    zIndex: "10",
                                                                                }}
                                                                            ></button> :
                                                                            <span className="file_icons position-absolute">
                                                                                <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                                                                </svg>
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                    {errors.attach2 && (
                                                                        <p className="text-danger mt-2">
                                                                            {errors.attach2.message}
                                                                        </p>
                                                                    )}
                                                                </>
                                                            )} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group d-flex gap-4 align-items-center ">
                                                <input type="checkbox" id="checkbox" {...register('checkbox')} name='checkbox' />
                                                <label htmlFor="checkbox" className="d-block">
                                                    I declare that the above information provided by me is accurate and
                                                    truthful.
                                                </label>
                                            </div>
                                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.checkbox?.message}</span></p>

                                            <br></br>
                                            <div className="col-lg-12">
                                                <div className="form-group mb-4 mt-2">
                                                    <label className="mb-2">Your Full Name:<span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" {...register("first_name")} name="first_name" />
                                                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.first_name?.message}</span></p>
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-12">
                                                <div className="form-group mb-4">
                                                    <label className="mb-2 mx-2">
                                                        Date:
                                                    </label>
                                                    <input type="date" className="form-control w-25 d-inline-block" {...register("date1")} name="date1" min={StartDate} value={StartDate} />
                                                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.date1?.message}</span></p>

                                                </div>
                                            </div> */}

                                            <div className="col-lg-12">
                                                <div className="form-group mb-4">
                                                    <label className="mb-2 mx-2">
                                                        Date:
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="form-control w-25 d-inline-block"
                                                        {...register("date1")}
                                                        name="date1"
                                                        value={today}
                                                        disabled
                                                        onFocus={(e) => e.target.blur()}
                                                    />
                                                    <p className='mt-2 font-bold col-red'>
                                                        <span style={{ color: 'red' }}>{errors.date1?.message}</span>
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-lg-12">
                                                <div className="form-group mb-4">
                                                    <label className="mb-2">Your Country of Residence :<span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" {...register("country_of_residence")} name="country_of_residence" />
                                                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.country_of_residence?.message}</span></p>
                                                </div>
                                            </div>
                                            <div className="button mt-3">
                                                <button className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block" >
                                                    Submit
                                                </button>
                                                {/* <a href="dashboard.php" className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block">Submit</a> */}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}
