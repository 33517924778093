import axios from "axios";
import CryptoJS from 'crypto-js';
import { logOutRedirectCall, loginRedirectCall } from '../pages/Common/RedirectPathMange'

var key = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNfTmDXHWVkQWdanYOm');
var iv = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNf');

const showMessage = (msg) => {
  // console.log(msg)
}
// console.log(process.env.REACT_APP_BASE_URL)
const axiosClient = axios.create({
  // baseURL: 'http://13.234.122.246:8625/api',
  baseURL: 'https://api.studentscholars.com:8015/api',
  //headers : authHeaders()
  headers: {
    'api-key': 'voRJA+cryPBoUoQh/k7q7obQN1nmg7CaY8Vt49+5lxg=',
    'Accept-Language': 'en',
    'Content-Type': 'text/plain',
  }
});

// console.log('axiosClient-->',axiosClient)
// Body Encryption Request
axiosClient.interceptors.request.use(function (request) {
  // console.log("requestAxios", request.data)
  request.data = bodyEncryption(request.data ? request.data : {}, true)

  if ((localStorage.getItem("Ttoken") !== undefined || localStorage.getItem("Ttoken") !== null) || (localStorage.getItem("PAtoken") !== undefined || localStorage.getItem("PAtoken") !== null) ){
    const token = localStorage.getItem("Ttoken") ? localStorage.getItem("Ttoken") : localStorage.getItem("PAtoken")
    // console.log('token123 :', token);
    request.headers['token'] = bodyEncryption(token, false);                                                                                                                        
  }
  return request;
});

axiosClient.interceptors.response.use(
  function (response) {
    response = bodyDecryption(response?.data);
    var respData = response;
    if (respData?.code == -1) {
      // console.log(respData)
      logOutRedirectCall();
      return;
    }else{
      if (response?.code == 0) {
        showMessage(response.message)
      }
      // console.log("response", response)
      return response;
    }
  },

  function (error) {
    // console.log('error', error)
    let res = error?.response;
    // console.log('res111', res)
    if (res?.status == 401) {
      logOutRedirectCall()
    }
    // console.error("Looks like there was a problem. Status Code: " + res.status);
    // return Promise.reject(error);
  }
);

function bodyEncryption(request, isStringify) {
  // console.log(request)
  var request = (isStringify) ? JSON.stringify(request) : request;
  var encrypted = CryptoJS.AES.encrypt(request, key, { iv: iv });
  return encrypted.toString();
}

function bodyDecryption(request) {
  // console.log(request)
  var decrypted = CryptoJS.AES.decrypt(request.toString(), key, { iv: iv });
  // console.log("Decrypt Data")
  // console.log(decrypted)
  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
}


// function encryptURL(url) {
//   const [baseUrl, params] = url.split('?'); // Split URL into base and parameters
//   const urlParts = baseUrl.split('/'); // Split the base URL by slashes
//   const classId = urlParts[urlParts.length - 1]; // Extract the class_id

//   if (!params) return url; // Return original URL if no parameters exist

//   // Encrypt class_id and parameters using AES
//   const dataToEncrypt = `id=${classId}&${params}`;
//   const encrypted = CryptoJS.AES.encrypt(dataToEncrypt, key, { iv: iv }).toString();

//   // Remove class_id from the URL and append encrypted data   
//   urlParts[urlParts.length - 1] = '';
//   const baseUrlWithoutId = urlParts.join('/').slice(0, -1);

//   return `${baseUrlWithoutId}?${decodeURIComponent(encrypted)}`; // Return encrypted URL
// }

// function decryptURL(url) {
//   const [baseUrl, encryptedParams] = url.split('?'); // Split URL into base and encrypted parameters
//   if (!encryptedParams) return url; // Return original URL if no parameters exist

//   // Decrypt class_id and parameters using AES
//   const decrypted = CryptoJS.AES.decrypt(decodeURIComponent(encryptedParams), key, { iv: iv });
//   const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);

//   // Extract class_id and parameters from decrypted data
//   const [idPart, ...paramsArray] = decryptedString.split('&');
//   const classId = idPart.split('=')[1];
//   const params = paramsArray.join('&');

//   return `${baseUrl}/${classId}?${params}`; // Return decrypted URL with class_id
// }

function encryptURL(url) {
  const [baseUrl, params] = url.split('?');
  if (!params) return url;
  
  const encrypted = CryptoJS.AES.encrypt(params, key, { iv: iv }).toString();
  return `${baseUrl}?${encodeURIComponent(encrypted)}`;
}

function decryptURL(url) {
  const [baseUrl, encryptedParams] = url.split('?');
  if (!encryptedParams) return url;
  
  const decrypted = CryptoJS.AES.decrypt(decodeURIComponent(encryptedParams), key, { iv: iv });
  const params = decrypted.toString(CryptoJS.enc.Utf8);
  return `${baseUrl}?${params}`;
}

export { axiosClient,decryptURL, encryptURL};