import React, { useState, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useLocation } from 'react-router-dom'
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from 'react-router-dom';
import Select from 'react-select'
import countryList from 'react-select-country-list';
import moment from 'moment-timezone';
import { signUpp } from '../../../../api/apiHandler';
import { TOAST_SUCCESS, TOAST_ERROR, convertToBase64 } from '../../../../utils/common.service';
import Modals from 'react-modal';
import SendOtpModel from '../../../Models/SendOtpModel';
import { uploadImageOnAWS } from '../../../aws/Service';
import CryptoJS from 'crypto-js';
import { signInWithPopup } from 'firebase/auth';
import { auth, google, facebook } from './Config';

var COMMON_STYLE_FOR_POPUP = {
    content: {
        backgroundColor: 'transparent',
        background: 'none',
        border: '0px',
    },
}

const key = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNfTmDXHWVkQWdanYOm');
const iv = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNf');

function bodyDecryption(encryptedData) {
    try {
        const decryptedData = CryptoJS.AES.decrypt(encryptedData, key, { iv: iv }).toString(CryptoJS.enc.Utf8);
        console.log('decryptedData :', JSON.parse(decryptedData));
        return JSON.parse(decryptedData);
    } catch (error) {
        console.error('Decryption error:', error);
        return null; // Return null or handle the error as needed
    }
}

export default function SignUoOTeacher({ isLoader }) {
    const location = useLocation();
    console.log('location :', location);
    const [organizationId, setOrganizationId] = useState('')
    const allowedCountries = ['au', 'ca', 'nz', 'sg', 'gb', 'us'];
    // var teacher_id = location?.pathname?.split("/")[2];

    const navigate = useNavigate();
    const [lernerCategory, setLernerCategory] = useState('org_teacher')
    const [modalIsOpen, setIsOpen] = useState(false);

    console.log("gfdgdfg", lernerCategory)
    let abc = (phone) => {
        // Do nothing
        setLernerCategory(phone)
        clearErrors();
        // reset();
        console.log(phone)
    };

    const [loginType, setLoginType] = useState("S");
    const [socialId, setSocialId] = useState("");

    const handleSocialSignin = async (provider, login_type) => {
        const result = await signInWithPopup(auth, provider)
        console.log('result :', result);

        if (result.user) {
            setValue("email", result?.user?.providerData[0]?.email)
            setValue("full_name", result?.user?.providerData[0]?.displayName)
            setValue("phone", result?.user?.providerData[0]?.phoneNumber)
            setValue("password", "Ssss@8495")
            setSocialId(result?.user?.providerData[0]?.uid)
            setLoginType(login_type)
        }
    }

    let schema;

    if (lernerCategory == "org_teacher") {
        schema = yup.object().shape({
            // file: yup.mixed().required('File is required'),
            full_name: yup.string()
                .required('Please enter full name')
                .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
                .matches(/^[a-zA-Z\s!]+$/, "Special characters and numbers are not allowed")
                .test(
                    'no-only-whitespace',
                    'Full name cannot consist of only spaces',
                    (value) => value && value.trim().length > 0
                ),
            // email: yup.string().required("Please enter email").matches(/^[a-zA-Z0-9]+([._]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/, "Plaese enter valid email"),
            password: yup.string()
                .required("Please enter password")
                .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
                .min(8, "Password must contain 8 or more characters, at least one uppercase, one lowercase, one digit, and one special character")
                .matches(/^\S+$/, "Password cannot contain leading or trailing spaces")
                .matches(/(?=.*[a-z])(?=.*[A-Z])/, "Password must contain at least one uppercase and one lowercase letter")
                .matches(/\d/, "Password must contain at least one digit")
                .matches(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, "Password must contain at least one special character"),
            phone: yup.string()
                .required("Please enter phone number"),
            country: yup.string().required("Please select country"),
            checkbox: yup.boolean()
                .oneOf([true], 'You must agree to the Terms of Service and Privacy Policy')
                .required('You must agree to the Terms of Service and Privacy Policy'),
        });
    }

    const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const [profileImageState, setProfileImageState] = useState('')
    console.log("profileImageState", profileImageState)

    const handleImageChange = async (image) => {
        setValue("imageUpload", image);
        const bufferImage = await convertToBase64(image);
        setProfileImageState(bufferImage);
        clearErrors("imageUpload");
    }

    var [CountryData, SetCountryData] = useState([]);
    var [OrgCountryData, SetOrgCountryData] = useState([]);
    var [countryCode, setCountryCode] = useState();
    var [phoneCode, setPhoneCode] = useState();

    const countryData = (data) => {
        SetCountryData(data)
        clearErrors("country")
    }

    const orgCountryData = (data, countryValue) => {
        console.log('countryValue :', countryValue);
        console.log('data :', data);
        setCountryCode(data)
        SetOrgCountryData(countryValue)
        SetCountryData(countryValue)
        clearErrors("country")
    }

    const selectCountryData = {
        au: { name: 'Australia', flag: '🇦🇺', value: '14' },
        ca: { name: 'Canada', flag: '🇨🇦', value: '39' },
        nz: { name: 'New Zealand', flag: '🇳🇿', value: '158' },
        sg: { name: 'Singapore', flag: '🇸🇬', value: '199' },
        gb: { name: 'UK', flag: '🇬🇧', value: '232' },
        us: { name: 'USA', flag: '🇺🇸', value: '233' }
    };

    const handleCountryChange = (e) => {
        const countryCode = e.target.value;
        // console.log('countryCode :', countryCode);
        if (countryCode in selectCountryData) {
            const countryValue = selectCountryData[countryCode].value;
            // console.log('countryValue :', countryValue);
            orgCountryData(countryCode, countryValue);
            clearErrors("country");
        } else if (countryCode === "") {
            // Handle the case when no country is selected
            orgCountryData("", "");
            clearErrors("country");
        } else {
            console.error(`Unknown country code: ${countryCode}`);
            // You might want to set an error state here
        }
    };

    const [email, setEmail] = useState('');
    console.log('email :', email);
    useEffect(() => {
        const url = window.location.href;
        const teacher_id = location?.pathname?.split("/")[2];
        const emailParam = new URLSearchParams(url.split('?')[1]).get('EM');
        console.log('emailParam :', emailParam);

        console.log('teacher_id (encrypted):', teacher_id);

        if (emailParam) {
            var dec = decodeURIComponent(emailParam)
            console.log('dec :', dec);
            const decryptedEmail = bodyDecryption(dec);
            console.log('Decrypted email:', decryptedEmail);
            setEmail(decryptedEmail); // Update email state
            setValue("email", decryptedEmail); // Set email in the form
        }
        if (teacher_id) {
            const decryptedId = bodyDecryption(teacher_id);
            console.log('Decrypted teacher_id:', decryptedId);
            setOrganizationId(decryptedId); // Update organization ID state
        }

    }, [setValue]);

    let onSubmit = async (data) => {
        localStorage.clear()
        isLoader(true)
        console.log('data :', data);

        if (data.imageUpload != null) {
            var image = await uploadImageOnAWS(data?.imageUpload, "profile_image");
        }

        data.time_zone = selectedTimezone
        data.category = lernerCategory
        data.role = 'teach'
        localStorage.setItem("type", "org_teacher");

        let submitData = {
            // "profile_image": image,
            "organization_id": organizationId,
            "category": "org_teacher",
            "full_name": data.full_name,
            "email": data.email,
            "password": data.password,
            "phone": phone,
            "login_type": loginType,
            "role": "Teach",
            "country_code": "+" + country,
            "phoneCode": phoneCode,
            "countryCode": countryCode,
            // "country_name": data.country_name,
            "country_name": CountryData,
            "time_zone": data.time_zone,
            "use_refferal": (data.referral_code != undefined ? data.referral_code : "")
        }
        if (data.imageUpload != null) {
            submitData.profile_image = image
        }
        if (loginType == "S") {
            submitData.password = data.password
        } else {
            submitData.social_id = socialId
        }
        console.log("submitData", submitData);
        // localStorage.setItem("Tid", data.id);
        signUpp(submitData).then((res) => {
            console.log('res :', res);

            if (res?.code == 1) {
                isLoader(false)
                setIsOpen(true);
                console.log("Tid", res.data.id)
                console.log("Poto", res.data.otp);

                localStorage.setItem("Tid", res.data?.id);
                localStorage.setItem("Otp", res.data?.otp);
                reset();

                // localStorage.setItem("PASignupStep", res.data?.signup_step);
                // isLoader(false);
            } else {
                isLoader(false)
                TOAST_ERROR(res.message);
            }
        });
    }
    const [phone, setPhone] = useState('+1');
    const [country, setCountry] = useState('ca');

    const handleOnChange = (value, country) => {
        console.log('country :', country);
        // console.log('country35453 :', country.dialCode);
        console.log('value :', value);
        const formattedValue = value.substring(country.dialCode.length);
        setPhoneCode(country?.countryCode)
        setPhone(formattedValue);
        setCountry(country.dialCode);
        setValue('phone', value)
        clearErrors('phone')
    };

    // const [value, setCountry] = useState('')
    // const options = useMemo(() => countryList().getData(), [])

    // let changeHandler = value => {
    //   setCountry(value)
    //   // console.log(value);
    // }

    const [showPassword, setShowPassword] = useState(false);
    const handleTogglePassword = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };

    const [selectedTimezone, setSelectedTimezone] = useState('');
    const timezones = moment.tz.names().map((tz) => ({
        value: tz,
        label: `${tz} (UTC ${moment.tz(tz).format('Z')})`,
    }));

    useEffect(() => {
        // Detect and set the user's timezone as the default value.
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setSelectedTimezone(userTimezone);
    }, []);

    let handleChange = (selectedOption) => {
        setSelectedTimezone(selectedOption.value);
    };

    const [isChecked, setChecked] = useState(false);

    const handleCheckboxChange = () => {
        console.log('isChecked :', isChecked);
        setChecked(!isChecked);
        if (!isChecked) {
            setValue('referral_code', '');  // Using setValue to reset the field
        }
    };

    const [isChecked1, setChecked1] = useState(false);

    const handleCheckboxChange1 = () => {
        console.log('isChecked1 :', isChecked1);
        setChecked1(!isChecked1);
        if (!isChecked1) {
            setValue('referral_code', '');  // Using setValue to reset the field
        }
    };

    console.log(watch("phone"))

    return (
        <>
            <Helmet>
                <style>
                    {`
        .react-tel-input .form-control {
          width: 93% !important;
          height: 45px !important;
          margin-left: 45px !important;

        }
        .react-tel-input {
          border-radius: 0.25rem !important;
        }
        
        .flag-dropdown {
          background-color: #f2f2f2 !important;
        }
        .css-olqui2-singleValue {
          grid-area: 1/1/2/3;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: hsl(0deg 0% 6.3%);
          margin-left: 2px;
          margin-right: 2px;
          box-sizing: border-box;
      }
      .css-1vx9cxd-control {
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        cursor: default;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        min-height: 38px;
        outline: 0!important;
        position: relative;
        -webkit-transition: all 100ms;
        transition: all 100ms;
        /* background-color: hsl(0, 0%, 95%); */
        border-color: hsl(0, 0%, 90%);
        border-radius: 12px;
        border-style: solid;
        border-width: 1px;
        box-sizing: border-box;
        border: 1px solid rgba(102, 102, 102, 0.35);
        padding: 5px 10px;
        height: 46px;
        background-color: #e9ecef;
      }
    `}
                </style>
            </Helmet>

            <main>
                <div className="login_sec">
                    <div className="container-fluid">
                        <div className="row">
                            {/* <div className="align-items-center col-lg-6 d-flex justify-content-center h-auto left" style={{ backgroundImage:  `url(${process.env.PUBLIC_URL}/assets/images/auth/sinin_teacher.png) }}>
                                <h1 className="text-white poppins fw_500 position-relative">Please Select a Teacher or Organization Account</h1>
                            </div> */}
                            <div className="align-items-center col-lg-6 d-flex justify-content-center h-auto left" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/auth/sinin_teacher.png)` }}>
                                <h1 className="text-white poppins fw_500 position-relative">Please Select a Teacher or Organization Account</h1>
                            </div>

                            <div className="col-lg-6 right">
                                <div className="top">
                                    <div className="d-md-flex justify-content-between">
                                        <div className="arrow_back mb-md-0 mb-4">
                                            <a onClick={() => navigate(-1)} className="d-flex align-items-center">
                                                <span>
                                                    <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.86245 2.225L8.37912 0.75L0.137451 9L8.38745 17.25L9.86245 15.775L3.08745 9L9.86245 2.225Z" fill="#131313" />
                                                    </svg>
                                                </span>
                                                <span onClick={() => navigate(-1)} className="ps-3">Back</span>
                                            </a>
                                        </div>
                                        <div className>
                                            <p className="fs-6 text-gray">Already Have Account?<Link to="/login" className="text-purple ps-2">Sign In</Link></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="btm_div form-section">
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <h2 className="text-purple poppins fw-600">Create A Teacher Account</h2>
                                    </div>
                                    <form onSubmit={handleSubmit(onSubmit)} className="custom_form mt-4" action id>
                                        <Controller name="imageUpload" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                            <>
                                                <div className="avatar-upload">
                                                    <div className="avatar-edit">
                                                        {/* <input name="file" type="file" id="imageUpload" accept=".png, .jpg, .jpeg" /> */}
                                                        <input type="file" className="file-input" id="imageUpload" accept="image/*" onInput={(e) => handleImageChange(e.target.files[0])} />
                                                        {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.file?.message}</span></p> */}

                                                        <label htmlFor="imageUpload" />
                                                    </div>
                                                    <div className="avatar-preview">
                                                        {/* <div id="imagePreview" style={{ backgroundImage: `url(${profileImageState ? profileImageState : "./assets/images/kids-profile-demo.png"})` }}>
                                                                </div> */}
                                                        <div id="imagePreview" style={{ backgroundImage: `url(${profileImageState ? profileImageState : process.env.PUBLIC_URL + "/assets/images/kids-profile-demo.png"})` }}>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )} />

                                        <div className="form-group mb-4">
                                            <label className="mb-2">full name</label>
                                            <input name="full_name" {...register("full_name")} type="text" className="form-control" placeholder />
                                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.full_name?.message}</span></p>
                                        </div>
                                        <div className="form-group mb-4">
                                            <label className="mb-2">Email ID</label>
                                            <input name="email"  {...register("email")} value={email} type="text" className="form-control" placeholder disabled />
                                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.email?.message}</span></p>
                                        </div>
                                        {loginType == "S" ?
                                            <div className="form-group mb-4">
                                                <div className="d-flex justify-content-between position-relative">
                                                    <label className="mb-2">Password</label>
                                                    <Link onClick={handleTogglePassword}
                                                        style={{ position: "absolute", right: "20px", zIndex: "9999", bottom: "-36px" }}
                                                    >
                                                        <span>
                                                            {showPassword ? (
                                                                <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#666666">
                                                                    <path d="M0 0h24v24H0z" fill="none" />
                                                                    <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                                                                </svg>
                                                            ) : (
                                                                <svg width={19} height={17} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M17.0194 0.881336L16.2835 0.145393C16.0755 -0.0625975 15.6915 -0.0305884 15.4515 0.257357L12.8913 2.80132C11.7392 2.30538 10.4754 2.06538 9.14731 2.06538C5.19519 2.08132 1.77141 4.38529 0.12329 7.69749C0.0272626 7.90549 0.0272626 8.16141 0.12329 8.3374C0.891223 9.90543 2.04329 11.2015 3.4833 12.1774L1.3873 14.3054C1.1473 14.5454 1.11529 14.9293 1.27534 15.1374L2.01128 15.8733C2.21928 16.0813 2.60326 16.0493 2.84326 15.7613L16.8912 1.71345C17.1952 1.47358 17.2272 1.08962 17.0192 0.881611L17.0194 0.881336ZM9.9953 5.71322C9.72329 5.6492 9.43534 5.56925 9.16332 5.56925C7.80327 5.56925 6.71538 6.65727 6.71538 8.01718C6.71538 8.28919 6.7794 8.57714 6.85936 8.84916L5.78724 9.90519C5.46728 9.34524 5.2913 8.72114 5.2913 8.01722C5.2913 5.88924 7.00332 4.17722 9.1313 4.17722C9.83536 4.17722 10.4593 4.3532 11.0193 4.67316L9.9953 5.71322Z"
                                                                        fill="#666666"
                                                                        fillOpacity="0.8"
                                                                    />
                                                                    <path
                                                                        d="M18.1714 7.69743C17.6115 6.57738 16.8754 5.56945 15.9635 4.75342L12.9875 7.69743V8.01738C12.9875 10.1454 11.2754 11.8574 9.14745 11.8574H8.8275L6.93951 13.7454C7.64357 13.8893 8.37952 13.9854 9.09952 13.9854C13.0516 13.9854 16.4754 11.6814 18.1235 8.35325C18.2675 8.12918 18.2675 7.90527 18.1715 7.69726L18.1714 7.69743Z"
                                                                        fill="#666666"
                                                                        fillOpacity="0.8"
                                                                    />
                                                                </svg>
                                                            )}
                                                        </span>
                                                    </Link>
                                                </div>
                                                <input name="password"
                                                    {...register("password")}
                                                    type={showPassword ? 'text' : 'password'}
                                                    placeholder=""
                                                    className="form-control" />
                                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.password?.message}</span></p>
                                            </div> : <></>
                                        }
                                        {/* <div className="form-group mb-4">
                          <label className="mb-2 d-block">Phone Number</label>
                          <input type="text" name="phone" {...register("phone")} id="mobile_code" className="form-control w-100" placeholder />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.phone?.message}</span></p>
                        </div> */}


                                        <div className="form-group mb-4">
                                            <label className="mb-2 d-block">Phone Number</label>
                                            <PhoneInput placeholder="Enter phone number" name="phone" {...register("phone")}
                                                id="phone" country="ca" onChange={(value, country) => handleOnChange(value, country)} onlyCountries={allowedCountries} />
                                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.phone?.message}</span></p>
                                        </div>
                                        <div className="form-group mb-4">
                                            <label className="mb-2">Country</label>
                                            <select
                                                className="form-select"
                                                {...register("country")}
                                                name="country"
                                                onChange={(e) => handleCountryChange(e)}
                                                aria-label="Default select example"
                                            >
                                                <option value="">Select Country</option>
                                                {allowedCountries.map((countryCode) => {
                                                    const country = selectCountryData[countryCode];
                                                    return (
                                                        <option
                                                            key={countryCode}
                                                            value={countryCode}
                                                            selected={CountryData === country.name}
                                                        >
                                                            {country.flag} {country.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                            <p className='mt-2 font-bold col-red'>
                                                <span style={{ color: 'red' }}>{errors.country?.message}</span>
                                            </p>
                                        </div>
                                        <div className="form-group mb-4">
                                            <label className="mb-2">Time Zone</label>
                                            <Select
                                                options={timezones}
                                                value={timezones.find((tz) => tz.value === selectedTimezone)}
                                                onChange={handleChange}
                                                isSearchable={true}
                                                placeholder="Search for a timezone..."
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        border: '1px solid rgba(102, 102, 102, 0.35)',
                                                        borderRadius: '12px',
                                                        padding: '5px 10px',
                                                        height: '46px',
                                                    }),
                                                    indicatorsContainer: (provided) => ({
                                                        ...provided,
                                                        display: 'none', // This hides the dropdown arrow
                                                    }),
                                                }}
                                                isDisabled
                                            />
                                        </div>
                                        <div className="form-group mb-4">
                                            <div className="form-check">
                                                <input className="form-check-input rounded-1" type="checkbox" checked={isChecked} onChange={handleCheckboxChange} id="invalidCheck1" />
                                                <label className="form-check-label ps-3" htmlFor="invalidCheck1">
                                                    Do You Have Any Referral Code?<br />

                                                </label>

                                                {/* <p className='mt-2 font-bold col-red'><span style={{ marginLeft: '-21px', color: 'red' }}>{errors.checkbox?.message}</span></p> */}
                                            </div>

                                        </div>
                                        <div className="form-group mb-4">
                                            {isChecked && (
                                                <>
                                                    <label className="mb-2">Referral Code</label>
                                                    <input
                                                        name="referral_code"
                                                        {...register("referral_code")}
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                    {/* Add any additional elements or error messages here */}
                                                </>
                                            )}
                                        </div>
                                        <div className="form-group mb-2">
                                            <div className="form-check">
                                                <input className="form-check-input rounded-1" {...register('checkbox')} name='checkbox' type="checkbox" defaultValue id="invalidCheck" />
                                                <label className="form-check-label ps-3" htmlFor="invalidCheck">
                                                    By continuing, you agree to the<br />
                                                    <span className="fw-600 text-purple" style={{ "marginRight": "3px" }}>Terms of Service</span>and <span className="fw-600 text-purple">Privacy Policy.</span>
                                                </label>
                                                <p className='mt-1 font-bold col-red'><span style={{ marginLeft: '-21px', color: 'red' }}>{errors.checkbox?.message}</span></p>
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            {/* <Link to="/signup-teacher" className="btn-theme bg-yellow text-center d-block">Next</Link> */}
                                            <button type='submit' className="col-md-12 btn-theme bg-yellow text-center fw-600 d-block" >
                                                {/* <Link to="/sign_in_step3">Next</Link> */}Next
                                            </button>
                                        </div>
                                    </form>
                                    <div className="position-relative or_sec">
                                        <div className="position-absolute">OR</div>
                                    </div>
                                    <div className>
                                        <a onClick={(e) => handleSocialSignin(facebook, "F")} className="btn-theme btn-prple-border d-block text-center my-4">
                                            <span>
                                                <svg width={33} height={33} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="16.5" cy="16.5" r={14} fill="#0C82EE" />
                                                    <path d="M21.7137 20.7816L22.3356 16.8301H18.4452V14.267C18.4452 13.1857 18.9877 12.1311 20.7302 12.1311H22.5V8.76699C22.5 8.76699 20.8945 8.5 19.3603 8.5C16.1548 8.5 14.0617 10.3929 14.0617 13.8184V16.8301H10.5V20.7816H14.0617V30.3345C14.7767 30.444 15.5082 30.5 16.2534 30.5C16.9986 30.5 17.7302 30.444 18.4452 30.3345V20.7816H21.7137Z" fill="white" />
                                                </svg>
                                            </span>
                                            <span className='ms-3'>Continue with Facebook</span>
                                        </a>
                                        <a onClick={(e) => handleSocialSignin(google, "G")} className="btn-theme btn-prple-border d-block text-center ">
                                            <span>
                                                <svg width={24} height={25} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M22.501 12.7331C22.501 11.8698 22.4296 11.2398 22.2748 10.5864H12.2153V14.483H18.12C18.001 15.4514 17.3582 16.9097 15.9296 17.8897L15.9096 18.0202L19.0902 20.4349L19.3106 20.4564C21.3343 18.6247 22.501 15.9297 22.501 12.7331Z" fill="#4285F4" />
                                                    <path d="M12.214 23.0001C15.1068 23.0001 17.5353 22.0667 19.3092 20.4567L15.9282 17.89C15.0235 18.5083 13.8092 18.94 12.214 18.94C9.38069 18.94 6.97596 17.1083 6.11874 14.5767L5.99309 14.5871L2.68583 17.0955L2.64258 17.2133C4.40446 20.6433 8.0235 23.0001 12.214 23.0001Z" fill="#34A853" />
                                                    <path d="M6.12046 14.5767C5.89428 13.9234 5.76337 13.2233 5.76337 12.5C5.76337 11.7767 5.89428 11.0767 6.10856 10.4234L6.10257 10.2842L2.75386 7.7356L2.64429 7.78667C1.91814 9.21002 1.50146 10.8084 1.50146 12.5C1.50146 14.1917 1.91814 15.79 2.64429 17.2133L6.12046 14.5767Z" fill="#FBBC05" />
                                                    <path d="M12.2141 6.05997C14.2259 6.05997 15.583 6.91163 16.3569 7.62335L19.3807 4.73C17.5236 3.03834 15.1069 2 12.2141 2C8.02353 2 4.40447 4.35665 2.64258 7.78662L6.10686 10.4233C6.97598 7.89166 9.38073 6.05997 12.2141 6.05997Z" fill="#EB4335" />
                                                </svg>
                                            </span>
                                            <span className='ms-3'>Continue with Google</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Modals isOpen={modalIsOpen} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal" >
                <SendOtpModel message={`We have sent you an verification code to ${watch('phone')}`} type={`Signup2`} setIsOpen={setIsOpen} isLoader={isLoader} />
            </Modals>
        </>
    )
}
