import React, { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { express_account_callback } from '../../../api/apiHandler';
import JqueryLoad from '../../../JqueryLoad/JqueryLoad'

import OFooter_Dashboard from '../OInclude/OFooter_Dashboard';
import OHeader_Dashboard from '../OInclude/OHeader_Dashboard';

export default function OsetupLink({ isLoader }) {
  const navigate = useNavigate();
  const location = useLocation();
  var after = useParams();
  console.log('after111 :', after);

  // var type = location?.search?.split("/")[1];
  // console.log('type :', type);

  const AddAccount = async () => {
    try {
      let send_data= {
          accountId : after?.id
        }
        console.log('send_data :', send_data);

        const response = await express_account_callback(send_data);
        console.log('express_account_callback :', response);
        // isLoader(false)
        if (response?.code == 1) {
           navigate('/organization-setup-account')
        } else {
        
        }
    } catch (err) {
        console.log('express_account_callback_err :', err);
    }
}

useEffect(()=>{
  AddAccount();
},[])

  return (
    <>
      <JqueryLoad />
      <OHeader_Dashboard />
      <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="#" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <div className="class-content">
                        <div className>
                            {/*start*/}
                            <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                                <span>
                                    <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                                    </svg>
                                    <span  className="ms-2">Back</span>
                                </span></a>
                            {/*end*/}
                            {/*start*/}
                            <div className>
                                <div>
                                    <div className="row">
                                        <div className="col-xl-9">
                                            <div className="d-flex mb-4 justify-content-between">
                                                <h2 className>Teacher Account</h2>
                                            </div>
                                            <div className="lead_request pt-2">
                                                <div className="">
                                                    <div className="lead_req_img d-flex position-relative align-items-center">
                                                        <div className="ms-3">
                                                            <span className="fw-600"></span>
                                                        </div>
                                                    </div>
                                                    <div className="d-md-flex align-items-end  justify-content-between ">
                                                        {/* <Link onClick={(e) => AddAccount()} className="text-purple fs-5 text-decoration-underline" >Setup My Account</Link> */}
                                                        <div className="button">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
      <OFooter_Dashboard />
    </>
  )
}