import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import Slider from "react-slick";
import JqueryLoad from "../../../../JqueryLoad/JqueryLoad";
import PFooter_Dashboard from "../../Pinc/PFooter_Dashboard";
import PHeader_Dashboard from "../../Pinc/PHeader_Dashboard";
import {
  recently_viewed,
  quiz_details,
  quiz_available_times,
  other_classes,
  quiz_meet_the_teacher,
  follow_unfollow,
  favorites,
} from "../../../../api/apiHandler";
import MeetTheTeacher from "../meetTheTeacher";
import SimilarItemsLikeThis from "../similarItemsLikeThis";
import { TOAST_ERROR } from "../../../../utils/common.service";
import CommonShareModal from "../commonShareModal";
import { BASE_NAME } from "../../../../Config";
import ViewTeacherLink from "../viewTeacherLink";
import SubscribeModal1 from "../../Pinc/SubscribeModals/SubscribeModal1";
import SubscribeModal2 from "../../Pinc/SubscribeModals/SubscribeModal2";
import SubscribeModal3 from "../../Pinc/SubscribeModals/SubscribeModal3";

function PQuizzesDetails() {
  const location = useLocation();
  const navigate = useNavigate();

  const { id } = useParams();
  const teacher_id = location?.search?.split("=")[1];
  const userType = localStorage.getItem('userType')
  var [recently_Viewed, set_recently_Viewed] = useState([]);

  var [Quiz_Details, set_Quiz_Details] = useState(null);
  console.log("🚀 ~ PQuizzesDetails ~ Quiz_Details:", Quiz_Details);

  var [quiz_available_Times, set_quiz_available_Times] = useState([]);

  var [quiz_meet_the_Teacher, set_quiz_meet_the_Teacher] = useState(null);
  var [teacher_review, set_teacher_review] = useState([]);
  const [visibleReviews, setVisibleReviews] = useState(1); // Number of reviews to initially display
  const [learnerData, setLearnerData] = useState([]);

  var [other_Classes, set_other_Classes] = useState([]);
  const [subscribeModal1Data, setSubscribeModal1Data] = useState(null);
  const [totalSubscriptionAmount, setTotalSubscriptionAmount] = useState("");

  useEffect(() => {
    parents_quiz_Details();
    available_quize_Details();
    other_Classes_Details();
    quiz_meet_the_teacher_details();
    recently_viewed_details();
  }, []);

  const handleRender = (type) => {
    switch (type) {
      case 1:
        other_Classes_Details();
        break;
      case 2:
        recently_viewed_details();
        break;
      default:
        break;
    }
  };

  const handleFollowUnfollow = async (teacher_id, type) => {
    try {
      const { code, message } = await follow_unfollow({ teacher_id });
      if (code == 1) {
        handleRender(type);
      } else TOAST_ERROR(message);
    } catch (err) {
      TOAST_ERROR(err.message);
    }
  };

  const handleFavorite = (id, type) => {
    favorites({ classes_id: id, activity_type: "quiz" }).then(({ code, message }) => {
      if (code == 1) {
        handleRender(type);
        !type && parents_quiz_Details();
      } else TOAST_ERROR(message);
    });
  };

  const parents_quiz_Details = () => {
    quiz_details({ quiz_id: id }).then((resposnse) => {
      if (resposnse?.code == 1) {
        set_Quiz_Details(resposnse.data?.[0]);
      } else {
        set_Quiz_Details(null);
      }
    });
  };

  const available_quize_Details = () => {
    quiz_available_times({ quiz_id: id }).then((resposnse) => {
      if (resposnse?.code == 1) {
        set_quiz_available_Times(resposnse.data);
      } else {
        set_quiz_available_Times([]);
        console.log("Data Not Found");
      }
    });
  };

  const other_Classes_Details = () => {
    other_classes({ teacher_id: teacher_id }).then((resposnse) => {
      if (resposnse?.code == 1) {
        set_other_Classes(resposnse.data);
      } else {
        set_other_Classes([]);
        console.log("Data Not Found");
      }
    });
  };

  const quiz_meet_the_teacher_details = (data) => {
    quiz_meet_the_teacher({ quiz_id: id }).then((resposnse) => {
      console.log(resposnse, "quiz_meet_the_teacher_details");

      if (resposnse?.code == 1) {
        set_quiz_meet_the_Teacher(resposnse.data?.[0]);
        set_teacher_review(resposnse.data[0].meet_the_teacher);
        console.log(resposnse.data[0].meet_the_teacher, "esposnse.data.meet_the_teacher");
      } else {
        console.log("Data Not Found");
      }
    });
  };

  const recently_viewed_details = (data) => {
    recently_viewed(data).then((resposnse) => {
      if (resposnse?.code == 1) {
        set_recently_Viewed(resposnse.data);
      } else {
        console.log("Data Not Found");
      }
    });
  };

  const handleShowMoreReviews = () => {
    setVisibleReviews(teacher_review.length); // Show all reviews
  };

  let able_tms_slider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let class_slider = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  return (
    <div>
      <>
        <JqueryLoad />
        <PHeader_Dashboard />
        <main className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content custom_btm_brdr">
            <div className="mb-3">
              <button className="btn btn-custom text-capitalize custom-back" onClick={() => navigate(-1)}>
                {" "}
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86245 2.225L8.37912 0.75L0.137451 9L8.38745 17.25L9.86245 15.775L3.08745 9L9.86245 2.225Z" fill="#131313" />
                  </svg>
                </span>{" "}
                Back
              </button>
            </div>
            <section>
              <h2>Find Quizes</h2>
              <div className="class_tag my-lg-5 my-4 d-flex align-items-center">
                <span>
                  {Quiz_Details?.main_category_image && <img width={30} height={28} src={Quiz_Details?.main_category_image} alt="main_cat" />}
                  {/* <svg width={30} height={28} viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M29.2389 9.16506C28.3584 7.35918 26.846 6.34807 25.3829 5.36908C24.2925 4.63859 23.2643 3.94881 22.5359 2.96125L22.401 2.77917C21.9725 2.20292 21.4841 1.54955 21.4091 1.00114C21.3341 0.446314 20.8971 0.0607187 20.2759 0.0778563C19.7146 0.116416 19.2798 0.583415 19.2798 1.14681V20.2338C18.3822 19.6897 17.2768 19.3555 16.0665 19.3555C13.1145 19.3555 10.711 21.277 10.711 23.6399C10.711 26.0027 13.1145 27.9243 16.0665 27.9243C19.0206 27.9243 21.422 26.0027 21.422 23.6399V11.1552C23.0308 11.77 25.6635 13.3359 26.3876 16.9926C26.2526 17.1919 26.1241 17.4082 25.9741 17.5775C25.5821 18.0209 25.6271 18.6978 26.0705 19.0877C26.5118 19.4819 27.1888 19.4347 27.5808 18.9913C28.9218 17.4682 29.8451 15.0561 29.9886 12.6975C30.0593 11.5343 29.8001 10.3133 29.2389 9.16506ZM1.0711 2.21791H13.9243C14.5155 2.21791 14.9954 1.73806 14.9954 1.14681C14.9954 0.555566 14.5155 0.0757141 13.9243 0.0757141H1.0711C0.479852 0.0757141 0 0.555566 0 1.14681C0 1.73806 0.479852 2.21791 1.0711 2.21791ZM1.0711 8.6445H13.9243C14.5155 8.6445 14.9954 8.16465 14.9954 7.5734C14.9954 6.98216 14.5155 6.50231 13.9243 6.50231H1.0711C0.479852 6.50231 0 6.98216 0 7.5734C0 8.16465 0.479852 8.6445 1.0711 8.6445ZM7.49769 12.9289H1.0711C0.479852 12.9289 0 13.4087 0 14C0 14.5912 0.479852 15.0711 1.0711 15.0711H7.49769C8.08894 15.0711 8.56879 14.5912 8.56879 14C8.56879 13.4087 8.08894 12.9289 7.49769 12.9289ZM7.49769 19.3555H1.0711C0.479852 19.3555 0 19.8353 0 20.4266C0 21.0178 0.479852 21.4977 1.0711 21.4977H7.49769C8.08894 21.4977 8.56879 21.0178 8.56879 20.4266C8.56879 19.8353 8.08894 19.3555 7.49769 19.3555Z"
                    fill="#FFC924"
                  />
                </svg> */}
                </span>
                {Quiz_Details?.main_category ? (
                  <span className="ms-2 fw-bold text text-capitalize text-purple">{Quiz_Details?.main_category}</span>
                ) : (
                  <span className="fw-600 d-block">N/A</span>
                )}
              </div>
              <div className="class_dtls_sec">
                <h1 className="mb-2">{Quiz_Details?.title}</h1>
                <div className="row">
                  <div className="bg-transparent col-lg-6">
                    <div className="justify-content-between">
                      <div>
                        <div className="clss_detls_ctn mt-lg-5 mt-md-4 mt-3">
                          <p className="text-capitalize text-break">{Quiz_Details?.summary}</p>
                        </div>
                        <div className="payment-week bg-transparent my-4">
                          <div className>
                            <span className="payment_price fw-bold text-purple">{Quiz_Details?.currency_symbol}{Quiz_Details?.price}</span>
                          </div>
                        </div>
                        <ul className="class_tag d-flex flex-wrap">
                          <li className="px-3 py-2 rounded-3">
                            <span className="text-gray45 fw-500 text-capitalize">Age</span>
                            <span className="fw-600 d-block">
                              {Quiz_Details?.min_age}-{Quiz_Details?.max_age}
                            </span>
                          </li>
                          <li className="px-3 py-2 rounded-3">
                            <span className="text-gray45 fw-500 text-capitalize">Quizzes meets</span>
                            <span className="fw-600 d-block">{Quiz_Details?.class_meets ? Quiz_Details?.class_meets : "Once A Week"}</span>
                          </li>
                          {/* <li className="px-3 py-2 rounded-3">
                          <span className="text-gray45 fw-500 text-capitalize">Class Length</span>
                          <span className="fw-600 d-block">{Quiz_Details?.length}</span>
                        </li>
                        <li className="px-3 py-2 rounded-3">
                          <span className="text-gray45 fw-500 text-capitalize">Class Duration</span>
                          <span className="fw-600 d-block">{Quiz_Details?.duration}</span>
                        </li> */}
                          {/* <li className="px-3 py-2 rounded-3">
                          <span className="text-gray45 fw-500 text-capitalize">Class size</span>
                          <span className="fw-600 d-block">{Quiz_Details?.class_size}</span>
                        </li>
                        <li className="px-3 py-2 rounded-3">
                          <span className="text-gray45 fw-500 text-capitalize">Class Type</span>
                          <span className="fw-600 d-block">{Quiz_Details?.type}</span>
                        </li> */}
                          {/* <li className="px-3 py-2 rounded-3">
                            <span className="text-gray45 fw-500 text-capitalize">Number Of Learners Completed This Class</span>
                            <span className="fw-600 d-block">
                              {Quiz_Details?.min_learner}-{Quiz_Details?.max_learner}
                            </span>
                          </li> */}
                        </ul>
                        {/* <div className="mt-4 mb-lg-0 mb-4 find_btm_class">
                          <div className="d-flex mb-3">
                            <span className="find_btm_class_left fw-500 fs-6 text-purple text-capitalize">Pre-Requisite :</span>
                            <p className=" find_btm_class_right fw-500 fs-6 text-capitalize">{Quiz_Details?.pre_requisites}</p>
                          </div>
                          <div className="d-flex">
                            <span className="find_btm_class_left fw-500 fs-6 text-purple text-capitalize">Next-Class :</span>
                            <p className="find_btm_class_right fw-500 fs-6 text-capitalize">{Quiz_Details?.next_class}</p>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="class_image_box col-lg-6">
                    <div className="class_img position-relative">
                      <div className="video-container position-static pb-0">
                        {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                        <video id="video" className="position-static rounded-3" loop width="100%" controls="hide">
                          <source src={Quiz_Details?.video_file} type="video/mp4" />
                        </video>
                      </div>
                      <div className="d-flex bottom-info align-items-center justify-content-between position-static bg-diff-yellow-op-1 rounded-3">
                        <div className="d-flex align-items-center">
                          {/* <img src={class_Details?.image} alt="user1" /> */}
                          <img
                            src={
                              quiz_meet_the_Teacher?.profile_image ? quiz_meet_the_Teacher?.profile_image : "./assets/images/NicePng_watsapp-icon-png_9332131.png"
                            }
                            alt="user1"
                          />

                          <span className="tech_nm ms-2">{Quiz_Details?.teacher_name}</span>
                          <img
                            src={Quiz_Details?.teacher_country}
                            alt={`${Quiz_Details?.teacher_country
                              } flag`}
                            style={{
                              width: '23px',
                              height: '15px',
                              objectFit: 'cover',
                              marginRight: '8px',

                            }}
                            className="ms-2 det_flag_class"
                          />
                        </div>
                        <div className="star_rat">
                          <span>
                            <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z"
                                fill="#FDC840"
                              />
                            </svg>
                          </span>
                          <span className="fw-600">
                            {Quiz_Details?.teacher_ratings}
                            <span className="text-gray fw-500">({Quiz_Details?.teacher_review})</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <ul className="btm_cls_dtls mt-4 d-flex align-items-center justify-content-end">
                      <li className="me-4 pointer">
                        <a onClick={() => handleFavorite(Quiz_Details?.id)}>
                          <span>
                            {Quiz_Details?.is_favourite ? (
                              <svg width={29} height={25} viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="m15.517 27.228-2.083-1.897C6.034 18.621 1.15 14.195 1.15 8.764 1.15 4.34 4.628.862 9.052.862a8.62 8.62 0 0 1 6.465 3.004A8.62 8.62 0 0 1 21.983.862c4.426 0 7.903 3.478 7.903 7.902 0 5.431-4.886 9.857-12.284 16.581z"
                                  fill="#570861"
                                />
                              </svg>
                            ) : (
                              <svg width={29} height={25} viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M26.1155 2.47995C23.0354 -0.689867 18.0248 -0.689867 14.9455 2.47995L14.2303 3.21577L13.5155 2.47995C10.4363 -0.690296 5.4252 -0.690296 2.34595 2.47995C-0.670779 5.5854 -0.690368 10.5079 2.30052 13.9304C5.02841 17.0509 13.0737 23.7925 13.4151 24.0778C13.6375 24.2646 13.9158 24.3664 14.2028 24.3662L14.2299 24.3657C14.5166 24.3795 14.8055 24.2782 15.0447 24.0778C15.3861 23.7925 23.4322 17.0509 26.1609 13.93C29.1514 10.5079 29.1318 5.5854 26.1155 2.47995ZM24.3021 12.2082C22.1752 14.6405 16.3289 19.6569 14.2299 21.437C12.1309 19.6574 6.28587 14.6413 4.15941 12.2086C2.07295 9.8214 2.05336 6.42162 4.11398 4.3004C5.16637 3.21748 6.54845 2.67559 7.93053 2.67559C9.3126 2.67559 10.6947 3.21705 11.7471 4.3004L13.3192 5.91877C13.5012 6.10507 13.7361 6.22693 13.9898 6.26673C14.1945 6.3124 14.407 6.30451 14.608 6.2438C14.809 6.18309 14.9919 6.07149 15.1402 5.9192L16.7131 4.3004C18.8183 2.13413 22.2427 2.13456 24.3467 4.3004C26.4073 6.42162 26.3877 9.8214 24.3021 12.2082Z"
                                  fill="#570861"
                                />
                              </svg>
                            )}
                          </span>
                          <span className="ms-2 text-purple fw-500">{Quiz_Details?.is_favourite ? "Saved" : "Save"}</span>
                        </a>
                      </li>
                      <li>
                        <a href="#" data-bs-toggle="modal" data-bs-target="#share_link_modal">
                          <span>
                            <svg width={30} height={28} viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M17.3438 8.37423C18.123 8.37423 18.75 7.74727 18.75 6.96798V6.49923V5.56173V2.74923L28.125 11.1867L18.75 19.6242V16.8117V15.8742V15.4055C18.75 14.6262 18.123 13.9992 17.3438 13.9992H16.875H11.25C7.10742 13.9992 3.75 17.3566 3.75 21.4992C3.75 21.9856 3.79102 22.4426 3.86719 22.8586C2.80664 21.4816 1.875 19.5129 1.875 16.8117C1.875 12.1535 5.6543 8.37423 10.3125 8.37423H16.875H17.3438ZM16.875 16.8117V17.7492V19.6242C16.875 20.3625 17.3086 21.0363 17.9883 21.3352C18.668 21.634 19.4531 21.5109 20.0039 21.0188L29.3789 12.5813C29.7715 12.2238 30 11.7199 30 11.1867C30 10.6535 29.7773 10.1496 29.3789 9.7922L20.0039 1.3547C19.4531 0.856649 18.6621 0.733602 17.9883 1.03829C17.3145 1.34298 16.875 2.01095 16.875 2.74923V4.62423V5.56173V6.49923H15H10.3125C4.61719 6.49923 0 11.1164 0 16.8117C0 23.4504 4.77539 26.4152 5.87109 27.0129C6.01758 27.0949 6.18164 27.1242 6.3457 27.1242C6.98438 27.1242 7.5 26.6027 7.5 25.9699C7.5 25.5305 7.24805 25.1262 6.92578 24.8274C6.375 24.3059 5.625 23.2805 5.625 21.4992C5.625 18.3938 8.14453 15.8742 11.25 15.8742H15H16.875V16.8117Z"
                                fill="#570861"
                              />
                            </svg>
                          </span>
                          <span className="ms-2 text-purple fw-500">Share</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            {/*end*/}
          </div>
          {/* <div className="class-content custom_btm_brdr">
            <section className="avilable_time slick_silder_button"> */}
          {/* <h2 className="mb-2">Available Times</h2>
              <div className="d-flex align-items-center mb-4">
                <span>
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M2.70469 14.9531L3.14531 14.1844C3.53437 13.5047 4.17188 13.0031 4.92656 12.7875L7.64062 12.0141C8.44687 11.7844 9 11.0484 9 10.2094V8.33906C9 7.82344 9.29062 7.35469 9.75 7.125C10.2094 6.89531 10.5 6.42656 10.5 5.91094V4.08281C10.5 3.35156 11.1984 2.82187 11.9016 3.02344C12.6562 3.23906 13.2422 3.84375 13.4344 4.60781L13.5656 5.13281C13.7625 5.925 14.2781 6.60469 14.9859 7.00781L15.3656 7.22344C16.0687 7.62188 16.5 8.37187 16.5 9.17812V9.56719C16.5 10.1625 16.2609 10.7344 15.8391 11.1562L15.6562 11.3391C15.2344 11.7609 14.6625 12 14.0672 12H12.0469C11.5266 12 11.0109 12.1359 10.5562 12.3937L8.93906 13.3172C8.7375 13.4344 8.58281 13.6219 8.50781 13.8422C8.35781 14.2922 8.55937 14.7797 8.98594 14.9906L9.2625 15.1313C9.57188 15.2859 9.93281 15.3141 10.2609 15.2016L11.3484 14.8406C11.7328 14.7141 12.1547 14.8594 12.375 15.1922C12.5953 15.5203 12.5719 15.9562 12.3187 16.2609L11.6812 17.025C11.2125 17.5875 11.2172 18.4078 11.6953 18.9609L12.4313 19.8188C12.8438 20.3016 12.9094 20.9906 12.5953 21.5391L12.4828 21.7359C12.3187 21.7453 12.1594 21.75 11.9953 21.75C7.64531 21.75 3.95625 18.8953 2.70469 14.9531ZM21.75 12C21.75 13.725 21.3 15.3469 20.5125 16.7578L19.3125 16.275C18.5766 15.9797 18.1969 15.1594 18.4453 14.4094L19.2375 12.0328C19.4016 11.5453 19.8 11.175 20.2969 11.0531L21.6609 10.7109C21.7172 11.1328 21.7453 11.5641 21.7453 12H21.75ZM24 12C24 5.37188 18.6281 0 12 0C5.37188 0 0 5.37188 0 12C0 18.6281 5.37188 24 12 24C18.6281 24 24 18.6281 24 12Z"
                      fill="#570861"
                    />
                  </svg>
                </span>
                <span className="ms-2">Indian Standard Time</span>
              </div> */}
          {/*start*/}
          {/* <div className="avilable_box able_tms_slider  pt-2">
              <Slider {...able_tms_slider}>
              <div className="pe-3">
                <div className="tme_box" data-bs-toggle="modal" data-bs-target="#subscription_modal_1">
                  <h2 className="poppins fw-600 text-dif-black">Next On Friday, May 16th</h2>	
                  <div className="actul_tme my-4">
                    <span className="text-gray45 fw-600">Class Time</span>
                    <p className="fs-5">08:00am - 10:00am</p>
                  </div>
                  <div className="num_spot">
                    <span className="fw-600 mb-2 text-purple d-block">Number Of Spot Left</span>
                    <span className="text-purple">2</span>
                  </div>
                  <div className="button mt-2">
                    <a href="#" className="btn-theme d-block text-center fw-500" tabIndex={0}>Subscribe</a>
                  </div>
                </div>
              </div>
              </Slider>
            </div> */}
          {/* <div className="avilable_box able_tms_slider pt-2">
                <Slider {...able_tms_slider}>
                  {quiz_available_Times.map((classItem, index) => (
                    <div className="pe-3" key={index}>
                      <div className="tme_box" data-bs-toggle="modal" data-bs-target="#subscription_modal_1">
                        <h2 className="poppins fw-600 text-dif-black">{classItem.formatted_date}</h2>
                        <div className="actul_tme my-4">
                          <span className="text-gray45 fw-600">Class Time</span>
                          <p className="fs-5">{classItem.formatted_time_range}</p>
                        </div>
                        <div className="num_spot">
                          <span className="fw-600 mb-2 text-purple d-block">Number Of Spot Left</span>
                          <span className="text-purple">{classItem.spots_left}</span>
                        </div>
                        {userType !== 'learner' &&

                          <div className="button mt-2">
                            <a
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#subscription_modal_1"
                              className="fw-500 btn-theme d-block text-center"
                              onClick={() => setSubscribeModal1Data(classItem)}
                            >
                              Subscribe
                            </a>
                          </div>
                        }
                      </div>
                    </div>
                  ))}
                </Slider>
              </div> */}

          {/*end*/}
          {/* <span className="text-center d-block mb-4">Don’t See What You’re looking For?</span>
              <div className="d-xl-flex justify-content-center text-center">
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#request_another_time"
                  className="btn-theme bg-purple d-inline-block me-xl-4 mb-xl-0 mb-4"
                >
                  Request Another Time
                </a>
                <a href="#" className="btn-theme bg-purple d-inline-block">
                  Request A Private Class
                </a>
              </div> */}
          {/* </section>
          </div> */}
          <div className="class-content pt-lg-5">
            {/* <section className="description_ctn">
              <h2 className="mb-5">Description</h2>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="poppins fw-600 accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Class Experience
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">{Quiz_Details?.class_exp}</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="poppins fw-600 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Learning goals
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">{Quiz_Details?.learning_goals}</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="poppins fw-600 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Homework
                    </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">{Quiz_Details?.homeworks}</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="poppins fw-600 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      Assessment
                    </button>
                  </h2>
                  <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                    <div className="accordion-body">{Quiz_Details?.assessment}</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFive">
                    <button
                      className="poppins fw-600 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      Parental guidance
                    </button>
                  </h2>
                  <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                    <div className="accordion-body">{Quiz_Details?.parental_guide}</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingSix">
                    <button
                      className="poppins fw-600 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      Content
                    </button>
                  </h2>
                  <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                    <div className="accordion-body">{Quiz_Details?.content}</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingsevan">
                    <button
                      className="poppins fw-600 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsesevan"
                      aria-expanded="false"
                      aria-controls="collapsesevan"
                    >
                      Learner supply
                    </button>
                  </h2>
                  <div id="collapsesevan" className="accordion-collapse collapse" aria-labelledby="headingsevan" data-bs-parent="#accordionExample">
                    <div className="accordion-body">{Quiz_Details?.supply_list}</div>
                  </div>
                </div>
              </div>
            </section> */}
            <MeetTheTeacher
              teacherDetails={quiz_meet_the_Teacher}
              handleShowMoreReviews={handleShowMoreReviews}
              teacher_review={teacher_review}
              visibleReviews={visibleReviews}
            />

            <section className="explore_new_class slick_silder_button slick_slider_white py-5">
              <div>
                <div className="title mb-4">
                  <h2>Other Quizes by {other_Classes[0].full_name}</h2>
                </div>
                <div className=" class_slider">
                  {other_Classes.length > 0 ? (
                    <Slider {...class_slider}>
                      {other_Classes.map((classItem, index) => (
                        <div key={index}>
                          <div className="single-box">
                            <div className="class_image_box">
                              <div className="class_img position-relative">
                                <div className="video-container">
                                  {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                                  <video id="video" loop width="100%" controls="hide" poster="./assets/images/video_image.png">
                                    <source src={classItem.video_file} type="video/mp4" />
                                  </video>
                                </div>
                                <div className="toggle like">
                                  <input type="checkbox" defaultChecked={classItem.is_favourite} onChange={() => handleFavorite(classItem.id, 1)} />
                                  <label />
                                </div>
                                {/* <a href="#" className="play_btn">
                                  <img src={`${BASE_NAME}/assets/images/play.png`} alt="play" />
                                </a> */}
                              </div>
                            </div>
                            <ViewTeacherLink data={classItem} />
                            <div className="class_room_info">
                              <div className="tag mb-3">
                                <a href="#" className="px-3 bg-yellow d-inline-block" tabIndex={0}>
                                  Music
                                </a>
                                <a
                                  onClick={() => handleFollowUnfollow(classItem.teacher_id, 1)}
                                  className="px-3 ms-2 bg-purple text-white border-purple d-inline-block"
                                  tabIndex={0}
                                >
                                  {classItem.is_follow === 1 ? "Following" : "Follow Me"}
                                </a>
                              </div>
                              <Link to={`/quize_detail/${classItem?.id}?teacher_id=${classItem?.teacher_id}`} >

                                <div className="class_title mb-4">
                                  <p className="text-truncate">{classItem.title} </p>
                                </div>
                                <div className="row mb-4">
                                  <div className="col-8">
                                    <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Time</span>
                                    <span className="fw-600">
                                      {classItem.start_time}-{classItem.end_time}{" "}
                                    </span>
                                  </div>
                                  <div className="col-4">
                                    <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Age</span>
                                    <span className="fw-600">
                                      {classItem.min_age}-{classItem.max_age}
                                    </span>
                                  </div>
                                </div>
                                <div className="row mb-4">
                                  <div className="col-8">
                                    <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Day</span>
                                    <span className="fw-600">Monday To Friday</span>
                                  </div>
                                  <div className="col-4">
                                    <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Price</span>
                                    <span className="fw-600">{classItem?.currency_code} {classItem?.currency_symbol}{classItem?.price}</span>
                                  </div>
                                </div>
                              </Link>
                              {userType !== 'learner' &&

                                <div className="button">
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#subscription_modal_1"
                                    className="fw-500 btn-theme d-block text-center"
                                    onClick={() => setSubscribeModal1Data(classItem)}
                                  >
                                    Subscribe
                                  </a>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  ) : (
                    <p>Data Not Found.</p>
                  )}
                </div>
              </div>
            </section>
            <section className="popular_topic">
              <div className="title mb-md-5 mb-3 text-center">
                <h2>Popular Topics</h2>
              </div>
              <div className="popular_item">
                <ul>
                  <li>
                    <a href="#" className="d-flex align-items-center">
                      <div className="topi_icon">
                        <img src={`/assets/images/topic/topic1.png`} alt="topic1" />
                      </div>
                      <span className="ms-4">Arts</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="d-flex align-items-center">
                      <div className="topi_icon">
                        <img src={`/assets/images/topic/topic2.png`} alt="topic2" />
                      </div>
                      <span className="ms-4">Health and Wellness</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="d-flex align-items-center">
                      <div className="topi_icon">
                        <img src={`/assets/images/topic/topic3.png`} alt="topic3" />
                      </div>
                      <span className="ms-4">Languages</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="d-flex align-items-center">
                      <div className="topi_icon">
                        <img src={`/assets/images/topic/topic4.png`} alt="topic4" />
                      </div>
                      <span className="ms-4">Life Skills</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="d-flex align-items-center">
                      <div className="topi_icon">
                        <img src={`/assets/images/topic/topic5.png`} alt="topic5" />
                      </div>
                      <span className="ms-4">Coding &amp; Tech</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="d-flex align-items-center">
                      <div className="topi_icon">
                        <img src={`/assets/images/topic/topic6.png`} alt="topic6" />
                      </div>
                      <span className="ms-4">Music</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="d-flex align-items-center">
                      <div className="topi_icon">
                        <img src={`/assets/images/topic/topic7.png`} alt="topic7" />
                      </div>
                      <span className="ms-4">Professional Training</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="d-flex align-items-center">
                      <div className="topi_icon">
                        <img src={`/assets/images/topic/topic8.png`} alt="topic8" />
                      </div>
                      <span className="ms-4">In-Person Classes</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="d-flex align-items-center">
                      <div className="topi_icon">
                        <img src={`/assets/images/topic/topic9.png`} alt="topic9" />
                      </div>
                      <span className="ms-4">Academic</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="d-flex align-items-center">
                      <div className="topi_icon">
                        <img src={`/assets/images/topic/topic10.png`} alt="topic9" />
                      </div>
                      <span className="ms-4">Privateschool</span>
                    </a>
                  </li>
                </ul>
              </div>
            </section>
            <SimilarItemsLikeThis
              title={"Quizes"}
              category_Details={{ main_category: Quiz_Details?.main_category, sub_category: Quiz_Details?.subject_category }}
              handleFollowUnfollow={handleFollowUnfollow}
              handleFavorite={handleFavorite}
              handleSubscription1Modaldata={(val) => setSubscribeModal1Data(val)}
            />
            <section className="explore_new_class slick_silder_button slick_slider_white py-5">
              <div>
                <div className="title mb-4">
                  <h2>Recently Viewed Quizes by You</h2>
                </div>
                <div className=" class_slider">
                  {recently_Viewed.length > 0 ? (
                    <Slider {...class_slider}>
                      {recently_Viewed.map((classItem, index) => (
                        <div key={index}>
                          <div className="single-box">
                            <div className="class_image_box">
                              <div className="class_img position-relative">
                                <div className="video-container">
                                  {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                                  <video id="video" loop width="100%" controls="hide" poster="">
                                    <source src={classItem.video_file} type="video/mp4" />
                                  </video>
                                </div>
                                <div className="toggle like">
                                  <input
                                    type="checkbox"
                                    defaultChecked={classItem.is_favourite}
                                    onChange={() => handleFavorite(classItem.quiz_id, 2)}
                                  />
                                  <label />
                                </div>
                                {/* <a href="#" className="play_btn">
                                  <img src={`${BASE_NAME}/assets/images/play.png`} alt="play" />
                                </a> */}
                              </div>
                            </div>
                            <ViewTeacherLink data={classItem} />
                            <div className="class_room_info">
                              <div className="tag mb-3">
                                <a href="#" className="px-3 bg-yellow d-inline-block" tabIndex={0}>
                                  Music
                                </a>
                                <a
                                  onClick={() => handleFollowUnfollow(classItem.teacher_id, 1)}
                                  className="px-3 ms-2 bg-purple text-white border-purple d-inline-block"
                                  tabIndex={0}
                                >
                                  {classItem.is_follow === 1 ? "Following" : "Follow Me"}
                                </a>
                              </div>
                              <Link to={`/quize_detail/${classItem?.class_id}?teacher_id=${classItem?.teacher_id}`} >

                                <div className="class_title mb-4">
                                  <p>{classItem.title}</p>
                                </div>
                                <div className="row mb-4">
                                  <div className="col-8">
                                    <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Time</span>
                                    <span className="fw-600">
                                      {classItem.start_time}-{classItem.end_time}
                                    </span>
                                  </div>
                                  <div className="col-4">
                                    <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Age</span>
                                    <span className="fw-600">
                                      {classItem.min_age}-{classItem.max_age}
                                    </span>
                                  </div>
                                </div>
                                <div className="row mb-4">
                                  <div className="col-8">
                                    <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Day</span>
                                    <span className="fw-600">{classItem.class_meets}</span>
                                  </div>
                                  <div className="col-4">
                                    <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Price</span>
                                    <span className="fw-600">{classItem?.currency_code} {classItem?.currency_symbol}{classItem?.price}</span>
                                  </div>
                                </div>
                              </Link>
                              {userType !== 'learner' &&

                                <div className="button">
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#subscription_modal_1"
                                    className="fw-500 btn-theme d-block text-center"
                                    onClick={() => setSubscribeModal1Data(classItem)}
                                  >
                                    Subscribe
                                  </a>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  ) : (
                    <p>Data Not Found.</p>
                  )}
                </div>
              </div>
            </section>
            {/*end-slider*/}
          </div>
        </main>
        <CommonShareModal />
        <SubscribeModal1 data={subscribeModal1Data} handleSubscriptionAmount={(val) => setTotalSubscriptionAmount(val)} setLearnerData={setLearnerData} />
        <SubscribeModal2 />
        <SubscribeModal3 data={subscribeModal1Data} totalSubscriptionAmount={totalSubscriptionAmount} activity_type={"quiz"}
          learnerData={learnerData}
        />
        <PFooter_Dashboard />
      </>
    </div>
  );
}

export default PQuizzesDetails;
