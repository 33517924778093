import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import JqueryLoad from '../../../JqueryLoad/JqueryLoad'
import OFooter_Dashboard from '../OInclude/OFooter_Dashboard';
import OHeader_Dashboard from '../OInclude/OHeader_Dashboard';
import Leeds_Filter from './Leeds_Filter'
import New_Headers from './New_Headers'
import New_Leads from './New_Leads'
import Rejected_class from './Rejected_class';
import Responded_class from './Responded_class';

export default function RequestClassO({isLoader}) {
    const navigate = useNavigate();

    const handleStepChange = (newStep) => setStep(newStep);

 const [CategoriesData,setCategoriesData] = useState(null)
 const [subCategoriesData,setSubCategoriesData] = useState([])
 const [classSize,setclassSize] = useState('')
 const [startDate,setStartDate] = useState('')
 const [endDate,setEndDate] = useState('')

    const [step, setStep] = useState(parseInt(localStorage.getItem("Leeds_Step", 1)) ? parseInt(localStorage.getItem("Leeds_Step", 1)) : 1);
    console.log('step :', step);

    useEffect(() => {
        localStorage.setItem("Leeds_Step", step)
    }, [step, parseInt(localStorage.getItem("Leeds_Step", 1))]);

    console.log('parseInt(localStorage.getItem("Leeds_Step",1)) :', localStorage.getItem("Leeds_Step", 1));
    const renderStep = () => {
        switch (localStorage.getItem("Leeds_Step", 1) ? parseInt(localStorage.getItem("Leeds_Step", 1)) : 1) {
            case 1:
                return (
                    <New_Leads CategoriesData={CategoriesData} subCategoriesData={subCategoriesData} classSize={classSize}  startDate={startDate} endDate={endDate} isLoader={isLoader}/>
                );
            case 2:
                return (
                    <Responded_class CategoriesData={CategoriesData} subCategoriesData={subCategoriesData} classSize={classSize} startDate={startDate} endDate={endDate} isLoader={isLoader}/>
                );
            case 3:
                return (
                    <Rejected_class CategoriesData={CategoriesData} subCategoriesData={subCategoriesData} classSize={classSize} startDate={startDate} endDate={endDate} isLoader={isLoader}/>
                );


            default:
                return null;
        }
    };

    return (
        <>
            <JqueryLoad />
            <OHeader_Dashboard />
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="#" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <div className="class-content">
                        <div className>
                            {/*start*/}
                            <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                                <span>
                                    <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                                    </svg>
                                    <span  className="ms-2">Back</span>
                                </span></a>
                            <div className>
                                <div className="d-xl-flex align-items-center mb-5 justify-content-between">
                                    <New_Headers onStepChange={handleStepChange} />
                                    <Leeds_Filter CategoriesData={CategoriesData} subCategoriesData={subCategoriesData} setCategoriesData={setCategoriesData} setSubCategoriesData={setSubCategoriesData} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} classSize={classSize} setclassSize={setclassSize} />
                               
                                </div>
                                <div className="my-4">
                                <Link to="/become-prefer-teacher-form" className="text-purple text-decoration-underline mb-sm-0 mb-4 d-inline-block">Fill Out The Form To Become A Preferred Teacher</Link>                                </div>
                                {renderStep()}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <OFooter_Dashboard />
        </>
    )
}
