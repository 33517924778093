import React, { useState, useEffect } from "react";
import { country_list_class, main_category_list, state_listing_classwise, subject_category_list } from "../../../api/apiHandler";

export default function PLeeds_Filter({
  CategoriesData,
  subCategoriesData,
  setCategoriesData,
  setSubCategoriesData,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  classSize,
  setclassSize,
}) {
  const [MainCatdata, setMainCatdata] = useState([]);
  var [SelectedCatID, setSelectedCatID] = useState([]);
  var [SelectedCategory, SetSelectedCategory] = useState([]);
  var [SelectedSubCat, setSelectedSubCat] = useState([]);
  var [SelectedSubCatErr, setSelectedSubCatErr] = useState(null);

  var [SelectedSubCatID, setSelectedSubCatID] = useState([]);
  var [SelectedSubCategory, SetSelectedSubCategory] = useState([]);

  const subjectsArray = [
    "Math",
    "English",
    "Science",
    "Nature & Space",
    "Social Sciences",
    "Business / Economics",
    "Accounting/Finance",
    "Engineering",
    "Medicine/Nursing",
    "Law",
    "Exam Preparation",
    "Homework Help",
  ];

  const languagesArray = [
    "English- ESL",
    "French",
    "Spanish",
    "Chinese-Mandarin, Cantonese",
    "Russian",
    "Japanese",
    "Latin",
    "Italian",
    "Sign Language",
    "German",
    "Greek",
  ];

  const languagesArray1 = ["Portuguese", "Hindi", "Korean", "Cantonese", "Arabic", "Dutch", "Vietnamese", "Hindi", "Sinhala", "Tamil"];

  const coding = [
    "Python Coding",
    "Scratch Coding",
    "Video Game Design",
    "C# Coding",
    "C++ Coding",
    "Java Coding",
    "Lua Coding",
    "HTML & CSS Coding",
  ];

  const technology = ["Robotics", "Snap Circuits", "Typing", "STEM", "Social Media", "3D Modeling", "Computer Science", "Artificial Intelligence"];

  const catArts = [
    "Visual Arts",
    "Drawing",
    "Painting",
    "Digital Art",
    "Photography",
    "Animation",
    "Crafts",
    "Crochet",
    "Sewing",
    "Video & Movie Making",
  ];

  const catArts1 = ["Clay Sculpting", "Architecture", "Makeup", "Graphic Design", "Fashion Design"];

  const performingArts = ["Dance", "Ballet", "Hip Hop", "Theater & Acting", "Improv Comedy"];

  const musicArray1 = ["Piano", "Guitar", "Voice/Singing", "Violin", "Ukulele", "Drums"];

  const musicArray2 = ["Clarinet", "Trumpet", "Flute", "Cello", "Saxophone", "French Horn", "Trombone"];

  const healthArray = ["Yoga", "Karate", "Workout & Fitness", "Cooking & Baking", "Nutrition", "Mindfulness", "Critical Thinking", "Growth Mindset"];

  const lifiSkillArray = [
    "Chess",
    "Memory & Focus Games",
    "Sports clubs",
    "Makeup Tutorials",
    "Leadership",
    "Financial Literacy",
    "Study Skills",
    "Adulting/Parenting",
    "Entrepreneurship",
    "Pet Care",
    "Internet Safety",
  ];

  const categoriesLabels = [
    "Accounting & Finance",
    "Business & Entrepreneurship",
    "Health and Wellness",
    "Skill Trades",
    "Beauty",
    "Medicine & Nursing",
    "Coding & Tech",
    "Law and Government",
    "Engineering",
  ];

  const careerServicesLabels = [
    "Interview Preparation",
    "Resume and cover letter",
    "Career Counseling",
    "College Admissions",
    "Skills Development",
    "Exam Preparation",
    "In-Person Seminars & Events",
    "Online Webinars & Events",
  ];

  const handleSelectedIngredients = (Categories_data) => {
    if (subCategoriesData.includes(Categories_data)) {
      let filteredArray = subCategoriesData.filter((item) => item !== Categories_data);
      setSubCategoriesData(filteredArray);
    } else {
      setSubCategoriesData((prevSelectSubject) => [...new Set([...prevSelectSubject, Categories_data])]);
    }
  };

  const handleSelectedsize = (data) => {
    console.log("setclassSize", data);
    setclassSize(data);
  };

  const MainCategory = () => {
    main_category_list().then((resposnse) => {
      if (resposnse?.code == 1) {
        setMainCatdata(resposnse?.data);
        setSelectedCatID([resposnse?.data[0]?.category_id]);
        SetSelectedCategory([resposnse?.data[0]?.category_name]);
      } else {
        console.log("Data Not Found");
        setMainCatdata([]);
      }
    });
  };

  useEffect(() => {
    MainCategory();
  }, []);

  const handleItemClick = (id, cat_name) => {
    if (SelectedCatID != id) {
      setSelectedSubCat([]);
      setSelectedSubCatID([]);
      SetSelectedSubCategory([]);
      setSubCategoriesData([]);
    }

    setSelectedCatID(id);
    SetSelectedCategory(cat_name);
    setCategoriesData(cat_name);
  };

  const SubCategoryList = () => {
    subject_category_list({ category_id: SelectedCatID }).then((resposnse) => {
      if (resposnse?.code == 1) {
        setSelectedSubCat(resposnse?.data);
        if (SelectedSubCatID.length > 0) {
          setSelectedSubCatErr("abcd");
        }
      } else {
        console.log("Data Not Found");
      }
    });
  };

  useEffect(() => {
    SubCategoryList();
  }, [SelectedCatID]);

  const Start_Date = (data) => {
    setStartDate(data);
  };

  const End_Date = (data) => {
    setEndDate(data);
  };

  const ResetFilter = () => {
   
    setStartDate("");
    setEndDate("");
    setSubCategoriesData([]);
  };

  return (
    <>
      <div className="fliter_list">
        <ul className="list-unstyled slider_filter_list justify-content-end d-flex flex-wrap align-items-center">
          <li className="ms-2 mb-2 d-md-none d-inline-block">
            <button
              className="btn border-purple rounded-cricle rounded-pill"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#rightOffcanvas"
              aria-controls="rightOffcanvas"
            >
              Categories
            </button>
          </li>
          <li className="ms-2 mb-2 d-md-inline-block d-none">
            <div className="dropdown">
              <button
                className="btn border-purple rounded-cricle rounded-pill dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
                onClick={() => {
                  setCategoriesData("Academic");
                }}
              >
                Categories
              </button>
              <div className="dropdown-menu subjects_drp filter_box">
                <div className="d-flex align-items-start">
                  <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    {MainCatdata?.map((mainCat, index) => {
                      return (
                        <button
                          className={`nav-link ${SelectedCatID == mainCat.category_id ? "active" : ""}`}
                          onClick={(e) => handleItemClick(mainCat.category_id, mainCat.category_name)}
                          id="v-pills-Academic-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-Academic"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-Academic"
                          aria-selected="true"
                        >
                          {mainCat.category_name}
                        </button>
                      );
                    })}
                    {/* <button
                      className="nav-link active"
                      id="v-pills-Academic-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-Academic"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-Academic"
                      aria-selected="true"
                      onClick={() => {
                        setSubCategoriesData([]);
                        setCategoriesData("Academic");
                      }}
                    >
                      Academic
                    </button>
                    <button
                      className="nav-link"
                      id="v-pills-Languages-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-Languages"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-Languages"
                      onClick={() => {
                        setSubCategoriesData([]);
                        setCategoriesData("Languages");
                      }}
                      aria-selected="false"
                    >
                      Languages
                    </button>
                    <button
                      className="nav-link"
                      id="v-pills-Coding-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-Coding"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-Coding"
                      onClick={() => {
                        setSubCategoriesData([]);
                        setCategoriesData("Coding & Tech");
                      }}
                      aria-selected="false"
                    >
                      Coding &amp; Tech
                    </button>
                    <button
                      className="nav-link"
                      id="v-pills-Arts-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-Arts"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-Arts"
                      onClick={() => {
                        setSubCategoriesData([]);
                        setCategoriesData("Arts");
                      }}
                      aria-selected="false"
                    >
                      Arts
                    </button>
                    <button
                      className="nav-link"
                      id="v-pills-Music-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-Music"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-Music"
                      onClick={() => {
                        setSubCategoriesData([]);
                        setCategoriesData("Music");
                      }}
                      aria-selected="false"
                    >
                      Music
                    </button>
                    <button
                      className="nav-link"
                      id="v-pills-Health-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-Health"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-Health"
                      onClick={() => {
                        setSubCategoriesData([]);
                        setCategoriesData("Health and Wellness");
                      }}
                      aria-selected="false"
                    >
                      Health and Wellness
                    </button>
                    <button
                      className="nav-link"
                      id="v-pills-Life-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-Life"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-Life"
                      onClick={() => {
                        setSubCategoriesData([]);
                        setCategoriesData("Life Skills");
                      }}
                      aria-selected="false"
                    >
                      Life Skills
                    </button>
                    <button
                      className="nav-link"
                      id="v-pills-Professional-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-Professional"
                      type="button"
                      role="tab"
                      onClick={() => {
                        setSubCategoriesData([]);
                        setCategoriesData("Professional Training");
                      }}
                      aria-controls="v-pills-Professional"
                      aria-selected="false"
                    >
                      Professional Training
                    </button> */}
                  </div>
                  <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-Academic"
                      role="tabpanel"
                      aria-labelledby="v-pills-Academic-tab"
                      tabIndex={0}
                    >
                      <div className="row">
                        {SelectedSubCat[0]?.category_name ? (
                          <>
                            {SelectedSubCat?.map((SubCat, index) => {
                              if (SubCat.category_name !== "Clubs" && SubCat.category_name !== "Competitions" && SubCat.category_name !== "Curriculum")
                                return (
                                  <ul key={index} className="col-md-6 col-sm-12 mb-2">
                                    <li className="headings">
                                      <span className="text-purple fw-600">{SubCat.category_name}</span>
                                    </li>
                                    {SubCat.category?.map((SubCat1, index1) => {
                                      return (
                                        <li>
                                          <a>
                                            <div className="form-check">
                                              <input
                                                onChange={(e) => handleSelectedIngredients(SubCat1.subject_name, e.target.checked)}
                                                checked={subCategoriesData.indexOf(SubCat1.subject_name) > -1}
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id={`flexCheckDefault_${index1}`}
                                              />
                                              <label className="form-check-label" htmlFor={`flexCheckDefault_${index1}`}>
                                                {SubCat1.subject_name}
                                              </label>
                                            </div>
                                          </a>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                );
                            })}
                          </>
                        ) : (
                          <ul className="row">
                            {SelectedSubCat?.map((SubCat, index) => {
                              return (
                                <li className="col-md-6 col-sm-12">
                                  <a>
                                    <div className="form-check">
                                      <input
                                        onChange={(e) => handleSelectedIngredients(SubCat.subject_name, e.target.checked)}
                                        checked={subCategoriesData.indexOf(SubCat.subject_name) > -1}
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue
                                        id={`flexCheckDefault_${index}`}
                                      />
                                      <label className="form-check-label" htmlFor={`flexCheckDefault_${index}`}>
                                        {SubCat.subject_name}
                                      </label>
                                    </div>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-Academic"
                      role="tabpanel"
                      aria-labelledby="v-pills-Academic-tab"
                      tabIndex={0}
                    >
                      <div className="row">
                        <ul className="col-md-12">
                          <li className="headings">
                            <span className="text-purple fw-600">Subjects</span>
                          </li>
                          {subjectsArray?.map((subject, index) => {
                            return (
                              <li>
                                <a>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      onChange={(e) => handleSelectedIngredients(subject, e.target.checked)}
                                      id={subject}
                                      checked={subCategoriesData.indexOf(subject) > -1}
                                    />
                                    <label className="form-check-label" htmlFor={subject}>
                                      {subject}
                                    </label>
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="v-pills-Languages" role="tabpanel" aria-labelledby="v-pills-Languages-tab" tabIndex={0}>
                      <div className="row">
                        <ul className="col-md-6">
                          {languagesArray?.map((language, index) => {
                            return (
                              <li>
                                <a>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      onChange={(e) => handleSelectedIngredients(language, e.target.check)}
                                      type="checkbox"
                                      defaultValue
                                      id={language}
                                      checked={subCategoriesData.indexOf(language) > -1}
                                    />
                                    <label className="form-check-label" htmlFor={language}>
                                      {language}
                                    </label>
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                        <ul className="col-md-6">
                          {languagesArray1?.map((language, index) => {
                            return (
                              <li>
                                <a>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      onChange={(e) => handleSelectedIngredients(language, e.target.check)}
                                      type="checkbox"
                                      defaultValue
                                      id={language}
                                      checked={subCategoriesData.indexOf(language) > -1}
                                    />
                                    <label className="form-check-label" htmlFor={language}>
                                      {language}
                                    </label>
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="v-pills-Coding" role="tabpanel" aria-labelledby="v-pills-Coding-tab" tabIndex={0}>
                      <div className="row">
                        <ul className="col-md-6">
                          <li className="headings">
                            <span className="text-purple fw-600">Coding</span>
                          </li>
                          {coding?.map((codingdata, index) => {
                            return (
                              <li>
                                <a>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      onChange={(e) => handleSelectedIngredients(codingdata, e.target.check)}
                                      type="checkbox"
                                      defaultValue
                                      id={codingdata}
                                      checked={subCategoriesData.indexOf(codingdata) > -1}
                                    />
                                    <label className="form-check-label" htmlFor={codingdata}>
                                      {codingdata}
                                    </label>
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                        <ul className="col-md-6">
                          <li className="headings">
                            <span className="text-purple fw-600">Technology</span>
                          </li>
                          {technology?.map((techdata, index) => {
                            return (
                              <li>
                                <a>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      onChange={(e) => handleSelectedIngredients(techdata, e.target.check)}
                                      type="checkbox"
                                      defaultValue
                                      id={techdata}
                                      checked={subCategoriesData.indexOf(techdata) > -1}
                                    />
                                    <label className="form-check-label" htmlFor={techdata}>
                                      {techdata}
                                    </label>
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="v-pills-Arts" role="tabpanel" aria-labelledby="v-pills-Arts-tab" tabIndex={0}>
                      <div className="row">
                        <ul className="col-md-6">
                          <li className="headings">
                            <span className="text-purple fw-600">Categories</span>
                          </li>
                          {catArts?.map((catdata, index) => {
                            return (
                              <li>
                                <>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      onChange={(e) => handleSelectedIngredients(catdata, e.target.check)}
                                      type="checkbox"
                                      defaultValue
                                      id={catdata}
                                      checked={subCategoriesData.indexOf(catdata) > -1}
                                    />
                                    <label className="form-check-label" htmlFor={catdata}>
                                      {catdata}
                                    </label>
                                  </div>
                                </>
                              </li>
                            );
                          })}
                        </ul>
                        <ul className="col-md-6">
                          {catArts1?.map((catdata1, index) => {
                            return (
                              <li>
                                <a>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      onChange={(e) => handleSelectedIngredients(catdata1, e.target.check)}
                                      type="checkbox"
                                      defaultValue
                                      id={catdata1}
                                      checked={subCategoriesData.indexOf(catdata1) > -1}
                                    />
                                    <label className="form-check-label" htmlFor={catdata1}>
                                      {catdata1}
                                    </label>
                                  </div>
                                </a>
                              </li>
                            );
                          })}

                          <li className="headings">
                            <span className="text-purple fw-600">Performing Arts</span>
                          </li>
                          {performingArts?.map((arts, index) => {
                            return (
                              <li>
                                <a>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      onChange={(e) => handleSelectedIngredients(arts, e.target.check)}
                                      type="checkbox"
                                      defaultValue
                                      id={arts}
                                      checked={subCategoriesData.indexOf(arts) > -1}
                                    />
                                    <label className="form-check-label" htmlFor={arts}>
                                      {arts}
                                    </label>
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="v-pills-Music" role="tabpanel" aria-labelledby="v-pills-Music-tab" tabIndex={0}>
                      <div className="row">
                        <ul className="col-md-6">
                          <li className="headings">
                            <span className="text-purple fw-600">Instruments</span>
                          </li>
                          {musicArray1?.map((musicdata, index) => {
                            return (
                              <li>
                                <a>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      onChange={(e) => handleSelectedIngredients(musicdata, e.target.check)}
                                      type="checkbox"
                                      defaultValue
                                      id={musicdata}
                                      checked={subCategoriesData.indexOf(musicdata) > -1}
                                    />
                                    <label className="form-check-label" htmlFor={musicdata}>
                                      {musicdata}
                                    </label>
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                        <ul className="col-md-6">
                          {musicArray2?.map((musicdata2, index) => {
                            return (
                              <li>
                                <a>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      onChange={(e) => handleSelectedIngredients(musicdata2, e.target.check)}
                                      type="checkbox"
                                      defaultValue
                                      id={musicdata2}
                                      checked={subCategoriesData.indexOf(musicdata2) > -1}
                                    />
                                    <label className="form-check-label" htmlFor={musicdata2}>
                                      {musicdata2}
                                    </label>
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="v-pills-Health" role="tabpanel" aria-labelledby="v-pills-Health-tab" tabIndex={0}>
                      <div className="row">
                        <ul className="col-md-12">
                          {healthArray?.map((healthData, index) => {
                            return (
                              <li>
                                <a>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      onChange={(e) => handleSelectedIngredients(healthData, e.target.check)}
                                      type="checkbox"
                                      defaultValue
                                      id={healthData}
                                      checked={subCategoriesData.indexOf(healthData) > -1}
                                    />
                                    <label className="form-check-label" htmlFor={healthData}>
                                      {healthData}
                                    </label>
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="v-pills-Life" role="tabpanel" aria-labelledby="v-pills-Life-tab" tabIndex={0}>
                      <div className="row">
                        <ul className="col-md-12">
                          {lifiSkillArray?.map((lifeskillsData, index) => {
                            return (
                              <li>
                                <a>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      onChange={(e) => handleSelectedIngredients(lifeskillsData, e.target.check)}
                                      type="checkbox"
                                      defaultValue
                                      id={lifeskillsData}
                                      checked={subCategoriesData.indexOf(lifeskillsData) > -1}
                                    />
                                    <label className="form-check-label" htmlFor={lifeskillsData}>
                                      {lifeskillsData}
                                    </label>
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="v-pills-Professional" role="tabpanel" aria-labelledby="v-pills-Professional-tab" tabIndex={0}>
                      <div className="row">
                        <ul className="col-md-6">
                          <li className="headings">
                            <span className="text-purple fw-600">Categories</span>
                          </li>
                          {categoriesLabels?.map((CategoriesData, index) => {
                            return (
                              <li>
                                <a>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      onChange={(e) => handleSelectedIngredients(CategoriesData, e.target.check)}
                                      type="checkbox"
                                      defaultValue
                                      id={CategoriesData}
                                      checked={subCategoriesData.indexOf(CategoriesData) > -1}
                                    />
                                    <label className="form-check-label" htmlFor={CategoriesData}>
                                      {CategoriesData}
                                    </label>
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                        <ul className="col-md-6">
                          <li className="headings">
                            <span className="text-purple fw-600">Career Services</span>
                          </li>
                          {careerServicesLabels?.map((serviceData, index) => {
                            return (
                              <li>
                                <a>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      onChange={(e) => handleSelectedIngredients(serviceData, e.target.check)}
                                      type="checkbox"
                                      defaultValue
                                      id={serviceData}
                                      checked={subCategoriesData.indexOf(serviceData) > -1}
                                    />
                                    <label className="form-check-label" htmlFor={serviceData}>
                                      {serviceData}
                                    </label>
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </li>

          {/* <li className="ms-2 mb-2">
            <div className="dropdown">
              <button className="btn border-purple rounded-cricle rounded-pill dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                Class Size
              </button>
              <div className="dropdown-menu class_size_start filter_box">
                <h3 className="fs-5 mb-3">Class Size</h3>
                <form className action id>
                  <div className="form-check">
                    <input className="form-check-input" onChange={(e) => handleSelectedsize("", e.target.check)} type="radio" name="flexRadioDefault" id="flexRadioDefault1" defaultChecked />
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      Any
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" onChange={(e) => handleSelectedsize("Individual/private", e.target.check)} type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                      1-on-1 Private Lesson
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" onChange={(e) => handleSelectedsize("Group", e.target.check)} type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                    <label className="form-check-label" htmlFor="flexRadioDefault3">
                      Group Lesson
                    </label>
                  </div>
                </form>
              </div>
            </div>
          </li> */}
          <li className="ms-2 mb-2">
            <div className="dropdown">
              <button
                className="btn border-purple rounded-cricle rounded-pill dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Select Date
              </button>
              <div className="dropdown-menu class_size_start filter_box">
                <form className action id>
                  <div className="form-group mb-4">
                    <label className="mb-2">From Date</label>
                    <input
                      type="date"
                      className="form-control"
                      id
                      onChange={(e) => {
                        Start_Date(e.target.value);
                      }}
                      placeholder="Start"
                      value={startDate}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label className="mb-2">To Date</label>
                    <input
                      type="date"
                      className="form-control"
                      min={startDate}
                      id
                      onChange={(e) => {
                        End_Date(e.target.value);
                      }}
                      placeholder="Start"
                      name
                      value={endDate}
                    />
                  </div>
                </form>
              </div>
            </div>
          </li>
          <li className="ms-4 pointer">
            <a onClick={() => ResetFilter()} className="text-purple fw-500">
              Reset Filters
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}
