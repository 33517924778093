import React, { useEffect, useState, useRef } from "react";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import PFooter_Dashboard from "../Pinc/PFooter_Dashboard";
import PHeader_Dashboard from "../Pinc/PHeader_Dashboard";
import copy from "clipboard-copy"; // Import the clipboard-copy library
import { TOAST_SUCCESS, TOAST_ERROR } from "../../../utils/common.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { refer_friend_code } from "../../../api/apiHandler";
import BackToPrevious from "../../../components/backToPrevious";

const CopyToClipboard = ({ referralCode }) => {
  const textToCopyRef = useRef(null);
  

  const handleCopyClick = async () => {
    var text = referralCode.split('/')
    var code = text[1]
    // console.log(code,'testttttttttttttt');

    try {
      await copy(code); // Use clipboard-copy to copy text to clipboard
      toast.success("Referral code copied successfully");
      console.log("Text copied to clipboard");
    } catch (err) {
      console.error("Unable to copy text to clipboard", err);
    }
  };

  return (
    // <div className="copy-text-container text-center my-lg-5 mt-4">
    //   {/* <h4>Your Referral URL</h4> */}
    //   <div className="copy-text-box position-relative">
    //     <span ref={textToCopyRef} spellCheck="false">
    //       {referralCode}
    //     </span>
    //     <button onClick={handleCopyClick} className="copy-button fw-500">
    //       Copy
    //     </button>
    //   </div>
    // </div>

    <div className="text-center my-lg-5 mt-4">
      <span className="mb-3 d-block">Your Referral URL</span>
      <div className="copy_text_bx position-relative">
        <span ref={textToCopyRef} id="to-copy" spellCheck="false">
          {referralCode}
        </span>
        <button id="copy" onClick={handleCopyClick} className="position-absolute fw-500">
          Share
        </button>
      </div>
    </div>
  );
};

export default function ReferFriend({ ReferFriendCode }) {
  var [ReferFriendCode, set_ReferFriendCode] = useState(null);

  
  // console.log(ReferFriendCode,'testtttttttt');
  useEffect(() => {
    refer_friend_code_details();
  }, []);

  const refer_friend_code_details = () => {
    refer_friend_code().then((resposnse) => {
      console.log(resposnse, "refer_friend_code_details");
      if (resposnse?.code == 1) {
        set_ReferFriendCode(resposnse.data);
        // TOAST_SUCCESS(resposnse.message);
      } else {
        // TOAST_ERROR(resposnse.message);
        console.log("Data Not Found");
      }
    });
  };

  return (
    <>
      <JqueryLoad />
      <PHeader_Dashboard />

      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div>
              {/*start*/}
            <BackToPrevious/>
              {/*end*/}
              {/*start*/}
              {/*start*/}
              <ul className="nav custom_nav_bar nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-classes-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-classes"
                    type="button"
                    role="tab"
                    aria-controls="pills-classes"
                    aria-selected="true"
                  >
                    Refer A Learner
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-clubs-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-clubs"
                    type="button"
                    role="tab"
                    aria-controls="pills-clubs"
                    aria-selected="false"
                  >
                    Refer A Teacher
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                {/*----------------------------------Tab1-------------------------------------------------*/}
                <div className="tab-pane fade show active" id="pills-classes" role="tabpanel" aria-labelledby="pills-classes-tab">
                  {/*start*/}
                  <div className="row">
                    <div className="col-xl-8">
                      <div className="refer_title">
                        <p style={{ color: "purple", fontWeight: "bold" }}>Here’s Your Chance To Receive Free Credit.</p>
                        <br />
                        <p>
                          <strong>How it works:</strong>
                        </p>
                        <br />
                        <p>- You have your own unique referral code.</p>
                        <br />
                        <p>- To share your referral code, simply click on ‘Share’ to copy Your unique referral code to share with your friends and family.

</p>
                        <br />
                        <p>
                          - If they sign up for the class,{" "}
                          <span className="text-dif-black fw-500"> both of you will receive a credit of USD $15 to register to a class.</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  {/*end*/}
               
                  <ul className="row refer_setp_list mt-5">
                    <li className="col-lg-4 mb-lg-0 mb-5 text-center">
                      <div className="mb-lg-5 mb-4">
                        <svg width={100} height={70} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clipPath="url(#clip0_1330_28393)">
                            <path
                              d="M64.6467 43.2578L98.8219 73.2156C99.5973 71.7052 100.001 70.0316 100 68.3338V16.7713C100 15.8787 99.8887 15.0119 99.6826 14.1822L64.6467 43.2578ZM35.3621 43.2502L0.320315 14.1699C0.107526 15.0207 -4.93079e-05 15.8945 2.22828e-06 16.7715V68.334C-0.00109466 70.0318 0.402789 71.7054 1.17813 73.2158L35.3621 43.2502ZM60.0805 47.0471L57.2912 49.3619C55.1814 51.1127 52.5951 51.9883 50.009 51.9883C47.4228 51.9883 44.8367 51.1129 42.7268 49.3619L39.9283 47.0395L5.16582 77.5121C6.84501 78.5373 8.7748 79.0786 10.7422 79.0762H36.0379C36.3467 79.0762 36.6402 79.2244 36.8236 79.473L46.0527 91.9854C46.9775 93.2395 48.4016 93.9627 49.9598 93.9699H49.9832C51.5324 93.9699 52.9537 93.2605 53.8854 92.0213L63.3238 79.4658C63.4151 79.345 63.533 79.247 63.6684 79.1793C63.8039 79.1117 63.9531 79.0763 64.1045 79.076H89.2578C91.2252 79.0784 93.155 78.5371 94.8342 77.5119L60.0805 47.0471ZM46.4686 44.8529C48.5199 46.5555 51.498 46.5553 53.5494 44.8529L96.7041 9.03984C94.7715 7.17793 92.1471 6.0293 89.2578 6.0293H10.7422C7.85703 6.0293 5.23614 7.1748 3.3041 9.03203L46.4686 44.8529Z"
                              fill="#FFC924"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1330_28393">
                              <rect width={100} height={100} fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <h3 className="text-purple fw-600 mb-3 poppins text-capitalize">1. Invite A Friend</h3>
                      <p className="px-md-5 text-capitalize">Invite a Friend -to join StudentScholars with the URL link</p>
                    </li>
                    <li className="col-lg-4 mb-lg-0 mb-5 text-center">
                      <div className="mb-lg-5 mb-4">
                        <svg width={100} height={72} viewBox="0 0 100 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M52.202 30.1406C50.8838 34.0455 47.3836 36.4178 43.5334 36.4178C42.9168 36.4178 42.3121 36.2729 41.7062 36.1488L46.383 43.1641C47.2814 44.5115 46.918 46.3283 45.5705 47.2268C44.2016 48.134 42.3916 47.7418 41.5078 46.4143L35.1562 36.8869V71.1562H91.2109C96.0574 71.1562 100 67.2137 100 62.3672V30.1406H52.202ZM85.3516 59.4375H61.5234C59.9041 59.4375 58.5938 58.1271 58.5938 56.5078C58.5938 54.8885 59.9041 53.5781 61.5234 53.5781H85.3516C86.9709 53.5781 88.2812 54.8885 88.2812 56.5078C88.2812 58.1271 86.9709 59.4375 85.3516 59.4375ZM85.3516 47.7188H61.5234C59.9041 47.7188 58.5938 46.4084 58.5938 44.7891C58.5938 43.1697 59.9041 41.8594 61.5234 41.8594H85.3516C86.9709 41.8594 88.2812 43.1697 88.2812 44.7891C88.2812 46.4084 86.9709 47.7188 85.3516 47.7188ZM41.6822 24.4271L37.508 27.2109L41.6822 29.9947C43.9045 31.476 46.875 29.8846 46.875 27.2139C46.875 24.5312 43.8959 22.9514 41.6822 24.4271ZM8.78906 0.84375C3.94258 0.84375 0 4.78633 0 9.63281V24.2812H12.2512C14.2328 18.4115 21.0492 16.2406 26.0209 19.552L29.2969 21.7363V0.84375H8.78906ZM91.2109 0.84375H35.1562V21.7363L38.432 19.552C43.3512 16.2656 50.208 18.3682 52.2031 24.2812H100V9.63281C100 4.78633 96.0574 0.84375 91.2109 0.84375ZM22.7709 24.4271C20.5453 22.9434 17.5781 24.5445 17.5781 27.208C17.5781 29.8795 20.5379 31.4809 22.7709 29.9947L26.9451 27.2109L22.7709 24.4271ZM22.9453 46.4141C22.0469 47.7559 20.2303 48.1135 18.8826 47.2266C17.5352 46.3281 17.1717 44.5115 18.0701 43.1639L22.7469 36.1486C22.141 36.2727 21.5363 36.4176 20.9197 36.4176C17.0602 36.4176 13.5641 34.0357 12.2498 30.1404H0V62.3672C0 67.2137 3.94258 71.1562 8.78906 71.1562H29.2969V36.8869L22.9453 46.4141Z"
                            fill="#FFC924"
                          />
                        </svg>
                      </div>
                      <h3 className="text-purple fw-600 mb-3 poppins text-capitalize">2. Your Friend Get 15$</h3>
                      <p className="px-md-5 text-capitalize">
                        Your Friend gets to spend USD $15 on any class when they sign up with your referral code link.
                      </p>
                    </li>
                    <li className="col-lg-4 mb-lg-0 mb-5 text-center">
                      <div className="mb-lg-5 mb-4">
                        <svg width={88} height={70} viewBox="0 0 88 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M87.9453 64.8438C87.9453 61.6211 85.3086 58.9844 82.0859 58.9844C85.3086 58.9844 87.9453 56.3477 87.9453 53.125C87.9453 49.9023 85.3086 47.2656 82.0859 47.2656H58.0627C61.6367 36.5428 61.5781 33.125 61.5781 26.5625C61.5781 21.6873 57.5809 17.7734 52.7891 17.7734H51.0312C49.918 17.7734 48.5117 18.6525 48.2186 20C45.3199 31.2854 40.7881 44.2352 29.3516 46.8582V89.2531L39.4881 92.617C42.4764 93.6129 45.582 94.1404 48.7461 94.1404H76.2266C79.4627 94.1406 82.0859 91.517 82.0859 88.2812C82.0859 85.0451 79.4627 82.4219 76.2266 82.4219H82.0859C85.3086 82.4219 87.9453 79.7852 87.9453 76.5625C87.9453 73.3398 85.3086 70.7031 82.0859 70.7031C85.3086 70.7031 87.9453 68.0664 87.9453 64.8438ZM14.7031 41.4062H2.98438C1.36504 41.4062 0.0546875 42.7166 0.0546875 44.3359V97.0703C0.0546875 98.6897 1.36504 100 2.98438 100H14.7031C19.5496 100 23.4922 96.0574 23.4922 91.2109V50.1953C23.4922 45.3488 19.5496 41.4062 14.7031 41.4062ZM14.7031 88.2812C13.0852 88.2812 11.7734 86.9695 11.7734 85.3516C11.7734 83.7332 13.0852 82.4219 14.7031 82.4219C16.3211 82.4219 17.6328 83.7332 17.6328 85.3516C17.6328 86.9695 16.3211 88.2812 14.7031 88.2812ZM40.358 12.9184L36.2152 8.77559C35.0709 7.63125 33.2168 7.63125 32.0725 8.77559C30.9281 9.91992 30.9281 11.774 32.0725 12.9184L36.2152 17.0611C37.3596 18.2055 39.2135 18.2055 40.358 17.0611C41.5023 15.9168 41.5023 14.0627 40.358 12.9184ZM73.5057 8.77559C72.3613 7.63125 70.5072 7.63125 69.3629 8.77559L65.2201 12.9184C64.0758 14.0627 64.0758 15.9168 65.2201 17.0611C66.3645 18.2055 68.2186 18.2055 69.3629 17.0611L73.5057 12.9184C74.65 11.774 74.65 9.91992 73.5057 8.77559ZM52.7891 0C51.1482 0 49.8594 1.28887 49.8594 2.92969V8.98438C49.8594 10.6252 51.1482 11.9141 52.7891 11.9141C54.4299 11.9141 55.7188 10.6252 55.7188 8.98438V2.92969C55.7188 1.28887 54.4299 0 52.7891 0Z"
                            fill="#FFC924"
                          />
                        </svg>
                      </div>
                      <h3 className="text-purple fw-600 mb-3 poppins text-capitalize">3. You Get 15$ too!</h3>
                      <p className="px-md-5 text-capitalize">
                        Once the friend takes their first class, you will receive USD $15 credit to spend on any class.
                      </p>
                    </li>
                  </ul>
                  <div className="text-center my-lg-5 mt-4">
                    {/* <span className="mb-3 d-block">Your URL</span> */}

                    <CopyToClipboard
                      referralCode={`Studentscholar.com/${ReferFriendCode?.[0]?.refer_learner}`}
                      onCopySuccess={() => TOAST_SUCCESS("Refer code copied successfully")}
                    />
                  </div>
   {/*start*/}
   <div className="refer_banner text-center my-md-5 my-4">
                    <img src="./assets/images/refer_friend.png" alt="refer_friend" />
                  </div>
                  {/*end*/}
                  {/* <div className="text-center my-lg-5 mt-4">
                    <span className="mb-3 d-block">Your Referral URL</span>
                    <div className="copy_text_bx position-relative">
                      <span id="to-copy" spellCheck="false">{ReferFriendCode[0].refer_learner}</span>
                      <a href="#" id="copy" className="position-absolute fw-500">Copy</a>
                    </div>
                  </div> */}

                  {/*end*/}
                </div>
                {/*----------------------------------Tab2-------------------------------------------------*/}
                <div className="tab-pane fade" id="pills-clubs" role="tabpanel" aria-labelledby="pills-clubs-tab">
                  {/*start*/}

                  {/* <div className="row">
                    <div className="col-xl-8">
                      <div className="refer_title">
                        <p>Here’s your chance to receive Free Credit.</p>
                        <p>How it works: You have your own unique referral code. To share your referral code, simply click on share next to the URL link with a teacher. If they sign up as a teacher and teaches a class, <span className="text-dif-black fw-500"> you will receive USD $15 credit to your account for the referral.</span></p>
                      </div> 	
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-xl-8">
                      <div className="refer_title">
                        <p style={{ color: "purple", fontWeight: "bold" }}>Here’s Your Chance To Receive Free Credit.</p>
                        <br />
                        <p>
                          <strong>How it works:</strong>
                        </p>
                        <br />
                        <p>- You have your own unique referral code.</p>
                        <br />
                        <p>- To share your referral code, simply click on ‘Share’ to copy Your unique referral code to share with teachers you want to invite.</p>
                        <br />
                        <p>
                          - If they sign up as a teacher and teaches a class,{" "}
                          <span className="text-dif-black fw-500"> you will receive USD $15 credit to your account for the referral.</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  {/*end*/}
                
                  <ul className="row refer_setp_list justify-content-center mt-5">
                    <li className="col-lg-4 mb-lg-0 mb-5 text-center">
                      <div className="mb-lg-5 mb-4">
                        <svg width={100} height={70} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clipPath="url(#clip0_1330_28393)">
                            <path
                              d="M64.6467 43.2578L98.8219 73.2156C99.5973 71.7052 100.001 70.0316 100 68.3338V16.7713C100 15.8787 99.8887 15.0119 99.6826 14.1822L64.6467 43.2578ZM35.3621 43.2502L0.320315 14.1699C0.107526 15.0207 -4.93079e-05 15.8945 2.22828e-06 16.7715V68.334C-0.00109466 70.0318 0.402789 71.7054 1.17813 73.2158L35.3621 43.2502ZM60.0805 47.0471L57.2912 49.3619C55.1814 51.1127 52.5951 51.9883 50.009 51.9883C47.4228 51.9883 44.8367 51.1129 42.7268 49.3619L39.9283 47.0395L5.16582 77.5121C6.84501 78.5373 8.7748 79.0786 10.7422 79.0762H36.0379C36.3467 79.0762 36.6402 79.2244 36.8236 79.473L46.0527 91.9854C46.9775 93.2395 48.4016 93.9627 49.9598 93.9699H49.9832C51.5324 93.9699 52.9537 93.2605 53.8854 92.0213L63.3238 79.4658C63.4151 79.345 63.533 79.247 63.6684 79.1793C63.8039 79.1117 63.9531 79.0763 64.1045 79.076H89.2578C91.2252 79.0784 93.155 78.5371 94.8342 77.5119L60.0805 47.0471ZM46.4686 44.8529C48.5199 46.5555 51.498 46.5553 53.5494 44.8529L96.7041 9.03984C94.7715 7.17793 92.1471 6.0293 89.2578 6.0293H10.7422C7.85703 6.0293 5.23614 7.1748 3.3041 9.03203L46.4686 44.8529Z"
                              fill="#FFC924"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1330_28393">
                              <rect width={100} height={100} fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <h3 className="text-purple fw-600 mb-3 poppins text-capitalize">1. Invite A Teacher</h3>
                      <p className="px-md-5 text-capitalize">Invite a Teacher -to join StudentScholars with the URL link</p>
                    </li>
                    <li className="col-lg-4 mb-lg-0 mb-5 text-center">
                      <div className="mb-lg-5 mb-4">
                        <svg width={88} height={70} viewBox="0 0 88 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M87.9453 64.8438C87.9453 61.6211 85.3086 58.9844 82.0859 58.9844C85.3086 58.9844 87.9453 56.3477 87.9453 53.125C87.9453 49.9023 85.3086 47.2656 82.0859 47.2656H58.0627C61.6367 36.5428 61.5781 33.125 61.5781 26.5625C61.5781 21.6873 57.5809 17.7734 52.7891 17.7734H51.0312C49.918 17.7734 48.5117 18.6525 48.2186 20C45.3199 31.2854 40.7881 44.2352 29.3516 46.8582V89.2531L39.4881 92.617C42.4764 93.6129 45.582 94.1404 48.7461 94.1404H76.2266C79.4627 94.1406 82.0859 91.517 82.0859 88.2812C82.0859 85.0451 79.4627 82.4219 76.2266 82.4219H82.0859C85.3086 82.4219 87.9453 79.7852 87.9453 76.5625C87.9453 73.3398 85.3086 70.7031 82.0859 70.7031C85.3086 70.7031 87.9453 68.0664 87.9453 64.8438ZM14.7031 41.4062H2.98438C1.36504 41.4062 0.0546875 42.7166 0.0546875 44.3359V97.0703C0.0546875 98.6897 1.36504 100 2.98438 100H14.7031C19.5496 100 23.4922 96.0574 23.4922 91.2109V50.1953C23.4922 45.3488 19.5496 41.4062 14.7031 41.4062ZM14.7031 88.2812C13.0852 88.2812 11.7734 86.9695 11.7734 85.3516C11.7734 83.7332 13.0852 82.4219 14.7031 82.4219C16.3211 82.4219 17.6328 83.7332 17.6328 85.3516C17.6328 86.9695 16.3211 88.2812 14.7031 88.2812ZM40.358 12.9184L36.2152 8.77559C35.0709 7.63125 33.2168 7.63125 32.0725 8.77559C30.9281 9.91992 30.9281 11.774 32.0725 12.9184L36.2152 17.0611C37.3596 18.2055 39.2135 18.2055 40.358 17.0611C41.5023 15.9168 41.5023 14.0627 40.358 12.9184ZM73.5057 8.77559C72.3613 7.63125 70.5072 7.63125 69.3629 8.77559L65.2201 12.9184C64.0758 14.0627 64.0758 15.9168 65.2201 17.0611C66.3645 18.2055 68.2186 18.2055 69.3629 17.0611L73.5057 12.9184C74.65 11.774 74.65 9.91992 73.5057 8.77559ZM52.7891 0C51.1482 0 49.8594 1.28887 49.8594 2.92969V8.98438C49.8594 10.6252 51.1482 11.9141 52.7891 11.9141C54.4299 11.9141 55.7188 10.6252 55.7188 8.98438V2.92969C55.7188 1.28887 54.4299 0 52.7891 0Z"
                            fill="#FFC924"
                          />
                        </svg>
                      </div>
                      <h3 className="text-purple fw-600 mb-3 poppins text-capitalize">2. You Get 15$ too!</h3>
                      <p className="px-md-5 text-capitalize">
                        Once the teacher teaches their first class, you will receive USD $15 credit to spend on any class.
                      </p>
                    </li>
                  </ul>
                  <div className="text-center my-lg-5 my-4">
                    {/* <span className="mb-3 d-block">Your Referral URL</span> */}
                    {/* <div className="copy_text_bx position-relative"> */}
                    <CopyToClipboard referralCode={`Studentscholar.com/${ReferFriendCode?.[0]?.refer_teacher}`} />
  {/*start*/}
  <div className="refer_banner text-center my-md-5 my-4">
                    <img src="./assets/images/refer_friend.png" alt="refer_friend" />
                  </div>
                  {/*end*/}
                    {/* <span id="to-copy" spellCheck="false">{ReferFriendCode[0].refer_teacher}</span> */}
                    {/* <a href="#" id="copy" className="position-absolute fw-500">Copy</a> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>
      <PFooter_Dashboard />
    </>
  );
}





// import React, { useRef, useEffect, useState } from 'react'
// import { Link, useLocation, useNavigate } from 'react-router-dom'
// import JqueryLoad from '../../../JqueryLoad/JqueryLoad'
// import { refer_friend_code } from '../../../api/apiHandler'
// import PHeader_Dashboard from '../Pinc/PHeader_Dashboard'
// import PFooter_Dashboard from '../Pinc/PFooter_Dashboard'

// export default function Refer() {

//   const navigate = useNavigate();

//   const [data, setData] = useState([]);

//   const referFriend = () => {
//     // isLoader(true)

//     refer_friend_code().then((resposnse) => {
//       console.log("refer_friend", resposnse)
//       // isLoader(false)

//       if (resposnse.code == 1) {
//         setData(resposnse.data[0])
//       } else {
//          
//       }
//     });
//   }

//   useEffect(() => {
//     referFriend();

//   }, []);

//   const textToCopy = data.refer_learner;
//   const textToCopy1 = data.refer_teacher;
//   const textRef = useRef(null);
//   const textRef1 = useRef(null);
//   const [buttonText, setButtonText] = useState('Copy');

//   const handleCopyClick = async () => {
//     try {
//       if (navigator.clipboard && textRef.current) { // Check if navigator.clipboard is supported
//         await navigator.clipboard.writeText(textToCopy);
//         setButtonText('Copied!');
//         console.log('Text copied to clipboard:', textToCopy);
  
//         // Reset the button text after 2 seconds
//         setTimeout(() => {
//           setButtonText('Copy');
//         }, 2000);
//       } else {
//         // Fallback for browsers that do not support navigator.clipboard or textRef is not available
//         const textarea = document.createElement('textarea');
//         textarea.value = textToCopy;
//         document.body.appendChild(textarea);
//         textarea.select();
//         document.execCommand('copy');
//         document.body.removeChild(textarea);
  
//         setButtonText('Copied!');
//         console.log('Text copied to clipboard:', textToCopy);
  
//         // Reset the button text after 2 seconds
//         setTimeout(() => {
//           setButtonText('Copy');
//         }, 2000);
//       }
//     } catch (error) {
//       console.error('Unable to copy text to clipboard', error);
//     }
//   };
  

//   const handleCopyClick1 = async () => {
//     try {
//       if (navigator.clipboard && textRef1.current) { // Check if navigator.clipboard is supported
//         await navigator.clipboard.writeText(textToCopy1);
//         setButtonText('Copied!');
//         console.log('Text copied to clipboard:', textToCopy1);
  
//         // Reset the button text after 2 seconds
//         setTimeout(() => {
//           setButtonText('Copy');
//         }, 2000);
//       } else {
//         // Fallback for browsers that do not support navigator.clipboard or textRef1.current is not available
//         const textarea = document.createElement('textarea');
//         textarea.value = textToCopy1;
//         document.body.appendChild(textarea);
//         textarea.select();
//         document.execCommand('copy');
//         document.body.removeChild(textarea);
  
//         setButtonText('Copied!');
//         console.log('Text copied to clipboard:', textToCopy1);
  
//         // Reset the button text after 2 seconds
//         setTimeout(() => {
//           setButtonText('Copy');
//         }, 2000);
//       }
//     } catch (error) {
//       console.error('Unable to copy text to clipboard', error);
//     }
//   };
  
//   return (
//     <>
//       <JqueryLoad />
//       <PHeader_Dashboard />
//       <main>
//         <div className="dashboard-app d-lg-flex">
//           <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
//             <div className="align-items-center justify-content-between d-flex">
//               <div className="d-flex align-items-center">
//                 <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
//                   <i className="fa fa-bars" />
//                 </a>
//                 <a href="#" className="d-lg-none d-block brand-logo">
//                   <img src="./assets/images/logo.png" alt="logo" />
//                 </a>
//               </div>
//             </div>
//           </header>
//           <div className="class-content">
//             <div className>
//               {/*start*/}
//               <a href="#" className="mb-4 arrow_left mb-3 d-block" onclick="window.history.back()">
//                 <span>
//                   <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
//                     <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
//                   </svg>
//                   <span className="ms-2">Back</span>
//                 </span></a>
//               {/*end*/}
//               {/*start*/}
//               {/*start*/}
//               <ul className="nav custom_nav_bar nav-pills mb-3" id="pills-tab" role="tablist">
//                 <li className="nav-item" role="presentation">
//                   <button className="nav-link active" id="pills-classes-tab" data-bs-toggle="pill" data-bs-target="#pills-classes" type="button" role="tab" aria-controls="pills-classes" aria-selected="true">
//                     Refer A Learner
//                   </button>
//                 </li>
//                 <li className="nav-item" role="presentation">
//                   <button className="nav-link" id="pills-clubs-tab" data-bs-toggle="pill" data-bs-target="#pills-clubs" type="button" role="tab" aria-controls="pills-clubs" aria-selected="false">
//                     Refer A Teacher
//                   </button>
//                 </li>
//               </ul>
//               <div className="tab-content" id="pills-tabContent">
//                 {/*----------------------------------Tab1-------------------------------------------------*/}
//                 <div className="tab-pane fade show active" id="pills-classes" role="tabpanel" aria-labelledby="pills-classes-tab">
//                   {/*start*/}
//                   <div className="row">
//                     <div className="col-xl-8">
//                     <div className="refer_title">
//                       <p style={{ color: 'purple', fontWeight: 'bold' }}>Here’s Your Chance To Receive Free Credit.</p>
//                       <br/>
//                       <p><strong>How it works:</strong></p>
//                       <br/>
//                        <p>- You have your own unique referral code.</p> 
//                        <br/>
//                        <p>- To share your referral code, simply click on share next to the URL link with your friends.</p> 
//                        <br/>
//                        <p>- If they sign up for the class, <span className="text-dif-black fw-500"> both of you will receive a credit of USD $15 to register to a class.</span></p>
//                     </div>
//                     </div>
//                   </div>
//                   {/*end*/}
//                   {/*start*/}
//                   <div className="refer_banner text-center my-md-5 my-4">
//                     <img src="./assets/images/refer_friend.png" alt="refer_friend" />
//                   </div>
//                   {/*end*/}
//                   <ul className="row refer_setp_list">
//                     <li className="col-lg-4 mb-lg-0 mb-5 text-center">
//                       <div className="mb-lg-5 mb-4">
//                         <svg width={100} height={70} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
//                           <g clipPath="url(#clip0_1330_28393)">
//                             <path d="M64.6467 43.2578L98.8219 73.2156C99.5973 71.7052 100.001 70.0316 100 68.3338V16.7713C100 15.8787 99.8887 15.0119 99.6826 14.1822L64.6467 43.2578ZM35.3621 43.2502L0.320315 14.1699C0.107526 15.0207 -4.93079e-05 15.8945 2.22828e-06 16.7715V68.334C-0.00109466 70.0318 0.402789 71.7054 1.17813 73.2158L35.3621 43.2502ZM60.0805 47.0471L57.2912 49.3619C55.1814 51.1127 52.5951 51.9883 50.009 51.9883C47.4228 51.9883 44.8367 51.1129 42.7268 49.3619L39.9283 47.0395L5.16582 77.5121C6.84501 78.5373 8.7748 79.0786 10.7422 79.0762H36.0379C36.3467 79.0762 36.6402 79.2244 36.8236 79.473L46.0527 91.9854C46.9775 93.2395 48.4016 93.9627 49.9598 93.9699H49.9832C51.5324 93.9699 52.9537 93.2605 53.8854 92.0213L63.3238 79.4658C63.4151 79.345 63.533 79.247 63.6684 79.1793C63.8039 79.1117 63.9531 79.0763 64.1045 79.076H89.2578C91.2252 79.0784 93.155 78.5371 94.8342 77.5119L60.0805 47.0471ZM46.4686 44.8529C48.5199 46.5555 51.498 46.5553 53.5494 44.8529L96.7041 9.03984C94.7715 7.17793 92.1471 6.0293 89.2578 6.0293H10.7422C7.85703 6.0293 5.23614 7.1748 3.3041 9.03203L46.4686 44.8529Z" fill="#FFC924" />
//                           </g>
//                           <defs>
//                             <clipPath id="clip0_1330_28393">
//                               <rect width={100} height={100} fill="white" />
//                             </clipPath>
//                           </defs>
//                         </svg>
//                       </div>
//                       <h3 className="text-purple fw-600 mb-3 poppins text-capitalize">1. Invite A Friend</h3>
//                       <p className="px-md-5 text-capitalize">Invite a Friend -to join StudentScholars with the URL link</p>
//                     </li>
//                     <li className="col-lg-4 mb-lg-0 mb-5 text-center">
//                       <div className="mb-lg-5 mb-4">
//                         <svg width={100} height={72} viewBox="0 0 100 72" fill="none" xmlns="http://www.w3.org/2000/svg">
//                           <path d="M52.202 30.1406C50.8838 34.0455 47.3836 36.4178 43.5334 36.4178C42.9168 36.4178 42.3121 36.2729 41.7062 36.1488L46.383 43.1641C47.2814 44.5115 46.918 46.3283 45.5705 47.2268C44.2016 48.134 42.3916 47.7418 41.5078 46.4143L35.1562 36.8869V71.1562H91.2109C96.0574 71.1562 100 67.2137 100 62.3672V30.1406H52.202ZM85.3516 59.4375H61.5234C59.9041 59.4375 58.5938 58.1271 58.5938 56.5078C58.5938 54.8885 59.9041 53.5781 61.5234 53.5781H85.3516C86.9709 53.5781 88.2812 54.8885 88.2812 56.5078C88.2812 58.1271 86.9709 59.4375 85.3516 59.4375ZM85.3516 47.7188H61.5234C59.9041 47.7188 58.5938 46.4084 58.5938 44.7891C58.5938 43.1697 59.9041 41.8594 61.5234 41.8594H85.3516C86.9709 41.8594 88.2812 43.1697 88.2812 44.7891C88.2812 46.4084 86.9709 47.7188 85.3516 47.7188ZM41.6822 24.4271L37.508 27.2109L41.6822 29.9947C43.9045 31.476 46.875 29.8846 46.875 27.2139C46.875 24.5312 43.8959 22.9514 41.6822 24.4271ZM8.78906 0.84375C3.94258 0.84375 0 4.78633 0 9.63281V24.2812H12.2512C14.2328 18.4115 21.0492 16.2406 26.0209 19.552L29.2969 21.7363V0.84375H8.78906ZM91.2109 0.84375H35.1562V21.7363L38.432 19.552C43.3512 16.2656 50.208 18.3682 52.2031 24.2812H100V9.63281C100 4.78633 96.0574 0.84375 91.2109 0.84375ZM22.7709 24.4271C20.5453 22.9434 17.5781 24.5445 17.5781 27.208C17.5781 29.8795 20.5379 31.4809 22.7709 29.9947L26.9451 27.2109L22.7709 24.4271ZM22.9453 46.4141C22.0469 47.7559 20.2303 48.1135 18.8826 47.2266C17.5352 46.3281 17.1717 44.5115 18.0701 43.1639L22.7469 36.1486C22.141 36.2727 21.5363 36.4176 20.9197 36.4176C17.0602 36.4176 13.5641 34.0357 12.2498 30.1404H0V62.3672C0 67.2137 3.94258 71.1562 8.78906 71.1562H29.2969V36.8869L22.9453 46.4141Z" fill="#FFC924" />
//                         </svg>
//                       </div>
//                       <h3 className="text-purple fw-600 mb-3 poppins text-capitalize">2. Your Friend Get 15$</h3>
//                       <p className="px-md-5 text-capitalize">Your Friend gets to spend USD $15 on any class when they sign up with your referral code link.</p>
//                     </li>
//                     <li className="col-lg-4 mb-lg-0 mb-5 text-center">
//                       <div className="mb-lg-5 mb-4">
//                         <svg width={88} height={70} viewBox="0 0 88 100" fill="none" xmlns="http://www.w3.org/2000/svg">
//                           <path d="M87.9453 64.8438C87.9453 61.6211 85.3086 58.9844 82.0859 58.9844C85.3086 58.9844 87.9453 56.3477 87.9453 53.125C87.9453 49.9023 85.3086 47.2656 82.0859 47.2656H58.0627C61.6367 36.5428 61.5781 33.125 61.5781 26.5625C61.5781 21.6873 57.5809 17.7734 52.7891 17.7734H51.0312C49.918 17.7734 48.5117 18.6525 48.2186 20C45.3199 31.2854 40.7881 44.2352 29.3516 46.8582V89.2531L39.4881 92.617C42.4764 93.6129 45.582 94.1404 48.7461 94.1404H76.2266C79.4627 94.1406 82.0859 91.517 82.0859 88.2812C82.0859 85.0451 79.4627 82.4219 76.2266 82.4219H82.0859C85.3086 82.4219 87.9453 79.7852 87.9453 76.5625C87.9453 73.3398 85.3086 70.7031 82.0859 70.7031C85.3086 70.7031 87.9453 68.0664 87.9453 64.8438ZM14.7031 41.4062H2.98438C1.36504 41.4062 0.0546875 42.7166 0.0546875 44.3359V97.0703C0.0546875 98.6897 1.36504 100 2.98438 100H14.7031C19.5496 100 23.4922 96.0574 23.4922 91.2109V50.1953C23.4922 45.3488 19.5496 41.4062 14.7031 41.4062ZM14.7031 88.2812C13.0852 88.2812 11.7734 86.9695 11.7734 85.3516C11.7734 83.7332 13.0852 82.4219 14.7031 82.4219C16.3211 82.4219 17.6328 83.7332 17.6328 85.3516C17.6328 86.9695 16.3211 88.2812 14.7031 88.2812ZM40.358 12.9184L36.2152 8.77559C35.0709 7.63125 33.2168 7.63125 32.0725 8.77559C30.9281 9.91992 30.9281 11.774 32.0725 12.9184L36.2152 17.0611C37.3596 18.2055 39.2135 18.2055 40.358 17.0611C41.5023 15.9168 41.5023 14.0627 40.358 12.9184ZM73.5057 8.77559C72.3613 7.63125 70.5072 7.63125 69.3629 8.77559L65.2201 12.9184C64.0758 14.0627 64.0758 15.9168 65.2201 17.0611C66.3645 18.2055 68.2186 18.2055 69.3629 17.0611L73.5057 12.9184C74.65 11.774 74.65 9.91992 73.5057 8.77559ZM52.7891 0C51.1482 0 49.8594 1.28887 49.8594 2.92969V8.98438C49.8594 10.6252 51.1482 11.9141 52.7891 11.9141C54.4299 11.9141 55.7188 10.6252 55.7188 8.98438V2.92969C55.7188 1.28887 54.4299 0 52.7891 0Z" fill="#FFC924" />
//                         </svg>
//                       </div>
//                       <h3 className="text-purple fw-600 mb-3 poppins text-capitalize">3. You Get 15$ too!</h3>
//                       <p className="px-md-5 text-capitalize">Once the friend takes their first class, you will receive USD $15 credit to spend on any class.</p>
//                     </li>
//                   </ul>
//                   <div className="text-center my-lg-5 mt-4">
//                     <span className="mb-3 d-block">Your Referral URL</span>
//                     <div className="copy_text_bx position-relative">
//                       <span id="to-copy" ref={textRef} spellCheck="false">Studentscholar.com/{data.refer_learner}</span>
//                       <button 
//                         id="copy"
//                         className="position-absolute fw-500"
//                         onClick={handleCopyClick}
//                         style={{
//                           top: 0,
//                           background: '#FFC924',
//                           padding: '10px 25px',
//                           display: 'block',
//                           right: 0,
//                           borderRadius: '50px',
//                           border: 'none',
//                         }}
//                       >
//                         {buttonText}
//                       </button>
//                     </div>
//                   </div>
//                   {/*end*/}
//                 </div>
//                 {/*----------------------------------Tab2-------------------------------------------------*/}
//                 <div className="tab-pane fade" id="pills-clubs" role="tabpanel" aria-labelledby="pills-clubs-tab">
//                   {/*start*/}
//                   <div className="row">
//                     <div className="col-xl-8">
//                     <div className="refer_title">
//                       <p style={{ color: 'purple', fontWeight: 'bold' }}>Here’s Your Chance To Receive Free Credit.</p>
//                       <br/>
//                       <p><strong>How it works:</strong></p>
//                       <br/>
//                        <p>- You have your own unique referral code.</p> 
//                        <br/>
//                        <p>- To share your referral code, simply click on share next to the URL link with your friends.</p> 
//                        <br/>
//                        <p>- If they sign up for the class, <span className="text-dif-black fw-500"> both of you will receive a credit of USD $15 to register to a class.</span></p>
//                     </div>
//                     </div>
//                   </div>
//                   {/*end*/}
//                   {/*start*/}
//                   <div className="refer_banner text-center my-md-5 my-4">
//                     <img src="./assets/images/refer_friend.png" alt="refer_friend" />
//                   </div>
//                   {/*end*/}
//                   <ul className="row refer_setp_list justify-content-center">
//                     <li className="col-lg-4 mb-lg-0 mb-5 text-center">
//                       <div className="mb-lg-5 mb-4">
//                         <svg width={100} height={70} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
//                           <g clipPath="url(#clip0_1330_28393)">
//                             <path d="M64.6467 43.2578L98.8219 73.2156C99.5973 71.7052 100.001 70.0316 100 68.3338V16.7713C100 15.8787 99.8887 15.0119 99.6826 14.1822L64.6467 43.2578ZM35.3621 43.2502L0.320315 14.1699C0.107526 15.0207 -4.93079e-05 15.8945 2.22828e-06 16.7715V68.334C-0.00109466 70.0318 0.402789 71.7054 1.17813 73.2158L35.3621 43.2502ZM60.0805 47.0471L57.2912 49.3619C55.1814 51.1127 52.5951 51.9883 50.009 51.9883C47.4228 51.9883 44.8367 51.1129 42.7268 49.3619L39.9283 47.0395L5.16582 77.5121C6.84501 78.5373 8.7748 79.0786 10.7422 79.0762H36.0379C36.3467 79.0762 36.6402 79.2244 36.8236 79.473L46.0527 91.9854C46.9775 93.2395 48.4016 93.9627 49.9598 93.9699H49.9832C51.5324 93.9699 52.9537 93.2605 53.8854 92.0213L63.3238 79.4658C63.4151 79.345 63.533 79.247 63.6684 79.1793C63.8039 79.1117 63.9531 79.0763 64.1045 79.076H89.2578C91.2252 79.0784 93.155 78.5371 94.8342 77.5119L60.0805 47.0471ZM46.4686 44.8529C48.5199 46.5555 51.498 46.5553 53.5494 44.8529L96.7041 9.03984C94.7715 7.17793 92.1471 6.0293 89.2578 6.0293H10.7422C7.85703 6.0293 5.23614 7.1748 3.3041 9.03203L46.4686 44.8529Z" fill="#FFC924" />
//                           </g>
//                           <defs>
//                             <clipPath id="clip0_1330_28393">
//                               <rect width={100} height={100} fill="white" />
//                             </clipPath>
//                           </defs>
//                         </svg>
//                       </div>
//                       <h3 className="text-purple fw-600 mb-3 poppins text-capitalize">1. Invite A Teacher</h3>
//                       <p className="px-md-5 text-capitalize">Invite a Teacher -to join StudentScholars with the URL link</p>
//                     </li>
//                     <li className="col-lg-4 mb-lg-0 mb-5 text-center">
//                       <div className="mb-lg-5 mb-4">
//                         <svg width={88} height={70} viewBox="0 0 88 100" fill="none" xmlns="http://www.w3.org/2000/svg">
//                           <path d="M87.9453 64.8438C87.9453 61.6211 85.3086 58.9844 82.0859 58.9844C85.3086 58.9844 87.9453 56.3477 87.9453 53.125C87.9453 49.9023 85.3086 47.2656 82.0859 47.2656H58.0627C61.6367 36.5428 61.5781 33.125 61.5781 26.5625C61.5781 21.6873 57.5809 17.7734 52.7891 17.7734H51.0312C49.918 17.7734 48.5117 18.6525 48.2186 20C45.3199 31.2854 40.7881 44.2352 29.3516 46.8582V89.2531L39.4881 92.617C42.4764 93.6129 45.582 94.1404 48.7461 94.1404H76.2266C79.4627 94.1406 82.0859 91.517 82.0859 88.2812C82.0859 85.0451 79.4627 82.4219 76.2266 82.4219H82.0859C85.3086 82.4219 87.9453 79.7852 87.9453 76.5625C87.9453 73.3398 85.3086 70.7031 82.0859 70.7031C85.3086 70.7031 87.9453 68.0664 87.9453 64.8438ZM14.7031 41.4062H2.98438C1.36504 41.4062 0.0546875 42.7166 0.0546875 44.3359V97.0703C0.0546875 98.6897 1.36504 100 2.98438 100H14.7031C19.5496 100 23.4922 96.0574 23.4922 91.2109V50.1953C23.4922 45.3488 19.5496 41.4062 14.7031 41.4062ZM14.7031 88.2812C13.0852 88.2812 11.7734 86.9695 11.7734 85.3516C11.7734 83.7332 13.0852 82.4219 14.7031 82.4219C16.3211 82.4219 17.6328 83.7332 17.6328 85.3516C17.6328 86.9695 16.3211 88.2812 14.7031 88.2812ZM40.358 12.9184L36.2152 8.77559C35.0709 7.63125 33.2168 7.63125 32.0725 8.77559C30.9281 9.91992 30.9281 11.774 32.0725 12.9184L36.2152 17.0611C37.3596 18.2055 39.2135 18.2055 40.358 17.0611C41.5023 15.9168 41.5023 14.0627 40.358 12.9184ZM73.5057 8.77559C72.3613 7.63125 70.5072 7.63125 69.3629 8.77559L65.2201 12.9184C64.0758 14.0627 64.0758 15.9168 65.2201 17.0611C66.3645 18.2055 68.2186 18.2055 69.3629 17.0611L73.5057 12.9184C74.65 11.774 74.65 9.91992 73.5057 8.77559ZM52.7891 0C51.1482 0 49.8594 1.28887 49.8594 2.92969V8.98438C49.8594 10.6252 51.1482 11.9141 52.7891 11.9141C54.4299 11.9141 55.7188 10.6252 55.7188 8.98438V2.92969C55.7188 1.28887 54.4299 0 52.7891 0Z" fill="#FFC924" />
//                         </svg>
//                       </div>
//                       <h3 className="text-purple fw-600 mb-3 poppins text-capitalize">2. You Get 15$ too!</h3>
//                       <p className="px-md-5 text-capitalize">Once the teacher teaches their first class, you will receive USD $15 credit to spend on any class.</p>
//                     </li>
//                   </ul>
//                   <div className="text-center my-lg-5 my-4">
//                     <span className="mb-3 d-block">Your Referral URL</span>
//                     <div className="copy_text_bx position-relative">
//                       <span id="to-copy" ref={textRef1} spellCheck="false">Studentscholar.com/{data.refer_teacher}</span>
//                       <button
//                         id="copy"
//                         className="position-absolute fw-500"
//                         onClick={handleCopyClick1}
//                         style={{
//                           top: 0,
//                           background: '#FFC924',
//                           padding: '10px 25px',
//                           display: 'block',
//                           right: 0,
//                           borderRadius: '50px',
//                           border: 'none',
//                         }}
//                       >
//                         {buttonText}
//                       </button>
//                     </div>
//                   </div>

//                   {/* <div className="text-center my-lg-5 mt-4">
//                     <span className="mb-3 d-block">Your Referral URL</span>
//                     <div className="copy_text_bx position-relative">
//                       <span id="to-copy" spellCheck="false">Studentscholar.com/3436FRSJ</span>
//                       <a href="#" id="copy" className="position-absolute fw-500">Copy</a>
//                     </div>
//                   </div> */}
//                 </div>
//               </div>
//               {/*end*/}
//             </div>
//           </div>
//         </div>
//       </main>
//       <PFooter_Dashboard />
//     </>
//   )
// }

