import { useEffect, useState } from "react";
import { teacher_view_profile } from "../../../../api/apiHandler";
import CommonNotificationList from "./commonNotificationList";
import { useLocation } from "react-router-dom";
import CommonChatBox from "../../Message/commonChatBox";
import EnrollCommonChatBox from "../../Message/enrollCommonChatBox";


const CommonMessageTab = ({ teacherData , activityId , activityType, ActiveTab, learnerId}) => {
  return (
    <div className={`tab-pane fade ${ActiveTab == "Messages" ? 'active show' : ''} `} id="pills-Messages" role="tabpanel" aria-labelledby="pills-Messages-tab">
      <div className="row">
      <div className="col-xl-4">
          <div className="modal-dialog-scrollable">
            <div className="modal-content">
              <div className="msg-head">
                {/* <span>Total Learners</span>
                
                */}
              </div>
              <div className="modal-body p-0">
                <div className="msg-body">
                  {/*first*/}
                  <div className="accordion border-radius-10 " id="accordionExample">
                    <div className="accordion-item border-0 border-bottom">
                      {/* <h2 className="accordion-header poppins" id="headingOne">
                        <button className="accordion-button shadow-none fs-6 text-dif-black bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"><div className="usr_leart_imgs">
                          <span>E</span>
                        </div>
                          Enrolled (1)
                        </button>
                      </h2> */}
                      <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <div className="enroll_list">
                            <ul>
                              {/* {lList?.map((LearnerList, index) => {
                                return (
                                  <li ref={el => (listItemRefs.current[index] = el)} onClick={() => getTeacherData(LearnerList)} className="d-flex mt-0 justify-content-between align-items-center">
                                    <div className="enroll_left d-flex align-items-center">
                                      <div className>
                                        <img src={LearnerList.profile_image} alt="kids-profile" />
                                      </div>
                                      <div className="ms-3">
                                        <span>{LearnerList.name}</span>
                                        <span className="ms-2 enroll_flag">
                                          <img src="./assets/images/country_flag.png" alt="country_flag" />
                                        </span>
                                      </div>
                                    </div>
                                    {LearnerList.learner_age <= 18 ?
                                      <div className="enroll_age">
                                        <span>Age : {LearnerList.learner_age}</span>
                                      </div>
                                      : <></>}
                                  </li>
                                )
                              })} */}
                              {/* {lList?.map((LearnerList, index) => (
                                <li
                                  ref={el => (listItemRefs.current[index] = el)}
                                  onClick={() => getTeacherData(LearnerList)}
                                  key={index}
                                  className="d-flex mt-0 justify-content-between align-items-center"
                                >
                                  <div className="enroll_left d-flex align-items-center">
                                    <div className="">
                                      <img src={LearnerList.profile_image} alt="kids-profile" />
                                    </div>
                                    <div className="ms-3">
                                      <span>{LearnerList.name}</span>
                                      <span className="ms-2 enroll_flag">
                                        <img src="./assets/images/country_flag.png" alt="country_flag" />
                                      </span>
                                    </div>
                                  </div>
                                  {LearnerList.learner_age <= 18 && (
                                    <div className="enroll_age">
                                      <span>Age : {LearnerList.learner_age}</span>
                                    </div>
                                  )}
                                </li>
                              ))} */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*second*/}
               
                  {/*three*/}
                  {/* <div className="accordion border-radius-10 " id="accordionExample">
                    <div className="accordion-item border-0 border-bottom">
                      <h2 className="accordion-header poppins" id="headingOne">
                        <button className="accordion-button shadow-none fs-6 text-dif-black bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree"><div className="usr_leart_imgs">
                          <span>W</span>
                        </div>
                          Withdrawn Enrollment(1)
                        </button>
                      </h2>
                      <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <div className="enroll_list">
                            <ul>
                              <li className="d-flex mt-0 justify-content-between align-items-center">
                                <div className="enroll_left d-flex align-items-center">
                                  <div className>
                                    <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                                  </div>
                                  <div className="ms-3">
                                    <span>Mark joseph</span>
                                    <span className="ms-2 enroll_flag">
                                      <img src="./assets/images/country_flag.png" alt="country_flag" />
                                    </span>
                                  </div>
                                </div>
                                <div className="enroll_age">
                                  <span>Age : 8</span>
                                </div>
                              </li>
                              <li className="d-flex mt-0 justify-content-between align-items-center">
                                <div className="enroll_left d-flex align-items-center">
                                  <div className>
                                    <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                                  </div>
                                  <div className="ms-3">
                                    <span>Mark joseph</span>
                                    <span className="ms-2 enroll_flag">
                                      <img src="./assets/images/country_flag.png" alt="country_flag" />
                                    </span>
                                  </div>
                                </div>
                                <div className="enroll_age">
                                  <span>Age : 8</span>
                                </div>
                              </li>
                              <li className="d-flex mt-0 justify-content-between align-items-center">
                                <div className="enroll_left d-flex align-items-center">
                                  <div className>
                                    <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                                  </div>
                                  <div className="ms-3">
                                    <span>Mark joseph</span>
                                    <span className="ms-2 enroll_flag">
                                      <img src="./assets/images/country_flag.png" alt="country_flag" />
                                    </span>
                                  </div>
                                </div>
                                <div className="enroll_age">
                                  <span>Age : 8</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-8">
          {/* <CommonChatBox teacherData={teacherData} activityId={activityId} activityType={activityType} /> */}
          <EnrollCommonChatBox teacherData={teacherData} activityId={activityId} activityType={activityType} learnerId={learnerId}/>
          {/* <div className="modal-dialog-scrollable">
            <div className="modal-content">
              <div className="msg-head">
                <div className="row">
                  <div className="col-8">
                    <div className="d-flex align-items-center">
                      <span className="chat-icon">
                        <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                        </svg>
                      </span>
                      <div className="flex-shrink-0 position-relative">
                        <img className="img-fluid chat_user" src={teacherData?.profile_image} alt="user img " />
                        <span className="active" />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h3>{teacherData?.full_name}</h3>
                        <p>Active Now</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <ul className="moreoption">
                      <li className="navbar nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="fa fa-ellipsis-v" aria-hidden="true" />
                        </a>
                        <ul className="dropdown-menu">
                          <li>
                            <a className="dropdown-item" href="#">
                              Action
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Another action
                            </a>
                          </li>
                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Something else here
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="msg-body">
                  <ul>
                    <li className="sender">
                      <div className="d-flex align-items-start">
                        <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                        <div>
                          <p className="text-dif-black">
                            {" "}
                            Hi, I hope you are doing well, yesterday you have gave a pen This very nice, i am very happy for this.yesterday you have
                            gave a pen This very nice{" "}
                          </p>
                          <span className="time text-end">10:06 am</span>
                        </div>
                      </div>
                    </li>
                    <li className="sender">
                      <div className="d-flex align-items-start">
                        <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                        <div>
                          <p className="text-dif-black"> Hey, Are you there? </p>
                          <span className="time text-end">10:16 am</span>
                        </div>
                      </div>
                    </li>
                    <li className="repaly">
                      <div className="d-flex justify-content-end">
                        <div>
                          <p className="text-dif-black">
                            yea I’m well, Thank you, i am very happy for this.yesterday you have gave a pen This very nice
                          </p>
                          <span className="time text-start">10:20 am</span>
                        </div>
                        <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                      </div>
                    </li>
                    <li className="sender">
                      <div className="d-flex align-items-start">
                        <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                        <div>
                          <p className="text-dif-black"> Hey, Are you there? </p>
                          <span className="time text-end">10:26 am</span>
                        </div>
                      </div>
                    </li>
                    <li className="sender">
                      <div className="d-flex align-items-start">
                        <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                        <div>
                          <p className="text-dif-black"> Hey, Are you there? </p>
                          <span className="time text-end">10:32 am</span>
                        </div>
                      </div>
                    </li>
                    <li className="repaly">
                      <div className="d-flex justify-content-end">
                        <div>
                          <p className="text-dif-black">How are you?</p>
                          <span className="time text-start">10:35 am</span>
                        </div>
                        <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                      </div>
                    </li>
                    <li>
                      <div className="divider">
                        <h6>Today</h6>
                      </div>
                    </li>
                    <li className="repaly">
                      <div className="d-flex justify-content-end">
                        <div>
                          <p className="text-dif-black"> yes, tell me</p>
                          <span className="time text-start">10:36 am</span>
                        </div>
                        <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                      </div>
                    </li>
                    <li className="repaly">
                      <div className="d-flex justify-content-end">
                        <div>
                          <p className="text-dif-black">yes... on it</p>
                          <span className="time text-start">junt now</span>
                        </div>
                        <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="send-box">
                <form action className="position-relative">
                  <input type="text" className="form-control rounded-pill" aria-label="message…" placeholder="Write message…" />
                  <a href="#" className="send_icons">
                    <i className="fa fa-paper-plane" aria-hidden="true" />
                  </a>
                  <div className="send-btns">
                    <div className="attach">
                      <div className="button-wrapper position-relative">
                        <span className="label">
                          <svg width={16} height={18} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M12.9297 2.94612C12.0719 2.08831 10.6797 2.08831 9.82187 2.94612L3.35313 9.41487C1.87305 10.8949 1.87305 13.2926 3.35313 14.7727C4.8332 16.2528 7.23086 16.2528 8.71094 14.7727L14.0547 9.42893C14.4379 9.04573 15.0637 9.04573 15.4469 9.42893C15.8301 9.81213 15.8301 10.4379 15.4469 10.8211L10.1031 16.1649C7.85312 18.4149 4.21094 18.4149 1.96094 16.1649C-0.289062 13.9149 -0.289062 10.2727 1.96094 8.02268L8.42969 1.55393C10.0574 -0.0738037 12.6941 -0.0738037 14.3219 1.55393C15.9496 3.18166 15.9496 5.81838 14.3219 7.44612L8.13437 13.6336C7.12891 14.6391 5.49766 14.6391 4.49219 13.6336C3.48672 12.6281 3.48672 10.9969 4.49219 9.99143L9.55469 4.92893C9.93789 4.54573 10.5637 4.54573 10.9469 4.92893C11.3301 5.31213 11.3301 5.93791 10.9469 6.32112L5.88438 11.3836C5.64883 11.6192 5.64883 12.0059 5.88438 12.2414C6.11992 12.477 6.50664 12.477 6.74219 12.2414L12.9297 6.05393C13.7875 5.19612 13.7875 3.80393 12.9297 2.94612Z"
                              fill="#131313"
                            />
                          </svg>
                        </span>
                        <input type="file" name="upload" id="upload" className="upload-box" placeholder="Upload File" aria-label="Upload File" />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div> */}
        </div>
        {/* <CommonNotificationList /> */}
      </div>
    </div>
  );
};

export default CommonMessageTab;
