import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { check_teacher_balance } from '../../../api/apiHandler';
import JqueryLoad from '../../../JqueryLoad/JqueryLoad';
import TFooter_Dashboard from '../Include/TFooter_Dashboard';
import THeader_Dashboard from '../Include/THeader_Dashboard';

export default function EwalletBalance({ isLoader }) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const TeacherEarning = async () => {

    isLoader(true)
    try {
      const response = await check_teacher_balance();
      console.log('response_check_teacher_balance :', response);
      if (response?.code == 1) {
        setData(response?.data)
        isLoader(false)

      } else {
        setData([])
        isLoader(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    TeacherEarning();
  }, [])
  return (
    <>
      <JqueryLoad />
      <THeader_Dashboard />
      <Helmet>
        <style>{`
  
  .card-container {
  border: 1px solid #e1dfdf;
  box-shadow: rgba(0, 0, 0, 0.20) 0px 2px 5px;
  border-radius: 10px;
  background-color: #570861;
  color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-container:hover {
  transform: translateY(-5px);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.card-content {
  transition: color 0.3s ease;
}

.card-container:hover .card-content {
  color: #ffffffcc; /* Lighter shade of white */
}

  `}</style>
      </Helmet>
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>

          <div className="class-content">
            <div className>
              {/*start*/}
              <a onClick={() => navigate("/teacher-e-wallet")} className="mb-4 arrow_left mb-3 d-block" >
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span 
                   className="ms-2">Back</span>
                </span></a>
              {/*end*/}
              {/*start*/}
              <div className>
                <div className=" ">
                  {/*start*/}
                  <div className="mb-4 d-md-flex align-items-center justify-content-between">
                    <h2 className="mb-md-0 mb-4 poppins fw-600">E Wallat Balance</h2>

                    <div className="fliter_list">
                      <ul className="list-unstyled slider_filter_list d-flex flex-wrap align-items-center">

                      </ul>
                    </div>
                  </div>
                  <div className="row">
        
                    <div className='d-flex'>
                      <div className='col-md-3 mt-5 mx-3 p-4 card-container'>
                        <div className='content card-content'>
                          <p className='fs-5 fw-600'>Available Balance</p>
                          <p className='fs-5 fw-500 mt-3'>$ {data?.length === 0 ? "0.00" : data?.available_balance}</p>
                        </div>
                      </div>

                      <div className='col-md-3 mt-5 mx-3 p-4 card-container'>
                        <div className='content card-content'>
                          <p className='fs-5 fw-600'>Pending Payment</p>
                          <p className='fs-5 fw-500 mt-3'>$ {data?.length === 0 ? "0.00" : data?.pending_balance}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <TFooter_Dashboard />
    </>
  )
}
