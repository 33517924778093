import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import PFooter_Dashboard from "../Pinc/PFooter_Dashboard";
import PHeader_Dashboard from "../Pinc/PHeader_Dashboard";


import Rejected_class from "./Rejected_class";
import BackToPrevious from "../../../components/backToPrevious";
import PNew_Leads from "./PNew_Leads";
import PResponded_class from "./PResponded_class";
import PLeeds_Filter from "./PLeeds_Filter";
import PNew_Headers from "./PNew_Headers";

export default function PNew_Leeds({isLoader}) {
  const navigate = useNavigate();

  const handleStepChange = (newStep) => setStep(newStep);

  const [CategoriesData, setCategoriesData] = useState(null);
  const [subCategoriesData, setSubCategoriesData] = useState([]);
  const [classSize, setclassSize] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [step, setStep] = useState(parseInt(localStorage.getItem("Request_Step", 1)) ? parseInt(localStorage.getItem("Request_Step", 1)) : 1);
  console.log("step :", step);

  useEffect(() => {
    localStorage.setItem("Request_Step", step);
  }, [step, parseInt(localStorage.getItem("Request_Step", 1))]);

  console.log('parseInt(localStorage.getItem("Request_Step",1)) :', localStorage.getItem("Request_Step", 1));
  const renderStep = () => {
    switch (localStorage.getItem("Request_Step", 1) ? parseInt(localStorage.getItem("Request_Step", 1)) : 1) {
      case 1:
        return (
          <PNew_Leads
            CategoriesData={CategoriesData}
            subCategoriesData={subCategoriesData}
            classSize={classSize}
            startDate={startDate}
            endDate={endDate}
            isLoader={isLoader}
          />
        );
      case 2:
        return (
          <PResponded_class
            CategoriesData={CategoriesData}
            subCategoriesData={subCategoriesData}
            classSize={classSize}
            startDate={startDate}
            endDate={endDate}
            isLoader={isLoader}
          />
        );
      // case 3:
      //     return (
      //         <Rejected_class CategoriesData={CategoriesData} subCategoriesData={subCategoriesData} classSize={classSize} startDate={startDate} endDate={endDate}/>
      //     );

      default:
        return null;
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <JqueryLoad />
      <PHeader_Dashboard />
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div>
              {/*start*/}
              <BackToPrevious />
              <div>
                <div className="d-xl-flex align-items-center mb-5 justify-content-between">
                  <PNew_Headers onStepChange={handleStepChange} isLoader={isLoader}/>
                  <PLeeds_Filter
                    CategoriesData={CategoriesData}
                    subCategoriesData={subCategoriesData}
                    setCategoriesData={setCategoriesData}
                    setSubCategoriesData={setSubCategoriesData}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    classSize={classSize}
                    setclassSize={setclassSize}
                    isLoader={isLoader}
                  />
                </div>
                <div className="my-4">
                  <Link to="/request_class_form" className="text-purple text-decoration-underline">
                  Fill Out The Form To Request A Class  
                  </Link>
                  {/* <Link to="/preferred_teacher" className="text-purple text-decoration-underline">Fill Out The Form To Become A Preferred Teacher</Link> */}
                </div>
                {renderStep()}
              </div>
            </div>
          </div>
        </div>
      </main>
      <PFooter_Dashboard />
    </>
  );
}
