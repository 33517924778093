import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { get_user_details, step_comments } from '../../../api/apiHandler';
import { Video_URL, Video_URL1 } from '../../../Config';
import Modals from 'react-modal';

var COMMON_STYLE_FOR_POPUP = {
  content: {
    backgroundColor: 'transparent',
    background: 'none',
    border: '0px',
  },
}

export default function Ostep() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate();
  const [data, setData] = useState([])
  const [modalIsOpenTearms, setIsOpenTearms] = useState(false);

  console.log('data :', data);

  const steps = async () => {
    try {
      let send_data = {
        userId: localStorage.getItem("Tid")
      };
      console.log('send_data:', send_data);

      const response = await get_user_details(send_data);
      console.log('response:', response);

      if (response?.code == 1) {
        setData(response.data);
      } else {
        ;
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  useEffect(() => {
    steps();
  }, [])

  const [commentsData, setCommentsData] = useState({
    comment_step_1: '',
    comment_step_2: '',
    comment_step_3: '',
    comment_step_4: '',
    comment_step_5: ''
  });

  const [showFullText1, setShowFullText1] = useState(false);
  const [showFullText2, setShowFullText2] = useState(false);
  const [showFullText3, setShowFullText3] = useState(false);
  const [showFullText4, setShowFullText4] = useState(false);
  const [showFullText5, setShowFullText5] = useState(false);

  const maxLength = 200;

  const stepComments = async () => {
    try {
      let send_data = {
        user_id: localStorage.getItem("Tid"),
        type: localStorage.getItem("type")
      };

      const response = await step_comments(send_data);
      console.log('step_comments :', response);
      if (response?.code == "1") {
        setCommentsData(response.data);
      } else {
        ;
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  useEffect(() => {
    stepComments();
  }, []);

  const renderComment = (comment, showFullText, setShowFullText) => {
    if (!comment || comment.length <= maxLength) {
      return comment;
    }

    return (
      <>
        {showFullText ? comment : `${comment.slice(0, maxLength)}...`}
        <a
          href="#learn-more"
          className='text-purple fw-600'
          onClick={(e) => {
            e.preventDefault();
            setShowFullText(!showFullText);
          }}
        >
          {showFullText ? ' Show Less' : ' Learn More'}
        </a>
      </>
    );
  };


  return (
    <>

      <main>
        <div className="login_sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 right">
                <div className="top">
                  <div className>
                    <div className="arrow_back mb-3">
                      <a onClick={() => { navigate('/login'); localStorage.clear(); }} className="d-flex align-items-center">
                        <span>
                          <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.86245 2.225L8.37912 0.75L0.137451 9L8.38745 17.25L9.86245 15.775L3.08745 9L9.86245 2.225Z" fill="#131313" />
                          </svg>
                        </span>
                        <span className="ps-3">Back</span>
                      </a>
                    </div>
                    <h2 className="mb-4">Welcome To StudentScholars</h2>
                    <p>You are only a few steps away from joining our team of specialized organizations at StudentScholars. Please follow the step-by-step guide to get started</p>

                    <p className='text-purple fw-600 text-center fs-5 mt-3'>Please Complete All 5 Steps of The Application</p>
                    {/*start*/}
                    <div className="video_section dash_video py-5">
                      <div className="video_box text-center">
                        <video id="video" height={564} className="border-0 w-auto shadow-none" loop controls="controls" >
                          <source src={`${Video_URL1}/Organization Application (1).mp4`} type="video/mp4" />
                        </video>
                      </div>
                    </div>
                    {/*end*/}
                    {/*start*/}
                    <div className="setp_list">
                      <ul>
                        {data[0]?.step_1 == 2 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 1/5</span>
                                <p className="mb-3 fw-600 text-purple fs-5">Tell us about yourself : <span className='fw-normal text-dark fs-6'>This is your interview. Please fill out the application to teach with us.
                                </span></p>
                                {data[0]?.step_1 != 2 ?
                                  <Link to="/organization-application" className="text-center d-block btn-theme bg-yellow fw-500">Fill Out Application</Link>
                                  : <></>}
                              </div>
                              <div className="col-md-2 order-first order-md-last text-center mb-md-0 mb-4">
                                <span><img src="./assets/images/completed.png" alt="completed" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Completed</span>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_1 == 3 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 1/5</span>
                                <p className="mb-3 fw-600 text-purple fs-5">Tell us about yourself : <span className='fw-normal text-dark fs-6'>This is your interview. Please fill out the application to teach with us.
                                </span></p>
                                <Link to="/organization-application" className="text-center d-block btn-theme bg-yellow fw-500">Fill Out Application</Link>
                              </div>

                              {commentsData.comment_step_1 != "" && commentsData.comment_step_1 != null ?
                                <div className="col-md-4">
                                  <span className="mb-3 fw-500">Comment : {renderComment(commentsData.comment_step_1, showFullText1, setShowFullText1)}</span>
                                </div> : <></>}
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/cancel.png" alt="cancel" /></span>
                                <span className="d-block fs-5 text-danger mt-2 mb-3 fw-500">Rejected</span>
                                <p className="fs-6">Reason: Sorry, your ID Verification is not complete</p>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_1 == 0 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 1/5</span>
                                <p className="mb-3 fw-600 text-purple fs-5">Tell us about yourself : <span className='fw-normal text-dark fs-6'>This is your interview. Please fill out the application to teach with us.
                                </span></p>
                                <Link to="/organization-application" className="text-center d-block btn-theme bg-yellow fw-500">Fill Out Application</Link>
                              </div>
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/not_yet_comp.png" alt="not_yet_comp" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Not Yet Completed</span>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_1 == 1 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 1/5</span>
                                <p className="mb-3 fw-600 text-purple fs-5">Tell us about yourself : <span className='fw-normal text-dark fs-6'>This is your interview. Please fill out the application to teach with us.
                                </span></p>
                                {data[0]?.step_1 == 0 && data[0]?.step_1 == 3 ?
                                  <Link to="/organization-application" className="text-center d-block btn-theme bg-yellow fw-500">Fill Out Application</Link>
                                  : <></>}
                              </div>
                              {commentsData.comment_step_1 != "" && commentsData.comment_step_1 != null ?
                                <div className="col-md-4">
                                  <span className="mb-3 fw-500">Comment : {renderComment(commentsData.comment_step_1, showFullText1, setShowFullText1)}</span>
                                </div> : <></>}
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/approved-yellow.svg" alt="not_yet_comp" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Pending</span>
                              </div>
                            </div>
                          </li>) : <></>}

                        {data[0]?.step_2 == 2 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 2/5</span>
                                <p className="mb-3 fw-600 text-purple fs-5">Identify Verification : <span className='fw-normal text-dark fs-6'>The next step is to confirm your identity with a selfie. It is to show us that the same person creating the account will be the administrator of the StudentScholars Organization account.
                                </span> </p>
                                <p className='mb-4'>Please note: This is an administrator account, <span className='fw-600'>you cannot teach with this account.</span> If you would like to teach as part of your organization, you are required to create a separate organization teacher account. This can be done after you set up your organization account.
                                </p>
                                {data[0]?.step_2 != 2 ?
                                  <Link to={"/organization-id-verification"} className="text-center d-block btn-theme bg-yellow fw-500">Complete Your ID Verification</Link>
                                  : <></>}
                              </div>
                              <div className="col-md-2 order-first order-md-last text-center mb-md-0 mb-4">
                                <span><img src="./assets/images/completed.png" alt="completed" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Completed</span>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_2 == 3 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 2/5</span>
                                <p className="mb-3 fw-600 text-purple fs-5">Identify Verification : <span className='fw-normal text-dark fs-6'>The next step is to confirm your identity with a selfie. It is to show us that the same person creating the account will be the administrator of the StudentScholars Organization account.
                                </span> </p>
                                <p className='mb-4'>Please note: This is an administrator account,<span className='fw-600'>you cannot teach with this account.</span>  If you would like to teach as part of your organization, you are required to create a separate organization teacher account. This can be done after you set up your organization account.
                                </p>
                                <Link to={"/organization-id-verification"} className="text-center d-block btn-theme bg-yellow fw-500">Complete Your ID Verification</Link>
                              </div>

                              {commentsData.comment_step_2 != "" && commentsData.comment_step_2 != null ?
                                <div className="col-md-4">
                                  <span className="mb-3 fw-500">Comment : {renderComment(commentsData.comment_step_2, showFullText2, setShowFullText2)}</span>
                                </div> : <></>}
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/cancel.png" alt="cancel" /></span>
                                <span className="d-block fs-5 text-danger mt-2 mb-3 fw-500">Rejected</span>
                                <p className="fs-6">Reason: Sorry, your ID Verification is not complete</p>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_2 == 0 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 2/5</span>
                                <p className="mb-3 fw-600 text-purple fs-5">Identify Verification : <span className='fw-normal text-dark fs-6'>The next step is to confirm your identity with a selfie. It is to show us that the same person creating the account will be the administrator of the StudentScholars Organization account.
                                </span> </p>
                                <p className='mb-4'>Please note: This is an administrator account, <span className='fw-600'>you cannot teach with this account.</span>  If you would like to teach as part of your organization, you are required to create a separate organization teacher account. This can be done after you set up your organization account.
                                </p>
                                <Link to={"/organization-id-verification"} className="text-center d-block btn-theme bg-yellow fw-500">Complete Your ID Verification</Link>
                              </div>
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/not_yet_comp.png" alt="not_yet_comp" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Not Yet Completed</span>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_2 == 1 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 2/5</span>
                                <p className="mb-3 fw-600 text-purple fs-5">Identify Verification : <span className='fw-normal text-dark fs-6'>The next step is to confirm your identity with a selfie. It is to show us that the same person creating the account will be the administrator of the StudentScholars Organization account.
                                </span> </p>
                                <p className='mb-4'>Please note: This is an administrator account, <span className='fw-600'>you cannot teach with this account.</span>  If you would like to teach as part of your organization, you are required to create a separate organization teacher account. This can be done after you set up your organization account.
                                </p>
                                {data[0]?.step_2 == 0 && data[0]?.step_2 == 3 ?
                                  <Link to={"/organization-id-verification"} className="text-center d-block btn-theme bg-yellow fw-500">Complete Your ID Verification</Link>
                                  : <></>}
                              </div>
                              {commentsData.comment_step_2 != "" && commentsData.comment_step_2 != null ?
                                <div className="col-md-4">
                                  <span className="mb-3 fw-500">Comment : {renderComment(commentsData.comment_step_2, showFullText2, setShowFullText2)}</span>
                                </div> : <></>}
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/approved-yellow.svg" alt="not_yet_comp" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Pending</span>
                              </div>
                            </div>
                          </li>
                        ) : <></>}

                        {data[0]?.step_3 == 2 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 3/5</span>
                                <p className="mb-3 fw-600 text-purple fs-5">Organization Agreement : <span className='fw-normal text-dark fs-6'>Please review, fill out and accept the agreement to teach as an organization.
                                </span> </p>
                                {data[0]?.step_3 != 2 ?
                                  <Link to={"/agreement"} className="text-center d-block btn-theme bg-yellow fw-500">Accept Agreement</Link>
                                  : <></>}
                              </div>
                              <div className="col-md-2 order-first order-md-last text-center mb-md-0 mb-4">
                                <span><img src="./assets/images/completed.png" alt="completed" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Completed</span>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_3 == 3 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 3/5</span>
                                <p className="mb-3 fw-600 text-purple fs-5">Organization Agreement : <span className='fw-normal text-dark fs-6'>Please review, fill out and accept the agreement to teach as an organization.
                                </span> </p>
                                <Link to={"/agreement"} className="text-center d-block btn-theme bg-yellow fw-500">Accept Agreement</Link>
                              </div>

                              {commentsData.comment_step_3 != "" && commentsData.comment_step_3 != null ?
                                <div className="col-md-4">
                                  <span className="mb-3 fw-500">Comment : {renderComment(commentsData.comment_step_3, showFullText3, setShowFullText3)}</span>
                                </div> : <></>}
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/cancel.png" alt="cancel" /></span>
                                <span className="d-block fs-5 text-danger mt-2 mb-3 fw-500">Rejected</span>
                                <p className="fs-6">Reason: Sorry, your ID Verification is not complete</p>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_3 == 0 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 3/5</span>
                                <p className="mb-3 fw-600 text-purple fs-5">Organization Agreement : <span className='fw-normal text-dark fs-6'>Please review, fill out and accept the agreement to teach as an organization.
                                </span> </p>
                                <Link to={"/agreement"} className="text-center d-block btn-theme bg-yellow fw-500">Accept Agreement</Link>
                              </div>
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/not_yet_comp.png" alt="not_yet_comp" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Not Yet Completed</span>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_3 == 1 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 3/5</span>
                                <p className="mb-3 fw-600 text-purple fs-5">Organization Agreement : <span className='fw-normal text-dark fs-6'>Please review, fill out and accept the agreement to teach as an organization.
                                </span> </p>
                                {data[0]?.step_3 == 0 && data[0]?.step_3 == 3 ?
                                  <Link to={"/agreement"} className="text-center d-block btn-theme bg-yellow fw-500">Accept Agreement</Link>
                                  : <></>}
                              </div>
                              {commentsData.comment_step_3 != "" && commentsData.comment_step_3 != null ?
                                <div className="col-md-4">
                                  <span className="mb-3 fw-500">Comment : {renderComment(commentsData.comment_step_3, showFullText3, setShowFullText3)}</span>
                                </div> : <></>}
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/approved-yellow.svg" alt="not_yet_comp" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Pending</span>
                              </div>
                            </div>
                          </li>
                        ) : <></>}

                        {data[0]?.step_4 == 2 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 4/5 (Optional)</span>
                                <p className="mb-3 fw-600 text-purple fs-5">Set up Your In-person Location : <span className='fw-normal text-dark fs-6'> If you are interested in finding more learners for your in-person location, this is a useful function to use.</span> </p>
                                <p className='mb-4'><span className='fw-600'>Before you apply please note: </span> In order to set up your in-person location; your organization has to be registered, your location should be complying with the jurisdiction of your city/province legal requirements, and the location should have a valid liability insurance to conduct lessons for learners. You will be fully responsible for any incident that occurs at your location. StudentScholars purpose is only to list your organization location on the StudentScholars website to find you learners.
                                </p>
                                {data[0]?.step_4 != 2 ?
                                  <Link to={"/organization-onsite-application"} className="text-center d-block btn-theme bg-yellow fw-500">Apply For Onsite Location Approval</Link>
                                  : <></>}
                              </div>
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                {/* <span><img src="./assets/images/cancel.png" alt="cancel" /></span> */}
                                <span><img src="./assets/images/completed.png" alt="completed" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Completed</span>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_4 == 3 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 4/5 (Optional)</span>
                                <p className="mb-3 fw-600 text-purple fs-5">Set up Your In-person Location : <span className='fw-normal text-dark fs-6'> If you are interested in finding more learners for your in-person location, this is a useful function to use.</span> </p>
                                <p className='mb-4'><span className='fw-600'>Before you apply please note: </span> In order to set up your in-person location; your organization has to be registered, your location should be complying with the jurisdiction of your city/province legal requirements, and the location should have a valid liability insurance to conduct lessons for learners. You will be fully responsible for any incident that occurs at your location. StudentScholars purpose is only to list your organization location on the StudentScholars website to find you learners.
                                </p>
                                <Link to={"/organization-onsite-application"} className="text-center d-block btn-theme bg-yellow fw-500">Apply For Onsite Location Approval</Link>
                              </div>
                              {commentsData.comment_step_4 != "" && commentsData.comment_step_4 != null ?
                                <div className="col-md-4">
                                  <span className="mb-3 fw-500">Comment : {renderComment(commentsData.comment_step_4, showFullText4, setShowFullText4)}</span>
                                </div> : <></>}
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/cancel.png" alt="cancel" /></span>
                                <span className="d-block fs-5 text-danger mt-2 mb-3 fw-500">Rejected</span>
                                <p className="fs-6">Reason: Sorry, your ID Verification is not complete</p>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_4 == 0 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 4/5 (Optional)</span>
                                <p className="mb-3 fw-600 text-purple fs-5">Set up Your In-person Location : <span className='fw-normal text-dark fs-6'> If you are interested in finding more learners for your in-person location, this is a useful function to use.</span> </p>
                                <p className='mb-4'><span className='fw-600'>Before you apply please note: </span> In order to set up your in-person location; your organization has to be registered, your location should be complying with the jurisdiction of your city/province legal requirements, and the location should have a valid liability insurance to conduct lessons for learners. You will be fully responsible for any incident that occurs at your location. StudentScholars purpose is only to list your organization location on the StudentScholars website to find you learners.
                                </p>
                                <Link to={"/organization-onsite-application"} className="text-center d-block btn-theme bg-yellow fw-500">Apply For Onsite Location Approval</Link>
                              </div>
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/not_yet_comp.png" alt="not_yet_comp" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Not Yet Completed</span>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_4 == 1 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 4/5 (Optional) </span>
                                <p className="mb-3 fw-600 text-purple fs-5">Set up Your In-person Location : <span className='fw-normal text-dark fs-6'> If you are interested in finding more learners for your in-person location, this is a useful function to use.</span> </p>
                                <p className='mb-4'><span className='fw-600'>Before you apply please note: </span> In order to set up your in-person location; your organization has to be registered, your location should be complying with the jurisdiction of your city/province legal requirements, and the location should have a valid liability insurance to conduct lessons for learners. You will be fully responsible for any incident that occurs at your location. StudentScholars purpose is only to list your organization location on the StudentScholars website to find you learners.
                                </p>
                                {data[0]?.step_4 == 0 && data[0]?.step_4 == 3 ?
                                  <Link to={"/organization-onsite-application"} className="text-center d-block btn-theme bg-yellow fw-500">Apply For Onsite Location Approval</Link>
                                  : <></>}
                              </div>
                              {commentsData.comment_step_4 != "" && commentsData.comment_step_4 != null ?
                                <div className="col-md-4">
                                  <span className="mb-3 fw-500">Comment : {renderComment(commentsData.comment_step_4, showFullText4, setShowFullText4)}</span>
                                </div> : <></>}
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/approved-yellow.svg" alt="not_yet_comp" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Pending</span>
                              </div>
                            </div>
                          </li>
                        ) : <></>}

                        {data[0]?.step_5 == 2 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 5/5</span>
                                <p className="mb-3 fw-600 text-purple fs-5">Setup Your Organization Profile : <span className='fw-normal text-dark fs-6'>Please set up your organization profile to start teaching. Do not share any personal or organization information including  email, phone number, social media accounts, and website as it is against our policies. Please refer to our  <span className='text-purple fw-500' onClick={() => setIsOpenTearms(true)}>‘Teacher & Organization Restrictions’</span>  for more information.
                                </span> </p>
                                {data[0]?.step_5 != 2 ?
                                  <Link to={"/organization-complete-profile"} className="text-center d-block btn-theme bg-yellow fw-500">Complete Your Profile</Link>
                                  : <></>}
                              </div>
                              <div className="col-md-2 order-first order-md-last text-center mb-md-0 mb-4">
                                <span><img src="./assets/images/completed.png" alt="completed" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Completed</span>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_5 == 3 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 5/5</span>
                                <p className="mb-3 fw-600 text-purple fs-5">Setup Your Organization Profile : <span className='fw-normal text-dark fs-6'>Please set up your organization profile to start teaching. Do not share any personal or organization information including  email, phone number, social media accounts, and website as it is against our policies. Please refer to our <span className='text-purple fw-500' onClick={() => setIsOpenTearms(true)}>‘Teacher & Organization Restrictions’</span>  for more information.
                                </span> </p>
                                <Link to={"/organization-complete-profile"} className="text-center d-block btn-theme bg-yellow fw-500">Complete Your Profile</Link>
                              </div>
                              {commentsData.comment_step_5 != "" && commentsData.comment_step_5 != null ?
                                <div className="col-md-4">
                                  <span className="mb-3 fw-500">Comment : {renderComment(commentsData.comment_step_5, showFullText5, setShowFullText5)}</span>
                                </div> : <></>}
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/cancel.png" alt="cancel" /></span>
                                <span className="d-block fs-5 text-danger mt-2 mb-3 fw-500">Rejected</span>
                                <p className="fs-6">Reason: Sorry, your ID Verification is not complete</p>
                              </div>
                            </div>
                          </li>
                        ) : data[0]?.step_5 == 0 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 5/5</span>
                                <p className="mb-3 fw-600 text-purple fs-5">Setup Your Organization Profile : <span className='fw-normal text-dark fs-6'>Please set up your organization profile to start teaching. Do not share any personal or organization information including  email, phone number, social media accounts, and website as it is against our policies. Please refer to our  <span className='text-purple fw-500' onClick={() => setIsOpenTearms(true)}>‘Teacher & Organization Restrictions’</span> for more information.
                                </span> </p>
                                <Link to={"/organization-complete-profile"} className="text-center d-block btn-theme bg-yellow fw-500">Complete Your Profile</Link>
                              </div>
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/not_yet_comp.png" alt="not_yet_comp" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Not Yet Completed</span>
                              </div>
                            </div>

                          </li>
                        ) : data[0]?.step_5 == 1 ? (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <span className="text-purple candal fs-5 mb-4 d-block">Step 5/5</span>
                                <p className="mb-3 fw-600 text-purple fs-5">Setup Your Organization Profile : <span className='fw-normal text-dark fs-6'>Please set up your organization profile to start teaching. Do not share any personal or organization information including  email, phone number, social media accounts, and website as it is against our policies. Please refer to our  <span className='text-purple fw-500' onClick={() => setIsOpenTearms(true)}>‘Teacher & Organization Restrictions’</span> for more information.
                                </span> </p>
                                {data[0]?.step_5 == 0 && data[0]?.step_5 == 3 ?
                                  <Link to={"/organization-complete-profile"} className="text-center d-block btn-theme bg-yellow fw-500">Complete Your Profile</Link>
                                  : <></>}
                              </div>
                              {commentsData.comment_step_5 != "" && commentsData.comment_step_5 != null ?
                                <div className="col-md-4">
                                  <span className="mb-3 fw-500">Comment : {renderComment(commentsData.comment_step_5, showFullText5, setShowFullText5)}</span>
                                </div> : <></>}
                              <div className="text-center col-md-2 order-first order-md-last mb-md-0 mb-4">
                                <span><img src="./assets/images/approved-yellow.svg" alt="not_yet_comp" /></span>
                                <span className="d-block fs-5 mt-2 fw-500">Pending</span>
                              </div>
                            </div>
                          </li>
                        ) : <></>}

                        {data[0]?.step_1 == 2 && data[0]?.step_2 == 2 && data[0]?.step_3 == 2 && data[0]?.step_5 == 2 && (
                          <li className="mb-4 border-top pt-3 pb-md-5 pb-3">
                            <div className="d-flex justify-content-between align-items-center row">
                              <div className="col-md-4">
                                <Link to={"/login"} className="text-center d-block btn-theme bg-yellow fw-500">Login</Link>
                              </div>

                            </div>
                          </li>
                        )}

                        {(data[0]?.step_1 == 1 && data[0]?.step_2 == 1 && data[0]?.step_3 == 1 && data[0]?.step_5 == 1) && (
                          <div className='fw-500 text-center'> <p className='mb-1'>Thank you for submitting your application.</p>
                            <p className='mb-1'>The Admin Team at StudentScholars is checking your application. If there are any further details required from you, we will contact you within 48 hours.</p>
                            <p className='mb-1'> If your application has passed all the requirements, you will get access to your ‘Organization Dashboard’ to set up your classes.</p>
                            <p className='mb-1'>Thank you once again for your interest to join<span className='ms-1 text-purple  fw-600'>StudentScholars,</span> <span className='fw-600'>The Global Learning MarketPlace for All Ages.</span> </p>
                            <p className='mb-1 text-purple fw-600'>Admin Team@StudentScholars</p>
                          </div>
                        )}

                      </ul>
                    </div>
                    {/*end*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Modals isOpen={modalIsOpenTearms} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal" >
        <div className="modal-dialog modal-lg modal-dialog-scrollable yello_left_border">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <h5 className="modal-title poppins fw_500" id="exampleModalLabel">
                <span className="me-2">
                  <svg width={23} height={30} viewBox="0 0 43 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.2886 15.4744C16.0354 15.4744 11.7617 19.748 11.7617 25.0007C11.7617 30.2541 16.0354 34.5276 21.2886 34.5276C26.5415 34.5276 30.8149 30.2541 30.8149 25.0008C30.8149 19.7479 26.5415 15.4744 21.2886 15.4744ZM26.9446 23.2068L20.1197 30.0316C19.9836 30.1681 19.8219 30.2763 19.6438 30.3501C19.4658 30.4239 19.2749 30.4618 19.0821 30.4616C18.8894 30.4618 18.6986 30.424 18.5206 30.3502C18.3426 30.2764 18.181 30.1681 18.045 30.0316L15.4368 27.4235C14.8639 26.8509 14.8639 25.922 15.4368 25.3491C16.0094 24.7761 16.9383 24.7761 17.5112 25.3491L19.0821 26.92L24.8702 21.1324C25.4428 20.5593 26.3717 20.5593 26.9446 21.1324C27.5176 21.7049 27.5176 22.6338 26.9446 23.2068Z" fill="#570861" />
                    <path d="M42.528 13.5663L42.5266 13.5274C42.5038 13.0097 42.4888 12.4918 42.4815 11.9737C42.4407 9.21675 40.2491 6.93629 37.4919 6.78229C31.7432 6.46139 27.296 4.58688 23.4962 0.883177L23.4638 0.852318C22.2244 -0.284106 20.3548 -0.284106 19.115 0.852318L19.0826 0.883177C15.2828 4.58688 10.8356 6.46139 5.08688 6.78258C2.33005 6.93629 0.138066 9.21685 0.0972458 11.974C0.090081 12.492 0.0751053 13.0098 0.052324 13.5274L0.0499802 13.6177C-0.061836 19.4798 -0.200703 26.7754 2.23992 33.3973C3.582 37.0389 5.61442 40.2042 8.28053 42.8062C11.317 45.7695 15.2942 48.122 20.1015 49.7982C20.2597 49.8532 20.4216 49.8973 20.5859 49.9302C20.8194 49.9767 21.0544 50 21.2894 50C21.5244 50 21.7597 49.9767 21.9928 49.9302C22.1578 49.8971 22.3203 49.8527 22.4791 49.7973C27.2807 48.1181 31.2537 45.7645 34.2875 42.8017C36.9525 40.1989 38.985 37.0327 40.3281 33.3904C42.7779 26.7487 42.6394 19.4393 42.528 13.5663ZM21.2886 37.4614C14.4175 37.4614 8.82789 31.8718 8.82789 25.0008C8.82789 18.1297 14.4176 12.5401 21.2886 12.5401C28.1593 12.5401 33.7493 18.1297 33.7493 25.0008C33.7493 31.8719 28.1593 37.4614 21.2886 37.4614Z" fill="#570861" />
                  </svg>
                </span>
                Teacher & Organization Restrictions
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => setIsOpenTearms
                (false)} />
            </div>
            <div className="modal-body">
              <div className="space-y-6 text-gray-700">
                <p className="text-base">
                  Notwithstanding the Terms or the Teacher Terms, StudentScholars may restrict, in whole or in part, suspend or terminate your use or access of the Services or your Teacher Account for repeated or extreme unprofessional conduct as determined in our sole discretion, including but not limited to:
                </p>

                <div>
                  <p className="fs-5 fw-600 text-lg mb-2 mt-2">Attendance</p>
                  <ol className="list-decimal list-inside space-y-1 ml-4">
                    <li>Teacher cancellation within 24 hours of class</li>
                    <li>Cancelling remaining session(s) after class begins</li>
                    <li>Multiple teacher cancellations 24 hours before a class begins</li>
                    <li>Cancelling class based on parent reviews or comments on other classes</li>
                    <li>Missing or failing to attend Session(s)</li>
                    <li>Repeatedly arriving late or leaving early</li>
                    <li>Failing to follow up with StudentScholars and affected learners when a Session is missed or cancelled</li>
                  </ol>
                </div>

                <div>
                  <p className="fs-5 fw-600 text-lg mb-2">Quality of Classes</p>
                  <ol className="list-decimal list-inside space-y-1 ml-4" start="8">
                    <li>Failing to adhere in providing the teaching environment or equipment required under our established policies</li>
                    <li>Consistently providing poor-quality class experiences complained by Parent / Guardian</li>
                    <li>Repeatedly failing to achieve pre-set teaching objectives for a class</li>
                    <li>Not cooperating with StudentScholars to solve problems when technical problems arise</li>
                    <li>Failing to wait for at least 10 minutes for a learner before cancelling the class</li>
                    <li>Providing classes to learners under the influence of alcohol or drugs</li>
                    <li>Using inappropriate language or gestures</li>
                  </ol>
                </div>

                <div>
                  <p className="fs-5 fw-600 text-lg mb-2">Shortcomings in the Quality of Services</p>
                  <ol className="list-decimal list-inside space-y-1 ml-4" start="15">
                    <li>Not providing regular feedback or answering questions</li>
                    <li>Not responding to messages from learners who are enrolled in a class</li>
                    <li>Failing to refund money to learners/parents if you do not attend a class or cancel classes</li>
                    <li>StudentScholars receiving complaints about a teacher's behavior</li>
                  </ol>
                </div>

                <div>
                  <p className="fs-5 fw-600 text-lg mb-2">Prohibited</p>
                  <ol className="list-decimal list-inside space-y-1 ml-4" start="19">
                    <li>When failing to maintain a good working relationship with StudentScholars adhering to established guidelines</li>
                    <li>For Unprofessional behaviors</li>
                  </ol>
                </div>

                <div>
                  <p className="fs-5 fw-600 text-lg mb-2">Strictly Prohibited</p>
                  <ol className="list-decimal list-inside space-y-1 ml-4" start="21">
                    <li>Disregarding student or parent safety or privacy</li>
                    <li>Using an account or unapproved third-party platform to teach live sessions, rather than using the services</li>
                    <li>Requesting payment (other than those collected through StudentScholars)</li>
                    <li>Requesting a learner's phone number and email to contact them privately</li>
                    <li>Sharing your phone number, email, company or social media details with the student for them to contact you privately</li>
                    <li>Transferring, lending or selling your teacher account</li>
                    <li>Failing to provide the required qualification certificate or routine background investigation information as required by StudentScholars</li>
                    <li>Deceiving, or trying to deceive, students or StudentScholars</li>
                    <li>Bad or disruptive behaviour</li>
                    <li>Using the content provided by StudentScholars other than as permitted in the Terms and Teacher Terms</li>
                  </ol>
                </div>
              </div>

            </div>
          </div>
        </div>
      </Modals>

    </>
  )
}
