import React from 'react'
import { Link } from 'react-router-dom'
import JqueryLoad from '../../../../JqueryLoad/JqueryLoad'
import OFooter_Dashboard from '../../OInclude/OFooter_Dashboard'
import OHeader_Dashboard from '../../OInclude/OHeader_Dashboard'

export default function OQuizInfo() {
  return (
    <>
    <JqueryLoad/>
<OHeader_Dashboard/>

      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div> 
            </div> 
          </header>  
          <div className="class-content">
            <div className>
              {/*start*/}
              <a  className="mb-4 arrow_left mb-3 d-block"  >
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span className="ms-2">Back</span>
                </span></a>
              {/*end*/}
              {/*start*/}  
              <div className> 
                <div className="class_lsit_section">
                  {/*start*/}
                  <div className="mb-4">
                    <h2 className="mb-2 poppins fw-600">My Quizzes</h2> 
                  </div>
                  {/*end*/}
                  {/*start*/}
                  <div className="quiz_card">
                    <div className="card_title mb-4">
                      <h3 className="mb-2 poppins fw-bold">History Quiz</h3>
                      <p className="fs-6 text-gray">Read the following instructions</p>
                    </div>
                    {/*start-row*/}
                    <div className="row mb-5 align-items-center">
                      <div className="col-xl-6 mb-xl-0 mb-4 left_side">
                        <div className="quiz_image">
                          <img src="./assets/images/classroom/class4.png" alt="class4" />
                        </div>
                      </div>
                      <div className="col-xl-6 right_side">
                        <div className="quiz_info ps-xl-5">
                          <table className="table table-borderless">
                            <tbody><tr>
                                <td className="text-purple fw-600">Date: </td>
                                <td className="text-dif-black">28/07/2021</td>
                              </tr>
                              <tr>
                                <td className="text-purple fw-600">Time Limit:</td>
                                <td className="text-dif-black">30 Mins</td>
                              </tr>
                              <tr>
                                <td className="text-purple fw-600">Attempts:</td>
                                <td className="text-dif-black">Once</td>
                              </tr>
                              <tr>
                                <td className="text-purple fw-600">No Of Ques::</td>
                                <td className="text-dif-black">15 Question</td>
                              </tr>
                            </tbody></table>
                        </div>
                      </div>
                    </div>
                    {/*end-row*/}
                    {/*start*/}
                    <div className="quiz_Inst">
                      <h4 className="mb-3 poppins fw-600">Instructions</h4>
                      <p className="fs-6 mb-lg-4 mb-md-3 mb-2">This quiz consists of 5 multiple-choice questions. To be successful with the quizzes, it’s important to conversant with the topics. Keep the following in mind:</p>
                      <p className="fs-6 mb-lg-4 mb-md-3 mb-2">Timing - You need to complete each of your attempts in one sitting, as you are allotted 30 minutes to each attempt.
                        Answers - You may review your answer-choices and compare them to the correct answers after your final attempt.</p>
                      <p className="fs-6 mb-lg-4 mb-md-3 mb-2">To start, click the "Start" button. When finished, click the "Submit " button.</p>
                    </div>
                    {/*end*/}
                    {/*button*/}
                    <div className="text-end pt-2">
                      <Link to="/add_quiz" className="btn-theme bg-yellow d-inline-block">Edit Quiz</Link>
                    </div>
                  </div>
                  {/*end*/}
                </div>  
              </div> 
              {/*end*/} 
            </div>
          </div>
        </div>
      </main>
<OFooter_Dashboard/>
    </>
  )
}
