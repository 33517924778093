import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import io from "socket.io-client";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { SOCKET_URL } from "../../../Config";
import { uploadImageOnAWS } from "../../aws/Service";
import { TOAST_ERROR, TOAST_SUCCESS, convertToBase64 } from "../../../utils/common.service";
import { block_chat, chat_history, pin_chat, show_personal_chat_list, update_user_activity_status } from "../../../api/apiHandler";

const ShowMessages = ({ discussionId, userData,teacherId, handleChatList }) => {
  const [chatHistory, setChatHistory] = useState(null);
  console.log('chatHistory :', chatHistory);
  console.log("🚀 ~ CommonChatBox ~ chatHistory:", chatHistory);
  const [page, setPage] = useState(1);
  const [message, setMessage] = useState("");
  console.log('message :', message);
  const [socket, setSocket] = useState(null);
  const [image, setImage] = useState({ blob: "", base64: "" });
  const [status, setStatus] = useState("Inactive");
  const USER_ID = JSON.parse(localStorage.getItem("Tid"));
  const USER_TYPE = localStorage.getItem("userType");
  const scrollToBottomRef = useRef(null);
  const scrollToTopRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    userData?.id && getChatHistory();
  }, [userData?.id, page, discussionId,teacherId]);

  useEffect(() => {
    if (userData?.id) {
      setMessage("");
      setImage({ blob: "", base64: "" });
      setPage(1);
      setStatus(userData?.status === "online" ? "Active" : "Inactive");
    }
  }, [userData?.id]);
  // useEffect(() => {
  //   userData && setStatus(userData?.status === "online" ? "Active" : "Inactive");
  // }, [userData]);

  const commonBody = {
    chat_id: chatHistory?.detail?.chat_id,
  };

  console.log('commonBody :', commonBody);


  const getChatHistory = async () => {
    try {
      let body = {
        page,
        user_id1: userData?.id,
        login_user_id: teacherId,
        user_type1: "user",
        user_type2: userData?.user_type,
      };
      console.log("chathistory123", body)
      if (discussionId) body.discussion_id = discussionId;
      const { data, code, message } = await show_personal_chat_list(body);
      console.log('datamessage :', data);
      if (code == 1) {
        socket &&
          socket.emit("read_message", {
            chat_id: data?.detail?.chat_id,
            user_id: userData?.id
          });
        if (page === 1) setChatHistory(data);
        else
          setChatHistory({
            ...chatHistory,
            list: [...chatHistory?.list, ...data.list],
          });
      }
    } catch (err) {
      // TOAST_ERROR(err.message);
    }
  };

  const handleActivityStatus = async (status) => {
    try {
      await update_user_activity_status({
        user_id: teacherId,
        status,
        user_type: USER_TYPE === "learner" ? "learner" : "user",
      });
    } catch (error) {
      // TOAST_ERROR(error.message);
    }
  };

  useEffect(() => {
    console.log("teacherId",teacherId)
    const socketInstance = io(SOCKET_URL, { query: { user_id: teacherId } });
    setSocket(socketInstance);
    socketInstance.on("connect", (res) => {
      handleActivityStatus("online");
      console.log("Connected to server");
    });

    socketInstance.on("error", (error) => {
      console.error("Socket error:", error);
    });
    socketInstance.on("receive_message", (newMessage) => {
      console.log("newMessage", newMessage)
      if (userData?.id == newMessage?.data?.user_id || teacherId == newMessage?.data?.user_id) {
        const updatedChatHistory = {
          ...chatHistory,
          list: [...chatHistory?.list, newMessage?.data],
        };
        setChatHistory(updatedChatHistory);
      }
    });
    socketInstance.on("user_status", (data) => {
      console.log("🚀 ~ socketInstance.on ~ data:", data);
      setStatus("Active");
    });
    socketInstance.on("start_typing", (data) => {
      if (data.user_id == userData?.id) setStatus("typing...");
    });
    socketInstance.on("stop_typing", (data) => {
      if (data.user_id == userData?.id) setStatus("Active");
    });

    return () => {
      if (socketInstance) {
        socketInstance.disconnect();
        handleActivityStatus("offline");
      }
    };
  }, [teacherId]);


  const sendMessage = async (e) => {
    e.preventDefault();
    if (message || image.base64) {
      let attachment_file = null;
      if (image.base64) attachment_file = await uploadImageOnAWS(image.blob, "chat");
      console.log("🚀 ~ sendMessage ~ attachment_file:", attachment_file);
      const newMessageBody = {
        ...commonBody,
        user_type: "user",
        type: image.base64 ? (
          image.blob.type.includes("image") ? "photo" : (
            image.blob.type.includes("video") ? "video" : (
              image.blob.type.includes("pdf") ? "pdf" : (
                image.blob.type.includes("msword") ||
                  image.blob.type.includes("vnd.openxmlformats-officedocument.wordprocessingml.document") ? "doc" : (
                  image.blob.type.includes("excel") ||
                    image.blob.type.includes("spreadsheetml") ? "excel" : "text"
                )
              )
            )
          )
        ) : "text",
        message: image.base64 ? attachment_file : message,
      };
      console.log('newMessageBody :', newMessageBody);

      socket.emit("send_message", newMessageBody);
      setMessage("");
      setImage({ blob: "", base64: "" });
      handleChatList();
    }
  };

  const handleMessage = async (e) => {
    const { files, value, type } = e.target;
    if (type === "text") setMessage(value);
    else if (type === "file") setImage({ blob: files[0], base64: await convertToBase64(files[0]) });
  };

  const handleScroll = () => {
    if (scrollToTopRef?.current?.scrollTop === 0 && chatHistory?.list?.length < chatHistory?.totalMessageCount) {
      setPage(page + 1);
    }
  };

  const scrollToBottom = () => scrollToBottomRef.current?.scrollIntoView({ behavior: "smooth" });

  useEffect(() => {
    if (message || page === 1) scrollToBottom();
  }, [chatHistory?.list, message, image.blob]);

  useEffect(() => {
    scrollToBottom();
    inputRef?.current?.focus();
  }, []);

  useEffect(() => {

    if (socket && userData?.id) {
      if (message) socket.emit("start_typing", { ...commonBody, user_id: userData?.id });
      else socket.emit("stop_typing", { ...commonBody, user_id: userData?.id });
    }
  }, [message, userData?.id, socket]);

  const handlePinUnpin = async () => {
    try {
      const body = {
        ...commonBody,
        is_pin: chatHistory?.detail?.is_pin === 0 ? 1 : 0,
      };
      const { code, message } = await pin_chat(body);
      if (code == 1) {
        getChatHistory();
        typeof handleChatList === "function" && handleChatList();
      }
    } catch (err) {
      // TOAST_ERROR(err.message);
    }
  };

  const handleBlockUnblock = async () => {
    try {
      const body = {
        ...commonBody,
        is_block: chatHistory?.detail?.is_block === 0 ? 1 : 0,
      };
      const { code, message } = await block_chat(body);
      if (code == 1) {
        getChatHistory();
      }
    } catch (err) {
      // TOAST_ERROR(err.message);
    }
  };

  const showMessages = useMemo(() => {
    dayjs.extend(utc);
    let showDividerIndex;
    return chatHistory?.list
      ?.sort((a, b) => a.chat_reply_id - b.chat_reply_id)
      ?.map((chat, i) => {
        const timestampToDate1 = dayjs.unix(chat.insert_datetime);
        const timestampToDate2 = dayjs.unix(chatHistory?.list?.[i - 1]?.insert_datetime);
        const time = timestampToDate1.format("h:mm A");
        const isToday = timestampToDate1.startOf("day").isSame(dayjs(), "day");
        const date = timestampToDate1.format("DD-MM-YYYY");
        if (isToday && !showDividerIndex) showDividerIndex = chat.chat_reply_id;
        else if (!timestampToDate1.startOf("day").isSame(timestampToDate2.startOf("day"))) showDividerIndex = chat.chat_reply_id;
        return (
          <Fragment key={chat.chat_reply_id}>
            {isToday && showDividerIndex === chat.chat_reply_id ? (
              <li>
                <div className="divider">
                  <h6>Today</h6>
                </div>
              </li>
            ) : showDividerIndex === chat.chat_reply_id ? (
              <li>
                <div className="divider">
                  <h6>{date}</h6>
                </div>
              </li>
            ) : (
              <>
              </>
            )}
            {teacherId == chat.user_id ? (
              <li className="repaly">
                <div className="d-flex justify-content-end">
                  <div>
                    {chat.type === "text" ? (
                      <p className="text-dif-black">{chat.message}</p>
                    ) : chat.type === "photo" ? (
                      <img width={100} src={chat.message} />
                    ) : chat.type === "video" ? (
                      <video controls width={150} src={chat.message} />
                    ) : chat.type === "pdf" ? (
                      <embed src={chat.message} type="application/pdf" width="100%" height="600px" />
                    ) : chat.type === "doc" ? (
                      <a href={chat.message} download>{chat.message}</a>
                    ) : chat.type === "excel" ? (
                      <a href={chat.message} download>{chat.message}</a>
                    ) : null}

                    <span className="time text-start">{time}</span>
                  </div>
                  <img className="img-fluid chat_user ms-2 rounded-circle" src={chat.profile_image} alt="user img" />
                </div>
              </li>
            ) : (
              <li className="sender">
                <div className="d-flex align-items-start">
                  <img className="img-fluid chat_user me-2 rounded-circle" src={chat.learner_profile_image != null ? chat.learner_profile_image : chat.profile_image} alt="user img" />
                  <div>
                    {chat.type === "text" ? (
                      <p className="text-dif-black">{chat.message}</p>
                    ) : chat.type === "photo" ? (
                      <img width={25} src={chat.message} />
                    ) : (
                      <video width={100} controls src={chat.message} />
                    )}
                    <span className="time text-end">{time}</span>
                  </div>
                </div>
              </li>
            )}
          </Fragment>
        );
      });
  }, [chatHistory?.list]);

  return (
    <div className="modal-dialog-scrollable">
      <div className="modal-content">
        <div className="msg-head">
          {discussionId ? (
            <span>Live Discussion Board</span>
          ) : (
            <div className="row">
              <div className="col-8">
                <div className="d-flex align-items-center">
                  <span className="chat-icon">
                    <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                    </svg>
                  </span>
                  <div className="flex-shrink-0 position-relative">
                    <img className="img-fluid chat_user rounded-circle" src={userData?.profile_image} alt="user img " />
                    {userData?.status === "online" && <span className="active" />}
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h3>{userData?.full_name}</h3>
                    <p>{status}</p>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <ul className="moreoption">
                  <li className="navbar nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="fa fa-ellipsis-v" aria-hidden="true" />
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item pointer" onClick={handlePinUnpin}>
                          {chatHistory?.detail?.is_pin ? "Unpin" : "Pin"}
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item pointer" onClick={handleBlockUnblock}>
                          {chatHistory?.detail?.is_block ? "Unblock" : "Block"}
                        </a>
                      </li>
                      {/* <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li> */}
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
        <div className="modal-body" ref={scrollToTopRef} onScroll={handleScroll}>
          <div className="msg-body">
            <ul>
              {showMessages}
           
            </ul>
            <div ref={scrollToBottomRef}></div>
          </div>
        </div>
     
      </div>
    </div>
  );
};

export default ShowMessages;
