import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from 'react-redux';
import store from './Store/index';
import AgoraRTC, { AgoraRTCProvider } from 'agora-rtc-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51Ocbq7LeOni4YpY8fPiBVPDvTn8YyU11fZefxsosXcMR8lipBjh5As2BWmnC99o9KLBno9vzgo9nyVhMQuiNyAay00pRF1BHEx');

const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
    <Provider store={store}>
      <Router basename={'/'}>
        <AgoraRTCProvider client={client}>
          <ToastContainer />
          <QueryClientProvider client={queryClient}>
          <Elements stripe={stripePromise}>
          <App />
          </Elements>
          </QueryClientProvider>
        </AgoraRTCProvider>
      </Router>
    </Provider>
  </React.Fragment>
);

reportWebVitals();
