import React from 'react'

export default function Header({ onStepChange, type_class }) {
  console.log('type_class :', type_class);
  const handleLinkClick = (newStep) => {
    // console.log('newStep :', newStep);
    localStorage.setItem("Teach_Step", newStep)
    onStepChange(newStep);
  };
  var active = localStorage.getItem("Teach_Step", 1) ? parseInt(localStorage.getItem("Teach_Step")) : 1
  return (
    <>
      <ul className="nav custom_nav_bar custom_yellow_line nav-pills mb-4" id="pills-tab" role="tablist">
        <li className="nav-item" role="presentation">
          <button className={`nav-link ${active === 1 ? "active" : ""}`} id="pills-classes-tab" data-bs-toggle="pill" data-bs-target="#pills-classes" type="button" role="tab" aria-controls="pills-classes" aria-selected="true" onClick={() => { handleLinkClick(1) }}>
            Classroom
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button className={`nav-link ${active === 2 ? "active" : ""}`} id="pills-Homework-tab" data-bs-toggle="pill" data-bs-target="#pills-Homework" type="button" role="tab" aria-controls="pills-Homework" aria-selected="false" onClick={() => { handleLinkClick(2) }}>
            Homework
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button className={`nav-link ${active === 3 ? "active" : ""}`} id="pills-Assignments-tab" data-bs-toggle="pill" data-bs-target="#pills-Assignments" type="button" role="tab" aria-controls="pills-Assignments" aria-selected="false" onClick={() => { handleLinkClick(3) }}>
            Assesments
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button className={`nav-link ${active === 4 ? "active" : ""}`} id="pills-quizzes-tab" data-bs-toggle="pill" data-bs-target="#pills-quizzes" type="button" role="tab" aria-controls="pills-quizzes" aria-selected="false" onClick={() => { handleLinkClick(4) }}>
            Quizzes
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button className={`nav-link ${active === 5 ? "active" : ""}`} id="pills-Discussion-tab" data-bs-toggle="pill" data-bs-target="#pills-Discussion" type="button" role="tab" aria-controls="pills-Discussion" aria-selected="false" onClick={() => { handleLinkClick(5) }}>
            Discussion Board</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className={`nav-link ${active === 6 ? "active" : ""}`} id="pills-Recordings-tab" data-bs-toggle="pill" data-bs-target="#pills-Recordings" type="button" role="tab" aria-controls="pills-Recordings" aria-selected="false" onClick={() => { handleLinkClick(6) }}>
            {type_class != "Offline Course" ? "Recordings" : "Attendance"}</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className={`nav-link ${active === 7 ? "active" : ""}`} id="pills-Messages-tab" data-bs-toggle="pill" data-bs-target="#pills-Messages" type="button" role="tab" aria-controls="pills-Messages" aria-selected="false" onClick={() => { handleLinkClick(7) }}>
            Messages</button>
        </li>
        {type_class != "Offline Course" ?
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${active === 8 ? "active" : ""}`} id="pills-review-tab" data-bs-toggle="pill" data-bs-target="#pills-review" type="button" role="tab" aria-controls="pills-review" aria-selected="false" onClick={() => { handleLinkClick(8) }}>
              Review</button>
          </li>
          : <></>}
        <li className="nav-item" role="presentation">
          <button className={`nav-link ${active === 9 ? "active" : ""}`} id="pills-Progress-tab" data-bs-toggle="pill" data-bs-target="#pills-Progress" type="button" role="tab" aria-controls="pills-Progress" aria-selected="false" onClick={() => { handleLinkClick(9) }}>
            Progress Report</button>
        </li>
        {type_class != "Offline Course" ?
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${active === 10 ? "active" : ""}`} id="pills-feedback-tab" data-bs-toggle="pill" data-bs-target="#pills-feedback" type="button" role="tab" aria-controls="pills-feedback" aria-selected="false" onClick={() => { handleLinkClick(10) }}>
              Feedback</button>
          </li>
          : <></>}
      </ul>
    </>
  )
}
