// import dayjs from "dayjs";
// import { useEffect, useState } from "react";
// import { Link } from "react-router-dom";

// const Pagination = ({ handleActiveMonth, activeMonth }) => {
//   const [monthList, setMonthList] = useState([]);

//   // console.log(monthList,'testtttttttttt');

//   useEffect(() => {
//     const currentDate = dayjs();  
//     const previousMonth = currentDate.subtract(1, "month").format("MMM YYYY");
//     const currentMonth = currentDate.format("MMM YYYY");
//     const nextMonth = currentDate.add(1, "month").format("MMM YYYY");
//     handleActiveMonth();
//     setMonthList([previousMonth, currentMonth, nextMonth]);
//   }, []);

//   const handleChangeMonth = (val) => {
// // console.log(val,'valueeeeeeeeeeee');
//     const currentMonth = dayjs(activeMonth);
//     let newMonth = "";
//     let tempMonthList = [...monthList];
//     const activeMonthIndex = tempMonthList.indexOf(activeMonth);
//     const count = activeMonthIndex === 0 ? 1 : activeMonthIndex === 1 ? 2 : 3;
//     if (val === "prev") {
//       newMonth = currentMonth.subtract(count, "month").format("MMM YYYY");
//       for (let i = 0; i < 3; i++) {
//         let tempMonth = tempMonthList[i];
//         tempMonthList[i] = newMonth;
//         newMonth = tempMonth;
//       }
//     } else if (val === "next") {
//       newMonth = currentMonth.add(count, "month").format("MMM YYYY");
//       tempMonthList.splice(0, 1);
//       tempMonthList.push(newMonth);
//     }
//     handleActiveMonth(tempMonthList[1]);
//     setMonthList(tempMonthList);
//   };

//   const displayMonths = monthList.map((month, i) => {
//     // console.log(activeMonth,'month');
//     return (
//       <li className={`page-item ${activeMonth === month ? "active" : ""}`} key={i} onClick={() => handleActiveMonth(month)}>
//         <a className="page-link text-dark border-start-0 border-end-0">{month} </a>
//       </li>
//     );
//   });

//   return (
//     <nav aria-label="Page navigation example" className="mt-5 custom-pagination">
//       <ul className="pagination justify-content-evenly align-items-center border-radius-10">
//         {/* <li className="page-item">
//           <a className="page-link text-dark" aria-label="Previous">
//             <span >
//               <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
//                 <path d="M453-241 213-481l240-240 42 42-198 198 198 198-42 42Zm253 0L466-481l240-240 42 42-198 198 198 198-42 42Z" />
//               </svg>
//             </span>
//           </a>
//         </li> */}
//         <li className="page-item" onClick={() => handleChangeMonth("prev")}>
//           <a className="page-link text-dark border-start-0" aria-label="Previous">
//             <span>
//               <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
//                 <path d="M561-240 320-481l241-241 43 43-198 198 198 198-43 43Z" />
//               </svg>
//             </span>
//           </a>
//         </li>
//         {displayMonths}
//         <li className="page-item" onClick={() => handleChangeMonth("next")}>
//           <a className="page-link border-start-0 " aria-label="Next">
//             <span>
//               <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
//                 <path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z" />
//               </svg>
//             </span>
//           </a>
//         </li>
//         {/* <li className="page-item">
//           <a className="page-link text-dark border-start-0 " aria-label="Next">
//             <span>
//               <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}>
//                 <path d="m255-241-42-42 198-198-198-198 42-42 240 240-240 240Zm253 0-42-42 198-198-198-198 42-42 240 240-240 240Z" />
//               </svg>
//             </span>
//           </a>
//         </li> */}
//       </ul>
//     </nav>
//   );
// };

// export default Pagination;
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Pagination = ({ handleActiveMonth, activeMonth }) => {
  const [monthList, setMonthList] = useState([]);

  useEffect(() => {
    const currentDate = dayjs();
    const previousMonth = currentDate.subtract(1, "month").format("MMM YYYY");
    const currentMonth = currentDate.format("MMM YYYY");
    const nextMonth = currentDate.add(1, "month").format("MMM YYYY");
    handleActiveMonth();
    setMonthList([previousMonth, currentMonth, nextMonth]);
  }, []);

  const handleChangeMonth = (val) => {
    let newMonthList = [...monthList];
    let newActiveMonth = activeMonth;

    if (val === "prev") {
      newActiveMonth = dayjs(activeMonth).subtract(1, "month").format("MMM YYYY");
      newMonthList = [
        dayjs(newActiveMonth).subtract(1, "month").format("MMM YYYY"),
        newActiveMonth,
        dayjs(newActiveMonth).add(1, "month").format("MMM YYYY"),
      ];
    } else if (val === "next") {
      newActiveMonth = dayjs(activeMonth).add(1, "month").format("MMM YYYY");
      newMonthList = [
        dayjs(newActiveMonth).subtract(1, "month").format("MMM YYYY"),
        newActiveMonth,
        dayjs(newActiveMonth).add(1, "month").format("MMM YYYY"),
      ];
    }

    handleActiveMonth(newActiveMonth);
    setMonthList(newMonthList);
  };

  const displayMonths = monthList.map((month, i) => {
    return (
      <li
        className={`page-item ${activeMonth === month ? "active" : ""}`}
        key={i}
        onClick={() => handleActiveMonth(month)}
      >
        <a className="page-link text-dark border-start-0 border-end-0">
          {month}{" "}
        </a>
      </li>
    );
  });

  return (
    <nav
      aria-label="Page navigation example"
      className="mt-5 custom-pagination"
    >
      <ul className="pagination justify-content-evenly align-items-center border-radius-10">
        <li className="page-item" onClick={() => handleChangeMonth("prev")}>
          <a className="page-link text-dark border-start-0" aria-label="Previous">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={22}
                viewBox="0 -960 960 960"
                width={25}
              >
                <path d="M561-240 320-481l241-241 43 43-198 198 198 198-43 43Z" />
              </svg>
            </span>
          </a>
        </li>
        {displayMonths}
        <li className="page-item" onClick={() => handleChangeMonth("next")}>
          <a className="page-link border-start-0 " aria-label="Next">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={22}
                viewBox="0 -960 960 960"
                width={25}
              >
                <path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z" />
              </svg>
            </span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;