import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer';
import { useNavigate } from 'react-router-dom';
import { terms_conditions } from '../../api/apiHandler';
import CommonTruncatedText from '../../utils/commonTruncatedText';



export default function AboutUs() {


    const navigate = useNavigate()
    const [data, setData] = useState()
    const handleBack = () => {
        navigate(-1);
    };

    const term_data = () => {
        terms_conditions().then((response) => {
            console.log(response, 'term_data');
            if (response?.code == 1) {
                setData(response?.data);

            } else {
                setData(null);
                console.log("Data Not Found");
            }
        });
    };

    useEffect(() => {
        term_data()
    }, [])
    return (
        <>

            <Header />
            <main>

                <section>
                    {/*start*/}
                    <span className="mb-4 arrow_left mb-3 d-block mx-4 mt-2" onClick={handleBack} >

                        <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                        </svg>
                        <span className="ms-2">Back</span>
                    </span>
                    {/*end*/}
                    <div className="container">
                   
                    {/* <CommonTruncatedText text={data?.content} length={25000} className="fs-6" /> */}
                    {/* <div dangerouslySetInnerHTML={{ __html: data?.content }} className="mb-3 mt-2" /> */}

                    </div><br />
                </section>



            </main>
            <Footer />
        </>
    )
}
