// import AWS, { CloudFormation } from "aws-sdk";
import AWS, { CloudFormation } from "aws-sdk";

AWS.config.update({
  region: "ap-south-1",
  accessKeyId: "AKIA3MEJ26PR5TKQSMSN",
  secretAccessKey: "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",
});

export const uploadImageOnAWS = async (file, folder) => {
console.log('file :', file);
  // console.log(file);
  const s3 = new AWS.S3();
  const fileName = `${new Date().getTime()}${file.type?.split("/")?.[0] == "image" ? ".png" : file.type?.split("/")?.[0] == "video" ? ".mp4" : file?.name}`;
  const params = {
    Bucket: "hlink-bhavinp-s3",
    Key: `student_scholar/${folder}/${fileName}`,
    Body: file,
    ACL: "public-read-write",
  };
    
  try {
    await s3.upload(params).promise();
    console.log("Filename", fileName);
    return fileName;
  } catch (error) {
    console.log("error in S3 upload :", error);
    throw error;
  }
};  
