import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import PFooter_Dashboard from "../Pinc/PFooter_Dashboard";
import PHeader_Dashboard from "../Pinc/PHeader_Dashboard";
import { calendar_data, contact_us_details, interested_class, learner_list_parents, loginApi, notification_listing, parent_data, scheduled_date_list } from "../../../api/apiHandler";
import dayjs from 'dayjs';
import "@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css";
import { Calendar } from "@amir04lm26/react-modern-calendar-date-picker";
import { Link, useNavigate } from "react-router-dom";
import { loginRedirectCallWithDataStore } from "../../Common/RedirectPathMange";
import BackToPrevious from "../../../components/backToPrevious";
import { encryptURL } from "../../../api/apiClient";

export default function PNotification({ isLoader }) {
  const navigate = useNavigate();

  /////////////////// Scheduale data /////////////////
  const currentDate = dayjs();
  const defaultValue = {
    year: currentDate.year(),
    month: currentDate.month() + 1,
    day: currentDate.date(),
  };
  var [data2, setData2] = useState([]);
  var [calender, setcalender] = useState([]);
  const [selectedDay, setSelectedDay] = useState(defaultValue);
  const [scheduledDateList, setScheduledDateList] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedLearnerSchedule, setSelectedLearnerSchedule] = useState("");
  var [LearnerList, setLearnerList] = useState([]);
  const [switchProfile, setSwitchProfile] = useState(false);
  var [userData, setUserData] = useState([]);

  const userType = localStorage.getItem("userType");
  const isparent = localStorage.getItem("isparent")

  const handleCalendarSelect = (day) => {
    setSelectedDay(day);
  };

  const calender_details = () => {
    isLoader(true)
    let body = {};
    if (selectedLearnerSchedule) body.learnerId = selectedLearnerSchedule;
    if (selectedFilter) body.class = selectedFilter;
    if (selectedDay) body.custom_date = `${selectedDay.year}-${selectedDay.month}-${selectedDay.day}`;

    calendar_data(body).then((resposnse) => {
      if (resposnse?.code == 1) {
        setcalender(resposnse?.data);
        isLoader(false)
      } else {
        setcalender(resposnse?.data);
        isLoader(false)
      }
    });
  };

  const getScheduledDates = () => {
    isLoader(true)

    let body = {};
    if (selectedLearnerSchedule) body.learner_id = selectedLearnerSchedule;
    scheduled_date_list(body).then((resposnse) => {
      if (resposnse?.code == 1) {
        const scheduledDates = [];
        for (let item of resposnse?.data) {
          if (item?.date) {
            let tempDate = item?.date?.split("-");
            scheduledDates.push({ year: +tempDate[0], month: +tempDate[1], day: +tempDate[2], className: "highlight-date" });
          }
        }
        setScheduledDateList(scheduledDates);
        isLoader(false)
      } else {
        setScheduledDateList([]);
        isLoader(false)
      }
    });
  };

  const showLearnerList = LearnerList.map((data, index) => {
    return (
      <option key={index} value={data.id}>
        {data.learner_name}
      </option>
    );
  });


  const getUserData = () => {
    console.log('test');
    contact_us_details().then((response) => {
      console.log('user_data', response);
      if (response?.code === "1") {
        setUserData(response?.data);
      } else {
        console.log("Data Not Found");
      }
    });
  }

  const learner_list_parents_details = () => {
    if (userType == "learner") {
      console.log('got to parent');
      parent_data().then((resposnse) => {
        if (resposnse.code == 1) {
          console.log('resposnse', resposnse);
          setLearnerList(resposnse.data);
          getUserData()
        } else {
          console.log("Data Not Found");
        }
      });
    } else {
      console.log('got to learner');

      learner_list_parents().then((resposnse) => {
        console.log('resposnse', resposnse);
        if (resposnse?.code == 1) {
          setLearnerList(resposnse?.data);
          getUserData()
        } else {
          console.log("Data Not Found");
        }
      });
    }

  };

  const interested_class_details = () => {
    isLoader(true)

    interested_class().then((resposnse) => {
      if (resposnse?.code == 1) {
        setData2(resposnse?.data);
        isLoader(false)

      } else {
        console.log("Data Not Found");
        isLoader(false)

      }
    });
  };

  const handleLogin = (data) => {
    // console.log('dataaaa', data);
    isLoader(true)

    loginApi({ email: data.learner_email || data.email, password: data.learner_password || data.password, login_type: "S" })
      .then((resposnse) => {
        console.log('resposnse', resposnse);
        if (resposnse?.code == 1) {
          loginRedirectCallWithDataStore(resposnse?.data);
          localStorage.setItem("isparent", 1)
          learner_list_parents_details()
  
          navigate("/notification");
          setSwitchProfile(true);
        isLoader(false)

        } else {
        isLoader(false)
          
        }
 

      })
      .catch((err) => console.log("🚀 ~ loginApi ~ err:", err));
  }


  const showLearnerList1 = LearnerList.map((data, index) => {
    return (
      <li key={index}>
        <a
          className="dropdown-item"
          href="#"
          onClick={() => handleLogin(data)}
        >
          <img src={data.learner_image ? data.learner_image : "/assets/images/kids-profile-switch.png"} alt={data.learner_name || data.full_name} />
          <span className="ps-2">{data.learner_name || "Back To Parent"} </span>
        </a>
      </li>
    );
  });


  useEffect(() => {
    getUserData();
    interested_class_details();
  }, []);


  useEffect(() => {
    learner_list_parents_details();
    setSwitchProfile(false);
  }, [switchProfile]);

  useEffect(() => {
    calender_details();
  }, [selectedFilter, selectedLearnerSchedule, selectedDay]);

  useEffect(() => {
    getScheduledDates();
  }, [selectedLearnerSchedule]);


  ////////// Notification Listing //////////////
  const [notificationData, setNotificationData] = useState('');
  console.log('notificationData :', notificationData);

  const NotificationList = async () => {
    isLoader(true)

    try {
      const response = await notification_listing();
      console.log('response123 :', response);
      if (response?.code == 1) {
        setNotificationData(response?.data)
        isLoader(false)

      } else {
        setNotificationData([])
        isLoader(false)

      }

    } catch (error) {
      console.log('error :', error);

    }
  }

  useEffect(() => {
    NotificationList();
  }, [switchProfile])

  const generatePath = (data) => {
    var classType = data?.action_type
  const basePath = classType === "club"
    ? "/my_courses_details_clubs"
    : classType === "quiz"
      ? "/quiz-info"
      : classType === "competition"
        ? "/my_courses_details_competitions"
        : "/my_courses_detail";

  const queryParams = new URLSearchParams({
    class_id: data?.action_id,
    teacher_id: data?.teacher_id,
    id: data?.action_id,
    type: data?.action_type,
    learner_data: JSON.stringify( {id: data?.id,
      learner_age: data?.learner_age,
      learner_name: data?.learner_name,
      learner_profile_img: data?.learner_profile_img,}
      ),
    class_type: data?.type

   

  }).toString();
  const encryptedURL = encryptURL(`${basePath}?${queryParams}`);
  return encryptedURL;
};

  return (
    <>
      <JqueryLoad />
      <PHeader_Dashboard />

      <Helmet>
         {/* <script src="../assets/js/script.js"></script>    */}
        <style>
          {`
        .drop_calendar.filter_box {
          min-width: 523px;
        }
        .Calendar__weekDays {
          color: #570861;
        }
        .Calendar__day.-selected,
        .Calendar__day.-selectedStart,
        .Calendar__day.-selectedEnd {
          background: #ffc924;
          color: #212529;
        }
        .Calendar__day.-weekend:not(.-selected):not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
          color: #212529;
        }
        .Calendar__day.-selectedBetween {
          background: rgba(241, 241, 241, 0.65);
          color: #060706;
          border-radius: 0;
        }
        .scrollable-content {
          max-height: 200px;
          overflow-y: auto;
        }
        
        .scrollable-content::-webkit-scrollbar {
          width: 5px;
        }
        
        .scrollable-content::-webkit-scrollbar-thumb {
          background-color: #570861;
          border-radius: 10px;
        }
        
        .scrollable-content::-webkit-scrollbar-track {
          background-color: #f0f0f0;
        }
        
        .off_box.card .off_bg_img img.off_user {
          position: absolute;
          bottom: -22px;
          left: 20px;
          width: 65px;
          height: 65px;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
        }
        
        `}
        </style>
        

      </Helmet>
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className="d-flex justify-content-between w-100 mt-lg-0 mt-4">
              <div>
                {/*start*/}
                <BackToPrevious />
                {/*end*/}
              </div>
              {(userType !== "learner" || isparent == "1") && (
                <div className="d-lg-block d-none">
                  <div className="dropdown switch-profile">
                    <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" >
                      Switch Profile
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item">Switch To : </a>
                      </li>
                      {showLearnerList1}
                    </ul>
                  </div>
                </div>
              )}
            </div>
            <div className="container-fluid">
              <div className="row dashboard_part">
                <div className="col-xl-9 right">
                  {/*start*/}
                  <div className="mb-5">
                    <h2 className="poppins fw-600 mb-3">Notifications</h2>
                    <p>Please see below notifications that require your attention.</p>
                  </div>
                  {/*end*/}
                  {/*start*/}

                  {Array.isArray(notificationData) && notificationData.map((dateItem, index) => (
                    <div key={index}>
                      {dayjs(dateItem.date).isSame(dayjs(), 'day') ? (
                        <span className="mb-4 d-block">Today</span>
                      ) : dayjs(dateItem.date).isSame(dayjs().subtract(1, 'day'), 'day') ? (
                        <span className="mb-4 d-block">Yesterday</span>
                      ) : (
                        <span className="mb-4 d-block">{dateItem.date}</span>
                      )}
                      <div className="list_notification">
                        <ul>
                          {dateItem.notification_data.map((notification, index) => {
                            const notificationContent = (
                              <>
                                <div className={`noti_img bg-${notification.tag === 'add_class' ? 'pink' : notification.tag === 'quiz' ? 'diff-yellow-op-1' : 'sky-blue-op-1'} position-relative`}>
                                  <span className={`text-uppercase text-${notification.tag === 'add_class' ? 'danger' : notification.tag === 'quiz' ? 'yellow' : 'blue'}`}>{notification.message.charAt(0)}</span>
                                  <span className="notifi_logs">
                                    <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M10 4.5V9C10 9.13261 9.94732 9.25979 9.85355 9.35355C9.75979 9.44732 9.63261 9.5 9.5 9.5H0.5C0.367392 9.5 0.240215 9.44732 0.146447 9.35355C0.0526784 9.25979 0 9.13261 0 9V4.5H10ZM10 2.5H0V1C0 0.867392 0.0526784 0.740215 0.146447 0.646447C0.240215 0.552678 0.367392 0.5 0.5 0.5H9.5C9.63261 0.5 9.75979 0.552678 9.85355 0.646447C9.94732 0.740215 10 0.867392 10 1V2.5Z" fill="#3F3F44" />
                                    </svg>
                                  </span>
                                </div>
                                <p className="ms-3 noti_text">{notification.tag === "admin_notification" ? `Admin has sent you a notification: ${notification.message}` : notification.message}</p>
                              </>
                            );

                            let linkTo = '';
                            let linkState = {};


                            if (notification.tag === 'add_class') {
                              linkTo = `/class_detail/${notification?.teach_id}?teacher_id=${notification?.teacher_id}`;
                              // linkState = notification.action_id;
                            } else if (notification.tag === 'add_club') {
                              linkTo = `/club_detail/${notification?.teach_id}?teacher_id=${notification?.teacher_id}`;
                              // linkState = notification.action_id;
                            } else if (notification.tag === 'add_quiz') {
                              linkTo = `/quize_detail/${notification?.teach_id}?teacher_id=${notification?.teacher_id}`;
                              // linkState = { id: notification.action_id }
                            } else if (notification.tag === 'add_competition') {
                              linkTo = `/competitions_detail/${notification?.teach_id}?teacher_id=${notification?.teacher_id}`;
                              // linkState = notification.action_id;
                            } else if (notification.tag === 'classroom_add') {
                              linkTo = `/my_courses_detail_classes/${notification.teach_id}?type=classroom`;
                              // linkState = { id: notification?.teach_id, type: notification?.action_type }
                            } else if (notification.tag === 'homework_add') {
                              linkTo = `/my_courses_detail_classes/${notification.teach_id}?type=${notification.teach_type}`;
                              // linkState =  {id : notification.teach_id, type : notification.teach_type}
                            } else if (notification.tag === 'assetment_add') {
                              linkTo = `/my_courses_detail_classes/${notification.teach_id}?type=assessment`;
                              // linkState =  {id : notification.teach_id, type : notification.teach_type}
                            } else if (notification.tag === 'discussion_add') {
                              // linkTo = '/edit-disscussion';
                              linkState = notification.teach_id;
                            } else if (notification.tag === 'assetment_due') {
                              linkTo = `/my_courses_detail_classes/${notification.teach_id}?type=assessment`;
                              // linkState = { assessment_id: 71, id: { id: 59, class: "class" } }
                            } else if (notification.tag === 'homework_due') {
                              linkTo = `/my_courses_detail_classes/${notification.teach_id}?type=${notification.teach_type}`;
                              // linkState = { homework_id: 142, id: { id: 59, class: "class" } }
                            }
                            else if (notification.tag === "class_stop" || notification.tag === "class_pause") {
                              linkTo = generatePath(notification);
                          
                            }

                            return (
                              <li key={index} className="d-flex mb-4">
                                {notification.shouldRedirect !== false ? (
                                  <Link
                                    to={linkTo} state={linkState}
                                    className="notif_left d-flex align-items-center"
                                    style={{ textDecoration: 'none', color: 'inherit' }} // Maintain text styling
                                  >
                                    {notificationContent}
                                  </Link>
                                ) : (
                                  <div className="notif_left d-flex align-items-center">
                                    {notificationContent}
                                  </div>
                                )}
                                <span className="noti_timer">
                                  {dayjs(notification.created_at).format('hh:mm A')}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-xl-3 left position-relative">
                  <div className="mb-5">
                    <div className="avatar-upload">                    
                      <div className="avatar-preview">
                        <div
                          id="imagePreview"
                          style={{
                            backgroundImage: userData[0]?.profile_image ? `url(${userData[0]?.profile_image})` : 'url("./assets/images/kids-profile.png")',
                          }}
                        ></div>
                      </div>

                    </div>
                    <div className="text-center avatar_info">
                      <span className="text-dif-black">{userData[0]?.user_name ?? userData[0]?.learner_name}</span>
                      {userType == "below_18" ? (<p className="text-gray">Code:#{userData[0]?.code_generate}</p>) : null}

                    </div>
                  </div>
                  <div className="wrappers" style={{ marginLeft: "30px" }}>
                    <Calendar
                      value={selectedDay}
                      onChange={(day) => {
                        handleCalendarSelect(day);
                        // Close the dropdown if needed
                      }}
                      shouldHighlightWeekends
                      customDaysClassName={scheduledDateList}
                    />
                  </div>            
                  <div className="sche_sec">
                    <div className="title mb-2">
                      <h2 className="poppins fw-600 my-auto">Schedule</h2>
                    </div>
                    <div className="d-flex mb-4">
                      {(userType === "below_18" || userType === "after_school") && (
                        <select
                          className="border-purple bg-purple text-white fs-6 rounded-cricle rounded-pill px-2 py-2"
                          onChange={(e) => setSelectedLearnerSchedule(e.target.value)}
                          value={selectedLearnerSchedule}
                          defaultValue={""}
                        >
                          <option value={""}>Select a learner</option>
                          {showLearnerList}
                        </select>
                      )}                     
                    </div>
                 
                    <div className="schedule_list">
                      {calender && Array.isArray(calender) && calender.length > 0 ? (
                        <ul>
                          {calender.map((item, index) => (
                            <li key={index}>
                              <Link
                                // to={`/my_courses_detail_classes/${item?.class_id}?type=classroom`}
                                to={"/my_courses_detail"}
                                state={{ class_id: item?.class_id, teacher_id: item?.teacher_id }}
                                className="d-flex align-items-center justify-content-between"
                              >
                                {/* <a href="#" className="d-flex align-items-center justify-content-between"> */}
                                <div>
                                  <h3 className="text-break">{item.title}</h3>
                                  <p>Time : {item.format_time}</p>
                                  <p>Date : {item.format_date}</p>

                                  <span className="text-dif-black teac_schud_nam">
                                    Teacher’s Name : <span>{item.teacher_name}</span>
                                  </span>
                                </div>
                                <div className="arrs">
                                  <svg width={7} height={12} viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M1.19727 10.6209L5.0006 6.81753C5.44977 6.36836 5.44977 5.63336 5.0006 5.18419L1.19727 1.38086"
                                      stroke="#131313"
                                      strokeOpacity="0.4"
                                      strokeWidth="1.5"
                                      strokeMiterlimit={10}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </div>
                                {/* </a> */}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <div className="col-12">
                          <p style={{ color: "purple" }}>Data is not found</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*end-here*/}
      </main>
      <Helmet>
        <script src="./assets/js/custom_calendar.js"></script>
      </Helmet>
      <PFooter_Dashboard />
    </>
  );
}
