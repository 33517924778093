import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const CommonTabs = ({ handleActiveMonth, updateParentState, setActiveTab, ActiveTab, generateCertificate, class_type }) => {
  const location = useLocation();
  const path = location.pathname.split("/")[1];

  useEffect(() => {
    updateParentState('Child component mounted');
  }, []);

  const handleClick = () => {
    updateParentState('Button clicked');
  };
  return (
    <ul className="nav custom_nav_bar custom_yellow_line nav-pills mb-4" id="pills-tab" role="tablist">
      {path !== "my_courses_details_quizzes" && (
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${ActiveTab == "Classroom" ? 'active' : ''} `}
            id="pills-classes-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-classes"
            type="button"
            role="tab"
            aria-controls="pills-classes"
            aria-selected="true"
            onClick={() => { handleActiveMonth(); localStorage.setItem("NavItem", "Classroom"); setActiveTab("Classroom") }}
          >
            Classroom
          </button>
        </li>
      )}
      {path !== "my_courses_details_competitions" && (
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${ActiveTab == "Homework" ? 'active' : ''} `}

            id="pills-Homework-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-Homework"
            type="button"
            role="tab"
            aria-controls="pills-Homework"
            aria-selected="false"
            onClick={() => { handleActiveMonth(); localStorage.setItem("NavItem", "Homework"); setActiveTab("Homework") }}

          >
            Homework
          </button>
        </li>
      )}
      <li className="nav-item" role="presentation">
        <button
          className={`nav-link ${ActiveTab == "Assesment" ? 'active' : ''} `}

          id="pills-Assignments-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-Assignments"
          type="button"
          role="tab"
          aria-controls="pills-Assignments"
          aria-selected="false"
          onClick={() => { handleActiveMonth(); localStorage.setItem("NavItem", "Assesment"); setActiveTab("Assesment") }}

        >
          Assesments
        </button>
      </li>
      <li className="nav-item" role="presentation">
        <button
          className={`nav-link ${ActiveTab == "Quiz" ? 'active' : ''} `}

          id="pills-quizzes-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-quizzes"
          type="button"
          role="tab"
          aria-controls="pills-quizzes"
          aria-selected="false"
          onClick={() => { handleActiveMonth(); localStorage.setItem("NavItem", "Quiz"); setActiveTab("Quiz") }}

        >
          Quizzes
        </button>
      </li>
      <li className="nav-item" role="presentation">
        <button
          className={`nav-link ${ActiveTab == "Discussion" ? 'active' : ''} `}

          id="pills-Discussion-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-Discussion"
          type="button"
          role="tab"
          aria-controls="pills-Discussion"
          aria-selected="false"
          onClick={() => { handleActiveMonth(); localStorage.setItem("NavItem", "Discussion"); setActiveTab("Discussion") }}

        >
          Discussion Board
        </button>
      </li>
      {class_type == "Offline Course" ? (

        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${ActiveTab == "Attendance" ? 'active' : ''} `}
            id="pills-Attendance-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-Attendance"
            type="button"
            role="tab"
            aria-controls="pills-Attendance"
            aria-selected="false"
            onClick={() => { setActiveTab("Attendance"); localStorage.setItem("NavItem", "Attendance"); }}

          >
            Attendance
          </button>
        </li>
      ) : (

        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${ActiveTab == "Recording" ? 'active' : ''} `}
            id="pills-Recordings-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-Recordings"
            type="button"
            role="tab"
            aria-controls="pills-Recordings"
            aria-selected="false"
            onClick={() => { setActiveTab("Recording"); localStorage.setItem("NavItem", "Recording"); }}

          >
            Recordings
          </button>
        </li>
      )}


      <li className="nav-item" role="presentation">
        <button
          className={`nav-link ${ActiveTab == "Messages" ? 'active' : ''} `}
          id="pills-Messages-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-Messages"
          type="button"
          role="tab"
          aria-controls="pills-Messages"
          aria-selected="false"
          onClick={() => { setActiveTab("Messages"); localStorage.setItem("NavItem", "Messages"); }}


        >
          Messages
        </button>
      </li>
      <li className="nav-item" role="presentation">
        <button
          className={`nav-link ${ActiveTab == "Review" ? 'active' : ''} `}
          id="pills-review-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-review"
          type="button"
          role="tab"
          aria-controls="pills-review"
          aria-selected="false"
          onClick={() => { setActiveTab("Review"); localStorage.setItem("NavItem", "Review"); }}


        >
          Review
        </button>
      </li>
      <li className="nav-item" role="presentation">
        <button
          className={`nav-link ${ActiveTab == "ProgressReport" ? 'active' : ''} `}
          id="pills-Progress-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-Progress"
          type="button"
          role="tab"
          aria-controls="pills-Progress"
          aria-selected="false"
          onClick={() => { setActiveTab("ProgressReport"); localStorage.setItem("NavItem", "ProgressReport"); }}


        >
          Progress Report
        </button>
      </li>
      <li className="nav-item" role="presentation">
        <button
          className={`nav-link ${ActiveTab == "Feedback" ? 'active' : ''} `}
          id="pills-Feedback-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-Feedback"
          type="button"
          role="tab"
          aria-controls="pills-Feedback"
          aria-selected="false"
          onClick={() => { setActiveTab("Feedback"); localStorage.setItem("NavItem", "Feedback"); handleClick(); }}
        >
          Feedback
        </button>
      </li>
      {class_type == "Offline Course" &&
        <li className="mx-4">

          <button className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block" onClick={() => generateCertificate()}>
            Submit class
          </button>

        </li>
      }
    </ul>
  );
};

export default CommonTabs;
