import React, { useState, useEffect } from "react";
import {useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import * as yup from "yup";
import Modals from "react-modal";
import Model from "../../Models/Model";
import { yupResolver } from "@hookform/resolvers/yup";
import { edit_class_after_school } from "../../../api/apiHandler";
import BackToPrevious from "../../../components/backToPrevious";

var model_css = {
  content: {
    backgroundColor: "transparent",
    background: "none",
    border: "0px",
  },
};


export default function EditClasses({isLoader}) {
  const navigate = useNavigate();
  const [modalIsOpenForgot, setIsOpenForgot] = useState(false);
  const [selectSubject, setSelectSubject] = useState([]);
  const [selectDone, setSelectDone] = useState(false);
  const [imageAvatar, setImageAvatar] = useState();
  const [selectAvtar, setselectAvtar] = useState([]);

  const handleSelectSubject = (subjectName) => {
    console.log(selectSubject);
    if (selectSubject.includes(subjectName)) {
      let filteredArray = selectSubject.filter((item) => item !== subjectName);
      setSelectSubject(filteredArray);
    } else {
      setSelectSubject((prevSelectSubject) => [...new Set([...prevSelectSubject, subjectName])]);
    }
  };

  const location = useLocation();
  var id = location?.state;
  console.log(location.state,'location');
  console.log("id of edit learner_____",id.id)



  const validationSchema = yup.object().shape({
    class_name: yup
    .string()
    .required("Please enter class name")
    .matches(/^[A-Za-z0-9]+$/, "Only alphabet characters and numbers are allowed in the class name field")
    .max(10, "Class name must be at most 10 characters long")
    .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
    .min(2, "Field must contain atleast 2 or 3 characters long"),

    sub_cat: yup.string().nullable().required("Please select subject"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  // const { register, handleSubmit,reset, setValue,clearErrors,formState } = useForm(formOptions);
  const { register, handleSubmit, setValue, clearErrors, formState } = useForm(formOptions);

  const { errors } = formState;

  let onSubmit = async (data) => {
    isLoader(true)

    let submitData = {
      class_id: id.id,
      class_name: data.class_name,
      subject_category: data.sub_cat,
    };

   


    // console.log("submitDataeditprofile", submitData)
    // return
    edit_class_after_school(submitData).then((res) => {
      console.log("res :", res);
      if (res.code == 1) {
        TOAST_SUCCESS(res.message);
        isLoader(false)
        navigate("/edit_profile");
      } else {
        TOAST_ERROR(res.message);
        isLoader(false)
      }
    });
    // reset();
  };



  useEffect(() => {
    id?.subject_category && setSelectSubject(id?.subject_category.split(","));
    setValue("class_name", id?.class_name);
  
    setValue("sub_cat", id?.subject_category);
  }, [id]);
  console.log("id: ", id);


  return (
    <>
      <div>
        <main>
          <div className="login_sec">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 right">
                  <BackToPrevious />
                   <div className="btm_div form-section"> 
                    <form onSubmit={handleSubmit(onSubmit)} className="custom_form mt-4" action id>
                      <>
                       
                        <div className="form-group mb-4">
                          <label className="mb-2">Class Name:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="class_name"
                            id="class_name"
                            defaultValue={id?.class_name}
                            {...register("class_name")}
                          />
                          <p className="mt-2 font-bold col-red">
                            <span style={{ color: "red" }}>{errors.class_name?.message}</span>
                          </p>
                        </div>                 
                       
                        <div className="form-group mb-4">
                          <label className="mb-2">Select Subjects Your Learner Likes </label>
                        
                          <input
                            name="sub_cat"
                            onClick={() => setIsOpenForgot(true)}
                            {...register("sub_cat")}
                            type="text"
                            value={selectDone ? selectSubject.join(" , ") : id?.subject_category || ""}
                            className="form-select"
                          />
                          <p className="mt-2 font-bold col-red">
                            <span style={{ color: "red" }}>{errors.sub_cat?.message}</span>
                          </p>
                        </div>
                      </>

                   
                      <div className="mt-4">
                        {/* <Link to="/kidsDashboard" className="btn-theme bg-yellow text-center d-block fw-600">Sign Up</Link> */}
                        <button type="submit" className="btn-theme bg-yellow text-center fw-600 d-block">
                          Submit
                        </button>
                      </div>
                 
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
     
        {/* Modal */}

        <Modals isOpen={modalIsOpenForgot} style={model_css} contentLabel="Example Modal">
          <Model
            setIsOpenForgot={setIsOpenForgot}
            handleSelectSubject={handleSelectSubject}
            handleSelectAvtar={setImageAvatar}
            setSelectDone={setSelectDone}
            setSelectSubject={setSelectSubject}
            selectSubject={selectSubject}
            selectAvtar={selectAvtar}
          />
        </Modals>
      </div>
    </>
  );
}
