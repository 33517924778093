
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function OpaymetSuccess() {
    const [countdown, setCountdown] = useState(3);
  const navigate = useNavigate();


  useEffect(() => {
    if (countdown > 0) {  
      // Set a timer to update the countdown every second
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      // Cleanup the timer when the component is unmounted or countdown reaches 0
      return () => clearInterval(timer);
    } else {
      // Redirect to the specified page when countdown reaches 0
      navigate('/become-prefer-teacher-form');
    }
  }, [countdown, navigate]);
  return (
    <>
       <div style={styles.container}>
      <h1 style={styles.header}>Subscription Successful!</h1>
      <p style={styles.message}>
        Your subscription has been successfully activated. Thank you for joining us!
      </p>
      <p style={styles.countdown}>
        Redirecting in {countdown}...
      </p>
      {/* <button style={styles.button} onClick={() => navigate('/studentscholar/preferred_teacher')}>
        Go Now
      </button> */}
    </div>
    </>
  )
}

const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f4f4f4',
      fontFamily: 'Arial, sans-serif',
      textAlign: 'center',
      padding: '20px',
    },
    header: {
      fontSize: '2.5rem',
      color: '#333',
      marginBottom: '20px',
    },
    message: {
      fontSize: '1.2rem',
      color: '#555',
      marginBottom: '30px',
    },
    countdown: {
      fontSize: '1.5rem',
      color: '#007BFF',
      marginBottom: '20px',
    },
    button: {
      fontSize: '1rem',
      padding: '10px 20px',
      color: '#fff',
      backgroundColor: '#007BFF',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    },
  };