import { Link } from "react-router-dom";

const CommonRequestClassSection = () => {
  return (
    <div className="find-cls-teacher bg-purple border-radius-10 mb-md-5 mb-4">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7 mb-lg-0 mb-5 left">
            <h2 className="fw-bold text-white mb-4">Need help with finding a class or teacher?</h2>
            <p className="fw-bold text-white mb-3">Teachers that match your requirements will contact you.</p>
            <Link to="/request_class_form" className="btn-theme bg-yellow d-inline-block">
              Request a class
            </Link>
          </div>
          <div className="col-lg-5 right">
            <div className="video_boxs position-relative">
              <video id="video" loop width="100%" controls="hide" poster="./assets/images/video_image.png">
                <source src={'https://studentscholars.s3.amazonaws.com/video/Find a teacher.mp4'} type="video/mp4" />
              </video>
              <div className="height_qul d-md-block d-none position-absolute">
                <img src="./assets/images/height_edu.png" alt="height_edu" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonRequestClassSection;
