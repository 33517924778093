// const CommonNotificationList = ({ isRecordingTab, notificationData}) => {
//   return (
//     <div className={`${isRecordingTab ? "" : "col-xl-3"}`}>
//       <div className="accordion border-radius-10 " id="accordionExample">
//         <div className="accordion-item">
//           <h2 className="accordion-header poppins" id="headingOne">
//             <button
//               className="accordion-button fs-5 text-dif-black bg-transparent"
//               type="button"
//               data-bs-toggle="collapse"
//               data-bs-target="#collapseOne"
//               aria-expanded="true"
//               aria-controls="collapseOne"
//             >
//               New Notifications
//             </button>
//           </h2>
//           <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
//             <div className="accordion-body">
//               <div className="list_notification">
//                 <ul className="position-static">
//                   <li className="d-flex align-items-center mb-4">
//                     <div className="notif_left d-flex align-items-center">
//                       <div className="noti_img bg-pink position-relative">
//                         <span className="text-uppercase text-danger">A</span>
//                         <span className="notifi_logs">
//                           <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
//                             <path
//                               d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
//                               fill="#3F3F44"
//                             />
//                           </svg>
//                         </span>
//                       </div>
//                       <p className="ms-3 noti_text">Assignments Due</p>
//                     </div>
//                     <span className="noti_timer">10.12 PM</span>
//                   </li>                
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };


// export default CommonNotificationList;

import dayjs from "dayjs";
import React from "react";

const CommonNotificationList = ({ isRecordingTab, notificationData }) => {
  console.log(notificationData, 'notificationData');
  return (
    <div className={`${isRecordingTab ? "" : "col-xl-3"}`}>
      <div className="accordion border-radius-10" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header poppins" id="headingOne">
            <button
              className="accordion-button fs-5 text-dif-black bg-transparent"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              New Notifications
            </button>
          </h2>
          <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <div className="list_notification">
                <ul className="position-static">
                  {notificationData && notificationData.length > 0 ? (
                    notificationData.map((dayNotifications, index) => (
                      <React.Fragment key={index}>
               
                        <ul className="position-static">
                          {dayNotifications.notification_data.map((notification, innerIndex) => (
                            <li key={innerIndex} className="d-flex align-items-center mb-4">
                              <div className="notif_left d-flex align-items-center">
                                <div className="noti_img bg-pink position-relative">
                                  <span className="text-uppercase text-danger">A</span>
                                  <span className="notifi_logs">
                                    <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z"
                                        fill="#3F3F44"
                                      />
                                    </svg>
                                  </span>
                                </div>
                                <p className="ms-3 noti_text">{notification?.message}</p>
                              </div>
                              <span className="noti_timer"> {dayjs(notification?.insert_datetime).format('hh:mm A')} </span>
                            </li>
                          ))}
                        </ul>
                      </React.Fragment>
                    ))
                  ) : (
                    <p>No notifications found.</p>
                  )}

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonNotificationList;
