import React from 'react'

export default function New_Headers({ onStepChange }) {

    const handleLinkClick = (newStep) => {
        localStorage.setItem("Leeds_Step", newStep)
          onStepChange(newStep);
      };
      var active = localStorage.getItem("Leeds_Step",1)?parseInt(localStorage.getItem("Leeds_Step")):1
  return (
    <>
      <ul className="nav mb-xl-0 mb-4 custom_nav_bar nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className={`nav-link ${active === 1 ? "active" : ""}`} id="pills-new_leads-tab" data-bs-toggle="pill" data-bs-target="#pills-new_leads" type="button" role="tab" onClick={() => { handleLinkClick(1)}} aria-controls="pills-new_leads" aria-selected="true"> 
                        New Leads
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className={`nav-link ${active === 2 ? "active" : ""}`} id="pills-responded-tab" data-bs-toggle="pill" data-bs-target="#pills-responded" type="button" role="tab" onClick={() => { handleLinkClick(2)}} aria-controls="pills-responded" aria-selected="false"> 
                        Responded Class List
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className={`nav-link ${active === 3 ? "active" : ""}`} id="pills-rejected-tab" data-bs-toggle="pill" data-bs-target="#pills-rejected" type="button" role="tab"  onClick={() => { handleLinkClick(3)}} aria-controls="pills-rejected" aria-selected="false">  
                        Rejected Class List
                      </button>
                    </li>
                  </ul>
    </>
  )
}
