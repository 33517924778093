

import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import * as yup from "yup";
import { loginApi } from '../../api/apiHandler';
import { TOAST_ERROR, TOAST_SUCCESS } from '../../utils/common.service';
import Select from 'react-select'
import countryList from 'react-select-country-list';
import { yupResolver } from "@hookform/resolvers/yup";
import { loginRedirectCallWithDataStore } from '../Common/RedirectPathMange';
import { auth, google, facebook } from '../../utils/Config';
import { signInWithPopup } from 'firebase/auth'
import PhoneInput from "react-phone-input-2";
import BackToPrevious from '../../components/backToPrevious';
import Modals from "react-modal";
import SendOtpModel from '../Models/SendOtpModel';

var COMMON_STYLE_FOR_POPUP = {
  content: {
    // backgroundColor: 'rgb(0 0 0 / 56%)',
    background: "none",
    border: "0px",
  },
};

export default function Login({ isLoader }) {

  const navigate = useNavigate();


  useEffect(() => {


  }, [])

  const [modalIsOpen, setIsOpen] = useState(false);
  const [otp, setOtp] = useState();


  const deviceToken = localStorage.getItem('fcmToken')
  const schema = yup.object().shape({

    email: yup.string().required("Please enter email").matches(/^[a-zA-Z0-9]+([._]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/, "Plaese enter valid email"),
    password: yup.string()
      .required("Please enter password")

  });

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const schemaMobile = yup.object().shape({

    phone: yup.string()
      .required("Please enter phone number")
      .matches(/^[0-9]+$/, "Only numbers are allowed")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .min(2, "Field must contain atleast 2 or 3 characters long"),

    password_phone: yup.string()
      .required("please enter password")

  });

  const editTempFormOption = { resolver: yupResolver(schemaMobile) };
  const { register: register1, handleSubmit: handleSubmit1, setValue: setValue1, reset: reset1, formState: { errors: errors1 } } = useForm(editTempFormOption);

  const [resData, setResData] = useState([]);

  const [loginType, setLoginType] = useState("S");
  const [socialId, setSocialId] = useState("");
  const [countryCode, setCountryCode] = useState();


  const handleSocialSignin = async (provider, login_type) => {
    const result = await signInWithPopup(auth, provider)
    console.log('result :', result);

    if (result.user) {
      setValue("email", result?.user?.providerData[0]?.email)
      setValue("password", "Ssss@8495")
      setSocialId(result?.user?.providerData[0]?.uid)
      setLoginType(login_type)
    }

  }

  const handleSocialSignin1 = async (provider, login_type) => {
    const result = await signInWithPopup(auth, provider)
    console.log('result :', result);

    if (result.user) {
      console.log("dasdas")
      setValue1("password_phone", "Ssss@8495")
      setSocialId(result?.user?.providerData[0]?.uid)
      setLoginType(login_type)
    }
  }

  const handlePhoneChange = (phone, country) => {
    // setPhoneDetails({ phone, country });
    if (country && country.dialCode) {
      const formattedValue = phone.substring(country.dialCode.length);
      setCountryCode(country.dialCode);
      setValue1("phone", formattedValue);
    }

  };
  const onSubmit = data => {
    localStorage.clear()
    let submitData = {
      "email": data.email,
      "login_type": loginType,
      // "device_token":deviceToken
    }
    if (loginType == "S") {
      submitData.password = data.password
    } else {
      submitData.social_id = socialId
    }

    if (deviceToken) {
      submitData.device_token = deviceToken
    }
    console.log('submitData :', submitData);
    isLoader(true)
    loginApi(submitData).then((response) => {
      console.log('responseLogin :', response);
      if (
        response?.code == 1 &&
        (response?.data?.category == "above_18" ||
          response?.data?.category == "below_18" ||
          response?.data?.category == "after_school" ||
          response?.data?.category == "learner")
      ) {
        TOAST_SUCCESS(response?.message);
        loginRedirectCallWithDataStore(response?.data);
        if (response?.data?.category == "learner") {
          localStorage.removeItem("isparent")
        }
        navigate("/dashboard");
        isLoader(false)
      }
      else if (response?.code == 1 && response?.data?.category == "teacher") {
        localStorage.setItem("type", response?.data?.category);
        console.log('response111111 :', response);

        if (response?.data.step_1 == "2" && response?.data.step_2 == "2" && response?.data.step_3 == "2") {
          TOAST_SUCCESS(response?.message);
          isLoader(false)
          loginRedirectCallWithDataStore(response?.data)

        } else {
          isLoader(false)
          localStorage.setItem("Tid", response?.data.id);
          navigate("/signup-teacher")
        }
        // navigate("/teacher_dashboard");
      }
      else if (response?.code == 1 && response?.data?.category == "org_teacher") {
        localStorage.setItem("type", response?.data?.category);
        setResData(response?.data)
        console.log('response1234 :', response);

        if (response?.data.step_2 == "2" && response?.data.step_3 == "2" && response?.data.step_4 == "2") {
          isLoader(false)
          TOAST_SUCCESS(response?.message);
          loginRedirectCallWithDataStore(response?.data)

        } else {
          isLoader(false)
          localStorage.setItem("Tid", response?.data.id);
          navigate("/signup-teacher")
        }
      }
      else if (response?.code == 1 && response?.data?.category == "organization") {
        localStorage.setItem("type", response?.data?.category);

        if (response?.data.step_1 == "2" && response?.data.step_2 == "2" && response?.data.step_3 == "2" && response?.data.step_5 == "2") {
          TOAST_SUCCESS(response?.message);
          isLoader(false)
          loginRedirectCallWithDataStore(response?.data)

        } else {
          isLoader(false)
          localStorage.setItem("Tid", response?.data.id);
          navigate("/organization-step")
        }
      }
      else if (response?.code == 4) {
        console.log('response :', response);
        if (response?.data?.user_type == 'teacher' || response?.data?.user_type == 'org_teacher' || response?.data?.user_type == 'organization') {
          localStorage.setItem("Tid", response?.data?.user_id);
          localStorage.setItem("type", response?.data?.user_type);
        } else {
          localStorage.setItem("PAid", response?.data?.user_id);
          localStorage.setItem("userType", response?.data?.user_type);
        }
        localStorage.setItem("Otp", response?.data?.otp);
        setIsOpen(true)
        TOAST_ERROR(response?.message);
        setOtp(response?.data?.phone)
        isLoader(false)
      }
      else {
        TOAST_ERROR(response?.message);
        isLoader(false)

      }
    });

  }

  const onSubmitMobile = (data) => {
    localStorage.clear()

    let submitData = {
      "login_type": loginType
    }
    if (loginType == "S") {
      submitData.password = data.password_phone
      submitData.phone = data.phone
      submitData.country_code = "+" + countryCode
    } else {
      submitData.social_id = socialId
      submitData.phone = data.phone
      submitData.country_code = "+" + countryCode
    }
    console.log('submitData :', submitData);
    isLoader(true)

    loginApi(submitData).then((response) => {
      console.log('loginApi :', response);

      if (
        response?.code == 1 &&
        (response?.data?.category == "above_18" ||
          response?.data?.category == "below_18" ||
          response?.data?.category == "after_school" ||
          response?.data?.category == "learner")
      ) {
        isLoader(false)
        TOAST_SUCCESS(response?.message);
        loginRedirectCallWithDataStore(response?.data);
        if (response?.data?.category == "learner") {
          localStorage.removeItem("isparent")
        }
        navigate("/dashboard");

      }
      else if (response?.code == 1 && response?.data?.category == "teacher") {
        localStorage.setItem("type", response?.data?.category);

        isLoader(false)
        TOAST_SUCCESS(response?.message);
        // navigate("/teacher_dashboard");

        loginRedirectCallWithDataStore(response?.data)
        // alert(response.message);
        // loginRedirectCallWithDataStore(response.data.data)

      }
      else if (response?.code == 1 && response?.data?.category == "org_teacher") {
        localStorage.setItem("type", response?.data?.category);
        setResData(response?.data)
        console.log('response1234 :', response);

        if (response?.data.step_2 == "2" && response?.data.step_3 == "2" && response?.data.step_4 == "2") {
          isLoader(false)
          TOAST_SUCCESS(response?.message);
          loginRedirectCallWithDataStore(response?.data)

        } else {
          isLoader(false)
          localStorage.setItem("Tid", response?.data.id);
          navigate("/signup-teacher")
        }

      }
      else if (response?.code == 1 && response?.data.category == "organization") {
        localStorage.setItem("type", response?.data?.category);

        isLoader(false)
        TOAST_SUCCESS(response?.message);

        loginRedirectCallWithDataStore(response?.data)
        // navigate("/organization_dashboard");

        // alert(response.message);
        // loginRedirectCallWithDataStore(response.data.data)

      }
      else if (response?.code == 4) {
        console.log('response :', response);
        if (response?.data?.user_type == 'teacher' || response?.data?.user_type == 'org_teacher' || response?.data?.user_type == 'organization') {
          localStorage.setItem("Tid", response?.data?.user_id);
          localStorage.setItem("type", response?.data?.user_type);
        } else {
          localStorage.setItem("PAid", response?.data?.user_id);
          localStorage.setItem("userType", response?.data?.user_type);
        }
        localStorage.setItem("Otp", response?.data?.otp);
        setIsOpen(true)
        TOAST_ERROR(response?.message);
        setOtp(response?.data?.phone)
        isLoader(false)
      }
      else {
        isLoader(false)
        TOAST_ERROR(response?.message);

      }
    });

  }
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };


  return (
    <>
      <Helmet>
        <script>{`
                        $("document").ready(function() {
                            $(".tab-slider--body").hide();
                            $(".tab-slider--body:first").show();
                        });
                        $(".tab-slider--nav li").click(function() {
                            $(".tab-slider--body").hide();
                            var activeTab = $(this).attr("rel");
                            $("#" + activeTab).fadeIn();
                            if ($(this).attr("rel") == "tab2") {
                                $('.tab-slider--tabs').addClass('slide');
                            } else {
                                $('.tab-slider--tabs').removeClass('slide');
                            }
                            $(".tab-slider--nav li").removeClass("active");
                            $(this).addClass("active");
                        });

        `}</script>
        {/* <script>{`

	$("#mobile_code").intlTelInput({
	    initialCountry: "in",
	    separateDialCode: true,
	    // utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.4/js/utils.js"
	});
    `}

                </script> */}
      </Helmet>
      <div>
        <main>
          <div className="login_sec">
            <div className="container-fluid">
              <div className="row">
                <div className="align-items-center col-lg-6 d-flex justify-content-center left1" style={{ backgroundImage: 'url(./assets/images/auth/login_in.png)' }}>
                  <h1 className="text-white poppins fw_500 position-relative">Be Inspired. Grow Your Skills. Knowledge. Talents.</h1>
                </div>
                <div className="col-lg-6 right">
                  <div className="top">
                    <div className="d-md-flex justify-content-between">
                      <BackToPrevious />
                      <div className>
                        <p className="fs-6 text-gray">Don’t Have an Account? <Link to="/sign_in_step1" className="text-purple">Join Now</Link></p>
                      </div>
                    </div>
                  </div>
                  <div className="btm_div form-section">
                    <h1 className="mb-5 poppins text-capitalize fw-600 text-purple">Sign in</h1>
                    <div className="tab-slider--nav">
                      <ul className="tab-slider--tabs">
                        <li className="tab-slider--trigger active" rel="tab1">Email</li>
                        <li className="tab-slider--trigger" rel="tab2">Mobile</li>
                      </ul>
                    </div>
                    <div className="tab-slider--container">
                      {/*tab1*/}
                      <div id="tab1" className="tab-slider--body">
                        <form onSubmit={handleSubmit(onSubmit)} className="custom_form mt-4" action id>
                          <div className="form-group mb-4">
                            <label className="mb-2">Email ID</label>
                            <input type="text" {...register("email")} name='email' placeholder className="form-control" />
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.email?.message}</span></p>

                          </div>
                          {loginType == "S" ?

                            <div className="form-group mb-4">
                              <div className="d-flex justify-content-between position-relative">
                                <label className="mb-2">Your Password</label>
                                <Link onClick={handleTogglePassword}
                                  style={{ position: "absolute", right: "20px", zIndex: "9999", bottom: "-36px" }}
                                >
                                  <span>
                                    {showPassword ? (
                                      <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#666666">
                                        <path d="M0 0h24v24H0z" fill="none" />
                                        <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                                      </svg>
                                    ) : (
                                      <svg width={19} height={17} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M17.0194 0.881336L16.2835 0.145393C16.0755 -0.0625975 15.6915 -0.0305884 15.4515 0.257357L12.8913 2.80132C11.7392 2.30538 10.4754 2.06538 9.14731 2.06538C5.19519 2.08132 1.77141 4.38529 0.12329 7.69749C0.0272626 7.90549 0.0272626 8.16141 0.12329 8.3374C0.891223 9.90543 2.04329 11.2015 3.4833 12.1774L1.3873 14.3054C1.1473 14.5454 1.11529 14.9293 1.27534 15.1374L2.01128 15.8733C2.21928 16.0813 2.60326 16.0493 2.84326 15.7613L16.8912 1.71345C17.1952 1.47358 17.2272 1.08962 17.0192 0.881611L17.0194 0.881336ZM9.9953 5.71322C9.72329 5.6492 9.43534 5.56925 9.16332 5.56925C7.80327 5.56925 6.71538 6.65727 6.71538 8.01718C6.71538 8.28919 6.7794 8.57714 6.85936 8.84916L5.78724 9.90519C5.46728 9.34524 5.2913 8.72114 5.2913 8.01722C5.2913 5.88924 7.00332 4.17722 9.1313 4.17722C9.83536 4.17722 10.4593 4.3532 11.0193 4.67316L9.9953 5.71322Z"
                                          fill="#666666"
                                          fillOpacity="0.8"
                                        />
                                        <path
                                          d="M18.1714 7.69743C17.6115 6.57738 16.8754 5.56945 15.9635 4.75342L12.9875 7.69743V8.01738C12.9875 10.1454 11.2754 11.8574 9.14745 11.8574H8.8275L6.93951 13.7454C7.64357 13.8893 8.37952 13.9854 9.09952 13.9854C13.0516 13.9854 16.4754 11.6814 18.1235 8.35325C18.2675 8.12918 18.2675 7.90527 18.1715 7.69726L18.1714 7.69743Z"
                                          fill="#666666"
                                          fillOpacity="0.8"
                                        />
                                      </svg>
                                    )}
                                  </span>
                                </Link>
                              </div>
                              <input name="password"
                                {...register("password")}
                                type={showPassword ? 'text' : 'password'}
                                placeholder=""
                                className="form-control" />
                              <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.password?.message}</span></p>
                            </div>
                            : <></>}
                          <div className="text-end mt-2">
                            <Link to="/forgot-password" className="text-decoration-underline text-purple fs-6">Forgot your password?</Link>
                          </div>
                          <div className="mt-4">
                            <button type='submit' className="col-md-12 btn-theme bg-yellow text-center fw-600 d-block" >
                              {/* <Link to="/sign_in_step3">Next</Link> */}Sign in
                            </button>
                            {/* <Link to="/kidsDashboard" className="btn-theme bg-yellow text-center d-block fw-600">Sign in</Link> */}
                          </div>
                        </form>
                        <div className="position-relative or_sec">
                          <div className="position-absolute">OR</div>
                        </div>
                        <div className>
                          <a onClick={(e) => handleSocialSignin(facebook, "F")} className="btn-theme btn-prple-border d-block text-center my-4">
                            <span>
                              <svg width={33} height={33} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="16.5" cy="16.5" r={14} fill="#0C82EE" />
                                <path d="M21.7137 20.7816L22.3356 16.8301H18.4452V14.267C18.4452 13.1857 18.9877 12.1311 20.7302 12.1311H22.5V8.76699C22.5 8.76699 20.8945 8.5 19.3603 8.5C16.1548 8.5 14.0617 10.3929 14.0617 13.8184V16.8301H10.5V20.7816H14.0617V30.3345C14.7767 30.444 15.5082 30.5 16.2534 30.5C16.9986 30.5 17.7302 30.444 18.4452 30.3345V20.7816H21.7137Z" fill="white" />
                              </svg>
                            </span>
                            <span className='ms-2'>Continue with Facebook</span>
                          </a>
                          <a onClick={(e) => handleSocialSignin(google, "G")} className="btn-theme btn-prple-border d-block text-center ">
                            <span>
                              <svg width={24} height={25} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.501 12.7331C22.501 11.8698 22.4296 11.2398 22.2748 10.5864H12.2153V14.483H18.12C18.001 15.4514 17.3582 16.9097 15.9296 17.8897L15.9096 18.0202L19.0902 20.4349L19.3106 20.4564C21.3343 18.6247 22.501 15.9297 22.501 12.7331Z" fill="#4285F4" />
                                <path d="M12.214 23.0001C15.1068 23.0001 17.5353 22.0667 19.3092 20.4567L15.9282 17.89C15.0235 18.5083 13.8092 18.94 12.214 18.94C9.38069 18.94 6.97596 17.1083 6.11874 14.5767L5.99309 14.5871L2.68583 17.0955L2.64258 17.2133C4.40446 20.6433 8.0235 23.0001 12.214 23.0001Z" fill="#34A853" />
                                <path d="M6.12046 14.5767C5.89428 13.9234 5.76337 13.2233 5.76337 12.5C5.76337 11.7767 5.89428 11.0767 6.10856 10.4234L6.10257 10.2842L2.75386 7.7356L2.64429 7.78667C1.91814 9.21002 1.50146 10.8084 1.50146 12.5C1.50146 14.1917 1.91814 15.79 2.64429 17.2133L6.12046 14.5767Z" fill="#FBBC05" />
                                <path d="M12.2141 6.05997C14.2259 6.05997 15.583 6.91163 16.3569 7.62335L19.3807 4.73C17.5236 3.03834 15.1069 2 12.2141 2C8.02353 2 4.40447 4.35665 2.64258 7.78662L6.10686 10.4233C6.97598 7.89166 9.38073 6.05997 12.2141 6.05997Z" fill="#EB4335" />
                              </svg>
                            </span>
                            <span className='ms-2'>Continue with Google</span>
                          </a>
                        </div>
                      </div>
                      {/*tab2*/}
                      <div id="tab2" className="tab-slider--body">
                        <form onSubmit={handleSubmit1(onSubmitMobile)} className="custom_form mt-4" action id>
                          <div className="form-group mb-4">
                            <label className="mb-2 d-block">Phone Number</label>
                            <PhoneInput
                              placeholder="  Enter phone number"
                              // className="form-control w-100"
                              name="phone"
                              {...register1("phone")}
                              id="mobile_code"
                              onChange={(value, country) => handlePhoneChange(value, country)}
                            />
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors1.phone?.message}</span></p>
                          </div>
                          {/* <div className="form-group mb-4">
                            <label className="mb-2 d-block">Phone Number</label>
                            <input type="text" id="mobile_code" {...register1("phone")} className="form-control w-100" name="phone" />
                           
                          </div> */}
                          {/* <div className="form-group mb-4">
                          <label className="mb-2 d-block">Phone Number</label>
                          <PhoneInput placeholder="Enter phone number" name="phone" {...register("phone")}
                            id="phone" onChange={(value, country) => handleOnChange(value, country)} />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.phone?.message}</span></p>

                        </div> */}
                          {loginType == "S" ?
                            <div className="form-group mb-4">
                              <div className="d-flex justify-content-between">
                                <label className="mb-2">Your Password</label>
                                <a href="#" onClick={handleTogglePassword}>
                                  <span>
                                    {showPassword ? (
                                      <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#666666"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" /></svg>
                                    ) : (
                                      // <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">

                                      // </svg>

                                      <svg width={19} height={17} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.0194 0.881336L16.2835 0.145393C16.0755 -0.0625975 15.6915 -0.0305884 15.4515 0.257357L12.8913 2.80132C11.7392 2.30538 10.4754 2.06538 9.14731 2.06538C5.19519 2.08132 1.77141 4.38529 0.12329 7.69749C0.0272626 7.90549 0.0272626 8.16141 0.12329 8.3374C0.891223 9.90543 2.04329 11.2015 3.4833 12.1774L1.3873 14.3054C1.1473 14.5454 1.11529 14.9293 1.27534 15.1374L2.01128 15.8733C2.21928 16.0813 2.60326 16.0493 2.84326 15.7613L16.8912 1.71345C17.1952 1.47358 17.2272 1.08962 17.0192 0.881611L17.0194 0.881336ZM9.9953 5.71322C9.72329 5.6492 9.43534 5.56925 9.16332 5.56925C7.80327 5.56925 6.71538 6.65727 6.71538 8.01718C6.71538 8.28919 6.7794 8.57714 6.85936 8.84916L5.78724 9.90519C5.46728 9.34524 5.2913 8.72114 5.2913 8.01722C5.2913 5.88924 7.00332 4.17722 9.1313 4.17722C9.83536 4.17722 10.4593 4.3532 11.0193 4.67316L9.9953 5.71322Z" fill="#666666" fillOpacity="0.8" />
                                        <path d="M18.1714 7.69743C17.6115 6.57738 16.8754 5.56945 15.9635 4.75342L12.9875 7.69743V8.01738C12.9875 10.1454 11.2754 11.8574 9.14745 11.8574H8.8275L6.93951 13.7454C7.64357 13.8893 8.37952 13.9854 9.09952 13.9854C13.0516 13.9854 16.4754 11.6814 18.1235 8.35325C18.2675 8.12918 18.2675 7.90527 18.1715 7.69726L18.1714 7.69743Z" fill="#666666" fillOpacity="0.8" />
                                      </svg>
                                    )}
                                  </span>

                                </a>
                              </div>
                              <input name="password_phone"
                                {...register1("password_phone")}
                                type={showPassword ? 'text' : 'password'}
                                placeholder=""
                                className="form-control" />
                              <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors1.password_phone?.message}</span></p>
                            </div>
                            : <></>}
                          <div className="text-end mt-2">
                            <Link to="/forgot-password" className="text-decoration-underline text-purple fs-6">Forget your password?</Link>
                          </div>

                          <div className="mt-4">

                            <button type='submit' className="col-md-12 btn-theme bg-yellow text-center fw-600 d-block" >
                              Sign in
                            </button>
                          </div>
                        </form>
                        <div className="position-relative or_sec">
                          <div className="position-absolute">OR</div>
                        </div>

                        <div>
                          <a onClick={(e) => handleSocialSignin1(facebook, "F")} className="btn-theme btn-prple-border d-block text-center my-4">
                            <span>
                              <svg width={33} height={33} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="16.5" cy="16.5" r={14} fill="#0C82EE" />
                                <path d="M21.7137 20.7816L22.3356 16.8301H18.4452V14.267C18.4452 13.1857 18.9877 12.1311 20.7302 12.1311H22.5V8.76699C22.5 8.76699 20.8945 8.5 19.3603 8.5C16.1548 8.5 14.0617 10.3929 14.0617 13.8184V16.8301H10.5V20.7816H14.0617V30.3345C14.7767 30.444 15.5082 30.5 16.2534 30.5C16.9986 30.5 17.7302 30.444 18.4452 30.3345V20.7816H21.7137Z" fill="white" />
                              </svg>
                            </span>
                            <span className='ms-2'>Continue with Facebook </span>
                          </a>
                          <a onClick={(e) => handleSocialSignin1(google, "G")} className="btn-theme btn-prple-border d-block text-center ">
                            <span>
                              <svg width={24} height={25} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.501 12.7331C22.501 11.8698 22.4296 11.2398 22.2748 10.5864H12.2153V14.483H18.12C18.001 15.4514 17.3582 16.9097 15.9296 17.8897L15.9096 18.0202L19.0902 20.4349L19.3106 20.4564C21.3343 18.6247 22.501 15.9297 22.501 12.7331Z" fill="#4285F4" />
                                <path d="M12.214 23.0001C15.1068 23.0001 17.5353 22.0667 19.3092 20.4567L15.9282 17.89C15.0235 18.5083 13.8092 18.94 12.214 18.94C9.38069 18.94 6.97596 17.1083 6.11874 14.5767L5.99309 14.5871L2.68583 17.0955L2.64258 17.2133C4.40446 20.6433 8.0235 23.0001 12.214 23.0001Z" fill="#34A853" />
                                <path d="M6.12046 14.5767C5.89428 13.9234 5.76337 13.2233 5.76337 12.5C5.76337 11.7767 5.89428 11.0767 6.10856 10.4234L6.10257 10.2842L2.75386 7.7356L2.64429 7.78667C1.91814 9.21002 1.50146 10.8084 1.50146 12.5C1.50146 14.1917 1.91814 15.79 2.64429 17.2133L6.12046 14.5767Z" fill="#FBBC05" />
                                <path d="M12.2141 6.05997C14.2259 6.05997 15.583 6.91163 16.3569 7.62335L19.3807 4.73C17.5236 3.03834 15.1069 2 12.2141 2C8.02353 2 4.40447 4.35665 2.64258 7.78662L6.10686 10.4233C6.97598 7.89166 9.38073 6.05997 12.2141 6.05997Z" fill="#EB4335" />
                              </svg>
                            </span>
                            <span className='ms-2'>Continue with Google</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        {/*start-modal*/}
        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header pb-0 border-bottom-0">
                <h5 className="modal-title" id="staticBackdropLabel" />
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <div className="text-center mb-4">
                  <h4 className="modal-title" id="staticBackdropLabel">Forgot Password</h4>
                  <p className="fs-6 text-gray">Enter your phone number to reset your password.</p>
                </div>
                <form className="custom_form mt-4" action id>
                  <div className="form-group mb-4">
                    <label className="mb-2 d-block">Mobile</label>
                    <input type="text" id="mobile_code" className="form-control w-100" placeholder="Phone Number" name="name" />
                  </div>
                </form>
              </div>
              <div className="modal-footer border-top-0 justify-content-center pt-0">
                <a href="#" className="btn-theme bg-yellow" data-bs-dismiss="modal">Get OTP</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modals isOpen={modalIsOpen} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal">
        <SendOtpModel message={`We have sent you an verification code to ${otp}`} type={`Signup2`} setIsOpen={setIsOpen} isLoader={isLoader} />
      </Modals>

    </>
  )
}