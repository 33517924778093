import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { classroom_lesson_list, teacher_homework_view_details } from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import { formatDate } from "../../../utils/common.service";
import TFooter_Dashboard from "../Include/TFooter_Dashboard";
import THeader_Dashboard from "../Include/THeader_Dashboard";

export default function ViewDetailsCommon() {
    const [pageContent, setPageContent] = useState([]);
    console.log('pageContent :', pageContent);
    const location = useLocation();
    var id = location?.state;
    console.log('id222 :', id);
    //   const { id } = useParams();
    const navigate = useNavigate();
    const type = id?.type;

    useEffect(() => {
        id && getPageContent();
    }, [id, type]);

    const getPageContent = async () => {
        try {
            let res;
            switch (type) {
                case "class":
                    res = await classroom_lesson_list({ classroom_id: id?.id });
                    console.log('resssss :', res);
                    break;
                // case "homework":
                //   res = await teacher_homework_view_details({ homework_id: id.id });
                //   break;
                // case "assessment":
                //   res = await API.assessment_view_details({ assesment_id: id });
                //   break;
                default:
            }
            console.log('res123 :', res);

            if (+res.code === 1) setPageContent(res.data?.[0]);
        } catch (err) {
            console.log(err.message);
        }
    };

    const downloadDocx = (url) => {
        let fileName = url.split("/");
        fileName = fileName[fileName.length - 1];

        const anchor = document.createElement("a");
        anchor.style.display = "none";
        anchor.href = url;
        anchor.target = "_blank";
        anchor.download = fileName;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    };

    const displayContent = pageContent?.lessiondata?.map((item, index) => {
        return (
            <div key={item.des_id}>
                <div className="row mb-5 middle_contn">
                    {item.attachments.map((file, i) => {
                        console.log('file :', file);
                        return (
                            <div key={i} className="col-xl-6 col-lg-6 col-md-6 mb-md-0 mb-4">
                                <div className="certi_box bg-transparent shadow-none">
                                    <div className="certi_images position-relative text-center bg-transparent p-0 border-bottom-0">
                                        <div className="" >
                                            {file?.attachment_type === "image" ? (
                                                <img src={file?.attachment || file?.attachments} alt="blog1" className="img-fluid border-radius-10" style={{
                                                    height: '241px',
                                                    width: '523px',
                                                    position: 'relative',
                                                    marginRight: '224px'
                                                }} />
                                            ) : file.attachment_type === "video" ? (
                                                <video id="video" loop style={{
                                                    height: '241px',
                                                    width: '523px',
                                                    position: 'relative',
                                                    marginRight: '224px'
                                                }} controls="hide" poster="">
                                                    <source src={file?.attachment || file?.attachments} type="video/mp4" />
                                                </video>

                                            ) : file.attachment_type == "docx" ? (<>
                                                <div >
                                                    <button
                                                        className="btn-theme py-2 ms-2 d-block text-dif-black text-center"
                                                        onClick={() => downloadDocx(file?.attachment || file?.attachments)}
                                                    >
                                                        Download {file?.attachment_type}
                                                    </button>
                                                </div>
                                            </>
                                            ) : file.attachment_type == "pdf" ? (<>
                                                <div >
                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={24} height={22} viewBox="0 0 48 48">
                                                        <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path><path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path><path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
                                                    </svg> */}

                                                    {/* <a
                                                        href={file?.attachment || file?.attchments}
                                                        download={file?.attachment || file?.attchments}
                                                        target="_blank"
                                                        className="border-radius-10"
        
                                                    >
                                                        Pdf Download</a> */}
                                                    <button
                                                        className="btn-theme py-2 ms-2 d-block text-dif-black text-center"
                                                        onClick={() => downloadDocx(file?.attachment || file?.attachments)}
                                                    >
                                                        Download {file?.attachment_type}
                                                    </button>
                                                </div>
                                            </>
                                            ) : file.attachment_type == "xlsx" ? (<>
                                                <div >
                                       
                                                    <button
                                                        className="btn-theme py-2 ms-2 d-block text-dif-black text-center"
                                                        onClick={() => downloadDocx(file?.attachment || file?.attachments)}
                                                    >
                                                        Download {file?.attachment_type}
                                                    </button>
                                                </div>
                                            </>
                                            ) :  null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                {/* <p className="mb-4 fs-6">{item.description}</p> */}
                <div dangerouslySetInnerHTML={{ __html: item?.description }} className="mb-3 mt-2" />

                {/* <div className="">
                    <ul>


                        <li className="mb-2">
                            <span className="fw-500 font-14" style={{ color: '#570861' }}>Description : <span className="fw-500 text-dif-black font-14">&nbsp;{item.description}</span></span>
                        </li>
                    </ul>
                </div> */}
            </div>
        );
    });

    return (
        <>
            <JqueryLoad />
            <THeader_Dashboard />
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="#" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <div className="class-content">
                        <div>
                            <Link to="#" onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block">
                                <span>
                                    <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                                    </svg>
                                    <span className="ms-2">Back</span>
                                </span>
                            </Link>
                            <div className="blogs_detail">
                                <div className="dwnload_btm d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center mb-1">
                                        <div className="dwnl_img ">
                                            <img src={pageContent?.teacher_profile_image} width={50} style={{ height: "50px" }} alt="center_div_img2" />
                                        </div>
                                        <div className="ms-3">
                                            <span className="font-14 fw-bold">{pageContent?.teacher_name}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <span>{formatDate(pageContent?.created_at, "MMM D, YYYY")}</span> • <span>{pageContent?.duration}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="blogs_detail mb-4">
                                <div className="certi_box bg-transparent shadow-none">
                                    <div className="certi_ctn border-top border-dark px-0">
                                        <div className="dwnload_btm d-flex justify-content-between align-items-center">
                                            <h3 className="poppins">{pageContent?.lession_topic || pageContent?.topic} </h3>

                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div>

                                <div className="">
                                    <ul>

                                        {/* <li className="mb-2">
                                            <span className="fw-500 font-14" style={{ color: '#570861' }}>Topic Name : <span className="fw-500 text-dif-black font-14">&nbsp;{pageContent?.lession_topic}</span></span>
                                        </li> */}
                                        {/* <li className="mb-2">
                                            <span className="fw-500 font-14" style={{ color: '#570861' }}>{pageContent?.duration}</span>
                                        </li> */}
                                    </ul>
                                </div>

                                {displayContent}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <TFooter_Dashboard />
        </>
    );
}
