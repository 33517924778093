import React, { useEffect, useState } from "react";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import PFooter_Dashboard from "../Pinc/PFooter_Dashboard";
import PHeader_Dashboard from "../Pinc/PHeader_Dashboard";
import BackToPrevious from "../../../components/backToPrevious";
import { Helmet } from "react-helmet";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { credits_listing, get_user_credits_earnings, teacher_subscribed_and_payments_made } from "../../../api/apiHandler";
import Select from "react-select";
import { encryptURL } from "../../../api/apiClient";
import { useNavigate } from "react-router-dom";


export default function PEwallet({ isLoader }) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate();

  const [data, setData] = useState();
  const [amount, setAmount] = useState();
  const [selectedFilterOption, setSelectedFilterOption] = useState({
    value: "succeeded",
    label: "Payments Made"
  });

  const PaymentMade = async (value) => {
    console.log('value :', value);

    isLoader(true)

    try {
      var response;

      if (value !== 'credited') {
        response = await teacher_subscribed_and_payments_made({ value: value == undefined ? 'succeeded' : value });

      } else {
        response = await credits_listing();
      }
      console.log('response123 :', response);
      if (response?.code == 1) {
        const dataWithRowCount = response?.data.map((row, index) => ({ ...row, count: index + 1 }));

        setData(dataWithRowCount)
        isLoader(false)

      } else {
        setData([])
        isLoader(false)
      }
    } catch (error) {
      console.log(error)
      isLoader(false)

    }
  }

  const creditAmount = () => {
    get_user_credits_earnings().then((response) => {
    console.log('response :', response);
      if (response?.code == 1) {
        setAmount(response?.data)
      } else {
        setAmount([])
      }
    })
  }
  const handleFilterChange = (selectedOption) => {
    setSelectedFilterOption(selectedOption);
    PaymentMade(selectedOption.value)
  };

  useEffect(() => {
    PaymentMade();
    creditAmount();
  }, [])


  const columns = [
    {
      name: 'Sr.No',
      selector: 'count',

      sortable: true,

    },
    {
      name: 'Payment Date',
      selector: row => {
        const date = new Date(row.enrolled_at);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;
        const year = date.getFullYear();
        return `${formattedDay}-${formattedMonth}-${year}`;
      },
      sortable: true,
      // width: "150px"
    },
    {
      name: 'Transaction Id',
      selector: row => row.transaction_id,
      sortable: true,
      cellExport: row => row.transaction_id,
      width: "250px"
    },
    {
      name: 'Class Name',
      selector: row => row.activity_title,
      sortable: true,
      cellExport: row => row.activity_title,
      width: "200px"

    },
    {
      name: 'Teacher Name',
      selector: row => row.teacher_name,
      sortable: true,
      cellExport: row => row.teacher_name,
      width: "160px"

    },
    {
      name: 'Platform',
      selector: row => row.platform_type,
      sortable: true,
      cellExport: row => row.platform_type,
    },
    {
      name: 'Class Duration',
      selector: row => row.duration,
      sortable: true,
      cellExport: row => row.duration,
      width: "160px"

    },

    {
      name: 'Coupon Number',
      selector: row => row.coupan_number ? row.coupan_number : '-',
      sortable: true,
      cellExport: row => row.coupan_number ? row.coupan_number : '-',
      width: "170px"

    },

    {
      name: 'Payment Status',
      selector: row => (
        <button className="bg-success border-0 text-light px-2 fw-500" style={{ borderRadius: "5px" }}>
          {row.payment_status}
        </button>
      ),
      sortable: true,
      cellExport: row => row.payment_status,
      width: "170px"

    },
    {
      name: 'Amount',
      selector: row => `${row.currency_symbol} ${row.amount}`,
      sortable: true,
      cellExport: row => row.amount,
    },






  ];

  const refundColumns = [
    {
      name: 'Sr.No',
      selector: 'count',
      sortable: true,

    },
    {
      name: 'Refund Date',
      selector: row => {
        const date = new Date(row.enrolled_at);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;
        const year = date.getFullYear();
        return `${formattedDay}-${formattedMonth}-${year}`;
      },
      sortable: true,
      // width: "150px"
    },
    {
      name: 'Transaction Id',
      selector: row => row.transaction_id,
      sortable: true,
      cellExport: row => row.transaction_id,
    },
    {
      name: 'Class Name',
      selector: row => row.activity_title,
      sortable: true,
      cellExport: row => row.activity_title,
    },
    {
      name: 'Teacher Name',
      selector: row => row.teacher_name,
      sortable: true,
      cellExport: row => row.teacher_name,
    },
    {
      name: 'Platform',
      selector: row => row.platform_type,
      sortable: true,
      cellExport: row => row.platform_type,
    },
    {
      name: 'Class Duration',
      selector: row => row.duration,
      sortable: true,
      cellExport: row => row.duration,
    },



    {
      name: 'Refund Status',
      selector: row => (
        <button className="bg-success border-0 text-light px-2 fw-500" style={{ borderRadius: "5px" }}>
          {row.payment_status}
        </button>
      ),
      sortable: true,
      cellExport: row => row.payment_status
    },
    {
      name: 'Amount',
      selector: row => `${row.currency_symbol} ${row.amount}`,
      sortable: true,
      cellExport: row => row.amount,
    },






  ];


  const creditcolums = [
    {
      name: 'Id',
      selector: (row, index) => index + 1,
      cellExport: (row, index) => index + 1,
      sortable: true,
      width: '75px'
    },
    // {
    //   name: 'Payment date',
    //   selector: row => dayjs(row.enrolled_at).format("DD-MM-YYYY"),
    //   sortable: true,
    //   cellExport: row => row.enrolled_at
    // },
    {
      name: 'Referer',
      selector: row => row.referer,
      sortable: true,
      cellExport: row => row.referer,
    },

    {
      name: 'Referee',
      selector: row => row.referee,
      sortable: true,
      cellExport: row => row.referee,
    },

    {
      name: 'Amount',
      selector: row => `$ ${row.amount}`,
      sortable: true,
      cellExport: row => row.amount,
    },

  ];

  const generatePath = (data) => {
    var classType = data?.activity_type
    const basePath = classType === "club"
      ? "/my_courses_details_clubs"
      : classType === "quiz"
        ? "/quiz-info"
        : classType === "competition"
          ? "/my_courses_details_competitions"
          : "/my_courses_detail";

    const queryParams = new URLSearchParams({
      class_id: data?.activity_id,
      teacher_id: data?.teacher_id,
      id: data?.activity_id,
      type: data?.activity_type,
      learner_data: JSON.stringify({
        id: data?.learner_id,
        learner_age: data?.learner_age,
        learner_name: data?.learner_name,
        learner_profile_img: data?.learner_profile_img,
      }
      ),
      class_type: data?.type



    }).toString();
    const encryptedURL = encryptURL(`${basePath}?${queryParams}`);
    return encryptedURL;
  };

  const handleRowClick = (row) => {
    const path = generatePath(row);
    console.log('path :', path);

    navigate(path);
  };


  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
        fontSize: '14px'
      },
    },
  };


  return (
    <>
      <JqueryLoad />
      <PHeader_Dashboard />
      <Helmet>
        <style>{`
  
  .card-container {
  border: 1px solid #e1dfdf;
  box-shadow: rgba(0, 0, 0, 0.20) 0px 2px 5px;
  border-radius: 10px;
  background-color: #570861;
  color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-container:hover {
  transform: translateY(-5px);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.card-content {
  transition: color 0.3s ease;
}

.card-container:hover .card-content {
  color: #ffffffcc; / Lighter shade of white /
}

  `}</style>
      </Helmet>
      <main>

        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>

          <div className="class-content">
            <div className>
              {/*start*/}
              <BackToPrevious />
              {/*end*/}
              {/*start*/}
              <div className>
                <div className=" ">
                  {/*start*/}
                  <div className="mb-4 d-md-flex align-items-center justify-content-between">
                    <h2 className="mb-md-0 mb-4 poppins fw-600">E Wallet</h2>


                    {/* <button onClick={() => navigate("/teacher-balance")} className="btn-theme bg-yellow m-2">E Wallet Balance</button> */}

                    <div className="fliter_list">
                      <ul className="list-unstyled slider_filter_list d-flex flex-wrap align-items-center">

                      </ul>
                    </div>
                  </div>

                  <div className>
                    <div className=" ">
                      {/*start*/}

                      <div className="row">

                        <div className='d-flex'>
                          <div className='col-md-3 mt-2  p-4 card-container'>
                            <div className='content card-content'>
                              <p className='fs-5 fw-600'>Available Balance</p>
                              <p className='fs-5 fw-500 mt-3'>{amount?.currency_code } {amount?.currency_symbol} {amount?.credits}</p>
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                  <h6 className=" text-purple mt-4">To view your payments made, refunds, and credits please select the option from the drop-down below.</h6>
                  <div className="d-flex justify-content-between mt-4">
                    <div className='col-md-2'>
                      <label className="mb-2">Filter :</label>
                      <Select
                        options={[
                          { value: "succeeded", label: "Payments made" },
                          { value: "refunded", label: "Refunds" },
                          { value: "credited", label: "Credits" },
                          // { value: "paid", label: "Paid" },
                          // { value: "unpaid", label: "Unpaid" },
                          // { value: "payment_hold", label: "Payment Hold" },
                          // { value: "payment_under_review", label: "Payment Under Review" },
                          // { value: "payment_denied", label: "Payment Denied" },
                        ]}
                        className="skip_pdf"
                        id="filter-select"
                        value={selectedFilterOption}
                        onChange={handleFilterChange}
                        styles={{
                          width: "100%",
                          maxWidth: "200px",
                          minHeight: "40px"
                        }}
                        placeholder="Select Filter"
                      />
                    </div>

                  </div>
                  {/* <div className="row">
                    {console.log(selectedFilterOption,'dataaaa')}
                    {selectedFilterOption.value === 'succeeded' ? (

                      <DataTableExtensions
                        columns={columns}
                        data={data}
                        print={false}
                        export={false}
                        filter={false}>
                        <DataTable columns={columns} data={data} responsive pagination customStyles={customStyles} />
                      </DataTableExtensions>
                    ) :
                    
                      <DataTableExtensions
                        columns={columns}
                        data={data}
                        print={false}
                        export={false}
                        filter={false}>
                        <DataTable columns={refundColumns} data={data} responsive pagination customStyles={customStyles} />
                      </DataTableExtensions>
                    }
                  </div> */}
                  <div className="row">
                    {console.log('selectedFilterOption:', selectedFilterOption)}
                    {console.log('selectedFilterOption.value:', selectedFilterOption.value)}

                    {selectedFilterOption.value === 'succeeded' ? (
                      <DataTableExtensions
                        columns={columns}
                        data={data}
                        print={false}
                        export={false}
                        filter={false}
                      >
                        <DataTable
                          columns={columns}
                          data={data}
                          responsive
                          pagination
                          customStyles={customStyles}
                          onRowClicked={handleRowClick}
                        />
                      </DataTableExtensions>
                    ) : selectedFilterOption.value === 'refunded' ? (
                      <DataTableExtensions
                        columns={refundColumns}
                        data={data}
                        print={false}
                        export={false}
                        filter={false}
                      >
                        <DataTable
                          columns={refundColumns}
                          data={data}
                          responsive
                          pagination
                          customStyles={customStyles}
                          onRowClicked={handleRowClick}
                        />
                      </DataTableExtensions>
                    ) : selectedFilterOption.value === 'credited' ? (
                      <DataTableExtensions
                        columns={creditcolums}
                        data={data}
                        print={false}
                        export={false}
                        filter={false}
                      >
                        <DataTable
                          columns={creditcolums}
                          data={data}
                          responsive
                          pagination
                          customStyles={customStyles}
                        />
                      </DataTableExtensions>
                    ) : (
                      <div>No matching filter option selected</div>
                    )}

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <PFooter_Dashboard />
    </>
  );
}


