const CommonIntroVideo = () => {
  return (
    <div className="video_boxs_sec position-relative">
      <div>
        <div className="video_boxs">
          <video id="video" className="w-100" loop height={557} controls="controls" poster="./assets/images/video_bg_dash.png">
            <source src="./assets/video/Classroom -parents.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
      {/* <div className="cancel_btn position-absolute">
        <a href="javascript:void(0)" className="colse_div_video">
          <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" height={25} viewBox="0 -960 960 960" width={25}>
            <path d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
          </svg>
        </a>
      </div> */}
    </div>
  );
};

export default CommonIntroVideo;
