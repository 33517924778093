import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { blog_details } from '../../../api/apiHandler'
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import BackToPrevious from '../../../components/backToPrevious';
import FilterBySearch from '../../Parents/SearchClass/filterBySearch';

export default function LandingPageBlog() {

  const [BlogData, setBlogData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  console.log("searchTerm :", searchTerm);
  var token = localStorage.getItem('Ttoken') || localStorage.getItem("PAtoken");
  const BlogDetails = (searchTerm, page) => {
    blog_details({ search: searchTerm, page:page }).then((response) => {
      console.log(response,'blogresponse');
      if (response?.code == 1) {

        setBlogData(response?.data?.result);
        setTotalPages(response?.data?.totalPages);
      } else {
        setBlogData([]);
        console.log("Data Not Found");
      }
    });
  };


  useEffect(() => {
    BlogDetails(searchTerm, currentPage);
  }, [searchTerm, currentPage]);

  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <Header />
      <main>
        <div className='mb-5'>
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="container mt-3">
            <div className>
              <BackToPrevious />
              <div className>
                <div className=" ">
                  <div className="mb-4">
                    <h2 className="mb-2 poppins fw-bold">Blogs</h2>
                  </div>
                  <FilterBySearch search={searchTerm} handleSearch={(val) => setSearchTerm(val)} />
                  <div className="row mb-5 blogs_lists">
                    {BlogData?.length > 0 ? (
                      BlogData?.map((b_data, index) => {
                        return (
                          <div key={index} className="col-xl-4 col-lg-6 col-md-6 mb-4">
                            <div className="certi_box bg-transparent shadow-none">
                              <div className="certi_images position-relative text-center bg-transparent p-0 border-bottom-0">
                                <div className="blog_img">
                                  {/* <img src="./assets/images/blog1.png" alt="blog1" className="w-100 img-fluid border-radius-10" /> */}
                                  {b_data?.blog_media && b_data?.blog_media[0]?.type === "video" ? (
                                                    <video controls className="w-100 img-fluid border-radius-10" style={{ height: "300px", objectFit: "cover" }}>
                                                        <source src={b_data?.blog_media[0]?.media} type="video/mp4" />
                                                    </video>
                                                ) : (
                                                    <img src={b_data?.blog_media[0]?.media} alt="blog" className="w-100 img-fluid border-radius-10" style={{ height: "300px", objectFit: "cover" }} />
                                                )}
                                  {/* <a href="#" className="play_btn_op1" tabIndex={0}>
                                    <img src="./assets/images/play.png" alt="play" />
                              
                                  </a> */}
                                </div>
                                <span className="bg-yellow border-radius-10 music-tag d-inline-block px-3 text-capitalize">{b_data.media_type}</span>
                              </div>
                              <Link to={token ? "/blog_details" : "/login"} state={b_data.id} className="d-block certi_ctn px-0">
                                <h2 className="fw-600 text-dif-black text-capitalize poppins mb-2 text-truncate">{b_data.title}</h2>
                                <div className="dwnload_btm d-flex mt-3 justify-content-between align-items-center">
                                  <div className="d-flex align-items-center">
                                    <div className="dwnl_img">
                                      <img src={b_data.profile_image} alt="center_div_img2" className="rounded-circle" />
                                    </div>
                                    <div className="ms-3">
                                      <span className="font-14 fw-bold">{b_data.admin_name}</span>
                                    </div>
                                  </div>
                                  <div className>
                                    <span>{b_data?.formatted_date}</span> • <span>{b_data.formatted_time}</span>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <h4 className="d-flex justify-content-center" style={{ color: "#570861" }}>
                        No Blogs Listed, Please Check Again Later
                      </h4>
                    )}
                  </div>
                  {/* <div className="custom_pagenation">
                    <ul className="d-flex align-items-center justify-content-center">
                      <li className="circle active">
                        <a href="#" className="text-purple font-w">
                          1
                        </a>
                      </li>
                      <li className="circle">
                        <a href="#" className="text-purple font-w">
                          2
                        </a>
                      </li>
                      <li className="circle">
                        <a href="#" className="text-purple font-w">
                          3
                        </a>
                      </li>
                      <li className="circle">
                        <a href="#" className="text-purple font-w">
                          4
                        </a>
                      </li>
                      <li className="mx-2">
                        <span>...</span>
                      </li>
                      <li className="circle">
                        <a href="#" className="text-purple font-w">
                          13
                        </a>
                      </li>
                    </ul>
                  </div> */}
                 <div className="custom_pagenation">
                    <ul className="d-flex align-items-center justify-content-center">
                      <li className={`circle ${currentPage === 1 ? 'disabled' : ''}`}>
                        <a href="#" className="text-purple font-w" onClick={handlePreviousPage}>
                          Pre
                        </a>
                      </li>
                      {[...Array(totalPages).keys()].map((pageNumber) => (
                        <li key={pageNumber} className={`circle ${pageNumber + 1 === currentPage ? 'active' : ''}`}>
                          <a href="#" className="text-purple font-w" onClick={() => handlePaginationClick(pageNumber + 1)}>
                            {pageNumber + 1}
                          </a>
                        </li>
                      ))}
                      <li className={`circle ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <a href="#" className="text-purple font-w" onClick={handleNextPage}>
                          Next
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/*end*/}
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  )

}
