import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Teacher_New_Leads } from '../../../api/apiHandler';
import { formatDate } from '../../../utils/common.service';

export default function New_Leads({ CategoriesData, subCategoriesData, startDate, endDate, classSize, isLoader }) {
    const navigate = useNavigate();

    var [data, setData] = useState([]);

    const teacherNewLeads = () => {

        var search_item = {};
        if (CategoriesData && subCategoriesData?.[0]) {
            search_item.category_list = CategoriesData
            search_item.sub_category = subCategoriesData
        }
        if (startDate) {
            search_item.start_date = formatDate(startDate, 'DD-MM-YYYY')
        }
        if (endDate) {
            search_item.end_date = formatDate(endDate, 'DD-MM-YYYY')
        }

        if (classSize) {
            search_item.class_size = classSize
        }
        isLoader(true)

        Teacher_New_Leads(search_item).then((resposnse) => {
        console.log('Teacher_New_Leads :', resposnse);

            if (resposnse?.code == 1) {
                setData(resposnse?.data)
                isLoader(false)
            } else {
                setData([])
                isLoader(false)

            }
        });
    }

    useEffect(() => {
        teacherNewLeads();
    }, [CategoriesData, subCategoriesData, startDate, endDate, classSize]);


    return (
        <>

            <div className="tab-pane fade show active" id="pills-new_leads" role="tabpanel" aria-labelledby="pills-new_leads-tab">
                <div className="row">
                    <div className="col-xl-9">
                        <div className="lead_request">
                            {data?.length > 0 ?
                                data?.map((leads_data, index) => {
                                    return (
                                        <div className="card">
                                            <a className="lead_req_img d-flex position-relative align-items-center">
                                                {/* <img src="./assets/images/user/user.png" alt="user" /> */}
                                                <img className="rounded-circle" src={leads_data.profile_image} alt="user" />
                                                <div className="ms-3">
                                                    <span className="fw-600">{leads_data.full_name}</span>
                                                    {leads_data?.is_read == 0 ?
                                                        <span className="ms-3 green_dots position-absolute" /> : <></>
                                                    }
                                                </div>
                                            </a>
                                            <a className="d-md-flex mb-3 align-items-center justify-content-between">
                                                <ul className="mb-md-0 mb-4">
                                                    <li className="mb-2">
                                                        <span className="font-12">
                                                            <span>

                                                            </span>
                                                            <span className="fw-500 font-14" style={{ color: '#570861' }}>Learner’s Country :
                                                                <span className="fw-500 text-dif-black font-14">&nbsp;{leads_data.country_name}</span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                    <li className="mb-2">
                                                        <span className="fw-500 font-14" style={{ color: '#570861' }}>Learner’s Local Time :
                                                            <span className="fw-500 text-dif-black font-14">&nbsp;{leads_data.local_time}</span>
                                                        </span>
                                                    </li>
                                                    <li className="mb-2">
                                                        <span className="fw-500 font-14" style={{ color: '#570861' }}>Requested Categories :
                                                            <span className="fw-500 text-dif-black font-14">&nbsp;{leads_data.category_list}</span>
                                                        </span>
                                                    </li>
                                                    <li className="mb-2">
                                                        <span className="fw-500 font-14" style={{ color: '#570861' }}>Requested Subjects :
                                                            <span className="fw-500 text-dif-black font-14">&nbsp;{leads_data.subcategory_list}</span>
                                                        </span>
                                                    </li>
                                                    <li className="mb-2">
                                                        <span className="fw-500 font-14" style={{ color: '#570861' }}>Class Size :
                                                            <span className="fw-500 text-dif-black font-14">&nbsp;{leads_data.class_size == "Individual/private" ? "1-on-1 Private Lesson" : "Group Lesson"}</span>
                                                        </span>
                                                    </li>
                                                    <li className="mb-2">
                                                        <span className="fw-500 font-14" style={{ color: '#570861' }}>Frequency of Lessons :
                                                            <span className="fw-500 text-dif-black font-14">&nbsp;{leads_data.want_lessons}</span>
                                                        </span>
                                                    </li>

                                                </ul>
                                                <div className="num_of_teach text-end">
                                                    <span className="mb-3 d-block" style={{ fontSize: '-3.75rem !important' }}>Number Of Teachers Have Responded.</span>

                                                    <ul className="d-flex align-items-center justify-content-end">
                                                        {[...Array(5)].map((_, index) => (
                                                            <li key={index} className={index < leads_data.Respond_teacher ? 'active' : ''} />
                                                        ))}
                                                        <span className="ms-3 fw-600">{leads_data.Respond_teacher}/5</span>
                                                    </ul>
                                                </div>

                                            </a>
                                            <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                                                <div className="button d-sm-flex align-items-center">
                                                    <Link to="/request_class_detail" state={{ id: leads_data.id, count: leads_data.Respond_teacher }} className="discussion_btn btn-theme px-3 d-inline-block text-center font-14">View Details</Link>
                                                </div>
                                                <div className="list_btw_dots mb-xl-0 mb-4">
                                                    <ul className="d-sm-flex align-items-center">

                                                        <li>
                                                            <span className="text-purple fw-600 font-14">{leads_data.Format_time !== null ? leads_data.Format_time : "N/A"}</span>
                                                        </li>
                                                        <li>
                                                            <span className="text-purple fw-600 font-14">{leads_data.formate_date}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) :
                                <h4 className="d-flex justify-content-center" style={{ "color": "#570861" }}>Data Not Found</h4>
                            }

                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}
