import { useState, useEffect } from "react";

const useCountdown = (hours, minutes) => {
  const initialSeconds = hours * 3600 + minutes * 60;
  const [seconds, setSeconds] = useState(initialSeconds);
  const [isRunning, setRunning] = useState(true);

  useEffect(() => {
    let timer;
    if (isRunning && seconds > 0) {
      timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isRunning, seconds]);

  const startCountdown = () => setRunning(true);
  const pauseCountdown = () => setRunning(false);
  const resetCountdown = () => setSeconds(initialSeconds);

  const formatTime = (value) => (value < 10 ? `0${value}` : value);

  const formattedTime = {
    hours: Math.floor(seconds / 3600),
    minutes: Math.floor((seconds % 3600) / 60),
    seconds: seconds % 60,
  };

  const formattedTimeString = `${formatTime(formattedTime.hours)}:${formatTime(formattedTime.minutes)}:${formatTime(formattedTime.seconds)}`;

  return {
    hours: formattedTime.hours,
    minutes: formattedTime.minutes,
    seconds: formattedTime.seconds,
    isRunning,
    startCountdown,
    pauseCountdown,
    resetCountdown,
    formattedTimeString,
  };
};

export default useCountdown;
