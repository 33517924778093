import React, { useEffect, useRef, useState } from "react";
import BackToPrevious from "../../../components/backToPrevious";
import PHeader_Dashboard from "../Pinc/PHeader_Dashboard";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { uploadImageOnAWS } from "../../aws/Service";
import { TOAST_ERROR, TOAST_SUCCESS, formatDate } from "../../../utils/common.service";
import { parents_class_requirement_form } from "../../../api/apiHandler";
import { useLocation, useNavigate } from "react-router-dom";

export default function ClassRequirementForm() {
    const navigate = useNavigate();
    const location = useLocation();
    const params = location?.state
    console.log('params :', params);
    const fileInputs = useRef([]);
    const resetFileInputs = () => {
        fileInputs.current.forEach((input) => {
            input.value = "";
        });
    };
    const [selectedReason, setSelectedReason] = useState("");
    console.log('selectedReason :', selectedReason);

    const handleSelectChange = (event) => {
        console.log(event.target.value);
        setSelectedReason(event.target.value);
    };

    let schema = yup.object().shape({
        student_name: yup.string().required("Please add student name"),
        program: yup.string().required("Please add student name"),
        registered_date: yup.string().required("Please add registered date"),
        why_class: yup.string().required("Please add reason for class"),
        expect_class: yup.string().required("Please add expectation from class"),
        other_comments: yup.string()
    });

    if (selectedReason === "Other") {
        schema = schema.shape({
            other: yup.string().required("Please add reason")
                .matches(/^[A-Za-z]+$/, "Only alphabet and characters allowed")
                .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
                .min(4, "Field must contain atleast 4 characters long"),

        });
    }


    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        reset,
        watch,
        control,
        getValues,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });


    const [docExtensions, setDocExtensions] = useState(Array(3).fill(null));
    const [previewUrls, setPreviewUrls] = useState(Array(3).fill(null));
  
    const handleImage = async (e, index) => {
      const file = e.target.files[0];
  
      // Allowed file types including Excel
      const allowedFileTypes = [
        'image/jpeg',
        'image/png',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ];
  
      // Validate file type
      if (!allowedFileTypes.includes(file?.type)) {
        TOAST_ERROR('Please attach a valid image (JPEG/PNG), PDF, DOC, or Excel file');
        return;
      }
  
      // Update file extension state
      const newDocExtensions = [...docExtensions];
      newDocExtensions[index] = file.type;
      setDocExtensions(newDocExtensions);
  
      // Create preview URL for images
      if (file.type.startsWith('image/')) {
        const previewUrl = URL.createObjectURL(file);
        const newPreviewUrls = [...previewUrls];
        newPreviewUrls[index] = previewUrl;
        setPreviewUrls(newPreviewUrls);
      }
  
      // Update form values
      const selectedImages = Array.from(e.target.files);
      const currentAttachment = getValues("attachments") || [];
      setValue("attachments", [...currentAttachment, ...selectedImages]);
    };
  
    const renderFilePreview = (index) => {
      const fileType = docExtensions[index];
  
      if (fileType?.startsWith('image/')) {
        return (
          <img
            src={previewUrls[index]}
            alt="Preview"
            className="image"
            style={{
              width: "100%",
              height: "166px",
              objectFit: "cover"
            }}
            onClick={() => fileInputs.current[index]?.click()}
          />
        );
      }
  
      if (fileType === 'application/pdf') {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="currentColor"
            style={{ color: "red", margin: "30px" }} className="bi bi-filetype-pdf" viewBox="0 0 16 16"
            onClick={() => fileInputs.current[index]?.click()}>
            <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
          </svg>
        );
      }
  
      if (fileType?.includes('word')) {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={100} height={100}
            style={{ margin: "30px" }} viewBox="0 0 48 48"
            onClick={() => fileInputs.current[index]?.click()}>
            <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path>
            <path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path>
            <path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
          </svg>
        );
      }
  
      if (fileType?.includes('excel') || fileType?.includes('sheet')) {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={100} height={100}
            style={{ margin: "30px" }} viewBox="0 0 48 48"
            onClick={() => fileInputs.current[index]?.click()}>
            <defs>
              <linearGradient id="Excel_1" x1="36.2" y1="43.231" x2="11.8" y2="18.769" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#18884f" />
                <stop offset="1" stopColor="#117e43" />
              </linearGradient>
            </defs>
            <path fill="url(#Excel_1)" d="M44,27H35v-4h9c0.552,0,1,0.447,1,1v2C45,26.553,44.552,27,44,27z" />
            <path fill="#185c37" d="M44,34H35v-4h9c0.552,0,1,0.447,1,1v2C45,33.553,44.552,34,44,34z" />
            <path fill="#21a366" d="M44,41H35v-4h9c0.552,0,1,0.447,1,1v2C45,40.553,44.552,41,44,41z" />
            <path fill="#107c41" d="M44,20H35v-4h9c0.552,0,1,0.447,1,1v2C45,19.553,44.552,20,44,20z" />
            <path fill="#185c37" d="M44,13H35V9h9c0.552,0,1,0.447,1,1v2C45,12.553,44.552,13,44,13z" />
            <path fill="#21a366" d="M41,6H35V2h6c0.552,0,1,0.447,1,1v2C42,5.553,41.552,6,41,6z" />
            <path fill="#107c41" d="M35,6v34h-6c-0.552,0-1-0.447-1-1V7c0-0.553,0.448-1,1-1H35z" />
            <path opacity="0.1" d="M35,6v34h-6c-0.552,0-1-0.447-1-1V7c0-0.553,0.448-1,1-1H35z" />
            <path fill="#33c481" d="M28,6v34h-6c-0.552,0-1-0.447-1-1V7c0-0.553,0.448-1,1-1H28z" />
            <path fill="#21a366" d="M21,6v34h-6c-0.552,0-1-0.447-1-1V7c0-0.553,0.448-1,1-1H21z" />
            <path fill="#107c41" d="M14,6v34H8c-0.552,0-1-0.447-1-1V7c0-0.553,0.448-1,1-1H14z" />
            <path fill="#185c37" d="M7,6v34H4c-0.552,0-1-0.447-1-1V7c0-0.553,0.448-1,1-1H7z" />
            <path fill="#ffffff" d="M19.416,25.147l-4.032-6.259l3.937-6.107h-3.491l-2.032,3.768 c-0.146,0.277-0.242,0.475-0.287,0.593h-0.04c-0.091-0.224-0.187-0.427-0.287-0.608l-2.13-3.753h-3.633l3.927,6.12l-4.122,6.246h3.43 l2.289-4.067c0.091-0.155,0.179-0.352,0.264-0.59h0.044c0.063,0.169,0.159,0.371,0.287,0.605l2.354,4.052H19.416z" />
          </svg>
        );
      }
  
      return (
        <span className="file_icons position-absolute">
          <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
          </svg>
        </span>
      );
    };
    useEffect(() => {
        if (params?.learnerData && params.learnerData.length > 0) {
            const studentNames = params.learnerData.map(learner => learner.learner_name).join(', ');
            setValue('student_name', studentNames);
        }
        if (params?.data?.created_at) {
            const formattedDate = new Date(params?.data?.created_at).toISOString().split('T')[0];
            setValue('registered_date', formattedDate);
        }
        if (params?.title) {

            setValue('program', params.title);
        }
    }, [params, setValue]);

    const onSubmit = (data) => {
        data.activity_id = params?.activity_id
        data.activity_type = params?.activity_type
        data.teacher_id = params?.data?.teacher_id
        data.student_name = params?.learnerData.map(learner => learner.id).join(',');
        data.registered_date = params?.data?.created_at

        console.log('data :', data);
        // return
        try {
            var image = [];
            if (data?.attachments) {
                let promises = [];
                for (let i = 0; i < data.attachments.length; i++) {
                    let promise = uploadImageOnAWS(data.attachments[i], "class_requirement_attachment")
                        .then((attachment) => {
                            image.push({ attachments: attachment });
                        });
                    promises.push(promise);
                }

                Promise.all(promises).then(() => {
                    data.attachments = image;

                    parents_class_requirement_form(data)
                        .then((response) => {
                            if (response?.code == 1) {
                                TOAST_SUCCESS(response?.message);
                                setDocExtensions(Array(3).fill(null));
                                setPreviewUrls(Array(3).fill(null));
                                reset();
                                resetFileInputs();
                                navigate('/search_class')
                            } else {
                                TOAST_ERROR(response?.message);
                            }
                        })
                        .catch((error) => {
                            TOAST_ERROR("Error submitting form");
                            console.error("Error in form submission:", error);
                        });
                }).catch((error) => {
                    TOAST_ERROR("Error uploading file");
                    console.error("Error uploading file:", error);
                });
            } else {
                parents_class_requirement_form(data)
                    .then((response) => {
                        if (response?.code == 1) {
                            TOAST_SUCCESS(response?.message);
                            setDocExtensions(Array(3).fill(null));
                            setPreviewUrls(Array(3).fill(null));
                            reset();
                            resetFileInputs();
                            navigate('/search_class')

                        } else {
                            TOAST_ERROR(response?.message);
                        }
                    })
                    .catch((error) => {
                        TOAST_ERROR("Error submitting form");
                        console.error("Error in form submission:", error);
                    });
            }
        } catch (error) {
            TOAST_ERROR("Unexpected error");
            console.error("Error in form submission:", error);
        }
    };

    return (
        <>
            <PHeader_Dashboard />

            <main className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="#" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </a>
                        </div>
                    </div>
                </header>
                <div class="class-content">
                    <a className="mb-4 arrow_left mb-3 d-block pointer custom-back" onClick={() => navigate('/search_class')}>
                        {/* btn btn-custom text-capitalize custom-back */}
                        <span>
                            <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.86245 2.225L8.37912 0.75L0.137451 9L8.38745 17.25L9.86245 15.775L3.08745 9L9.86245 2.225Z" fill="#131313" />
                            </svg>
                        </span>
                        &nbsp; Back
                    </a>

                    <section class="requirement_form">
                        <div class="text-center mb-4">
                            <h2 class="fw-600 poppins text-capitalize text-purple">Class Requirement Form</h2>
                        </div>

                        <div class="row justify-content-center align-items-center">
                            <div class="col-lg-8">
                                <div class="card border-purple p-4">
                                    <form class="custom_form mt-4" action="" id="" onSubmit={handleSubmit(onSubmit)}>
                                        <div class="form-group mb-4">
                                            <label class="mb-2">Name of student:</label>
                                            <input type="text" class="form-control" name="" placeholder="" readonly="" {...register('student_name')} />
                                            {errors.student_name && <p className="mt-2 font-bold col-red">
                                                <span style={{ color: "red" }}>{errors.student_name.message}</span>
                                            </p>}
                                        </div>

                                        {/* <div class="form-group mb-4">
                                            <label class="mb-2">Program</label>
                                            <select class="form-select" aria-label="Default select example" disabled="" {...register('program')}>
                                                <option value={''} selected>Select Program</option>
                                                <option value={'Math'}>Math</option>
                                                <option value={'English'}>English</option>
                                                <option value={'Science'}>Science</option>
                                                <option value={'Nature & Space'}>Nature &amp; Space</option>
                                                <option value={'Social Sciences'}>Social Sciences</option>
                                                <option value={'Business / Economics'}>Business / Economics</option>
                                                <option value={'Accounting/ Finance'}>Accounting/ Finance</option>
                                                <option value={'Engineering'}>Engineering</option>
                                                <option value={'Medicine/Nursing'}>Medicine/Nursing</option>
                                                <option value={'Law'}>Law</option>
                                                <option value={'Exam Preparation'}>Exam Preparation</option>
                                                <option value={'Homework Help'}>Homework Help</option>
                                            </select>
                                            {errors.program && <p className="mt-2 font-bold col-red">
                                                <span style={{ color: "red" }}>{errors.program.message}</span>
                                            </p>}
                                        </div> */}

                                        <div class="form-group mb-4">
                                            <label class="mb-2">Program</label>

                                            <input type="text" class="form-control" name="program" placeholder="" readonly="" disabled="" {...register('program')} />
                                            {errors.program && <p className="mt-2 font-bold col-red">
                                                <span style={{ color: "red" }}>{errors.program.message}</span>
                                            </p>}
                                        </div>

                                        <div class="form-group mb-4">
                                            <label class="mb-2">Student Registered Date:</label>
                                            <input type="date" class="form-control" name="registered_date" placeholder="" readonly="" {...register('registered_date')} />
                                            {errors.registered_date && <p className="mt-2 font-bold col-red">
                                                <span style={{ color: "red" }}>{errors.registered_date.message}</span>
                                            </p>}
                                        </div>

                                        <div class="form-group mb-4">
                                            <label class="mb-2">Why did you take this class</label>
                                            <select class="form-select" aria-label="Default select example" id="why_did_tk_this" {...register('why_class')} onChange={handleSelectChange}>
                                                <option value="I need it for school">I need it for school</option>
                                                <option value="I need it for work">I need it for work</option>
                                                <option value="I want to learn  - beginner">I want to learn  - beginner</option>
                                                <option value="I want to improve - intermediate">I want to improve - intermediate</option>
                                                <option value="Other">Other</option>
                                            </select>

                                            {/* {errors3.stop_class_reason && <p>{errors3.stop_class_reason.message}</p>} */}
                                            {errors.why_class && <p className="mt-2 font-bold col-red">
                                                <span style={{ color: "red" }}>{errors.why_class.message}</span>
                                            </p>}
                                        </div>

                                        {selectedReason === "Other" && (
                                            <div class="form-group mb-4 " id="otherTab">
                                                <label class="mb-2">Other Reason</label>
                                                <input type="text" class="form-control" name="other" placeholder=""  {...register('other')} />
                                                {errors.other && <p className="mt-2 font-bold col-red">
                                                    <span style={{ color: "red" }}>{errors.other.message}</span>
                                                </p>}
                                            </div>
                                        )}

                                        <div class="form-group mb-4">
                                            <label class="mb-2">What do you expect from this class? </label>
                                            <textarea class="form-control" rows="4" {...register('expect_class')}></textarea>

                                            {errors.expect_class && <p className="mt-2 font-bold col-red">
                                                <span style={{ color: "red" }}>{errors.expect_class.message}</span>
                                            </p>}
                                        </div>

                                        <div class="form-group mb-4">
                                            <label class="mb-2">Any other comments you would like to share regarding the student etc.</label>
                                            <textarea class="form-control" rows="6" {...register('other_comments')}></textarea>

                                            {errors.other_comments && <p className="mt-2 font-bold col-red">
                                                <span style={{ color: "red" }}>{errors.other_comments.message}</span>
                                            </p>}
                                        </div>

                                        <div class="form-group">
                                            <label class="mb-2">Upload Attachments</label>
                                            <div className="row">
                                                {[0, 1, 2].map((index) => (
                                                    <div className="col-lg-4" key={index}>
                                                        <div className="form-group mb-lg-0 mb-4 file_upload">
                                                            <div
                                                                className="file_update position-relative d-flex align-items-center justify-content-center"
                                                                style={{ height: '166px', border: '1px solid #ddd', borderRadius: '8px', cursor: 'pointer' }}
                                                                onClick={() => fileInputs.current[index]?.click()}
                                                            >
                                                                {renderFilePreview(index)}
                                                            </div>
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                name="attachment"
                                                                accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                                                                ref={(el) => (fileInputs.current[index] = el)}
                                                                onChange={(e) => handleImage(e, index)}
                                                                style={{ display: 'none' }} // Hide the input field
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        <div class="button mt-5">

                                            <button type="submit" class="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block">Submit</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>
    );
}