import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import JqueryLoad from '../../../JqueryLoad/JqueryLoad';
import TFooter_Dashboard from '../Include/TFooter_Dashboard';
import THeader_Dashboard from '../Include/THeader_Dashboard';
import AiBox from './AiBox';

export default function AiList() {
  const navigate = useNavigate();
  const [getSubject, setSubject] = useState('');

  const SelectSubject = (value) => {
    console.log(`Selected Subject: ${value}`);
    setSubject(value);
  };

  return (
    <>
      <JqueryLoad />
      <THeader_Dashboard />
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div>
              <a
                onClick={() => navigate(-1)}
                className="mb-4 arrow_left mb-3 d-block"
                role="button" // Added role attribute for accessibility
                tabIndex={0}  // Added tabIndex for focus
                aria-label="Back"
              >
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span className="ms-2">Back</span>
                </span>
              </a>
            </div>

            <section className="message-area">
              <div>
                <h2 className="mb-5 poppins fw-600 text-capitalize">AI Virtual Tutor</h2>
                <div className="row">
                  <div className="col-12">
                  
                      <div className="chatbox">
                        <AiBox getSubject={getSubject} />
                      </div>
                    

                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
      <TFooter_Dashboard />
    </>
  );
}


