import React, { useEffect, useState } from 'react'
import JqueryLoad from '../../../JqueryLoad/JqueryLoad'
import TFooter_Dashboard from '../Include/TFooter_Dashboard'
import THeader_Dashboard from '../Include/THeader_Dashboard'
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { check_teacher_balance, credits_listing, teacher_payment_credits, teacher_payment_received, teacher_payment_refunds_listing, teacher_received_pdf } from '../../../api/apiHandler';
import { Helmet } from 'react-helmet';
import dayjs from 'dayjs';
import { NoDataComponent } from '../../../utils/common.service';
import CryptoJS from 'crypto-js';

export default function Ewallet({ isLoader }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [creditData, setCreditData] = useState([]);
  const [filter, setFilter] = useState("payment_received");
  console.log('filter  :', filter);
  const [timedData, setTimeData] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const [selectedOption, setSelectedOption] = useState('');

  const currentDate = new Date();
  const defaultStartDate = currentDate.toISOString().split('T')[0];
  const defaultStartMonth = currentDate.toISOString().slice(0, 7);

  let initialStartDate = defaultStartDate;
  let initialEndDate = defaultStartDate;

  if (selectedPeriod === 'daily') {
    initialStartDate = currentDate.toISOString().split('T')[0];
    initialEndDate = currentDate.toISOString().split('T')[0];
  } else if (selectedPeriod === 'weekly') {
    const sevenDaysAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
    initialStartDate = sevenDaysAgo.toISOString().split('T')[0];
    initialEndDate = currentDate.toISOString().split('T')[0];
  }
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [startMonth, setStartMonth] = useState(defaultStartMonth);
  const [endMonth, setEndMonth] = useState(defaultStartMonth);
  const currentYear = new Date().getFullYear();
  const [startYear, setStartYear] = useState(currentYear);
  const [endYear, setEndYear] = useState(currentYear);


  const handleStartMonthChange = (value) => {
    setStartMonth(value);
  };

  const handleEndMonthChange = (value) => {
    setEndMonth(value);
  };

  const getYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= currentYear - 50; i--) {
      years.push({ value: i, label: i });
    }
    return years;
  };

  const handleFilter = async (data) => {
    setFilter(data)
  }

  const handleTime = async (data) => {
    setTimeData(data)
  }

  const handlePeriodChange = (selectedOption) => {
    console.log('selectedOption :', selectedOption);
    setSelectedOption(selectedOption);
    setSelectedPeriod(selectedOption);

    // Update start and end dates immediately
    const currentDate = new Date();
    if (selectedOption == 'daily') {
      setStartDate(currentDate.toISOString().split('T')[0]);
      setEndDate(currentDate.toISOString().split('T')[0]);
    } else if (selectedOption == 'weekly') {
      const sevenDaysAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
      setStartDate(sevenDaysAgo.toISOString().split('T')[0]);
      setEndDate(currentDate.toISOString().split('T')[0]);
    }
  };
  useEffect(() => {
    console.log("use1")
    if (filter) {
      TeacherEarning();
    }
  }, [selectedPeriod, startDate, endDate, startMonth, endMonth, startYear, endYear, filter])
  const TeacherEarning = async () => {

    isLoader(true)
    try {
      let requestData = {};

      if (selectedPeriod == 'daily') {
        requestData = {
          type: 'daily',
          startDate: startDate,
          endDate: endDate
        };
      } else if (selectedPeriod == 'weekly') {
        requestData = {
          type: 'weekly',
          startDate: startDate,
          endDate: endDate
        };
      } else if (selectedPeriod == 'monthly') {
        requestData = {
          type: 'monthly',
          startDate: startMonth,
          endDate: endMonth
        };
      } else if (selectedPeriod == 'yearly') {
        requestData = {
          type: 'yearly',
          startYear: startYear,
          endYear: endYear
        };
      }
      console.log('requestData :', requestData);
      if (filter == "payment_received") {

        const response = await teacher_payment_credits(requestData);
        console.log('response123 :', response);
        if (response?.code == 1) {
          setData(response?.data)
          isLoader(false)

        } else {
          setData([])
          isLoader(false)
        }
      } else {
        console.log("else")
        const response = await credits_listing(requestData);
        console.log('credits_listing :', response);
        if (response?.code == 1) {
          setCreditData(response?.data)
          isLoader(false)

        } else {
          setCreditData([])
          isLoader(false)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const downloadPdf = async (a) => {
  console.log('downloadPdf :', a);
    try {
      const response = await teacher_received_pdf({ payment_id: a.toString() });

      console.log('Response:', response);

      if (response?.code == "1" && response?.data) {
        const pdfUrl = response?.data;

        const pdfResponse = await fetch(pdfUrl);
        const pdfBlob = await pdfResponse.blob();

        const randomName = `invoice_${Date.now()}.pdf`;

        const pdfFile = new File([pdfBlob], randomName, { type: 'application/pdf' });

        const pdfDataUrl = URL.createObjectURL(pdfFile);

        const downloadLink = document.createElement('a');
        downloadLink.href = pdfDataUrl;
        downloadLink.download = randomName;

        downloadLink.click();

        console.log('Download started.');
      } else {
        console.log('Invalid response or PDF URL not found.');
      }
    } catch (error) {
      console.log('Error downloading PDF:', error);
    }
  };

  const [dataCard, setDataCard] = useState([]);

  const TeacherEarningCard = async () => {
    try {
      const response = await check_teacher_balance();
      console.log('check_teacher_balance :', response);
      if (response?.code == 1) {
        setDataCard(response?.data)
      } else {
        setData([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    TeacherEarningCard();
  }, [])

  // const TeacherCredit = async () => {

  //   isLoader(true)
  //   try {
  //     const response = await teacher_payment_credits();
  //     console.log('teacher_payment_credits :', response);
  //     if (response?.code == 1) {
  //       setCreditData(response?.data)
  //       isLoader(false)

  //     } else {
  //       setCreditData([])
  //       isLoader(false)
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // useEffect(() => {
  //   TeacherCredit();
  // }, [])

  const columns = [
    {
      name: 'Id',
      selector: (row, index) => index + 1,
      cellExport: (row, index) => index + 1,
      sortable: true,
      width: '75px'
    },
    {
      name: 'Payment date',
      selector: row => dayjs(row.enrolled_at).format("DD-MM-YYYY"),
      sortable: true,
      cellExport: row => row.enrolled_at
    },
    {
      name: 'Transaction Id',
      selector: row => row.transaction_id,
      sortable: true,
      cellExport: row => row.transaction_id,
    },

    {
      name: 'Learner’s name',
      selector: row => row.from_account,
      sortable: true,
      cellExport: row => row.from_account,
    },
    // {
    //   name: 'Class name',
    //   selector: row => row.activity_title,
    //   sortable: true,
    //   cellExport: row => row.activity_title,
    // },
    {
      name: 'Class Name',
      selector: row => row.activity_title,
      sortable: true,
      cell: row => (
        <a onClick={() => handleClassClick({ 
          activityId: row.activity_id, 
          typeClass: row.activity_type, 
          type: "online"
        })} style={{ cursor: 'pointer' }}>
          {row.activity_title}
        </a>
      ),
      cellExport: row => row.activity_title,
    },
    {
      name: 'Platform',
      selector: row => row.platform_type,
      sortable: true,
      cellExport: row => row.platform_type,
    },
    {
      name: 'Class Duration',
      selector: row => row.duration,
      sortable: true,
      cellExport: row => row.duration,
    },
    {
      name: 'Payment Amount',
      selector: row => `$ ${row.teacher_amount} `,
      sortable: true,
      cellExport: row => row.teacher_amount,
    },
    {
      name: 'Action',
      selector: row => row.id,
      center: true,
      cell: (row) => <>

        <button type="button" className="btn" style={{ boxShadow: 'none' }} id={row.id} ><a className="material-icons" onClick={() => downloadPdf(row.payment_id)} id={row.id} >  <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 0C12.0333 0 9.13319 0.879735 6.66645 2.52796C4.19972 4.17618 2.27713 6.51886 1.14181 9.25975C0.00649929 12.0006 -0.290551 15.0166 0.288228 17.9264C0.867006 20.8361 2.29562 23.5088 4.39341 25.6066C6.49119 27.7044 9.16394 29.133 12.0737 29.7118C14.9834 30.2906 17.9994 29.9935 20.7403 28.8582C23.4812 27.7229 25.8238 25.8003 27.4721 23.3336C29.1203 20.8668 30 17.9667 30 15C30 11.0218 28.4197 7.20644 25.6066 4.3934C22.7936 1.58035 18.9783 0 15 0ZM19.9206 24.6625H10.0794C9.74786 24.6625 9.42992 24.5308 9.1955 24.2964C8.96108 24.062 8.82938 23.744 8.82938 23.4125C8.82938 23.081 8.96108 22.763 9.1955 22.5286C9.42992 22.2942 9.74786 22.1625 10.0794 22.1625H19.9206C20.2522 22.1625 20.5701 22.2942 20.8045 22.5286C21.0389 22.763 21.1706 23.081 21.1706 23.4125C21.1706 23.744 21.0389 24.062 20.8045 24.2964C20.5701 24.5308 20.2522 24.6625 19.9206 24.6625ZM20.8044 15.26L15.88 20.1875C15.6456 20.4218 15.3277 20.5535 14.9963 20.5535C14.6648 20.5535 14.3469 20.4218 14.1125 20.1875L9.19563 15.2713C8.96075 15.0369 8.8286 14.7188 8.82824 14.387C8.82789 14.0551 8.95937 13.7368 9.19376 13.5019C9.42814 13.267 9.74623 13.1348 10.0781 13.1345C10.4099 13.1341 10.7283 13.2656 10.9631 13.5L13.75 16.2869V5.22813C13.75 4.8966 13.8817 4.57866 14.1161 4.34424C14.3505 4.10982 14.6685 3.97813 15 3.97813C15.3315 3.97813 15.6495 4.10982 15.8839 4.34424C16.1183 4.57866 16.25 4.8966 16.25 5.22813V16.2794L19.0369 13.4925C19.2726 13.2648 19.5884 13.1388 19.9161 13.1417C20.2439 13.1445 20.5574 13.276 20.7892 13.5077C21.0209 13.7395 21.1524 14.053 21.1552 14.3807C21.1581 14.7085 21.0321 15.0242 20.8044 15.26Z" fill="#570861" />
        </svg></a> </button></>
    },

  ];

  function handleClassClick(params) {
    const { activityId, typeClass, type } = params; // Destructure the object properties

    // Create a query string from the parameters
    const queryString = new URLSearchParams({ activityId, typeClass, type }).toString();
  
    // Define your key and IV (Initialization Vector)
    var key = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNfTmDXHWVkQWdanYOm');
    var iv = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNf');
  
    // Encrypt the query string
    const encryptedData = CryptoJS.AES.encrypt(queryString, key, { iv: iv }).toString();
  
    // Encode the encrypted data for use in a URL
    const encryptedUrl = encodeURIComponent(encryptedData);
  
    // Construct the URL with the encrypted query parameter
    navigate(`/teach-detail?data=${encryptedUrl}`);
  
  }

  const creditcolums = [
    {
      name: 'Id',
      selector: (row, index) => index + 1,
      cellExport: (row, index) => index + 1,
      sortable: true,
      width: '75px'
    },
    // {
    //   name: 'Payment date',
    //   selector: row => dayjs(row.enrolled_at).format("DD-MM-YYYY"),
    //   sortable: true,
    //   cellExport: row => row.enrolled_at
    // },
    {
      name: 'Referer',
      selector: row => row.referer,
      sortable: true,
      cellExport: row => row.referer,
    },

    {
      name: 'Referee',
      selector: row => row.referee,
      sortable: true,
      cellExport: row => row.referee,
    },

    {
      name: 'Amount',
      selector: row => `$ ${row.amount}`,
      sortable: true,
      cellExport: row => row.amount,
    },

  ];


  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
        fontSize: '14px'
      },
    },
  };

  const today = new Date().toISOString().split('T')[0];


  return (
    <>
      <JqueryLoad />
      <THeader_Dashboard />
      <Helmet>
        <style>
          {`
                                      .hQPXLq {
                                        display: flex;
                                        width: 100%;
                                        color: #570861;
                                        font-size: 12px;
                                        font-weight: 500;
                                    }
                                    
                                   

                                            .card {
                                                position: relative;
                                                display: flex;
                                                flex-direction: column;
                                                /* min-width: 0; */
                                                word-wrap: break-word;
                                                background-color: #fff;
                                                background-clip: border-box;
                                                border: 1px solid rgba(0,0,0,.125);
                                                border-radius: 0.25rem;
                                            }

                                            .plan-details {
                                                border: 1px solid #ffefbd;
                                                border-radius: 0.5em;
                                                cursor: pointer;
                                                height: 100%;
                                                padding: 14px;
                                                transition: border-color 0.2s ease-out;
                                            }
                                            .pb-5 {
                                                padding-bottom: 0rem!important;
                                            }
                                            .dwnl_img img {
                                              width: 100%;
                                              height: auto;
                                              display: block;
                                          }
                                          .data-table-extensions > .data-table-extensions-action > button {
                                            float: right;
                                            display: none;
                                            width: 30px;
                                            height: 30px;
                                            background-repeat: no-repeat;
                                            background-position: center center;
                                            background-color: transparent;
                                            border: none;
                                            cursor: pointer;
                                            padding: 5px;
                                            border-radius: 0.3rem;
                                            outline: none;
                                            margin-right: 0.3rem;
                                        }
                                        .data-table-extensions > .data-table-extensions-filter {
                                          float: left;
                                          display: none;
                                      }
                                      .card-container {
                                        border: 1px solid #e1dfdf;
                                        box-shadow: rgba(0, 0, 0, 0.20) 0px 2px 5px;
                                        border-radius: 10px;
                                        background-color: #570861;
                                        color: white;
                                        transition: transform 0.3s ease, box-shadow 0.3s ease;
                                      }
                                      
                                      .card-container:hover {
                                        transform: translateY(-5px);
                                        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                                      }
                                      
                                      .card-content {
                                        transition: color 0.3s ease;
                                      }
                                      
                                      .card-container:hover .card-content {
                                        color: #ffffffcc; /* Lighter shade of white */
                                      }
                                      
                                    `}
        </style>
      </Helmet>
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>

          <div className="class-content">
            <div className>
              {/*start*/}
              <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span  className="ms-2">Back</span>
                </span></a>
              {/*end*/}
              {/*start*/}
              <div className>
                <div className=" ">
                  {/*start*/}
                  <div className="d-md-flex align-items-center justify-content-between">
                    <h2 className="mb-md-0 poppins fw-600">E Wallet</h2>

                    <div className="fliter_list">
                      <ul className="list-unstyled slider_filter_list d-flex flex-wrap align-items-center">

                      </ul>
                    </div>
                    {/* <div >
                      <button onClick={() => navigate("/teacher-balance")} className="btn-theme bg-yellow m-2"> E Wallet Balance</button>
                    </div> */}
                  </div>
                  <div className>
                    <div className=" ">
                      {/*start*/}

                      <div className="row">
                        <div className='d-flex'>
                          <div className='col-md-3 mt-5 mx-3 p-4 card-container'>
                            <div className='content card-content'>
                              <p className='fs-5 fw-600'>Available Balance</p>
                              {/* {console.log('length :', dataCard?.length)} */}
                              <p className='fs-5 fw-500 mt-3'>$ {dataCard?.length == 0 || dataCard?.available_balance == undefined || dataCard?.available_balance == 0 ?  "0.00" : dataCard?.available_balance}</p>
                            </div>
                          </div>

                          <div className='col-md-3 mt-5 mx-3 p-4 card-container'>
                            <div className='content card-content'>
                              <p className='fs-5 fw-600'>Pending Payment</p>
                              <p className='fs-5 fw-500 mt-3'>$ {dataCard?.length == 0 || dataCard?.available_balance == undefined || dataCard?.available_balance == 0 ?  "0.00" : dataCard?.pending_balance}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className='col-2'>
                      <div className="form-group mb-4">
                        <label className="mb-2">Filter : </label>
                        <select id="dropdown" className="form-select" aria-label="Default select example" onChange={(e) => handleFilter(e.target.value)} >
                          <option selected={filter == 'payment_received' ? 'selected' : ''} value="payment_received" >Payment Received</option>
                          <option selected={filter == 'credits' ? 'selected' : ''} value="credits">Credits</option>
                          {/* <option selected={filter == 'refunds' ? 'selected' : ''} value="refunds">Refunds</option> */}
                        </select>
                      </div>

                    </div>
                    <div className='col-2'>
                      <div className="form-group mb-4">
                        <label className="mb-2">Start Time Period : </label>
                        <select id="dropdown" className="form-select" aria-label="Default select example" onChange={(e) => { handleTime(e.target.value); handlePeriodChange(e.target.value); }} >
                          <option value="">Select Time Period</option>
                          <option selected={timedData == 'daily' ? 'selected' : ''} value="daily">Daily</option>
                          <option selected={timedData == 'weekly' ? 'selected' : ''} value="weekly">Weekly</option>
                          <option selected={timedData == 'monthly' ? 'selected' : ''} value="monthly">Monthly</option>
                          <option selected={timedData == 'yearly' ? 'selected' : ''} value="yearly">Yearly</option>
                        </select>
                      </div>

                    </div>

                    <div className='col-md-5'>

                      {selectedPeriod === 'daily' && (
                        <div className="d-flex justify-content-end">
                          <div className='col-md-5 ms-4'>
                            <label htmlFor="daily-start-date" className='mb-2'>Start Date:</label>
                            <input
                              type="date"
                              name="start_date"
                              id="daily-start-date"
                              className="form-control"
                              required=""
                              value={startDate}
                              max={today}
                              onChange={(e) => setStartDate(e.target.value)}
                            />
                          </div>

                          <div className="col-md-5 ms-4">
                            <label htmlFor="daily-end-date" className='mb-2' >End Date:</label>
                            <input
                              type="date"
                              name="end_date"
                              id="daily-end-date"
                              className="form-control"
                              required=""
                              min={startDate}
                              value={endDate}
                              onChange={(e) => setEndDate(e.target.value)}
                            />
                          </div>
                        </div>
                      )}

                      {selectedPeriod === 'weekly' && (
                        <div className="d-flex justify-content-end">
                          <div className='col-md-5 ms-4'>
                            <label htmlFor="weekly-start-date" className='mb-2'>Start Date:</label>
                            <input
                              type="date"
                              name="start_date"
                              id="weekly-start-date"
                              className="form-control"
                              required=""
                              value={startDate}
                              onChange={(e) => {
                                const selectedStartDate = e.target.value;
                                const sevenDaysLater = new Date(new Date(selectedStartDate).getTime() + 7 * 24 * 60 * 60 * 1000);
                                const formattedEndDate = sevenDaysLater.toISOString().split('T')[0];
                                setStartDate(selectedStartDate);
                                setEndDate(formattedEndDate);
                              }}
                            />
                          </div>

                          <div className='col-md-5 ms-4'>
                            <label htmlFor="weekly-end-date" className="mb-2">End Date:</label>
                            <input
                              type="date"
                              name="end_date"
                              id="weekly-end-date"
                              className="form-control"
                              required=""
                              min={startDate}
                              value={endDate}
                              onChange={(e) => {
                                const selectedEndDate = e.target.value;
                                const sevenDaysBefore = new Date(new Date(selectedEndDate).getTime() - 7 * 24 * 60 * 60 * 1000);
                                const formattedStartDate = sevenDaysBefore.toISOString().split('T')[0];
                                if (new Date(formattedStartDate) > new Date(startDate)) {
                                  setStartDate(formattedStartDate);
                                }
                                setEndDate(selectedEndDate);
                              }}
                            />
                          </div>
                        </div>
                      )}

                      {selectedPeriod === 'monthly' && (
                        <div className="d-flex justify-content-end">
                          <div className="col-md-5 ms-4">
                            <label htmlFor="startMonthPicker" className="mb-2">Start Month-Year:</label>
                            <input
                              className="form-control"
                              type="month"
                              id="startMonthPicker"
                              value={startMonth}
                              onChange={(e) => handleStartMonthChange(e.target.value)}
                            />
                          </div>
                          <div className="col-md-5 ms-4">
                            <label htmlFor="endMonthPicker" className="mb-2">End Month-Year:</label>
                            <input
                              className="form-control"
                              type="month"
                              id="endMonthPicker"
                              min={startMonth}
                              value={endMonth}
                              onChange={(e) => handleEndMonthChange(e.target.value)}
                            />
                          </div>
                        </div>
                      )}

                      {selectedPeriod === 'yearly' && (
                        <div className="d-flex justify-content-end">
                          <div className='col-md-5 ms-4'>
                            <label htmlFor="yearPicker" className='mb-2'>Start Year:</label>
                            <select
                              id='yearPicker'
                              className="form-control"
                              value={startYear.toString()}
                              onChange={(e) => setStartYear(parseInt(e.target.value))}
                            >
                              {getYearOptions().map(option => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className='col-md-5 ms-4'>
                            <label htmlFor="yearPickerEnd" className="mb-2">End Year:</label>
                            <select
                              id='yearPickerEnd'
                              className="form-control"
                              value={endYear.toString()}
                              onChange={(e) => setEndYear(parseInt(e.target.value))}
                            >
                              {getYearOptions().map(option => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}

                    </div>
                  </div>
                  <div className="row">
                    {filter == "payment_received" ? (
                      <DataTableExtensions columns={columns} data={data}>
                        <DataTable columns={columns} data={data} responsive pagination customStyles={customStyles} noDataComponent={<NoDataComponent/>}/>
                      </DataTableExtensions>
                    ) : filter == "credits" ? (
                      <DataTableExtensions columns={creditcolums} data={creditData}>
                        <DataTable columns={creditcolums} data={creditData} responsive pagination customStyles={customStyles} noDataComponent={<NoDataComponent/>}/>
                      </DataTableExtensions>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <TFooter_Dashboard />
    </>
  )
}
