import { configureStore } from "@reduxjs/toolkit";
import MasterSlice from "./slices/MasterSlice";

const store = configureStore({
    reducer: {
        master: MasterSlice,
    },
});

export default store;
