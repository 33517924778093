import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import OFooter_Dashboard from '../OInclude/OFooter_Dashboard'
import OHeader_Dashboard from '../OInclude/OHeader_Dashboard'
import { Helmet } from 'react-helmet'
import JqueryLoad from '../../../JqueryLoad/JqueryLoad'
import { calender_schedule_list, contact_us_details, notification_listing, org_calender_date_list, teacher_name_list } from '../../../api/apiHandler'
import dayjs from 'dayjs';
import { Calendar } from "@amir04lm26/react-modern-calendar-date-picker";

export default function ONotification({ isLoader }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const navigate = useNavigate();

  var [TeacherData, setTeacherData] = useState([]);

  const TeacherDetailData = () => {
    isLoader(true)
    contact_us_details().then((response) => {
      if (response?.code == 1) {
        setTeacherData(response?.data[0])
        isLoader(false)
      } else {
        setTeacherData([]);
        isLoader(false)
      }
    });
  }

  useEffect(() => {
    TeacherDetailData();
  }, []);

  var [teacherClassdata, setteacherClassData] = useState([]);
  var [QuesData, setQuesData] = useState([]);
  var [NewsData, setNewsData] = useState([]);
  const [learnerData, setLearnerData] = useState([]);
  var [TeacherId, setTeacherId] = useState("");

  const currentDate = dayjs();

  // Set the default value to the current year, month, and day
  const defaultValue = {
    year: currentDate.year(),
    month: currentDate.month() + 1,  // Month in Day.js starts from 0
    day: currentDate.date(),
  };
  const [selectedDay, setSelectedDay] = useState(defaultValue);
  const handleCalendarSelect = (day) => {
    setSelectedDay(day);
    // Do any other required actions when a date is selected
  };
  const handleSelectedLearner = (data) => {
    setTeacherId(data)
  }

  const learner_listing = () => {
    isLoader(true)
    teacher_name_list().then((response) => {
      if (response?.code == 1) {
        isLoader(false)
        setLearnerData(response?.data)
      } else {
        isLoader(false)
      }
    });
  }

  const [selectedLearnerSchedule, setSelectedLearnerSchedule] = useState("");
  const [scheduledDateList, setScheduledDateList] = useState([]);

  const getScheduledDates = () => {
    let body = {};
    if (selectedLearnerSchedule) body.learner_id = selectedLearnerSchedule;
    isLoader(true)
    org_calender_date_list(body).then((response) => {
      if (response?.code == 1) {
        isLoader(false)
        const scheduledDates = [];
        for (let item of response?.data) {
          if (item?.date) {
            let tempDate = item?.date?.split("-");
            scheduledDates.push({ year: +tempDate[0], month: +tempDate[1], day: +tempDate[2], className: "highlight-date" });
          }
        }
        setScheduledDateList(scheduledDates);
      } else {
        isLoader(false)
        setScheduledDateList([]);
      }
    });
  };

  useEffect(() => {
    getScheduledDates();
  }, [selectedLearnerSchedule]);

  const TeacherSchedule = () => {
    isLoader(true)
    calender_schedule_list({ "custom_date": selectedDay.year + "-" + selectedDay.month + "-" + selectedDay.day, "teacher_id": TeacherId }).then((response) => {
      if (response?.code == 1) {
        setteacherClassData(response?.data)
        isLoader(false)
      } else {
        setteacherClassData([])
        isLoader(false)
      }
    });
  }

  useEffect(() => {
    TeacherSchedule();
    learner_listing();
  }, [selectedDay, TeacherId]);

  const [notificationData, setNotificationData] = useState('');

  const NotificationList = async () => {
    try {
      isLoader(true)
      const response = await notification_listing();
      console.log('response123 :', response);
      if (response?.code == 1) {
        setNotificationData(response?.data)
        isLoader(false)
      } else {
        setNotificationData([])
        isLoader(false)
      }
    } catch (error) {
      console.log('error :', error);
    }
  }

  useEffect(() => {
    NotificationList();
  }, [])

  return (
    <>
      <JqueryLoad />
      <OHeader_Dashboard />
      <Helmet>
        <style>
          {`
                                    .drop_calendar.filter_box {
                                        min-width: 523px;
                                    }
                                    .Calendar__weekDays {
                                        color : #570861
                                    }
                                    .Calendar__day.-selected, .Calendar__day.-selectedStart, .Calendar__day.-selectedEnd {
                                        background: #FFC924;
                                        color: #212529;
                                    }
                                    .Calendar__day.-weekend:not(.-selected):not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
                                        color: #212529;
                                    }
                                    .Calendar__day.-selectedBetween {
                                        background: rgba(241, 241, 241, 0.65);
                                        color: #060706;
                                        border-radius: 0
                                    }
                                    .scrollable-content {
                                      max-height: 200px; /* Adjust the height as needed */
                                      overflow-y: auto;
                                    }    

                                    .scrollable-content::-webkit-scrollbar {
                                      width: 5px; /* Width of the scrollbar */
                                    }
                                    
                                    .scrollable-content::-webkit-scrollbar-thumb {
                                      background-color: #570861; /* Color of the thumb */
                                      border-radius: 10px; /* Rounded corners for the thumb */
                                    }
                                    
                                    .scrollable-content::-webkit-scrollbar-track {
                                      background-color: #f0f0f0; /* Color of the track */
                                    }


                                    .off_box.card .off_bg_img img.off_user {
                                      position: absolute;
                                      bottom: -22px;
                                      left: 20px;
                                      width: 65px;
                                      height: 65px;
                                      object-fit: cover;
                                      object-position: center;
                                      border-radius: 50%;
                                    }
                                    .highlight-date {
                                      font-weight: bolder;
                                      color: #570861 !important;

                                    }                             
                                    .Calendar__monthSelectorItem.-active .Calendar__monthSelectorItemText, .Calendar__yearSelectorItem.-active .Calendar__yearSelectorText {
                                      background-color: #570861;
                                      color: #fff;
                                  }
                                  
                                  
                                    `}
        </style>
      </Helmet>
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className="d-flex justify-content-between w-100 mt-lg-0 mt-4">
              <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span className="ms-2">Back</span>
                </span></a>
            </div>
            <div className="container-fluid">
              <div className="row dashboard_part">
                <div className="col-xl-9 right">
                  {/*start*/}
                  <div className="mb-5">
                    <h2 className="poppins fw-600 mb-3">Notifications</h2>
                    <p>Please see below notifications that require your attention.</p>
                  </div>
                  {/*end*/}

                  {/* {Array.isArray(notificationData) && notificationData.map((dateItem, index) => (
                    <div key={index}>
                      {dayjs(dateItem.date).isSame(dayjs(), 'day') && (
                        <span className="mb-4 d-block">Today</span>
                      )}
                      {dayjs(dateItem.date).isSame(dayjs().subtract(1, 'day'), 'day') && (
                        <span className="mb-4 d-block">Yesterday</span>
                      )}
                      {!dayjs(dateItem.date).isSame(dayjs(), 'day') &&
                        !dayjs(dateItem.date).isSame(dayjs().subtract(1, 'day'), 'day') && (
                          <span className="mb-4 d-block">{dateItem.date}</span>
                        )}
                      <div className="list_notification">
                        <ul>
                          {dateItem.notification_data.map((notification, index) => (
                            <li key={index} className="d-flex mb-4">
                              <div className="notif_left d-flex align-items-center">
                                <div className={`noti_img bg-${notification.tag === 'add_class' ? 'pink' : notification.tag === 'quiz' ? 'diff-yellow-op-1' : 'sky-blue-op-1'} position-relative`}>
                                  <span className={`text-uppercase text-${notification.tag === 'add_class' ? 'danger' : notification.tag === 'quiz' ? 'yellow' : 'blue'}`}>{notification.message.charAt(0)}</span>
                                  <span className="notifi_logs">
                                    <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M10 4.5V9C10 9.13261 9.94732 9.25979 9.85355 9.35355C9.75979 9.44732 9.63261 9.5 9.5 9.5H0.5C0.367392 9.5 0.240215 9.44732 0.146447 9.35355C0.0526784 9.25979 0 9.13261 0 9V4.5H10ZM10 2.5H0V1C0 0.867392 0.0526784 0.740215 0.146447 0.646447C0.240215 0.552678 0.367392 0.5 0.5 0.5H9.5C9.63261 0.5 9.75979 0.552678 9.85355 0.646447C9.94732 0.740215 10 0.867392 10 1V2.5Z" fill="#3F3F44" />
                                    </svg>
                                  </span>
                                </div>
                                <p className="ms-3 noti_text">{notification.message}</p>
                              </div>
                              <span className="noti_timer">
                                {dayjs(notification.insert_datetime).format('hh:mm A')}
                              </span>
                            </li>
                          ))}                          
                        </ul>
                      </div>
                    </div>
                  ))} */}


                  {Array.isArray(notificationData) && notificationData.map((dateItem, index) => (
                    <div key={index}>
                      {dayjs(dateItem.date).isSame(dayjs(), 'day') ? (
                        <span className="mb-4 d-block">Today</span>
                      ) : dayjs(dateItem.date).isSame(dayjs().subtract(1, 'day'), 'day') ? (
                        <span className="mb-4 d-block">Yesterday</span>
                      ) : (
                        <span className="mb-4 d-block">{dateItem.date}</span>
                      )}
                      <div className="list_notification">
                        <ul>
                          {dateItem.notification_data.map((notification, index) => {
                            const notificationContent = (
                              <>
                                <div className={`noti_img bg-${notification.tag === 'add_class' ? 'pink' : notification.tag === 'quiz' ? 'diff-yellow-op-1' : 'sky-blue-op-1'} position-relative`}>
                                  <span className={`text-uppercase text-${notification.tag === 'add_class' ? 'danger' : notification.tag === 'quiz' ? 'yellow' : 'blue'}`}>{notification.message.charAt(0)}</span>
                                  <span className="notifi_logs">
                                    <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M10 4.5V9C10 9.13261 9.94732 9.25979 9.85355 9.35355C9.75979 9.44732 9.63261 9.5 9.5 9.5H0.5C0.367392 9.5 0.240215 9.44732 0.146447 9.35355C0.0526784 9.25979 0 9.13261 0 9V4.5H10ZM10 2.5H0V1C0 0.867392 0.0526784 0.740215 0.146447 0.646447C0.240215 0.552678 0.367392 0.5 0.5 0.5H9.5C9.63261 0.5 9.75979 0.552678 9.85355 0.646447C9.94732 0.740215 10 0.867392 10 1V2.5Z" fill="#3F3F44" />
                                    </svg>
                                  </span>
                                </div>
                                <p className="ms-3 noti_text">
                                  {notification.tag === "admin_notification"
                                    ? `Admin has sent you a notification: ${notification.title} - ${notification.message}`
                                    : notification.tag === "admin_message"
                                      ? `Admin sent you a message, please check your mail: ${notification.title} - ${notification.message}`
                                      : `${notification.message}`}
                                </p>
                              </>
                            );

                            let linkTo = '';
                            let linkState = {};

                            if (notification.tag === 'add_class') {
                              linkTo = '/organization-edit-class';
                              linkState = notification.action_id;
                            } else if (notification.tag === 'add_club') {
                              linkTo = '/organization-edit-club';
                              linkState = notification.action_id;
                            } else if (notification.tag === 'add_quiz') {
                              linkTo = '/organization-edit-quiz';
                              linkState = { id: notification.action_id }
                            } else if (notification.tag === 'add_competition') {
                              linkTo = '/organization-edit-competitation';
                              linkState = notification.action_id;
                            } else if (notification.tag === 'classroom_add') {
                              linkTo = '/organization-view-details-classroom';
                              linkState = { id: notification?.teach_id, type: notification?.action_type }
                            } else if (notification.tag === 'homework_add') {
                              linkTo = '/organization-view-details-page';
                              linkState = { id: notification.teach_id, type: notification.teach_type }
                            } else if (notification.tag === 'assesment_add') {
                              linkTo = '/organization-view-details-page';
                              linkState = { id: notification.teach_id, type: notification.teach_type }
                            } else if (notification.tag === 'discussion_add') {
                              linkTo = '/organizaion-edit-disscussion';
                              linkState = notification.teach_id;
                            } else if (notification.tag === 'assetment_due') {
                              linkTo = '/organizaion-assessment-response';
                              linkState = { assessment_id: 71, id: { id: 59, class: "class" } }
                            } else if (notification.tag === 'homework_due') {
                              linkTo = '/organizaion-homework-response';
                              linkState = { homework_id: 142, id: { id: 59, class: "class" } }
                            } else if (notification.tag === "edit_class") {
                              linkTo = '/organization-edit-class';
                              linkState = notification.action_id;
                            }

                            return (
                              <li key={index} className="d-flex mb-4">
                                {notification.shouldRedirect !== false ? (
                                  <Link
                                    to={linkTo} state={linkState}
                                    className="notif_left d-flex align-items-center"
                                    style={{ textDecoration: 'none', color: 'inherit' }} 
                                  >
                                    {notificationContent}
                                  </Link>
                                ) : (
                                  <div className="notif_left d-flex align-items-center">
                                    {notificationContent}
                                  </div>
                                )}
                                <span className="noti_timer">
                                  {dayjs(notification.created_at).format('hh:mm A')}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  ))}

                </div>
                <div className="col-xl-3 left position-relative">
                  <div className="mb-5">
                    <div className="avatar-upload">
                      <div className="avatar-edit">
                        {/* <input id="imageUpload" accept=".png, .jpg, .jpeg" />
                        <label htmlFor="imageUpload" data-bs-toggle="modal" data-bs-target="#avtar_kids" /> */}
                      </div>
                      <div className="avatar-preview">
                        <div id="imagePreview" style={{ backgroundImage: TeacherData.profile_image ? `url(${TeacherData.profile_image})` : 'url("https://hlink-bhavinp-s3.s3.amazonaws.com/student_scholar/profile_image/default.jpeg")' }}>
                        </div>
                      </div>

                    </div>
                    <div className="text-center avatar_info">
                      <Link to="/organization-profile-details" state={TeacherData.id} className="text-dif-black fw-500">{TeacherData.user_name}</Link>
                      <p className="text-gray">Code:#{TeacherData.code_generate}</p>
                    </div>
                  </div>
                  <div className="wrappers" style={{ marginLeft: '30px' }}>
                    <Calendar
                      value={selectedDay}
                      onChange={(day) => {
                        handleCalendarSelect(day);
                        // Close the dropdown if needed
                      }}
                      shouldHighlightWeekends
                      customDaysClassName={scheduledDateList}

                    />
                  </div>
                  <div className="sche_sec">
                    <div className="title">
                      <h2 className="mb-4 fw-600 poppins">Schedule</h2>
                      <div className="text-end">
                        <div className="mb-3">
                          <span className='custom-select'>
                            <select onChange={(e) => handleSelectedLearner(e.target.value)} className="bg-purple border-purple fs-6 rounded-cricle rounded-pill ms-sm-3">
                              <option value='' selected >Teacher's Name</option>
                              {learnerData?.map((student, index) => {
                                return (
                                  <option key={index} value={student?.id}>{student?.full_name}</option>
                                )
                              })}
                            </select>
                          </span>

                        </div>
                      </div>
                    </div>
                    <div className="schedule_list">
                      <ul>
                        {teacherClassdata?.length > 0 ?
                          teacherClassdata?.map((ClassData, index) => {
                            return (
                              <li key={index}>
                                <Link to="/organization-tech-detail" state={{ id: ClassData.class_id, class: "class" }} className="d-flex align-items-center justify-content-between">
                                  <div className>
                                    <h3>{ClassData.title}<span></span></h3>
                                    <p>Time : {ClassData.format_time}</p>
                                    <p>Date : {ClassData.format_date}</p>
                                  </div>
                                  <div className="arrs">
                                    <svg width={7} height={12} viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M1.19727 10.6209L5.0006 6.81753C5.44977 6.36836 5.44977 5.63336 5.0006 5.18419L1.19727 1.38086" stroke="#131313" strokeOpacity="0.4" strokeWidth="1.5" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                  </div>
                                </Link>
                              </li>
                            )
                          }) :
                          <h4 className="d-flex justify-content-center" style={{ "color": "#570861", "fontSize": '18px' }}>Data Not Found</h4>
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*end-here*/}
      </main>
      <Helmet>
        <script src="./assets/js/custom_calendar.js"></script>
      </Helmet>
      <OFooter_Dashboard />
    </>
  )
}
