import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { convertToBase64, TOAST_SUCCESS, TOAST_ERROR } from '../../../utils/common.service';
import { learner_name_list, parents_class_feedback_details, student_list, teacher_feedback_form } from '../../../api/apiHandler';
import { uploadImageOnAWS } from '../../aws/Service';
import queryString from 'query-string';
import CryptoJS from 'crypto-js';
var key = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNfTmDXHWVkQWdanYOm');
var iv = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNf');
export default function Feedback({ isLoader }) {

  const location = useLocation();

  // var id = location?.state;
  // console.log('idFeedback :', id);
  var after = useParams();
  if (location?.search) {
    const queryParams = new URLSearchParams(location?.search);
    const encryptedData = queryParams.get('data');

    // Define your key and IV (must be the same as used for encryption)
    // const key = CryptoJS.enc.Utf8.parse('your-key-here');
    // const iv = CryptoJS.enc.Utf8.parse('your-iv-here');

    // Decode and decrypt the data
    const decryptedData1 = CryptoJS.AES.decrypt(decodeURIComponent(encryptedData), key, { iv: iv }).toString(CryptoJS.enc.Utf8);
    console.log('decryptedData1 :', decryptedData1);
    const params = new URLSearchParams(decryptedData1);
    console.log('params :', params);

    id = {
      id: parseInt(params?.get('activityId'), 10),  // Assuming id is a number
      class: params?.get('typeClass'),
      type_class: params?.get('type'),
    };;
    console.log('idxyz1 :', id);
    var edit_class_id = id?.id;
    // setDataType(id?.type)
  } else if (location?.state) {
    var id = location?.state;
    console.log('idxyz :', id);
    var edit_class_id = id?.id;
  } else {
    // const { id } = useParams();
    // var type = location?.search?.split("=")[1];
    // console.log('type :', type);
    // var id = after
    // id.class = type
    // console.log('idtype :', id);
    // const queryParams = queryString.parse(location.search);

    // // Extract specific query parameters
    // const type = queryParams.type;

    // const typeClass = queryParams?.type_class;

    // var id = after
    // id.class = type
    // id.type_class = typeClass
    var decryptedData = CryptoJS.AES.decrypt(decodeURIComponent(location?.pathname?.split('/')[2]), key, { iv: iv }).toString(CryptoJS.enc.Utf8);
    var descryptedJson = decryptedData.split('?')
    const queryParams = queryString.parse(descryptedJson[1]);
    console.log('queryParams :', queryParams);
    const type = queryParams?.type;
    const typeClass = queryParams?.type_class;
    var id = {}
    var edit_class_id = descryptedJson[0]
    id.id = descryptedJson[0]
    id.class = type
    id.type_class = typeClass
  }

  const schema = yup.object().shape({
    teacher_name: yup.string().required("Please enter teacher name"),
    class_name: yup.string().required("Please enter class name"),
    url_link: yup.string().required("Please enter URL"),
    description: yup.string().required("Please enter description"),
    learnerId: yup
      .object({
        id: yup.string().required("Please select a learner"),
      })
      .required("Please select a learner"),
      day_time: yup.string().required("Please select the day and time of class"),  // added validation
      date: yup.string().required("Please select the date"),  // added validation
  });

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });
  console.log('errors :', errors);

  const [profileImageState1, setProfileImageState1] = useState('')
  // console.log("profileImageState1", profileImageState1)

  const handleImage1Change = async (image) => {
    if (image != null) {
      TOAST_SUCCESS("Your attachment has been uploaded!");
    }
    // console.log('image :', image);
    setValue("attach1", image);
    const bufferImage = await convertToBase64(image);
    setProfileImageState1(bufferImage);
    clearErrors("attach1");
  }
  // console.log("hello", watch("attachments_data"))

  const [profileImageState2, setProfileImageState2] = useState('')
  // console.log("profileImageState2", profileImageState2)

  const handleImage2Change = async (image) => {
    if (image != null) {
      TOAST_SUCCESS("Your attachment has been uploaded!");
    }
    // console.log('image :', image);
    setValue("attach2", image);
    const bufferImage = await convertToBase64(image);
    setProfileImageState2(bufferImage);
    clearErrors("attach2");
  }
  // console.log("hello2", watch("attachments_data"))

  const [profileImageState3, setProfileImageState3] = useState('')
  // console.log("profileImageState3", profileImageState3)

  const handleImage3Change = async (image) => {
    if (image != null) {
      TOAST_SUCCESS("Your attachment has been uploaded!");
    }
    // console.log('image :', image);
    setValue("attach3", image);
    const bufferImage = await convertToBase64(image);
    setProfileImageState3(bufferImage);
    clearErrors("attach3");
  }

  const selectedLearnerId = watch("learnerId");

  let onSubmit = async (data) => {
    console.log('data :', data);

    if (!selectedLearnerId) {
      TOAST_ERROR("Please select a learner");
      return;
    }

    let attachment1 = data?.attach1
    let attachment2 = data?.attach2
    let attachment3 = data?.attach3
    var attachments = []
    if (data.attach1) {
      attachment1 = await uploadImageOnAWS(attachment1, "Feedback_Image");
      attachments.push({ 'attachment': attachment1 })
    }
    if (data.attach2) {
      attachment2 = await uploadImageOnAWS(attachment2, "Feedback_Image");
      attachments.push({ 'attachment': attachment2 })
    }

    if (data.attach3) {
      attachment3 = await uploadImageOnAWS(attachment3, "Feedback_Image");
      attachments.push({ 'attachment': attachment3 })
    }

    let feedback_data = {
      class_id: id.id,
      type_id: learnerId.id,
      type: learnerId.type,
      teacher_name: data.teacher_name,
      class_name: data.class_name,
      student_name: learnerId.studentName,
      class_link: data.url_link,
      time_of_class: data.day_time,
      date: data.date,
      feedback_type: data.type,
      feedback_details: data.description,
      attachments: attachments
    }
    isLoader(true)
    teacher_feedback_form(feedback_data).then((response) => {
      if (response?.code == 1) {
        isLoader(false)
        TOAST_SUCCESS(response?.message);
        reset();
        setProfileImageState1("")
        setProfileImageState2("")
        setProfileImageState3("")
      } else {
        isLoader(false)
        TOAST_ERROR(response?.message);
      }
    });
  }

  var [ClassData, setClassData] = useState([]);
  const ClassDetailsofTeacher = () => {
    isLoader(true)
    parents_class_feedback_details({ class_id: id.id, class_type: id.class }).then((response) => {
      if (response?.code == 1) {
        isLoader(false)
        setClassData(response?.data)
        reset({
          teacher_name: response?.data?.teacher_name,
          class_name: response?.data?.title,
          url_link: `https://studentscholars.com/teach-detail/${id?.id}?type=${id?.class}`
        });
      } else {
        isLoader(false)
      }
    });
  }

  useEffect(() => {
    ClassDetailsofTeacher();
  }, [])

  var [StudentData, setStudentData] = useState([]);
  const StudentList = () => {
    isLoader(true)
    student_list({ activity_id: id.id, activity_type: "class" }).then((response) => {
      if (response?.code == 1) {
        isLoader(false)
        setStudentData(response?.data)
      } else {
        isLoader(false)
      }
    });
  }

  useEffect(() => {
    StudentList();
  }, [])

  const handleSelectedLearner = (data) => {
    const selectedLearner = JSON.parse(data);
    setLearnerId(selectedLearner);
    setValue("learnerId", selectedLearner);  // Update form value
    clearErrors("learnerId")
  };


  const [learnerId, setLearnerId] = useState([]);

  const [learnerData, setLearnerData] = useState([]);
  const learner_listing = () => {
    isLoader(true)
    learner_name_list({ activity_id: id.id, activity_type: id.class }).then((response) => {
      if (response?.code == 1) {
        setLearnerData(response?.data)
        isLoader(false)
      } else {
        isLoader(false)
      }
    });
  }
  useEffect(() => {
    learner_listing();
  }, []);

  return (
    <>
      {/* <div className="tab-pane fade" id="pills-feedback" role="tabpanel" aria-labelledby="pills-feedback-tab">  */}
      {/*start*/}
      <div className="row">
        <div className="col-lg-6 mb-4">
          <form onSubmit={handleSubmit(onSubmit)} className="custom_form mt-4" action id>
            <div className="form-group mb-4">
              <label className="mb-2">Teacher’s Name</label>
              <input type="text"  {...register("teacher_name")} className="form-control" name="teacher_name" disabled />
              <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.teacher_name?.message}</span></p>

            </div>
            <div className="form-group mb-4">
              <label className="mb-2">Name of Class</label>
              <input type="text" {...register("class_name")} name="class_name" className="form-control" placeholder disabled />
              <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.class_name?.message}</span></p>

            </div>
            <div className="form-group mb-4">
              <label className="mb-2">Learner's Name</label>
              <select
                className="form-select"
                onChange={(e) => handleSelectedLearner(e.target.value)}
                aria-label="Default select example"
              >
                <option value="" selected disabled>
                  Learner’s Name
                </option>
                {learnerData?.map((student, index) => {
                  const type = student?.learner_id ? 'learner' : 'user';
                  const id = student?.learner_id || student?.user_id;
                  const studentName = student?.learner_name || student?.user_name;
                  const valueObj = JSON.stringify({ type, id, studentName });
                  return (
                    <option key={index} value={valueObj}>
                      {studentName}
                    </option>
                  );
                })}
              </select>
              {errors.learnerId?.id && (
                <p className="text-danger">{errors.learnerId.id.message}</p>
              )}

            </div>

            <div className="form-group mb-4">
              <label className="mb-2">URL link to the class</label>
              <input type="text" {...register("url_link")} className="form-control" name="url_link" placeholder disabled />
              <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.url_link?.message}</span></p>

            </div>
            <div className="form-group mb-4">
              <label className="mb-2">Day and Time of Class</label>
              <select className="form-select" {...register("day_time")} aria-label="Default select example">
                <option value='' selected disabled>Select Day and Time of Class</option>

                {ClassData?.meetings?.map((dayTime, index) => {
                  return (
                    <option key={index} value={dayTime.schedule}>{dayTime.schedule}</option>
                  )
                })}

              </select>
              {errors.day_time && (
                <p className="text-danger">{errors.day_time.message}</p>
              )}
            </div>
            <div className="form-group mb-4">
              <label className="mb-2">Date</label>
              <select className="form-select" {...register("date")} aria-label="Default select example">
                <option value='' selected disabled>Select Date</option>
                {ClassData?.meetings?.map((DateData, index) => {
                  return (
                    <option value={DateData.date}>{DateData.date}</option>
                  )
                })}
              </select>
              {errors.date && (
                <p className="text-danger">{errors.date.message}</p>
              )}
            </div>
            <div className="form-group mb-4">
              <label className="mb-2">Type</label>
              <select className="form-select pe-5" {...register("type")} aria-label="Default select example">
                <optgroup label="Attendance">
                  <option>Student cancellation within 24 hours of class</option>
                  <option>Student Cancelling remaining session(s) after class begins</option>
                  <option>Multiple student cancellations 24 hours before a class begins</option>
                  <option>Missing or failing to attend Session(s)</option>
                  <option>Repeatedly arriving late or leaving early</option>
                </optgroup>
                <optgroup label="Classroom Behaviour">
                  <option>Using inappropriate language or gestures</option>
                  <option>Bullying other students or teacher</option>
                  <option>Not providing regular feedback or answering questions</option>
                </optgroup>
                <optgroup label="Communication">
                  <option>Parent/student reviews or comments </option>
                  <option>Not responding to messages from learners who are enrolled in a class</option>
                  <option>Failing to refund</option>
                </optgroup>
                <optgroup label="Strictly Prohibited">
                  <option>Disregarding learner or parent safety or privacy</option>
                  <option>Using an account or unapproved third party platform to teach live sessions, rather than using the services</option>
                  <option>Making payment (other than those collected through StudentScholars)</option>
                  <option>Requesting student’s/teacher’s phone number and email to contact them privately.?</option>
                  <option>Sharing your phone number and email with the student/teacher for them to contact you
                    Privately.</option>
                  <option className="fw-600">Other</option>
                </optgroup>
              </select>
            </div>
            {/* <div class="form-group mb-4">
										<label class="mb-2">Sub category of Incident (predefined list)</label>
										<select class="form-select" aria-label="Default select example">
											<option selected="">Lorem ipsum dolor sit amet</option>
											<option value="1">Lorem ipsum dolor sit amet.</option>
											<option value="2">sed do eiusmod ut labore et dolore magna aliqua</option>
											<option value="3">sed do eiusmod ut labore et dolore.</option>
										</select>
									</div> */}
            <div className="form-group mb-4">
              <label className="mb-2">Please describe the feedback in detail</label>
              <textarea className="form-control" {...register("description")} name="description" rows={6} />
              <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.description?.message}</span></p>

            </div>
            <div className>
              <label className="mb-2">Attachments</label>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group mb-lg-0 mb-4  file_upload">
                    <Controller name="attach1" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                      <>
                        <div className="file_update position-relative">
                          <div className="d-flex align-items-center justify-content-center">
                            <img className="image" onClick={() => {
                              document.getElementById("attach1")?.click()
                            }} src={profileImageState1 ? profileImageState1 : ""} alt="" style={{
                              width: profileImageState1 ? "100%" : "",
                              height: profileImageState1 ? "166px" : "",
                            }} />
                          </div>
                          <input type="file" id='attach1' name="attach1" onInput={(e) => handleImage1Change(e.target.files[0])} className="form-control" style={{ display: profileImageState1 ? "none" : "" }} accept="image/*" />
                          {profileImageState1 ? "" :
                            <span className="file_icons position-absolute">
                              <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                              </svg>
                            </span>
                          }
                        </div>
                      </>
                    )} />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mb-lg-0 mb-4  file_upload">
                    <Controller name="attach2" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                      <>
                        <div className="file_update position-relative">
                          <div className="d-flex align-items-center justify-content-center">
                            <img className="image" onClick={() => {
                              document.getElementById("attach2")?.click()
                            }} src={profileImageState2 ? profileImageState2 : ""} alt="" style={{
                              width: profileImageState2 ? "100%" : "",
                              height: profileImageState2 ? "166px" : "",
                            }} />
                          </div>
                          <input type="file" id='attach2' name="attach2" onInput={(e) => handleImage2Change(e.target.files[0])} className="form-control" style={{ display: profileImageState2 ? "none" : "" }} accept="image/*" />
                          <span className="file_icons position-absolute">
                            <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                            </svg>
                          </span>
                        </div>
                      </>
                    )} />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mb-lg-0 mb-4  file_upload">
                    <Controller name="attach3" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                      <>
                        <div className="file_update position-relative">
                          <div className="d-flex align-items-center justify-content-center">
                            <img className="image" onClick={() => {
                              document.getElementById("attach3")?.click()
                            }} src={profileImageState3 ? profileImageState3 : ""} alt="" style={{
                              width: profileImageState3 ? "100%" : "",
                              height: profileImageState3 ? "166px" : "",
                            }} />
                          </div>
                          <input type="file" id='attach3' name="attach3" onInput={(e) => handleImage3Change(e.target.files[0])} className="form-control" style={{ display: profileImageState3 ? "none" : "" }} accept="image/*" />
                          <span className="file_icons position-absolute">
                            <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                            </svg>
                          </span>
                        </div>
                      </>
                    )} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className>
                {/* <p class="text-gray fw-600">StudentScholars Support team will get back to you within 48 hours. If you do not hear back us, please send us a direct message to <a href="#" class="text-dark">support@studentscholars.com</a></p> */}
                <p className="text-gray fw-600">StudentScholars Support team will get back to you.</p>
              </div>
              <div className="button mt-4">
                <button className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block">Submit</button>
              </div>
            </div>
          </form>
        </div>

      </div>
      {/*end*/}
      {/* </div> */}
    </>
  )
}
