import React, { useState, useEffect, useRef } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Modals from 'react-modal';
import ClassRoom from './ClassRoom'
import Header from './Header'
import JqueryLoad from '../../../JqueryLoad/JqueryLoad'
import Homework from './Homework'
import Assessment from './Assessment'
import Quizzes from './Quizzes'
import Disscussion from './Disscussion'
import Recording from './Recording'
import Messages from './Messages'
import Review from './Review'
import ProgressReport from './ProgressReport'
import Feedback from './Feedback'
import { accept_reject_refund_request, classroom_list, class_detail_of_teacher, class_option_payment_list, learner_name_list, refund_data_list, refund_payment_give, start_pause_subscriptions, start_stop_pause_subcriptions, teacher_club_details, teacher_competition_details, transfer_schedule_accept, transfer_schedule_list } from '../../../api/apiHandler'
import OHeader_Dashboard from '../OInclude/OHeader_Dashboard'
import OFooter_Dashboard from '../OInclude/OFooter_Dashboard'
import { TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/common.service';
import dayjs from 'dayjs'
import { Video_URL } from '../../../Config';

var COMMON_STYLE_FOR_POPUP = {
  content: {
    // backgroundColor: 'rgb(0 0 0 / 56%)',
    background: 'none',
    border: '0px',
  },
}

export default function OTeachDetails({ isLoader }) {
  const handleStepChange = (newStep) => setStep(newStep);

  const navigate = useNavigate();
  const location = useLocation();

  // var id = location?.state;
  // console.log('idxyz :', id); 
  // var edit_class_id = id?.id;

  var after = useParams();
  if (location?.state) {
    var id = location?.state;
    console.log('idxyz :', id);
    var edit_class_id = id?.id;
  } else {
    // const { id } = useParams();
    var type = location?.search?.split("=")[1];
    console.log('type :', type);
    var id = after
    id.class = type
    console.log('idtype :', id);
  }

  localStorage.setItem("storeData", JSON.stringify(id));
  const [step, setStep] = useState((location?.state?.type) ? 7 : parseInt(localStorage.getItem("Teach_Step", 1)) ? parseInt(localStorage.getItem("Teach_Step", 1)) : 1);
  console.log('step :', step);

  var [data, setData] = useState([]);
  console.log('data :', data);

  const class_details = (classType) => {
    if (classType == 'class') {
      isLoader(true)
      class_detail_of_teacher({ classes_id: id.id }).then((response) => {
        console.log('class_detail_of_teacher :', response);
        if (response?.code == 1) {
          setData(response.data[0]);
          isLoader(false)
        } else {
          isLoader(false)
            ;
        }
      });
    } else if (classType == 'club') {
      isLoader(true)
      teacher_club_details({ club_id: id.id }).then((response) => {
        if (response?.code == 1) {
          setData(response.data[0]);
          isLoader(false)
        } else {
          isLoader(false)
            ;
        }
      });
    } else if (classType == 'competition') {
      isLoader(true)
      teacher_competition_details({ competition_id: id.id }).then((response) => {
        if (response?.code == 1) {
          setData(response.data[0]);
          isLoader(false)
        } else {
          isLoader(false)
            ;
        }
      });
    }
  };

  useEffect(() => {
    class_details(id?.class);
  }, []);

  useEffect(() => {
    localStorage.setItem("Teach_Step", step)
  }, [step, parseInt(localStorage.getItem("Teach_Step", 1))]);

  console.log('parseInt(localStorage.getItem("Teach_Step",1)) :', localStorage.getItem("Teach_Step", 1));
  const renderStep = () => {
    switch (localStorage.getItem("Teach_Step", 1) ? parseInt(localStorage.getItem("Teach_Step", 1)) : 1) {
      case 1:
        return (
          <ClassRoom id={id} />
        );
      case 2:
        return (
          <Homework id={id} />
        );
      case 3:
        return (
          <Assessment id={id} />
        );
      case 4:
        return (
          <Quizzes id={id} />
        );
      case 5:
        return (
          <Disscussion id={id} />
        );
      case 6:
        return (
          <Recording id={id} />
        );
      case 7:
        return (
          <Messages id={id} />
        );
      case 8:
        return (
          <Review id={id} />
        );
      case 9:
        return (
          <ProgressReport id={id} />
        );
      case 10:
        return (
          <Feedback id={id} />
        );

      default:
        return null;
    }
  };


  const schema = yup.object().shape({
    // learner_name: yup.string().required("Learner's name is required"),
    select_section: yup.string().required("Select Section is required"),

  });

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });


  const [learnerData, setLearnerData] = useState([]);
  const [scheduleId, setScheduleId] = useState({});
  console.log('scheduleIdmain :', scheduleId);
  const [lName, setLname] = useState("");


  const learner_listing = () => {
    isLoader(true)

    learner_name_list({ activity_id: id?.id, activity_type: id?.class }).then((response) => {
      console.log('learner_name_list :', response);
      if (response?.code == 1) {
        isLoader(false)
        setLearnerData(response?.data)
        setScheduleId(response?.data[0]?.learner_id ? { id: response?.data[0]?.learner_id, type: "learner" } : { id: response?.data[0]?.user_id, type: "user" })
        setLname(response?.data[0]?.learner_name ? response?.data[0]?.learner_name : response?.data[0]?.user_name)
      } else {
        isLoader(false)
        setScheduleId(0)

      }
    });
  }
  useEffect(() => {
    learner_listing();
  }, []);

  // const handleSelectedLearner = (data) => {
  //   console.log('data1234 :', data);
  //   if (data) {
  //     clearErrors('learner_name')
  //   }
  //   if (data != "" && data != undefined){

  //     var main = JSON.parse(data);
  //     setScheduleId(JSON.parse(data))

  //     PaymentList(id?.id, id?.class);
  //   }
  //   // setLname(JSON.parse(data?.l_name))
  //   // TransferScheduleList();  
  //   // RefundList();
  //   console.log('main :', main);

  // }
  const paymentListCalled = useRef(false);
  const handleSelectedLearner = (data) => {
    console.log('data1234 :', data);
    if (data) {
      clearErrors('learner_name');
    }

    if (data !== "" && data !== undefined) {
      var main = JSON.parse(data);

      // Update scheduleId and set a flag to call PaymentList after the state is updated
      setScheduleId(main);
      paymentListCalled.current = true;

      console.log('main :', main);
    }
  };

  useEffect(() => {
    if (paymentListCalled.current) {
      PaymentList(id?.id, id?.class);
      paymentListCalled.current = false; // Reset the flag
    }
  }, [scheduleId]);


  const [teacherSchedule, setTeacherSchedule] = useState([]);

  const TransferScheduleList = () => {
    isLoader(true)
    transfer_schedule_list({ activity_id: id?.id, activity_type: id?.class }).then((response) => {
      // console.log("transfer_schedule_list", response)
      if (response?.code == 1) {
        isLoader(false)
        setTeacherSchedule(response?.data)
      } else {
        isLoader(false)
      }
    });
  }
  useEffect(() => {
    TransferScheduleList();
  }, []);

  let onSubmit = async (data) => {
    // console.log('dataqqqq :', data);
    try {
      let send_data = {
        activity_id: id.id,
        activity_type: id.class,
        meeting_id: data.select_section
      }
      if (scheduleId != null && scheduleId != undefined && scheduleId != {}) {
        if (scheduleId.type == 'user') {
          send_data.userId = scheduleId.id
        } else {
          send_data.learnerId = scheduleId.id
        }
      }
      // console.log('send_data :', send_data);
      isLoader(true)
      const response = await transfer_schedule_accept(send_data);
      if (response?.code == 1) {
        isLoader(false)
        TOAST_SUCCESS(response.message)
        reset();
      } else {
        isLoader(false)
        TOAST_ERROR(response.message)
      }
    } catch (error) {
      console.log('error :', error);
    }
  }

  const [modalIsOpen, setIsOpen] = useState(false);
  const [activityId, setActivityId] = useState('');
  console.log('activityId :', activityId);
  const [activityType, setActivityType] = useState('');
  const [paymentListData, setPaymentListData] = useState([]);
  console.log('paymentListData :', paymentListData);

  let ClassOptions = async (activity_id, activity_type) => {
    console.log("activity_id", activity_id)
    setActivityId(activity_id)
    setActivityType(activity_type)
    // setUserId(user_id)
    // setIsOpen(true)
  };

  const PaymentList = async (activity_id, activity_type) => {
    // alert("aaaa")
    console.log('activity_type1 :', activity_type);
    console.log('activity_id1 :', activity_id);

    setActivityId(activity_id)
    setActivityType(activity_type)
    try {
      let send_data = {
        activity_id: id?.id,
        activity_type: id?.class
      }
      if (scheduleId != null && scheduleId != undefined && scheduleId != {}) {
        if (scheduleId.type == 'user') {
          send_data.userId = scheduleId.id
        } else {
          send_data.learnerId = scheduleId.id
        }
      }
      console.log('send_data123 :', send_data);
      isLoader(true)
      const response = await class_option_payment_list(send_data);
      console.log("class_option_payment_list", response)

      if (response?.code == 1) {
        isLoader(false)
        setPaymentListData(response?.data)
        setIsOpen(true)
        TransferScheduleList();
        RefundList();
      } else {
        isLoader(false)
        setPaymentListData([])
        setIsOpen(true)
        TransferScheduleList();
        RefundList();
      }

    } catch (error) {
      console.log("error", error)
    }
  }

  const [RefundReason, setRefundReason] = useState("Teacher was not prepared for the lesson")
  const [RefundReasonstopsub, setRefundReasonStopSub] = useState("Teacher was not prepared for the lesson")

  const refundReason = (data) => {
    setRefundReason(data)
  }

  const StopSub = (data) => {
    setRefundReasonStopSub(data)
  }

  const schema2 = yup.object().shape({

  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    clearErrors: clearErrors2,
    reset: reset2,
    watch: watch2,
    control: control2,
    getValues: getValues2,
    formState: { errors: errors2 },
  } = useForm({
    resolver: yupResolver(schema2), // Pass the schema2 resolver
  });

  // const onSubmit2 = async (data, index) => {
  //   console.log('index :', index);
  //   console.log('dataonSubmit2 :', data);
  //   try {
  //     let send_data = {
  //       activity_id: id?.id,
  //       activity_type: id?.class,
  //       refund_amount: data[`rPrice_${index}`],
  //       meeting_id: data[`meetingId_${index}`],
  //       reason_id: data[`Refund_Reason_${index}`],
  //     }
  //     if (data[`userId_${index}`] != null && data[`userId_${index}`] != undefined && data[`userId_${index}`] != "") {
  //       send_data.userId = data[`userId_${index}`]
  //     } else {
  //       send_data.learnerId = data[`learnerId_${index}`]
  //     }
  //     console.log('send_datarefund_payment_give :', send_data);
  //     const response = await refund_payment_give(send_data);
  //     console.log('responseeeee :', response);
  //     if (response?.code == 1) {
  //       TOAST_SUCCESS(response.message)
  //       RefundList()
  //     } else {
  //       TOAST_ERROR(response.message)
  //     }
  //   } catch (error) {
  //     console.log('error :', error);
  //   }
  // }

  const onSubmit2 = async (data, index) => {
    console.log('index :', index);
    console.log('dataonSubmit2 :', data);
    console.log("activity_type2", activityType)
    if (data[`actionType_${index}`] == "Accepted") {

      try {
        let send_data = {
          requested_user_id: scheduleId.id,
          activity_id: activityId,
          activity_type: activityType,
          amount: data[`rPrice_${index}`],
          meeting_id: data[`meetingId_${index}`],
          reason_id: data[`Refund_Reason_${index}`],
          refund_request_status: data[`actionType_${index}`],
          payment_intent_id: data[`transaction_Id_${index}`],
          userId: data[`userId_${index}`],
        }

        // if (data[`userId_${index}`] != null && data[`userId_${index}`] != undefined && data[`userId_${index}`] != "") {
        //   send_data.userId = data[`userId_${index}`]
        // } else {
        //   send_data.learnerId = data[`learnerId_${index}`]
        // }

        if (data[`learnerId_${index}`] != null && data[`learnerId_${index}`] != undefined && data[`learnerId_${index}`] != "") {
          send_data.learnerId = data[`learnerId_${index}`]
        }

        console.log('send_datarefund_payment_give :', send_data);
        isLoader(true)
        const response = await accept_reject_refund_request(send_data);
        console.log('responseeeee :', response);
        if (response?.code == 1) {
          isLoader(false)
          TOAST_SUCCESS(response.message)
          RefundList()
          // setRefundList1(response?.data)
        } else {
          isLoader(false)
          TOAST_ERROR(response.message)
        }
      } catch (error) {
        console.log('error :', error);
      }
    } else {
      try {
        let send_data = {
          requested_user_id: scheduleId.id,
          activity_id: activityId,
          activity_type: activityType,
          meeting_id: data[`meetingId_${index}`],
          reason_id: data[`Refund_Reason_${index}`],
          refund_request_status: data[`actionType_${index}`],
          userId: data[`userId_${index}`]
        }

        // if (data[`userId_${index}`] != null && data[`userId_${index}`] != undefined && data[`userId_${index}`] != "") {
        //   send_data.userId = data[`userId_${index}`]
        // } else {
        //   send_data.learnerId = data[`learnerId_${index}`]
        // }
        if (data[`learnerId_${index}`] != null && data[`learnerId_${index}`] != undefined && data[`learnerId_${index}`] != "") {
          send_data.learnerId = data[`learnerId_${index}`]
        }
        console.log('send_datarefund_payment_give :', send_data);
        isLoader(true)
        const response = await accept_reject_refund_request(send_data);
        console.log('responseeeee :', response);
        if (response?.code == 1) {
          isLoader(false)
          TOAST_SUCCESS(response.message)
          RefundList()
          // setRefundList1(response?.data)
        } else {
          isLoader(false)
          TOAST_ERROR(response.message)
        }
      } catch (error) {
        console.log('error :', error);
      }
    }
  }

  // stop subcription
  let schema3 = yup.object().shape({

  });
  const {
    register: register3,
    handleSubmit: handleSubmit3,
    setValue: setValue3,
    clearErrors: clearErrors3,
    reset: reset3,
    watch: watch3,
    control: control3,
    getValues: getValues3,
    formState: { errors: errors3 },
  } = useForm({
    resolver: yupResolver(schema3),
  });

  let onSubmit3 = (data) => {
    // console.log(data);
    let send_data = {
      action: 'pause',
      reason_id: data.select_other_reason,
      activity_id: id.id,
      activity_type: id.class
    }

    console.log('send_data :', send_data);
    isLoader(true)
    start_stop_pause_subcriptions(send_data).then((response) => {
      if (response?.code == 1) {
        isLoader(false)
        TOAST_SUCCESS(response.message);
      } else {
        isLoader(false)
        TOAST_ERROR(response.message)
      }
    });
  }

  //pause class

  let schema4 = yup.object().shape({
    start_date: yup.string().required("Please select start date"),
    end_date: yup.string().required("Please select end date"),

  });

  const [refundList, setRefundList] = useState([]);
  const [parentId, setParentId] = useState([]);
  console.log('refundList :', refundList);
  // const [refundList1, setRefundList1] = useState([]);
  // console.log('refundList1 :', refundList1);
  // console.log('refundList :', refundList);

  const RefundList = async () => {
    try {
      let send_data = {
        activity_id: id?.id,
        activity_type: id?.class,
        // learnerId : scheduleId?.id
      }
      if (scheduleId != null && scheduleId != undefined && scheduleId != {}) {
        if (scheduleId.type == 'user') {
          send_data.userId = scheduleId.id
        } else {
          send_data.learnerId = scheduleId.id
        }
      }
      console.log('RefundList :', send_data);
      isLoader(true)
      const response = await refund_data_list(send_data);
      console.log('responsewwww :', response);
      if (response?.code == 1) {
        isLoader(false)
        setRefundList(response?.data)
        // setRefundList1(response?.data)
      } else {
        isLoader(false)
        setRefundList([])
      }
    } catch (error) {
      console.log('error :', error);
    }
  }

  useEffect(() => {
    if (modalIsOpen == true) {
      RefundList()
    }
  }, [scheduleId?.id])

  const {
    register: register4,
    handleSubmit: handleSubmit4,
    setValue: setValue4,
    clearErrors: clearErrors4,
    reset: reset4,
    watch: watch4,
    control: control4,
    getValues: getValues4,
    formState: { errors: errors4 },
  } = useForm({
    resolver: yupResolver(schema4),
  });

  const [currentDate, setCurrentDate] = useState(new Date().toISOString().split('T')[0]);

  let onSubmit4 = (data) => {
    // console.log(data);
    let send_data = {
      action: 'pause',
      start_date: data.start_date,
      end_date: data.end_date,
      activity_id: id.id,
      activity_type: id.class
    }

    console.log('send_data :', send_data);
    isLoader(true)
    start_pause_subscriptions(send_data).then((response) => {
      if (response?.code == 1) {
        isLoader(false)
        TOAST_SUCCESS(response.message);
        setValue4("start_date", '')
        setValue4("end_date", '')
      } else {
        isLoader(false)
        TOAST_ERROR(response.message)
      }
    });
  }

  return (
    <>
      <JqueryLoad />
      <OHeader_Dashboard />
      <Helmet>
        {/* <script src="./assets/js/custom_calendar.js"></script> */}
        {/* 
        <script>
          {`
  var ctx = document.getElementById("myChart").getContext('2d');

	var myChart = new Chart(ctx, {
	    type: 'doughnut',
	    data: {
	        labels:false,
	        datasets: [{
	            backgroundColor: [
	                "rgba(255, 255, 255, 0.5)",
	                "rgba(255, 255, 255, 1)"
	            ],
	            data: [50, 50]
	        }]
	    }
	});

var ctx = document.getElementById("myChart1").getContext('2d');
	var myChart = new Chart(ctx, {
	    type: 'doughnut',
	    data: {
	        labels:false,
	        datasets: [{
	            backgroundColor: [
	                "rgba(255, 255, 255, 0.5)",
	                "rgba(255, 255, 255, 1)"
	            ],
	            data: [50, 50]
	        }]
	    }
	});

var ctx = document.getElementById("myChart2").getContext('2d');
	var myChart = new Chart(ctx, {
	    type: 'doughnut',
	    data: {
	        labels:false,
	        datasets: [{
	            backgroundColor: [
	                "rgba(255, 255, 255, 0.5)",
	                "rgba(255, 255, 255, 1)"
	            ],
	            data: [50, 50]
	        }]
	    }
	});

	var ctx = document.getElementById("myChart3").getContext('2d');
	var myChart = new Chart(ctx, {
	    type: 'doughnut',
	    data: {
	        labels:false,
	        datasets: [{
	            backgroundColor: [
	                "rgba(255, 255, 255, 0.5)",
	                "rgba(255, 255, 255, 1)"
	            ],
	            data: [50, 50]
	        }]
	    }
	});

	var ctx = document.getElementById("myChart4").getContext('2d');
	var myChart = new Chart(ctx, {
	    type: 'doughnut',
	    data: {
	        labels:false,
	        datasets: [{
	            backgroundColor: [
	                "rgba(255, 255, 255, 0.5)",
	                "rgba(255, 255, 255, 1)"
	            ],
	            data: [50, 50]
	        }]
	    }
	});

	var ctx = document.getElementById("myChart5").getContext('2d');
	var myChart = new Chart(ctx, {
	    type: 'doughnut',
	    data: {
	        labels:false,
	        datasets: [{
	            backgroundColor: [
	                "rgba(255, 255, 255, 0.5)",
	                "rgba(255, 255, 255, 1)"
	            ],
	            data: [50, 50]
	        }]
	    }
	});
  `}
        </script> */}
        <style>{`
         .list_item {
          display: inline;
          width: 10%;
          text-align: center;
          padding: .375rem .75rem;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: #212529;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #ced4da;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border-radius: .25rem;
          transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      }
        `}</style>
      </Helmet>
      <main className="dashboard-app d-lg-flex">
        <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
          <div className="align-items-center justify-content-between d-flex">
            <div className="d-flex align-items-center">
              <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                <i className="fa fa-bars" />
              </a>
              <a href="#" className="d-lg-none d-block brand-logo">
                <img src="./assets/images/logo.png" alt="logo" />
              </a>
            </div>
          </div>
        </header>
        <div className="class-content  bg-dark-gray">
          {/*start*/}
          <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
            <span>
              <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
              </svg>
              <span className="ms-2">Back</span>
            </span></a>
          {/*end*/}
          {/*start*/}
          <section className>
            <div className="class_dtls_sec pb-0">
              <div className="row align-items-center">
                <div className="class_image_box h-auto col-xl-7 mb-xl-0 mb-4">
                  <div className="class_img position-relative">
                    <div className="pb-0 position-static video-container">
                      {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                      <video id="video" class="position-static rounded-3" loop width="100%" controls="hide" poster={process.env.PUBLIC_URL + "/assets/images/video_image.png"}>
                        <source src={`${Video_URL}/Classroom -teachers (2).mp4`} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                  <div>
                    <div className="align-items-center bottom-info d-flex justify-content-between position-static rounded-3">
                      <Link to="/organization-profile-details" state={data.teacher_id} className="d-flex align-items-center">
                        <img src={data.profile_image} alt="user1" />
                        <span className="tech_nm ms-2">{data.teacher_name}</span>
                      </Link>
                      <div className="star_rat">
                        <span>
                          <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z" fill="#FDC840" />
                          </svg>
                        </span>
                        <span className="fw-600">{data.teacher_ratings != null ? data.teacher_ratings : "0"}<span className="text-gray fw-500">({data.teacher_review})</span></span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between follow-btn-sec position-static py-2 w-100">
                      <div>
                        <a className="follow_btn">Follow Me</a>
                      </div>
                      <span className="bg-yellow px-3 py-1 rounded-3 border-radius-10">{data.subject_category}</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 ps-xl-5">
                  <div className="justify-content-between">
                    <div className>
                      <h2 className="mb-4 poppins fw-600">Welcome To</h2>
                      <p className="fw-500 text-capitalize">Get private writing tutoring on a weekly basis to improve your skills and receive personalized feedback.</p>
                      <div className="button safety_btn mt-4">
                        <a href="#!" data-bs-toggle="modal" data-bs-target="#safety_tips" className="btn-theme mb-3 bg-yellow d-inline-block">
                          <span className="me-3">
                            <svg width={26} height={31} viewBox="0 0 26 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.1731 9.78467C10.0212 9.78467 7.45703 12.3488 7.45703 15.5005C7.45703 18.6525 10.0212 21.2166 13.1731 21.2166C16.3249 21.2166 18.889 18.6525 18.889 15.5005C18.889 12.3488 16.3249 9.78467 13.1731 9.78467ZM16.5668 14.4241L12.4718 18.519C12.3902 18.6009 12.2931 18.6658 12.1863 18.7101C12.0795 18.7544 11.9649 18.7771 11.8493 18.777C11.7337 18.7771 11.6192 18.7544 11.5124 18.7102C11.4056 18.6659 11.3086 18.6009 11.227 18.519L9.66209 16.9541C9.31832 16.6106 9.31832 16.0533 9.66209 15.7095C10.0056 15.3657 10.563 15.3657 10.9067 15.7095L11.8493 16.652L15.3221 13.1795C15.6657 12.8356 16.223 12.8356 16.5668 13.1795C16.9105 13.523 16.9105 14.0804 16.5668 14.4241Z" fill="#570861" />
                              <path d="M25.9172 8.63975L25.9163 8.61643C25.9027 8.30584 25.8937 7.99507 25.8893 7.68421C25.8648 6.03005 24.5499 4.66177 22.8955 4.56937C19.4463 4.37683 16.778 3.25213 14.4981 1.02991L14.4787 1.01139C13.735 0.329536 12.6133 0.329536 11.8694 1.01139L11.8499 1.02991C9.57005 3.25213 6.90175 4.37683 3.45252 4.56955C1.79842 4.66177 0.48323 6.03011 0.458738 7.68439C0.454439 7.99519 0.445454 8.3059 0.431785 8.61643L0.430379 8.67063C0.363289 12.1879 0.279969 16.5652 1.74434 20.5384C2.54959 22.7233 3.76904 24.6225 5.36871 26.1837C7.19062 27.9617 9.5769 29.3732 12.4613 30.3789C12.5562 30.4119 12.6533 30.4384 12.7519 30.4581C12.892 30.486 13.033 30.5 13.174 30.5C13.315 30.5 13.4562 30.486 13.5961 30.4581C13.6951 30.4383 13.7926 30.4116 13.8879 30.3784C16.7688 29.3709 19.1526 27.9587 20.9729 26.181C22.5719 24.6194 23.7914 22.7196 24.5973 20.5343C26.0671 16.5492 25.9841 12.1636 25.9172 8.63975ZM13.1736 22.9769C9.05091 22.9769 5.69713 19.6231 5.69713 15.5005C5.69713 11.3778 9.05097 8.02405 13.1736 8.02405C17.296 8.02405 20.65 11.3778 20.65 15.5005C20.65 19.6231 17.296 22.9769 13.1736 22.9769Z" fill="#570861" />
                            </svg>
                          </span>
                          Safety Tips
                        </a>
                        <Link onClick={(e) => PaymentList(id?.id, id?.class)} state={id} className="ms-2 btn-theme mb-3 bg-yellow d-inline-block">
                          <span className="me-3">
                            <svg width={30} height={27} viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M27.2385 0.5H14.0584C12.5331 0.5 11.2969 1.73645 11.2969 3.26147V9.75365C11.9936 10.0999 12.7465 10.4744 13.4491 10.8237L14.5242 8.67272C15.1751 7.37193 16.7619 6.84342 18.0622 7.49322C19.3652 8.14537 19.8933 9.72781 19.241 11.0311L17.3245 14.8649C17.3759 15.3737 17.3292 15.8876 17.1872 16.3789H18.4525L15.1154 25.5553C14.9173 26.0995 15.3001 26.7346 15.9416 26.7346C16.3003 26.7346 16.6377 26.5133 16.7672 26.1558L20.3227 16.3789H20.9386L24.5302 26.1588C24.6608 26.5145 24.9973 26.7346 25.3557 26.7346C25.9552 26.7346 26.3946 26.1359 26.1802 25.5529L22.8111 16.3789H27.2386C28.7636 16.3789 30 15.1425 30 13.6174V3.26152C30 1.73645 28.7636 0.5 27.2385 0.5ZM25.3553 12.6136H21.4119C20.9266 12.6136 20.533 12.2199 20.533 11.7346C20.533 11.249 20.9266 10.8557 21.4119 10.8557H25.3553C25.841 10.8557 26.2342 11.249 26.2342 11.7346C26.2342 12.2199 25.8411 12.6136 25.3553 12.6136ZM25.3553 9.31836H21.4119C20.9266 9.31836 20.533 8.9252 20.533 8.43945C20.533 7.95424 20.9266 7.56055 21.4119 7.56055H25.3553C25.841 7.56055 26.2342 7.95424 26.2342 8.43945C26.2342 8.9252 25.8411 9.31836 25.3553 9.31836ZM25.3553 6.02363H15.9416C15.4559 6.02363 15.0627 5.62988 15.0627 5.14473C15.0627 4.65957 15.4559 4.26582 15.9416 4.26582H25.3553C25.841 4.26582 26.2342 4.65945 26.2342 5.14473C26.2342 5.63 25.841 6.02363 25.3553 6.02363Z" fill="#570861" />
                              <path d="M15.4642 16.0019C15.623 15.5366 15.6219 15.0505 15.4877 14.6069L17.669 10.2448C17.886 9.81066 17.7102 9.28268 17.276 9.06564C16.8421 8.84844 16.314 9.02445 16.0968 9.45869L14.2365 13.1789C13.1245 12.6252 11.4352 11.7853 10.0743 11.1085C8.80805 10.4788 7.90441 9.91414 6.3627 9.91414H5.58609C6.78656 9.91414 7.85795 9.3616 8.55926 8.49687C9.0944 7.83887 9.41438 7.00021 9.41438 6.08633C9.41438 3.97168 7.70051 2.25781 5.58563 2.25781C3.47168 2.25781 1.75781 3.97168 1.75781 6.08633C1.75781 7.10187 2.15332 8.02473 2.79855 8.70957C3.49617 9.45137 4.48699 9.91414 5.58539 9.91437C4.11275 9.91437 2.67311 10.511 1.6374 11.5495C0.581543 12.6026 0 14.0056 0 15.5002V18.3245C0 19.5403 0.789434 20.5747 1.88279 20.9428V25.8555C1.88279 26.341 2.27625 26.7344 2.7617 26.7344H8.41002C8.89547 26.7344 9.28893 26.341 9.28893 25.8555V15.9805C10.1223 16.3971 11.2171 16.9443 12.1028 17.3874C12.7238 17.6978 13.4493 17.7257 14.0964 17.4625C14.7416 17.197 15.2389 16.6685 15.4607 16.0123L15.4642 16.0019Z" fill="#570861" />
                            </svg>
                          </span>
                          Class Options
                        </Link>
                      </div>
                      <div className="d-md-flex">
                        <Link to="/edit-your-class" state={edit_class_id} className="fw-500 text-capitalize text-purple text-decoration-underline mb-md-0 mb-3 d-inline-md-block d-block">Edit Class Details</Link>
                        <Link to="/scheduling_visibility" state={{ id: id?.id, duration: " ", activity_type: id?.class, teacher_id: data?.teacher_id }} className="fw-500 text-capitalize text-purple text-decoration-underline ms-md-4">Edit My Class Schedule</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*end*/}
        </div>
        <div className="class-content pt-lg-5">
          {/*start-video-section*/}
          <div className="video_boxs_sec position-relative">
            <div className>
              <div className="video_boxs">
                <video id="video" className="w-100" loop height={557} controls="controls" poster={process.env.PUBLIC_URL + "/assets/images/video_bg_dash.png"}>
                  <source src={`${Video_URL}/Classroom -teachers (2).mp4`} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="cancel_btn position-absolute m-2">
              <a href="#" className="colse_div_video">
                <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" height={25} viewBox="0 -960 960 960" width={25}>
                  <path d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
                </svg>
              </a>
            </div>
          </div>
          <Header onStepChange={handleStepChange} type_class={id?.type_class} />
          <div className="row">
            <div className="col-xl-12 mb-xl-0 mb-4">
              {/*start-content*/}
              <div className="tab-content" id="pills-tabContent">
                {renderStep()}

                {/* <ClassRoom id={id} />
                <Homework/>
                <Assessment/>
                <Quizzes/>
                <Disscussion/>
                <Recording/>
                <Messages/>
                <Review/>
                <ProgressReport/>
                <Feedback/> */}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Modals isOpen={modalIsOpen} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal" >
        {/* <div className="modal fade" id="class_option" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header pb-0 border-bottom-0">
              <div className="class_op_user d-flex align-items-center">
                {/* <img src="./assets/images/kids-profile.png" alt="kids-profile" />
                <div className="ms-3">
                  <span className="text-dif-black fw-500">Mark Joseph</span>
                </div> */}
              </div>
              <button type="button" data-bs-dismiss="modal" className="btn-close" state={id} onClick={() => setIsOpen(false)} />
            </div>
            <div className="modal-body p-0">
              {/*start*/}
              <div className="px-3 py-3 border-bottom">
                <p className="text-purple fw-500 fs-5 mb-3">Subscribed Learners</p>

                <div className>
                  <div className="form-group mb-4">
                    <select onChange={(e) => handleSelectedLearner(e.target.value)} name="learner_name" className="form-select" aria-label="Default select example">
                      <option value="" disabled >Learner’s Name</option>
                      {learnerData?.map((student, index) => {
                        const type = student?.learner_id ? 'learner' : 'user';
                        const id = student?.learner_id || student?.user_id;
                        const l_name = student?.learner_name || student?.user_name;
                        const valueObj = JSON.stringify({ type, id, l_name });
                        return (
                          <option key={index} selected={scheduleId?.id == id ? 'selected' : ''} value={valueObj}>{student?.learner_name || student?.user_name}</option>
                        )
                      })}
                    </select>
                    {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.learner_name?.message}</span></p> */}

                  </div>

                  <form onSubmit={handleSubmit(onSubmit)} className="custom_form mt-2">
                    <p className="text-purple fw-500 fs-5 mb-3">Transfer</p>


                    <div className="form-group mb-4">
                      <label className="mb-2">Transfer {lName} to another section</label>
                      <select className="form-select" aria-label="Default select example" name="select_section" {...register("select_section")} >
                        <option value="" >Select section</option>
                        {teacherSchedule?.map((TS, index) => {
                          console.log('teacherSchedule :', teacherSchedule);
                          return (
                            // <option key={index} value={TS.id}>{dayjs(TS.date, 'YYYY-MM-DD').format('dddd, MMM D')},&nbsp;
                            //   {dayjs(TS.date + ' ' + TS.start_time, 'hh:mm:ss').format('h:mm ')}- {dayjs(TS.date + ' ' + TS.end_time, 'hh:mm:ss').format('h:mm A')}</option>
                            <option key={index} value={TS.id}>{TS?.formatted_datetime}</option>
                          )
                        })}

                      </select>
                      <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.select_section?.message}</span></p>

                      <span className="fw-500 text-dif-black mt-2 d-block">Please confirm this change with the learner before you transfer.</span>
                    </div>
                    <div className="mt-4">
                      {/* <a href="#!" className="btn-theme bg-yellow text-center d-block">Change Class Schedule </a> */}
                      <button type="submit" className="btn-theme bg-yellow text-center d-block col-12" >
                        Change Class Schedule
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              {/*end*/}
              {/*start*/}
              <div className="px-3 py-3 border-bottom">

                <p className="text-purple fw-500 fs-5 mb-3">Completed Lessons</p>
                <div className="class_op_price">
                  <ul>
                    {paymentListData?.length > 0 ?

                      paymentListData?.map((pData, index) => {
                        return (
                          <li >Class date:  {pData?.meeting_date}  {dayjs(pData?.date + ' ' + pData?.start_time, 'hh:mm:ss').format('h:mm')}-{dayjs(pData?.date + ' ' + pData?.end_time, 'hh:mm:ss').format('h:mm A')} - Payment made of ${pData?.amount} USD  by {pData?.full_name} on {pData?.payment_date} </li>
                        )
                      }) : <> <h4 className="d-flex justify-content-center" style={{ "color": "#570861", "fontSize": '18px' }}>Data Not Found
                      </h4></>}
                    {/* <li>Enrollment price: $48 USD * 7 weeks = $336 USD</li>
                    <li>Teacher earnings: $235.20 USD</li>
                    <li>$48 USD paid by Mark J. on Mar 19</li>
                    <li>$48 USD paid by Mark J. on Mar 26</li>
                    <li>$48 USD paid by Mark J. on Apr 2</li>
                    <li>$48 USD paid by Mark J. on Apr 9</li>
                    <li>$48 USD paid by Mark J. on Apr 16</li> */}

                  </ul>
                </div>

                <p className="text-purple fw-500 fs-5 mb-3">Refunded List</p>
                <div className="class_op_price">
                  <ul>
                    {refundList?.refunded_data?.length > 0 ?

                      refundList?.refunded_data?.map((rfd, index) => {
                        return (
                          <li >  Class date: {rfd?.meeting_date} {dayjs(rfd?.start_date + ' ' + rfd.start_time, 'hh:mm:ss').format('h:mm')}-{dayjs(rfd?.start_date + ' ' + rfd?.end_time, 'hh:mm:ss').format('h:mm A')} - Payment made of ${rfd?.amount} USD by {rfd?.full_name} on {rfd?.payment_date} </li>
                        )
                      }) : <> <h4 className="d-flex justify-content-center" style={{ "color": "#570861", "fontSize": '18px' }}>Data Not Found
                      </h4></>}
                    {/* <li>Enrollment price: $48 USD * 7 weeks = $336 USD</li>
                    <li>Teacher earnings: $235.20 USD</li>
                    <li>$48 USD paid by Mark J. on Mar 19</li>
                    <li>$48 USD paid by Mark J. on Mar 26</li>
                    <li>$48 USD paid by Mark J. on Apr 2</li>
                    <li>$48 USD paid by Mark J. on Apr 9</li>
                    <li>$48 USD paid by Mark J. on Apr 16</li> */}

                  </ul>
                </div>

                <p className="text-purple fw-500 fs-5 mb-3">Request Refund</p>
                <div className="class_op_price">
                  <ul>
                    {/* <li>Enrollment price: $48 USD * 7 weeks = $336 USD</li> */}
                    {refundList?.request_data?.map((Rrl, index) => {
                      const handleSubmitForm = handleSubmit2((data) => {
                        // Handle form submission for this specific form
                        onSubmit2(data, index);
                      });
                      return (
                        <>
                          {/* <form onSubmit={handleSubmit2(onSubmit2)} className="custom_form mt-4">

                            <div className="row">
                              <div className="d-flex">

                                <li className="">Class date:  {Rrl.meeting_date}  {dayjs(Rrl.start_date + ' ' + Rrl.start_time, 'hh:mm:ss').format('h:mm')}-{dayjs(Rrl.start_date + ' ' + Rrl.end_time, 'hh:mm:ss').format('h:mm A')} - Payment made of ${Rrl.amount} USD  by {Rrl.full_name} on {Rrl.payment_date}
                                  <span><input type="text" className="text-purple form-control list_item mx-2" name="rPrice" {...register2("rPrice")} /></span></li>
                                <input type="hidden" name="userId" value={Rrl.user_id} {...register("userId")}></input>
                              </div>

                            </div>
                            <div className="form-group mb-4">
                              <label className="mb-2">Request Refund Reason</label>
                              <select className="form-select" aria-label="Default select example" name="Refund_Reason" {...register2("Refund_Reason")} onChange={(e) => { refundReason(e.target.value) }} >
                                <option selected value="Teacher was not prepared for the lesson">Teacher was not prepared for the lesson</option>
                                <option value="Learner is not interested anymore">Learner is not interested anymore</option>
                                <option value="Leaner is too busy">Learner is too busy</option>
                                <option value="Conflicts with other classes">Conflicts with other classes</option>
                                <option value="Other">Other</option>
                              </select>
                            </div>
                            {RefundReason == "Other" ?
                              <>
                                <div className="form-group mb-4">
                                  <label>Other Reason</label>
                                  <input type="text" className="form-control" name placeholder />
                                </div>
                              </> : <></>

                            }

                            <div className="mt-4">
                              <button type='submit' className="btn-theme bg-yellow text-center d-block col-12">Offer A Refund</button>
                            </div>
                          </form> */}
                          <div key={index}>
                            <form onSubmit={handleSubmitForm} className="custom_form mt-4">
                              <div className="row">
                                <div className="d-flex">
                                  <li className="">
                                    Class date: {Rrl.meeting_date} {dayjs(Rrl.start_date + ' ' + Rrl.start_time, 'hh:mm:ss').format('h:mm')}-{dayjs(Rrl.start_date + ' ' + Rrl.end_time, 'hh:mm:ss').format('h:mm A')} - Payment made of ${Rrl.amount} USD by {Rrl.full_name} on {Rrl.payment_date}
                                    {/* <span><input type="text" className="text-purple form-control list_item mx-2" name={`rPrice_${index}`} {...register2(`rPrice_${index}`)} /></span>
                                  </li> */}
                                    <span>
                                      <input
                                        type="text"
                                        className="text-purple form-control list_item mx-2"
                                        name={`rPrice_${index}`}
                                        {...register2(`rPrice_${index}`, {
                                          required: "This field is required",
                                          validate: value => parseFloat(value) <= parseFloat(Rrl.amount) || `Amount must be less than or equal to ${Rrl.amount}`
                                        })}
                                      />
                                    </span>
                                    {errors2[`rPrice_${index}`] && <span className="error">{errors2[`rPrice_${index}`].message}</span>}
                                  </li>
                                  <input type="hidden" name={`userId_${index}`} value={Rrl.user_id} {...register2(`userId_${index}`)} />
                                  <input type="hidden" name={`learnerId_${index}`} value={Rrl.learner_id} {...register2(`learnerId_${index}`)} />
                                  <input type="hidden" name={`meetingId_${index}`} value={Rrl.meeting_id} {...register2(`meetingId_${index}`)} />
                                  <input type="hidden" name={`actionType_${index}`} {...register2(`actionType_${index}`)} />
                                  <input type="hidden" name={`transaction_Id_${index}`} value={Rrl.transaction_id} {...register2(`transaction_Id_${index}`)} />

                                </div>
                              </div>
                              <div className="form-group mb-4">
                                <label className="mb-2">Request Refund Reason</label>
                                <select className="form-select" aria-label="Default select example" name={`Refund_Reason_${index}`} {...register2(`Refund_Reason_${index}`)} onChange={(e) => { refundReason(e.target.value) }}>
                                  <option selected value="Teacher was not prepared for the lesson">Teacher was not prepared for the lesson</option>
                                  <option value="Learner is not interested anymore">Learner is not interested anymore</option>
                                  <option value="Leaner is too busy">Learner is too busy</option>
                                  <option value="Conflicts with other classes">Conflicts with other classes</option>
                                  <option value="Other">Other</option>
                                </select>
                              </div>
                              {RefundReason == "Other" ?
                                <>
                                  <div className="form-group mb-4">
                                    <label>Other Reason</label>
                                    <input type="text" className="form-control" name={`otherReason_${index}`} />
                                  </div>
                                </> : <></>}
                              <div className='row'>
                                <div className='col-6'>

                                  <div className="mt-4">
                                    <button type='submit' className="btn-theme bg-yellow text-center d-block col-12" onClick={() => setValue2(`actionType_${index}`, 'Accepted')}>Offer A Refund</button>
                                  </div>

                                </div>
                                <div className='col-6'>

                                  <div className="mt-4">
                                    <button type='submit' className="btn-theme bg-yellow text-center d-block col-12" onClick={() => setValue2(`actionType_${index}`, 'Rejected')}>Reject A Refund</button>
                                  </div>
                                </div>
                              </div>

                            </form>

                          </div>
                        </>
                      )
                    })}

                    {/* <div className="row align-items-center">
                      <span className="col-lg-5 mb-lg-0 mb-2">$48 USD paid by Mark J. on Apr 23</span>
                      <div className="col-lg-7">
                        <div className="row align-items-center">
                          <div className="col-lg-3 mb-lg-0 mb-2">
                            <form className="custom_form">
                              <input type="text" placeholder className="text-purple form-control" defaultValue="$24" name />
                            </form>
                          </div>
                          <div className="col ms-3">
                            <a href="#" className="text-decoration-underline text-purple">Offer A Refund</a>
                            <span className="text-purple ms-3">(You Will get 24 $)</span>
                          </div>
                        </div>
                      </div>
                    </div> */}

                  </ul>
                </div>
                {/* <form className="custom_form mt-4">
            

                  <div className="form-group mb-4">
                    <label className="mb-2">Request Refund Reason</label>
                    <select className="form-select" aria-label="Default select example">
                      <option selected>   Teacher is not a good fit</option>
                      <option>Teacher was not prepared for the lesson</option>
                      <option>Learner is not interested anymore</option>
                      <option>Leaner is too busy</option>
                      <option>Conflicts with other classes</option>
                    </select>
                  </div>
                  <div className="form-group mb-4">
                    <label>Other Reason</label>
                    <input type="text" className="form-control" name placeholder />
                  </div>
                </form> */}
                {/* <div className="mt-4">
                  <a href="#!" className="btn-theme bg-yellow text-center d-block">Offer A Refund</a>
                </div> */}
              </div>
              {/*end*/}
              {/*start*/}
              <div className="px-3 py-3 border-bottom">
                <div className>
                  <form className="custom_form" onSubmit={handleSubmit4(onSubmit4)}>
                    <p className="text-purple fw-500 fs-5 mb-2">Pause Class</p>
                    <div className="d-flex justify-content-between">
                      <div className="form-group mb-4 col-5">
                        <label className="mb-2">From Date</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Start" min={currentDate}
                          {...register4("start_date")}
                        />
                        {/* {errors4.start_date && <p>{errors4.start_date.message}</p>} */}
                        {errors4.start_date && <p className="mt-2 font-bold col-red">
                          <span style={{ color: "red" }}>{errors4.start_date.message}</span>
                        </p>}
                      </div>
                      <div className="form-group mb-4 col-5">
                        <label className="mb-2">To Date</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="End" min={currentDate}
                          {...register4("end_date")}
                        />
                        {/* {errors4.end_date && <p>{errors4.end_date.message}</p>}  */}
                        {errors4.end_date && <p className="mt-2 font-bold col-red">
                          <span style={{ color: "red" }}>{errors4.end_date.message}</span>
                        </p>}
                      </div>
                    </div>
                    <p>You will not be charged during this period. The subscription will be stopped till this day.</p>
                    <button type="submit" className="btn-theme bg-yellow text-center d-block col-12">Pause Class</button>
                  </form>
                  <p>You will not be charged during this period. The subscription will be stopped till this day.</p>
                </div>
              </div>
              {/*end*/}
              {/*start*/}
              <div className="px-3 py-3 border-bottom">
                <p className="text-purple fw-500 fs-5">Stop Subscription</p>
                <form onSubmit={handleSubmit3(onSubmit3)} className="custom_form mt-4">
                  <div className="form-group mb-4">
                    <label className="mb-2">reasons why a leaner stopped the class</label>
                    <select className="form-select" name="select_other_reason"  {...register3("select_other_reason")} onChange={(e) => { StopSub(e.target.value) }} aria-label="Default select example" >
                      <option selected value="Teacher was not prepared for the lesson">Teacher was not prepared for the lesson</option>
                      <option value="Learner is not interested anymore">Learner is not interested anymore</option>
                      <option value="Leaner is too busy">Learner is too busy</option>
                      <option value="Conflicts with other classes">Conflicts with other classes</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  {RefundReasonstopsub == "Other" ?
                    <div className="form-group mb-4">
                      <label>Other Reason</label>
                      <input type="text" className="form-control" name="other_reason" {...register3("other_reason")} />
                    </div>
                    : <></>}
                  <button type="submit" className="btn-theme bg-yellow text-center d-block col-12">Stop Subscription</button>

                </form>
                <div className>
                  <p className="text-purple fw-500 fs-5">Stop subscription for {lName} to this class</p>
                </div>
              </div>
              {/*end*/}
              {/*start*/}
              {/* <div class="px-3 py-3 border-bottom">
					<div class="">
						<p class="text-purple fw-500 fs-6">Pause subscription for Mark Joseph to this class</p>
					</div>
				</div> */}
              {/*end*/}
              {/*start*/}
              <div className="px-3 py-3">
                <p>Total net payment teacher has received to date after deductions:<span>$336 USD</span></p>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
        {/* </div> */}
      </Modals>
      <OFooter_Dashboard />
    </>
  )
}
