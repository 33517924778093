import React from "react";

export default function Model({ setIsOpenForgot, handleSelectSubject, selectSubject, setSelectDone, setSelectSubject }) {
  // console.log('hello');
  //    console.log(handleSelectSubject)
  // const active=()=>{

  // }
  console.log("selectSubject1 :", selectSubject);
  // console.log("aaa",selectSubject?.includes('Arts'))

  return (
    <>
      {/* hello */}
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              Select Your Subjects
            </h1>
            <button
              type="button"
              onClick={() => {
                setIsOpenForgot(false);
                // setSelectSubject([]);
              }}
              className="btn-close"
            />
          </div>
          <div className="modal-body">
            <div className="popular_item">
              <ul>
                <li>
                  <a onClick={() => handleSelectSubject("Academic")} className="d-flex align-items-center">
                    <div className={`topi_icon ${selectSubject?.includes("Academic") ? "active" : ""}`}>
                      <img src="./assets/images/topic/topic9.png" alt="topic9" />
                    </div>
                    <span className="ms-4">Academic</span>
                  </a>
                </li>
                <li>
                  <a className="d-flex align-items-center" onClick={() => handleSelectSubject("Arts")}>
                    <div className={`topi_icon ${selectSubject?.includes("Arts") ? "active" : ""}`}>
                      <img src="./assets/images/topic/topic1.png" alt="topic1" />
                    </div>
                    <span className="ms-4">Arts</span>
                  </a>
                </li>
                <li>
                  <a onClick={() => handleSelectSubject("Music")} className="d-flex align-items-center">
                    <div className={`topi_icon ${selectSubject?.includes("Music") ? "active" : ""}`}>
                      <img src="./assets/images/topic/topic6.png" alt="topic6" />
                    </div>
                    <span className="ms-4">Music</span>
                  </a>
                </li>
                <li>
                  <a onClick={() => handleSelectSubject("Coding & Tech")} className="d-flex align-items-center">
                    <div className={`topi_icon ${selectSubject?.includes("Coding & Tech") ? "active" : ""}`}>
                      <img src="./assets/images/topic/topic5.png" alt="topic5" />
                    </div>
                    <span className="ms-4">Coding &amp; Tech</span>
                  </a>
                </li>
                <li>
                  <a onClick={() => handleSelectSubject("Languages")} className="d-flex align-items-center">
                    <div className={`topi_icon ${selectSubject?.includes("Languages") ? "active" : ""}`}>
                      <img src="./assets/images/topic/topic3.png" alt="topic3" />
                    </div>
                    <span className="ms-4">Languages</span>
                  </a>
                </li>
                <li>
                  <a onClick={() => handleSelectSubject("Life Skills")} className="d-flex align-items-center">
                    <div className={`topi_icon ${selectSubject?.includes("Life Skills") ? "active" : ""}`}>
                      <img src="./assets/images/topic/topic4.png" alt="topic4" />
                    </div>
                    <span className="ms-4">Life Skills</span>
                  </a>
                </li>
                <li>
                  <a onClick={() => handleSelectSubject("Health and Wellness")} className="d-flex align-items-center">
                    <div className={`topi_icon ${selectSubject?.includes("Health and Wellness") ? "active" : ""}`}>
                      <img src="./assets/images/topic/topic2.png" alt="topic2" />
                    </div>
                    <span className="ms-4">Health and Wellness</span>
                  </a>
                </li>
                <li>
                  <a onClick={() => handleSelectSubject("Professional Training")} className="d-flex align-items-center">
                    <div className={`topi_icon ${selectSubject?.includes("Professional Training") ? "active" : ""}`}>
                      <img src="./assets/images/topic/topic7.png" alt="topic7" />
                    </div>
                    <span className="ms-4">Professional Training</span>
                  </a>
                </li>
                <li>
                  <a onClick={() => handleSelectSubject("In-Person Classes")} className="d-flex align-items-center">
                    <div className={`topi_icon ${selectSubject?.includes("In-Person Classes") ? "active" : ""}`}>
                      <img src="./assets/images/topic/topic8.png" alt="topic8" />
                    </div>
                    <span className="ms-4">In-Person Classes</span>
                  </a>
                </li>

                {/* <li>
                <a onClick={() => handleSelectSubject('Privateschool')} className="d-flex align-items-center">
                  <div className={`topi_icon ${selectSubject?.includes('Privateschool')?"active":""}`}>
                    <img src="./assets/images/topic/topic10.png" alt="topic9" />
                  </div>
                  <span className="ms-4">Privateschool</span>
                </a>
              </li> */}
              </ul>
            </div>
          </div>
          <div className="modal-footer justify-content-center">
            <div className="col-lg-4 col-12">
              <button
                type="button"
                onClick={() => {
                  setSelectDone(true);
                  setIsOpenForgot(false);
                }}
                className="btn-theme w-100 bg-yellow fw-600"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
