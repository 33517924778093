import React, { useEffect, useState, useRef } from 'react'
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom'
import "@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css";
import { Calendar } from "@amir04lm26/react-modern-calendar-date-picker";
import { certificate_competition, classroom_delete_lesson, classroom_list, class_certificate, class_meeting_data, class_room_listing, generateAgoraToken, learner_name_list, notification_listing, teacher_detail_of_leads, updateMeeting, join_meeting } from '../../../api/apiHandler'
import Swal from 'sweetalert2';
import { TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/common.service';
import dayjs from 'dayjs';
import { htmlToText } from 'html-to-text';
import Modals from 'react-modal';
import { Controller, useForm } from "react-hook-form";
import { useSelector } from 'react-redux';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import queryString from 'query-string';
import CryptoJS from 'crypto-js';
import io from "socket.io-client";
import { SOCKET_URL } from '../../../Config';

var key = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNfTmDXHWVkQWdanYOm');
var iv = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNf');


var COMMON_STYLE_FOR_POPUP = {
  content: {
    // backgroundColor: 'rgb(0 0 0 / 56%)',
    background: 'none',
    border: '0px',
  },
}


const TruncateText = ({ text, length }) => {
  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };

  // Convert HTML to plain text
  const plainText = htmlToText(text || '', {
    wordwrap: false,
    ignoreImage: true,
    ignoreHref: true,
  });

  const truncatedText = truncateString(plainText, length);

  return (
    <p
      dangerouslySetInnerHTML={{ __html: truncatedText }}
      className="fs-6"
      style={{ fontWeight: 'normal', fontSize: '1rem', textDecoration: 'none' }} // Adjust the font size and weight
    />
  );
};


export default function ClassRoom({ id, classDetails }) {

  console.log('idLesson :', id);
  const navigate = useNavigate();
  const location = useLocation();

  // var id = location?.state;
  // console.log('id123 :', id);
  var after = useParams();
  if (location?.search) {
    const queryParams = new URLSearchParams(location?.search);
    const encryptedData = queryParams.get('data');

    // Define your key and IV (must be the same as used for encryption)
    // const key = CryptoJS.enc.Utf8.parse('your-key-here');
    // const iv = CryptoJS.enc.Utf8.parse('your-iv-here');

    // Decode and decrypt the data
    const decryptedData1 = CryptoJS.AES.decrypt(decodeURIComponent(encryptedData), key, { iv: iv }).toString(CryptoJS.enc.Utf8);
    console.log('decryptedData1 :', decryptedData1);
    const params = new URLSearchParams(decryptedData1);
    console.log('params :', params);

    id = {
      id: parseInt(params?.get('activityId'), 10),  // Assuming id is a number
      class: params?.get('typeClass'),
      type_class: params?.get('type'),
    };;
    console.log('idxyz1 :', id);
    var edit_class_id = id?.id;
    // setDataType(id?.type)
  }
  else if (location?.state) {
    var id = location?.state;
    console.log('idxyz -------->>>   ', id);
    var edit_class_id = id?.id;
  } else {
    // const { id } = useParams();
    // var type = location?.search?.split("=")[1];
    // console.log('type :', type);
    // var id = after
    // // id.class = type
    // id.class= 'class'
    // console.log('idtype :', id);


    // const queryParams = queryString.parse(location.search);

    // // Extract specific query parameters
    // const type = queryParams.type;

    // const typeClass = queryParams?.type_class;

    // var id = after
    // id.class = type
    // id.type_class = typeClass

    var decryptedData = CryptoJS.AES.decrypt(decodeURIComponent(location?.pathname?.split('/')[2]), key, { iv: iv }).toString(CryptoJS.enc.Utf8);
    console.log('decryptedData :', decryptedData);
    var descryptedJson = decryptedData.split('?')
    const queryParams = queryString.parse(descryptedJson[1]);
    console.log('queryParams :', queryParams);
    const type = queryParams?.type;
    const typeClass = queryParams?.type_class;
    var id = {}
    var edit_class_id = descryptedJson[0]
    id.id = descryptedJson[0]
    id.class = type
    id.type_class = typeClass
  }

  const { userDetails: { data: userDetails } } = useSelector((state) => state.master);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  console.log('dataLesson :', data);
  const [newData, setNewData] = useState([]);
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [nextMonth, setNextMonth] = useState(currentDate.add(1, 'month'));
  const [previousMonth, setPreviousMonth] = useState(currentDate.subtract(1, 'month'));
  const [selectedMonth, setSelectedMonth] = useState(currentDate);
  const [UpdatedData, setUpdatedData] = useState(currentDate.format("MMM YYYY"));
  const [learnerData, setLearnerData] = useState([]);
  const [scheduleId, setScheduleId] = useState({});
  const [dataClassroom, setDataClassroom] = useState(null);

  const learner_listing = () => {
    learner_name_list({ activity_id: id?.id, activity_type: id?.class }).then((response) => {
      if (response?.code == 1) {
        setLearnerData(response?.data)
        setIsOpen(true)
      } else {
        TOAST_ERROR("No learners are currently subscribed")
      }
    });
  }

  const handleSelectedLearner = (data) => {
    // var main = JSON.parse(data);
    // console.log('main :', main);
    clearErrors("select_class", "")
    if (data !== "" && data !== undefined) {
      var main = JSON.parse(data);

      // Update scheduleId and set a flag to call PaymentList after the state is updated
      setScheduleId(main);
    }
  }

  const NextMonthYear = (e) => {
    e.preventDefault();

    setCurrentDate((prevCurrentDate) => prevCurrentDate.add(1, 'month'));
    setNextMonth((prevNextMonth) => prevNextMonth.add(1, 'month'));
    setPreviousMonth((prevPreviousMonth) => prevPreviousMonth.add(1, 'month'));
    setSelectedMonth((prevSelectedMonth) => prevSelectedMonth.add(1, 'month'));
  };

  const PreviousMonthYear = (e) => {
    e.preventDefault();
    setCurrentDate((prevCurrentDate) => prevCurrentDate.subtract(1, 'month'));
    setNextMonth((prevNextMonth) => prevNextMonth.subtract(1, 'month'));
    setPreviousMonth((prevPreviousMonth) => prevPreviousMonth.subtract(1, 'month'));
    setSelectedMonth((prevSelectedMonth) => prevSelectedMonth.subtract(1, 'month'));
  }

  const PMonth = (e) => {
    setUpdatedData(e)
  }

  const Classroom_listing = async () => {
    try {

      let sendData = {
        activity_id: id?.id,
        activity_type: id?.class,
        month_wise: UpdatedData.toLowerCase(),
      }
      console.log('sendData :', sendData);
      const response = await class_meeting_data(sendData);
      console.log('class_meeting_data :', response);
      if (response?.code == 1) {
        setDataClassroom(response?.data?.[0]);
      } else {
        setDataClassroom(null);
         ;
      }
    } catch (error) {
      console.error("Error fetching classroom list:", error);
    }
  };

  useEffect(() => {
    Classroom_listing();
  }, [UpdatedData]);

  const classMeetingData = async () => {
    try {

      let sendData = {
        class_id: id?.id,
        activity_type: id?.class,
        month_wise: UpdatedData.toLowerCase(),
      }
      console.log('sendData Lesson:', sendData);
      const response = await classroom_list(sendData);
      console.log('classroom_list :', response);
      if (response?.code == 1) {
        setData(response?.data);
      } else {
        setData([]);
         ;
      }
    } catch (error) {
      console.error("Error fetching classroom list:", error);
    }
  };

  useEffect(() => {
    classMeetingData();
  }, []);

  const Classroom_listing1 = async () => {
    try {

      let sendData = {
        class_id: id?.id,
        activity_type: id?.class,
        month_wise: UpdatedData.toLowerCase(),
      }
      console.log('sendDataLesson :', sendData);
      const response = await class_room_listing(sendData);
      console.log('class_room_listing :', response);

      if (response?.code == 1) {
        setNewData(response?.data);
      } else {
        setNewData([]);
         ;
      }
    } catch (error) {
      console.error("Error fetching classroom list:", error);
    }
  };

  useEffect(() => {
    Classroom_listing1();
  }, [UpdatedData]);


  const delete_class = (index, id) => {

    // alert(id)

    Swal.fire({
      title: 'Are you sure you want to delete ?',
      text: "You won't be able to revert this!",
      icon: 'question',
      background: 'white',
      showCancelButton: true,
      confirmButtonColor: '#570861',
      cancelButtonColor: '#FFC924',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonClass: 'swal-cancel-button',
    }).then((result) => {
      if (result.isConfirmed) {
        classroom_delete_lesson({ classroom_id: id }).then((response) => {
          if (response?.code == 1) {
            setData(data.filter(o => o.id !== id));
            TOAST_SUCCESS(response.message);

          } else {
            TOAST_ERROR(response.message);
          }
        })
      }
    })

  }

  const schema = yup.object().shape({
    select_class: yup.string().required("Please enter learner name"),

  });

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  let onSubmit = async () => {
    let class_data = {
      activity_id: id?.id,
      activity_type: id?.class,
    }
    if (scheduleId != null && scheduleId != undefined && scheduleId != {}) {
      if (scheduleId.type == 'user') {
        class_data.userId = scheduleId.id
      } else {
        class_data.learnerId = scheduleId.id
      }
    }
    if (id?.class == "class") {
      class_certificate(class_data).then((res) => {

        if (res?.code == 1) {
          TOAST_SUCCESS(res?.message);
          setIsOpen(false)
        } else {
          TOAST_ERROR(res?.message);

        }
      });
      reset();
    } else {
      certificate_competition(class_data).then((res) => {
        if (res?.code == 1) {
          TOAST_SUCCESS(res?.message);
          setIsOpen(false)
        } else {
          TOAST_ERROR(res?.message);
        }
      });
      reset();
    }


  }


  // var currentDate = dayjs();

  // // Add one month
  // var nextMonthDate = currentDate.add(1, 'month');
  // console.log(nextMonthDate.format("MMM YYYY"));  // Output: Next month in MMM YYYY format

  // // Subtract one month
  // var previousMonthDate = currentDate.subtract(1, 'month');
  // console.log(previousMonthDate.format("MMM YYYY"));  // Output: Previous month in MMM YYYY format

  // const [selectedMonth, setSelectedMonth] = useState(currentDate.format("MMM YYYY"));
  // const [nextMonth, setNextMonth] = useState(nextMonthDate.format("MMM YYYY"));
  // const [previousMonth, setPreviousMonth] = useState(previousMonthDate.format("MMM YYYY"));


  // const NextMonthYear = (e) => {

  //   e.preventDefault(); // Prevent the default behavior of the anchor tag
  //   currentDate = currentDate.add(1,'month')
  //   console.log('currentDate :', currentDate);
  //   previousMonthDate = previousMonthDate.add(1,'month')
  //   console.log('previousMonthDate :', previousMonthDate);
  //   nextMonthDate = nextMonthDate.add(1,'month')
  //   console.log('nextMonthDate :', nextMonthDate);

  //   setSelectedMonth(currentDate.format("MMM YYYY"))
  //   setNextMonth(nextMonthDate.format("MMM YYYY"));
  //   setPreviousMonth(previousMonthDate.format("MMM YYYY"))

  // };

  const [notificationData, setNotificationData] = useState('');

  const NotificationList = async () => {
    try {
      let send_data = {
        action_id: id?.id,
        action_type: id?.class,
        teach_type: "classroom"
      }

      const response = await notification_listing(send_data);
      if (response?.code == 1) {
        setNotificationData(response?.data)
      } else {
        setNotificationData([])
      }
    } catch (error) {
      console.log('error :', error);
    }
  }

  useEffect(() => {
    NotificationList();
  }, [])

  const handleCallStart = async () => {
    try {
      let send_data = {
        agora_uid: userDetails?.agora_uid,
        class_name: classDetails?.title,
      }
      console.log('send_data :', send_data);
      const response = await generateAgoraToken(send_data);
      if (response?.code === "1") {
        console.log('generateAgoraToken   -   response?.data --->>  ', response?.data);
        // return
        let updateMeetingSendData = {
          class_meeting_id: dataClassroom?.id,
          channel_id: response?.data?.channel,
        }
        const updateMeetingResponse = await updateMeeting(updateMeetingSendData);
        if (updateMeetingResponse?.code === "1") {
          var rules = {
            user_type: localStorage.getItem('type'),
            class_meeting_id:dataClassroom?.id,
            agora_uid: userDetails?.agora_uid,
        }
          const JoinMeeting = await join_meeting(rules);
          if (JoinMeeting?.code == 1) {
            const socketInstance = io(SOCKET_URL);
            socketInstance.emit('teacher_start_call',true)
            navigate('/tstart-meeting', { state: { classRoomData: dataClassroom, channel: response?.data?.channel, token: response?.data?.token, uid: response?.data?.uid ,title:classDetails?.title} });
          } else {
            TOAST_ERROR(JoinMeeting?.message);
          }

        } else {
          TOAST_ERROR(updateMeetingResponse?.message);
        }
      } else {
        TOAST_ERROR(response?.message);
      }
      
    } catch (error) {
      console.log('error :', error);
    }
  }

  console.log('dataClassroom :', dataClassroom);


  //   const teacherClassListing = async () => {
  //     const search_item = {
  //       activity_id : id?.id,
  //       activity_type : id?.class
  //     };

  //     try {
  //         const response = await get_meeting_link(search_item);
  //         console.log('get_meeting_link :', response);

  //         if (response?.code == 1) {
  //             setData(response?.data);

  //         } else {
  //             setData(response);

  //         }
  //     } catch (error) {
  //     }
  // };

  // useEffect(() => {
  //   teacherClassListing();
  // }, [])


  return (
    <>
      {/* <div className="tab-pane fade active show" id="pills-classes" role="tabpanel" aria-labelledby="pills-classes-tab">  */}
      <div className="row">
        <div className="col-xl-9">
          {dataClassroom && (
            <div className="card mb-4 mt-4 bg-gray review_box text-center">
              <h3 className="mb-4 poppins fw-600">Next Meeting Starts {dataClassroom?.formatted_date} At {dataClassroom?.formatted_time_range}</h3>
              <div className="button">
                <div className="btn-theme fw-600 text-dark bg-yellow mb-xl-0 mb-3 d-inline-block" onClick={handleCallStart}>Start Live Meeting</div>

              </div>
              {/* <div>
                <video ref={localVideoRef} autoPlay muted style={{ width: '300px', height: '200px' }}></video>
                {remoteStreams.map((stream, index) => (
                  <video key={index} ref={(video) => (remoteVideosRef.current[stream.getId()] = video)} autoPlay style={{ width: '300px', height: '200px' }}></video>
                ))}
              </div> */}
              <div>


              </div>
            </div>
          )}
        </div>
        <div className="col-xl-12">
          <ul className="d-flex flex-wrap">
            <li><Link to="/add_lesson" className="btn-theme border-dark d-inline-block mb-xl-4 mb-lg-3 mb-2 fw-500 text-dif-black " state={id}>+ Add Lesson</Link></li>
            <li><Link to="/add-homework" className="btn-theme border-dark d-inline-block mb-xl-4 mb-lg-3 mb-2 fw-500 text-dif-black  ms-3" state={id}>+ Add Homework</Link></li>
            <li><Link to="/add_assessment" className="btn-theme border-dark d-inline-block mb-xl-4 mb-lg-3 mb-2 fw-500 text-dif-black  ms-3" state={id}>+ Add Assesments</Link></li>
            <li><Link to="/setup_my_quiz" className="btn-theme border-dark d-inline-block mb-xl-4 mb-lg-3 mb-2 fw-500 text-dif-black  ms-3" state={id}>+ Add Quiz </Link></li>
            <li><Link to="/add_discussion" className="btn-theme border-dark d-inline-block mb-xl-4 mb-lg-3 mb-2 fw-500 text-dif-black  ms-3" state={id}>+ Add Discussion</Link></li>
            <li><Link onClick={() => learner_listing()} className="btn-theme border-dark d-inline-block mb-xl-4 mb-lg-3 mb-2 fw-500 text-dif-black  ms-3" state={id}>Certificate</Link></li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-9 mb-xl-0 mb-4">
          <div className="enviorment_div mb-5 align-items-center d-md-flex">
            <div className="env_img">
              <img src={process.env.PUBLIC_URL + "/assets/images/enviorment.png"} alt="enviorment" />
            </div>
            <div className="env_text">
              <p className="text-500">Help save the environment. Cut down on printing. Instead please use Google docs, sheets and slides which is freely available to you.</p>
            </div>
          </div>
          <div className="review_dack">
            {newData?.length > 0 ?
              newData?.map((classroom_data, index) => {
                // console.log('classroom_data :', classroom_data);
                return (
                  <div key={index} className="card review_box mb-4">
                    <div className="review_top">
                      <div className="list_btw_dots mb-4">
                        <ul className="d-sm-flex align-items-center">
                          <span className="pe-4 fw-600 position-relative">Lesson <span className="green_dots position-absolute" /></span>
                          <li>
                            <span className="text-purple fw-500">Duration Of Lesson : {classroom_data?.duration}</span>
                          </li>
                        </ul>
                      </div>
                      <div className="d-lg-flex justify-content-between mb-3 align-items-satrt">
                        <div className="d-flex align-items-center">
                          <div className="fw-600">
                            <span className="d-block">{classroom_data?.lession_topic}</span>
                          </div>
                        </div>
                        <div className="d-lg-flex d-block font-14 mb-lg-0 mb-2 fw-600 align-items-center">
                          <span>Created By</span>:&nbsp;<span>{classroom_data?.full_name}</span>
                        </div>
                      </div>
                      <div className="review_text row mb-4">
                        <div className="col-lg-8">
                          <TruncateText text={classroom_data?.description} length={25} className="fs-6" />
                        </div>
                      </div>
                      <div className="d-flex flex-sm-row  flex-column-reverse justify-content-between align-items-sm-center">
                        <div className="button d-flex align-items-center justify-content-between">
                          <Link to="/view-details-classroom" state={{ id: classroom_data?.id, type: "class" }} className="btn-theme px-3 d-inline-block text-center">View Details</Link>
                          <ul className="edit_delete d-flex ms-4 align-items-center justify-content-end">
                            <li>
                              <a onClick={() => (delete_class(index, classroom_data.id))} className>
                                <svg width={20} height={20} viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M1.80679 25.6355C1.80679 27.406 3.24075 28.84 5.01126 28.84H17.8291C19.5995 28.84 21.0335 27.406 21.0335 25.6355V6.40886H1.80679V25.6355ZM17.0279 1.6022L15.4257 0H7.41459L5.81232 1.6022H0.20459V4.80667H22.6357V1.6022H17.0279Z" fill="#FF2929" />
                                </svg>
                              </a>
                            </li>
                            <li>
                              <Link to="/edit-lesson" state={classroom_data?.id} className>
                                <svg width={20} height={23} viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M3.10738 18.8439C3.32277 18.8439 3.36584 18.8224 3.55969 18.7793L7.43661 18.0039C7.84584 17.8962 8.25507 17.7024 8.57815 17.3793L17.9689 7.98851C19.412 6.54543 19.412 4.06851 17.9689 2.62543L17.172 1.78543C15.7289 0.342356 13.2305 0.342356 11.7874 1.78543L2.39661 11.1977C2.09508 11.4993 1.87969 11.93 1.772 12.3393L0.953537 16.2593C0.845845 16.9916 1.06123 17.7024 1.57815 18.2193C1.98738 18.6285 2.59046 18.8439 3.10738 18.8439ZM3.83969 12.7485L13.2305 3.3362C13.8551 2.71159 14.9966 2.71159 15.5997 3.3362L16.4181 4.15466C17.1505 4.88697 17.1505 5.92082 16.4181 6.63159L7.04892 16.0439L3.06431 16.7116L3.83969 12.7485Z" fill="#570861" />
                                  <path d="M17.8827 20.1797H1.98734C1.36272 20.1797 0.953491 20.5889 0.953491 21.2135C0.953491 21.8382 1.47041 22.2474 1.98734 22.2474H17.7966C18.4212 22.2474 18.9381 21.8382 18.9381 21.2135C18.9166 20.5889 18.3997 20.1797 17.8827 20.1797Z" fill="#570861" />
                                </svg>
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="list_btw_dots mb-sm-0 mb-4">
                          <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                            <li>
                              <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span> {classroom_data?.Posted_on}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }) :
              <h4 className="d-flex justify-content-center" style={{ "color": "#570861" }}>Data Not Found</h4>
            }

          </div>

          {/* <div className="review_dack">
            {newData?.length > 0 ?
              newData?.map((new_classroom_data, index) => {
                return (
                  <>
                    {new_classroom_data?.tag == "lesson" ? (
                      <div key={index} className="card review_box mb-4">
                        <div className="review_top">
                          <div className="list_btw_dots mb-4">
                            <ul className="d-sm-flex align-items-center">
                              <span className="pe-4 fw-600 position-relative">Lesson <span className="green_dots position-absolute" /></span>
                              <li>
                                <span className="text-purple fw-500">Duration Of Lesson : {new_classroom_data?.duration}</span>
                              </li>
                            </ul>
                          </div>
                          <div className="d-lg-flex justify-content-between mb-3 align-items-satrt">
                            <div className="d-flex align-items-center">
                              <div className="fw-600">
                                <span className="d-block">{new_classroom_data?.lession_topic}</span>
                              </div>
                            </div>
                            <div className="d-lg-flex d-block font-14 mb-lg-0 mb-2 fw-600 align-items-center">
                              <span>Created By</span>:&nbsp;<span>{new_classroom_data?.full_name}</span>
                            </div>
                          </div>
                          <div className="review_text row mb-4">
                            <div className="col-lg-8">
                              <TruncateText text={new_classroom_data?.description} length={25} className="fs-6" />
                            </div>
                          </div>
                          <div className="d-flex flex-sm-row  flex-column-reverse justify-content-between align-items-sm-center">
                            <div className="button d-flex align-items-center justify-content-between">
                              <Link to="/view-details-classroom" state={{ id: new_classroom_data?.id, type: "class" }} className="btn-theme px-3 d-inline-block text-center">View Details</Link>
                              <ul className="edit_delete d-flex ms-4 align-items-center justify-content-end">
                                <li>
                                  <a onClick={() => (delete_class(index, new_classroom_data.id))} className>
                                    <svg width={20} height={20} viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M1.80679 25.6355C1.80679 27.406 3.24075 28.84 5.01126 28.84H17.8291C19.5995 28.84 21.0335 27.406 21.0335 25.6355V6.40886H1.80679V25.6355ZM17.0279 1.6022L15.4257 0H7.41459L5.81232 1.6022H0.20459V4.80667H22.6357V1.6022H17.0279Z" fill="#FF2929" />
                                    </svg>
                                  </a>
                                </li>
                                <li>
                                  <Link to="/edit-lesson" state={new_classroom_data?.id} className>
                                    <svg width={20} height={23} viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M3.10738 18.8439C3.32277 18.8439 3.36584 18.8224 3.55969 18.7793L7.43661 18.0039C7.84584 17.8962 8.25507 17.7024 8.57815 17.3793L17.9689 7.98851C19.412 6.54543 19.412 4.06851 17.9689 2.62543L17.172 1.78543C15.7289 0.342356 13.2305 0.342356 11.7874 1.78543L2.39661 11.1977C2.09508 11.4993 1.87969 11.93 1.772 12.3393L0.953537 16.2593C0.845845 16.9916 1.06123 17.7024 1.57815 18.2193C1.98738 18.6285 2.59046 18.8439 3.10738 18.8439ZM3.83969 12.7485L13.2305 3.3362C13.8551 2.71159 14.9966 2.71159 15.5997 3.3362L16.4181 4.15466C17.1505 4.88697 17.1505 5.92082 16.4181 6.63159L7.04892 16.0439L3.06431 16.7116L3.83969 12.7485Z" fill="#570861" />
                                      <path d="M17.8827 20.1797H1.98734C1.36272 20.1797 0.953491 20.5889 0.953491 21.2135C0.953491 21.8382 1.47041 22.2474 1.98734 22.2474H17.7966C18.4212 22.2474 18.9381 21.8382 18.9381 21.2135C18.9166 20.5889 18.3997 20.1797 17.8827 20.1797Z" fill="#570861" />
                                    </svg>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className="list_btw_dots mb-sm-0 mb-4">
                              <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                <li>
                                  <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span> {new_classroom_data?.Posted_on}</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : new_classroom_data?.tag == "homework" ? (

                      <div className="card review_box mb-4">
                        <div className="review_top">
                          <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                            <div className="list_btw_dots mb-3">
                              <ul className="d-xl-flex align-items-center">
                                <span className="pe-sm-4 fw-600 position-relative">{new_classroom_data.tag} <span className="green_dots position-absolute" /></span>
                                <li>
                                  <span className="text-purple fw-500">Total Duration: {new_classroom_data.Total_duration} </span>
                                </li>
                              </ul>
                            </div>
                            <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                              <span>Created By</span>:&nbsp;<span>{new_classroom_data.full_name}</span>
                            </div>
                          </div>


                          <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                            <div className="list_btw_dots mb-3">
                              <ul className="d-xl-flex align-items-center">
                                <TruncateText text={new_classroom_data?.description} length={25} className="fs-6" />


                              </ul>
                            </div>
                            <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                              <Link to="/homework-response" state={{ new_classroom_data: new_classroom_data?.id, id: id }} className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">Student Homework</Link>
                            </div>
                          </div>
                          <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                            <div className="button d-sm-flex align-items-center">
                              <Link to="/view-details-page" state={{ id: new_classroom_data?.id, type: "homework" }} className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">View Details</Link>

                            </div>
                            <div className="list_btw_dots mb-xl-0 mb-4">
                              <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                <li>
                                  <span className="text-danger fw-500">{new_classroom_data.Days_Left}</span>
                                </li>
                                <li>
                                  <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span> {new_classroom_data.Posted_on}</span>
                                </li>
                                <li>
                                  <span className="text-purple fw-500"><span className="text-dif-black">Deadline:</span> {new_classroom_data.Deadline}</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : new_classroom_data?.tag == "assetment" ? (
                      <div key={index} className="card review_box mb-4">
                        <div className="review_top">
                          <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                            <div className="list_btw_dots mb-3">
                              <ul className="d-xl-flex align-items-center">
                                <span className="position-relative fw-600">{new_classroom_data.tag}</span>

                                <li>
                                  <span className="text-purple fw-500">Total Duration: {new_classroom_data.Total_duration}</span>
                                </li>
                              </ul>
                            </div>
                            <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                              <span>Created By</span>:&nbsp;<span>{new_classroom_data.full_name}</span>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                            <div className="list_btw_dots mb-3">
                              <ul className="d-xl-flex align-items-center">
                                <TruncateText text={new_classroom_data?.description} length={25} className="fs-6" />

                              </ul>
                            </div>
                            <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                              <Link to="/assesment-response" state={{ new_classroom_data: new_classroom_data?.id, id: id }} className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">Student Assesment</Link>
                            </div>
                          </div>


                          <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                            <div className="button d-sm-flex align-items-center">

                              <Link to="/view-details-page" state={{ id: new_classroom_data?.id, type: "assessment" }} className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">View Details</Link>
                              <ul className="ms-3 edit_delete d-flex align-items-center justify-content-end">
                                <li>
                                  <a onClick={() => (delete_class(index, new_classroom_data.id))} className>
                                    <svg width={20} height={20} viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M1.80679 25.6355C1.80679 27.406 3.24075 28.84 5.01126 28.84H17.8291C19.5995 28.84 21.0335 27.406 21.0335 25.6355V6.40886H1.80679V25.6355ZM17.0279 1.6022L15.4257 0H7.41459L5.81232 1.6022H0.20459V4.80667H22.6357V1.6022H17.0279Z" fill="#FF2929" />
                                    </svg>
                                  </a>
                                </li>
                                <li>
                                  <Link to="/edit-assessment" state={new_classroom_data?.id} className>
                                    <svg width={20} height={23} viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M3.10738 18.8439C3.32277 18.8439 3.36584 18.8224 3.55969 18.7793L7.43661 18.0039C7.84584 17.8962 8.25507 17.7024 8.57815 17.3793L17.9689 7.98851C19.412 6.54543 19.412 4.06851 17.9689 2.62543L17.172 1.78543C15.7289 0.342356 13.2305 0.342356 11.7874 1.78543L2.39661 11.1977C2.09508 11.4993 1.87969 11.93 1.772 12.3393L0.953537 16.2593C0.845845 16.9916 1.06123 17.7024 1.57815 18.2193C1.98738 18.6285 2.59046 18.8439 3.10738 18.8439V18.8439ZM3.83969 12.7485L13.2305 3.3362C13.8551 2.71159 14.9966 2.71159 15.5997 3.3362L16.4181 4.15466C17.1505 4.88697 17.1505 5.92082 16.4181 6.63159L7.04892 16.0439L3.06431 16.7116L3.83969 12.7485Z" fill="#570861" />
                                      <path d="M17.8827 20.1797H1.98734C1.36272 20.1797 0.953491 20.5889 0.953491 21.2135C0.953491 21.8382 1.47041 22.2474 1.98734 22.2474H17.7966C18.4212 22.2474 18.9381 21.8382 18.9381 21.2135C18.9166 20.5889 18.3997 20.1797 17.8827 20.1797Z" fill="#570861" />
                                    </svg>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className="list_btw_dots mb-xl-0 mb-4">
                              <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                <li>
                                  <span className="text-danger fw-500">{new_classroom_data.Days_Left}</span>
                                </li>
                                <li>
                                  <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span>{new_classroom_data.Posted_On}</span>
                                </li>
                                <li>
                                  <span className="text-purple fw-500"><span className="text-dif-black">Deadline:</span>{new_classroom_data.Deadline}</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : new_classroom_data?.tag == "discussion" ? (

                      <div key={index} className="card review_box mb-4">
                        <div className="review_top">
                          <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                            <div className="list_btw_dots mb-3">
                              <ul className="d-xl-flex align-items-center">
                                <span className="fw-600 position-relative">{new_classroom_data.tag}</span>
                              
                                <li>
                                  <span className="text-purple fw-500">Total Duration: {new_classroom_data.Total_duration}</span>
                                </li>
                              </ul>
                            </div>
                            <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                              <span>Created By</span>:&nbsp;<span>{new_classroom_data.teacher_name}</span>
                            </div>
                          </div>

                          <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                            <div className="list_btw_dots mb-3">
                              <ul className="d-xl-flex align-items-center">
                                <p className="fs-6">{new_classroom_data.description}</p>
                              </ul>
                            </div>
                            <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                              <Link to="/discussion-response" state={{ disscussion_id: new_classroom_data?.id, id: id }}
                                className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">Student Disscussion</Link>
                            </div>
                          </div>
                          <div >
                            <div className="button d-sm-flex align-items-center">

                              {new_classroom_data?.status == "opened" ?
                                <ul className="edit_delete d-flex align-items-center justify-content-end">
                                  <li>
                                    <a onClick={() => (delete_class(index, new_classroom_data.id))} className>
                                      <svg width={20} height={20} viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.80679 25.6355C1.80679 27.406 3.24075 28.84 5.01126 28.84H17.8291C19.5995 28.84 21.0335 27.406 21.0335 25.6355V6.40886H1.80679V25.6355ZM17.0279 1.6022L15.4257 0H7.41459L5.81232 1.6022H0.20459V4.80667H22.6357V1.6022H17.0279Z" fill="#FF2929" />
                                      </svg>
                                    </a>
                                  </li>
                                  <li>
                                    <Link to="/edit-disscussion" state={new_classroom_data?.id} className>
                                      <svg width={20} height={23} viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.10738 18.8439C3.32277 18.8439 3.36584 18.8224 3.55969 18.7793L7.43661 18.0039C7.84584 17.8962 8.25507 17.7024 8.57815 17.3793L17.9689 7.98851C19.412 6.54543 19.412 4.06851 17.9689 2.62543L17.172 1.78543C15.7289 0.342356 13.2305 0.342356 11.7874 1.78543L2.39661 11.1977C2.09508 11.4993 1.87969 11.93 1.772 12.3393L0.953537 16.2593C0.845845 16.9916 1.06123 17.7024 1.57815 18.2193C1.98738 18.6285 2.59046 18.8439 3.10738 18.8439V18.8439ZM3.83969 12.7485L13.2305 3.3362C13.8551 2.71159 14.9966 2.71159 15.5997 3.3362L16.4181 4.15466C17.1505 4.88697 17.1505 5.92082 16.4181 6.63159L7.04892 16.0439L3.06431 16.7116L3.83969 12.7485Z" fill="#570861" />
                                        <path d="M17.8827 20.1797H1.98734C1.36272 20.1797 0.953491 20.5889 0.953491 21.2135C0.953491 21.8382 1.47041 22.2474 1.98734 22.2474H17.7966C18.4212 22.2474 18.9381 21.8382 18.9381 21.2135C18.9166 20.5889 18.3997 20.1797 17.8827 20.1797Z" fill="#570861" />
                                      </svg>
                                    </Link>
                                  </li>
                                </ul> : null
                              }
                            </div>
                            <div className="list_btw_dots mb-xl-0 mb-4">
                              <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                {new_classroom_data.status == "opened" ?
                                  <li>
                                    <span class="text-success fw-500">{new_classroom_data.status}</span>
                                  </li> : <li>
                                    <span class="text-danger fw-500">{new_classroom_data.status}</span>
                                  </li>
                                }
                                <li>
                                  <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span>{new_classroom_data.Posted_On}</span>
                                </li>
                                <li>
                                  <span className="text-purple fw-500"><span className="text-dif-black">Deadline:</span>{new_classroom_data.Deadline}</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : new_classroom_data?.tag == "quiz" ? (
                      <div key={index} className="card review_box mb-4">
                        <div className="review_top">
                          <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                            <div className="list_btw_dots mb-3">
                              <ul className="d-xl-flex align-items-center">
                                <span className="pe-sm-4 fw-600 position-relative">{new_classroom_data.tag} <span className="green_dots position-absolute" /></span>
                              
                                <li>
                                  <span className="text-purple fw-500">Total Duration: {new_classroom_data.duration}</span>
                                </li>
                              </ul>
                            </div>
                            <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                              <span>Created By</span>:&nbsp;<span>{new_classroom_data.Teacher_name}</span>
                            </div>
                          </div>
                          <div className="review_text row mb-4">
                            <div className="col-lg-8">
                              <p className="fs-6">Let’s return to design thinking. Over time designers have built up their own body of approaches to solving classes of problems.</p>
                            </div>
                          </div>
                          <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                            <div className="button d-sm-flex align-items-center">
                              <Link to="/view-details-quiz" state={{ id: new_classroom_data?.id, class_id: new_classroom_data.class_id }} className="btn-theme px-3 mb-sm-0 mb-3  d-inline-block">View Details</Link>
                              <ul className="ms-3 edit_delete d-flex align-items-center justify-content-end">
                                <li>
                       
                                </li>
                                <li>
                                  <Link to="/edit-quiz" state={{ id: new_classroom_data?.id, class_id: new_classroom_data.class_id }} className>
                                    <svg width={20} height={23} viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M3.10738 18.8439C3.32277 18.8439 3.36584 18.8224 3.55969 18.7793L7.43661 18.0039C7.84584 17.8962 8.25507 17.7024 8.57815 17.3793L17.9689 7.98851C19.412 6.54543 19.412 4.06851 17.9689 2.62543L17.172 1.78543C15.7289 0.342356 13.2305 0.342356 11.7874 1.78543L2.39661 11.1977C2.09508 11.4993 1.87969 11.93 1.772 12.3393L0.953537 16.2593C0.845845 16.9916 1.06123 17.7024 1.57815 18.2193C1.98738 18.6285 2.59046 18.8439 3.10738 18.8439V18.8439ZM3.83969 12.7485L13.2305 3.3362C13.8551 2.71159 14.9966 2.71159 15.5997 3.3362L16.4181 4.15466C17.1505 4.88697 17.1505 5.92082 16.4181 6.63159L7.04892 16.0439L3.06431 16.7116L3.83969 12.7485Z" fill="#570861" />
                                      <path d="M17.8827 20.1797H1.98734C1.36272 20.1797 0.953491 20.5889 0.953491 21.2135C0.953491 21.8382 1.47041 22.2474 1.98734 22.2474H17.7966C18.4212 22.2474 18.9381 21.8382 18.9381 21.2135C18.9166 20.5889 18.3997 20.1797 17.8827 20.1797Z" fill="#570861" />
                                    </svg>
                                  </Link>
                                </li>
                              </ul>

                              <a className="btn-theme px-3 ms-sm-3 bg-yellow d-inline-block text-center">Show Certificate</a>
                            </div>
                            <div className="list_btw_dots mb-xl-0 mb-4">
                              <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                <li>
                                  <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span> {new_classroom_data.Posted_on}</span>
                                </li>
                                <li>
                                  <span className="text-purple fw-500"><span className="text-dif-black">Deadline:</span> 25 March 2022   5:00 PM</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null
                    }
                  </>

                )
              }) :
              <h4 className="d-flex justify-content-center" style={{ "color": "#570861" }}>Data Not Found</h4>
            }

          </div> */}
          {/*start-pagination*/}
          {/* <nav aria-label="Page navigation example" className="mt-5 custom-pagination">
            <ul className="pagination justify-content-evenly align-items-center border-radius-10">
              <li className="page-item">
                <a className="page-link text-dark" aria-label="Previous">
                  <span className>
                    <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="M453-241 213-481l240-240 42 42-198 198 198 198-42 42Zm253 0L466-481l240-240 42 42-198 198 198 198-42 42Z" /></svg>
                  </span>
                </a>
              </li>
              <li className="page-item">
                <a className="page-link text-dark border-start-0" aria-label="Previous">
                  <span className>
                    <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="M561-240 320-481l241-241 43 43-198 198 198 198-43 43Z" /></svg>
                  </span>
                </a>
              </li>
              <li className="page-item"><a className="page-link text-dark border-start-0 border-end-0"   >{previousMonth}</a></li>
              <li className="page-item active"><a className="page-link text-dark border-start-0 border-end-0"   >{selectedMonth}</a></li>
              <li className="page-item"><a className="page-link text-dark border-start-0 border-end-0"   >{nextMonth}</a></li>
              <li className="page-item">
                <a className="page-link border-start-0 " aria-label="Next" onClick={(e)=>NextMonthYear(e)}>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z" /></svg>
                  </span>
                </a>
              </li>
              <li className="page-item">
                <a className="page-link text-dark border-start-0 " aria-label="Next">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="m255-241-42-42 198-198-198-198 42-42 240 240-240 240Zm253 0-42-42 198-198-198-198 42-42 240 240-240 240Z" /></svg>
                  </span>
                </a>
              </li>
            </ul>
          </nav> */}
          <nav aria-label="Page navigation example" className="mt-5 custom-pagination">
            <ul className="pagination justify-content-evenly align-items-center border-radius-10">

              <li className="page-item">
                <button className="page-link text-dark border-start-0" style={{ "border": "0 !important" }} aria-label="Previous" onClick={(e) => PreviousMonthYear(e)}>
                  <span className>
                    <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="M561-240 320-481l241-241 43 43-198 198 198 198-43 43Z" /></svg>
                  </span>
                </button>
              </li>
              <li className={`page-item ${UpdatedData == previousMonth.format("MMM YYYY") ? 'active' : ''}`}><a className="page-link text-dark border-start-0 border-end-0" onClick={(e) => PMonth(previousMonth.format("MMM YYYY"))}>{previousMonth.format("MMM YYYY")}</a></li>
              <li className={`page-item ${UpdatedData == selectedMonth.format("MMM YYYY") ? 'active' : ''}`}><a className="page-link text-dark border-start-0 border-end-0" onClick={(e) => PMonth(selectedMonth.format("MMM YYYY"))}>{selectedMonth.format("MMM YYYY")}</a></li>
              <li className={`page-item ${UpdatedData == nextMonth.format("MMM YYYY") ? 'active' : ''}`}><a className="page-link text-dark border-start-0 border-end-0" onClick={(e) => PMonth(nextMonth.format("MMM YYYY"))}>{nextMonth.format("MMM YYYY")}</a></li>
              <li className="page-item">
                <button className="page-link border-start-0 " style={{ "border": "0 !important" }} aria-label="Next" onClick={(e) => NextMonthYear(e)}>
                  <span>
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z" /></svg>
                    </span>
                  </span>
                </button>
              </li>

            </ul>
          </nav>
        </div>
        <div className="col-xl-3">
          <div className>
            <div className="accordion border-radius-10 " id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header poppins" id="headingOne">
                  <button className="accordion-button fs-5 text-dif-black bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">New Notifications</button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <div className="list_notification">
                      {Array.isArray(notificationData) && notificationData.map((dateItem, index) => (
                        <div key={index}>
                          {/* <span className="mb-4 d-block">{dateItem.date}</span> */}
                          {dayjs(dateItem.date).isSame(dayjs(), 'day') && (
                            <span className="mb-4 d-block">Today</span>
                          )}
                          {/* Check if the date is yesterday */}
                          {dayjs(dateItem.date).isSame(dayjs().subtract(1, 'day'), 'day') && (
                            <span className="mb-4 d-block">Yesterday</span>
                          )}
                          {/* Render the date */}
                          {!dayjs(dateItem.date).isSame(dayjs(), 'day') &&
                            !dayjs(dateItem.date).isSame(dayjs().subtract(1, 'day'), 'day') && (
                              <span className="mb-4 d-block">{dateItem.date}</span>
                            )}

                          <ul >
                            {dateItem.notification_data.map((notification, index) => (
                              <li key={index} className="d-flex align-items-center mb-4">
                                <div className="notif_left d-flex align-items-center">
                                  <div className={`noti_img bg-${notification.tag === 'add_class' ? 'pink' : notification.tag === 'quiz' ? 'diff-yellow-op-1' : 'sky-blue-op-1'} position-relative`}>
                                    <span className={`text-uppercase text-${notification.tag === 'add_class' ? 'danger' : notification.tag === 'quiz' ? 'yellow' : 'blue'}`}>{notification.message.charAt(0)}</span>
                                    <span className="notifi_logs">
                                      <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 4.5V9C10 9.13261 9.94732 9.25979 9.85355 9.35355C9.75979 9.44732 9.63261 9.5 9.5 9.5H0.5C0.367392 9.5 0.240215 9.44732 0.146447 9.35355C0.0526784 9.25979 0 9.13261 0 9V4.5H10ZM10 2.5H0V1C0 0.867392 0.0526784 0.740215 0.146447 0.646447C0.240215 0.552678 0.367392 0.5 0.5 0.5H9.5C9.63261 0.5 9.75979 0.552678 9.85355 0.646447C9.94732 0.740215 10 0.867392 10 1V2.5Z" fill="#3F3F44" />
                                      </svg>
                                    </span>
                                  </div>
                                  <p className="ms-3 noti_text">{notification.message}</p>
                                </div>
                                <span className="noti_timer">
                                  {dayjs(notification.insert_datetime).format('hh:mm A')}
                                </span>
                              </li>
                            ))}

                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modals isOpen={modalIsOpen} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal" >
        {/* <Teacher_Response setIsOpen={setIsOpen} setTeacherData={setTeacherData} teacherData={teacherData}/> */}
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 pb-0">
              <h5 className="modal-title" id="exampleModalLabel" />
              <button type="button" className="btn-close" onClick={() => setIsOpen(false)} />
            </div>
            <div className="modal-body meet_techer">

              <form onSubmit={handleSubmit(onSubmit)} className="custom_form">
                <div className="form-group mb-4">
                  <label className="mb-2">Select A Learner</label>
                  {/* <select className="form-select" {...register("select_class")} name="select_class" aria-label="Default select example">
                    <option value='' selected disabled>select class</option>

                    {selectClass?.map((coupon, index) => {

                      return (

                        <option key={index} value={coupon?.class_id} >{coupon?.title}</option>
                      )
                    })}


                  </select> */}
                  <select   {...register("select_class")} onChange={(e) => handleSelectedLearner(e.target.value)} className="form-select">

                    <option value='' selected disabled >Learner’s Name</option>

                    {learnerData?.map((student, index) => {
                      const type = student?.learner_id ? 'learner' : 'user';
                      const id = student?.learner_id || student?.user_id;
                      const valueObj = JSON.stringify({ type, id });
                      return (
                        <option key={index} value={valueObj}>{student?.learner_name || student?.user_name}</option>
                      )
                    })}
                  </select>
                  <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.select_class?.message}</span></p>

                </div>
                <div className='row'>

                  <div className="d-xl-flex align-items-center">
                    <button className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block" >
                      Send Certificate
                    </button>


                    <Link className="btn-theme d-inline-block text-center ms-xl-3" state={id} onClick={() => setIsOpen(false)}>Cancel</Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modals>
      {/* </div> */}
    </>
  )
}
